import { useEffect, useState } from 'react';

import emotional_chapter3_img1 from '../../../../assets/images/courses/emotional-intelligence/chapter3/emotional_chapter3_img1.jpg';
import emotional_chapter3_img2 from '../../../../assets/images/courses/emotional-intelligence/chapter3/emotional_chapter3_img2.jpg';
import emotional_chapter3_img3 from '../../../../assets/images/courses/emotional-intelligence/chapter3/emotional_chapter3_img3.jpg';
import m1 from '../../../../assets/images/courses/emotional-intelligence/chapter3/m1.jpg';
import m2 from '../../../../assets/images/courses/emotional-intelligence/chapter3/m2.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { course_questions } from '../../../../courses/emotional-intelligence/emotional-intelligence-course-question';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  UserChapterQuestion,
  dispatchUpdateUserCourseQuestionResponse,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import FlipCard from '../../FlipCard';
import { InfoBlock } from '../../InfoBlock';
import { MediaBlock } from '../../MediaBlock';
import { SimpleCarouselComponent } from '../../SimpleCarouselComponent';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};

export const EmotionalIntelligence3: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);
  const [, setAnswers] = useState<AnswersState>({});

  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();

  const chapterQuestions = course_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(false);
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const accordionData = [
    {
      id: 'panel1',
      title: 'Prepare for the Emotion',
      content:
        '<div class="fr-view"><p>Avoiding negative stimuli is rarely an option. Stress happens, tough conversations are inevitable, and projects don’t always run seamlessly. So, rather than avoiding conflicts, prepare for them.</p><p><br></p><p>Before a stressful situation, think about how you can calm yourself. Establish a few go-to techniques. If you’re walking into a difficult conversation, think about potential negative reactions. How can you respond calmly? What are your <em>“hot buttons”&nbsp;</em>that you need to watch out for? Or, before starting a project, think about what might go wrong so that you can prepare for those potential upsets.</p><p><br></p><p>By planning for negativity and your emotional responses, you’ll be less likely to get caught off guard. You’ll stay in control, and that makes it less likely that you’ll say or do things you later regret.</p></div>',
    },
    {
      id: 'panel2',
      title: 'Practice Mindful Observation ',
      content:
        '<div class="fr-view"><p>Denial is rarely an effective strategy. When you run from your feelings, those feelings tend to come out in unexpected ways. So, rather than burying your emotions, mindfully accept them. Recognize why you’re feeling a certain way and don’t judge that emotion.</p><p><br></p><p>For example, if you’re irritable, acknowledge and experience that emotion like a wave: let it come and go. <em>“I’m irritable today because I got stuck in traffic and didn’t have time to stop for breakfast.” </em>The simple act of mindful observation will allow you to get unstuck from emotions. Plus, if something comes up later that further aggravates you, you’ll be less likely to lash out since you know you’re feeling irritable for other reasons.</p></div>',
    },
    {
      id: 'panel3',
      title: 'Take Time to STOPP ',
      content:
        '<div class="fr-view"><p>In the heat of the moment, STOPP is a strategy that can help you cool down. Here’s what the acronym stands for:</p><ul class="list-disc pl-5"><li><p><strong>S - Stop:</strong> Stop and pause for a moment. Rather than having an emotional outburst, pause and create a buffer between the feeling and your reaction.&nbsp;</p></li><li><p><strong>T - Take a breath.</strong> Take a deep breath in and out. Get your breathing steady before proceeding to take action.</p></li><li><p><strong>O - Observe. </strong>Consider how you’re feeling right now. What are you reacting to? What thoughts are going through your head? What are your instincts telling you to do? And how could that response affect others?&nbsp;</p></li><li><p><strong>P - Pull back.</strong> Take a step back. Before marching up to that employee or sending out an angry email, get some perspective. Consider the implications of a short outburst. Think about the bigger picture. Ask yourself:<em> “How can I look at the situation differently? How important is this moment in the long term?"</em></p></li><li><p><strong>P - Proceed. </strong>Now, take action and do what’s most appropriate. How can you react rationally? &nbsp;</p></li></ul></div>',
    },
    {
      id: 'panel4',
      title: 'Practice Letting Go ',
      content:
        '<div class="fr-view"><p>Cognitive reappraisal is a technique that allows you to reframe your understanding of events. There are a lot of ways to think about the same situation. And, through this technique, you can choose the healthiest interpretation.</p><p><br></p><p>For example, let’s say one of your coworkers, Frankie, appeared spaced-out during today’s meeting. You took time to prepare an agenda, and you feel ignored and disrespected. Your gut emotional reaction is anger.</p><p><br></p><p>With cognitive reappraisal, you can reframe that situation with Frankie. Rather than thinking his behavior was disrespectful, you might think: <em>“Frankie might be going through something.”&nbsp;</em>You can readjust your mindset. And, that way, when you confront Frankie later, it won’t be out of anger, but concern. You’ll communicate the same message: <em>“You need to pay attention during meetings,”</em> but you’ll do so in a more productive and emotionally stable way.</p></div>',
    },
    {
      id: 'panel5',
      title: 'Reframe Through Cognitive Reappraisal',
      content:
        '<p>When you’re in a good mood, you take your team out to lunch, offer bonuses, and congratulate them on their work. But when you’re frustrated or stressed, you take those emotions out on your employees—nitpicking their performance, yelling about inconsequential details, and causing everyone to walk on eggshells.</p><p><br></p><p>You’re unpredictable. Behind your back, employees call you a loose cannon who’s hard to follow and difficult to work for. You let your emotions run you (rather than the other way around), and that impacts your actions and the feelings of those around you. &nbsp;</p>',
    },
  ];
  const imageSlidesData = [
    {
      heading: '',
      content:
        'A couple is in a heated argument, so they agree to pause the conversation and “cool down.”',
      image: emotional_chapter3_img1,
    },
    {
      heading: '',
      content:
        'An employee is turned away from his dream promotion, but he doesn’t let that setback crush his spirits.',
      image: emotional_chapter3_img2,
    },
    {
      heading: '',
      content:
        'An angry customer is yelling at a sales representative, and—rather than matching that anger—the representative remains courteous to prevent the situation from escalating.',
      image: emotional_chapter3_img3,
    },
  ];
  const slidesData = [
    {
      heading: 'Self-Control',
      content: `<div class="fr-view"><p>Self-control is how well you manage disruptive emotions, control your behavior, and keep impulses in check.&nbsp;</p><p>Those with high self-control are rational and level-headed. They communicate their messages clearly and create a balanced and stable workplace.</p></div>`,
    },
    {
      heading: 'Trustworthiness',
      content: `<div class="fr-view"><p>When you’re skilled at regulating your emotional behavior, you’re more likely to act consistently and according to your values.&nbsp;</p><p>To your team, that makes you a reliable contributo who doesn’t submit to unpredictable behaviors. You’re someone people can trust and count on, regardless of what emotional triggers might come up.</p></div>`,
    },
    {
      heading: 'Conscientiousness',
      content: `<div class="fr-view"><p>Conscientiousness is how disciplined and responsible you are. Rather than blaming people or external factors for your actions, you’ll take responsibility for your performance.</p><p>Conscientious performers keep their emotions in check, uphold their commitments, and act as role models for others.</p></div>`,
    },
    {
      heading: 'Adaptability',
      content: `<div class="fr-view"><p>Adaptability is how well you handle rapid change and unexpected stress. Without this quality, you may crack under pressure or express high anxiety, stress, and worry in times of uncertainty.&nbsp;</p><p>Such reactions will only alarm others. But, with high self-regulation, you’ll know whether your responses will result in a positive or negative outcome.</p></div>`,
    },
    {
      heading: 'Innovation',
      content: `<div class="fr-view"><p>With strong self-regulation, you’re more likely to view challenges as opportunities and be open to new ideas.&nbsp;</p><p>Rather than getting caught up in a problem or sinking down during times of workplace turmoil, you’ll take a step back so that you can think creatively and find new, innovative ideas.</p></div>`,
    },
  ];
  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext, isPageDirty]);

  return (
    <>
      <div className="content-wrap border-l-0 lg:border-l pl-0 lg:pl-8 pt-8 pb-10">
        <h2 className="font-semibold text-3xl">Boosting Self-Regulation</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">Three Emotional Scenes</h3>
        <p>
          Advance through the scenes below and ask yourself: "What do the stories have in common?"
        </p>
        {/* slider with image */}
        <SimpleCarouselComponent data={imageSlidesData} />
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          Did you say{' '}
          <em>
            <strong>"self-regulation?"</strong>
          </em>
        </h3>
        <p>
          The people in the scenes above were skilled at self-regulation—even when their emotions
          were running high. Self-regulation is a skill for managing impulsive behavior and staying
          collected in times of emotional intensity.
        </p>
        <br />
        <p>
          In this lesson, you’ll learn to define self-regulation, spot why it’s necessary, and
          improve your own self-regulation with five strategies.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/pmm84TfAS-6slTdJ5v37--eMH1JMGGF7/dpSdzXnW/assets/rbc/iCfWscOiZj3gPWC9_LDRSHP-EQ-03.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <p>
          Watch this video to learn what self-regulation is, what elements make up this competency,
          and how to improve your self-regulation skills.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">What Is Self-Regulation?</h3>
        <p>
          If you’ve ever picked yourself up out of a bad mood, resisted an angry outburst, or stayed
          true to your values during emotional extremes, then you were displaying emotional
          self-regulation. Flip the card below to learn more.
        </p>
        <div className="my-10">
          <div className="flex justify-center gap-5 text-2xl font-light">
            <FlipCard>
              <div className="front-content">
                <p>Self-Regulation</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center text-lg">
                <p>
                  Self-regulation—also known as self-management or self-discipline—is the ability to{' '}
                  <em>control</em> your emotions and behavior.
                </p>
              </div>
            </FlipCard>
          </div>
        </div>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          Why Self-Regulation Matters
        </h3>
        <p>
          Controlling your behavior isn't about suppressing or ignoring your feelings. Instead,
          self-regulation keeps your emotions in check by enabling you to:
        </p>
        <ul className="list-disc pl-5 mt-7">
          <li className="pl-4 mb-5">Maintain composure in high-stress situations.</li>
          <li className="pl-4 mb-5">Respond to events in an emotionally appropriate manner.</li>
          <li className="pl-4 mb-5">Resist impulsive behaviors.</li>
          <li className="pl-4 mb-5">Bounce back from failures.</li>
          <li className="pl-4 mb-5">Calm yourself down in times of stress or frustration.</li>
        </ul>
        <MediaBlock mediaImage={m1}>
          <p className="text-3xl leading-10 font-light w-3/4">
            Rather than blaming circumstances for your reactions or allowing your emotions to
            control you, self-regulation puts you in the emotional driver’s seat.
          </p>
        </MediaBlock>
        <p className="text-3xl text-center font-light mt-7">
          "My fault, my failure, is not in the passions I have, but in my lack of control of them."
          <br />
          <span className="text-base font-bold">Jack Kerouac</span>
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          5 Elements of Self-Regulation
        </h3>
        <p>
          Self-regulation is made up of five primary elements, each of which is necessary for
          workplace success. Move through the cards below to learn more.
        </p>
        <div className="border rounded-3xl pt-10 mt-7">
          <SimpleCarouselComponent data={slidesData} />
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          How to Improve Your Self-Regulation
        </h3>
        <p>
          So, how can you improve your self-regulation skills? Rather than avoiding your feelings or
          seeking temporary distractions, you’ll need to confront emotions head-on.
        </p>
        <br />
        <p>Expand the rows below to learn five strategies for improving your self-regulation.</p>
        <br />
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <br />
        <InfoBlock
          title="Don’t play the blame game.  "
          text=" It’s easy to blame our situations for our reactions: “I’m angry because my team doesn’t get it!” But remember, you can choose how you react. The next time you're feeling a strong emotion, remember that you’re in control of your emotions, and it’s up to you to determine what happens next."
        />
        <MediaBlock mediaImage={m2}>
          <p className="text-3xl leading-10 font-light w-3/4">
            It isn’t stress that makes us fall—it’s how we respond to stressful events.
            <br />
            <span className="text-base font-bold">Wayde Goodall</span>
          </p>
        </MediaBlock>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">Apply Your Understanding</h3>
        <p>Apply your understanding by completing the following activity. </p>
        <div className="mb-8 mt-5">
          <CourseQuestionCard
            submittedAnswer={userCourseQuestionResponseContext[
              chapterQuestions[0].course_id
            ]?.chapters[chapter.id]?.questions?.at(0)}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmitAnswer={handleCourseAnswerSubmit}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetakeQuestion={handleQuestionRetake}
            question={chapterQuestions[0]}
          />
        </div>

        <p className="text-3xl my-8 leading-10 font-light text-center">
          In times of emotional intensity, self-regulation can help you maintain composure and
          resist impulsive behaviors. In the next lesson, you’ll learn to master the final component
          of personal competence: self-motivation.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full uppercase"
        >
          Increasing Self-Motivation
        </button>
      </div>
    </>
  );
};
