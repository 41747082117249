import { East } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
interface CourseCardProps {
  cssClass?: string;
  customStyle?: React.CSSProperties | null;
  courseName: string;
  courseImage: string;
  courseDescription: string;
  courseId: string;
  organization: string | undefined;
  disabled: boolean;
  disabledDescription?: string;
  isPremium?: boolean;
}
export const CourseCard: React.FC<CourseCardProps> = ({
  cssClass,
  customStyle,
  courseImage,
  courseName,
  courseDescription,
  courseId,
  organization,
  disabled,
  disabledDescription,
  isPremium,
}) => {
  return (
    <>
      <div
        style={customStyle || undefined}
        className={`${cssClass || ''} course-card ${disabled ? 'course-disabled' : ''}`}
      >
        <div className="img-wrap">
          <img src={courseImage} alt="" />
        </div>
        <div className={`course-body ${organization && '!pb-16'}`}>
          <div className="course-type">
            <p></p>
          </div>
          <div className="course-title">
            <h2>{courseName}</h2>
          </div>
          <div className="course-content">
            <p dangerouslySetInnerHTML={{ __html: courseDescription }} />
          </div>
        </div>
        {organization || isPremium ? (
          <Link
            to={`${disabled ? '#' : '/course/details/' + courseId}`}
            className={`${disabled ? 'course-action' : 'course-action cursor-pointer'}`}
          >
            View Details
            <East />
          </Link>
        ) : null}
      </div>
      {disabled && (
        <div
          className="dashboard-course p-2 rounded-2xl shadow-sm absolute p-5 top-[0px] h-full w-full flex items-center theme-gradient text-center
            opacity-0
            hover:opacity-95 "
        >
          <div className="mt-0">
            <div>
              <div className="p-2 text-white">
                <p
                  className="text-2xl"
                  dangerouslySetInnerHTML={{ __html: disabledDescription || '' }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
