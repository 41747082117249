/* eslint-disable @typescript-eslint/no-misused-promises */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CommonAuthenticatedPage } from '../components/AuthenticatedPage/CommonAuthenticatedPage';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchUpdatedPasswordEmail } from '../redux/slice/auth/auth.operations';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';
import { UpdateUserPassword } from '../services/database';
import { calculatePasswordStrength } from '../utils/helper';

export const UpdatePassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useUserProfileContext();
  const { name } = useIntakeFormContext();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // State for confirm password
  const [loading, setLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // For confirm password visibility
  const [passwordStrength, setPasswordStrength] = useState<number>(0); // Strength indicator state

  useEffect(() => {
    void dispatch(dispatchGetUserProfileValues());
    void dispatch(dispatchGetIntakeFormValues());
  }, []);

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setNewPassword(value);
    setPasswordStrength(calculatePasswordStrength(value)); // Calculate strength
  };

  const handlePasswordChange = async (event: React.FormEvent) => {
    event.preventDefault();

    setLoading(true);

    // Check if the new password is the same as the current password
    if (newPassword === currentPassword) {
      toast.error('New password cannot be the same as the current password.');
      setLoading(false);
      return;
    }

    // Check for password strength and length validation
    if (newPassword.length < 8) {
      toast.error('Password must be at least 8 characters long.');
      setLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('New Password and Confirm Password do not match.');
      setLoading(false);
      return;
    }

    const response = await UpdateUserPassword(currentPassword, newPassword);
    if (response) {
      setLoading(false);
      if (response.status) {
        toast.success(response.message);
        if (response.email) {
          await dispatchUpdatedPasswordEmail(
            response?.email,
            user?.name || name || response?.email,
          );
        }
        navigate('/dashboard');
      } else {
        toast.error(response.message);
      }
    }
  };

  // Function to render password strength indicator
  const getPasswordStrengthLabel = () => {
    if (passwordStrength === 5) return { label: 'Strong', color: 'green' };
    if (passwordStrength >= 3) return { label: 'Medium', color: 'orange' };
    return { label: 'Weak', color: 'red' };
  };

  const { label: strengthLabel, color: strengthColor } = getPasswordStrengthLabel();

  return (
    <div className="m-2">
      <div className="flex items-center min-h-[50vh] justify-center">
        <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Change Password</h2>
          <form onSubmit={handlePasswordChange}>
            {/* Current Password */}
            <div className="mb-4">
              <TextField
                required
                label="Current Password"
                type={showCurrentPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value.trim())}
                className="w-full"
                placeholder="Enter your current password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        edge="end"
                      >
                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            {/* New Password */}
            <div className="mb-4">
              <TextField
                required
                label="New Password"
                type={showNewPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                value={newPassword}
                onChange={handleNewPasswordChange}
                className="w-full"
                placeholder="Enter your new password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Password Strength Indicator */}
              {newPassword && (
                <div className="mt-2">
                  <LinearProgress
                    variant="determinate"
                    value={(passwordStrength / 5) * 100} // Strength as percentage
                    sx={{
                      backgroundColor: '#e0e0e0',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: strengthColor,
                      },
                    }}
                  />
                  <p className="text-sm mt-1" style={{ color: strengthColor }}>
                    Password Strength: {strengthLabel}
                  </p>
                </div>
              )}
            </div>

            {/* Confirm Password */}
            <div className="mb-4">
              <TextField
                required
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value.trim())}
                className="w-full"
                placeholder="Confirm your new password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            {/* Submit Button */}
            <button
              disabled={!currentPassword || !newPassword || !confirmPassword}
              type="submit"
              className="w-1/2 justify-center bg-purple-600 disabled:bg-purple-400 text-white py-2 rounded-md hover:bg-purple-700 transition duration-300"
            >
              {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Change Password'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export const UpdatePasswordPage: React.FC = () => {
  return <CommonAuthenticatedPage render={() => <UpdatePassword />} />;
};
