import { collection, addDoc, setDoc, doc } from 'firebase/firestore';

import { OrganizationDTO } from '../../redux/slice/form-intake';
import { hash } from '../../utils/crypto';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';

const database: DatabaseTable = DatabaseTable.ORGANIZATIONS;

/**
 * upsert a record into the organization
 */
async function updateRecord<T extends Record<string, unknown>>(
  dto: T,
  recordId?: string,
): Promise<void> {
  if (recordId) {
    await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
      merge: true,
    });
  } else {
    await addDoc(collection(getFirestoreDatabase(), database), dto);
  }
}

/**
 * creates an organization
 */
export async function upsertOrganization(dto: OrganizationDTO): Promise<void> {
  logger.debug('Upserting organization.');
  try {
    const { organization } = dto;
    const hashedOrganizationId: string = await hash(organization);
    await updateRecord(dto, hashedOrganizationId);
  } catch (e) {
    logger.debug('Failed to upsert organization.', e);
    throw e;
  }
}
