import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import color_energies from '../../../../assets/images/courses/perception-understanding/chapter8/color_energies.jpg';
import EmbracingOpposites from '../../../../assets/images/courses/perception-understanding/chapter8/EmbracingOpposites.png';
import introImg from '../../../../assets/images/courses/perception-understanding/chapter8/introImg.jpg';
import m1 from '../../../../assets/images/courses/perception-understanding/chapter8/m1.jpg';
import m2 from '../../../../assets/images/courses/perception-understanding/chapter8/m2.jpg';
import m3 from '../../../../assets/images/courses/perception-understanding/chapter8/m3.jpg';
import perciption_chapter7_img1 from '../../../../assets/images/courses/perception-understanding/chapter8/perciption_chapter8_img1.png';
import perciption_chapter7_img2 from '../../../../assets/images/courses/perception-understanding/chapter8/perciption_chapter8_img2.png';
import perciption_chapter7_img3 from '../../../../assets/images/courses/perception-understanding/chapter8/perciption_chapter8_img3.png';
import perciption_chapter7_img4 from '../../../../assets/images/courses/perception-understanding/chapter8/perciption_chapter8_img4.png';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseMatchingQuestion,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import {
  CourseBasicDetails,
  course_basic_details,
  course_matching_questions,
} from '../../../../courses/perception-understanding/perciption-understanding-questions';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { dispatchUpdateUserCourseQuestionResponse } from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomQuestionAccordion } from '../../../common/QuestionAccordion';
import { CustomAudioTabsBlock, CustomTabPanel } from '../../CustomAudioTabs';
import { InfoBlock } from '../../InfoBlock';
import { MatchingQuestionBlock } from '../../MatchingQuestion/CourseMatchingQuestion';
import { MediaBlock } from '../../MediaBlock';
import { ToggleCard } from '../../ToggleCard';
interface PerciptionProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const PerceptionAndUnderstandingChapter8: React.FC<PerciptionProps> = ({
  chapter,
  onNextChapter,
}) => {
  const [isPageDirty, setIsPageDirty] = useState(true);
  const [energyTabValue, setEnergyTabValue] = useState<number | null>(null);

  const dispatch = useAppDispatch();

  const tabsData = [
    {
      title: 'Fiery Red',
      textColor: '',
      bgColor: 'bg-fiery-red',
    },
    {
      title: 'Sunshine Yellow',
      textColor: '',
      bgColor: 'bg-sunshine-yellow',
    },
    {
      title: 'Earth Green',
      textColor: '',
      bgColor: 'bg-earth-green',
    },
    {
      title: 'Cool Blue',
      textColor: '',
      bgColor: 'bg-cool-blue',
    },
  ];

  const [openCardPosition, setOpenCardPosition] = useState<string | null>(null);
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();

  const courseMatchingQuestion = course_matching_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const courseFilter: CourseBasicDetails[] = course_basic_details.filter(
    (course) => course.chapter_id === chapter.id,
  );
  const handleToggleCard = (position: string) => {
    setOpenCardPosition((prevPosition) => (prevPosition === position ? null : position));
  };
  const handleMatchingRetake = async (questionId: string) => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseMatchingQuestion[0].course_id]: {
          chapters: {
            [chapter.id]: {
              matchingQuestions: [
                ...userMatchingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };

  const handleMatchingExcerciseCompleted = async (matchingAnswers: SortingAnswersState) => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseMatchingQuestion[0].course_id]: {
          chapters: {
            [chapter.id]: {
              matchingQuestions: [
                ...userMatchingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(matchingAnswers)[0]),
                ),
                matchingAnswers,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const accordionData = [
    {
      id: 'panel1',
      title: 'Question1',
      content:
        '1. Think back to a conversation that did not go very well where your style and the person you were connecting with were different.  What happened?  ',
    },
    {
      id: 'panel2',
      title: 'Question2',
      content: '2. What might you do differently to adapt and connect?',
    },
  ];
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };

  useEffect(() => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userMatchingAnswerResponse?.length == 2
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext, isPageDirty]);
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-xl font-light">
        <h2 className="font-semibold text-3xl">Adapting to Connect</h2>
        <p className="text-sm italic">Part 8 of 10</p>
        <MediaBlock mediaImage={introImg}></MediaBlock>

        <div className="flex items-center mt-12">
          <div>
            <p className="font-bold mb-4">To enhance our interpersonal effectiveness, we can:</p>
            <ul className="list-disc pl-5">
              <li className="pl-4 mb-7">
                Pay attention to other’s styles and look for cues to their preferences
              </li>
              <li className="pl-4">
                Adapting our approach can help us connect best with their preferences
              </li>
            </ul>
          </div>
          <img className="w-96 ml-9 rounded-3xl" src={m1} alt="" />
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-6">Adapt in Order to Connect</h3>
        <div className="flex items-center mt-12">
          <img className="w-96 mr-9 rounded-3xl" src={m2} alt="" />
          <div>
            <p>Adapting works from the “outside in.” </p>
            <ul className="list-disc pl-5">
              <li className="pl-4 mb-7">
                Changing your outer behavior results in a change in your inner state.
              </li>
            </ul>
            <p>Connecting works from the “inside out.” </p>
            <ul className="list-disc pl-5">
              <li className="pl-4 mb-7">
                Changing your inner state, results in a change in your outer behavior.
              </li>
            </ul>
            <p>
              Putting effort into both adapting and connecting will significantly enhance the
              strength of any relationship.
            </p>
          </div>
        </div>
        <div className="flex items-center mt-12">
          <div>
            <p>
              Adapting is more about how others might see you. As you start to behave differently,
              you start to think differently as well.
              <br />
              <br />
              At the same time, if you can consciously change how you look at an individual or
              situation, it will start to modify your behaviors.
              <br />
              <br />
              Using a combination of both can move you closer to someone that may be different than
              you.
            </p>
          </div>
          <img className="w-96 ml-9 rounded-3xl" src={m3} alt="" />
        </div>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">
          Activity: Reflecting on a Conversation
        </h3>
        <p className="mb-8">Answer the following questions:</p>
        <InfoBlock
          title="Note: "
          text="Upon completion of answering both questions, click next and save as a pdf or print your responses. "
        />
        {accordionData.map((data) => (
          <CustomQuestionAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
            chapter={chapter}
            course={courseFilter[0]}
          />
        ))}
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">
          Communicating with Color Energies
        </h3>
        <p className="mb-5">
          Click each tab to learn about communicating with different colour energies and click on
          the arrows within each tab to learn more.
        </p>
        {/* Tabs */}
        <CustomAudioTabsBlock tabsData={tabsData} onTabChange={(value) => setEnergyTabValue(value)}>
          {!_.isNil(energyTabValue) && (
            <>
              {/* tab 1 */}
              <CustomTabPanel index={0} value={energyTabValue}>
                {/* tab 1 */}
                <Swiper navigation={true} modules={[Navigation]} className="preferencesSwiper">
                  <SwiperSlide>
                    {/* slide 1 */}
                    <p className="inline-block text-lg border-b-2 border-[#b73434]">
                      Fiery Red Energy
                    </p>
                    <div className="flex text-base m-5 justify-center font-normal items-center relative">
                      <div className="min-w-[200px]">
                        <p className="text-center text-[#b73434] font-bold  text-sm mb-7">
                          Body Language
                        </p>
                        <div className="right-sec border-b-2 border-black pb-12 mb-12">
                          <div className="text-left text-sm">
                            <li className="mb-1  whitespace-nowrap">Formal</li>
                            <li className="mb-1  whitespace-nowrap">Direct eye-contact</li>
                            <li className="mb-1  whitespace-nowrap">Firm handshake</li>
                            <li className="mb-1  whitespace-nowrap">Upright posture</li>
                          </div>
                        </div>
                        <p className="text-center text-[#b73434] font-bold  text-sm mb-7 ">
                          Work environment
                        </p>
                        <div className="text-left text-sm ">
                          <li className="mb-1  whitespace-nowrap">Orderly</li>
                          <li className="mb-1  whitespace-nowrap">Efficient - easy access to</li>
                          <li className="mb-1  whitespace-nowrap">everything they need</li>
                          <li className="mb-1  whitespace-nowrap">Gadgets to hand</li>
                        </div>
                      </div>

                      <div className="flex text-center  mx-8">
                        <img className="" src={perciption_chapter7_img1} alt="" />
                      </div>
                      <div className="right-sec min-w-[200px]">
                        <p className="text-center text-[#b73434] font-bold  text-sm mb-7 ">
                          Verbal Style
                        </p>
                        <div className="right-sec  border-b-2 border-black pb-12 mb-12">
                          <div className="text-left text-sm ">
                            <li className="mb-1  whitespace-nowrap">Business-like</li>
                            <li className="mb-1  whitespace-nowrap">Faster pace</li>
                            <li className="mb-1  whitespace-nowrap">Responds quickly</li>
                            <li className="mb-1  whitespace-nowrap">Direct</li>
                          </div>
                        </div>

                        <p className="text-center text-[#b73434] font-bold  text-sm mb-7 ">
                          Interactions
                        </p>
                        <div className="text-left text-sm ">
                          <li className="mb-1  whitespace-nowrap">Urgency</li>
                          <li className="mb-1  whitespace-nowrap">Chalenge facts</li>
                          <li className="mb-1  whitespace-nowrap">Controlling</li>
                          <li className="mb-1  whitespace-nowrap">Less patient</li>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {/* slide 2 */}
                    <div className="flex  relative justify-between pb-10">
                      <p className=" ">
                        How to communciate with someone with a <br />
                        <span className="text-[#b73434] font-bold  text-sm">Fiery Red</span>{' '}
                        prefernce
                      </p>
                      <p className=" ">
                        How to connect with someone with a <br />
                        <span className="text-[#b73434] font-bold  text-sm">Fiery Red</span>{' '}
                        prefernce
                      </p>
                    </div>

                    <div className="flex text-base m-5 justify-center font-normal items-center relative">
                      <div className="flex-1">
                        <p className="text-left text-[#b73434] font-bold  text-sm mb-7">Do:</p>
                        <div className="right-sec mb-10">
                          <div className="text-left text-sm">
                            <ul className="list-disc pl-5 inline-block">
                              <li className="mb-1  whitespace-nowrap">
                                Be direct and to the point
                              </li>
                              <li className="mb-1  whitespace-nowrap">
                                Focus on result and objectives
                              </li>
                              <li className="mb-1  whitespace-nowrap">
                                Be confident and assertive
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="right-sec">
                          <p className="text-left text-[#b73434] font-bold  text-sm mb-7">Don’t:</p>
                          <div className="text-left text-sm ">
                            <ul className="list-disc pl-5 inline-block">
                              <li className="mb-1  whitespace-nowrap">Hesitate or be indecisive</li>
                              <li className="mb-1  whitespace-nowrap">Focus on feelings</li>
                              <li className="mb-1  whitespace-nowrap">Try to take over</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-center text-center  mx-8">
                        <img className="" src={perciption_chapter7_img1} alt="" />
                      </div>
                      <div className="right-sec flex-1">
                        <div className="text-left text-sm ">
                          <ul className="list-disc pl-5 inline-block">
                            <li className="mb-1  whitespace-pre-wrap">
                              Stand tall and place your hands on your hips to exude more confident
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Focus your attention on th task at hand
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Connect with the objectives and outpus needed, prioritize and move
                              into action
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Detach from the emotion or story of the moment. Shift to a more
                              objective perspective by asking, "What do I know is true and factual
                              here?"
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </CustomTabPanel>
              {/* tab 2 */}
              <CustomTabPanel index={1} value={energyTabValue}>
                <Swiper navigation={true} modules={[Navigation]} className="preferencesSwiper">
                  <SwiperSlide>
                    {/* slide 1 */}
                    <p className="inline-block text-lg border-b-2 border-[#fcca4f]">
                      Sunshine Yellow Energy
                    </p>
                    <div className="flex text-base m-5 justify-center font-normal items-center relative">
                      <div className="min-w-[200px]">
                        <p className="text-center text-[#fcca4f] font-bold  text-sm  mb-7">
                          Body Language
                        </p>
                        <div className="right-sec">
                          <div className="text-left text-sm  border-b-2 border-black pb-12 mb-12">
                            <li className="mb-1  whitespace-nowrap">Relaxed</li>
                            <li className="mb-1  whitespace-nowrap">Open/responsive</li>
                            <li className="mb-1  whitespace-nowrap">Facial expression</li>
                            <li className="mb-1  whitespace-nowrap">Physical contact</li>
                          </div>
                          <p className="text-center text-[#fcca4f] font-bold  text-sm  mb-7">
                            Work environment
                          </p>
                          <div className="text-left text-sm ">
                            <li className="mb-1  whitespace-nowrap">Unstructured</li>
                            <li className="mb-1  whitespace-nowrap">Busy (Messy!)</li>
                            <li className="mb-1  whitespace-nowrap">Photos activities or social</li>
                            <li className="mb-1  whitespace-nowrap">events</li>
                          </div>
                        </div>
                      </div>

                      <div className="flex text-center  mx-8">
                        <img className="" src={perciption_chapter7_img2} alt="" />
                      </div>
                      <div className="right-sec min-w-[200px]">
                        <p className="text-center text-[#fcca4f] font-bold  text-sm  mb-7">
                          Verbal Style
                        </p>
                        <div className="right-sec  border-b-2 border-black pb-12 mb-12">
                          <div className="text-left text-sm ">
                            <li className="mb-1  whitespace-nowrap">Animated</li>
                            <li className="mb-1  whitespace-nowrap">Expressive </li>
                            <li className="mb-1  whitespace-nowrap">Spontaneous</li>
                            <li className="mb-1  whitespace-nowrap">Talkative</li>
                          </div>
                        </div>
                        <p className="text-center text-[#fcca4f] font-bold  text-sm  mb-7">
                          Interactions
                        </p>
                        <div className="text-left text-sm ">
                          <li className="mb-1  whitespace-nowrap">Flexible</li>
                          <li className="mb-1  whitespace-nowrap">Friendly facts</li>
                          <li className="mb-1  whitespace-nowrap">Future-oriented</li>
                          <li className="mb-1  whitespace-nowrap">Cooperative</li>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {/* slide 2 */}
                    <div className="flex  relative justify-evenly pb-10  whitespace-pre-wrap">
                      <p className="  whitespace-pre-wrap">
                        How to communciate with someone with a <br />
                        <span className="text-[#fcca4f] font-bold  text-sm">
                          Sunshine Yellow
                        </span>{' '}
                        prefernce
                      </p>
                      <p className=" ">
                        How to connect with someone with a <br />
                        <span className="text-[#fcca4f] font-bold  text-sm">
                          Sunshine Yellow
                        </span>{' '}
                        prefernce
                      </p>
                    </div>

                    <div className="flex text-base m-5 justify-center font-normal items-center relative">
                      <div className="left-4 flex-1">
                        <p className="text-left text-[#fcca4f] font-bold  text-sm mb-7">Do:</p>
                        <div className="right-sec mb-10">
                          <div className="text-left text-sm">
                            <ul className="list-disc pl-5 inline-block">
                              <li className="mb-1  whitespace-pre-wrap">
                                Be friendly and sociable
                              </li>
                              <li className="mb-1  whitespace-pre-wrap">
                                Be engaging and stimulating
                              </li>
                              <li className="mb-1  whitespace-pre-wrap">Be open and flexible</li>
                            </ul>
                          </div>
                        </div>
                        <div className="right-sec flex-1">
                          <p className="text-left text-[#fcca4f] font-bold  text-sm mb-7">Don’t:</p>
                          <div className="text-left text-sm ">
                            <ul className="list-disc pl-5 inline-block">
                              <li className="mb-1 whitespace-pre-wrap">Bore them with details</li>
                              <li className="mb-1 whitespace-pre-wrap">
                                Tie them down with routine
                              </li>
                              <li className="mb-1  whitespace-pre-wrap">
                                Ask them to work alone for long periods of time
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="flextext-center  mx-8">
                        <img className="" src={perciption_chapter7_img2} alt="" />
                      </div>
                      <div className="right-sec flex-1">
                        <div className="text-left text-sm ">
                          <ul className="list-disc pl-5 inline-block">
                            <li className="mb-1  whitespace-pre-wrap">
                              Smile. Smiles invite connection with others (and can lift your mood)
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Ask open questions. Great questions open dialogue with others and
                              offer opportunity for new ideas to come forward.
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Ask, "What else might be possible here?" Stay open to possibilities
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Be enthusiastice, expressive and get involved.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </CustomTabPanel>
              {/* tab 3 */}
              <CustomTabPanel index={2} value={energyTabValue}>
                <Swiper navigation={true} modules={[Navigation]} className="preferencesSwiper">
                  <SwiperSlide>
                    {/* slide 1 */}
                    <p className="inline-block text-lg border-b-2 border-[#8ba40a]">
                      Earth Green Energy
                    </p>
                    <div className="flex text-base m-5 justify-center font-normal items-center relative">
                      <div className="min-w-[200px]">
                        <p className="text-center text-[#8ba40a] font-bold  text-sm  mb-7">
                          Body Language
                        </p>
                        <div className="right-sec border-b-2 border-black pb-12 mb-12">
                          <div className="text-left text-sm">
                            <li className="mb-1  whitespace-nowrap">Warm smile</li>
                            <li className="mb-1  whitespace-nowrap">Some eye-contact</li>
                            <li className="mb-1  whitespace-nowrap">Gentle handshake</li>
                            <li className="mb-1  whitespace-nowrap">Few gestures</li>
                          </div>
                        </div>
                        <p className="text-center text-[#8ba40a] font-bold  text-sm mb-7">
                          Work environment
                        </p>
                        <div className="text-left text-sm ">
                          <li className="mb-1  whitespace-nowrap">Comfortable</li>
                          <li className="mb-1  whitespace-nowrap">Personal</li>
                          <li className="mb-1  whitespace-nowrap">Family phots/plants</li>
                          <li className="mb-1  whitespace-nowrap">on display</li>
                        </div>
                      </div>

                      <div className="flex text-center  mx-8">
                        <img className="" src={perciption_chapter7_img3} alt="" />
                      </div>
                      <div className="right-sec min-w-[200px]">
                        <p className="text-center text-[#8ba40a] font-bold  text-sm   mb-7">
                          Verbal Style
                        </p>
                        <div className="right-sec">
                          <div className="text-left text-sm border-b-2 border-black pb-12 mb-12 ">
                            <li className="mb-1  whitespace-nowrap">Soft tone</li>
                            <li className="mb-1  whitespace-nowrap">Enquiring </li>
                            <li className="mb-1  whitespace-nowrap">Slower pace</li>
                            <li className="mb-1  whitespace-nowrap">Diplomatic</li>
                          </div>
                        </div>
                        <p className="text-center text-[#8ba40a] font-bold  text-sm mb-7">
                          Interactions
                        </p>
                        <div className="text-left text-sm ">
                          <li className="mb-1  whitespace-nowrap">Considerate</li>
                          <li className="mb-1  whitespace-nowrap">Little challenge</li>
                          <li className="mb-1  whitespace-nowrap">Small talk</li>
                          <li className="mb-1  whitespace-nowrap">Indirect responeses</li>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {/* slide 2 */}
                    <div className="flex justify-between  relative pb-10 ">
                      <p className=" ">
                        How to communciate with someone with a <br />
                        <span className="text-[#8ba40a] font-bold  text-sm">Earth Green</span>{' '}
                        prefernce
                      </p>
                      <p className=" ">
                        How to connect with someone with a <br />
                        <span className="text-[#8ba40a] font-bold  text-sm">Earth Green</span>{' '}
                        prefernce
                      </p>
                    </div>

                    <div className="flex text-base m-5 justify-center font-normal items-center relative">
                      <div className="flex-1">
                        <p className="text-left text-[#8ba40a] font-bold  text-sm mb-7">Do:</p>
                        <div className="right-sec mb-10">
                          <div className="text-left text-sm">
                            <ul className="list-disc pl-5 inline-block">
                              <li className="mb-1  whitespace-pre-wrap">
                                Be patient and supportive
                              </li>
                              <li className="mb-1  whitespace-pre-wrap">
                                Ask for input before making a decision
                              </li>
                              <li className="mb-1  whitespace-pre-wrap">
                                Ask for their opinion and give time to answer
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="right-sec">
                          <p className="text-left text-[#8ba40a] font-bold  text-sm mb-7">Don’t:</p>
                          <div className="text-left text-sm ">
                            <ul className="list-disc pl-5 inline-block">
                              <li className="mb-1  whitespace-pre-wrap">
                                Take advantage of their good nature
                              </li>
                              <li className="mb-1  whitespace-pre-wrap">
                                Push them to make quick decisions
                              </li>
                              <li className="mb-1  whitespace-pre-wrap">
                                Tell, instruct, or command
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="flex text-center  mx-8">
                        <img className="" src={perciption_chapter7_img3} alt="" />
                      </div>
                      <div className="right-sec flex-1">
                        <div className="text-left text-sm ">
                          <ul className="list-disc pl-5 inline-block">
                            <li className="mb-1  whitespace-pre-wrap">
                              Focus on your core values and those of others. Consider how your
                              actions are in in line with these values.
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Take a breath, unfold your arms, try to relax. Pay attention to the
                              sensation in your body and strive to embody a calm presence.
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Practice active listening. Give your full attention to others, their
                              needs and their concerns.
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Be more informal, less confrontational, slow the pace down a bit.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </CustomTabPanel>
              {/* tab 4 */}
              <CustomTabPanel index={3} value={energyTabValue}>
                <Swiper navigation={true} modules={[Navigation]} className="preferencesSwiper">
                  <SwiperSlide>
                    {/* slide 1 */}
                    <p className="inline-block text-lg border-b-2 border-[#0188d3]">
                      Cool Blue Energy
                    </p>
                    <div className="flex text-base m-5 justify-center font-normal items-center relative">
                      <div className="left-4">
                        <p className="text-center text-[#0188d3] font-bold  text-sm  mb-7">
                          Body Language
                        </p>
                        <div className="right-sec">
                          <div className="text-left text-sm   border-b-2 border-black pb-12 mb-12">
                            <li className="mb-1  whitespace-nowrap">Detached</li>
                            <li className="mb-1  whitespace-nowrap">No physical</li>
                            <li className="mb-1  whitespace-nowrap">Still</li>
                            <li className="mb-1  whitespace-nowrap">Little facial expression</li>
                          </div>
                          <p className="text-center text-[#0188d3] font-bold  text-sm mb-7">
                            Work environment
                          </p>
                          <div className="text-left text-sm ">
                            <li className="mb-1  whitespace-nowrap">Oragnised</li>
                            <li className="mb-1  whitespace-nowrap">Neat</li>
                            <li className="mb-1  whitespace-nowrap">Information and reference</li>
                            <li className="mb-1  whitespace-nowrap">books at hand</li>
                          </div>
                        </div>
                      </div>

                      <div className="flex text-center  mx-8">
                        <img className="" src={perciption_chapter7_img4} alt="" />
                      </div>
                      <div className="right-sec">
                        <p className="text-center text-[#0188d3] font-bold  text-sm mb-7">
                          Verbal Style
                        </p>
                        <div className="right-sec   border-b-2 border-black pb-12 mb-12">
                          <div className="text-left text-sm ">
                            <li className="mb-1  whitespace-nowrap">Little tonal variation</li>
                            <li className="mb-1  whitespace-nowrap">Questioning </li>
                            <li className="mb-1  whitespace-nowrap">Thoughtful</li>
                            <li className="mb-1  whitespace-nowrap">Quiet</li>
                          </div>
                        </div>
                        <p className="text-center text-[#0188d3] font-bold  text-sm mb-7">
                          Interactions
                        </p>
                        <div className="text-left text-sm ">
                          <li className="mb-1  whitespace-nowrap">Keeps to self</li>
                          <li className="mb-1  whitespace-nowrap">Independent</li>
                          <li className="mb-1  whitespace-nowrap">Analytical</li>
                          <li className="mb-1  whitespace-nowrap">Systematic</li>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {/* slide 2 */}
                    <div className="flex  relative justify-between pb-10 ">
                      <p className=" ">
                        How to communciate with someone with a <br />
                        <span className="text-[#0188d3] font-bold  text-sm">Cool Blue</span>{' '}
                        prefernce
                      </p>
                      <p className=" ">
                        How to connect with someone with a <br />
                        <span className="text-[#0188d3] font-bold  text-sm">Cool Blue</span>{' '}
                        prefernce
                      </p>
                    </div>

                    <div className="flex text-base m-5 justify-center font-normal items-center relative">
                      <div className="flex-1">
                        <p className="text-left text-[#0188d3] font-bold  text-sm mb-7">Do:</p>
                        <div className="right-sec mb-10">
                          <div className="text-left text-sm">
                            <ul className="list-disc pl-5 inline-block">
                              <li className="mb-1  whitespace-pre-wrap">
                                Be well prepared and through
                              </li>
                              <li className="mb-1  whitespace-pre-wrap">Put things in writing</li>
                              <li className="mb-1  whitespace-pre-wrap">
                                Let them consider all the details
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="right-sec">
                          <p className="text-left text-[#0188d3] font-bold  text-sm mb-7">Don’t:</p>
                          <div className="text-left text-sm ">
                            <ul className="list-disc pl-5 inline-block">
                              <li className="mb-1  whitespace-pre-wrap">
                                Be over-emotional or exaggerate
                              </li>
                              <li className="mb-1  whitespace-pre-wrap">
                                Be careless or casual with important issues
                              </li>
                              <li className="mb-1  whitespace-pre-wrap">
                                Keep changing things without good reasons
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="flex text-center  mx-8">
                        <img className="" src={perciption_chapter7_img4} alt="" />
                      </div>
                      <div className="right-sec flex-1">
                        <div className="text-left text-sm ">
                          <ul className="list-disc pl-5 inline-block">
                            <li className="mb-1  whitespace-pre-wrap">
                              Think before you speak; organize your thoughts first.
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Present your ideas clearly and with structure.
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Tidy things up. Organize your documents so you can easliy find what
                              you need.
                            </li>
                            <li className="mb-1 whitespace-pre-wrap">
                              Quiet your body and slow your pace. Manintain a more formal posture.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </CustomTabPanel>
            </>
          )}
        </CustomAudioTabsBlock>
        {_.isNil(energyTabValue) && (
          <MediaBlock mediaImage={color_energies} cssClass="!mt-3"></MediaBlock>
        )}
        <p className="text-2xl mt-7">
          Remember: It's not about being something you are not. Rather, it's all in the small
          changes – dialing up or down your dominant energy by a little will do wonders for
          improving your ability to connect.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">Adapting & Connecting – Do</h3>
        {/* drag drop */}
        <DndProvider backend={HTML5Backend}>
          <MatchingQuestionBlock
            questionData={courseMatchingQuestion[0] as CourseMatchingQuestion}
            submitedAnswer={
              _.flatMap(
                userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[
                  chapter.id
                ]?.matchingQuestions?.filter((x) =>
                  Object.keys(x).filter((key) => key === courseMatchingQuestion[0].id),
                ),
                _.values,
              )[0]
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetake={handleMatchingRetake}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            submitMatchingQuestion={handleMatchingExcerciseCompleted}
          />
        </DndProvider>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">
          Adapting & Connecting – Don’t
        </h3>
        {/* drag drop */}
        <DndProvider backend={HTML5Backend}>
          <MatchingQuestionBlock
            questionData={courseMatchingQuestion[1] as CourseMatchingQuestion}
            submitedAnswer={
              _.flatMap(
                userCourseQuestionResponseContext[courseMatchingQuestion[1].course_id]?.chapters[
                  chapter.id
                ]?.matchingQuestions?.filter((x) =>
                  Object.keys(x).filter((key) => key === courseMatchingQuestion[1].id),
                ),
                _.values,
              )[1]
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetake={handleMatchingRetake}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            submitMatchingQuestion={handleMatchingExcerciseCompleted}
          />
        </DndProvider>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">Embracing Opposites</h3>
        <p className="text-2xl mb-7">It is all about flexing and connecting.</p>
        <p>Click on each marker to learn some helpful tips on how to embrace opposites.</p>
        <div className="mt-8">
          <div className="w-[635px] mx-auto relative">
            <img className="w-full" src={EmbracingOpposites} alt="" />
            <div className="absolute top-[10%] left-0">
              <ToggleCard
                position="top-left"
                isOpen={openCardPosition === 'top-left'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#388dbb] mb-8">COOL BLUE</p>
                    <p>
                      Those that lead with Cool Blue need to connect with Sunshine Yellow with
                      Passion.
                    </p>
                  </div>
                }
              />
            </div>
            <div className="absolute top-[10%] right-0">
              <ToggleCard
                position="top-right"
                isOpen={openCardPosition === 'top-right'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#d3473c] mb-8">FIERY RED</p>
                    <p>
                      Those of us that lead with Fiery Red, need to connect with Earth Green with
                      Grace.
                    </p>
                  </div>
                }
              />
            </div>
            <div className="absolute bottom-[10%] left-0">
              <ToggleCard
                position="bottom-left"
                isOpen={openCardPosition === 'bottom-left'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#8ba40a] mb-8">EARTH GREEN</p>
                    <p>
                      Those that lead with Earth Green need to connect with Fiery Red with Power.{' '}
                    </p>
                  </div>
                }
              />
            </div>
            <div className="absolute bottom-[10%] right-0">
              <ToggleCard
                position="bottom-right"
                isOpen={openCardPosition === 'bottom-right'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#fbc600] mb-8">SUNSHINE YELLOW</p>
                    <p>If you lead with Sunshine Yellow, connect with Cool Blue with Focus. </p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="w-full btn-primary uppercase mt-8"
        >
          SUMMARY
        </button>
      </div>
    </>
  );
};
