import { useDrag } from 'react-dnd';

import dragIcon from '../../../assets/icons/drag.svg';
import { MatchingQuestionBase } from '../../../courses/jumpstart-communicator/jumpstart-communicator.types';

interface QuestionCardProps {
  item: MatchingQuestionBase;
  onDrag: (item: MatchingQuestionBase) => void;
}
export const MatchingAnswerCard: React.FC<QuestionCardProps> = ({ item, onDrag }) => {
  const [, drag] = useDrag({
    type: 'MATCHINGQUESTION',
    item: { type: 'MATCHINGQUESTION', item },
    end: (_, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        onDrag(item);
      }
    },
  });
  return (
    <>
      <div
        ref={drag}
        onDragStart={() => onDrag(item)}
        className={`cursor-grab left-shape ${item.matched ? 'attached' : ''}`}
      >
        <div className="left-content flex items-center border-l-4 border-purple-600 font-semibold">
          <img src={dragIcon} className="ml-4 mr-6" alt="" />
          <span className="mr-3">{item.answer?.option}</span>
        </div>
      </div>
    </>
  );
};
