import AddIcon from '@mui/icons-material/Add';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { CourseChapter } from '../../courses/courses.types';
import { CourseBasicDetails } from '../../courses/perception-understanding/perciption-understanding-questions';
import { useUserCourseQuestionResponseContext } from '../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../redux/store';

interface CustomAccordionProps {
  id: string;
  title: string;
  content: string;
  chapter?: CourseChapter;
  course?: CourseBasicDetails;
  onChange?: (data: string) => void;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<AddIcon sx={{ fontSize: '20px' }} />} {...props} />
))(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const CustomQuestionAccordion: React.FC<CustomAccordionProps> = ({
  id,
  title,
  content,
  chapter,
  course,
  onChange,
}) => {
  const [showMessage, setShowMessage] = React.useState(false);
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();

  const [data, setData] = React.useState('');
  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const dispatch = useAppDispatch();
  const handleAnswerSubmit = async () => {
    if (data.trim()) {
      const questionAnswer = {
        question_id: id,
        question: content,
        answer: data,
      };

      if (chapter && course) {
        const userAnswerResponse =
          userCourseQuestionResponseContext[course.course_id]?.chapters[chapter.id]?.questions ||
          [];
        const userMatchingAnswerResponse =
          userCourseQuestionResponseContext[course.course_id]?.chapters[chapter.id]
            ?.matchingQuestions || [];
        const userSortingAnswerResponse =
          userCourseQuestionResponseContext[course.course_id]?.chapters[chapter.id]
            ?.matchingQuestions || [];
        await dispatch(
          dispatchUpdateUserCourseQuestionResponse({
            [course?.course_id]: {
              chapters: {
                [chapter.id]: {
                  questions: [
                    ...userAnswerResponse.filter((x) => x.question_id !== id),
                    questionAnswer,
                  ],
                  matchingQuestions: [...userMatchingAnswerResponse],
                  sortingQuestions: [...userSortingAnswerResponse],
                  quiz: null,
                },
              },
            },
          }),
        );
      }
    }
    showMessageAndAutoHide();
  };

  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData(event.target.value);
    onChange && onChange(event.target.value);
  };
  const showMessageAndAutoHide = () => {
    setShowMessage(true);

    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };
  React.useEffect(() => {
    if (chapter && course) {
      const userAnswerResponse: UserChapterQuestion[] =
        userCourseQuestionResponseContext[course.course_id]?.chapters[
          chapter.id
        ]?.questions?.filter((answer) => answer.question_id === id) || [];

      const submitedAnswer = userAnswerResponse[userAnswerResponse.length - 1];
      if (submitedAnswer) {
        const formattedAnswers = submitedAnswer.answer || '';
        setData(formattedAnswers);
      }
    }
  }, [userCourseQuestionResponseContext]);
  return (
    <div>
      <Accordion expanded={expanded === id} onChange={handleChange(id)}>
        <AccordionSummary
          expandIcon={expanded === id ? <MinimizeIcon /> : <AddIcon />}
          id={`${id}d-header`}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </Typography>
          <div className="pl-[3.5rem]">
            <textarea
              value={data}
              className="w-full bg-white rounded-2xl my-5 text-base p-5"
              onChange={(event) => {
                textAreaChange(event);
              }}
              rows={3}
              placeholder="Type your text here"
            ></textarea>
            <div className="text-center">
              {showMessage && (
                <Typography className="message">There is no right or wrong answer.</Typography>
              )}
            </div>
            <div>
              <button
                disabled={!data.trim()}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleAnswerSubmit}
                className="btn-primary text-base"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
