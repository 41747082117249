import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import {
  dispatchGetAsaTaskHistoryValues,
  UserAsaTaskHistory,
} from '../redux/slice/asa-task-history';
import { setOTPAuthAction } from '../redux/slice/auth';
import {
  clearUserTempData,
  dispatchLogin,
  dispatchVerifyLogin,
} from '../redux/slice/auth/auth.operations';
import { LoginDTO, UserRole } from '../redux/slice/auth/auth.types';
import { CustomUser } from '../redux/slice/auth/CustomUser';
import { dispatchOtpEmail } from '../redux/slice/form-otp-validation/form-otp-validation.operations';
import { useAppDispatch } from '../redux/store';
import { isOtpVerified, sendOtpToEmail } from '../services/database/mfa-auth';
import { getUserProfileValues } from '../services/database/user-profile';
import logger from '../services/logger';
import { EnvironmentVariable } from '../utils/constants';

import { CircularLoader } from './loader/CircularLoader';
import OtpPopup from './Otp/OtpPopup';
import { ForgetPasswordPopup } from './Popup/ForgetPasswordPopup';

export const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams: URLSearchParams = new URLSearchParams(location.search);
  const organization: string | undefined =
    queryParams.get('organization') ?? Cookies.get('organization');
  const disablePersonalInfo: string | undefined =
    queryParams.get('disablePersonalInfo') ?? Cookies.get('disablePersonalInfo');
  const LoginSchema = yup
    .object({
      email: yup.string().email('Invalid email address').required(),
      password: yup.string().required(),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(LoginSchema),
  });

  interface IFormInput {
    email: string;
    password: string;
  }
  const handleLogin: SubmitHandler<IFormInput> = async (dto: LoginDTO): Promise<void> => {
    setOpen(false);
    setIsLoading(true);
    logger.debug('Submitted form to login.', dto);

    try {
      const result: CustomUser = await dispatch(dispatchLogin(dto));
      if (result && result?.user?.email) {
        const userProfile = await getUserProfileValues();

        if (userProfile.isMFAEnabled) {
          const otp = await sendOtpToEmail();
          const otpExpTime = process.env[EnvironmentVariable.REACT_APP_OTP_EXP_TIME];
          await dispatchOtpEmail({
            To: result?.user?.email,
            otp: otp,
            validUntil: Number(otpExpTime) || 15,
          });
          setOpen(true);
        } else {
          dispatch(setOTPAuthAction());
          await isOtpVerified(true);

          await dispatch(dispatchVerifyLogin(result));
          await dispatch(clearUserTempData());
          if (result?.claims.isAsaUser) {
            // const asaParamObj = asaParams && JSON.parse(asaParams);
            const taskHistory: UserAsaTaskHistory | undefined = await dispatch(
              dispatchGetAsaTaskHistoryValues(),
            );

            if (taskHistory) {
              //:TODO redirect to payment page
              const currentTask = taskHistory.tasks.find((x) => !x.isCompleted);
              window.location.href = currentTask?.taskUrl
                ? currentTask?.taskUrl
                : userProfile?.isPremium
                ? '/dashboard'
                : '/upgrade';
            }
          } else if (result?.claims.role === UserRole.ORGANIZATION_ADMIN) {
            toast.success('Login Success!');
            navigate('/organization-dashboard');
          } else {
            const previousUrl = sessionStorage.getItem('previousUrl');
            if (previousUrl) {
              sessionStorage.removeItem('previousUrl');
              navigate(previousUrl);
            } else {
              toast.success('Welcome back!');
              navigate('/pre-employment/start');
            }
          }
        }
      } else {
        toast.error('Incorrect login details.');
      }
    } catch (e) {
      logger.error('Failed to login.', e);
      toast.error('Incorrect login details.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (organization) {
      Cookies.set('organization', organization, { path: '/' });
    }
  }, [organization]);

  useEffect(() => {
    if (disablePersonalInfo !== undefined && Cookies.get('disablePersonalInfo') === undefined) {
      Cookies.set('disablePersonalInfo', 'true');
    }
  }, [disablePersonalInfo]);

  return (
    <div className="relative flex items-center justify-center w-full">
      {isLoading && <CircularLoader />}
      <OtpPopup isOtpSent={open} />
      <div
        className={`mx-auto w-full max-w-lg bg-white-100 rounded-xl px-5 py-10 lg:p-10 border border-black/10 ${
          isLoading ? 'blur' : ''
        }`}
      >
        <h2 className="text-center text-2xl font-bold leading-tight mb-4">
          Sign in to your account
        </h2>
        <p className="mb-8 text-center text-base text-black/60">
          Don&apos;t have any account?&nbsp;
          <Link
            to="/signup"
            className="text-purple-700 font-medium text-primary transition-all duration-200 hover:underline"
          >
            Sign Up
          </Link>
        </p>
        <div className="space-y-5">
          <Box
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(handleLogin)}
            component="form"
            sx={{
              '& > :not(style)': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl margin="normal">
              <TextField
                id="email"
                type="email"
                {...register('email')}
                label="Email"
                variant="outlined"
              />
              <p className="text-red-500">{errors.email?.message}</p>
            </FormControl>
            <FormControl margin="normal">
              <TextField
                id="password"
                type="password"
                {...register('password')}
                label="Password"
                variant="outlined"
              />
              <p className="text-red-500">{errors.password?.message}</p>
            </FormControl>
            <FormControl margin="normal">
              <button type="submit" className="btn-primary w-full" disabled={isLoading}>
                Sign In
              </button>
            </FormControl>
          </Box>
          <div className="text-center">
            <ForgetPasswordPopup
              openPopup={forgotPasswordOpen}
              onClose={() => setForgotPasswordOpen(false)}
            />
            <Button
              variant="text"
              sx={{
                color: '#962cc6',
                '&:hover': {
                  backgroundColor: '#ffffff',
                },
              }}
              onClick={() => setForgotPasswordOpen(true)}
            >
              Forgot Password?
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
