import { useEffect, useState } from 'react';

import WilliamStrunkJr from '../../../../assets/images/courses/communication-basics/chapter11/WilliamStrunkJr.jpg';
import { course_questions as courseQuestions } from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { MediaBlock } from '../../MediaBlock';
import { TabsBlock } from '../../TabsBlock';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number, chapterQuestionAnswers?: UserChapterQuestion[]) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const CommunicationBasicsChapter11: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const TabsData = [
    {
      title: 'Broad introduction: ',
      htmlContent: `Use your introduction to tell your audience your purpose—or why you're writing the document. Don’t leave your readers with any surprises; if there’s something you plan on saying later, be sure to mention it briefly in the introduction. Your introduction is like an outline: You don’t need to give all the details, but your readers should get a clear idea of what’s coming next.`,
    },
    {
      title: 'Narrow body paragraphs:  ',
      htmlContent: `This is your chance to give details supporting the main outline you provided in the introduction. To keep your sections narrow, limit yourself to one argument per paragraph. Then, offer your reader a few proof points (pieces of evidence that will help support your claim). Be specific. Can you provide any examples, data, or evidence? Think through the content and remember to avoid any information that doesn’t directly relate to your purpose.`,
    },
    {
      title: 'Broad conclusion: ',
      htmlContent: `Use your conclusion to wrap up everything neatly. Take this opportunity to summarize those details you mentioned in the introduction. Do the legwork for your reader and connect the dots. If you’re unsure if a line fits, try adding: “in conclusion” to the front. If your sentence doesn’t work with this addition, then it doesn’t belong in the conclusion. `,
    },
  ];
  const accordionData = [
    {
      id: 'panel1',
      title: 'Introduction',
      content:
        '<div class="fr-view"><p><strong>Broadly introduce your topic. </strong>Your first sentence is like a handshake hello. Similar to an in-person greeting, you don’t want to give away all the details just yet. Start by welcoming your reader to the topic and letting them get comfortable with this subject.</p><p><strong>&nbsp;</strong></p><p><strong>Explain your supporting points.</strong> In the next few sentences, give your readers a brief overview of what you’ll include in each body paragraph. This is your chance to lay out your supporting arguments and give your reader a clear road map of what to expect.&nbsp;</p><p><strong><br></strong></p><p><strong>Broadly state your purpose. </strong>After a successful lead-in, now it’s time to share your purpose. This is your thesis—or main argument—and it’s central to your document’s structure. Think through how you write this last sentence; all subsequent sentences should link back to this one idea.</p></div>',
    },
    {
      id: 'panel2',
      title: 'Body Paragraphs',
      content:
        '<div class="fr-view"><p><strong>Broadly introduce one idea.&nbsp;</strong>Remember the ideas you outlined in the introduction? Now its time to discuss them. In the first sentence of each body paragraph, broadly introduce one idea.</p><p><br></p><p><strong>Give supporting details.&nbsp;</strong>Use the next few sentences to elaborate on that one idea by giving your reader a few proof points, or pieces of evidence. These unbiased details will help to support your argument. What details does your reader need to know? Can you give specific facts or examples?</p><p>&nbsp;</p><p><strong>Broadly summarize and connect to the next paragraph.</strong> Your last sentence should sum up what you’ve shared and link the information to the following paragraph. Ask yourself: “<em>What’s the next thing my reader needs to know?</em>” By connecting your sections in this way, you’ll help your readers understand how your information relates.</p></div>',
    },
    {
      id: 'panel3',
      title: 'Conclusion',
      content:
        '<div class="fr-view"><p><strong>Broadly restate your argument. </strong>Now it’s time to wrap everything up. Start your conclusion by summarizing your overall purpose and what you’ve shared with your readers.</p><p>&nbsp;</p><p><strong>Review your main ideas. </strong>Use the next few sentences to review each of your supporting points—connecting the arguments and explaining how they further back up your purpose.</p><p>&nbsp;</p><p><strong>Broadly make a concluding statement. </strong>Your last sentence is the one that sticks with your reader. Keep these final words strong and resolute. Consider broadly explaining why your document is significant or summarizing how your reader can use this information for their benefit.</p></div>',
    },
  ];
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);

  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">How to Structure Your Writing</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>

        <p className="text-2xl text-center leading-10 mt-16 font-light mb-16">
          Deciding how to structure your writing isn’t always easy. When looking at a blank page,
          that blinking cursor can feel intimidating to even the most skilled business writers. You
          might have a few questions:{' '}
          <span className="font-bold italic">
            Where do I begin? What do I say in the middle? How do I conclude?
          </span>
          <br />
          <br />
          Here’s a simple trick: Begin with your general concept, give your details in the middle,
          and conclude with one last statement that brings it all together. This is called a
          broad-narrow-broad approach, and it’s a great way to keep your writing
          <span className="font-bold"> logical, organized, </span>
          and <span className="font-bold">easy to follow.</span>
        </p>
        <p className="mb-8">
          In this lesson, we’ll explain exactly how the broad-narrow-broad approach works. You’ll
          learn the benefits of keeping your writing structured, and how to lay out your
          introduction, body paragraphs, and conclusion most effectively.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/AFXfa8jcN_ZH5f74wUC4NcBGgrxm353L/svVmBcGK/assets/rbc/PVihYqAB8c5ZGsdc_COMM-WW-04.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          The Benefits of Structuring Your Writing
        </h3>
        <p className="mb-8">
          Structuring your writing is about building a framework. Piece by piece, it’s the way you
          lay out your sections so that your ideas flow smoothly and your content is clear. Review
          the list below to learn three key benefits of building a strong writing structure.
        </p>
        <ul className="list-decimal pl-7 mt-8 mb-10 text-xl">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">It’s logical.</span> When your writing follows a structure,
            your readers receive information in an order that makes logical sense. Your work has a
            clear, understandable purpose and each supporting point relates back to that purpose
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">It’s organized.</span> A well-structured piece of writing is
            orderly. Related ideas are connected, flowing seamlessly from one to the next. Plus,
            because you’ve taken the time to build this framework, you’ll likely avoid inconsistent
            sentences and incomplete or divided arguments.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">It’s easy to follow.</span> When your work is
            well-structured, it becomes easier for your reader to understand the material. Your
            argument follows an expected path and no sentence seems to “come out of nowhere.” Rather
            than expecting your reader to wade through disconnected thoughts, your writing is easy
            to follow and understand.
          </li>
        </ul>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          How to Use a Broad-Narrow-Broad Structure
        </h3>
        <p className="mb-8">
          So, how do you create a well-structured piece of writing that’s logical, organized, and
          easy to follow? It starts with using a broad-narrow-broad framework. This format allows
          you to summarize your purpose at the beginning, provide details in the middle, and neatly
          wrap up your argument at the end. Let’s take a closer look at how this approach works when
          structuring your introduction, body paragraphs, and conclusion. Click each tab to learn
          more.
        </p>
        <TabsBlock tabsData={TabsData} />
        <MediaBlock mediaImage={WilliamStrunkJr}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            As a rule, begin each paragraph with a topic sentence; end it in conformity with the
            beginning.
            <br />
            <span className="text-base">William Strunk Jr.</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">Laying Out Your Sentences</h3>
        <p className="mb-8">
          Now that you have the content prepared for your introduction, body paragraphs, and
          conclusion, let’s take a look at how you can use the broad-narrow-broad framework to lay
          out the sentences in these paragraphs. Expand the rows below by clicking the (+) signs to
          learn more.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          How Should You Structure Your Conclusion?
        </h3>
        <CourseQuestionCard
          submittedAnswer={userCourseQuestionResponseContext[
            chapterQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterQuestions[0]}
        />

        <p className="text-2xl text-center leading-10 font-light mb-10 mt-10">
          Well-structured writing is important, but if it's not accompanied with correct grammar,
          spelling, and punctuation, your message may still be lost. Let's dig into these concepts
          in the next lesson.
        </p>

        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full uppercase"
        >
          How to Improve Your Grammar, Spelling, and Punctuation
        </button>
      </div>
    </>
  );
};
