import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import { NewsLetterDTO } from './form-newsletter.types';

export type NewsletterFormState = Partial<NewsLetterDTO>;

const initialState: NewsletterFormState = {
  email: undefined,
};

const newsletterFormSlice = createSlice({
  name: 'formNewsletter',
  initialState,
  reducers: {
    updateNewsletterSignup: (state, action: PayloadAction<NewsLetterDTO>) => {
      logger.debug('Updating newsletter form state.', {
        state,
        action,
      });
      Object.assign(state, { ...action.payload });
    },
  },
});

export const { updateNewsletterSignup: updateNewsletterSignupAction } = newsletterFormSlice.actions;
export default newsletterFormSlice.reducer;
