import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { SWOTReport } from '../../redux/slice/swot/swot.types';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.SWOT_REPORTS;

/**
 * upsert a record into the swot report responses collection
 */
async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

/**
 * retrieves the values of the previously saved swot report if available
 */
export async function getSWOTReportValues(): Promise<SWOTReport | undefined> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<SWOTReport> = (await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    )) as DocumentSnapshot<SWOTReport>;
    return result.data() ?? undefined;
  } catch (e) {
    logger.error('Failed to retrieve swot report values.', e);
    throw e;
  }
}
export async function getSWOTReportValuesForDownload(
  userId: string,
): Promise<SWOTReport | undefined> {
  try {
    const recordId: string = userId;
    const result: DocumentSnapshot<SWOTReport> = (await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    )) as DocumentSnapshot<SWOTReport>;
    return result.data() ?? undefined;
  } catch (e) {
    logger.error('Failed to retrieve swot report values.', e);
    throw e;
  }
}

/**
 * updates the swot report assessment
 */
export async function saveSWOTReport(dto: SWOTReport): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved swot report.');
  } catch (e) {
    logger.error('Failed to update swot report.', e);
    throw e;
  }
}
