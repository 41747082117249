import chat_background from '../../../../assets/images/courses/communication-basics/chapter3/cort/chat_background.jpeg';
import chat_character from '../../../../assets/images/courses/communication-basics/chapter3/cort/chat_character.png';
import NatalieMassenet from '../../../../assets/images/courses/communication-basics/chapter3/NatalieMassenet.jpg';
import Openyourposture from '../../../../assets/images/courses/communication-basics/chapter3/Openyourposture.jpg';
import Putawaythedistraction from '../../../../assets/images/courses/communication-basics/chapter3/Putawaythedistraction.jpg';
import Smileandbefriendly from '../../../../assets/images/courses/communication-basics/chapter3/Smileandbefriendly.jpg';
import StartWithaHandshake from '../../../../assets/images/courses/communication-basics/chapter3/StartWithaHandshake.jpg';
import { course_chat_questions as CourseChatQuestions } from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import { CustomAccordion } from '../../../common/Accordion';
import { ActionableBlock } from '../../ActionableBlock';
import { MediaBlock } from '../../MediaBlock';
import { TabsBlock } from '../../TabsBlock';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const CommunicationBasicsChapter3: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const courseChatQuestions = CourseChatQuestions.filter((obj) => obj.chapter_id === chapter.id);
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const communicatorsTabData = [
    {
      title: 'Start With a Handshake',
      mediaImage: StartWithaHandshake,
      htmlContent: `<span class="font-bold">Start with a handshake.</span> Start with physically addressing the person' perhaps - handshake, fist bump, or smile -- physically engage with whomever you are meeting. That simple gesture communicates friendliness, sincerity, and confidence. If using a handshake, make sure you're using the right grip: firm, but not aggressive.`,
    },
    {
      title: 'Smile and be friendly',
      mediaImage: Smileandbefriendly,
      htmlContent: `<span class="font-bold">Smile and be friendly. </span>Would you rather speak with someone who’s smiling or someone who looks disinterested? A friendly disposition and warm smile communicate positivity, trust, and sincerity. A smile is memorable and powerful, and it helps put both you and your listener at ease.
      
      `,
    },
    {
      title: 'Open your posture',
      mediaImage: Openyourposture,
      htmlContent: `<span class="font-bold">Open your posture. </span>Our body language reveals a lot about our personalities, emotions, and intentions. Face the other person, avoid crossing your arms, and stand (or sit) up straight. It’s much easier to start a conversation when you appear open and engaged. `,
    },
    {
      title: 'Put away the distraction',
      mediaImage: Putawaythedistraction,
      htmlContent: `<span class="font-bold">Put away the distractions.  </span>Before you walk up to this person, minimize the things that can undermine your focus. Turn off your phone, avoid looking around the room, and don’t get sidetracked by discussions happening around you. If you’re distracted, your receiver will likely notice and feel offended. Be courteous by offering this person your full, undivided attention.`,
    },
  ];
  const accordionData = [
    {
      id: 'panel1',
      title: 'Compliment the Listener',
      content:
        'Everyone enjoys a compliment, especially when it’s about their accomplishments or talents. What do you know about this person? Is there anything you find impressive? If so, let the person know and follow up with a question to get them talking.<br /><br />For example, you might say: “I loved reading your recent article on the modern workplace. Have you written similar pieces before?”<br /><br />If you know nothing about the person, compliment them on something you see, such as their taste in clothing or accessories. You could say: “What a beautiful necklace! Is there a story behind it?” or “That’s a great jacket! Where did you get it?” But avoid focusing on physical appearances, as these comments can be viewed as unprofessional.',
    },
    {
      id: 'panel2',
      title: 'Find Common Interests',
      content:
        'Do you have anything in common with the person? If so, that’s a great way to kick off the discussion. For example, you might say: “I have an English degree as well! How did you end up in finance?”<br /><br />If the person is a stranger, find common ground with a few generic questions. You could ask: “What are your hobbies outside of work?” or “I just hate leaving my dog alone! Do you have any pets?”',
    },
    {
      id: 'panel3',
      title: 'Talk About the Weather',
      content:
        'Talking about the weather is a tried-and-true conversation starter because it helps you bond over a shared experience.<br /><br />Your listener will likely have an opinion to offer if you ask, “Will this snow ever stop?” They might respond with: “I know! Every time I shovel the driveway, it snows again.” The next thing you know, you’re talking about memories of playing in the snow as children, your favorite weather, and a mutual dream of moving to a tropical paradise.    ',
    },
    {
      id: 'panel4',
      title: 'Talk About Where You Are',
      content:
        'Take a look around you. Is there anything you can comment on? Whether it’s the view outside, the food, the event, or a nearby painting on the wall, there’s always something you can talk about.<br /><br />For example, you might say: “This is such a beautiful building. Have you been here before?” or “What did you think about today’s speaker?”',
    },
    {
      id: 'panel5',
      title: 'Ask for Help',
      content:
        'A request for advice can make someone feel important and valued. When asking for help, think of topics that might lead to an extended conversation.<br /><br />For instance, you might say: “I have no idea what to do for lunch today. Do you have a favorite restaurant in the area?” Such questions will encourage the other person to contribute, open up, and maybe even share one of their favorite stories. ',
    },
  ];
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Starting a Conversation</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-7">
          Whether you’re speaking with a boss, a coworker, a potential customer, or someone else you
          admire and respect, striking up a conversation can be nerve-wracking. We often wonder:
          <span className="font-bold italic"> “What do I say first?”</span> and{' '}
          <span className="font-bold italic">“How do I make sure this doesn’t fall flat?”</span>
          <br />
          <br />
          Conversations are the building blocks of professional relationships. But even though we
          know they’re important, we’re often reluctant to break the ice. We fear the awkward
          silences, and so, we stay silent.
        </p>
        <p>
          In this lesson, we’ll reveal how starting a great conversation doesn’t need to be
          daunting. First, you’ll learn how to make the right first impression and why it’s
          important to keep the focus on your listener. Then, we’ll give you a few go-to
          conversation starters you can use when you’re at a loss for words.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/AFXfa8jcN_ZH5f74wUC4NcBGgrxm353L/svVmBcGK/assets/rbc/tfq0GERmjVz2mIOE_COMM-VC-02.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          How to Make the Right First Impression
        </h3>
        <p className="mb-8">
          It only takes a few seconds for us to develop an opinion about someone. When starting a
          conversation, you need to make the right first impression. Here are a few tips:
        </p>
        <TabsBlock tabsData={communicatorsTabData} />
        <MediaBlock mediaImage={NatalieMassenet}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            Never forget that you only have one opportunity to make a first impression.
            <br />
            <span className="text-base">Natalie Massenet</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">Focus on the Other Person</h3>
        <p>
          No matter how you choose to start a conversation, keep the focus on the other person. Your
          goal is to engage rather than impress. If you’re only talking to boast how clever,
          interesting, or funny you are, you may come off as self-absorbed.
          <br />
          <br />
          Instead, ask yourself: How can I bring this person out of their shell? How can I get them
          to start talking? Approach the conversation with the other person in mind, and make them
          feel like you care and value their opinions. Show your sincere interest, and the other
          person will be encouraged to respond in kind.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          Five Go-To Conversation Starters
        </h3>
        <p className="mb-7">
          So, what’s the best way to break the ice? Expand the rows below by clicking the (+) signs
          to learn about five great conversation starters.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <ActionableBlock
          backgroundImage={chat_background}
          courseChatQuestions={courseChatQuestions}
          characterImage={chat_character}
          feedbackTitle="Focus on the Other Person"
          feedbackText="When starting a conversation, focus on the other person. Don’t put the spotlight on yourself or immediately jump to your end goal. Instead, build the relationship naturally."
          buttonName="Continue"
        />
        <p className="text-2xl text-center leading-10 font-light mb-10">
          To be an excellent conversationalist, you also need to be a great listener. In the next
          lesson, we'll dig into how to listen effectively.
        </p>
        <button onClick={handleNextChapterClick} className="btn-primary w-full">
          STRATEGIES FOR EFFECTIVE LISTENING
        </button>
      </div>
    </>
  );
};
