import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import BrainTracy from '../../../../assets/images/courses/communication-basics/chapter2/BrainTracy.jpeg';
import {
  course_questions as courseQuestions,
  course_sorting_questions as sortingQuestions,
} from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { MediaBlock } from '../../MediaBlock';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';
interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const CommunicationBasicsChapter2: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const dispatch = useAppDispatch();
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const chapterSortingQuestions = sortingQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              questions: [...userAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingRetake = async (questionId: string) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              questions: [...userAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
              sortingQuestions: [...userSortingAnswerResponse],
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userAnswerResponse?.length &&
      userSortingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  const accordionData = [
    {
      id: 'panel1',
      title: 'Conversations',
      content:
        'A verbal conversation is an exchange between two or more people. When participating in a conversation, you’ll split your time between speaking and listening. These discussions can be spontaneous or planned, formal or informal, and occur between many different people and groups. In business, a coherent conversation can help you address a problem, gain knowledge, and build closer connections with others.',
    },
    {
      id: 'panel2',
      title: 'Meetings',
      content:
        'Business meetings are typically scheduled in advance and follow an agenda. Often, an organizer will run a meeting and lead the discussion by sharing information or asking questions. Meetings are great for brainstorming ideas, chatting about upcoming projects, and sharing personal progress and goals. Much like a conversation, a meeting will require careful listening and providing input.',
    },
    {
      id: 'panel3',
      title: 'Presentations',
      content:
        'A presentation is a form of public speaking where one or more people will speak to a group, sharing a strategy or vision, offering instructions or information, or pitching an idea or product. Presentations tend to include visual elements, such as on-screen slides, to help inform and keep audience members engaged. Presentations work best when the speaker communicates clearly, prepares adequately, and allows time for listeners to ask questions.',
    },
  ];
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Improving Your Verbal Communication Skills</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-20">
          Verbal communication is spoken communication. Whether you’re having a conversation,
          participating in a staff meeting, or running a presentation, effective verbal
          communication skills play a central role in bringing both people and ideas together.
        </p>
        <p>
          In this lesson, you'll learn about the importance of verbal communication, how it’s used
          in the workplace, and how to improve your verbal communication skills.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/AFXfa8jcN_ZH5f74wUC4NcBGgrxm353L/bFyiWqvY/assets/rbc/UEdSIObzuAsDa5MQ_COMM-VC-01.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          Why Is Verbal Communication Important?
        </h3>
        <p>
          We use verbal communication to share information, ideas, thoughts, and opinions through
          speech. It’s both how we deliver messages and how we receive them. It’s speaking, and it’s
          listening.
        </p>
        <p>
          <br />
        </p>
        <p>
          Regardless of your profession or industry, your spoken words and listening skills have
          power. Maybe you’re righting a wrong with an empathetic “I’m sorry,” deciphering
          directions, sharing your latest project idea, or negotiating a business deal. In each
          case, verbal communication is a core component of business success. Through productive
          communication, you can refine ideas, remove frustrations, and create stronger
          relationships.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          How Is Verbal Communication Used in the Workplace?
        </h3>
        <p className="mb-9">
          In a work setting, we use verbal communication to interact with individuals and groups in
          many situations. Expand the rows below by clicking the (+) signs to learn how we use
          verbal communication in the workplace.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <MediaBlock mediaImage={BrainTracy}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            Communication is a skill that you can learn. It’s like riding a bicycle or typing. If
            you’re willing to work at it, you can rapidly improve the quality of every part of your
            life.
            <br />
            <span className="text-base">Brian Tracy</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          How to Improve Your Verbal Communication Skills
        </h3>
        <p>
          When your verbal communication skills are strong, you’re more likely to share a message
          effectively and listen actively. Here are five tips to improve your verbal communication:
        </p>
        <ul className="list-decimal pl-7 mt-8 mb-14 text-xl font-light">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Think before you talk. </span> While it might be tempting to
            just “start talking,” the best speakers plan out their messages. When you consider your
            words ahead of time, you’re less likely to say the wrong thing or deliver a confusing
            message. If you anticipate questions, think through what those questions might be so you
            can prepare and phrase your message appropriately.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Speak clearly. </span>Speaking clearly is about volume,
            pace, and pronunciation. If you’re too loud, your message may come off as aggressive. If
            you’re too quiet, your listeners could struggle to hear you. Speak fast enough so that
            you don’t bore your listener, but also slowly enough so that your audience can process
            the words. Avoid misinterpretations by pronouncing words fully.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Use the right tone. </span>Your tone will reveal a lot about
            your message—either reinforcing or discrediting your meaning. Whether you’re feeling
            nervous, angry, sad, excited, or any other emotion, ask yourself if this is something
            you want your listeners to hear. If not, take a few deep breaths. Control your tone so
            that you can control your message.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Remember your audience. </span>Depending on your audience,
            you’ll need to change how you share your message. Are you speaking with someone who
            already understands the topic or is new to the material? Does your listener agree with
            the message, or do you need to convince and persuade them? You should change your
            vocabulary and information depending on how you answer those questions.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Check in with your listener. </span>Verbal communication is
            about listening just as much as it’s about speaking. Once you’ve finished sharing your
            message, ask your listener if they have questions or feedback. Give them the floor.
            Consider their responses. Your goal is to make sure your listener understands the
            message the same way you do.
          </li>
        </ul>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Increase Your Chances of Success
        </h3>
        <CourseQuestionCard
          submittedAnswer={userCourseQuestionResponseContext[
            chapterQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterQuestions[0]}
        />
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">Sorting Activity</h3>
        <DndProvider backend={HTML5Backend}>
          <CourseSortingQuestionBlock
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetake={handleSortingRetake}
            question={chapterSortingQuestions[0]}
            submitedCorrectAnswerCount={
              userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[
                chapter.id
              ]?.sortingQuestions
                ?.filter((x) =>
                  Object.keys(x).filter((key) => key === chapterSortingQuestions[0].id),
                )
                .map(
                  (x) =>
                    x[chapterSortingQuestions[0].id].filter(
                      (x) => x.answer?.id === x.correct_answer?.id,
                    ).length,
                )[0] ?? null
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            submitSortingQuestion={handleSortingExcerciseCompleted}
          />
        </DndProvider>

        <p className="text-2xl leading-10 text-center font-light mb-9 mt-20">
          Even the best verbal communicators agree that one of the toughest aspects of verbal
          communication is knowing how to start a conversation. Let's take a closer look at how to
          do that in the next lesson.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          STARTING A CONVERSATION
        </button>
      </div>
    </>
  );
};
