/**
 * how confident a user is in a particular skill
 */
export enum ConfidenceLevel {
  ZERO_CONFIDENCE = 0,
  NOT_CONFIDENT = 1,
  NEUTRAL = 2,
  CONFIDENT = 3,
  VERY_CONFIDENT = 4,
}

/**
 * the fields used in the skills assessment
 */
export type SkillsFields = {
  skillCommunication: ConfidenceLevel;
  skillTeamwork: ConfidenceLevel;
  skillMotivation: ConfidenceLevel;
  skillPlanning: ConfidenceLevel;
  skillProblemSolving: ConfidenceLevel;
  skillProfessionalism: ConfidenceLevel;
  skillCulture: ConfidenceLevel;
  skillTechnology: ConfidenceLevel;
  skillReliability: ConfidenceLevel;
  skillStressTolerance: ConfidenceLevel;
};

/**
 * payload to update skills assessment
 *
 * @todo rename to `UpdateSkillsAssessmentDTO`
 */
export type UpdateSkillsDTO = Partial<SkillsFields>;
