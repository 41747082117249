import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 50,
  borderRadius: 50,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 100 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 50,
    background:
      theme.palette.mode === 'light'
        ? 'linear-gradient(90deg, #6751e3 0%, #9169df 100%)'
        : '#6751e',
  },
}));
interface ProgressProp {
  progress: number;
}
export const OverallProgress: React.FC<ProgressProp> = ({ progress }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={progress} />
    </Box>
  );
};
