interface QuizStartProps {
  description: string;
  btnText?: string;
  pageIndex: number;
  totalPage: number;
  onQuizStart: () => void;
}
export const QuizStart: React.FC<QuizStartProps> = ({
  description,
  btnText,
  pageIndex,
  totalPage,
  onQuizStart,
}) => {
  const handleStartClick = () => {
    onQuizStart();
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-base">
        <h2 className="font-semibold text-3xl">Quiz</h2>
        <p className="text-sm italic">
          Part {pageIndex} of {totalPage}
        </p>
        <h2 className="text-3xl font-normal leading-relaxed mt-28 mb-7 md:w-3/4">{description}</h2>
        <button onClick={handleStartClick} className="btn-primary">
          {btnText || 'START QUIZ'}
        </button>
      </div>
    </>
  );
};
