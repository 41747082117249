import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  AgreementLevelAnswer,
  AgreementLevelQuestion,
  EmploymentReadinessAnswer,
} from '../../assessments/employment-readiness/employment-readiness.types';
import character from '../../assets/images/intake/step10/char.svg';
import { useEmploymentReadinessContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  AgreementLevel,
  dispatchUpdateEmploymentReadiness,
  EmploymentReadinessState,
} from '../../redux/slice/form-employment-readiness';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
interface ComponentProps {
  questions: AgreementLevelQuestion[];
  step: number;
  onBack: () => void;
}

type AnswersState = {
  [questionId: string]: EmploymentReadinessAnswer;
};

export const JobSkillOptions = [
  {
    label: 'Strongly Disagree',
    value: AgreementLevel.STRONGLY_DISAGREE,
  },
  {
    label: 'Disagree',
    value: AgreementLevel.DISAGREE,
  },
  {
    label: 'Neutral',
    value: AgreementLevel.NEUTRAL,
  },
  {
    label: 'Agree',
    value: AgreementLevel.AGREE,
  },
  {
    label: 'Strongly Agree',
    value: AgreementLevel.STRONGLY_AGREE,
  },
];

export function convertValueAgreementLevelToString(
  answer: EmploymentReadinessAnswer,
): 'strongly_disagree' | 'disagree' | 'neutral' | 'agree' | 'strongly_agree' | undefined {
  const typedAnswer: AgreementLevelAnswer = answer as AgreementLevelAnswer;
  switch (typedAnswer?.answer) {
    case undefined:
      return undefined;
    case AgreementLevel.STRONGLY_DISAGREE:
      return 'strongly_disagree';
    case AgreementLevel.DISAGREE:
      return 'disagree';
    case AgreementLevel.NEUTRAL:
      return 'neutral';
    case AgreementLevel.AGREE:
      return 'agree';
    case AgreementLevel.STRONGLY_AGREE:
      return 'strongly_agree';
  }
}

export const JobInterview: React.FC<ComponentProps> = ({ step, questions, onBack }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const navigate = useNavigate();
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
  } = usePreEmploymentAssessmentContext();
  const savedAnswers: EmploymentReadinessState = useEmploymentReadinessContext();
  const [error, setError] = useState('');
  const [answers, setAnswers] = useState<AnswersState>({});
  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);
  function updateAnswer(
    questionId: string,
    updatedAnswer: Partial<EmploymentReadinessAnswer>,
  ): void {
    setError('');
    setAnswers((previousState: AnswersState) => ({
      ...previousState,
      [questionId]: {
        ...answers[questionId],
        ...updatedAnswer,
      },
    }));
  }

  const { handleSubmit } = useForm();
  const backButtonClick = () => {
    onBack();
  };
  const hasMissingAnswers: boolean = questions.some((question: AgreementLevelQuestion): boolean => {
    if (!answers[question.id]) {
      return true;
    }

    return false;
  });

  const submitAssessment = async () => {
    logger.debug('Submitted job interview skills form.', answers);
    setIsLoading(true);
    const error_message: string = 'Please select an option for each question';
    try {
      if (hasMissingAnswers) {
        setError(error_message);
      } else {
        if (isDisabled) {
          navigate(`/pre-employment/employment-readiness/step${step + 1}`);
        } else {
          await dispatch(dispatchUpdateEmploymentReadiness(answers));
          navigate(`/pre-employment/employment-readiness/step${step + 1}`);
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: savedAnswers[questions[0].id] ? progress : totalProgress,
                activeStepUrl: `/pre-employment/employment-readiness/step${step + 1}`,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/employment-readiness/step${step + 1}`,
              }),
            );
          }
        }
      }
    } catch (err) {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (savedAnswers && Object.keys(savedAnswers).length) {
      setAnswers(savedAnswers);
    }
  }, [savedAnswers]);
  return (
    <div>
      <p className="text-lg mt-7 md:px-10">
        Next, let's explore your job interview skills, a crucial aspect of securing a job and making
        a positive impression. You can rate your agreement on a scale from strongly disagree to
        strongly agree.
      </p>
      <p className="text-lg mt-7 md:px-10">
        By exploring these aspects, we'll help you develop the skills and confidence needed to excel
        in interviews, making you a standout candidate in the eyes of potential employers.
      </p>
      <p className="text-lg mt-7 md:px-10">
        You have the flexibility to complete this assessment in one sitting or return later to
        finish the remaining sections. Remember, this journey is all about self-discovery, and we're
        excited to be a part of it with you.
      </p>
      <p className="text-lg mt-7 md:px-10">
        Let’s embark on this path toward self-improvement and career success together!
      </p>
      <div className="flex mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">15</div>
        <div>
          <h4 className="text-2xl">
            I have taken part in or do not know how to perform in a job interview - strongly
            disagree, disagree, neutral, agree and strongly agree
          </h4>
        </div>
      </div>
      <div className="character absolute top-96 right-[-125px] z-[-1]">
        <img className="" src={character} alt="" />
      </div>
      <form
        className=" md:px-16"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitAssessment)}
      >
        <ol className="pl-5" style={{ listStyle: 'upper-latin' }} type="A">
          {questions.map((question: AgreementLevelQuestion) => (
            <li className="mb-5 font-semibold" key={question.id}>
              {question.question}
              <div className="rating-btns flex flex-wrap items-center mt-3">
                {JobSkillOptions.map((option) => (
                  <div key={option.label} className="h-[55px] mr-4 mb-3 lg:mb-0">
                    <label className="radio-container h-full block relative">
                      <input
                        disabled={isDisabled}
                        checked={
                          convertValueAgreementLevelToString(answers[question.id]) === option.value
                        }
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          updateAnswer(question.id, {
                            question: question.question,
                            answer: event.target.value as AgreementLevel,
                          })
                        }
                        value={option.value}
                        className="w-full h-full absolute top-0 left-0 opacity-0"
                        type="radio"
                        name={`interview-${question.id}`}
                      />
                      <span className="checkmark  !static !h-[55px] !w-auto !py-4 !px-5 !text-sm !whitespace-nowrap font-light !text-black">
                        {option.label}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </li>
          ))}
        </ol>
        {error && <p className="text-red-500 font-light !text-sm">{error}</p>}
        <div className="flex justify-end lg:justify-between lg:mt-20 mt-10">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
