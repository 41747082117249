import _ from 'lodash';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { AuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { courses } from '../courses/courses';
import { Course } from '../courses/courses.types';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { useCourseProgressContext } from '../hooks/use-user-course.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import { dispatchGetChapterProgress } from '../redux/slice/user-course';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';

const CoursesList: React.FC = () => {
  const dispatch = useAppDispatch();
  const courseProgressContext = useCourseProgressContext();
  const { organization } = useIntakeFormContext();
  const userProfileContext = useUserProfileContext();
  const coursesByModule = _.groupBy(courses, 'module_id');
  useEffect(() => {
    if (!Object.keys(courseProgressContext).length) {
      void dispatch(dispatchGetChapterProgress());
    } else {
      //enable next module if first is completed
      _.forEach(coursesByModule, (moduleCourses) => {
        let firstEnabledCourse: Course | undefined = undefined;
        _.forEach(moduleCourses, (course, index) => {
          if (!firstEnabledCourse && !course.disabled) {
            firstEnabledCourse = course;
          } else if (course.disabled && firstEnabledCourse && course !== firstEnabledCourse) {
            const progress = courseProgressContext[moduleCourses[index - 1].id]?.progress ?? 0;
            if (progress === 100) {
              course.disabled = false;
            }
          }
        });
      });
    }
  }, [courseProgressContext]);
  useEffect(() => {
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchGetUserProfileValues());
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {courses.length ? (
        courses.map((course: Course) => (
          <Container key={course.id}>
            <div className="jumpstart-wrap grid lg:grid-cols-2 grid-cols-1 rounded-3xl overflow-hidden my-20">
              <div className="content-section p-10 lg:px-16 flex items-center">
                <div>
                  <h1 className="lg:text-5xl text-4xl lg:leading-16 font-bold text-white mb-6">
                    {course.name}
                  </h1>
                  {/* {organization && !course.disabled && ( */}
                  {(organization || userProfileContext?.isPremium) && (
                    <NavLink to={`/course/start/${course.id}`} className="btn-secondary">
                      {`${
                        course.sections.find((section) =>
                          section.chapters.some(
                            (chapter) =>
                              chapter.id === courseProgressContext[course.id]?.activeChapter,
                          ),
                        )
                          ? 'RESUME'
                          : 'START'
                      }`}
                    </NavLink>
                  )}
                </div>
              </div>
              <div className="img-section">
                <img className="w-full" src={course.course_image} alt={course.name} />
              </div>
            </div>
          </Container>
        ))
      ) : (
        <p>No course available</p>
      )}
    </>
  );
};

export const CoursesListPage: React.FC = () => {
  return <AuthenticatedPage render={() => <CoursesList />} />;
};
