import { EmploymentReadinessAnswer } from '../../../assessments/employment-readiness/employment-readiness.types';

/**
 * used to specify how much a user agrees with a question
 */
export enum AgreementLevel {
  STRONGLY_DISAGREE = 'strongly_disagree',
  DISAGREE = 'disagree',
  NEUTRAL = 'neutral',
  AGREE = 'agree',
  STRONGLY_AGREE = 'strongly_agree',
}

/**
 * used to specify how much a user understands and owns a professional resources
 */
export enum ProfessionalResourceStatus {
  WHAT_IS_IT = 'what_is_it',
  NO = 'no',
  INCOMPLETE = 'incomplete',
  OUTDATED = 'outdated',
  UNPROFESSIONAL = 'unprofessional',
  YES = 'yes',
}

/**
 * used to specify a user's agreement or understanding
 */
export enum YesNoSomewhat {
  YES = 'yes',
  NO = 'no',
  SOMEWHAT = 'somewhat',
}

/**
 * payload to update the employment readiness assessment
 */
export type UpdateEmploymentReadinessDTO = {
  [answerId: string]: EmploymentReadinessAnswer;
};
