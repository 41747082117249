import { getUserProfileValues } from '../../../services/database/user-profile';
import { getStripPaymentStatus } from '../../../services/stripe/stripe';

export const usertransactionUpdate = async () => {
  const userProfile = await getUserProfileValues();
  if (userProfile && userProfile.stripeSessionId) {
    const sessionId = userProfile.stripeSessionId;
    await getStripPaymentStatus(sessionId);
  }

  return null;
};
