import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import { UserPrePostAssesment } from './user-course-assesments.types';

export type CoursePrePostAssessmentsState = {
  [courseId: string]: UserPrePostAssesment;
};

const initialState: CoursePrePostAssessmentsState = {};

const coursePrePostAssessment = createSlice({
  name: 'coursePrePostAssessments',
  initialState,
  reducers: {
    updateCourseAssesmentsQuestion: (
      state,
      action: PayloadAction<CoursePrePostAssessmentsState>,
    ) => {
      logger.debug('Updating user course pre assesment question state.', { state, action });
      const courseId = Object.keys(action.payload)[0];

      if (!state[courseId]) {
        state[courseId] = {
          preAssessmentsQuestions: {
            questions: [],
            score: null,
            isPassed: false,
          },
          postAssessmentQuestions: {
            questions: [],
            score: null,
            isPassed: false,
          },
        };
      }

      state[courseId].preAssessmentsQuestions = action.payload[courseId]?.preAssessmentsQuestions;
      state[courseId].postAssessmentQuestions = action.payload[courseId]?.postAssessmentQuestions;

      return state;
    },
  },
});

export const { updateCourseAssesmentsQuestion: updateCourseAssesmentsQuestionAction } =
  coursePrePostAssessment.actions;

export default coursePrePostAssessment.reducer;
