import { Typography, Paper, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  dispatchReminderEmail,
  getUserEngagementData,
} from '../../redux/slice/organization-dashboard/organization-api';
import {
  OrganizationProps,
  UserEngagementData,
} from '../../redux/slice/organization-dashboard/organization.types';
import logger from '../../services/logger';
import { GenericDataTable } from '../common/GenericDataTable';

export const PlatformUsageStats: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  const [filters, setFilters] = useState({
    name: '',
    email: '',
  });

  const [data, setData] = useState<UserEngagementData[]>([]);
  const [loading, setLoading] = useState(true);
  const [emailLoader, setEmailLoader] = useState(false);

  const limit = 100;

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = orgnizationId && (await getUserEngagementData(orgnizationId, limit));
        if (result && result.employeeData) {
          setData(result?.employeeData); // Ensure data is set correctly
        } else {
          setData([]); // Set empty array if no data
        }
      } catch (error) {
        console.error('Error fetching data:', { error });
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [orgnizationId, limit]);

  const handleFilterChange = (newFilters: Record<string, string>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const filteredData = data.filter((user) => {
    return (
      (!filters.name || user?.name?.toLowerCase().includes(filters.name)) &&
      (!filters.email || user?.email?.toLowerCase().includes(filters.email))
    );
  });
  const handleButtonClick = async (user: UserEngagementData) => {
    try {
      setEmailLoader(true);
      if (user) {
        const sendEmail = await dispatchReminderEmail(user);
        if (sendEmail?.status == 200) {
          toast.success(`Email sent to ${user.email}`);
        }
      }
    } catch (error) {
      toast.error('Something went wrong...');

      logger.error('error:', error);
    } finally {
      setEmailLoader(false);
    }
  };

  const columns = [
    { label: 'Name', accessor: 'name' },
    { label: 'Email', accessor: 'email' },
    { label: 'Time Spent', accessor: 'timeSpentInSeconds' },
    { label: 'Login Frequency/Day', accessor: 'numberOfTimeLoggedIn' },
    { label: 'Last Logged In', accessor: 'lastLoggedInDate' },
    { label: 'Registration Date', accessor: 'createdAt' },
    {
      label: 'Send Email', // Add button column
      accessor: 'action',
      Cell: (row: UserEngagementData) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => handleButtonClick(row)} // Button click event
        >
          Reminder
        </Button>
      ),
    },
  ];

  return (
    <Paper className="p-6">
      <Typography
        variant="h6"
        fontWeight="bold"
        className="border-purple-900 border-b-2 rounded-lg  mb-4 mt-4 text-purple-700 "
        gutterBottom
      >
        User Engagement Data
      </Typography>

      <GenericDataTable
        isLoading={loading}
        columns={columns}
        data={filteredData}
        filterFields={[
          { name: 'name', label: 'Filter by Name' },
          { name: 'email', label: 'Filter by Email' },
        ]}
        onFilterChange={handleFilterChange}
        ctaLoader={emailLoader}
      />
    </Paper>
  );
};
