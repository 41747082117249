import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import React from 'react';

export const CircularLoader: React.FC = () => {
  return (
    <Stack className="loading-overlay" spacing={1} direction="row">
      <CircularProgress color="inherit" />
    </Stack>
  );
};
