import { Sort } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import { CourseChapter } from '../../courses/courses.types';

interface ChapterLayoutProps {
  chapter: CourseChapter | undefined;
  onNextChapter: (nextIndex: number) => void;
  totalChapters: number;
  courseId: string;
  onQuizResult: (result: boolean | undefined) => void;
  onSidebarMenu: (enabled: boolean) => void;
  menuActive: boolean;
}
export const ChapterLayout: React.FC<ChapterLayoutProps> = ({
  chapter,
  onNextChapter,
  totalChapters,
  courseId,
  onQuizResult,
  onSidebarMenu,
  menuActive,
}) => {
  const SelectedComponent = chapter?.content;
  const [sidebarEnabled, setSidebarEnabled] = useState(menuActive);
  const handleSidebarMenuClick = () => {
    const sideBarActive = !sidebarEnabled;
    setSidebarEnabled(sideBarActive);
    onSidebarMenu(sideBarActive);
  };
  useEffect(() => {
    setSidebarEnabled(menuActive);
  }, [menuActive]);
  return (
    <>
      <div className={`right-panel pt-4 ${sidebarEnabled ? 'ml-[300px]' : ''}`}>
        <button
          onClick={handleSidebarMenuClick}
          className="lg:hidden btn-secondary !pl-0 !p-3 !text-black"
        >
          <Sort sx={{ fontSize: '36px' }} />
        </button>
        {chapter ? (
          <>
            {SelectedComponent && (
              <SelectedComponent
                onQuizResult={onQuizResult}
                onNextChapter={onNextChapter}
                chapter={chapter}
                totalPages={totalChapters}
                courseId={courseId}
              />
            )}
          </>
        ) : (
          <p>Please select a chapter from the left panel</p>
        )}
      </div>
    </>
  );
};
