import CircularProgress from '@mui/material/CircularProgress';
interface PageProps {
  scrollTop: number;
}
export const FixedScrollProgress: React.FC<PageProps> = ({ scrollTop }) => {
  return (
    <>
      <div className="page-progress relative">
        <CircularProgress
          variant="determinate"
          sx={{ color: '#d4a5ff', position: 'absolute' }}
          size={25}
          thickness={7}
          value={100}
        />
        <CircularProgress
          className="progress-color"
          sx={{ color: '#962CC6' }}
          size={25}
          thickness={7}
          variant="determinate"
          value={scrollTop}
        />
      </div>
    </>
  );
};
