import m1 from '../../../../assets/images/courses/emotional-intelligence/chapter7/m1.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { MediaBlock } from '../../MediaBlock';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

export const EmotionalIntelligence7: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap border-l-0 lg:border-l pl-0 lg:pl-8 pt-8 pb-10">
        <h2 className="font-semibold text-3xl">Summary</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock mediaImage={m1}>
          <p className="text-3xl leading-10 font-light w-3/4 mx-auto">
            Emotional intelligence is a way of recognizing, understanding, and choosing how we
            think, feel, and act. It shapes our interactions with others and our understanding of
            ourselves.
            <br />
            <span className="text-base font-bold">J. Freedman</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">Key Takeaways</h3>
        <p>
          Emotional intelligence is just as much about knowing yourself as it is about knowing
          others. It’s understanding how you feel, regulating your reactions, and cultivating
          personal resilience. Having good emotional intelligence enables you to understand others,
          react appropriately, and form healthy, productive relationships.
        </p>
        <br />
        <p>
          As you develop your emotional intelligence, remember the key competencies from this
          course:
        </p>
        <ul className="list-decimal pl-5 mt-7">
          <li className="pl-5 mb-5 marker:font-bold">
            <strong>Self-awareness</strong> is recognizing your emotions, knowing why they happen,
            and understanding how they influence your behavior and impact others. To improve your
            self-awareness, feel and accept your emotions, keep a reflective journal, notice your
            physical reactions, reflect with “what” questions, and seek regular feedback.
          </li>
          <li className="pl-5 mb-5 marker:font-bold">
            <strong>Self-regulation</strong> is the ability to control your emotions and behavioral
            reactions. To improve this skill, prepare for emotions ahead of time, employ mindful
            observation, take time to STOP, practice letting go, and reframe difficult situations
            through cognitive reappraisal.
          </li>
          <li className="pl-5 mb-5 marker:font-bold">
            <strong>Self-motivation</strong> is an internal drive that pushes you to pursue your
            goals, stay energized, and remain resilient. Boost your self-motivation by defining your
            whys, breaking down SMART goals, celebrating wins, controlling your inner self-talk, and
            not comparing yourself to others.
          </li>
          <li className="pl-5 mb-5 marker:font-bold">
            <strong>Empathy</strong> is the ability to put yourself in another’s place and
            understand their perspective. To develop empathy, practice active listening, be present
            and unplug around others, and work alongside those who are struggling so that you can
            understand their viewpoint. Stay curious, ask questions, and relate to feelings—not just
            events.
          </li>
          <li className="pl-5 mb-5 marker:font-bold">
            <strong>Social skills</strong> are people skills. They allow you to handle social
            interactions and influence others. To enhance your social skills, develop the seven
            hallmarks: communication, collaboration and cooperation, persuasion and influence,
            inspirational leadership, conflict management, and developing and maintaining
            relationships.
          </li>
        </ul>

        <p className="text-3xl my-8 leading-10 font-light text-center">
          Thank you for completing this course!
        </p>
        <button onClick={handleNextChapterClick} className="btn-primary w-full uppercase">
          Quiz
        </button>
      </div>
    </>
  );
};
