export enum QuestionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  CHAT = 'chat',
  SORTING = 'sorting',
  MATCHING = 'matching',
  INFOBLOCK = 'infoBlock',
}

export type ChapterBase = {
  course_id: string;
  chapter_id: string;
};

export type QuestionBase = {
  id: string;
  question: string;
  question_description?: string;
};

export type QuestionOption = {
  id: string;
  option: string;
  description?: string;
  img?: string;
  feedback_title?: string;
  feedback_content?: string;
};

export type QuizQuestion = QuestionBase & {
  type: QuestionType;
  options: QuestionOption[];
  answers: string[];
  answer_description: string;
  wrong_answer_description?: string;
};

export type CourseQuestion = ChapterBase & QuizQuestion;

export type CourseSortingQuestion = ChapterBase & {
  id: string;
  question_description?: string;
  questions: QuestionBase[];
  options: QuestionOption[];
  answers: Record<string, QuestionOption>;
  type: QuestionType;
  answer_description?: string;
};
export type MatchingQuestionBase = ShortingMatchingBase & {
  index: number;
  matched: boolean;
};
export type ShortingMatchingBase = {
  question: QuestionBase;
  answer: QuestionOption;
  correct_answer: QuestionOption;
};
export type CourseMatchingQuestion = ChapterBase & {
  id: string;
  question_title?: string;
  question_description?: string;
  questions: MatchingQuestionBase[];
  type: QuestionType;
  answer_description?: string;
};

export type CourseChatQuestion = ChapterBase &
  QuizQuestion & {
    question_img?: string;
    answer: string;
    title?: string;
    content?: string;
  };

export type QuizAnswer = {
  question: string;
  is_correct: boolean;
  answer_options: QuestionOption[];
};

export type CourseQuestionAnswer = QuizAnswer & {
  chapter_id: string;
  question_id: string;
};

export type JumpStartQuizQuestion = QuizQuestion;
