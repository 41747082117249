import introImg from '../../../../assets/images/courses/communication-styles/chapter4/introImg.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { MediaBlock } from '../../MediaBlock';
interface CommunicationStylesProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

export const CommunicationStylesChapter4: React.FC<CommunicationStylesProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Summary</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>

        <MediaBlock mediaImage={introImg}>
          <p className="lg:w-3/4 text-2xl leading-10">
            To effectively communicate, we must realize that we are all different in the way we
            perceive the world and use this communication as a guide to our communication with
            others.
            <p className="font-bold mt-5">Tony Robbins</p>
          </p>
        </MediaBlock>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">Summary</h3>
        <p className="mb-7">
          Different people process information in different ways. We can work with others more
          effectively by understanding one another’s dominant communication styles. Start by
          identifying whether your audience uses a dominant action-based, fact-based, idea-based, or
          people-based communication style. Then, adapt your communication style to match theirs.
        </p>
        <ul className="list-decimal pl-7 mt-8 mb-10 text-xl font-light">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Action-based.</span> If they are action-based, make your
            communication task-oriented and focus on execution and producing results.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Fact-based.</span> If they are fact-based, present
            information systematically, focus on the facts, and don’t leave out any details.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Idea-based.</span> If they are idea-based, you’ll need to
            communicate the big picture or greater vision.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">People-based.</span> If they’re people-based, use emotional
            language and give them a chance to discuss with others and build interpersonal
            connections.
          </li>
        </ul>
        <p className="text-2xl text-center mb-7 font-light">
          Click continue to check your knowledge.
        </p>
        <button onClick={handleNextChapterClick} className="btn-primary w-full uppercase">
          Quiz
        </button>
      </div>
    </>
  );
};
