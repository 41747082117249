import { ArrowBackIosOutlined, ArrowForwardIosOutlined, Check, Replay } from '@mui/icons-material';
import { useState } from 'react';

type CarouselComponentData = {
  heading: string;
  paragraph: string;
  list: string[];
  image?: string;
};
interface CarouselComponentProps {
  data: CarouselComponentData[];
}

export const CarouselComponent: React.FC<CarouselComponentProps> = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % data.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + data.length) % data.length);
  };
  const handleReplaySlides = () => {
    setCurrentSlide(0);
  };
  return (
    <div className="carousel-container pb-10">
      {currentSlide > 0 && (
        <button className="prev" onClick={prevSlide}>
          <ArrowBackIosOutlined />
        </button>
      )}
      <div className="carousel">
        {data.map((content, index) => (
          <div
            key={index}
            className={`flex items-center px-10 lg:px-20 slide ${
              index === currentSlide ? 'active' : ''
            }`}
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            <div>
              {currentSlide === 0 ? (
                <div className="text-center">
                  <h2 className="text-3xl font-semibold mb-5 text-center">{content.heading}</h2>
                  <p className="text-xl font-light text-center mb-5">
                    <span dangerouslySetInnerHTML={{ __html: content.paragraph }} />
                  </p>
                  <button className="btn-primary mx-auto" onClick={nextSlide}>
                    Start
                  </button>
                </div>
              ) : currentSlide === data.length - 1 ? (
                <div className="text-center">
                  <h2 className="text-3xl font-semibold mb-5 text-center">{content.heading}</h2>
                  <p className="text-xl font-light text-center mb-5">
                    <span dangerouslySetInnerHTML={{ __html: content.paragraph }} />
                  </p>
                  <div className="mt-10 pt-5 border-1 border-t border-gray-700 inline-block">
                    <button onClick={handleReplaySlides} className="text-purple-700 min-w-[140px]">
                      START AGAIN
                      <br />
                      <Replay />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <h2 className="text-2xl font-semibold mb-7">{content.heading}</h2>
                  {content.image && (
                    <span className="block mt-7 rounded-3xl overflow-hidden mb-7">
                      <img
                        className="object-cover h-full w-full  max-h-[300px]"
                        src={content.image}
                        alt=""
                      />
                    </span>
                  )}
                  <p>
                    <span dangerouslySetInnerHTML={{ __html: content.paragraph }} />
                  </p>
                  <ul className="list-disc pl-5 mt-5">
                    {content.list.map((item, i) => (
                      <li key={i} className="mb-3" dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {currentSlide < data.length - 1 && (
        <button className="next" onClick={nextSlide}>
          <ArrowForwardIosOutlined />
        </button>
      )}
      {data.length > 1 &&
        currentSlide > 0 && ( // Check if there is more than one slide
          <div className="pagination">
            {data.map(
              (_, index) =>
                index !== 0 && ( // Exclude index 0 from rendering
                  <div
                    key={index}
                    className={`pagination-dot ${index === currentSlide ? 'active' : ''}`}
                    onClick={() => setCurrentSlide(index)}
                  >
                    {index === data.length - 1 ? <Check /> : index}
                  </div>
                ),
            )}
          </div>
        )}
    </div>
  );
};
