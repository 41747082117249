import { ProfessionalResourceStatus } from '../../redux/slice/form-employment-readiness/form-employment-readiness.types';

import { ReadinessQuestionType, ReadinessQuestion } from './employment-readiness.types';

export const questions: ReadinessQuestion[] = [
  {
    id: '4bddd6b6-879f-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.YES_NO,
    question: 'I can conduct a job search.',
  },
  {
    id: '4bdddac6-879f-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.YES_NO,
    question: 'I evaluate my strengths and weaknesses before applying for a job.',
  },
  {
    id: '4bdddc88-879f-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.YES_NO,
    question:
      'I collect information about the job profile and the company which I am interested in.',
  },
  {
    id: '4bddddbe-879f-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.YES_NO,
    question: 'I have performed informational interviews.',
    tooltip: {
      title: 'informational',
      content:
        'An informational interview is an informal conversation with an individual who works in a company, role, or industry that interests you. Unlike job interviews, these conversations can help you gather information about a career path, not obtain a job. Informational interviews typically last 20 to 30 minutes, and you may also organize one to:',
      bulletList: [
        'Discover new careers and fields',
        'Recognize your suitability for a potential role',
        'Learn the steps to pursue a potential career',
        'Focus on specific career goals',
        'Prepare for future job interviews',
      ],
    },
  },
  {
    id: '4bdddf58-879f-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.YES_NO,
    question: 'I maintain up-to-date knowledge on the job I’m interested in.',
  },
  {
    id: '4bdde08e-879f-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.YES_NO,
    question:
      'I have a strong list of references and have explored strategies for strengthening this list.',
  },
  {
    id: '4bdde4b2-879f-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.YES_NO,
    question:
      'I have reviewed networking strategies. I have explored personal contacts (ex-coworkers, ex-classmates, teachers, counselor, etc.) and have communicated my job goal with them.',
  },

  {
    id: '4bdde688-879f-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.MARKETING_TOOLKIT,
    resource: 'professional sample resume',
    options: {
      'What is a resume?': ProfessionalResourceStatus.WHAT_IS_IT,
      No: ProfessionalResourceStatus.NO,
      'I have one that’s not complete': ProfessionalResourceStatus.INCOMPLETE,
      'I have a resume but not sure if it’s professional':
        ProfessionalResourceStatus.UNPROFESSIONAL,
      'Yes, I have a professional resume': ProfessionalResourceStatus.YES,
    },
  },
  {
    id: '4bdde7be-879f-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.MARKETING_TOOLKIT,
    resource: 'professional sample cover letter',
    options: {
      'What is a cover letter?': ProfessionalResourceStatus.WHAT_IS_IT,
      No: ProfessionalResourceStatus.NO,
      'I have a cover letter but it’s not very professional':
        ProfessionalResourceStatus.UNPROFESSIONAL,
      'I have an outdated cover letter': ProfessionalResourceStatus.OUTDATED,
      'Yes, I have a professional cover letter': ProfessionalResourceStatus.YES,
    },
  },
  {
    id: '4bdde8ea-879f-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.MARKETING_TOOLKIT,
    resource: 'updated professional sample profile',
    options: {
      'What is a profile?': ProfessionalResourceStatus.WHAT_IS_IT,
      No: ProfessionalResourceStatus.NO,
      'I have a profile but it’s not very professional': ProfessionalResourceStatus.UNPROFESSIONAL,
      'I have an outdated profile': ProfessionalResourceStatus.OUTDATED,
      'Yes, I have a professional profile': ProfessionalResourceStatus.YES,
    },
  },
  {
    id: 'e4bf2544-87a7-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.MARKETING_TOOLKIT,
    resource: 'professional email account',
    options: {
      'What is a professional email account?': ProfessionalResourceStatus.WHAT_IS_IT,
      No: ProfessionalResourceStatus.NO,
      'I have an email account but it’s not very professional':
        ProfessionalResourceStatus.UNPROFESSIONAL,
      'Yes, I have a professional email account': ProfessionalResourceStatus.YES,
    },
  },

  {
    id: 'b5b70dd4-87ac-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I practice the way I introduce myself before attending a job interview.',
  },
  {
    id: 'd9b27eee-87ac-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I am on time for the job interview.',
  },
  {
    id: 'e75b8630-87ac-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question:
      'I am appropriately dressed and groomed for the position and for the interview setting.',
  },
  {
    id: '031be18a-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I provide documents as requested.',
  },
  {
    id: '0ac76b20-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I express myself clearly.',
  },
  {
    id: '2323dba4-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question:
      'I provide complete and appropriate answers without rambling or providing extraneous information.',
  },
  {
    id: '29e4e640-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I state relevant qualifications and connect my experiences to them.',
  },
  {
    id: '457f65a6-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I ask appropriate and relevant questions.',
  },
  {
    id: '4cbeb8c6-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I have a positive attitude throughout the interview.',
  },
  {
    id: '65280818-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I demonstrate appropriate body language.',
  },
  {
    id: '6c8e2f92-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question:
      'I feel confident that I can communicate my skills and experiences with potential employers.',
  },
  {
    id: '9414424a-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I take responsibility for my work.',
  },
  {
    id: '9f6ffa44-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I gain knowledge and acquire skill from my work.',
  },
  {
    id: 'b58e66ee-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I do not feel tired even after working for a long period of time.',
  },
  {
    id: 'be101704-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I gain satisfaction in my work.',
  },
  {
    id: 'd8555fac-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I follow the assigned work schedule.',
  },
  {
    id: 'df1e1450-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I arrive at my workplace punctually.',
  },
  {
    id: 'fae4b4a0-87ad-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I complete my tasks on time.',
  },
  {
    id: '02828444-87ae-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I am polite and friendly at work.',
  },
  {
    id: '1ec99598-87ae-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I express my ideas and opinions clearly.',
  },
  {
    id: '28aca8de-87ae-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I maintain good communication with my manager.',
  },
  {
    id: '43f65b58-87ae-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I can resolve conflict and recognize different views at work.',
  },
  {
    id: '4be0d4ec-87ae-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I use appropriate language in my workplace.',
  },
  {
    id: '70da2d34-87ae-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I respond appropriately to working instructions.',
  },
  {
    id: '884e9c7a-87ae-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I take initiative to complete tasks at work.',
  },
  {
    id: '8f1350be-87ae-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I care about my integrity.',
  },
];
