import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthenticatedPage } from '../components/AuthenticatedPage';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchPaymentStatusEmail } from '../redux/slice/form-send-email/payment-status-mail';
import { dispatchUpsertPreEmploymentAssessment } from '../redux/slice/pre-employment-assessment';
import {
  dispatchGetUserProfileValues,
  dispatchUpdateUserProfileInfo,
} from '../redux/slice/user-profile';
import { usertransactionUpdate } from '../redux/slice/user-transaction/user-transaction-operation';
import { useAppDispatch } from '../redux/store';
import { EnvironmentVariable } from '../utils/constants';

export const PaymentSuccess: React.FC = () => {
  const productPrice =
    Number(process.env[EnvironmentVariable.REACT_APP_SUBSCRIPTION_PRICE_CENTS]) / 100;
  const asaUserDiscount = Number(process.env[EnvironmentVariable.REACT_APP_ASA_DISCOUNT_PERCENT]);
  const [productFinalPrice, setProductFinalPrice] = useState<number | null>(null);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userProfileContext = useUserProfileContext();
  const calculateFinalPrice = (price: number, discount: number) => {
    return price - (price * discount) / 100;
  };
  useEffect(() => {
    void dispatch(dispatchGetUserProfileValues());
  }, []);

  useEffect(() => {
    if (
      Object.keys(userProfileContext).length &&
      userProfileContext?.email &&
      !userProfileContext?.isPremium &&
      userProfileContext.paymentProcessing &&
      !isEmailSent
    ) {
      setIsEmailSent(true);
      void usertransactionUpdate();

      setProductFinalPrice(
        userProfileContext.asaUserId
          ? calculateFinalPrice(productPrice, asaUserDiscount)
          : calculateFinalPrice(productPrice, 0),
      );
      void dispatch(
        dispatchUpdateUserProfileInfo({
          isPremium: true,
          subscriptionExpiryDate: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1),
          ).toISOString(),
          paymentProcessing: false,
          paymentSessionCreateDate: '',
          stripeSessionId: '',
        }),
      );
      void dispatch(
        dispatchUpsertPreEmploymentAssessment({
          syncedToAirtable: false,
        }),
      );
      void dispatchPaymentStatusEmail({
        isAsaUser: !!userProfileContext.asaUserId,
        paymentStatus: true,
        to: userProfileContext?.email,
        userName: userProfileContext?.name,
      });
    } else if (
      Object.keys(userProfileContext).length &&
      userProfileContext?.email &&
      !userProfileContext.paymentProcessing
    ) {
      setTimeout(() => {
        return navigate(userProfileContext?.isPremium ? '/dashboard' : '/upgrade');
      }, 3000);
    }
  }, [userProfileContext, isEmailSent]);

  return (
    <>
      <div className="h-screen flex pt-4  pb-4 justify-center items-center ">
        <div
          className={`mx-auto w-full max-w-3xl payment-box  justify-center  border-l border-r relative bg-white`}
        >
          <div className="bg-white text-center">
            <CheckCircleOutlineTwoToneIcon style={{ fontSize: '120px', color: 'green' }} />
          </div>
          <div className=" rounded-lg p-3 text-center  text-xl ">
            <h3 className="font-bold ">Payment Successfull</h3>
          </div>
          <div className=" p-3 text-center  text-xl text-gray-900">
            <p className="font-light mt-5 text-lg">We have received payment amount of</p>
            <h4 className="text-4xl mt-5 font-bold ">$ {productFinalPrice}</h4>

            {/* <h4 className="text-lg mt-5">Request Id : x907615gh </h4> */}
          </div>
        </div>
      </div>
    </>
  );
};

export const PaymentSuccessPage: React.FC = () => {
  return <AuthenticatedPage isPaymentSuccessPage={true} render={() => <PaymentSuccess />} />;
};
