import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

export const SfetsPieChart: React.FC = () => {
  return (
    <PieChart
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
        },
      }}
      series={[
        {
          data: [
            { id: 0, value: 68, label: 'skill 1', color: '#00a0cf' },
            { id: 1, value: 18, label: 'skill 2', color: '#ff3169' },
            { id: 2, value: 18, label: 'skill 3', color: '#00b4b0' },
            { id: 3, value: 35, label: 'skill 4', color: '#ffa523' },
            { id: 4, value: 45, label: 'skill 5', color: '#9f49a3' },
          ],
          innerRadius: 30,
          paddingAngle: 2,
          cornerRadius: 5,
          arcLabel: (item) => `${item.value}`,
        },
      ]}
      width={500}
      height={250}
    />
  );
};
