import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ExperienceAnswer,
  ExperienceAssessmentAnswer,
  ExperienceAssessmentQuestion,
  ExperienceAssessmentQuestionType,
  ProficiencyAndInterestAnswer,
} from '../../assessments/experience/experience.types';
import { useExperienceAssessmentContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  ExperienceAssessmentState,
  dispatchUpdateExperienceAssessment,
} from '../../redux/slice/form-experience';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';

export const experienceAssessmentOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];

interface FormData {
  interactedVirtually: ExperienceDetails;
  interactedInPerson: ExperienceDetails;
  resolvedQueries: ExperienceDetails;
  workedInRetail: ExperienceDetails;
  takenOrder: ExperienceDetails;
  cleanedTable: ExperienceDetails;
  servedFood: ExperienceDetails;
}

export interface ExperienceDetails {
  proficient: string;
  interested: string;
}
interface ComponentProps {
  step: number;
  questions: ExperienceAssessmentQuestion[];
  startIndex: number;
  onBack: () => void;
  completePreEmploymentOnSubmission?: boolean;
}

type AnswersState = {
  [questionId: string]: ExperienceAssessmentAnswer;
};
export const ExperienceAssessment: React.FC<ComponentProps> = ({
  step,
  onBack,
  questions,
  startIndex,
  completePreEmploymentOnSubmission,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const savedAnswers: ExperienceAssessmentState = useExperienceAssessmentContext();
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
  } = usePreEmploymentAssessmentContext();
  const [error, setError] = useState('');
  const [answers, setAnswers] = useState<AnswersState>({});

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);
  function updateAnswer(
    questionId: string,
    updatedAnswer: Partial<ExperienceAssessmentAnswer>,
  ): void {
    setError('');
    setAnswers((previousState: AnswersState) => ({
      ...previousState,
      [questionId]: {
        ...answers[questionId],
        ...updatedAnswer,
      },
    }));
  }

  function convertValueForProficiencyToString(
    answer: ExperienceAssessmentAnswer,
  ): 'Yes' | 'No' | undefined {
    const typedAnswer: ProficiencyAndInterestAnswer = answer as ProficiencyAndInterestAnswer;
    if (typedAnswer?.isProficient === undefined) {
      return undefined;
    } else if (typedAnswer.isProficient) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  function convertValueForInterestToString(
    answer: ExperienceAssessmentAnswer,
  ): 'Yes' | 'No' | undefined {
    const typedAnswer: ProficiencyAndInterestAnswer = answer as ProficiencyAndInterestAnswer;
    if (typedAnswer?.isInterested === undefined) {
      return undefined;
    } else if (typedAnswer.isInterested) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  function convertValueForExperienceToString(
    answer: ExperienceAssessmentAnswer,
  ): 'Yes' | 'No' | undefined {
    const typedAnswer: ExperienceAnswer = answer as ExperienceAnswer;
    if (typedAnswer?.hasExperience === undefined) {
      return undefined;
    } else if (typedAnswer.hasExperience) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  const { handleSubmit } = useForm<FormData>();
  const backButtonClick = () => {
    onBack();
  };
  const submitForm: SubmitHandler<FormData> = async () => {
    logger.debug('Submitted experience assessment form.', answers);

    let error_message: string = 'Please select an option from each section';
    setIsLoading(true);
    try {
      const hasMissingAnswers: boolean = questions.some(
        (question: ExperienceAssessmentQuestion): boolean => {
          if (!answers[question.id]) {
            return true;
          }

          const answer: ExperienceAssessmentAnswer = answers[question.id];
          if (
            question.type === ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST &&
            (answer as ProficiencyAndInterestAnswer).isInterested === undefined
          ) {
            return true;
          } else if (
            question.type === ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST &&
            (answer as ProficiencyAndInterestAnswer).isProficient === undefined
          ) {
            return true;
          } else if (
            question.type === ExperienceAssessmentQuestionType.EXPERIENCE &&
            question.id === questions[questions.length - 1].id &&
            (answer as ExperienceAnswer).hasExperience &&
            !(answer as ExperienceAnswer).comments?.trim()
          ) {
            error_message = 'Please explain the military service experience.';
            return true;
          } else if (
            question.type === ExperienceAssessmentQuestionType.EXPERIENCE &&
            (answer as ExperienceAnswer).hasExperience === undefined
          ) {
            return true;
          }

          return false;
        },
      );
      if (hasMissingAnswers) {
        setError(error_message);
      } else {
        if (!isDisabled) {
          logger.trace('✅ Successfully submitted experience assessment form.');
          await dispatch(dispatchUpdateExperienceAssessment(answers));
        }

        // logger.trace('✅ Successfully submitted experience assessment form.');
        // await dispatch(dispatchUpdateExperienceAssessment(answers));
        if (completePreEmploymentOnSubmission) {
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            await dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: savedAnswers[questions[0].id] ? progress : totalProgress,
                activeStepUrl: `/swot/step1`,
                status: PreEmploymentAssessmentStatus.COMPLETE,
                didCompleteExperience: true,
                syncedToAirtable: false,
                preEmployementSubmitDate: new Date().toISOString(),
              }),
            );
          } else {
            await dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/swot/step1`,
                status: PreEmploymentAssessmentStatus.COMPLETE,
                didCompleteExperience: true,
                syncedToAirtable: false,
              }),
            );
          }
          navigate('/swot/step1');
        } else {
          if (isDisabled) {
            navigate(`/pre-employment/experience/step${step + 1}`);
          } else {
            navigate(`/pre-employment/experience/step${step + 1}`);
            if (defaultStepProgress) {
              const totalProgress = (progress ?? 0) + defaultStepProgress;
              await dispatch(
                dispatchUpsertPreEmploymentAssessment({
                  progress: savedAnswers[questions[0].id] ? progress : totalProgress,
                  activeStepUrl: `/pre-employment/experience/step${step + 1}`,
                }),
              );
            } else {
              await dispatch(
                dispatchUpsertPreEmploymentAssessment({
                  activeStepUrl: `/pre-employment/experience/step${step + 1}`,
                }),
              );
            }
          }
        }
      }
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (savedAnswers && Object.keys(savedAnswers).length) {
      setAnswers(savedAnswers);
    }
  }, [savedAnswers]);

  return (
    <>
      <div className="mt-10">
        <form
          className=" md:px-10"
          action=""
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(submitForm)}
        >
          <ol className="pl-5" style={{ listStyle: 'decimal' }} type="1" start={startIndex}>
            {questions.map((question: ExperienceAssessmentQuestion) => (
              <li className="mb-10 font-semibold" key={question.id}>
                {question.question}
                {question.type === ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST ? (
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8">
                    <div className="mt-5">
                      <div>
                        <p className="text-purple-600 text-sm font-semibold mb-2">
                          Are you proficient?
                        </p>
                        <div className="grid grid-cols-1 gap-3">
                          <div className="border py-3 px-6 rounded-lg flex">
                            {experienceAssessmentOptions.map((option) => (
                              <label
                                key={option.label}
                                className="cr-radio-container font-light mr-8"
                              >
                                {option.label}
                                <input
                                  disabled={isDisabled}
                                  type="radio"
                                  checked={
                                    convertValueForProficiencyToString(answers[question.id]) ===
                                    option.value
                                  }
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    updateAnswer(question.id, {
                                      question: question.question,
                                      isProficient: event.target.value === 'Yes',
                                    })
                                  }
                                  value={option.value}
                                  name={`proficiency-${question.id}`}
                                />
                                <span className="cr-checkmark"></span>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <div>
                        <p className="text-purple-600 text-sm font-semibold mb-2">
                          Does this type of work interest you?
                        </p>
                        <div className="flex gap-3">
                          <div className="py-3 rounded-lg flex justify-between">
                            {experienceAssessmentOptions.map((option) => (
                              <label
                                key={option.label}
                                className="cr-radio-container mr-8 font-light"
                              >
                                {option.label}
                                <input
                                  disabled={isDisabled}
                                  type="radio"
                                  checked={
                                    convertValueForInterestToString(answers[question.id]) ===
                                    option.value
                                  }
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    updateAnswer(question.id, {
                                      question: question.question,
                                      isInterested: event.target.value === 'Yes',
                                    })
                                  }
                                  value={option.value}
                                  name={`interest-${question.id}`}
                                />
                                <span className="cr-checkmark"></span>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-wrap lg:flex-nowrap gap-x-24">
                    <div className="mt-5">
                      <div>
                        <div className="flex gap-3">
                          <div className="rounded-lg flex justify-between gap-3">
                            {experienceAssessmentOptions.map((option) => (
                              <label key={option.label} className="cr-radio-container font-light">
                                {option.label}
                                <input
                                  disabled={isDisabled}
                                  type="radio"
                                  checked={
                                    convertValueForExperienceToString(answers[question.id]) ===
                                    option.value
                                  }
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    updateAnswer(question.id, {
                                      question: question.question,
                                      hasExperience: event.target.value === 'Yes',
                                    })
                                  }
                                  value={option.value}
                                  name={`experience-${question.id}`}
                                />
                                <span className="cr-checkmark"></span>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5  basis-full w-full">
                      <p className="text-purple-600 text-sm font-semibold mb-2">
                        If yes, please explain
                      </p>
                      <textarea
                        disabled={isDisabled}
                        value={(answers[question.id] as ExperienceAnswer)?.comments}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                          updateAnswer(question.id, {
                            question: question.question,
                            comments: event.target.value,
                          })
                        }
                        rows={2}
                        className="bg-white border rounded-lg w-full resize-none mt-4 font-light p-3"
                      ></textarea>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ol>
          {error && <p className="text-red-500 font-light !text-sm">{error}</p>}
          <div className="flex justify-end lg:justify-between lg:mt-20 mt-10">
            <button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit(backButtonClick)}
              className="btn-primary"
            >
              Back
            </button>
            <button className="btn-primary ml-5" disabled={isLoading}>
              {completePreEmploymentOnSubmission ? 'Submit' : 'Next'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
