import _ from 'lodash';
import React, { useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import introImg from '../../../../assets/images/courses/perception-understanding/chapter7/introImg.jpg';
import perciption_chapter7_img1 from '../../../../assets/images/courses/perception-understanding/chapter7/perciption_chapter7_img1.png';
import perciption_chapter7_img2 from '../../../../assets/images/courses/perception-understanding/chapter7/perciption_chapter7_img2.png';
import perciption_chapter7_img3 from '../../../../assets/images/courses/perception-understanding/chapter7/perciption_chapter7_img3.png';
import perciption_chapter7_img4 from '../../../../assets/images/courses/perception-understanding/chapter7/perciption_chapter7_img4.png';
import sbStyle from '../../../../assets/images/courses/perception-understanding/chapter7/sbStyle.png';
import stress from '../../../../assets/images/courses/perception-understanding/chapter7/stress.jpg';
import stress_signal from '../../../../assets/images/courses/perception-understanding/chapter7/stress_signal.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { CustomAudioTabsBlock, CustomTabPanel } from '../../CustomAudioTabs';
import FlipCard from '../../FlipCard';
import { MediaBlock } from '../../MediaBlock';

interface PerciptionProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const PerceptionAndUnderstandingChapter7: React.FC<PerciptionProps> = ({
  chapter,
  onNextChapter,
}) => {
  const [energyTabValue, setEnergyTabValue] = useState<number | null>(null);
  const tabsData = [
    {
      title: 'Cool Blue',
      textColor: '',
      bgColor: 'bg-cool-blue',
      audioUrl:
        'https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/d97AraXL/assets/L6L2S8ctgMP0UA0W/story_content/62aApzFRTeu_44100_56_0.mp3',
    },
    {
      title: 'Sunshine Yellow',
      textColor: '',
      bgColor: 'bg-sunshine-yellow',
      audioUrl:
        'https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/d97AraXL/assets/L6L2S8ctgMP0UA0W/story_content/5soWRb04wnF_44100_56_0.mp3',
    },
    {
      title: 'Earth Green',
      textColor: '',
      bgColor: 'bg-earth-green',
      audioUrl:
        'https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/d97AraXL/assets/L6L2S8ctgMP0UA0W/story_content/5drPZ8HnATt_44100_56_0.mp3',
    },
    {
      title: 'Fiery Red',
      textColor: '',
      bgColor: 'bg-fiery-red',
      audioUrl:
        'https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/d97AraXL/assets/L6L2S8ctgMP0UA0W/story_content/6a6OYCkNUAd_44100_56_0.mp3',
    },
  ];

  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-xl font-light">
        <h2 className="font-semibold text-3xl">Stress & Behavioral Style</h2>
        <p className="text-sm italic">Part 7 of 10</p>
        <MediaBlock mediaImage={introImg}></MediaBlock>

        <div className="flex items-center mt-12">
          <div>
            <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-6">
              Stress & Behavioral Style
            </h3>
            <p>
              Our color energies show up as “good day” or “bad day” behaviors.
              <br />
              <br />
              Stress causes our behavioral styles to show up in “bad day” ways. This image provides
              a summary of some of those behaviors.
              <br />
              <br />
              Do you recognize any of these in yourself or other's behavior?
            </p>
          </div>
          <img className="w-96 ml-9 rounded-3xl" src={sbStyle} alt="" />
        </div>
        <div className="flex items-center mt-12">
          <img className="w-96 mr-9 rounded-3xl" src={stress} alt="" />
          <div>
            <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-6">Stress</h3>
            <p>
              Stress is a physical, chemical, or emotional factor that causes bodily or mental
              tension.
              <br />
              <br />
              It is a state of bodily or mental tension resulting from factors that tend to alter an
              existent equilibrium.
            </p>
          </div>
        </div>

        <p className="my-8">Click on the cards below to find answers to a few questions.</p>
        <div className="my-10">
          <div className="flex justify-center gap-5 mb-7">
            <FlipCard>
              <div className="front-content text-center">What causes stress?</div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>when there is a lack of a person's dominant energy in the environment.</div>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content text-center">How do they respond?</div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>
                  by trying to infuse that energy into the environment, whatever way they can.
                </div>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content text-center">How do we help?</div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>by giving them more of that energy!</div>
              </div>
            </FlipCard>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-6">Stress Signals</h3>
        <p>Let us explore the stress signals for each color energy in more detail.</p>
        <p className="font-bold mt-7 mb-5"> Click on the tabs and play the audio to learn more.</p>
        <CustomAudioTabsBlock tabsData={tabsData} onTabChange={(value) => setEnergyTabValue(value)}>
          {!_.isNil(energyTabValue) && (
            <>
              <CustomTabPanel index={0} value={energyTabValue}>
                <Swiper navigation={true} modules={[Navigation]} className="preferencesSwiper">
                  <SwiperSlide>
                    <div className="flex border-black text-base font-normal justify-around mx-auto mt-5 mb-5">
                      <div className="inline-block">
                        <p className="mb-7  text-xl text-white">
                          <strong className="bg-[#0188d3]">CAUSES OF STRESS: </strong>
                        </p>

                        <ul className="list-disc pl-5 inline-block">
                          <li className="mb-2 ml-5  pl-4 whitespace-nowrap">
                            Lack of information, structure, and logic
                          </li>
                          <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Poor quality work</li>
                          <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                            Time wasted or task rushed
                          </li>
                        </ul>
                        <p className="mb-7  text-xl text-white">
                          <strong className="bg-[#0188d3]">STRESS SIGNALS: </strong>
                        </p>

                        <ul className="list-disc pl-5 inline-block">
                          <li className="mb-2 ml-5  pl-4 whitespace-nowrap">
                            Become questioning and delibrate
                          </li>
                          <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Nit Picking</li>
                          <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                            Aloof, withdrawn, and resentfully
                          </li>
                        </ul>
                        <p className="mb-7  text-xl text-white">
                          <strong className="bg-[#0188d3]">Remedy: </strong>
                        </p>

                        <ul className="list-disc pl-5 inline-block">
                          <li className="mb-2 ml-5  pl-4 whitespace-nowrap">
                            Get their feeback on the way forward
                          </li>
                          <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                            Informational and emotional support
                          </li>
                          <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                            Back to beginning and analyze more carefully
                          </li>
                        </ul>
                      </div>
                      <div className="flex justify-end w-1/3 text-center items-center  mx-8">
                        <img className="" src={perciption_chapter7_img1} alt="" />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </CustomTabPanel>
              <CustomTabPanel index={1} value={energyTabValue}>
                <SwiperSlide>
                  {' '}
                  <div className="flex item-center text-base font-normal justify-around mx-auto mt-5 mb-5">
                    <div className="inline-block">
                      <p className="mb-7  text-xl text-white">
                        <strong className="bg-[#fcca4f]">CAUSES OF STRESS: </strong>
                      </p>

                      <ul className="list-disc pl-5 inline-block">
                        <li className="mb-2 ml-5  pl-4 whitespace-nowrap">
                          Restriction on flexibiltiy
                        </li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">No interaction and fun</li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Personal rejection</li>
                      </ul>
                      <p className="mb-7  text-xl text-white">
                        <strong className="bg-[#fcca4f]">STRESS SIGNALS: </strong>
                      </p>

                      <ul className="list-disc pl-5 inline-block">
                        <li className="mb-2 ml-5  pl-4 whitespace-nowrap">Over responsive</li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Appears opinionated</li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Arguments</li>
                      </ul>
                      <p className="mb-7  text-xl text-white">
                        <strong className="bg-[#fcca4f]">Remedy: </strong>
                      </p>

                      <ul className="list-disc pl-5 inline-block">
                        <li className="mb-2 ml-5  pl-4 whitespace-nowrap">
                          Allow room for maneuver
                        </li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Save face</li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                          Distract onto something different
                        </li>
                      </ul>
                    </div>
                    <div className="flex justify-end w-1/3 items-center text-center  mx-8">
                      <img className="" src={perciption_chapter7_img2} alt="" />
                    </div>
                  </div>{' '}
                </SwiperSlide>
              </CustomTabPanel>
              <CustomTabPanel index={2} value={energyTabValue}>
                <SwiperSlide>
                  {' '}
                  <div className="flex border-black text-base font-normal justify-around mx-auto mt-5 mb-5">
                    <div className="inline-block">
                      <p className="mb-7  text-xl text-white">
                        <strong className="bg-[#8ba40a]">CAUSES OF STRESS: </strong>
                      </p>

                      <ul className="list-disc pl-5 inline-block">
                        <li className="mb-2 ml-5  pl-4 whitespace-nowrap">
                          Unfair or impersonal treatment
                        </li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Violation of values</li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                          Interruption or time pressures
                        </li>
                      </ul>
                      <p className="mb-7  text-xl text-white">
                        <strong className="bg-[#8ba40a]">STRESS SIGNALS: </strong>
                      </p>

                      <ul className="list-disc pl-5 inline-block">
                        <li className="mb-2 ml-5  pl-4 whitespace-nowrap">
                          Becomes silent, withdrawn, or hurt
                        </li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                          Judgmental, impersonal, resistant
                        </li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                          Stubborn and overlay cautious
                        </li>
                      </ul>
                      <p className="mb-7  text-xl text-white">
                        <strong className="bg-[#8ba40a]">Remedy: </strong>
                      </p>

                      <ul className="list-disc pl-5 inline-block">
                        <li className="mb-2 ml-5  pl-4 whitespace-nowrap">
                          Personal contact to restore trust
                        </li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                          Understanding sincerity
                        </li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                          Put the task aside to another day
                        </li>
                      </ul>
                    </div>
                    <div className="flex justify-end  items-center w-1/3 text-center  mx-8">
                      <img className="" src={perciption_chapter7_img3} alt="" />
                    </div>
                  </div>{' '}
                </SwiperSlide>
              </CustomTabPanel>
              <CustomTabPanel index={3} value={energyTabValue}>
                <SwiperSlide>
                  {' '}
                  <div className="flex border-black text-base font-normal justify-around mx-auto mt-5 mb-5">
                    <div className="inline-block">
                      <p className="mb-7  text-xl text-white">
                        <strong className="bg-[#b73434]">CAUSES OF STRESS: </strong>
                      </p>

                      <ul className="list-disc pl-5 inline-block">
                        <li className="mb-2 ml-5  pl-4 whitespace-nowrap">Lack of focus</li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Indecisiveness</li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Being out of controll</li>
                      </ul>
                      <p className="mb-7  text-xl text-white">
                        <strong className="bg-[#b73434]">STRESS SIGNALS: </strong>
                      </p>

                      <ul className="list-disc pl-5 inline-block">
                        <li className="mb-2 ml-5  pl-4 whitespace-nowrap">Becomes aggressive</li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Impatient</li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">Irritable, demanding</li>
                      </ul>
                      <p className="mb-7  text-xl text-white">
                        <strong className="bg-[#b73434]">Remedy: </strong>
                      </p>

                      <ul className="list-disc pl-5 inline-block">
                        <li className="mb-2 ml-5  pl-4 whitespace-nowrap">Allow for fast action</li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                          Take decisions or time out
                        </li>
                        <li className="mb-2 ml-5 pl-4 whitespace-nowrap">
                          Put the task aside to another day
                        </li>
                      </ul>
                    </div>
                    <div className="flex justify-end items-center w-1/3  text-center  mx-8">
                      <img className="" src={perciption_chapter7_img4} alt="" />
                    </div>
                  </div>{' '}
                </SwiperSlide>
              </CustomTabPanel>
            </>
          )}
        </CustomAudioTabsBlock>
        {_.isNil(energyTabValue) && (
          <MediaBlock mediaImage={stress_signal} cssClass="!mt-3"></MediaBlock>
        )}
        <p className="text-2xl mt-7">
          Now that we have seen some clues to help you identify your and others' styles and
          preferences, we'll turn our attention on how we can adapt to connect with others.
        </p>
        <button onClick={handleNextChapterClick} className="w-full btn-primary uppercase mt-8">
          ADAPTING TO CONNECT
        </button>
      </div>
    </>
  );
};
