import info_icon from '../../assets/icons/info.svg';

interface InfoBlockProps {
  title: string;
  text: string;
}
export const InfoBlock: React.FC<InfoBlockProps> = ({ title, text }) => {
  return (
    <div className="bg-purple-100 p-5 rounded-3xl flex items-start mb-10">
      <img className="w-5" src={info_icon} alt="" />
      <p className="text-base ml-3 font-normal">
        <span className="font-bold">{title}</span> {text}
      </p>
    </div>
  );
};
