import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { CareerReadinessState } from '../../redux/slice/form-career-readiness/form-career-readiness.slice';
import { UpdateCareerReadinessDTO } from '../../redux/slice/form-career-readiness/form-career-readiness.types';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.CAREER_READINESS_RESPONSES;

/**
 * upsert a record into the career readiness responses collection
 */
async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

/**
 * retrieves previously inputted career readiness values if available
 */
export async function getCareerReadinessValues(): Promise<CareerReadinessState> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<CareerReadinessState> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return result.data() ?? {};
  } catch (e) {
    logger.error('Failed to retrieve career readiness values.', e);
    throw e;
  }
}

/**
 * updates the career readiness assessment
 */
export async function updateCareerReadiness(dto: UpdateCareerReadinessDTO): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved career readiness.');
  } catch (e) {
    logger.error('Failed to update career readiness.', e);
    throw e;
  }
}
