import { Card, CardContent, Grid, Typography, Paper, CircularProgress, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { getOrganizationUserStastics } from '../../redux/slice/organization-dashboard/organization-api';
import {
  OrganizationProps,
  UserStats,
} from '../../redux/slice/organization-dashboard/organization.types';
import { formatTime } from '../../utils/activityTimer';

export const OrganizationStats: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  const [data, setData] = useState<UserStats>({
    totalUsersCount: 0,
    completedPreEmploymentCount: 0,
    pending: 0,
    avgTimeSpentOnPlatform: 0,
  });

  const [loading, setLoading] = useState(true);

  const limit = 100; // You can adjust the limit as needed

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = orgnizationId && (await getOrganizationUserStastics(orgnizationId, limit));
        // Update to match the correct API response structure
        if (result) {
          setData(result);
        }
      } catch (error) {
        console.error('Error fetching data:', { error });
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [orgnizationId, limit]);

  return (
    <>
      {/* Title */}
      <Typography
        variant="h6"
        fontWeight="bold"
        className="border-purple-900 border-b-2 rounded-lg  mb-4 mt-4 text-purple-700 "
        gutterBottom
      >
        All User Statistics
      </Typography>

      <Paper className="flex w-full p-6 mt-8">
        <Grid container spacing={2}>
          {/* Card 1: Average Time Spent on Platform */}
          <Grid item xs={10} sm={3}>
            <Card elevation={3}>
              <CardContent className="bg-yellow-300">
                <Typography variant="h6" align="center" gutterBottom>
                  Average Time Spent <br /> on Platform
                </Typography>
                {loading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress color="primary" />
                  </Box>
                ) : (
                  <Typography variant="h4" align="center" color="primary">
                    {data?.avgTimeSpentOnPlatform ? formatTime(data.avgTimeSpentOnPlatform) : 0}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Card 2: Total Users */}
          <Grid item xs={10} sm={3}>
            <Card elevation={3}>
              <CardContent className="bg-blue-300">
                <Typography variant="h6" align="center" gutterBottom>
                  Total <br /> Users
                </Typography>

                {loading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress color="primary" />
                  </Box>
                ) : (
                  <Typography variant="h4" align="center" color="primary">
                    {data?.totalUsersCount ?? 0}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Card 3: Completed Pre-Employment */}
          <Grid item xs={10} sm={3}>
            <Card elevation={3}>
              <CardContent className="bg-green-300">
                <Typography variant="h6" align="center" gutterBottom>
                  Completed <br /> Pre-Employment
                </Typography>
                {loading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress color="primary" />
                  </Box>
                ) : (
                  <Typography variant="h4" align="center" color="primary">
                    {data?.completedPreEmploymentCount ?? 0}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Card 4: Pending */}
          <Grid item xs={10} sm={3}>
            <Card elevation={3}>
              <CardContent className="bg-red-300">
                <Typography variant="h6" align="center" gutterBottom>
                  Pending <br /> Pre-Employment
                </Typography>
                {loading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress color="primary" />
                  </Box>
                ) : (
                  <Typography variant="h4" align="center" color="primary">
                    {data?.pending ?? 0}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
