import { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { AuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { courses } from '../courses/courses';
import { useCourseProgressContext } from '../hooks/use-user-course.hooks';
import { dispatchGetChapterProgress } from '../redux/slice/user-course';
import { useAppDispatch } from '../redux/store';

const CoursesDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const courseProgressContext = useCourseProgressContext();
  const { courseId } = useParams<{ courseId: string }>();
  const course = courses.find((course) => course.id === courseId);
  useEffect(() => {
    if (!Object.keys(courseProgressContext).length) {
      void dispatch(dispatchGetChapterProgress());
    }
  }, [courseProgressContext]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {course && (
        <Container key={course.id}>
          <div className="jumpstart-wrap grid grid-cols-1 md:grid-cols-2 rounded-3xl overflow-hidden my-20">
            <div className="content-section px-8 lg:px-16 flex items-center  py-10">
              <div>
                <h1 className="text-2xl lg:text-5xl lg:leading-16 font-bold text-white mb-6">
                  {course.name}
                </h1>
                <NavLink to={`/course/start/${course.id}`} className="btn-secondary">
                  {`${
                    course.sections.find((section) =>
                      section.chapters.some(
                        (chapter) => chapter.id === courseProgressContext[course.id]?.activeChapter,
                      ),
                    )
                      ? 'RESUME'
                      : 'START'
                  }`}
                </NavLink>
              </div>
            </div>
            <div className="img-section">
              <img src={course.course_image} alt={course.name} />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export const CoursesDetailPage: React.FC = () => {
  return <AuthenticatedPage render={() => <CoursesDetails />} />;
};
