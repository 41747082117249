import { useEffect, useState } from 'react';

import JeffreyGitomer from '../../../../assets/images/courses/communication-basics/chapter12/JeffreyGitomer.jpg';
import QuoteImg from '../../../../assets/images/courses/communication-basics/chapter12/QuoteImg.jpg';
import { course_questions as courseQuestions } from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { MediaBlock } from '../../MediaBlock';
import { TabsBlock } from '../../TabsBlock';
import { TextBlock } from '../../TextBlock';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const CommunicationBasicsChapter12: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const TabsData = [
    {
      title: 'Grammar:  ',
      htmlContent: `Grammar refers to the way we arrange our words. When a sentence is grammatically accurate, it will contain the right words in the right order. `,
    },
    {
      title: 'Spelling:   ',
      htmlContent: `Spelling deals with individual letters that make up our words. A spelling mistake could be the result of a careless typo or because we don’t know how to spell a word correctly. Some of the most common spelling mistakes happen when we confuse two words that sound the same such as “your” and “you’re.”`,
    },
    {
      title: 'Punctuation: ',
      htmlContent: `Punctuation refers to the symbols we use to break up our sentences. Consider how commas build needed pauses or how sentences feel incomplete when they don’t end with a period, question mark, or exclamation point. `,
    },
  ];
  const accordionData = [
    {
      id: 'panel1',
      title: 'Dangling Modifiers',
      content:
        '<div class="fr-view"><p>A dangling modifier happens when we accidentally define (or modify) the wrong word in a sentence. The problem occurs when we use a descriptive phrase such as, <em>“Having been repaired …,”</em> but the word that follows the phrase doesn’t relate to the description.&nbsp;</p><p>&nbsp;</p><p>Let’s say we said: <em>“Having been repaired, <strong><em>we</em></strong> were ready to use the car.”</em> In that instance, the reader is led to believe <em>“<strong>we</strong>” </em>were repaired, which makes little sense. Consider how the following sentence is more accurate: <em>“Having been repaired, <strong><em>the car&nbsp;</em></strong>was ready to use.”</em></p><p>&nbsp;</p><p><strong>Tip:&nbsp;</strong>The noun that immediately follows a descriptive phrase should refer to who or what that phrase is about.</p></div>',
    },
    {
      id: 'panel2',
      title: 'Incomplete Comparisons',
      content:
        '<div class="fr-view"><p>An incomplete comparison happens when we compare two or more things, but we dont explain what those things are.&nbsp;</p><p>&nbsp;</p><p>For example, if we were to say: <em>“My new car is stronger and faster,”</em> our readers will wonder: Stronger and faster than what? We could improve the clarity of that message by adding more details: <em>“My new car is stronger and faster&nbsp;</em><strong><em>than Mike’s truck.</em></strong><em>”&nbsp;</em></p><p><br></p><p><strong>Tip:</strong> Remember, all comparisons need at least two items to work.</p></div>',
    },
    {
      id: 'panel3',
      title: 'Me vs. I',
      content:
        '<div class="fr-view"><p>Mixing up <em>“me”</em> versus <em>“I”</em> is a common mistake. Thankfully, there’s an easy fix. If the action is being done for you, use <em>“<strong>me.</strong>”</em> If you’re the one doing the acting, use <em>“<strong>I</strong>.”</em><em> </em></p><p>&nbsp;</p><p>For example: Consider how the question <em>“Can you send the report to Sarah and I?</em>” is grammatically incorrect because you’re not the one performing the action.</p><p>&nbsp;</p><p><strong>Tip:</strong> If you’re struggling, here’s a trick: Try taking the other person out of the sentence and see if everything sounds okay. <em>“Can you send the report to I?”</em> definitely doesn’t look right. Consider how a grammatically correct sentence sounds better: <em>“Can you send the report to <strong>me</strong>?”</em></p></div>',
    },
    {
      id: 'panel4',
      title: 'Which vs. That',
      content:
        '<div class="fr-view"><p><em>“Which” </em>and <em>“that”</em> are often used interchangeably, but they mean two very different things. If you’re giving information that’s vital to your sentence, use <em>“that.”</em> If the details are supplementary, use <em>“which.”</em></p><p>&nbsp;</p><p>For instance, the sentence<em> “The table <strong>that’s blue&nbsp;</strong>is in the basement” </em>uses <em>“that”</em> to distinguish how the table is a different color from the others.&nbsp;</p><p>&nbsp;</p><p>If we changed that sentence to: <em>“The table, <strong>which is blue</strong>, is in the basement,” </em>we’ve altered the sentence’s meaning. Now, the table’s color is background information. The detail is nice, but it’s not essential, and we can assume this is the only table in the basement.&nbsp;</p><p>&nbsp;</p><p><strong>Tip:</strong> If your information is essential, use <em>“that.”</em> If your information is nonessential, use <em>“which.”</em></p></div>',
    },
    {
      id: 'panel5',
      title: 'Who vs. Whom',
      content:
        '<div class="fr-view"><p>There’s a lot of confusion over the difference between <em>“who”</em> and <em>“whom.”</em> We use <em>“who”&nbsp;</em>when describing the subject of a sentence (the person or thing doing something) and <em>“whom”&nbsp;</em>when describing the object of a sentence (the person or thing having something done to it).&nbsp;</p><p>&nbsp;</p><p>For instance, we’d say: <em>“<strong><em>Who</em></strong> wrote this beautiful letter”</em> because <em>“who”</em> is the subject (the person doing the action).</p><p>&nbsp;</p><p><strong>Tip:&nbsp;</strong>There’s an easy trick for distinguishing between <em>“who”&nbsp;</em>and “whom.” Use <em>“<strong>who</strong>”</em> if you can replace the word with <em>“<strong>he</strong>”&nbsp;</em>or <em>“<strong>she</strong>.”</em> Use <em>“<strong>whom</strong>”</em> if you can replace the world with <em>“<strong>him</strong>”</em> or <em>“<strong>her</strong>.”&nbsp;</em></p><p>&nbsp;</p><p>In the example above, “who” is correct because <em>“<strong>He</strong> wrote this beautiful letter”</em> makes sense, whereas <em>“<strong>Him</strong> wrote this beautiful letter”</em> does not.</p></div>',
    },
  ];
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );

  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      const userAnswers: AnswersState = {};
      userAnswerResponse.map((userAnswer) => {
        userAnswers[userAnswer.question_id || ''] = {
          chapter_id: chapter.id,
          question_id: userAnswer.question_id || '',
          question: userAnswer.question || '',
          is_correct: userAnswer.isCorrect || false,
          answer_options: userAnswer.answers || [],
        };
      });

      setAnswers((prevAnswers) => {
        return {
          ...prevAnswers,
          ...userAnswers,
        };
      });
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);

  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">
          How to Improve Your Grammar, Spelling, and Punctuation
        </h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock mediaImage={QuoteImg}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            Attention; whom was the last to leave, the conference room Having finished the meeting:
            the papers were left they’re on the table.
          </p>
        </MediaBlock>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-16">
          What’s your impression of the above message? Are you stumbling over the language and
          punctuation?
          <br />
          <br />
          If so, you’re not alone.
        </p>
        <p className="mb-8 text-xl font-light">
          Deciding how to structure your writing isn’t always easy. When looking at a blank page,
          that blinking cursor can feel intimidating to even the most skilled business writers. You
          might have a few questions: Where do I begin? What do I say in the middle? How do I
          conclude?
          <br />
          <br />
          Here’s a simple trick: Begin with your general concept, give your details in the middle,
          and conclude with one last statement that brings it all together. This is called a
          broad-narrow-broad approach, and it’s a great way to keep your writing logical, organized,
          and easy to follow.
          <br />
          <br />
          In this lesson, we’ll explain exactly how the broad-narrow-broad approach works. You’ll
          learn the benefits of keeping your writing structured, and how to lay out your
          introduction, body paragraphs, and conclusion most effectively.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/AFXfa8jcN_ZH5f74wUC4NcBGgrxm353L/svVmBcGK/assets/rbc/wuVC8FMexurU3SC2_COMM-WW-05.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          The Difference Between Grammar, Spelling, and Punctuation
        </h3>
        <p className="mb-8">
          Before we explore some of the most common grammar, spelling, and punctuation errors, let’s
          take a look at how we define these terms. Click each tab to learn more.
        </p>
        <TabsBlock tabsData={TabsData} />
        <MediaBlock mediaImage={JeffreyGitomer}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            Your grammar is a reflection of your image. Good or bad, you have made an impression.
            And like all impressions, you are in total control.
            <br />
            <span className="text-base font-bold">Jeffrey Gitomer</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          How to Improve Your Grammar
        </h3>
        <p className="mb-8">
          It’s easy for grammar mistakes to slip through the cracks. Sometimes the sentences we say
          in our heads aren’t translated well on paper. Expand the rows below by clicking the (+)
          signs to learn about some of the most common grammar mistakes and how to fix them.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          How to Improve Your Spelling
        </h3>
        <p className="mb-8">
          As helpful as your computer’s spell-checker might be, it will likely miss homonyms. These
          are words that sound the same but have different spellings (and meanings). Flip the cards
          below to uncover some of the most common homonyms and spelling mistakes.
        </p>
        <TextBlock title="Your vs. You’re">
          <p>
            Use “
            <strong>
              <em>your</em>
            </strong>
            ” to show ownership: “This is{' '}
            <strong>
              <em>your</em>
            </strong>{' '}
            desk.”
          </p>

          <p>
            Use “
            <strong>
              <em>you’re</em>
            </strong>
            ” as a shortened form of “
            <strong>
              <em>you are</em>
            </strong>
            .” For example: “
            <strong>
              <em>You’re</em>
            </strong>{' '}
            doing great.”
          </p>
        </TextBlock>
        <TextBlock title="Its vs. It’s">
          <p>
            Use “
            <strong>
              <em>its</em>
            </strong>
            ” (without an apostrophe) to show ownership: “The company lost{' '}
            <strong>
              <em>its</em>
            </strong>{' '}
            license.”&nbsp;
          </p>
          <p></p>
          <p>
            Use “
            <strong>
              <em>it’s</em>
            </strong>
            ” (with an apostrophe) as a shortened form of “
            <em>
              <strong>it is</strong>
            </em>
            ” or “
            <strong>
              <em>it has</em>
            </strong>
            .” For example: “
            <strong>
              <em>It’s</em>
            </strong>{' '}
            cold outside!”
          </p>
        </TextBlock>
        <TextBlock title="Their vs. There vs. They’re">
          <p>
            Use “
            <strong>
              <em>their</em>
            </strong>
            ” to show ownership: “That’s{' '}
            <strong>
              <em>their</em>
            </strong>{' '}
            house.”
          </p>
          <p></p>
          <p>
            Use “
            <strong>
              <em>there</em>
            </strong>
            ” to reference a location: “It’s over{' '}
            <strong>
              <em>there</em>
            </strong>
            .”&nbsp;
          </p>
          <p></p>
          <p>
            Use “
            <strong>
              <em>they’re</em>
            </strong>
            ” as a shortened form of “
            <strong>
              <em>they are</em>
            </strong>
            .” For example: “
            <strong>
              <em>They’re</em>
            </strong>{' '}
            my favorite customers.”
          </p>
        </TextBlock>
        <TextBlock title="Then vs. Than">
          <p>
            Use “
            <strong>
              <em>then</em>
            </strong>
            ” to show a sequence of events: “Come to my house, and{' '}
            <strong>
              <em>then</em>
            </strong>{' '}
            we’ll have lunch.”
          </p>
          <p></p>
          <p>
            Use “
            <strong>
              <em>than</em>
            </strong>
            ” to compare and contrast different items: “My desk is messier{' '}
            <strong>
              <em>than</em>
            </strong>{' '}
            yours.”
          </p>
        </TextBlock>
        <TextBlock title="By vs. Buy vs. Bye">
          <p>
            Use “
            <strong>
              <em>by</em>
            </strong>
            ” to refer to a location such as: “It’s{' '}
            <strong>
              <em>by</em>
            </strong>{' '}
            the table.”&nbsp;
          </p>
          <p></p>
          <p>
            Use “
            <strong>
              <em>buy</em>
            </strong>
            ” to refer to a purchase: “I’ll{' '}
            <strong>
              <em>buy</em>
            </strong>{' '}
            it for you.”
          </p>
          <p></p>
          <p>
            Use “<strong>bye</strong>” to say goodbye: “
            <strong>
              <em>Bye</em>
            </strong>
            ! I’ll see you next time.”
          </p>
        </TextBlock>
        <TextBlock title="Affect vs. Effect">
          <p>
            “
            <strong>
              <em>Affect</em>
            </strong>
            ” is normally a verb meaning “to influence or have an impact on something.” For example:
            “The cold{' '}
            <strong>
              <em>affects</em>
            </strong>{' '}
            my health.”
          </p>
          <p></p>
          <p>
            “
            <strong>
              <em>Effect</em>
            </strong>
            ” is normally a noun referring to the result of a situation. For example, “My coffee had
            an immediate{' '}
            <strong>
              <em>effect</em>
            </strong>{' '}
            on my mood.”
          </p>
        </TextBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          How to Improve Your Punctuation
        </h3>
        <p className="mb-8">
          By using the right punctuation, you can help break up your sentences and clarify your
          message. Here are six common types of punctuation and how to use them properly:
        </p>
        <ul className="list-decimal pl-7 mt-8 mb-10 text-xl">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Full stops.</span> At the end of every sentence, you’ll need
            a full stop. These stops can be a period (.), question mark (?), or exclamation point
            (!). Avoid run-on sentences by adding a full stop as soon as you’ve completed a thought.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Commas.</span> Commas (,) are used to break up sentences,
            encouraging your reader to pause and slow down. Use a comma after introductory phrases
            such as: “In the morning, it’s cold.” Also, use a comma to separate two clauses (groups
            of words that contain a subject and a verb) that depend on one another. For example: “I
            should get up, but I’m exhausted.” Finally, always use commas to separate items in a
            list: “You’ll need flour, sugar, and vanilla to make that cake.”
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Colons.</span> Colons (:) can be used after a complete
            sentence to introduce a list or add an explanation. For example, with a list, you might
            say: “Great writing includes being mindful of three elements: spelling, grammar, and
            punctuation.” When providing additional information, you might say: “Jake had only one
            thing left to do: Call his boss.”
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Semicolons.</span> Semicolons (;) connect two closely
            related, complete sentences. They indicate a stronger pause than a comma but not a full
            stop like a period, question mark, or exclamation point. For example, consider how these
            two related sentences are connected with a semicolon: “It’s getting dark; we should go
            back.”
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Apostrophes.</span> There are two ways we can use
            apostrophes ('). First, apostrophes show that letters are missing from a contraction.
            For example, in the sentence: “I shouldn’t go,” the apostrophe shows how an “o” is
            missing from the word “should not.” You can also use an apostrophe to show ownership. If
            the item belongs to one person, put the apostrophe before the possessive “s”: “That is
            Michael's office.” If the item belongs to more than one person, add the apostrophe after
            the possessive “s”: “This is the girls' bedroom.”
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Quotation marks.</span> Quotation marks (“) indicate direct
            speech, such as in the sentence: He stood up and yelled: “I’ve had enough!” Unless
            you’re ending a sentence with a semicolon, colon, or question mark (that isn’t part of
            the quote), you should enclose all punctuation within your quotations.
          </li>
        </ul>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Can You Spot the Correct Sentence?
        </h3>
        <div className="pt-8">
          <CourseQuestionCard
            submittedAnswer={userCourseQuestionResponseContext[
              chapterQuestions[0].course_id
            ]?.chapters[chapter.id]?.questions?.find(
              (x) => x.question_id === chapterQuestions[0].id,
            )}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetakeQuestion={handleQuestionRetake}
            label="Question 1 of 3"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmitAnswer={handleCourseAnswerSubmit}
            question={chapterQuestions[0]}
          />
        </div>

        <div className="pt-8">
          <CourseQuestionCard
            submittedAnswer={userCourseQuestionResponseContext[
              chapterQuestions[0].course_id
            ]?.chapters[chapter.id]?.questions?.find(
              (x) => x.question_id === chapterQuestions[1].id,
            )}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetakeQuestion={handleQuestionRetake}
            label="Question 2 of 3"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmitAnswer={handleCourseAnswerSubmit}
            question={chapterQuestions[1]}
          />
        </div>

        <div className="pt-8">
          <CourseQuestionCard
            submittedAnswer={userCourseQuestionResponseContext[
              chapterQuestions[0].course_id
            ]?.chapters[chapter.id]?.questions?.find(
              (x) => x.question_id === chapterQuestions[2].id,
            )}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetakeQuestion={handleQuestionRetake}
            label="Question 3 of 3"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmitAnswer={handleCourseAnswerSubmit}
            question={chapterQuestions[2]}
          />
        </div>

        <p className="text-2xl text-center leading-10 font-light mb-10 mt-10">
          Even if you know the rules of grammar, spelling, and punctuation, it's easy to make
          careless mistakes when you're short on time. That's why it's important to edit and
          proofread your work before you send it off. In the next lesson, we'll learn more about how
          to do that effectively.
        </p>

        <button
          onClick={handleNextChapterClick}
          disabled={isPageDirty}
          className="btn-primary w-full uppercase"
        >
          How to Edit and Proofread Your Work
        </button>
      </div>
    </>
  );
};
