import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import {
  getIntakeFormValues,
  updateCareerConstraints,
  updateCareerPlan,
  updateEducation,
  updateIntakeFormAge,
  updateLanguageProficiency,
  updateOrganization,
  updatePersonalInfo,
  updateWorkHistory,
} from '../../../services/database/form-intake';

import {
  IntakeFormState,
  updateAllAction,
  updateAgeRangeAction,
  updatePersonalInfoAction,
  updateEducationAction,
  updateLanguageProficiencyAction,
  updateWorkHistoryAction,
  updateCareerPlanAction,
  updateCareerConstraintsAction,
  updateOrganizationAction,
} from './form-intake.slice';
import {
  IntakeFormAgeRangeDTO,
  IntakeFormCareerConstraintsDTO,
  IntakeFormCareerPlanDTO,
  IntakeFormEducationDTO,
  IntakeFormLanguageProficiencyDTO,
  IntakeFormWorkHistoryDTO,
  OrganizationDTO,
  PersonalInfoDTO,
} from './form-intake.types';

/**
 * retrieve previously saved values from the intake form
 */
export const dispatchGetIntakeFormValues =
  () =>
  async (dispatch: Dispatch<PayloadAction<IntakeFormState>>): Promise<void> => {
    const values: IntakeFormState = await getIntakeFormValues();
    dispatch(updateAllAction(values));
  };

/**
 * update the age range in the intake form
 */
export const dispatchUpdateIntakeFormAgeRange =
  (dto: IntakeFormAgeRangeDTO) =>
  async (dispatch: Dispatch<PayloadAction<IntakeFormAgeRangeDTO>>): Promise<void> => {
    await updateIntakeFormAge(dto);
    dispatch(updateAgeRangeAction(dto));
  };

/**
 * update personal information in the intake form
 */
export const dispatchUpdateIntakeFormPersonalInfo =
  (dto: PersonalInfoDTO) =>
  async (dispatch: Dispatch<PayloadAction<PersonalInfoDTO>>): Promise<void> => {
    await updatePersonalInfo(dto);
    dispatch(updatePersonalInfoAction(dto));
  };

/**
 * update education in the intake form
 */
export const dispatchUpdateIntakeFormEducation =
  (dto: IntakeFormEducationDTO) =>
  async (dispatch: Dispatch<PayloadAction<IntakeFormEducationDTO>>): Promise<void> => {
    await updateEducation(dto);
    dispatch(updateEducationAction(dto));
  };

/**
 * update language proficiency in the intake form
 */
export const dispatchUpdateIntakeFormLanguageProficiency =
  (dto: IntakeFormLanguageProficiencyDTO) =>
  async (dispatch: Dispatch<PayloadAction<IntakeFormLanguageProficiencyDTO>>): Promise<void> => {
    await updateLanguageProficiency(dto);
    dispatch(updateLanguageProficiencyAction(dto));
  };

/**
 * update work history in the intake form
 */
export const dispatchUpdateIntakeFormWorkHistory =
  (dto: IntakeFormWorkHistoryDTO) =>
  async (dispatch: Dispatch<PayloadAction<IntakeFormWorkHistoryDTO>>): Promise<void> => {
    await updateWorkHistory(dto);
    dispatch(updateWorkHistoryAction(dto));
  };

/**
 * update career plan in the intake form
 */
export const dispatchUpdateIntakeFormCareerPlan =
  (dto: IntakeFormCareerPlanDTO) =>
  async (dispatch: Dispatch<PayloadAction<IntakeFormCareerPlanDTO>>): Promise<void> => {
    await updateCareerPlan(dto);
    dispatch(updateCareerPlanAction(dto));
  };

/**
 * update career constraints in the intake form
 */
export const dispatchUpdateIntakeFormCareerConstraints =
  (dto: IntakeFormCareerConstraintsDTO) =>
  async (dispatch: Dispatch<PayloadAction<IntakeFormCareerConstraintsDTO>>): Promise<void> => {
    await updateCareerConstraints(dto);
    dispatch(updateCareerConstraintsAction(dto));
  };

/**
 * update personal information in the intake form
 */
export const dispatchUpdateIntakeFormUserOrganization =
  (dto: OrganizationDTO) =>
  async (dispatch: Dispatch<PayloadAction<OrganizationDTO>>): Promise<void> => {
    await updateOrganization(dto);
    dispatch(updateOrganizationAction(dto));
  };
