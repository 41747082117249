/**
 * splits an array into equal chunks
 * used to split questions into different pages
 *
 * @param questions
 * @param numChunks
 */
export function splitArrayIntoChunks<T>(array: T[], numChunks: number): Array<T[]> {
  const length: number = array.length;
  const partSize: number = Math.ceil(length / numChunks);
  const parts: T[][] = [];

  for (let i = 0; i < numChunks; i++) {
    const start: number = i * partSize;
    const end: number = Math.min(start + partSize, length);
    parts.push(array.slice(start, end));
  }

  return parts;
}
