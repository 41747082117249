import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import {
  IntakeFormAgeRangeDTO,
  IntakeFormCareerConstraintsDTO,
  IntakeFormCareerPlanDTO,
  IntakeFormEducationDTO,
  IntakeFormLanguageProficiencyDTO,
  IntakeFormWorkHistoryDTO,
  OrganizationDTO,
  PersonalInfoDTO,
} from './form-intake.types';

export type IntakeFormState = Partial<
  IntakeFormAgeRangeDTO &
    PersonalInfoDTO &
    IntakeFormEducationDTO &
    IntakeFormLanguageProficiencyDTO &
    IntakeFormWorkHistoryDTO &
    IntakeFormCareerPlanDTO &
    IntakeFormCareerConstraintsDTO &
    OrganizationDTO
>;

const initialState: IntakeFormState = {
  name: '',
  addressLine1: '',
  city: '',
  state: '',
  phoneCountryCode: undefined,
  phone: '',
  email: '',
  ageRange: undefined,
  education: undefined,
  workStatus: [],
  workStatusComments: undefined,
  languageProficiency: {
    English: undefined,
    Spanish: undefined,
  },
  workHistory: undefined,
  careerPlan: undefined,
  careerPlanRemarks: undefined,
  careerPlanDirection: undefined,
  careerConstraints: [],
  careerConstraintComments: undefined,
  organization: undefined,
};

const intakeFormSlice = createSlice({
  name: 'formIntake',
  initialState,
  reducers: {
    updateAll: (state, action: PayloadAction<IntakeFormState>) => {
      logger.debug('Updating intake form state: all data.', { state, action });
      Object.assign(state, { ...action.payload });
    },
    updateAgeRange: (state, action: PayloadAction<IntakeFormAgeRangeDTO>) => {
      logger.debug('Updating intake form state: ageRange.', { state, action });
      state.ageRange = action.payload.ageRange;
      state.age = action.payload.age;
      state.gender = action.payload.gender;
      state.otherGender = action.payload.otherGender;
    },
    updatePersonalInfo: (state, action: PayloadAction<PersonalInfoDTO>) => {
      logger.debug('Updating intake form state: personal information.', {
        state,
        action,
      });
      Object.assign(state, { ...action.payload });
    },
    updateEducation: (state, action: PayloadAction<IntakeFormEducationDTO>) => {
      logger.debug('Updating intake form state: education and work status.', {
        state,
        action,
      });
      Object.assign(state, { ...action.payload });
    },
    updateLanguageProficiency: (state, action: PayloadAction<IntakeFormLanguageProficiencyDTO>) => {
      logger.debug('Updating intake form state: language proficiency.', {
        state,
        action,
      });
      state.languageProficiency = action.payload.languageProficiency;
    },
    updateWorkHistory: (state, action: PayloadAction<IntakeFormWorkHistoryDTO>) => {
      logger.debug('Updating intake form state: work history.', {
        state,
        action,
      });
      state.workHistory = action.payload.workHistory;
      state.workHistorySelfEmployedComments = action.payload.workHistorySelfEmployedComments;
      state.workHistoryOtherComments = action.payload.workHistoryOtherComments;
      state.workHistoryOrganizationName = action.payload.workHistoryOrganizationName;
      state.workHistoryTotalExperienceInMonth = action.payload.workHistoryTotalExperienceInMonth;
      state.workHistoryFirstWorkplace = action.payload.workHistoryFirstWorkplace;
      state.workHistoryFirstJobTitle = action.payload.workHistoryFirstJobTitle;
      state.workHistoryCurrentJobRole = action.payload.workHistoryCurrentJobRole;
      state.workHistoryFutureJobRole = action.payload.workHistoryFutureJobRole;
      state.workHistoryJobSatisfactionRating = action.payload.workHistoryJobSatisfactionRating;
    },
    updateCareerPlan: (state, action: PayloadAction<IntakeFormCareerPlanDTO>) => {
      logger.debug('Updating intake form state: career plan.', {
        state,
        action,
      });
      state.careerPlan = action.payload.careerPlan;
      state.careerPlanDirection = action.payload.careerPlanDirection;
      state.careerPlanRemarks = action.payload.careerPlanRemarks;
    },
    updateCareerConstraints: (state, action: PayloadAction<IntakeFormCareerConstraintsDTO>) => {
      logger.debug('Updating intake form state: career constraints.', {
        state,
        action,
      });
      state.careerConstraints = action.payload.careerConstraints;
      state.careerConstraintComments = action.payload.careerConstraintComments;
    },
    updateOrganization: (state, action: PayloadAction<OrganizationDTO>) => {
      logger.debug('Updating intake form state: organization.', {
        state,
        action,
      });
      Object.assign(state, { ...action.payload });
    },
  },
});

export const {
  updateAll: updateAllAction,
  updateAgeRange: updateAgeRangeAction,
  updatePersonalInfo: updatePersonalInfoAction,
  updateEducation: updateEducationAction,
  updateLanguageProficiency: updateLanguageProficiencyAction,
  updateWorkHistory: updateWorkHistoryAction,
  updateCareerPlan: updateCareerPlanAction,
  updateCareerConstraints: updateCareerConstraintsAction,
  updateOrganization: updateOrganizationAction,
} = intakeFormSlice.actions;
export default intakeFormSlice.reducer;
