import { useEffect, useState } from 'react';

import m1 from '../../../../assets/images/courses/emotional-intelligence/chapter2/m1.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { course_questions } from '../../../../courses/emotional-intelligence/emotional-intelligence-course-question';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  UserChapterQuestion,
  dispatchUpdateUserCourseQuestionResponse,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CarouselComponent } from '../../CarouselComponent';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import FlipCard from '../../FlipCard';
import { InfoBlock } from '../../InfoBlock';
import { MediaBlock } from '../../MediaBlock';
import { TabsBlock } from '../../TabsBlock';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const EmotionalIntelligence2: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);
  const [, setAnswers] = useState<AnswersState>({});
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();

  const chapterQuestions = course_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(false);
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const TabsData = [
    {
      title: 'Emotional self-awareness',
      htmlContent: `Emotional self-awareness is being aware of your emotions. It’s noticing when you’re feeling joyful, sad, anxious, or any other emotion. And, with that awareness, it’s recognizing how those feelings may affect your thoughts, actions, and performance.`,
    },
    {
      title: 'Accurate self-assessment',
      htmlContent: `Accurate self-assessment is understanding your emotional strengths, weaknesses, and limitations. For example, you might know that you tend to yell when frustrated or be closed off when stressed.`,
    },
    {
      title: 'Self-confidence',
      htmlContent: `Self-confidence comes from knowing yourself and your emotional tendencies. Those with high self-confidence don’t let unpredictable events rattle them. Rather, they know who they are, and they’re adept at conveying their thoughts and feelings effectively.`,
    },
  ];
  const accordionData = [
    {
      id: 'panel1',
      title: 'Emotional Outbursts',
      content:
        '<p>On your way to work, you get stuck in bumper-to-bumper traffic. As you inch along, another driver cuts you off, and you miss the light. Frustrated, you honk the horn and spill your coffee in the process.&nbsp;</p><p><br></p><p>You’re going to be late, and you now have a stain on your clothes. You don’t have time to get angry or find the humor in the situation. So, you suppress the feeling, push down the anger, and just focus on <em>“getting there.”&nbsp;</em></p><p><br></p><p>When you finally make it to work, you learn that your team hasn’t finished writing the report for this afternoon’s meeting. It’s the last straw, and you storm out of the office. You “blow up” at the group—yelling about their incompetence and failures—and you refuse to hear their responses as you slam the door.</p>',
    },
    {
      id: 'panel2',
      title: 'Impulsive Decisions ',
      content:
        '<p>Your company is behind the competition, and you need to make some changes. After reading an article about a new piece of technology, you excitedly declare:<em>&nbsp;“This is it!”&nbsp;</em>You’re fired up, feeling great, and are sure you’ve found the solution.</p><p><br></p><p>You don’t bother consulting with your team. You take immediate action, overspend on the technology, and only later find that it’s not the solution. You let your enthusiasm get in the way of logical reasoning, and that mistake sets your company back.</p>',
    },
    {
      id: 'panel3',
      title: 'Unpredictable Behavior ',
      content:
        '<p>When you’re in a good mood, you take your team out to lunch, offer bonuses, and congratulate them on their work. But when you’re frustrated or stressed, you take those emotions out on your employees—nitpicking their performance, yelling about inconsequential details, and causing everyone to walk on eggshells.</p><p><br></p><p>You’re unpredictable. Behind your back, employees call you a loose cannon who’s hard to follow and difficult to work for. You let your emotions run you (rather than the other way around), and that impacts your actions and the feelings of those around you. &nbsp;</p>',
    },
  ];
  const slidesData = [
    {
      heading: '5 Tips for Improving Self-Awareness',
      paragraph: `<div class="fr-view"><p>So, how can you improve your self-awareness? It starts with paying attention and asking questions. Click through this interaction to learn about five tips you can follow.</p></div>`,
      list: [],
    },
    {
      heading: 'Feel and Accept Your Emotions',
      paragraph: `<div class="fr-view"><p>Emotions are sources of information, and they shouldn’t be suppressed or bottled up. Rather than fighting against your feelings, focus on them. Give them names, sit with them, and let them run their course. 

      Regularly ask yourself, “How do I feel right now?” and “Why am I choosing that label?” Recognition and acceptance will lead you to awareness.</p></li></ul></div>`,
      list: [],
    },
    {
      heading: 'Keep a Reflective Journal',
      paragraph: `<div class="fr-view"><p>Make a habit of reflecting on your emotions. Keep a journal and record how the day’s events made you feel. How did you react to those feelings? You might write: “I worked late today, which made me bitter and upset. I then ignored my family when I got home.”

      By keeping a journal, you’ll spot patterns—understanding your emotional triggers and typical responses to those triggers. </p></li></ul></div>`,
      list: [],
    },

    {
      heading: 'Notice Your Physical Reactions',
      paragraph: `<div class="fr-view"><p>We often store emotions in our bodies. Anxiety, for instance, might cause sweaty palms, a rapid heartbeat, and tense muscles. By recognizing those reactions, you can start to understand your internal signals before noticing the emotion. 

      So, make a habit of performing regular body scans during different emotional states (such as when you’re relaxed or returning home after a stressful day). Ask yourself: “How’s my breathing, heart rate, and muscle tension? Are they different than usual?” </p></div>`,
      list: [],
    },
    {
      heading: 'Reflect with “What” Questions',
      paragraph: `<div class="fr-view"><p>When reflecting on our emotions, we tend to focus on “why” instead of “what.” We say, “Why did I yell at everyone?” or “Why was I so nervous?” But with “why” questions, we often invent our own answers and focus on blame. We say things like, “I have an anger problem” or “I’m just a nervous person.” 

      To build self-awareness, figure out what's happening instead. For example, you might ask: “What was it about the event that made me nervous? What did this situation have in common with past experiences?”</p></div>`,
      list: [],
    },
    {
      heading: 'Seek Regular Feedback',
      paragraph: `<div class="fr-view"><p>Our thoughts and actions don’t always line up. For example, you might think you’re assertive, but others may perceive that same behavior as bullying. So, ask questions and seek frequent feedback from senior leaders, peers, and subordinates. 

      By asking for feedback and being open to those responses, you’ll gain awareness of not only how you see yourself, but how others see you as well.</p></div>`,
      list: [],
    },
  ];

  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext, isPageDirty]);
  return (
    <>
      <div className="content-wrap border-l-0 lg:border-l pl-0 lg:pl-8 pt-8 pb-10">
        <h2 className="font-semibold text-3xl">Improving Self-Awareness</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">A Sudden Change</h3>
        <div>
          <p>
            Imagine you’re at work and checking items off your to-do list in preparation to get the
            restaurant open. You smile to yourself, noticing how you’re feeling relaxed, satisfied,
            and calm. But then, your boss calls:
          </p>
          <p className="text-3xl my-8 leading-10 font-light text-center">
            <em>
              “We just got a last minute booking at the restaurant, there will be 50 people arriving
              in 30 minutes.”
            </em>
          </p>
          <br />
          <p>
            Immediately, you recognize the emotions flooding in—anxiety, stress, anger, and
            frustration. Your heart pounds and you feel lightheaded.
          </p>
          <br />
          <p>
            The above scenario is an example of self-awareness—a proficiency that’s not always easy
            to cultivate. In this lesson, you’ll learn to define self-awareness, pinpoint why it's
            important, and develop this skill with five tips.
          </p>
        </div>

        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/pmm84TfAS-6slTdJ5v37--eMH1JMGGF7/dpSdzXnW/assets/rbc/kkyeS1HlR_FZGOnb_LDRSHP-EQ-02.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <p>
          Watch this video to learn what self-awareness is, why it’s important, and how you can
          develop self-awareness with five tips.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">What Is Self-Awareness?</h3>
        <p>
          As the cornerstone of emotional intelligence, self-awareness covers three components. Flip
          the cards below by clicking on them to learn more.
        </p>
        <div className="my-10">
          <div className="flex justify-center gap-5 text-2xl font-light">
            <FlipCard>
              <div className="front-content">
                <p>Recognize</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center text-lg">
                <p>
                  You <strong>recognize</strong> and label your feelings—even when emotions are
                  running high.&nbsp;
                </p>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content">
                <p>Understand</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center text-lg">
                <p>
                  You <strong>understand</strong> why you're experiencing an emotion.
                </p>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content">
                <p>Influence</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center text-lg">
                <p>
                  You spot how your emotions <strong>influence</strong> your behavior and that of
                  others.
                </p>
              </div>
            </FlipCard>
          </div>
        </div>
        <p className="text-3xl my-8 leading-10 font-light text-center">
          Self-awareness is understanding how the external world influences your internal reality.
          It revolves around recognizing your emotions and their effects.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">3 Types of Self-Awareness</h3>
        <p>We can split self-awareness into three categories:</p>
        <br />
        <p className="mb-7">Click each tab to learn more.</p>
        <TabsBlock tabsData={TabsData} />
        <MediaBlock mediaImage={m1}>
          <p className="text-3xl leading-10 font-light w-3/4">
            To have greater self-awareness or understanding means to have a better grasp of reality.
            <br />
            <span className="font-bold text-base">Dalai Lama</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          The Importance of Self-Awareness
        </h3>
        <p>
          Without strong self-awareness, we’re prone to ignoring our emotions and being victims of
          circumstance. If we don’t acknowledge how we’re feeling, our emotions may come out in
          unexpected ways—controlling our decisions, influencing our actions, and negatively
          affecting our performance.
        </p>
        <br />
        <p className="mb-7">
          Expand the rows below by clicking on the (+) signs to see what might happen if you lack
          self-awareness.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <p className="text-3xl my-8 leading-10 font-light text-center">
          When we ignore our emotions, our behavior is reactionary. We allow our feelings to distort
          reality and make decisions for us. To think logically and take back control, you must
          cultivate self-awareness and recognize the impact of your emotions.
        </p>
        {/*carusel  */}
        <div className="border rounded-3xl mt-8">
          <CarouselComponent data={slidesData} />
        </div>

        <div className="mb-8">
          <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">Apply Your Understanding</h3>
          <p className="mb-3">
            Reflect on what you've learned by completing the following knowledge check.
          </p>
          {/* Questions card */}
          <CourseQuestionCard
            submittedAnswer={userCourseQuestionResponseContext[
              chapterQuestions[0].course_id
            ]?.chapters[chapter.id]?.questions?.at(0)}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmitAnswer={handleCourseAnswerSubmit}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetakeQuestion={handleQuestionRetake}
            question={chapterQuestions[0]}
          />
        </div>
        <InfoBlock
          title="Keep practicing. "
          text="A one-time reflection or feedback session isn’t enough. To develop self-awareness, you must make a conscious, everyday commitment to practice awareness. So, make a habit of regularly checking in with your experiences, noticing your feelings, and using every moment as an opportunity to learn more about yourself and your emotions."
        />
        <p className="text-3xl my-8 leading-10 font-light text-center">
          With heightened self-awareness, you'll make your unconscious emotions conscious. In the
          next lesson, you'll learn how to regulate those emotions.
        </p>
        <button
          onClick={handleNextChapterClick}
          disabled={isPageDirty}
          className="btn-primary w-full uppercase"
        >
          Boosting Self-Regulation
        </button>
      </div>
    </>
  );
};
