import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { ExperienceAssessmentState } from '../../redux/slice/form-experience/form-experience.slice';
import { UpdateExperienceAssessmentDTO } from '../../redux/slice/form-experience/form-experience.types';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.EXPERIENCE_RESPONSES;

/**
 * upsert a record into the skills responses collection
 */
async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

/**
 * retrieves the values of the previously inputted experience assessment if available
 */
export async function getExperienceAssessmentValues(): Promise<ExperienceAssessmentState> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<ExperienceAssessmentState> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return result.data() ?? {};
  } catch (e) {
    logger.error('Failed to retrieve experience assessment values.', e);
    throw e;
  }
}

/**
 * updates the experience assessment
 */
export async function updateExperienceAssessment(
  dto: UpdateExperienceAssessmentDTO,
): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved experience assessment.');
  } catch (e) {
    logger.error('Failed to update experience assessment.', e);
    throw e;
  }
}
