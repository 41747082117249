import { Close, Done } from '@mui/icons-material';
import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

import passingline from '../../assets/icons/passingline.svg';

export const CircularProgressWithLabel: React.FC<CircularProgressProps & { value: number }> = (
  props,
) => {
  const calculateSize = () => {
    // You can adjust this logic based on your requirements
    const screenWidth = window.innerWidth;
    return screenWidth > 600 ? '20rem' : '16rem';
  };

  const size = calculateSize();

  return (
    <Box className="dotted-line" sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          position: 'absolute',
          left: 0,
          transform: 'rotate(125deg) !important',
        }}
        size={size}
        {...props}
        value={100 - 20}
        thickness={4.5}
      />
      <CircularProgress
        sx={{
          color: '#962CC6',
          transform: 'rotate(125deg) !important',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={size}
        variant="determinate"
        {...props}
        value={(80 / 100) * props.value}
        thickness={4.5}
      />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ fontSize: '47px', color: '#000', fontWeight: 'bold', minWidth: '122px' }}
        >
          <div className="text-center mt-10">
            {props.value >= 80 ? (
              <>
                <Done sx={{ fontSize: ['60px', '100px'], color: '#962CC6' }} />
              </>
            ) : (
              <>
                <Close sx={{ fontSize: ['60px', '100px'] }} />
              </>
            )}
            <div className="mt-5">{`${Math.round(props.value)}%`}</div>
          </div>
        </Typography>
      </Box>
      <div className="absolute md:top-[300px] md:right-[110px] top-[240px] right-[80px] lg:top-[114px] lg:right-[-116px] ">
        <p className="text-sm text-gray-400 font-bold text-right">PASSING 80%</p>
        <img className="hidden lg:block" src={passingline} alt="" />
      </div>
    </Box>
  );
};
