import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import char from '../../assets/images/intake/step13/char.svg';
import { useIntakeFormContext, useSkillsContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import { useUserAsaTaskHistoryContext } from '../../hooks/use-user-asa-task-history-context.hooks';
import { useAuthContext } from '../../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../../hooks/user-user-profile-context.hooks';
import {
  AsaTaskName,
  AsaUserTasks,
  completeASATask,
  dispatchUpdateAsaTaskHistoryInfo,
} from '../../redux/slice/asa-task-history';
import { CustomUser } from '../../redux/slice/auth/CustomUser';
import { ConfidenceLevel, SkillsState, dispatchUpdateSkills } from '../../redux/slice/form-skills';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { dispatchGenerateSWOTReport, sendSwotReport } from '../../redux/slice/swot/swot.operations';
import { GenerateSWOTReportDTO } from '../../redux/slice/swot/swot.types';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import { EnvironmentVariable } from '../../utils/constants';
import { CircularLoader } from '../loader/CircularLoader';

import { skillOptions } from './Communication';

interface ComponentProps {
  step: number;
  onBack: () => void;
}

interface FormData {
  skillStressTolerance: string;
}

export const StressTolerance: React.FC<ComponentProps> = ({ onBack, step }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const asaUserTaskHistory = useUserAsaTaskHistoryContext();
  const currentUser: CustomUser | null = useAuthContext();
  const skills: SkillsState = useSkillsContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentAsaTask, setCurrentAsaTask] = useState<AsaUserTasks | undefined>(undefined);
  const userProfileContext = useUserProfileContext();
  const { organization } = useIntakeFormContext();
  const { skillStressTolerance } = useSkillsContext();

  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
    didCompleteSkills,
    didAsaUserViewedSwot,
    isSWOTGenerated,
  } = usePreEmploymentAssessmentContext();
  const [error, setError] = useState('');
  const [selectedOption, setSelectedOption] = useState(skillStressTolerance);
  const { handleSubmit } = useForm<FormData>();

  const backButtonClick = () => {
    onBack();
  };

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    } else if (didCompleteSkills) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);

  useEffect(() => {
    if (
      currentUser?.claims?.isAsaUser &&
      Object.keys(asaUserTaskHistory).length &&
      asaUserTaskHistory?.tasks?.length
    ) {
      const currentTask = asaUserTaskHistory.tasks?.find(
        (x) => !x.isCompleted && x.taskName === AsaTaskName.SKILL,
      );
      setCurrentAsaTask(currentTask);
      if (currentAsaTask && (currentAsaTask?.taskStep as number) != 11) {
        const url = new URL(currentAsaTask?.taskUrl || '');
        navigate(`${url.pathname}${url.search}`);
        setIsDisabled(true);
      }
    }
  }, [asaUserTaskHistory, currentUser, currentAsaTask]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(parseInt(event.target.value));
    setError('');
  };
  const submitForm: SubmitHandler<FormData> = async () => {
    logger.debug('Submitted stress tolerance skill form.', selectedOption);

    const error_message: string = 'Please select an option';
    setIsLoading(true);
    try {
      if (selectedOption === undefined) {
        setError(error_message);
      } else {
        if (isDisabled) {
          if (organization) {
            navigate('/pre-employment/career-readiness/step1');
          } else if (isSWOTGenerated) {
            if (userProfileContext?.isPremium) {
              navigate('/pre-employment/career-readiness/step1');
            } else {
              navigate('/upgrade');
            }
          } else {
            navigate(`/swot`);
          }
        } else {
          const dto: Record<keyof FormData, ConfidenceLevel> = {
            skillStressTolerance: selectedOption as ConfidenceLevel,
          };
          await dispatch(dispatchUpdateSkills(dto));
          await dispatch(
            dispatchUpsertPreEmploymentAssessment({
              didCompleteSkills: true,
              syncedToAirtable: false,
            }),
          );
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: !_.isNil(skillStressTolerance) ? progress : totalProgress,
                activeStepUrl: `/pre-employment/career-readiness/step1`,
                didCompleteSkills: true,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/career-readiness/step1`,
                didCompleteSkills: true,
              }),
            );
          }
          if (currentUser?.claims?.isAsaUser && currentAsaTask) {
            const endPointUrl = process.env[EnvironmentVariable.REACT_APP_ASA_COMPLETE_TASK_URL];
            const isStaging =
              process.env[EnvironmentVariable.REACT_APP_BASE_URL]?.includes('staging') ||
              process.env[EnvironmentVariable.REACT_APP_BASE_URL]?.includes('localhost');
            const taskResponse = await completeASATask(
              {
                asaUserId: currentUser?.claims?.asaUserId as number,
                taskId: currentAsaTask?.taskId,
                taskStep: currentAsaTask?.taskStep || step,
              },
              endPointUrl || '',
              isStaging || false,
            );

            if (!taskResponse || !taskResponse?.ok) {
              toast.error('Something went wrong!, Please try after sometime.');
              return;
            }
            const updatedSkills = Object.assign({}, skills);
            updatedSkills['skillStressTolerance'] = dto.skillStressTolerance;
            await dispatch(dispatchGenerateSWOTReport(updatedSkills as GenerateSWOTReportDTO));
            await dispatch(
              dispatchUpdateAsaTaskHistoryInfo({
                asaUserId: currentUser?.claims?.asaUserId || 0,
                taskId: currentAsaTask?.taskId || '',
                taskStep: parseInt(currentAsaTask?.taskStep.toString()),
                completedDate: new Date(),
                isCompleted: true,
              }),
            );
            await sendSwotReport(currentUser.user.uid, currentUser.user.email || '');
            navigate(`/swot`);
          } else {
            if (!didAsaUserViewedSwot && !userProfileContext.isPremium && !organization) {
              const updatedSkills = Object.assign({}, skills);
              updatedSkills['skillStressTolerance'] = dto.skillStressTolerance;
              await dispatch(dispatchGenerateSWOTReport(updatedSkills as GenerateSWOTReportDTO));

              navigate(`/swot`);
            } else {
              navigate(`/pre-employment/career-readiness/step1`);
            }
          }
        }
      }
    } catch (e) {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (skillStressTolerance !== undefined) {
      setSelectedOption(skillStressTolerance);
    }
  }, [skillStressTolerance]);

  return (
    <div>
      {isLoading && <CircularLoader />}

      <div className="flex mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
          {!didAsaUserViewedSwot && !userProfileContext.isPremium && !organization ? step - 1 : 26}
        </div>
        <div>
          <h4 className="text-2xl">
            <span className="font-semibold">How comfortable are you </span>
            with each of these competencies
          </h4>
        </div>
      </div>
      <div className="character absolute top-96 right-[-125px] z-[-1]">
        <img className="" src={char} alt="" />
      </div>
      <form
        className=" md:px-10"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitForm)}
      >
        <h4 className="mb-1 font-semibold text-purple-700 text-base">Competency</h4>
        <h3 className="font-semibold text-black text-xl mb-8">Stress Tolerance</h3>
        <h4 className="mb-2 font-semibold text-purple-700 text-base">Description</h4>
        <p className="mb-6">
          Stress tolerance is the capacity to remain composed when faced with difficulties. You can
          stay calm without getting carried away by strong emotions of helplessness and
          hopelessness.
        </p>
        <h4 className="mb-1 font-semibold text-purple-700 text-base mb-3">
          Competency items to be rated
        </h4>
        <ul className="list-disc pl-6">
          <li className="mb-2">
            Display emotional resilience and the ability to withstand pressure on an on-going basis.
          </li>
          <li className="mb-2">
            Deal with difficult situations and adverse events while maintaining performance and
            poise.
          </li>
          <li className="mb-2">
            Respond appropriately in the face of tension, emotion and resistance.
          </li>
        </ul>
        <div className="rating-btns flex flex-wrap lg:flex-nowrap items-center mt-10">
          {skillOptions.map((option) => (
            <div key={option.label} className="h-[55px] mr-5 mb-3">
              <label className="radio-container h-full block relative">
                <input
                  disabled={isDisabled}
                  checked={selectedOption === option.value}
                  onChange={handleOptionChange}
                  className="w-full h-full absolute top-0 left-0 opacity-0"
                  type="radio"
                  name="communication"
                  value={option.value}
                />
                <span className="checkmark  !static !h-[55px] !w-auto !py-4 !px-5 !text-sm !whitespace-nowrap font-light !text-black">
                  {option.label}
                </span>
              </label>
            </div>
          ))}
        </div>
        {error && <p className="text-red-500 font-light !text-sm pt-5">{error}</p>}
        <div className="flex justify-end lg:justify-between lg:mt-20 mt-10">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className={`btn-primary  ${currentAsaTask ? 'hidden' : ''}`}
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            {currentAsaTask ? 'Submit' : ' Next'}
          </button>
        </div>
      </form>
    </div>
  );
};
