// ToggleCard.tsx
import { Add } from '@mui/icons-material';
import React from 'react';

interface ToggleCardProps {
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  isOpen: boolean;
  onToggle: (position: string) => void;
  cardContent: React.ReactNode; // This prop will accept the content for the card
}

export const ToggleCard: React.FC<ToggleCardProps> = ({
  position,
  isOpen,
  onToggle,
  cardContent,
}) => {
  const toggleDropdown = () => {
    onToggle(position);
  };

  const getCardPositionStyle = () => {
    switch (position) {
      case 'top-left':
        return { top: 0, left: 0 };
      case 'top-right':
        return { top: 0, right: 0 };
      case 'bottom-left':
        return { bottom: 0, left: 0 };
      case 'bottom-right':
        return { bottom: 0, right: 0 };
      default:
        return {};
    }
  };

  return (
    <div className="toggle-card-container">
      <button
        className="bg-purple-600 h-8 w-8 rounded-full text-white flex items-center justify-center cursor-pointer hover:bg-purple-800"
        onClick={toggleDropdown}
      >
        <Add />
      </button>
      {isOpen && (
        <div className="card" style={getCardPositionStyle()}>
          {/* Display the provided card content */}
          {cardContent}
        </div>
      )}
    </div>
  );
};
