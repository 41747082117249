import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import {
  CourseProgress,
  //, CourseProgressDto
} from './user-course.types';

export type CourseProgressState = {
  [courseId: string]: CourseProgress;
};
const initialState: CourseProgressState = {};

const courseProgressSlice = createSlice({
  name: 'courseProgress',
  initialState,
  reducers: {
    updateCourseProgress: (state, action: PayloadAction<CourseProgressState>) => {
      logger.debug('Updating course progress state.', { state, action });

      if (Object.keys(action.payload).length > 1) {
        return { ...action.payload };
      } else {
        const courseId = Object.keys(action.payload)[0];
        const chapterId = Object.keys(action.payload[courseId].chapters)[0];

        if (!state[courseId]) {
          state[courseId] = { progress: 0, chapters: {}, activeChapter: '' };
        }

        state[courseId].progress = action.payload[courseId].progress;
        if (Object.keys(action.payload[courseId].chapters).length > 1) {
          state[courseId].chapters = action.payload[courseId].chapters;
        } else {
          state[courseId].chapters[chapterId] = action.payload[courseId].chapters[chapterId];
        }
        state[courseId].activeChapter = action.payload[courseId].activeChapter;
        return state;
      }
    },
  },
});

export const { updateCourseProgress: updateCourseProgressAction } = courseProgressSlice.actions;
export default courseProgressSlice.reducer;
