import { AgreementLevel } from '../../redux/slice/form-employment-readiness/form-employment-readiness.types';

/**
 * the types of questions in the employment readiness assessment
 */
export enum ReadinessQuestionType {
  AGREEMENT_LEVEL = 'agreement_level',
}

/**
 * a question in which the user selects one option
 */
export type AgreementLevelQuestion = {
  /**
   * a unique id for the question
   */
  id: string;

  /**
   * the type of question
   */
  type: ReadinessQuestionType.AGREEMENT_LEVEL;

  /**
   * the question displayed to users
   */
  question: string;
};

/**
 * an answer to an agreement level question
 */
export type AgreementLevelAnswer = {
  /**
   * the question that was asked
   */
  question: string;

  /**
   * answet option selected for the question
   */
  answer: AgreementLevel;
};

/**
 * a question in the employment readiness assessment
 */
export type ReadinessQuestion = AgreementLevelQuestion;

/**
 * an answer to a question in the assessment
 */
export type CareerReadinessAnswer = AgreementLevelAnswer;
