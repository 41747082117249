import adapting_to_change_img from '../assets/images/courses/adapting-to-change/course.jpg';
import communication_basics_img from '../assets/images/courses/communication-basics/course.png';
import communication_styles_img from '../assets/images/courses/communication-styles/course.png';
import emotional_intelligence_img from '../assets/images/courses/emotional-intelligence/course.jpg';
import jumpstart_communicator_img from '../assets/images/courses/jumpstart-communicator/course.png';
import perception_understanding_img from '../assets/images/courses/perception-understanding/course.jpg';
import {
  AdaptingToChange1,
  AdaptingToChange2,
  AdaptingToChange3,
  AdaptingToChange4,
  AdaptingToChangeQuiz,
} from '../components/Course/Chapters/AdaptingToChange';
import {
  CommunicationBasicsChapter1,
  CommunicationBasicsChapter2,
  CommunicationBasicsChapter3,
  CommunicationBasicsChapter4,
  CommunicationBasicsChapter5,
  CommunicationBasicsChapter6,
  CommunicationBasicsChapter7,
  CommunicationBasicsChapter8,
  CommunicationBasicsChapter9,
  CommunicationBasicsChapter10,
  CommunicationBasicsChapter11,
  CommunicationBasicsChapter12,
  CommunicationBasicsChapter13,
  CommunicationBasicsChapter14,
  CommunicationBasicsQuiz,
} from '../components/Course/Chapters/CommunicationBasics';
import {
  CommunicationStylesChapter1,
  CommunicationStylesChapter2,
  CommunicationStylesChapter3,
  CommunicationStylesChapter4,
  // Quiz,
  PostAssessmentQuiz,
} from '../components/Course/Chapters/CommunicationStyles';
import {
  EmotionalIntelligence1,
  EmotionalIntelligence2,
  EmotionalIntelligence3,
  EmotionalIntelligence4,
  EmotionalIntelligence5,
  EmotionalIntelligence6,
  EmotionalIntelligence7,
  EmotionalIntelligenceQuiz,
} from '../components/Course/Chapters/EmotionalIntelligence';
import {
  JumpStartChapter1,
  JumpStartChapter2,
  JumpStartChapter3,
  JumpStartChapter4,
  JumpStartChapter5,
  JumpStartChapter6,
  JumpStartChapter7,
  Quiz1,
} from '../components/Course/Chapters/JumpStartCommunicator';
import {
  PerceptionAndUnderstandingChapter1,
  PerceptionAndUnderstandingChapter2,
  PerceptionAndUnderstandingChapter3,
  PerceptionAndUnderstandingChapter4,
  PerceptionAndUnderstandingChapter5,
  PerceptionAndUnderstandingChapter6,
  PerceptionAndUnderstandingChapter7,
  PerceptionAndUnderstandingChapter8,
  PerceptionAndUnderstandingChapter9,
  PerciptionQuiz,
} from '../components/Course/Chapters/PerceptionAndUnderstanding';

import { Course, CourseModule } from './courses.types';
export const courseModules: CourseModule[] = [
  {
    id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
    name: 'Effective communication and listening skills',
  },
  {
    id: '74629550-e06b-4a7a-a59e-2d16b5c84447',
    name: 'Understanding self and others',
  },
];

export const courses: Course[] = [
  {
    id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
    module_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
    name: 'Jumpstart Communicator',
    course_image: jumpstart_communicator_img,
    sections: [
      {
        id: '4f8c6c62-da3b-480c-969d-a2a945d027a7',
        name: '',
        chapters: [
          {
            id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
            name: 'Introduction',
            index: 1,
            content: JumpStartChapter1,
            completion_percentage: 13,
          },
          {
            id: 'fd7be45f-c8d1-419d-9407-60d931551146',
            name: 'What Is Communication?',
            index: 2,
            content: JumpStartChapter2,
            completion_percentage: 12,
          },
          {
            id: '793f6d0e-2c51-4df5-b526-2b282724260f',
            name: 'Why Does Communication Fail?',
            index: 3,
            content: JumpStartChapter3,
            completion_percentage: 13,
          },
          {
            id: '43472023-17df-4363-8878-9d18fdfd9b74',
            name: 'Why Communication Skills Matter?',
            index: 4,
            content: JumpStartChapter4,
            completion_percentage: 12,
          },
          {
            id: '1087fc0c-37c1-4846-a2b9-e16c963cf417',
            name: 'Choosing the Right Communication Medium',
            index: 5,
            content: JumpStartChapter5,
            completion_percentage: 13,
          },
          {
            id: 'a7d830fa-1176-4aa2-aeae-ee4c74ab3d0a',
            name: 'How to Remove Five Common Communication Barriers',
            index: 6,
            content: JumpStartChapter6,
            completion_percentage: 12,
          },
        ],
      },
      {
        id: 'd6b89abe-3486-429d-aa51-f57323112980',
        name: 'CONCLUSION',
        chapters: [
          {
            id: '3be28db4-42f7-4b7f-bbe6-f468ae219201',
            name: 'Summary',
            index: 7,
            content: JumpStartChapter7,
            completion_percentage: 13,
          },
          {
            id: '38cab197-cc2c-4a55-825a-8368b2bb1372',
            name: 'Quiz',
            index: 8,
            content: Quiz1,
            disableContentScroll: true,
            completion_percentage: 12,
          },
        ],
      },
    ],
    description:
      '<p>In this course, you’ll learn how to define communication, the types of communication we use at work, how to choose the right delivery method for your message, and how to remove barriers to having it understood. </p>',
    progress_color: '#1c09f8',
    disabled: false,
  },
  {
    id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
    module_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
    name: 'Workplace Communication Basics',
    course_image: communication_basics_img,
    sections: [
      {
        id: 'e9b1303d-bc85-4d49-9d15-c94e2ad1b3f2',
        name: 'INTRODUCTION',
        chapters: [
          {
            id: 'f332d150-8a7c-4112-b34c-635913134a13',
            name: 'How to Use Verbal, Nonverbal, and Written Communication',
            index: 1,
            content: CommunicationBasicsChapter1,
            completion_percentage: 7,
          },
        ],
      },
      {
        id: '872a69b7-0e28-4701-b7a3-a67ab02b2640',
        name: 'VERBAL COMMUNICATION',
        chapters: [
          {
            id: '1130183b-639b-4240-8073-a7a0fb416b26',
            name: 'Improving Your Verbal Communication Skills',
            index: 2,
            content: CommunicationBasicsChapter2,
            completion_percentage: 6,
          },
          {
            id: 'de6547b5-298a-4bd1-8f21-e0df15a2f801',
            name: 'Starting a Conversation',
            index: 3,
            content: CommunicationBasicsChapter3,
            completion_percentage: 7,
          },
          {
            id: 'd516aa29-4073-4687-a274-94d942f40854',
            name: 'Strategies for Effective Listening',
            index: 4,
            content: CommunicationBasicsChapter4,
            completion_percentage: 7,
          },
          {
            id: '755d74e8-dc44-44c4-8aee-301ba6c2ccb2',
            name: 'Asking Closed Versus Open Questions',
            index: 5,
            content: CommunicationBasicsChapter5,
            completion_percentage: 6,
          },
          {
            id: 'bafb65dd-07e9-45e6-b019-a2a24d0fe387',
            name: 'Ending a Conversation Positively',
            index: 6,
            content: CommunicationBasicsChapter6,
            completion_percentage: 7,
          },
        ],
      },
      {
        id: '95e8a03a-87e2-46f6-b565-b9aa7fdd5c28',
        name: 'WRITTEN COMMUNICATION',
        chapters: [
          {
            id: '6961e488-c33b-4e8c-9044-06d0c43bcadd',
            name: 'Improving Your Business Writing Skills',
            index: 7,
            content: CommunicationBasicsChapter7,
            completion_percentage: 7,
          },
          {
            id: '84dcd17d-c845-4334-9712-253f1610b4b7',
            name: 'How to Write with Purpose',
            index: 8,
            content: CommunicationBasicsChapter8,
            completion_percentage: 6,
          },
          {
            id: '4920ded8-1bc5-4949-90ef-a15f202234ee',
            name: 'Choosing the Right Writing Medium',
            index: 9,
            content: CommunicationBasicsChapter9,
            completion_percentage: 7,
          },
          {
            id: '4e237045-6c91-4b8d-b908-9395cc5a3dbc',
            name: 'Writing for Your Audience',
            index: 10,
            content: CommunicationBasicsChapter10,
            completion_percentage: 7,
          },
          {
            id: '96b82e5e-a11a-424c-a0d2-a2a6fd32a6ff',
            name: 'How to Structure Your Writing',
            index: 11,
            content: CommunicationBasicsChapter11,
            completion_percentage: 6,
          },
          {
            id: '151cc8b6-491f-4cd5-a1c6-49b186bb5675',
            name: 'How to Improve Your Grammar, Spelling, and Punctuation',
            index: 12,
            content: CommunicationBasicsChapter12,
            completion_percentage: 7,
          },
          {
            id: 'cd68cd54-dfac-4c19-8a20-cfc58255c78b',
            name: 'How to Edit and Proofread Your Work',
            index: 13,
            content: CommunicationBasicsChapter13,
            completion_percentage: 7,
          },
        ],
      },
      {
        id: 'd6b89abe-3486-429d-aa51-f57323112980',
        name: 'CONCLUSION',
        chapters: [
          {
            id: '59b3f79f-24c5-4c58-83e2-1188e862bcac',
            name: 'Summary',
            index: 14,
            content: CommunicationBasicsChapter14,
            completion_percentage: 6,
          },
          {
            id: 'af761a2e-24b0-4478-af4a-0842c9cd16aa',
            name: 'Quiz',
            index: 15,
            disableContentScroll: true,
            content: CommunicationBasicsQuiz,
            completion_percentage: 7,
          },
        ],
      },
    ],
    description:
      '<p>In this module, you’ll learn why having excellent communication skills is so important in the workplace, and how you can improve yours.</p>',
    progress_color: '#6300f5',
    disabled: true,
    disabled_description:
      'Please complete Jumpstart Communicator module before starting this module. Thanks',
  },
  {
    id: 'abb1771f-d555-47c9-961c-9bffefbd7951',
    module_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
    name: 'Effective Communication With Different Communication Styles',
    course_image: communication_styles_img,
    sections: [
      {
        id: 'c9f3605c-47b9-4324-9e41-088b31e073f8',
        name: '',
        chapters: [
          {
            id: 'dd1a9c61-5789-48e5-9252-3c80847b64ac',
            name: 'What is a Communication Style?',
            index: 1,
            content: CommunicationStylesChapter1,
            completion_percentage: 20,
          },
          {
            id: 'f7905a29-f712-4519-a529-dac4bf207f46',
            name: 'Understanding Different Styles',
            index: 2,
            content: CommunicationStylesChapter2,
            completion_percentage: 20,
          },
          {
            id: 'ac4efa31-a198-4a31-bbdf-bbe8e8d55f5b',
            name: 'How to Adapt to Different Styles',
            index: 3,
            content: CommunicationStylesChapter3,
            completion_percentage: 20,
          },
        ],
      },
      {
        id: 'fe4717dd-a9b1-4406-b5bb-4b1c0ee75d46',
        name: 'CONCLUSION',
        chapters: [
          {
            id: 'c793c79d-c5c1-425e-91b7-7b7c3483309d',
            name: 'Summary',
            index: 4,
            content: CommunicationStylesChapter4,
            completion_percentage: 20,
          },
          {
            id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
            name: 'Post-Assessment',
            index: 5,
            content: PostAssessmentQuiz,
            disableContentScroll: true,
            completion_percentage: 20,
          },
        ],
      },
    ],
    description:
      '<p>In this course, you’ll learn four communication styles, how to identify each, and how to adapt your own communication approach.</p>',
    progress_color: '#02abfe',
    disabled: true,
    disabled_description:
      'Please complete Workplace Communication Basics module before starting this module. Thanks',
  },
  {
    id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    module_id: '74629550-e06b-4a7a-a59e-2d16b5c84447',
    name: 'Perception & Understanding Preferences',
    course_image: perception_understanding_img,
    sections: [
      {
        id: '0102f09d-7b43-4f2f-9685-f893234b85a3',
        name: 'INTRODUCTION',
        chapters: [
          {
            id: '6a205cc4-2f50-489f-83b8-4f95c8f08645',
            name: 'Overview',
            index: 1,
            content: PerceptionAndUnderstandingChapter1,
            completion_percentage: 10,
          },
        ],
      },
      {
        id: '35be4ebb-734b-43d9-9fa8-dcc1edcaec68',
        name: 'Perception',
        chapters: [
          {
            id: 'b3045f7e-05f8-422e-a3f2-b3806c145d80',
            name: 'Perception & The Ladder of Inference',
            index: 2,
            content: PerceptionAndUnderstandingChapter2,
            completion_percentage: 10,
          },
        ],
      },
      {
        id: '66cd15b4-c996-421e-aa3e-6d1849222fa8',
        name: 'Insights Discovery',
        chapters: [
          {
            id: '58181a06-5fb6-4804-a855-658c462e2230',
            name: 'Preferences',
            index: 3,
            content: PerceptionAndUnderstandingChapter3,
            completion_percentage: 10,
          },
          {
            id: 'e073e8bc-a3a7-40d3-9f9d-1d881b18500d',
            name: 'Insights Discovery',
            index: 4,
            content: PerceptionAndUnderstandingChapter4,
            completion_percentage: 10,
          },
          {
            id: 'e9f653d6-0b97-4206-ac17-53ca9c35b661',
            name: 'Color Energies & Preferences',
            index: 5,
            content: PerceptionAndUnderstandingChapter5,
            completion_percentage: 10,
          },
        ],
      },
      {
        id: '6aad706e-794d-443e-bd7b-7d86f2092eb9',
        name: 'Connecting with Others',
        chapters: [
          {
            id: '0bfaa520-fed7-4d4a-a10c-de5fd655d06d',
            name: 'Recognizing Type',
            index: 6,
            content: PerceptionAndUnderstandingChapter6,
            completion_percentage: 10,
          },
          {
            id: 'e79c427a-0c41-4d0e-b56e-34d90041782b',
            name: 'Stress & Behavioral Style',
            index: 7,
            content: PerceptionAndUnderstandingChapter7,
            completion_percentage: 10,
          },
          {
            id: 'fedbc4ce-86ce-4d6d-9022-48fe7a674cd5',
            name: 'Adapting to Connect',
            index: 8,
            content: PerceptionAndUnderstandingChapter8,
            completion_percentage: 10,
          },
        ],
      },
      {
        id: '969cd60c-8a87-4403-9de2-f25a50853c4d',
        name: 'Conclusion',
        chapters: [
          {
            id: 'bdf90edd-fdcc-4648-b09a-d6e75a38d009',
            name: 'Summary',
            index: 9,
            content: PerceptionAndUnderstandingChapter9,
            completion_percentage: 10,
          },
          {
            id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b',
            name: 'Quiz',
            index: 10,
            content: PerciptionQuiz,
            completion_percentage: 10,
            disableContentScroll: true,
          },
        ],
      },
    ],
    description:
      '<p>Understanding perceptions and preferences fosters better relationships, critical for success. Learn to recognize biases, avoid assumptions, and adapt communication styles for productive interactions.</p>',
    progress_color: '#6300f5',
    disabled: false,
  },
  {
    id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
    module_id: '74629550-e06b-4a7a-a59e-2d16b5c84447',
    name: 'Emotional Intelligence in the Workplace',
    course_image: emotional_intelligence_img,
    sections: [
      {
        id: 'da65a766-f5e6-4217-8901-6dfbd03e15d7',
        name: 'INTRODUCTION',
        chapters: [
          {
            id: 'd5eca8f6-5731-4588-b531-0e596d27adc8',
            name: 'What Is Emotional Intelligence?',
            index: 1,
            content: EmotionalIntelligence1,
            completion_percentage: 13,
          },
        ],
      },
      {
        id: '2225f28a-82b6-4479-94d5-570d7988929f',
        name: 'Personal Competence',
        chapters: [
          {
            id: '9339fd90-854d-4757-91cd-ce240fb8ef8b',
            name: 'Improving Self-Awareness',
            index: 2,
            content: EmotionalIntelligence2,
            completion_percentage: 12,
          },
          {
            id: '3f0f5e32-3bb7-41d2-80e7-32793b55a10b',
            name: 'Boosting Self-Regulation',
            index: 3,
            content: EmotionalIntelligence3,
            completion_percentage: 13,
          },
          {
            id: '630bdddb-d573-4c8a-af3c-9a17b8a636fb',
            name: 'Increasing Self-Motivation',
            index: 4,
            content: EmotionalIntelligence4,
            completion_percentage: 12,
          },
        ],
      },
      {
        id: '942711d5-690b-41e6-9686-a96e8d36b714',
        name: 'Social Competence',
        chapters: [
          {
            id: 'b56d8d23-4081-4293-9c3a-be1a5fca2a2f',
            name: 'Developing Empathy',
            index: 5,
            content: EmotionalIntelligence5,
            completion_percentage: 13,
          },
          {
            id: 'e029a466-ade8-4a26-8f44-0d4ea394e550',
            name: 'Enhancing Social Skills',
            index: 6,
            content: EmotionalIntelligence6,
            completion_percentage: 12,
          },
        ],
      },
      {
        id: '3ef39f1e-fda4-408f-96d0-7891943bd1e0',
        name: 'Conclusion',
        chapters: [
          {
            id: '8600f489-f14b-4943-89cd-ae99076442cf',
            name: 'Summary',
            index: 7,
            content: EmotionalIntelligence7,
            completion_percentage: 13,
          },
          {
            id: '94d3c511-f514-4a7d-8e97-1d1230d99ca3',
            name: 'Quiz',
            index: 8,
            content: EmotionalIntelligenceQuiz,
            completion_percentage: 12,
            disableContentScroll: true,
          },
        ],
      },
    ],
    description:
      '<p>Learn emotional intelligence—recognize and manage your emotions (self-awareness, self-regulation) and others to improve personal and social skills leading to better decision-making.</p>',
    progress_color: '#6300f5',
    disabled: true,
    disabled_description:
      'Please complete Perception & Understanding Preferences module before starting this module. Thanks',
  },
  {
    id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
    module_id: '74629550-e06b-4a7a-a59e-2d16b5c84447',
    name: 'Adapting to Change',
    course_image: adapting_to_change_img,
    sections: [
      {
        id: '483dca09-282f-46f9-b4fb-21a6833f7dd8',
        name: '',
        chapters: [
          {
            id: 'f1ab52f3-1be6-4b79-b893-f888bbf85561',
            name: 'Why Do We Struggle With Change?',
            index: 1,
            content: AdaptingToChange1,
            completion_percentage: 20,
          },
          {
            id: '8b8d1c69-6b57-448e-a31c-c94a442c6f89',
            name: 'Common Reactions To Change',
            index: 2,
            content: AdaptingToChange2,
            completion_percentage: 20,
          },
          {
            id: '40909258-081c-48a5-8767-2b8ebfc85fdc',
            name: '4 Strategies for Accepting Change',
            index: 3,
            content: AdaptingToChange3,
            completion_percentage: 20,
          },
          {
            id: 'acc03c50-2105-4fac-bda5-df50e6441a08',
            name: 'Summary',
            index: 4,
            content: AdaptingToChange4,
            completion_percentage: 20,
          },
          {
            id: '44b07e1b-0477-4f62-a22a-16075ca2650a',
            name: 'Quiz',
            index: 5,
            content: AdaptingToChangeQuiz,
            disableContentScroll: true,
            completion_percentage: 20,
          },
        ],
      },
    ],
    description:
      '<p>Adapting to Change: Learn why we struggle with workplace change, identify the five emotional stages and how to use coping strategies for maximum career success.</p>',
    progress_color: '#fd7701',
    disabled: true,
    disabled_description:
      'Please complete Emotional Intelligence in the Workplace module before starting this module. Thanks',
  },
];
