import React from 'react';

import img5 from '../../../assets/images/swot/step1/char.png';

export const GreetingsTemplate: React.FC = () => {
  return (
    <>
      {/* <header className="relative w-full z-10 shadow bg-white">
        <div className="container">
          <nav className="navbar flex">
            <div className="navbar-brand mr-4">
              <a href="#">
                <img
                  width="120"
                  src="https://www.verolearning.com/static/media/logo.61bccf503618f7b6a7f7cc8127c1bccc.svg"
                  alt="test image"
                />
              </a>
            </div>
          </nav>
        </div>
      </header> */}
      <div className="pt-4" style={{ height: '1122px' }}>
        <div className="pt-3 pb-3 text-center theme-gradient rounded-xl">
          <div className=" text-xl text-white">
            <span className="font-semibold"> SWOT </span> ANALYSIS
          </div>
        </div>
        <div className="text-center lg:px-16 mb-10 mt-2">
          <img className="mx-auto mt-5" src={img5} alt="" />
          <p className="mt-8 lg:text-xl">
            <span className="uppercase font-bold text-purple-700">
              <span>
                Hello There!
                <br />
              </span>
            </span>
            <br />
            Congratulations on talking the first step towards your career goals with Vero Learning!
            We are thrilled to have you on board and excited to help you reach your full potential.
            Our platform is designed to help you identify your unique skill sets and areas for
            improvement. Use this information to carve out a personalized training plan to boost
            your employability and help you make more informed career choices.
          </p>
          <br />
          <p className="mt-3 lg:text-xl">
            We are delighted to share with you the results of your preliminary career SWOT analysis
            report. Our assessment tools have evaluated your attitude, skills and experience and
            have generated a personalized report that provides insights into your career readiness.
            Based on your responses to the intake questionnaire, our platform has identified your
            strengths and opportunities for growth to support you as you explore potential career
            paths.
          </p>
        </div>
      </div>
    </>
  );
};
