export enum AsaTaskName {
  SIGNUP = 'signup',
  SKILL = 'skills',
}
/**
 * payload for updating a user's asa tasks
 */
export type UserAsaTaskHistory = {
  userId: string;
  tasks: AsaUserTasks[];
  asaUserId: number;
};

export type AsaUserTasks = {
  taskId: string;
  taskName: string;
  startDate?: Date | null;
  completedDate?: Date | null;
  taskStep: number;
  totalSteps: number;
  isCompleted: boolean;
  taskUrl: string;
};

export type UserAsaTaskHistoryDTO = {
  asaUserId: number;
  taskId: string;
  taskName?: string;
  startDate?: Date;
  completedDate?: Date;
  taskStep: number;
  totalSteps?: number;
  isCompleted?: boolean;
  taskUrl?: string;
};

export type CompleteAsaTaskDto = {
  taskId: string;
  taskStep: number;
  asaUserId: number;
};
