/**
 * payload for updating a user's personal information
 */
export type PersonalInfoDTO = {
  name: string;
  addressLine1: string;
  city: string;
  state: string;
  phoneCountryCode: string;
  phone: string;
  email: string;
};

export enum State {
  AK = 'Alaska',
  AL = 'Alabama',
  AR = 'Arkansas',
  AS = 'American Samoa',
  AZ = 'Arizona',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DC = 'District of Columbia',
  DE = 'Delaware',
  FL = 'Florida',
  GA = 'Georgia',
  GU = 'Guam',
  HI = 'Hawaii',
  IA = 'Iowa',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  MA = 'Massachusetts',
  MD = 'Maryland',
  ME = 'Maine',
  MI = 'Michigan',
  MN = 'Minnesota',
  MO = 'Missouri',
  MP = 'Northern Mariana Islands',
  MS = 'Mississippi',
  MT = 'Montana',
  NC = 'North Carolina',
  ND = 'North Dakota',
  NE = 'Nebraska',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NV = 'Nevada',
  NY = 'New York',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  PR = 'Puerto Rico',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TT = 'Trust Territories',
  TX = 'Texas',
  UT = 'Utah',
  VA = 'Virginia',
  VI = 'Virgin Islands',
  VT = 'Vermont',
  WA = 'Washington',
  WI = 'Wisconsin',
  WV = 'West Virginia',
  WY = 'Wyoming',
}
/**
 * valid age ranges for the age input form
 */
export enum AgeRange {
  VALUE_UNDER_13 = 'Under 13',
  VALUE_13_TO_15 = '13 - 15',
  VALUE_16_TO_17 = '16 - 17',
  VALUE_18_TO_19 = '18 - 19',
  VALUE_20_TO_24 = '20 - 24',
  VALUE_25_TO_29 = '25 - 29',
  VALUE_30_TO_34 = '30 - 34',
  VALUE_35_AND_OVER = 'Over 35',
}

/**
 * valid genders for the age input form
 */
export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  TRANSGENDER = 'Transgender',
  OTHER = 'Other',
}

/**
 * payload for updating a user's age
 */
export type IntakeFormAgeRangeDTO = {
  ageRange: AgeRange;
  age: number | null;
  gender: Gender | null;
  otherGender: string | null;
};

/**
 * the level of education a user has had
 */
export enum EducationLevel {
  FRESHMAN = 'freshman',
  SOPHOMORE = 'sophomore',
  JUNIOR = 'junior',
  SENIOR = 'senior',
  SOME_COLLEGE = 'some_college',
  COLLEGE_GRADUATE = 'college_graduate',
}

/**
 * current work status for a user
 *
 * @todo add `WorkStatus.OTHER`
 */
export enum WorkStatus {
  COLLEGE_TEST_PREP = 'college_test_prep',
  CAREER_COACHING = 'career_coaching',
  NO_COLLEGE_TEST_PREP = 'no_college_test_prep',
  NO_CAREER_COACHING = 'no_career_coaching',
  INTERNSHIP = 'internship',
  APPRENTICESHIP = 'apprenticeship',
  BOOTCAMP_CODING = 'bootcamp_coding',
  ENTREPRENEURSHIP = 'entrepreneurship',
  COMMUNITY_COLLEGE = 'community_college',
  VOCATIONAL = 'vocational',
  PHD = 'phd',
  POST_GRADUATE = 'post_graduate',
  UNEMPLOYED = 'unemployed',
  MILITARY_SERVICE = 'military_service',
  WORK_PART_TIME = 'work_part_time',
  WORK_FULL_TIME = 'work_full_time',
  OTHER = 'other',
}

/**
 * payload for updating a user's education level
 */
export type IntakeFormEducationDTO = {
  education?: EducationLevel | null;
  workStatus?: WorkStatus[] | null;
  workStatusComments?: string;
  schoolName?: string | null;
  schoolCity?: string | null;
  schoolState?: string | null;
};

export type LanguageProficiencyValue = undefined | 0 | 1 | 2 | 3 | 4 | 5;

/**
 * payload for updating a user's language proficiency
 */
export type IntakeFormLanguageProficiencyDTO = {
  languageProficiency: Record<string, LanguageProficiencyValue>;
};

/**
 * an enum to describe a user's work history
 */
export enum WorkHistory {
  LOOKING_FIRST_JOB = 'looking_first_job',
  NEVER_WORKED = 'never_worked',
  RECENT_VOLUNTEER = 'recent_volunteer',
  WORK_FULL_TIME = 'work_full_time',
  WORK_PART_TIME = 'work_part_time',
  SELF_EMPLOYED = 'self_employed',
  OTHER = 'other',
}

/**
 * payload for updating a user's work history
 */
export type IntakeFormWorkHistoryDTO = {
  workHistory: WorkHistory;
  workHistorySelfEmployedComments?: string;
  workHistoryOtherComments?: string;
  workHistoryOrganizationName: string | null;
  workHistoryTotalExperienceInMonth: string | null;
  workHistoryFirstWorkplace: string | null;
  workHistoryFirstJobTitle: string | null;
  workHistoryCurrentJobRole: string | null;
  workHistoryFutureJobRole: string | null;
  workHistoryJobSatisfactionRating: string | null;
  workHistoryJobSatisfactionRatingReason: string | null;
};

/**
 * a user's immediate goals with their career
 */
export enum CareerPlan {
  FIND_EMPLOYMENT = 'find_employment',
  CHANGE_JOB = 'change_job',
  COLLEGE = 'college',
  UNIVERSITY = 'university',
  INTERNSHIP = 'internship',
  APPRENTICESHIP = 'apprenticeship',
  NOT_SURE = 'not_sure',
  OTHER = 'other',
}

/**
 * whether or not the user knows which direction they want to go
 */
export enum CareerPlanDirection {
  YES = 'yes',
  NO = 'no',
  SOMEWHAT = 'somewhat',
}

/**
 * payload for updating a user's career plan
 */
export type IntakeFormCareerPlanDTO = {
  careerPlan: CareerPlan;
  careerPlanRemarks?: string;
  careerPlanDirection: CareerPlanDirection;
  futureGoal: string;
};

/**
 * factors that may limit one's employment
 */
export enum CareerConstraint {
  DISABILITY = 'disability',
  CHILDCARE = 'childcare',
  REQUIRED_TOOLS = 'required_tools',
  SKILLS_OR_QUALIFICATIONS = 'skills_or_qualifications',
  STRENGTH_WEAKNESS_KNOWLEDGE = 'strength_weakness_knowledge',
  ADDICTION = 'addiction',
  OTHER = 'other',
}

/**
 * payload for updating a user's career constraints
 */
export type IntakeFormCareerConstraintsDTO = {
  careerConstraints: CareerConstraint[];
  careerConstraintComments?: string;
};

/**
 * payload for updating a user's organization
 */
export type OrganizationDTO = {
  organization: string;
};
