import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import character from '../../assets/images/intake/step7/char.svg';
import { useIntakeFormContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  CareerConstraint as CareerConstraintValue,
  IntakeFormCareerConstraintsDTO,
} from '../../redux/slice/form-intake';
import { dispatchUpdateIntakeFormCareerConstraints } from '../../redux/slice/form-intake/form-intake.operations';
import { PreEmploymentAssessmentStatus } from '../../redux/slice/pre-employment-assessment';
import { dispatchUpsertPreEmploymentAssessment } from '../../redux/slice/pre-employment-assessment/pre-employment-assessment.operations';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
interface CareerConstraintsProps {
  onBack: () => void;
  step: number;
}

interface FormData {
  careerConstraints: string[];
  careerConstraintComments?: string;
}
const career_options = [
  {
    value: CareerConstraintValue.DISABILITY,
    label: 'Disability (physical, mental, or learning)',
  },
  {
    value: CareerConstraintValue.CHILDCARE,
    label: 'Lack of childcare',
  },
  {
    value: CareerConstraintValue.REQUIRED_TOOLS,
    label: 'Lack of required tools or clothing',
  },
  {
    value: CareerConstraintValue.SKILLS_OR_QUALIFICATIONS,
    label: 'Lack of skills/qualifications',
  },
  {
    value: CareerConstraintValue.STRENGTH_WEAKNESS_KNOWLEDGE,
    label: 'Not sure of which careers best suit your strengths and weaknesses',
  },
  {
    value: CareerConstraintValue.OTHER,
    label: 'Other',
    is_editable: true,
  },
  {
    value: CareerConstraintValue.ADDICTION,
    label: 'Struggle with addiction',
  },
];
export const CareerConstraints: React.FC<CareerConstraintsProps> = ({ onBack }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const dispatch = useAppDispatch();
  const { careerConstraints, careerConstraintComments } = useIntakeFormContext();
  const [selectedCareerConstraints, setSelectedCareerConstraints] = useState<string[]>(
    careerConstraints ?? [],
  );
  const [otherRemarks, setOtherRemarks] = useState(careerConstraintComments);
  const { requiresEmploymentReadiness, status: preEmploymentAssessmentStatus } =
    usePreEmploymentAssessmentContext();
  const [error, setError] = useState('');
  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);
  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, isOther: boolean) => {
    if (!isOther) {
      setOtherRemarks('');
    } else {
      setOtherRemarks(event.target.value);
      if (
        event.target.value.trim() &&
        !selectedCareerConstraints.includes(CareerConstraintValue.OTHER)
      ) {
        setSelectedCareerConstraints([...selectedCareerConstraints, CareerConstraintValue.OTHER]);
      } else if (!event.target.value.trim()) {
        setSelectedCareerConstraints(
          selectedCareerConstraints.filter((val) => val !== CareerConstraintValue.OTHER),
        );
      }
    }

    setError('');
  };

  const handleConstraintsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selectedCareerConstraints.includes(value)) {
      setSelectedCareerConstraints(selectedCareerConstraints.filter((val) => val !== value));
    } else {
      setSelectedCareerConstraints([...selectedCareerConstraints, value]);
      setError('');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll to the top
  }, []);
  useEffect(() => {
    if (careerConstraints && careerConstraints.length) {
      setSelectedCareerConstraints(careerConstraints);
    }
    if (careerConstraintComments?.trim().length) {
      setOtherRemarks(careerConstraintComments.trim());
    }
  }, [careerConstraints, careerConstraintComments]);

  const { handleSubmit } = useForm<FormData>();
  const submitCareerConstraints: SubmitHandler<FormData> = async () => {
    logger.debug('Submitted career constraints form.', {
      selectedCareerConstraints,
      otherRemarks,
    });

    setIsLoading(true);
    try {
      const isOtherSelected: boolean = selectedCareerConstraints.includes(
        CareerConstraintValue.OTHER,
      );
      if (isOtherSelected && !otherRemarks?.trim()?.length) {
        setError('Please explain the other category');
      } else {
        if (isDisabled) {
          navigate('/pre-employment/employment-readiness/step1');
        } else {
          const dto: IntakeFormCareerConstraintsDTO = {
            careerConstraints: selectedCareerConstraints as CareerConstraintValue[],
            careerConstraintComments: isOtherSelected ? otherRemarks?.trim() : undefined,
          };
          await dispatch(dispatchUpdateIntakeFormCareerConstraints(dto));
          await dispatch(
            dispatchUpsertPreEmploymentAssessment({
              didCompleteIntake: true,
              syncedToAirtable: false,
            }),
          );

          if (!requiresEmploymentReadiness) {
            navigate('/pre-employment/skills/step1');
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/skills/step1`,
              }),
            );
          } else {
            navigate('/pre-employment/employment-readiness/step1');
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/employment-readiness/step1`,
              }),
            );
          }
        }
      }
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  const backButtonClick = () => {
    onBack();
  };
  return (
    <div>
      <div className="flex mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">09</div>
        <div>
          <h4 className="text-2xl">
            What are some
            <span className="font-semibold"> factors that may affect your employment </span>
            and/or career choice?
          </h4>
        </div>
      </div>
      <div className="character absolute bottom-0 left-[-115px] lg:left-[-147px]">
        <img className="" src={character} alt="" />
      </div>
      <form
        className="md:px-10"
        action=""
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitCareerConstraints)}
      >
        <div className="grid gap-y-3">
          {career_options.map((option) => (
            <div
              key={option.label}
              className={` ${option.is_editable ? 'h-[170px]' : 'h-[90px] lg:h-[60px]'} `}
            >
              <label className="checkbox-container h-full p-4 block relative">
                <input
                  disabled={isDisabled}
                  checked={selectedCareerConstraints.includes(option.value)}
                  onChange={handleConstraintsChange}
                  value={option.value}
                  className="w-full h-full absolute top-0 left-0 opacity-0"
                  type="checkbox"
                />
                <span className="checkbox-checkmark font-semibold !block h-auto pt-4">
                  {option.label}
                  {option.is_editable && (
                    <textarea
                      disabled={isDisabled}
                      onChange={(event) =>
                        textAreaChange(event, option.value.includes(CareerConstraintValue.OTHER))
                      }
                      value={otherRemarks}
                      placeholder="Explain"
                      rows={2}
                      className="bg-white border rounded-lg w-full resize-none mt-4 p-3 font-light"
                      name=""
                    ></textarea>
                  )}
                </span>
              </label>
            </div>
          ))}
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex justify-end lg:justify-between mt-8">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
