import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EmploymentReadinessAnswer } from '../../../assessments/employment-readiness/employment-readiness.types';
import logger from '../../../services/logger';

export type EmploymentReadinessState = {
  [answerId: string]: EmploymentReadinessAnswer;
};

const initialState: EmploymentReadinessState = {};

const employmentReadinessSlice = createSlice({
  name: 'formEmploymentReadiness',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<EmploymentReadinessState>) => {
      logger.debug('Updating employment readiness state.', { state, action });
      Object.assign(state, { ...action.payload });
    },
  },
});

export const { update: updateEmploymentReadinessAction } = employmentReadinessSlice.actions;
export default employmentReadinessSlice.reducer;
