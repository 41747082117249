import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import { UserQuizQuestionState } from './user-course-question-response.types';

const initialState: UserQuizQuestionState = {};

const userCourseQuizQuestionSlice = createSlice({
  name: 'userCourseQuizQuestion',
  initialState,
  reducers: {
    updateUserCourseSortingQuestion: (state, action: PayloadAction<UserQuizQuestionState>) => {
      logger.debug('Updating user course quiz sorting question state.', { state, action });
      if (Object.keys(action.payload).length > 1) {
        return { ...action.payload };
      } else {
        const courseId = Object.keys(action.payload)[0];
        const chapterId = Object.keys(action.payload[courseId].chapters)[0];
        if (!state[courseId]) {
          state[courseId] = { chapters: {} };
        }
        if (Object.keys(action.payload[courseId].chapters).length > 1) {
          state[courseId].chapters = action.payload[courseId].chapters;
        } else {
          state[courseId].chapters[chapterId] = action.payload[courseId].chapters[chapterId];
        }
        return state;
      }
    },
  },
});

export const { updateUserCourseSortingQuestion } = userCourseQuizQuestionSlice.actions;

export default userCourseQuizQuestionSlice.reducer;
