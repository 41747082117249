import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import {
  getSWOTReportValues,
  getSWOTReportValuesForDownload,
  saveSWOTReport,
} from '../../../services/database/swot';
import { EnvironmentVariable } from '../../../utils/constants';

import { saveSWOTReportAction } from './swot.slice';
import { GenerateSWOTReportDTO, SWOTReport } from './swot.types';
import { generateSWOTReport } from './swot.utils';

/**
 * retrieve previously saved values from the skills assessment
 */
export const dispatchGetSWOTReportValues =
  () =>
  async (dispatch: Dispatch<PayloadAction<SWOTReport>>): Promise<void> => {
    const swot: SWOTReport | undefined = await getSWOTReportValues();
    if (swot) {
      dispatch(saveSWOTReportAction(swot));
    }
  };

export const dispatchGetSWOTReportValuesForDownload =
  (userId: string) =>
  async (dispatch: Dispatch<PayloadAction<SWOTReport>>): Promise<void> => {
    const swot: SWOTReport | undefined = await getSWOTReportValuesForDownload(userId);
    if (swot) {
      dispatch(saveSWOTReportAction(swot));
    }
  };

/**
 * update progress in the skills assessment
 */
export const dispatchGenerateSWOTReport =
  (dto: GenerateSWOTReportDTO) =>
  async (dispatch: Dispatch<PayloadAction<SWOTReport>>): Promise<void> => {
    const swot: SWOTReport = generateSWOTReport(dto);
    await saveSWOTReport(swot);
    dispatch(saveSWOTReportAction(swot));
  };

/**
 * send swot report email to the user
 */
export const sendSwotReport = async (userId: string, email: string) => {
  const endPointUrl = process.env[EnvironmentVariable.REACT_APP_SEND_SWOT_REPORT_URL];
  const appUrl = process.env[EnvironmentVariable.REACT_APP_BASE_URL];
  if (endPointUrl && appUrl) {
    await fetch(`${endPointUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: email,
        url: `${appUrl}download-swot-report/${userId}`,
      }),
    });
  }
};
