/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { dispatchGetUserEngagementActivity } from '../../redux/slice/user-engagement/user-engagement.operation';
import {
  UserActivity,
  UserEngagementType,
} from '../../redux/slice/user-engagement/user-engagement.type';
import { PlatformEngagementTable } from './PlatformEngagementTable';

dayjs.extend(utc);

const localizer = dayjsLocalizer(dayjs);

type TodayActiveTimeEvent = {
  title: string;
  start: Date;
  end: Date;
};

// Tooltip component to display time and date
const Tooltip: React.FC<{ content: string; position: { x: number; y: number } }> = ({
  content,
  position,
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        padding: '5px',
        backgroundColor: 'black',
        color: 'white',
        borderRadius: '5px',
        fontSize: '12px',
        pointerEvents: 'none',
        zIndex: 1000,
      }}
    >
      {content}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomToolbar: React.FC<{ toolbar: any; onMonthChange: (date: Date) => void }> = ({
  toolbar,
  onMonthChange,
}) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const label = () => {
    const date = dayjs(toolbar.date);
    onMonthChange(dayjs(date).toDate()); // Pass the correct previous month date

    return <span>{date.format('MMMM YYYY')}</span>;
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  return (
    <div className="flex">
      <button type="button" onClick={goToBack}>
        <NavigateBeforeIcon />
      </button>
      <span className="rbc-toolbar-label font-semibold">{label()}</span>
      <button type="button" onClick={goToNext}>
        <NavigateNextIcon />
      </button>
    </div>
  );
};

const eventStyleGetter = () => {
  return {
    style: {
      backgroundColor: '#962cc6',
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: 'none',
      display: 'block',
    },
  };
};

export const PlatformEngagementCalender: React.FC = () => {
  const [myEventsList, setMyEventsList] = useState<TodayActiveTimeEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<TodayActiveTimeEvent | null>(null);
  const [tooltipContent, setTooltipContent] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [currentMonthDate, setCurrentMonthDate] = useState<string>('');
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData: UserEngagementType = await dispatchGetUserEngagementActivity();

      if (fetchedData) {
        const fetchDataValues: UserActivity[] = Object.values(fetchedData);

        const todayActiveTimeEvents = fetchDataValues.map((dto) => {
          const eventDate = dayjs.utc(dto.lastUpdatedDate).toDate();
          setCurrentMonthDate(dto.lastUpdatedDate);
          const seconds = dto.todayActiveTime || 0;
          const hours = Math.floor(seconds / 3600);
          const minutes = Math.floor((seconds % 3600) / 60);

          const formattedTime = `${hours}h: ${minutes}m`;

          return {
            title: formattedTime,
            start: eventDate,
            end: eventDate,
            allDay: true,
          };
        });

        setMyEventsList(todayActiveTimeEvents);
      }
    };

    void fetchData();
  }, []);

  // Handle event click to toggle the tooltip
  const handleEventClick = (event: TodayActiveTimeEvent, e: React.MouseEvent) => {
    if (selectedEvent && selectedEvent === event) {
      setSelectedEvent(null);
      setTooltipContent(null);
    } else {
      setSelectedEvent(event);
      const formattedDate = dayjs(event.start).format('MMMM D, YYYY');
      const content = ` Date: ${formattedDate}, Time: ${event.title}`;

      // Calculate tooltip position and move it slightly above the clicked position
      const offsetY = -10; // Adjust how far above the event the tooltip should appear
      setTooltipContent(content);
      setTooltipPosition({ x: 0, y: e.clientY - offsetY });
    }
  };

  const handleMonthChange = (date: Date) => {
    const newMonth = dayjs(date).format('YYYY-MM'); // Format the month
    setCurrentMonthDate(newMonth); // Update the month state
  };
  return (
    <div style={{ position: 'relative' }}>
      <Calendar
        views={['month']}
        defaultView="month"
        localizer={localizer}
        className="calender-ui"
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        components={{
          toolbar: (toolbar) => (
            <CustomToolbar
              toolbar={toolbar}
              onMonthChange={() => handleMonthChange(toolbar.date)}
            />
          ),
        }}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={(event, e) => handleEventClick(event, e as unknown as React.MouseEvent)}
        style={{
          height: '500px',
          width: '100%',
          fontSize: '12px',
          gap: '10px',
          marginBottom: '15px',
        }}
      />

      {tooltipContent && <Tooltip content={tooltipContent} position={tooltipPosition} />}
      <PlatformEngagementTable engagementDate={currentMonthDate} />
    </div>
  );
};
