import _ from 'lodash';

// eslint-disable-next-line import/order
import energy_reenergies from '../../../../assets/images/courses/perception-understanding/chapter3/energy_reenergise.jpg';

import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import CarlJung from '../../../../assets/images/courses/perception-understanding/chapter3/CarlJung.jpg';
import introImg from '../../../../assets/images/courses/perception-understanding/chapter3/introImg.jpg';
import m1 from '../../../../assets/images/courses/perception-understanding/chapter3/m1.jpg';
import perciption_chap3_img10 from '../../../../assets/images/courses/perception-understanding/chapter3/perciption_chap3_img10.png';
import perciption_chap3_img11 from '../../../../assets/images/courses/perception-understanding/chapter3/perciption_chap3_img13.png';
import perciption_chap3_img2 from '../../../../assets/images/courses/perception-understanding/chapter3/perciption_chap3_img2.png';
import perciption_chap3_img4 from '../../../../assets/images/courses/perception-understanding/chapter3/perciption_chap3_img6.png';
import perciption_chap3_img5 from '../../../../assets/images/courses/perception-understanding/chapter3/perciption_chap3_img7.png';
import perciption_chap3_img8 from '../../../../assets/images/courses/perception-understanding/chapter3/perciption_chap3_img8.png';
import perciption_chap3_img9 from '../../../../assets/images/courses/perception-understanding/chapter3/perciption_chap3_img9.png';
import spectrum from '../../../../assets/images/courses/perception-understanding/chapter3/spectrum.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseBasicDetails,
  course_basic_details,
} from '../../../../courses/perception-understanding/perciption-understanding-questions';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { UserChapterQuestion } from '../../../../redux/slice/user-course-question-response';
import { CustomQuestionAccordion } from '../../../common/QuestionAccordion';
import { CustomAudioTabsBlock, CustomTabPanel } from '../../CustomAudioTabs';
import { InfoBlock } from '../../InfoBlock';
import { MediaBlock } from '../../MediaBlock';

interface PerciptionProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const PerceptionAndUnderstandingChapter3: React.FC<PerciptionProps> = ({
  chapter,
  onNextChapter,
}) => {
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const courseFilter: CourseBasicDetails[] = course_basic_details.filter(
    (course) => course.chapter_id === chapter.id,
  );
  const [energyTabValue, setEnergyTabValue] = useState<number | null>(null);
  const [isFormDirty, setIsFormDirty] = useState(true);

  const accordionData = [
    {
      id: 'panel1',
      title: 'Question1',
      content: '1. Which of the Jungian preferences do you tend to use most often?',
    },
    {
      id: 'panel2',
      title: 'Question2',
      content: '2. Which do you rarely use and which do you over use? What impact does this have?',
    },
    {
      id: 'panel3',
      title: 'Question3',
      content:
        '3. How does an awareness of these preferences help you to understand yourself and others?',
    },
  ];
  const tabsData = [
    {
      title: 'How We Get Energy and Re-energize ',
      textColor: '',
      bgColor: '',
      audioUrl:
        'https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/d97AraXL/assets/Pkn2SeeEz8SlDxLn/story_content/5woGh24vhPJ_44100_56_0.mp3',
    },
    {
      title: 'How We Make Decisions',
      textColor: '',
      bgColor: '',
      audioUrl:
        'https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/d97AraXL/assets/Pkn2SeeEz8SlDxLn/story_content/6PiYfyRJJoN_44100_56_0.mp3',
    },
    {
      title: 'How We Take In & Process Information',
      textColor: '',
      bgColor: '',
      audioUrl:
        'https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/d97AraXL/assets/Pkn2SeeEz8SlDxLn/story_content/6onm9ttCJLa_44100_56_0.mp3',
    },
  ];

  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const validateAnswer = (data: string) => {
    if (!data) {
      setIsFormDirty(true);
    }
  };
  useEffect(() => {
    if (
      chapter &&
      courseFilter[0]?.course_id &&
      Object.keys(userCourseQuestionResponseContext).length
    ) {
      const userAnswerResponse: UserChapterQuestion[] =
        userCourseQuestionResponseContext[courseFilter[0].course_id]?.chapters[chapter.id]
          ?.questions || [];

      if (userAnswerResponse.length === 3) {
        setIsFormDirty(false);
      }
    }
  }, [userCourseQuestionResponseContext]);
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-xl font-light">
        <h2 className="font-semibold text-3xl">Preferences</h2>
        <p className="text-sm italic">Part 3 of 10</p>
        <MediaBlock mediaImage={introImg}>
          <p className="text-2xl leading-10 w-3/4">
            Every advance, every conceptual achievement of mankind has been connected with an
            advance in self awareness."
            <br />
            <span className="font-bold text-base">– Dr. Carl Jung</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          The Psychology that Underlies the Insight Discovery Model
        </h3>
        <div className="flex items-center mb-11">
          <img className="w-96 mr-9" src={m1} alt="" />
          <div>
            <p>
              Insights Discovery is a tool used to help understand your and others' behavior. It was
              created in Scotland by father and son Andi and Andy Lothian, inspired by Carl Jung's
              work.
            </p>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">Psychological Preferences</h3>
        <p>
          The color energies are based upon Jung's work on Psychological Types.
          <br />
          <br />
          Jung explained that individuals have specific PREFERENCES that shape our experience of the
          world and our behavior in it.
        </p>
        <div className="flex items-start mt-11">
          <div>
            <p className="font-bold">Three areas of preference Jung studied were:</p>
            <ul className="list-decimal pl-7 mt-8">
              <li className="pl-4 mb-7 marker:font-bold">The way we gain or recharge energy</li>
              <li className="pl-4 mb-7 marker:font-bold">How we take in and process information</li>
              <li className="pl-4 mb-7 marker:font-bold">How we make decisions</li>
            </ul>
            <br />
            <p>
              The three areas follow a logical order: people are either introvert or extravert, they
              collect information based on sensing or intuition, and then make a decision based on
              thinking or feeling.
            </p>
            <br />
            <p>
              Based on Jung's two axis (introvert versus extravert and Thinking versus Feeling),
              four quadrants can be made to describe a certain personality. Each personality is
              represented by a color: red, yellow, green and blue.
            </p>
            <br />
          </div>
          <div className="text-center flex-none">
            <img className="w-96 ml-9" src={CarlJung} alt="" />
            <p>Carl Jung</p>
          </div>
        </div>
        <div className="flex items-center mb-11">
          <img className="w-96 mr-9" src={perciption_chap3_img2} alt="" />
          <div>
            <p className="font-bold">
              Going one step further, based on Jung's two axis (introvert versus extravert and
              Thinking versus Feeling), four quadrants can be made to describe a certain
              personality. A person's personality can be represented by 4 color energies: Red,
              Yellow, Green, and Blue. We will explore this more in the next section.
            </p>
          </div>
        </div>
        <p className="font-bold mb-3">
          Click each tab to learn more about each of these areas of preference and how they connect
          to the four colors energies.
        </p>
        <CustomAudioTabsBlock tabsData={tabsData} onTabChange={(value) => setEnergyTabValue(value)}>
          <>
            {!_.isNil(energyTabValue) && (
              <>
                <CustomTabPanel index={0} value={energyTabValue}>
                  <Swiper navigation={true} modules={[Navigation]} className="preferencesSwiper">
                    <SwiperSlide>
                      <div className="flex text-base font-normal items-center w-11/12 mx-auto">
                        <div className="inline-block">
                          <p className="mb-7 inline-block text-xl">
                            <strong>Introversion</strong>
                          </p>
                          <ul className="list-disc pl-5 inline-block">
                            <li className="mb-2 pl-4 whitespace-nowrap">Quiet</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Observant Inwardly</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Focused</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Depth focused</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Intimative Reserved</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Reflective</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Thoughtful</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Cautious</li>
                          </ul>
                        </div>
                        <div className="flex justify-center w-11/12 text-center  mx-8">
                          <img className="" src={perciption_chap3_img11} alt="" />
                        </div>
                        <div className="right-sec inline-block">
                          <p className="mb-7  inline-block text-xl">
                            <strong>Extraversion</strong>
                          </p>
                          <ul className="list-disc pl-5  inline-block">
                            <li className="mb-2 pl-4 whitespace-nowrap">Talkative</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Involved</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Outwardly focused</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Breadth focused</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Gregarious</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Flamboyant</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Action oriented</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Outspoken</li>
                            <li className="mb-2 pl-4 whitespace-nowrap">Bold</li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <p className="text-2xl mb-4">Introvert to Introvert Conversation</p>
                      <p className="text-xl mb-4">
                        How it looks when introverts and extraverts communicate with one another.
                      </p>
                      <p className="text-xl  mb-4">
                        When Introverts communicate with Introvert, because styles are similar, it
                        is easy for them to connect. Introverts like to think to talk.
                      </p>

                      <span className=" mt-7 rounded-3xl overflow-hidden flex justify-center">
                        <img
                          className="object-cover max-h-[300px]"
                          src={perciption_chap3_img8}
                          alt=""
                        />
                      </span>
                    </SwiperSlide>
                    <SwiperSlide>
                      <p className="text-2xl mb-4">Extrovert to Extrovert Conversation</p>
                      <p className="text-xl mb-4">
                        How it looks when extraverts and extraverts communicate with one another.
                      </p>
                      <p className="text-xl  mb-4">
                        On the other hand, when Extroverts speak, sometimes it is one person talking
                        overt the others. Extroverts like to talk to think
                      </p>
                      <span className=" mt-7 rounded-3xl overflow-hidden flex justify-center">
                        <img
                          className="object-cover max-h-[300px]"
                          src={perciption_chap3_img9}
                          alt=""
                        />
                      </span>
                    </SwiperSlide>
                    <SwiperSlide>
                      <p className="text-2xl mb-4">Introvert to Extrovert Conversation</p>
                      <p className="text-xl mb-4">
                        How it looks when introverts and extraverts communicate with one another.
                      </p>
                      <p className="text-xl  mb-4">
                        When introverts are connecting to Extroverts, we really can run into
                        disconnects.
                      </p>
                      <span className=" mt-7  overflow-hidden flex justify-center">
                        <div className="w-1/6">
                          <p className="text-xs font-bold text-center">
                            Hey!!! Do you ever think before you speak?
                          </p>
                        </div>
                        <img
                          className="object-cover max-h-[300px]"
                          src={perciption_chap3_img10}
                          alt=""
                        />
                        <div className="w-1/6">
                          <p className="text-xs font-bold text-center">
                            Hello!!! Anybody home? Can you hear me?
                          </p>
                        </div>
                      </span>
                    </SwiperSlide>
                  </Swiper>
                </CustomTabPanel>
                <CustomTabPanel index={1} value={energyTabValue}>
                  <div className="flex text-base font-normal items-center relative">
                    <div className="left-4">
                      <p className="w-64 text-center text-lg absolute top-0 left-0">
                        <strong>
                          Thinking Preference
                          <br /> (Results)
                        </strong>
                      </p>

                      <p className="w-64 text-center text-lg absolute top-0 right-0">
                        Informal, Personal, Heartfelt, Involved, Subjective, Accommodating,
                        Realtionship focused
                      </p>
                    </div>
                    <div className=" mx-8">
                      <img src={perciption_chap3_img5} alt="" />
                    </div>
                    <div className="right-sec">
                      <p className="w-64 text-center text-lg absolute bottom-0 left-0">
                        Formal, Impersonal, Analytcal, Detached, Objectve, Strong-minded,
                        Competitive, Partcular, Task focused
                      </p>

                      <p className="w-64 text-center text-lg absolute bottom-0 right-0">
                        <strong>
                          Feeling Preference
                          <br /> (Relationships)
                        </strong>
                      </p>
                    </div>
                  </div>
                </CustomTabPanel>
                <CustomTabPanel index={2} value={energyTabValue}>
                  <span>
                    {' '}
                    <div className="flex text-base font-normal items-center w-11/12 mx-auto">
                      <div className="inline-block">
                        <p className="mb-7  inline-block text-xl">
                          <strong>Sensation</strong>
                        </p>
                        <ul className="list-disc pl-5 ">
                          <li className="mb-2 pl-4 whitespace-nowrap">Fact-based</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Specific</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Realistic</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Practical</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Objective</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Down-to-earth</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Percise</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Present-oriented</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Detailed</li>
                        </ul>
                      </div>
                      <div className="flex justify-center w-11/12 text-center  mx-8">
                        <img src={perciption_chap3_img4} alt="" />
                      </div>
                      <div className="right-sec inline-block">
                        <p className="mb-7  inline-block text-xl">
                          <strong>Intution</strong>
                        </p>
                        <ul className="list-disc pl-5 ">
                          <li className="mb-2 pl-4 whitespace-nowrap">Global</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Conceptual</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Abstract</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">See possibilities</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Future-oriented</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Imaginative</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Catalytic</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Indirect</li>
                          <li className="mb-2 pl-4 whitespace-nowrap">Generalities</li>
                        </ul>
                      </div>
                    </div>
                  </span>
                </CustomTabPanel>
              </>
            )}
          </>
        </CustomAudioTabsBlock>
        {_.isNil(energyTabValue) && (
          <MediaBlock mediaImage={energy_reenergies} cssClass="!mt-3"></MediaBlock>
        )}
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-2">
          Activity: Are you predominantly Introverted or Extroverted?
        </h3>
        <p className="mb-8">
          <span className="font-bold">Group A:</span> Check off all the points that you feel
          describes you.
        </p>
        <ul>
          <li className="mb-7">
            <label className="custom-checkbox">
              You feel inspired after spending time with others and gain energy from social
              interactions.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="mb-7">
            <label className="custom-checkbox">
              Too much alone time drains your energy and begins to make you feel listless.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="mb-7">
            <label className="custom-checkbox">
              You thrive being the center of attention and are comfortable with unfamiliar
              situations.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="mb-7">
            <label className="custom-checkbox">
              Striking up a conversation, even with total strangers, brings you joy, and you often
              speak to explore and organize your thoughts.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="mb-7">
            <label className="custom-checkbox">
              You often spearhead group outings and other social events. It's rare for you to turn
              down an invitation to a party or gathering.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="mb-7">
            <label className="custom-checkbox">
              Adaptability is your strength, and you welcome spontaneous decisions in your life.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
        </ul>
        <p className="font-bold mb-8">
          Count the number of check marks you checked in Group A and click continue.
        </p>
        <div className="border-t mb-8"></div>
        <p className="mb-8">
          <span className="font-bold">Group A:</span> Check off all the points that you feel
          describes you.
        </p>
        <ul>
          <li className="mb-7">
            <label className="custom-checkbox">
              You feel a need for quiet when you want to concentrate.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="mb-7">
            <label className="custom-checkbox">
              Prefer only a few friendship that you are very close to.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="mb-7">
            <label className="custom-checkbox">
              You like to daydream or use your imaginations to work out a problem.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="mb-7">
            <label className="custom-checkbox">
              You feel tired after being in a crowd.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="mb-7">
            <label className="custom-checkbox">
              You feel comfortable being alone and prefer not to work in groups.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="mb-7">
            <label className="custom-checkbox">
              You like to retreat into your own mind to rest.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
        </ul>
        <p className="font-bold mb-8">Count the number of check marks you checked in Group B.</p>
        <div className="border-t mb-8"></div>
        <div className="bg-gray-100 rounded-3xl p-5 font-bold mb-11">
          Which group did you have the most check marks, group A or B?
          <br />
          <br />
          Group A represents extroverts and Group B represents introverts. Chances are you would
          land somewhere on a spectrum between these two.
        </div>
        <InfoBlock
          title="Note: "
          text="This quick assessment has not been validated and is intended for illustrative purposes only."
        />
        <div className="border-t mb-8"></div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-2">Spectrum</h3>
        <p className="font-bold mb-5">
          Most people fall on a spectrum between introverted and extroverted.
        </p>
        <p className="mb-5">
          According to Carl Jung, none of us can be completely introverted or extroverted. People
          cannot be really categorized as only one of the two.
          <br />
          <br />
          Introversion and extroversion are the two ends of a spectrum and we exist within the
          spectrum. It is a matter of where you fall on the introvert-extrovert spectrum and this
          can also be impacted by what is going on in your life and external circumstances. But in
          general, most of us tend to be more prominent on side or the other.
        </p>
        <img src={spectrum} alt="" />
        <p className="font-bold">
          What's important to remember is that one is not better than the other and we do no have to
          let these labels define us.
        </p>
        <div className="border-t mb-8"></div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-2">
          Activity: Applying Jungian’s Preferences
        </h3>
        <p className="font-bold">
          Reflecting on Jungian’s preferences click on the question tabs below and answer the
          following questions:
        </p>
        <InfoBlock
          title="Note: "
          text="Upon completion of answering all three questions, you may wish to click next and save as a pdf or print your responses.  "
        />
        {accordionData.map((data) => (
          <CustomQuestionAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
            chapter={chapter}
            course={courseFilter[0]}
            onChange={validateAnswer}
          />
        ))}
        <p className="text-3xl leading-10 mt-8">
          Now we'll look at preferences through the application in Insights Discovery.
        </p>
        <button
          disabled={isFormDirty}
          onClick={handleNextChapterClick}
          className="w-full btn-primary uppercase mt-8"
        >
          Insights Discovery
        </button>
      </div>
    </>
  );
};
