import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import { UserAsaTaskHistory } from './asa-task-history.types';

export type UserAsaTaskHistoryState = Partial<UserAsaTaskHistory>;

const initialState: UserAsaTaskHistoryState = {
  userId: '',
  asaUserId: 0,
  tasks: [],
};

const userAsaTaskHistorySlice = createSlice({
  name: 'userAsaTaskHistory',
  initialState,
  reducers: {
    updateUserAsaTaskHistoryInfo: (state, action: PayloadAction<UserAsaTaskHistory>) => {
      logger.debug('Updating user task history state.', {
        state,
        action,
      });
      if (action.payload) Object.assign(state, { ...action.payload });
    },
  },
});

export const { updateUserAsaTaskHistoryInfo: updateUserAsaTaskHistoryInfoAction } =
  userAsaTaskHistorySlice.actions;
export default userAsaTaskHistorySlice.reducer;
