import React from 'react';
import { Link } from 'react-router-dom';

import img5 from '../../assets/images/swot/img5.svg';

export const Resources: React.FC = () => {
  return (
    <>
      <div className="text-center lg:px-16">
        <img className="mx-auto mt-5" src={img5} alt="" />
        <p className="mt-8 text-xl">
          We also want to encourage you to share Vero Learning with your peers and invite them to
          explore our career exploration questionnaire. We believe that everyone deserves a fair
          chance to pursue their dream careers and we are excited for the opportunity to serve more
          students.
          <br />
          <br />
          <span className="uppercase font-bold !text-purple-700">
            <Link target="_blank" className="underline" to="https://www.verolearning.com">
              VISIT NOW
            </Link>
            <br />
          </span>
          <br />
          We understand that career exploration can be overwhelming. As you prepare for the next
          steps, we have also included a complied list of free resources to help you increase your
          employability.
        </p>
        <br />
        <Link target="_blank" to="/free-resources" className="btn-primary disabled">
          Free Resources
        </Link>
        <br />
        <p className="mt-8 text-xl">
          Our team is hard at work preparing for the launch our transferable skills modules, digital
          badging and job matching technology in early June of 2024. These new features will help
          you enhance your skill sets and find compatible vocational opportunities from top
          companies that match your interests and abilities.
          <br />
          <br />
          With our platform, we hope to bridge the gap between education and employment and help
          prepare young adults like you for fulfilling careers. Thank you for choosing Vero
          Learning, We look forward to being part of your success story!
        </p>
        <br />
        <br />
        <Link to="/dashboard" className="btn-primary">
          Dashboard
        </Link>
        <br />
        <br />
        <p className="uppercase font-bold text-purple-700">ALL THE BEST - VERO LEARNING TEAM</p>
      </div>
    </>
  );
};
