import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import {
  getExperienceAssessmentValues,
  updateExperienceAssessment,
} from '../../../services/database/form-experience';

import {
  ExperienceAssessmentState,
  updateExperienceAssessmentAction,
} from './form-experience.slice';
import { UpdateExperienceAssessmentDTO } from './form-experience.types';

/**
 * retrieve previously saved values from the skills assessment
 */
export const dispatchGetExperienceAssessmentValues =
  () =>
  async (dispatch: Dispatch<PayloadAction<ExperienceAssessmentState>>): Promise<void> => {
    const values: ExperienceAssessmentState = await getExperienceAssessmentValues();
    dispatch(updateExperienceAssessmentAction(values));
  };

/**
 * update progress in the skills assessment
 */
export const dispatchUpdateExperienceAssessment =
  (dto: UpdateExperienceAssessmentDTO) =>
  async (dispatch: Dispatch<PayloadAction<UpdateExperienceAssessmentDTO>>): Promise<void> => {
    await updateExperienceAssessment(dto);
    dispatch(updateExperienceAssessmentAction(dto));
  };
