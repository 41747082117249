import { useSelector } from 'react-redux';

import { CareerReadinessState } from '../redux/slice/form-career-readiness/form-career-readiness.slice';
import { EmploymentReadinessState } from '../redux/slice/form-employment-readiness';
import { ExperienceAssessmentState } from '../redux/slice/form-experience/form-experience.slice';
import { IntakeFormState } from '../redux/slice/form-intake';
import { SkillsState } from '../redux/slice/form-skills/form-skills.slice';
import { RootState } from '../redux/store';

export const useIntakeFormContext = (): IntakeFormState => {
  const state: IntakeFormState = useSelector((state: RootState) => state.formIntake);
  return state;
};

export const useEmploymentReadinessContext = (): EmploymentReadinessState => {
  const state: EmploymentReadinessState = useSelector(
    (state: RootState) => state.formEmploymentReadiness,
  );
  return state;
};

export const useSkillsContext = (): SkillsState => {
  const state: SkillsState = useSelector((state: RootState) => state.formSkills);
  return state;
};

export const useCareerReadinessContext = (): CareerReadinessState => {
  const state: CareerReadinessState = useSelector((state: RootState) => state.formCareerReadiness);
  return state;
};

export const useExperienceAssessmentContext = (): ExperienceAssessmentState => {
  const state: ExperienceAssessmentState = useSelector(
    (state: RootState) => state.formExperienceAssessment,
  );
  return state;
};
