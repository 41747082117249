import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  EmploymentReadinessAnswer,
  MarketingToolkitAnswer,
  MarketingToolkitQuestion,
} from '../../assessments/employment-readiness/employment-readiness.types';
import char from '../../assets/images/intake/step10/char.svg';
import { useEmploymentReadinessContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  dispatchUpdateEmploymentReadiness,
  EmploymentReadinessState,
} from '../../redux/slice/form-employment-readiness';
import { ProfessionalResourceStatus } from '../../redux/slice/form-employment-readiness/form-employment-readiness.types';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';

interface ComponentProps {
  question: MarketingToolkitQuestion;
  step: number;
  onBack: () => void;
}

type AnswersState = {
  [questionId: string]: EmploymentReadinessAnswer;
};

export const convertProfessionalResourceStatusToString = (
  answer: EmploymentReadinessAnswer,
): 'what_is_it' | 'no' | 'yes' | 'incomplete' | 'outdated' | 'unprofessional' | undefined => {
  const typedAnswer: MarketingToolkitAnswer = answer as MarketingToolkitAnswer;
  switch (typedAnswer?.answer) {
    case undefined:
      return undefined;
    case ProfessionalResourceStatus.YES:
      return 'yes';
    case ProfessionalResourceStatus.NO:
      return 'no';
    case ProfessionalResourceStatus.WHAT_IS_IT:
      return 'what_is_it';
    case ProfessionalResourceStatus.INCOMPLETE:
      return 'incomplete';
    case ProfessionalResourceStatus.OUTDATED:
      return 'outdated';
    case ProfessionalResourceStatus.UNPROFESSIONAL:
      return 'unprofessional';
  }
};

export const CandidateMarketingToolkit: React.FC<ComponentProps> = ({ step, question, onBack }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);

  const [error, setError] = useState('');
  const [answer, setAnswer] = useState<AnswersState>({});
  const [isLoading, setIsLoading] = useState(false);
  const savedAnswer: EmploymentReadinessState = useEmploymentReadinessContext();
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
  } = usePreEmploymentAssessmentContext();

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);

  const updateAnswer = (
    questionId: string,
    updatedAnswer: Partial<EmploymentReadinessAnswer>,
  ): void => {
    setError('');
    setAnswer((previousState: AnswersState) => ({
      ...previousState,
      [questionId]: {
        ...answer[questionId],
        ...updatedAnswer,
      },
    }));
  };

  const { handleSubmit } = useForm();
  const submitWorkHistory = async () => {
    logger.debug('Submitted professional resume option.', answer);
    setIsLoading(true);
    try {
      if (!answer[question.id]) {
        setError('Please select an option');
      } else {
        if (isDisabled) {
          navigate(`/pre-employment/employment-readiness/step${step + 1}`);
        } else {
          await dispatch(dispatchUpdateEmploymentReadiness(answer));
          navigate(`/pre-employment/employment-readiness/step${step + 1}`);
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: savedAnswer[question.id] ? progress : totalProgress,
                activeStepUrl: `/pre-employment/employment-readiness/step${step + 1}`,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/employment-readiness/step${step + 1}`,
              }),
            );
          }
        }
      }
    } catch (err) {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  const backButtonClick = () => {
    onBack();
  };

  useEffect(() => {
    if (savedAnswer && Object.keys(savedAnswer).length) {
      setAnswer(savedAnswer);
    }
  }, [savedAnswer]);

  return (
    <div>
      <div>
        <p className="text-lg mt-7 md:px-10">
          In this section, we'll delve into the world of self-marketing, a crucial skill for any
          aspiring professional. Let's start with understanding your self-marketing proficiency
          through check-off and multiple-choice style questions.
        </p>
        <p className="text-lg mt-7 md:px-10">
          These questions will help you evaluate your current standing in terms of self-marketing
          materials. Don't worry; we're here to guide you through every step of the way.
        </p>
        <p className="text-lg mt-7 md:px-10">
          <span className="font-semibold">Self-marketing package: </span>
          including sample cover letters, resume, profile, job interview skills.
        </p>
      </div>
      <div className="flex  mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">11</div>
        <div>
          <h4 className="text-2xl">
            I have an updated
            <span className="font-semibold"> {question.resource}</span>
          </h4>
        </div>
      </div>
      <div className="character absolute top-96 right-[-125px] z-[-1]">
        <img className="" src={char} alt="" />
      </div>
      <form
        className="md:px-10"
        action=""
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitWorkHistory)}
      >
        <div className="grid gap-y-3">
          {Object.entries(question.options).map(([label, value]) => (
            <div key={label} className="h-[90px] lg:h-[60px]">
              <label className="radio-container h-full p-4 block relative">
                <input
                  disabled={isDisabled}
                  checked={convertProfessionalResourceStatusToString(answer[question.id]) === value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    updateAnswer(question.id, {
                      question: question.id,
                      answer: event.target.value as ProfessionalResourceStatus,
                    })
                  }
                  value={value}
                  className="w-full h-full absolute top-0 left-0 opacity-0"
                  type="radio"
                  name="resume option"
                />
                <span className="checkmark !block h-auto pt-4">{label}</span>
              </label>
            </div>
          ))}
          {error && <p className="text-red-500">{error}</p>}
        </div>
        <div className="flex justify-end lg:justify-between lg:mt-20 mt-10">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
