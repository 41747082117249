import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import * as React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tab-body"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabsBlockProps {
  tabsData: {
    title: string;
    mediaImage?: string;
    htmlContent?: string;
    audioUrl?: string;
    slideData?: [{ htmlContent: string; image?: string }];
  }[];
}

export const TabsBlock: React.FC<TabsBlockProps> = ({ tabsData }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="custom-tab w-full border border-1 bg-[#f8f8f8] rounded-3xl">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          {tabsData.map((tab, index) => (
            <Tab key={index} label={tab.title} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {tabsData.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.slideData?.length ? (
            tab.slideData.map((slide, index) => (
              <>
                <h1>
                  slide {index}
                  <span dangerouslySetInnerHTML={{ __html: slide.htmlContent }} />
                </h1>
              </>
            ))
          ) : (
            <>
              {tab.htmlContent && <span dangerouslySetInnerHTML={{ __html: tab.htmlContent }} />}
              {tab.mediaImage && (
                <span className="block mt-7 rounded-3xl overflow-hidden">
                  <img
                    className="object-cover h-full w-full  max-h-[300px]"
                    src={tab.mediaImage}
                    alt=""
                  />
                </span>
              )}
            </>
          )}
        </CustomTabPanel>
      ))}
    </div>
  );
};
