import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import * as React from 'react';

import { courses } from '../../courses/courses';
import { Course } from '../../courses/courses.types';
import { useCourseProgressContext } from '../../hooks/use-user-course.hooks';
import { dispatchUpdateChapterProgress } from '../../redux/slice/user-course';
import { useAppDispatch } from '../../redux/store';

interface PageProps {
  courseId: string;
  chapterId: string;
  disableContentScroll: boolean;
  enableCompleteScroll: boolean;
}
export const PageScrollProgress: React.FC<PageProps> = ({
  courseId,
  chapterId,
  disableContentScroll,
  enableCompleteScroll,
}) => {
  const courseProgress = useCourseProgressContext();
  const dispatch = useAppDispatch();
  const [scrollTop, setScrollTop] = React.useState(
    courseProgress?.[courseId]?.chapters?.[chapterId] ?? 0,
  );

  React.useEffect(() => {
    if (courseProgress && courseProgress[courseId]) {
      setScrollTop(courseProgress?.[courseId]?.chapters?.[chapterId] ?? 0);
    }
  }, [courseProgress]);

  React.useEffect((): (() => void) | void => {
    const handleScroll = () => {
      const contentHeight = document.querySelector('.content-wrap');
      if (contentHeight && scrollTop < 100) {
        const winScroll = contentHeight.scrollTop;
        const height = contentHeight.scrollHeight - contentHeight.clientHeight;
        const scrolled = Math.round((winScroll / height) * 100);
        if (scrollTop < scrolled && (scrollTop < 90 || enableCompleteScroll)) {
          setScrollTop(scrolled > 90 && !enableCompleteScroll ? 90 : scrolled);
          let totalProgress = courseProgress[courseId]?.progress || 0;

          if (enableCompleteScroll && scrolled >= 100) {
            const course: Course | undefined = courses.find((course) => course.id === courseId);
            const flatChapters = _.flatMap(course?.sections, 'chapters');
            const currentChapterProgress = flatChapters.find(
              (x) => x.id === chapterId,
            )?.completion_percentage;
            totalProgress = courseProgress[courseId]?.progress + (currentChapterProgress || 0) || 0;
          }

          //const currentCourseProgress = courseProgress[courseId]?.progress;
          void dispatch(
            dispatchUpdateChapterProgress({
              [courseId]: {
                activeChapter: chapterId,
                chapters: { [chapterId]: scrolled > 90 && !enableCompleteScroll ? 90 : scrolled },
                progress: _.clamp(totalProgress, 0, 100),
              },
            }),
          );
        }
      }
    };
    if (!disableContentScroll) {
      const contentHeight = document.querySelector('.content-wrap');
      if (contentHeight && !disableContentScroll) {
        contentHeight.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (contentHeight) {
          contentHeight.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [scrollTop]);

  return (
    <>
      <div className="page-progress relative">
        <CircularProgress
          variant="determinate"
          sx={{ color: '#d4a5ff', position: 'absolute' }}
          size={25}
          thickness={7}
          value={100}
        />
        <CircularProgress
          className="progress-color"
          sx={{ color: '#962CC6' }}
          size={25}
          thickness={7}
          variant="determinate"
          value={scrollTop}
        />
      </div>
    </>
  );
};
