import { ReadinessQuestionType, ReadinessQuestion } from './career-readiness.types';

export const questions: ReadinessQuestion[] = [
  {
    id: '6d9c8b6c-8de9-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I understand the career I want to pursue.',
  },
  {
    id: '8f6bdd2e-8de9-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I have clearly defined career goals.',
  },
  {
    id: '9a760848-8de9-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I have explored and understand my own career interests.',
  },
  {
    id: 'b8bcadf2-8de9-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I have explored my personality type and understand the careers that fit my type.',
  },
  {
    id: 'c2868204-8de9-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question:
      'I have explored by abilities and how they related to my career interests and personality type.',
  },
  {
    id: 'e4599704-8de9-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I have explored my work values and know what is important to me in a career.',
  },
  {
    id: 'f4834d28-8de9-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I know the steps I need to take to achieve my career goals.',
  },
  {
    id: '5d2fa20e-8dea-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I have started collecting information related to my career goals.',
  },
  {
    id: '6dca00f0-8dea-11ee-b9d1-0242ac120002',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question:
      'I know what skills, qualifications and characterstics employers expect in my desired career field.',
  },

  {
    id: '4bf76c0f-dc38-4e08-a88c-8775d435bb8c',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I know the kind of job(s) that is suitable for me.',
  },
  {
    id: 'c9e196d3-81f6-4905-b0b8-9d48a7de8f7a',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question:
      'I know what kind of organizations are out there doing the kind of work I’m interested in.',
  },
  {
    id: 'c339ed31-382d-45fe-9a3c-ccf43f62d7bd',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I know what a typical career track in this field looks like.',
  },
  {
    id: 'ae935297-d6a4-49d4-a8b2-75a051b09658',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question:
      'I have completed the required high school subjects, licences, and/or certification I will need in my career choice.',
  },
  {
    id: 'e3bff13a-65be-4710-a008-457e14128e46',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I move towards my goal, regardless of what others may think.',
  },
  {
    id: 'deefb9a8-cb6d-42a5-8456-e7080fcb94db',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I spend time on developing my expertise and interest.',
  },
  {
    id: '415a2f30-a4fc-4920-ae1c-92ce774b45fe',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I think my relevant working experience is too limited.',
  },
  {
    id: 'f5760ae3-a119-47db-9bee-4070ad92a80d',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I know how to self-advocate for opportunities in the workplace.',
  },
  {
    id: '6e76a2d7-c71c-45ab-bc7f-16cd29cbf80b',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I’d like a stronger perspective regarding future job potential.',
  },
  {
    id: 'e68f66a1-b2ff-4904-b74e-93ac5d05878b',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question:
      'I’d like a clearer picture of my strengths, interests, values, and personality type.',
  },
  {
    id: '17eac6b1-f4ff-4a06-8720-338a1dbd6609',
    type: ReadinessQuestionType.AGREEMENT_LEVEL,
    question: 'I want identify competency areas that I can further develop.',
  },
];
