import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import {
  getCareerReadinessValues,
  updateCareerReadiness,
} from '../../../services/database/form-career-readiness';

import { CareerReadinessState, updateCareerReadinessAction } from './form-career-readiness.slice';
import { UpdateCareerReadinessDTO } from './form-career-readiness.types';

/**
 * retrieve previously saved values from the career readiness assessment
 */
export const dispatchGetCareerReadinessValues =
  () =>
  async (dispatch: Dispatch<PayloadAction<CareerReadinessState>>): Promise<void> => {
    const values: CareerReadinessState = await getCareerReadinessValues();
    dispatch(updateCareerReadinessAction(values));
  };

/**
 * update progress in the career readiness assessment
 */
export const dispatchUpdateCareerReadiness =
  (dto: UpdateCareerReadinessDTO) =>
  async (dispatch: Dispatch<PayloadAction<UpdateCareerReadinessDTO>>): Promise<void> => {
    await updateCareerReadiness(dto);
    dispatch(updateCareerReadinessAction(dto));
  };
