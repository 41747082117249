import { TextField, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';

interface DropdownItem {
  value: string;
  label: string;
}

interface DropdownProps {
  defaultValue: string;
  items: DropdownItem[];
  onSelectionChange: (value: string) => void;
}

const MuiDropdown: React.FC<DropdownProps> = ({ defaultValue, items, onSelectionChange }) => {
  const [documentType, setDocumentType] = useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setDocumentType(newValue);
    onSelectionChange(newValue);
  };

  useEffect(() => {
    setDocumentType(defaultValue);
  }, [defaultValue]);

  return (
    <TextField
      required
      id="documentType"
      select
      label="Document type"
      value={documentType}
      onChange={handleChange}
      helperText=""
    >
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default MuiDropdown;
