import { useEffect } from 'react';

import { OrgAdminAuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { OrganizationUserOverview } from '../components/OrganizationAdminDashboard/OrganizationUserOverview';
import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
import { CustomUser } from '../redux/slice/auth/CustomUser';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';

const OrganizationAdminDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const user: CustomUser | null = useAuthContext();
  useEffect(() => {
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchGetUserProfileValues());
  }, []);
  return (
    <div className="dashboard">
      <Container>
        <div className="lg:flex items-center justify-between mb-5">
          <h2 className="dashboard-heading mb-4 lg:mb-0">Dashboard</h2>
        </div>
        <OrganizationUserOverview orgnizationId={user?.claims?.organizationId} />
      </Container>
    </div>
  );
};

export const OrganizationAdminDashboardPage: React.FC = () => {
  return <OrgAdminAuthenticatedPage render={() => <OrganizationAdminDashboard />} />;
};
