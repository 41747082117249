import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import character from '../../assets/images/intake/step1/character.svg';
import { useIntakeFormContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import { useAuthInitializedContext } from '../../hooks/use-user-auth-context.hooks';
import { PersonalInfoDTO } from '../../redux/slice/form-intake';
import { dispatchUpdateIntakeFormPersonalInfo } from '../../redux/slice/form-intake/form-intake.operations';
import { State as StateValue } from '../../redux/slice/form-intake/form-intake.types';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { dispatchUpdateUserProfileInfo } from '../../redux/slice/user-profile';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import { allowOnlyNumbers, handlePasteOnlyNumbers } from '../../utils/helper';
interface FormData extends PersonalInfoDTO {}

interface PersonalInfoProps {
  step: number;
}
const PersonalInfoSchema = yup
  .object({
    name: yup.string().trim().max(50, 'Name must not exceed 50 characters').required('Required'),
    addressLine1: yup
      .string()
      .trim()
      .max(100, 'City must not exceed 50 characters')
      .required('Required'),
    email: yup
      .string()
      .max(100, 'Email must not exceed 100 characters')
      .email('Invalid email address')
      .required(),
    city: yup.string().trim().max(50, 'City must not exceed 50 characters').required('Required'),
    state: yup.string().required(),
    phoneCountryCode: yup.string().required(),
    phone: yup
      .string()
      .min(10, 'Phone must be 10 characters')
      .max(10, 'Phone must not exceed 10 characters')
      .required(),
  })
  .required();
export const PersonalInfo: React.FC<PersonalInfoProps> = ({ step }) => {
  const userInitialized = useAuthInitializedContext();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const country_codes = [
    {
      value: '+1',
      label: '+1',
    },
  ];
  const { name, addressLine1, city, state, phoneCountryCode, phone, email } =
    useIntakeFormContext();
  const state_options = (Object.keys(StateValue) as Array<keyof typeof StateValue>)
    .filter((key) => typeof StateValue[key] === 'string')
    .map((key) => ({ label: key, value: StateValue[key] }));

  const {
    status: preEmploymentAssessmentStatus,
    disablePersonalInfo,
    progress,
    defaultStepProgress,
  } = usePreEmploymentAssessmentContext();

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(PersonalInfoSchema),
    defaultValues: {
      name: name,
      addressLine1: addressLine1,
      city: city,
      state: state,
      phoneCountryCode: phoneCountryCode || '+1',
      phone: phone,
      email: email,
    },
  });

  const submitPersonalInfo: SubmitHandler<FormData> = async (data: FormData) => {
    logger.debug('Submitted personal info.', data);
    setIsLoading(true);
    const formData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
    };
    if (isDisabled) {
      navigate(`/pre-employment/intake/step2`);
    } else {
      try {
        await dispatch(dispatchUpdateIntakeFormPersonalInfo(data));
        await dispatch(dispatchUpdateUserProfileInfo(formData));
        if (defaultStepProgress) {
          const totalProgress = (progress ?? 0) + defaultStepProgress;
          await dispatch(
            dispatchUpsertPreEmploymentAssessment({
              progress: addressLine1 ? progress : totalProgress,
              activeStepUrl: `/pre-employment/intake/step2`,
            }),
          );
        } else {
          await dispatch(
            dispatchUpsertPreEmploymentAssessment({
              activeStepUrl: `/pre-employment/intake/step2`,
            }),
          );
        }
        navigate(`/pre-employment/intake/step2`);
      } catch {
        toast.error('Something went wrong!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    reset({
      name,
      addressLine1,
      city,
      state,
      phoneCountryCode,
      phone,
      email,
    });
  }, [name, addressLine1, city, state, phoneCountryCode, phone, email, reset, userInitialized]);

  useEffect(() => {
    if (disablePersonalInfo) {
      navigate(`/pre-employment/intake/step${step + 1}`);
    }
  }, [disablePersonalInfo]);

  return (
    <div>
      <div className="flex mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">01</div>
        <div>
          <p className="text-lg">Let's start by understanding a bit about you:</p>
          <h4 className="text-2xl">
            My Personal <span className="font-semibold">Information</span>
          </h4>
        </div>
      </div>
      <div className="character absolute left-[-130px] z-[-1]">
        <img className="" src={character} alt="" />
      </div>
      <Box
        className="md:px-10"
        component="form"
        sx={{
          '& > :not(style)': { mb: 4 },
        }}
        autoComplete="off"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitPersonalInfo)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange } }) => (
            <TextField
              required
              className="w-full"
              disabled={isDisabled}
              id="name"
              type="text"
              value={value}
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              onChange={(event) => onChange(event.target.value)}
              label="Name"
              variant="outlined"
              inputProps={{ maxLength: 50 }}
            />
          )}
        ></Controller>
        <Controller
          control={control}
          name="addressLine1"
          render={({ field: { value, onChange } }) => (
            <TextField
              required
              className="w-full"
              id="addressLine1"
              type="text"
              value={value}
              disabled={isDisabled}
              error={!!errors.addressLine1}
              helperText={errors.addressLine1 && errors.addressLine1.message}
              onChange={(event) => onChange(event.target.value)}
              label="Address"
              variant="outlined"
              inputProps={{ maxLength: 100 }}
            />
          )}
        ></Controller>
        <div className="grid grid-cols-2 gap-6">
          <Controller
            control={control}
            name="city"
            render={({ field: { value, onChange } }) => (
              <TextField
                required
                className=""
                id="city"
                type="text"
                value={value}
                disabled={isDisabled}
                onChange={(event) => onChange(event.target.value)}
                label="City/Town"
                variant="outlined"
                error={!!errors.city}
                helperText={errors.city && errors.city.message}
                inputProps={{ maxLength: 50 }}
              />
            )}
          ></Controller>

          <Controller
            control={control}
            name="state"
            render={({ field: { value, onChange } }) => (
              <TextField
                required
                className=""
                id="outlined-select-state"
                select
                label="State"
                disabled={isDisabled}
                onChange={(event) => onChange(event.target.value)}
                value={value}
                helperText=""
                defaultValue=""
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Set the maximum height of the dropdown
                      },
                    },
                  },
                }}
              >
                <MenuItem key="select" value="">
                  Select
                </MenuItem>
                {state_options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          ></Controller>
        </div>
        <div className="flex gap-6">
          <Controller
            control={control}
            name="phoneCountryCode"
            render={({ field: { value, onChange } }) => (
              <TextField
                required
                className="w-2/6 sm:w-1/6"
                id="outlined-select-currency"
                select
                label=""
                disabled={isDisabled}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                helperText=""
                defaultValue="+1"
              >
                {country_codes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          ></Controller>

          <Controller
            control={control}
            name="phone"
            render={({ field: { value, onChange } }) => (
              <TextField
                required
                className="w-full"
                id="phone"
                type="text"
                disabled={isDisabled}
                inputProps={{
                  pattern: '^[0-9]*$',
                  onKeyPress: allowOnlyNumbers,
                  onPaste: handlePasteOnlyNumbers,
                  maxLength: 10,
                  minLength: 10,
                }}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                label="Telephone"
                variant="outlined"
              />
            )}
          ></Controller>
        </div>
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange } }) => (
            <TextField
              required
              className="w-full"
              id="email"
              type="email"
              disabled={isDisabled}
              value={value}
              onChange={(event) => onChange(event.target.value)}
              label="Email"
              variant="outlined"
              inputProps={{ maxLength: 100 }}
            />
          )}
        ></Controller>
        <div className="text-right">
          <button className="btn-primary" disabled={isLoading}>
            Next
          </button>
        </div>
      </Box>
    </div>
  );
};
