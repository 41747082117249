import chat_answer1 from '../../assets/images/courses/communication-basics/chapter3/cort/answer1.png';
import chat_answer2 from '../../assets/images/courses/communication-basics/chapter3/cort/answer2.png';
import chat_answer3 from '../../assets/images/courses/communication-basics/chapter3/cort/answer3.png';
import chat_character from '../../assets/images/courses/communication-basics/chapter3/cort/chat_character.png';
import chat_question1 from '../../assets/images/courses/communication-basics/chapter3/cort/chat_character.png';
import chap5_chat_answer1 from '../../assets/images/courses/communication-basics/chapter5/rosie/answer1.png';
import chap5_chat_answer2 from '../../assets/images/courses/communication-basics/chapter5/rosie/answer2.png';
import chat_character_5 from '../../assets/images/courses/communication-basics/chapter5/rosie/chat_character.png';
import chap5_chat_question1 from '../../assets/images/courses/communication-basics/chapter5/rosie/question1.png';
import chap6_chat_answer1 from '../../assets/images/courses/communication-basics/chapter6/sonee/answer1.png';
import chap6_chat_answer2 from '../../assets/images/courses/communication-basics/chapter6/sonee/answer2.png';
import chap6_chat_answer3 from '../../assets/images/courses/communication-basics/chapter6/sonee/answer3.png';
import chat_character_7 from '../../assets/images/courses/communication-basics/chapter6/sonee/chat_character.png';
import chap6_chat_question1 from '../../assets/images/courses/communication-basics/chapter6/sonee/question1.png';
import chap9_chat_answer1 from '../../assets/images/courses/communication-basics/chapter9/james/answer1.png';
import chap9_chat_answer2 from '../../assets/images/courses/communication-basics/chapter9/james/answer2.png';
import chap9_chat_answer3 from '../../assets/images/courses/communication-basics/chapter9/james/answer3.png';
import chat_character_09 from '../../assets/images/courses/communication-basics/chapter9/james/chat_character.png';
import chap9_chat_question1 from '../../assets/images/courses/communication-basics/chapter9/james/question1.png';
import {
  CourseChatQuestion,
  CourseQuestion,
  CourseSortingQuestion,
  JumpStartQuizQuestion,
  QuestionType,
} from '../jumpstart-communicator/jumpstart-communicator.types';

export const course_sorting_questions: CourseSortingQuestion[] = [
  {
    id: '31d80b2e-6bb4-4c61-aafa-572aed99e986',
    chapter_id: '1130183b-639b-4240-8073-a7a0fb416b26',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
    type: QuestionType.SORTING,
    questions: [
      {
        id: '00f86a35-6000-4f9f-bb36-fad73a9f7219',
        question: 'Effective at articulating yourself clearly and logically.',
      },
      {
        id: 'edc9c619-b1e7-43f0-a20b-1954478cad28',
        question: 'Ignoring Nonverbal Cues.',
      },
      {
        id: '423bfc0e-109d-478b-892e-448bbeefa31f',
        question: 'Unwilling to compromise.',
      },
      {
        id: '3d4b1492-edc8-4f27-a827-2f274c131214',
        question: 'Focus on the person instead of the issue.',
      },
      {
        id: 'b826571a-6ef0-45e5-9a4a-f0667b48d7a7',
        question: 'Dismiss other people’s feelings.',
      },
      {
        id: '88632a2f-ccf6-4d36-ba51-df0dbba9fcec',
        question: 'Nurture an environment where others feel comfortable participating.',
      },
      {
        id: 'f66f4476-57e0-464c-83cd-86c9efb9c488',
        question: 'Assume you know what the other person means.',
      },
      {
        id: 'f40d9b50-4a3b-4bc5-9a8b-c75788afb2c8',
        question: 'Allow others to clearly articulate themselves before you answer. ',
      },
      {
        id: '7474587b-6b8c-4db4-ad1b-80350316ac26',
        question: 'Build trusted working relationships.',
      },
      {
        id: '2b83daf1-71da-4412-827d-1169becd35cb',
        question: 'Understanding other’s points of view.',
      },
    ],
    options: [
      {
        id: '869e8371-8dd4-4dc0-a1fe-516bc9706c16',
        option: 'Great Communication Skill',
      },
      {
        id: 'ec5589af-b0f7-4aa2-8e32-abe31b3a0d6d',
        option: 'Poor Communication Skill',
      },
    ],
    answers: {
      '00f86a35-6000-4f9f-bb36-fad73a9f7219': {
        id: '869e8371-8dd4-4dc0-a1fe-516bc9706c16',
        option: 'Great Communication Skill',
      },
      'edc9c619-b1e7-43f0-a20b-1954478cad28': {
        id: 'ec5589af-b0f7-4aa2-8e32-abe31b3a0d6d',
        option: 'Poor Communication Skill',
      },
      '423bfc0e-109d-478b-892e-448bbeefa31f': {
        id: 'ec5589af-b0f7-4aa2-8e32-abe31b3a0d6d',
        option: 'Poor Communication Skill',
      },
      '3d4b1492-edc8-4f27-a827-2f274c131214': {
        id: 'ec5589af-b0f7-4aa2-8e32-abe31b3a0d6d',
        option: 'Poor Communication Skill',
      },
      'b826571a-6ef0-45e5-9a4a-f0667b48d7a7': {
        id: 'ec5589af-b0f7-4aa2-8e32-abe31b3a0d6d',
        option: 'Poor Communication Skill',
      },
      '88632a2f-ccf6-4d36-ba51-df0dbba9fcec': {
        id: '869e8371-8dd4-4dc0-a1fe-516bc9706c16',
        option: 'Great Communication Skill',
      },
      'f66f4476-57e0-464c-83cd-86c9efb9c488': {
        id: 'ec5589af-b0f7-4aa2-8e32-abe31b3a0d6d',
        option: 'Poor Communication Skill',
      },
      'f40d9b50-4a3b-4bc5-9a8b-c75788afb2c8': {
        id: '869e8371-8dd4-4dc0-a1fe-516bc9706c16',
        option: 'Great Communication Skill',
      },
      '7474587b-6b8c-4db4-ad1b-80350316ac26': {
        id: '869e8371-8dd4-4dc0-a1fe-516bc9706c16',
        option: 'Great Communication Skill',
      },
      '2b83daf1-71da-4412-827d-1169becd35cb': {
        id: '869e8371-8dd4-4dc0-a1fe-516bc9706c16',
        option: 'Great Communication Skill',
      },
    },
  },
  {
    id: '556ec912-2ab9-4067-ba2b-5f4818e092f7',
    chapter_id: '755d74e8-dc44-44c4-8aee-301ba6c2ccb2',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
    type: QuestionType.SORTING,
    questions: [
      {
        id: '23538914-3930-4f27-86a5-043382ce80b3',
        question: 'When is your birthday?',
      },
      {
        id: 'f4c657e5-9dd2-4cb0-9c7b-fda3a5ed5267',
        question: 'What are your weekly goals?',
      },
      {
        id: '00e5688b-1e4b-4940-acb7-35ea1b550d44',
        question: 'What was that like?',
      },
      {
        id: '3cc5b3ac-7bc9-4b05-9e04-09487edf4017',
        question: 'Do you know Ella?',
      },
      {
        id: '5bad9abb-cd71-4651-af46-5217661f1abb',
        question: 'Have you been here?',
      },
      {
        id: '29ba04c6-cca4-4461-bea5-136ded006710',
        question: 'Is this your pen?',
      },
      {
        id: '77e8aa34-8416-43cd-90cf-4a3eb82cb807',
        question: 'Why do you want to work here?',
      },
      {
        id: '106646fd-a540-4645-9fb1-7348dabfa297',
        question: 'What got you into this profession?',
      },
      {
        id: 'fca83c98-3fbf-4445-ab4a-1269d6d0a300',
        question: 'How do you know Ella?',
      },
      {
        id: '9569ec5d-3730-4e54-a728-3d02dd88227c',
        question: 'Were you nervous?',
      },
    ],
    options: [
      {
        id: '337b1707-25e6-43ea-856b-48e86d7e6ce5',
        option: 'Closed Questions',
      },
      {
        id: 'eee42b96-b20d-41e6-ae6c-b2bbfedf3594',
        option: 'Open Questions',
      },
    ],
    answers: {
      '23538914-3930-4f27-86a5-043382ce80b3': {
        id: '337b1707-25e6-43ea-856b-48e86d7e6ce5',
        option: 'Closed Questions',
      },
      'f4c657e5-9dd2-4cb0-9c7b-fda3a5ed5267': {
        id: 'eee42b96-b20d-41e6-ae6c-b2bbfedf3594',
        option: 'Open Questions',
      },
      '00e5688b-1e4b-4940-acb7-35ea1b550d44': {
        id: 'eee42b96-b20d-41e6-ae6c-b2bbfedf3594',
        option: 'Open Questions',
      },
      '3cc5b3ac-7bc9-4b05-9e04-09487edf4017': {
        id: '337b1707-25e6-43ea-856b-48e86d7e6ce5',
        option: 'Closed Questions',
      },
      '5bad9abb-cd71-4651-af46-5217661f1abb': {
        id: '337b1707-25e6-43ea-856b-48e86d7e6ce5',
        option: 'Closed Questions',
      },
      '29ba04c6-cca4-4461-bea5-136ded006710': {
        id: '337b1707-25e6-43ea-856b-48e86d7e6ce5',
        option: 'Closed Questions',
      },
      '77e8aa34-8416-43cd-90cf-4a3eb82cb807': {
        id: 'eee42b96-b20d-41e6-ae6c-b2bbfedf3594',
        option: 'Open Questions',
      },
      '106646fd-a540-4645-9fb1-7348dabfa297': {
        id: 'eee42b96-b20d-41e6-ae6c-b2bbfedf3594',
        option: 'Open Questions',
      },
      'fca83c98-3fbf-4445-ab4a-1269d6d0a300': {
        id: 'eee42b96-b20d-41e6-ae6c-b2bbfedf3594',
        option: 'Open Questions',
      },
      '9569ec5d-3730-4e54-a728-3d02dd88227c': {
        id: '337b1707-25e6-43ea-856b-48e86d7e6ce5',
        option: 'Closed Questions',
      },
    },
  },
  {
    id: '4aef8b75-c56c-45e6-accf-f16b4d882891',
    chapter_id: '4920ded8-1bc5-4949-90ef-a15f202234ee',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
    type: QuestionType.SORTING,
    questions: [
      {
        id: '70c577c4-a1eb-4e25-ba8d-9737573f8c79',
        question: 'You need to communicate a formal, private message. ',
      },
      {
        id: 'f181acec-d1ce-4a35-94f2-b33bd4d32db3',
        question: 'You need to have a real-time, back-and-forth, written conversation. ',
      },
      {
        id: '44f0cbef-cf74-46c1-9e56-0386dd5b2b91',
        question: 'You need to share an in-depth factual analysis to a potential client. ',
      },
      {
        id: '193f0757-9270-4f6c-87a5-07027eb83707',
        question: 'You need to send a well-thought-out message centered on one topic.',
      },
    ],
    options: [
      {
        id: '4507b250-8c09-45c1-8dea-978de7528430',
        option: 'Email',
      },
      {
        id: '96abfb4c-9726-495f-af7a-12cca1a5e510',
        option: 'Report',
      },
      {
        id: '910daf11-2cda-4d0f-9661-d964869abf43',
        option: 'Instant Message',
      },
      {
        id: '6bb81271-3024-4535-b899-eb59456b0384',
        option: 'Letter',
      },
    ],
    answers: {
      '70c577c4-a1eb-4e25-ba8d-9737573f8c79': {
        id: '6bb81271-3024-4535-b899-eb59456b0384',
        option: 'Letter',
      },
      'f181acec-d1ce-4a35-94f2-b33bd4d32db3': {
        id: '910daf11-2cda-4d0f-9661-d964869abf43',
        option: 'Instant Message',
      },
      '44f0cbef-cf74-46c1-9e56-0386dd5b2b91': {
        id: '96abfb4c-9726-495f-af7a-12cca1a5e510',
        option: 'Report',
      },
      '193f0757-9270-4f6c-87a5-07027eb83707': {
        id: '4507b250-8c09-45c1-8dea-978de7528430',
        option: 'Email',
      },
    },
  },
  {
    id: '0bd5bee5-1896-46d9-8c72-79e1bcdf107f',
    chapter_id: '4e237045-6c91-4b8d-b908-9395cc5a3dbc',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
    type: QuestionType.SORTING,
    questions: [
      {
        id: '95938d04-e112-4ee9-bd3d-361919d436c1',
        question: 'Depending on your audience’s knowledge, what should you change?',
      },
      {
        id: 'adc43c12-01b0-490e-950a-29ea0f9b0da7',
        question: 'Depending on your audience’s perspective, what should you change?',
      },
      {
        id: 'de8068fd-8fe6-4fe3-860a-e850d5710ffb',
        question: 'Depending on your relationship with your audience, what should you change?',
      },
    ],
    options: [
      {
        id: '6fef3e35-2af4-43f3-bd72-c1ee6aa2d312',
        option: 'Your content',
      },
      {
        id: 'cce5be8d-b8dd-45aa-811c-0469d7e6da61',
        option: 'Your content and language',
      },
      {
        id: '9a7f4471-fe2f-40cc-a3cc-85eec90d4628',
        option: 'Your tone',
      },
    ],
    answers: {
      '95938d04-e112-4ee9-bd3d-361919d436c1': {
        id: 'cce5be8d-b8dd-45aa-811c-0469d7e6da61',
        option: 'Your content and language',
      },
      'adc43c12-01b0-490e-950a-29ea0f9b0da7': {
        id: '6fef3e35-2af4-43f3-bd72-c1ee6aa2d312',
        option: 'Your content',
      },
      'de8068fd-8fe6-4fe3-860a-e850d5710ffb': {
        id: '9a7f4471-fe2f-40cc-a3cc-85eec90d4628',
        option: 'Your tone',
      },
    },
  },
  {
    id: '54d7b512-b202-4f46-8332-c7c2c48494ce',
    chapter_id: 'cd68cd54-dfac-4c19-8a20-cfc58255c78b',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
    type: QuestionType.SORTING,
    questions: [
      {
        id: 'f4890c13-64a1-4cae-a286-9301de686963',
        question: 'This stage involves reviewing the overall content of your writing.',
      },
      {
        id: '6b65f7c2-7f10-44ab-b4c2-5b948cecb191',
        question: 'You need to check for grammar, spelling, and punctuation errors.',
      },
      {
        id: '08cb843a-dfd4-4726-abc1-d8c111cd6015',
        question: 'This stage involves looking at the smaller details of your writing.',
      },
      {
        id: 'bded129b-c0f3-4f34-a75b-0c984c371175',
        question: 'You need to add more evidence and change the order and flow of your sentences.',
      },
    ],
    options: [
      {
        id: '6ac18b0f-745e-4b2d-b83e-336ed7406d35',
        option: 'Proofreading',
      },
      {
        id: '0e9068ff-f892-4640-9054-f5dbe8e78689',
        option: 'Editing',
      },
    ],
    answers: {
      'f4890c13-64a1-4cae-a286-9301de686963': {
        id: '0e9068ff-f892-4640-9054-f5dbe8e78689',
        option: 'Editing',
      },
      '6b65f7c2-7f10-44ab-b4c2-5b948cecb191': {
        id: '6ac18b0f-745e-4b2d-b83e-336ed7406d35',
        option: 'Proofreading',
      },
      '08cb843a-dfd4-4726-abc1-d8c111cd6015': {
        id: '6ac18b0f-745e-4b2d-b83e-336ed7406d35',
        option: 'Proofreading',
      },
      'bded129b-c0f3-4f34-a75b-0c984c371175': {
        id: '0e9068ff-f892-4640-9054-f5dbe8e78689',
        option: 'Editing',
      },
    },
  },
];
export const course_questions: CourseQuestion[] = [
  {
    id: 'aa87ccc0-98cc-48e1-ba92-be17d4958d1b',
    question:
      'You’re speaking to a friend over the phone, and she’s telling you about her recent promotion. You reply with: “That’s great for you!” but she’s offended and says: “What’s your problem?” What might have gone wrong?',
    options: [
      {
        id: '805b23ea-b985-48cd-bdea-89cd75db3aae',
        option: 'I likely didn’t pronounce my words correctly.',
      },
      {
        id: '6fdb00a0-2cfb-4369-afbb-82fa1b571087',
        option: 'I went into an off-topic tangent, which likely confused my friend. ',
      },
      {
        id: '17a95027-d68d-4603-a2de-6747391bd1a5',
        option: 'My tone of voice could have been off-putting.',
      },
    ],
    answers: ['17a95027-d68d-4603-a2de-6747391bd1a5'],
    answer_description:
      'That’s right! Your message will change depending on your tone of voice. Consider how saying “That’s great for you” is offensive if it’s said in a sarcastic tone. Remember, your tone should match your intention.',
    type: QuestionType.RADIO,
    chapter_id: 'f332d150-8a7c-4112-b34c-635913134a13',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '6da04130-7418-44b7-a41e-ae9a022b9b47',
    question:
      'Your coworker is feeling overwhelmed, so you place your hand on his shoulder to show empathy. He suddenly looks confused and offended. Is there anything you could have done differently?',
    options: [
      {
        id: 'cf3b539f-5193-4127-bd42-f15e4ef10985',
        option:
          'No, my nonverbal communication was open and friendly. There’s no reason my message should have been seen as offensive.',
      },
      {
        id: 'cb45877a-b510-4c72-9ffb-e091065b18ac',
        option: 'Yes, I could have paired the gesture with words to keep my intentions clear.',
      },
    ],
    answers: ['cb45877a-b510-4c72-9ffb-e091065b18ac'],
    answer_description:
      'It can be easy for a receiver to misinterpret and distort your nonverbal cues. If possible, pair nonverbal cues with words to make your meaning extra clear. ',
    type: QuestionType.RADIO,
    chapter_id: 'f332d150-8a7c-4112-b34c-635913134a13',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '27e707aa-2afc-494d-8b2b-2ed88058e0cd',
    question:
      'Your boss then replies with: “I’m sorry, I don’t understand what you’re asking.” What went wrong?',
    question_description:
      'You’d like to take a vacation, so you send your boss an email. In the message, you explain how you’re feeling overworked \n\n and burned out. You also mention that your family will be on holiday next month—so that’d be a great opportunity to go with them on a trip.',
    options: [
      {
        id: '7942a6e2-6925-4116-9649-454ad3978fba',
        option: 'I should have shared this request in person.',
      },
      {
        id: '37f58490-7f09-409d-9b14-25a66bbaeb36',
        option: 'I should have kept my message more clear and concise.',
      },
      {
        id: '18ee8c3e-19bf-4e3f-b646-e147760381b3',
        option: 'I should have checked the email for grammar, spelling, and punctuation errors.',
      },
    ],
    answers: ['37f58490-7f09-409d-9b14-25a66bbaeb36'],
    answer_description:
      'While you should always check for grammar, spelling, and punctuation, the problem here has more to do with the overall content of your message.',
    type: QuestionType.RADIO,
    chapter_id: 'f332d150-8a7c-4112-b34c-635913134a13',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '8436fe8e-b9a6-44b7-b9cc-0a1eb8da0857',
    question:
      'You’re running a project and plan to ask team members for help during a meeting tomorrow. Is there anything you can do today to increase your chance of success?',
    options: [
      {
        id: 'd1e8741f-dbd0-4fcc-b3cf-56db00488f3e',
        option:
          'There’s nothing I need to do because I want to keep my communication genuine and avoid sounding rehearsed.',
      },
      {
        id: '1462ad00-950e-46c8-b11b-2d5c8e968bb2',
        option:
          'I should take time to practice, thinking about what I want to say and how I might answer a few likely questions.',
      },
    ],
    answers: ['1462ad00-950e-46c8-b11b-2d5c8e968bb2'],
    wrong_answer_description:
      'One of the first rules of successful communication is to think before you speak. While it might be tempting to be spontaneous, you can polish your argument and improve your chance of success by thinking ahead.',
    answer_description:
      'That’s right! By thinking ahead, you’ll have a clearer idea of what information to provide and how to share that information. Plus, by thinking through some possible questions, it’s less likely that you’ll be caught off guard if you get a tricky question.',
    type: QuestionType.RADIO,
    chapter_id: '1130183b-639b-4240-8073-a7a0fb416b26',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: 'afd72b2b-ddc7-46fa-b716-99f62cd2eddc',
    question:
      'A coworker is telling you about a trip she took this past weekend. What can you do to show that you’re listening actively?',
    options: [
      {
        id: '8ad22502-e82c-40aa-bf16-b3520a03b483',
        option:
          'As soon as she says something I can relate to, I’ll prove I’m listening by chiming in with a relevant story.',
      },
      {
        id: 'e3260008-77b3-45c9-b3bd-6489f7e79641',
        option:
          'To show I’m listening, I’ll give her a few cues such as nodding my head and offering brief prompts such as: “That’s amazing!” and “Uh-huh.”',
      },
      {
        id: '2cd60f9f-aeea-4171-ad61-39d5ab5872fb',
        option:
          'To avoid uncomfortable silences, I’ll start planning out a few relevant questions to ask once she’s finished speaking.',
      },
    ],
    answers: ['e3260008-77b3-45c9-b3bd-6489f7e79641'],
    answer_description:
      'Great. These nonverbal cues and prompts are excellent ways to show you’re interested without pulling the focus away from your coworker.',
    wrong_answer_description:
      'If you’re planning a response, then you’re not truly listening. What if your coworker already answered one of your questions, but you missed it? Stay focused in the moment, and you’ll improve your understanding.',
    type: QuestionType.RADIO,
    chapter_id: 'd516aa29-4073-4687-a274-94d942f40854',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: 'dec6da2c-fcb9-4e4b-ba35-672a4c9f9b4b',
    question:
      'You need to write a detailed document for a new client. What’s your writing process?',
    options: [
      {
        id: '98dfd7f4-46dd-4f97-8218-63c91f13c4e7',
        option:
          'First, I’ll think about my purpose and my audience. Then, I’ll start writing and styling my work so that it reads clearly.',
      },
      {
        id: 'aee08684-a885-4746-84fe-2aa3dea8d1f1',
        option:
          'I’d like my ideas to flow freely, so I’ll immediately start writing. Then, once my document is complete, I’ll look over my work to see if I can improve the style and content.',
      },
      {
        id: '55a1b2c7-91f1-4d60-82bd-1c0093501aea',
        option:
          'I should first consider my purpose, choose a medium, think about my reader, and outline my work. Only after I’ve completed these steps should I start writing.',
      },
    ],
    answers: ['55a1b2c7-91f1-4d60-82bd-1c0093501aea'],
    answer_description:
      'While it can be tempting to skip a few steps in this process, consider how doing so could negatively impact your work. By outlining your writing and thinking about your purpose, audience, and medium in advance, you’ll be more likely to cover all your points and write something that flows smoothly.',
    type: QuestionType.RADIO,
    chapter_id: '6961e488-c33b-4e8c-9044-06d0c43bcadd',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '440ea447-c6dc-41ed-92ce-a8dbbaa55049',
    question:
      'Imagine you receive an email from your boss that says: “Thanks for all your effort this week. I know it was tough putting in those extra hours, but the results definitely paid off!” What was the purpose of your boss’s communication?',
    options: [
      {
        id: '6c52b6a4-75a2-46b1-8e54-7795bab9a648',
        option: 'To inform',
      },
      {
        id: 'e5e7acf1-addd-4652-a6bd-82d3e9c1c93e',
        option: 'To persuade ',
      },
      {
        id: '5eee5378-ec56-4dd3-8a59-2eaf2fdfc4cc',
        option: 'To convey goodwill',
      },
    ],
    answers: ['5eee5378-ec56-4dd3-8a59-2eaf2fdfc4cc'],
    answer_description:
      'This email is an example of a goodwill message. It’s kind and specific; it uses conversational language with words such as "thanks," and it includes exclamation points. Your boss’s purpose for writing this message was to show kindness and appreciation. ',
    type: QuestionType.RADIO,
    chapter_id: '84dcd17d-c845-4334-9712-253f1610b4b7',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '32a6b276-ea41-4bb9-81cb-4787f49adcc8',
    question:
      'Let’s say you need to write a welcome email to a new client. How should you phrase your message?',
    options: [
      {
        id: '03187e15-1d66-4ff9-a2ab-c1c6ac9f2357',
        option:
          'On behalf of our organization, I’d like to welcome you as a new client. Our core competency is all about pushing the envelope, and we look forward to having you be part of that journey.',
      },
      {
        id: 'cb2f1c7d-c75a-444c-b8ac-c2218d7764fb',
        option:
          'On behalf of our organization, I’d like to welcome you as a new client. We pride ourselves on our innovation, and we look forward to having you be part of that journey.',
      },
      {
        id: 'dbc449dc-2153-4611-9288-a0c3134cdb95',
        option:
          'Welcome! I’m super excited to work with you! We’re all about the “next best thing” here … and that definitely describes you!',
      },
    ],
    answers: ['cb2f1c7d-c75a-444c-b8ac-c2218d7764fb'],
    answer_description:
      'Because this relationship is new, you’ll want to keep your content specific and avoid business jargon, since your reader may be unfamiliar with insider talk. It’s also best to keep your writing professional since being overly informal too soon can be seen as disrespectful rather than friendly.',
    type: QuestionType.RADIO,
    chapter_id: '4e237045-6c91-4b8d-b908-9395cc5a3dbc',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '65bfed68-7640-40e4-89d9-922971c68f58',
    question:
      'When structuring your writing, what should you include in your conclusion paragraph?',
    options: [
      {
        id: 'bf83f580-8343-4fb3-8be7-5150e7731cea',
        option: 'I should branch out and touch on a few related topics.',
      },
      {
        id: 'ed9a289b-c9a5-4fd4-8093-296a0e1858c4',
        option: 'I should focus on one of the supporting points I mentioned in my body paragraphs.',
      },
      {
        id: '0ffe55ea-b8a4-4d57-b5f4-dfb6fea1cb87',
        option:
          'I should summarize all my supporting points, clarifying their significance and how they connect.',
      },
    ],
    answers: ['0ffe55ea-b8a4-4d57-b5f4-dfb6fea1cb87'],
    answer_description:
      'Your conclusion should broadly summarize the information you’ve shared and why it matters. That means closing out your document—not introducing new topics that could potentially confuse your reader. You should also avoid focusing on only one of your supporting points since this could overshadow the arguments you made in your other paragraphs.',
    type: QuestionType.RADIO,
    chapter_id: '96b82e5e-a11a-424c-a0d2-a2a6fd32a6ff',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '6cc0d55a-13ef-4c8b-93ce-ea027fd3dae8',
    question: 'Which of the sentences below uses the right spelling?',
    options: [
      {
        id: '4d09dd8f-4310-4866-9654-918bf1fff172',
        option: 'There having a great week.',
      },
      {
        id: '2290f692-9c64-4103-a850-39cae38ebcb2',
        option: 'They’re having a great week.',
      },
      {
        id: '990e705d-f9ae-4b89-b64a-ec712c23d246',
        option: 'Their having a great week.',
      },
    ],
    answers: ['2290f692-9c64-4103-a850-39cae38ebcb2'],
    answer_description:
      'The sentence should read: “They are having a great week.” Because “they’re” is short for “they are,” “they’re” is the correct choice.  Remember, “there” refers to a location and “their” refers to possession or ownership.',
    type: QuestionType.RADIO,
    chapter_id: '151cc8b6-491f-4cd5-a1c6-49b186bb5675',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: 'c4b4452f-3fda-4c72-962f-2a6e5a7447e6',
    question: 'Which of the sentences below uses proper grammar?',
    options: [
      {
        id: 'df7e53d1-4966-47f2-9f0c-a436693ae855',
        option:
          'Hoping to impress my boss, the in-depth report was much more detailed than the last.',
      },
      {
        id: '8e91d3c6-d988-44a5-b7ab-81fa80c2dd7b',
        option:
          'Hoping to impress my boss, I wrote an in-depth report that was much more detailed.',
      },
      {
        id: 'f30651e0-244f-477e-bd60-32d38ed6ad94',
        option:
          'Hoping to impress my boss, I wrote an in-depth report that was much more detailed than the last.',
      },
    ],
    answers: ['f30651e0-244f-477e-bd60-32d38ed6ad94'],
    answer_description:
      'Watch out for dangling modifiers. Choice one is incorrect because it sounds as though the report is the one hoping to impress your boss, instead of you. Choice two isn’t quite right either because there’s an incomplete comparison. The sentence will cause your reader to question: “The report was much more detailed than what?” Choice three answers the question with the added detail of: “than the last.”',
    type: QuestionType.RADIO,
    chapter_id: '151cc8b6-491f-4cd5-a1c6-49b186bb5675',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '26ad7eb9-f1a9-448d-8966-72aae0e82420',
    question: 'Which of these sentences uses the correct punctuation?',
    options: [
      {
        id: '2ba98e1d-767e-4777-88d1-22ab6cdac5c7',
        option: 'My friend Sarah who I’ve known for three years is stopping by later.',
      },
      {
        id: '673c7a07-c89b-4bc6-b3af-034824c3ce21',
        option: 'My friend Sarah; who I’ve known for three years is stopping by later.',
      },
      {
        id: 'b31635ff-8374-4446-9fd9-32fa351b7214',
        option: 'My friend Sarah, who I’ve known for three years, is stopping by later.',
      },
    ],
    answers: ['b31635ff-8374-4446-9fd9-32fa351b7214'],
    answer_description:
      'Commas are a great way to pause the flow of thought or introduce more detail, making choice three the right pick. Consider how choice one can confuse your reader by not separating “Sarah” and “who” or how choice two is incorrect because semicolons should only be used to separate two complete sentences.',
    type: QuestionType.RADIO,
    chapter_id: '151cc8b6-491f-4cd5-a1c6-49b186bb5675',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '40ef3f1a-a5dd-4388-8219-fb6a77822f0c',
    question:
      'Imagine you’ve just finished writing a business proposal for a meeting you have next week. How should you go about editing and proofreading this document?',
    options: [
      {
        id: 'fc2b508e-9fe1-4955-9f99-9d7e595fcb74',
        option:
          'While my ideas are still fresh, I should edit and proofread the document right away.',
      },
      {
        id: '294135fa-415d-4fc4-b1ec-32c80ed92ce6',
        option:
          'Because I still have some extra time, I should take a break before I start to edit and proofread my work.',
      },
    ],
    answers: ['294135fa-415d-4fc4-b1ec-32c80ed92ce6'],
    answer_description:
      'While it can be tempting to finish that document and “be done with it,” it’s best to first step away. That will help you create some distance so that you can come back to your work with a fresh outlook.',
    type: QuestionType.RADIO,
    chapter_id: 'cd68cd54-dfac-4c19-8a20-cfc58255c78b',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
];
export const course_chat_questions: CourseChatQuestion[] = [
  {
    title: 'What Should You Say First?',
    content:
      'Imagine you work in medical sales. You spot a potential client at your local coffee shop and want to make a great first impression. He says hi to you first. Do you know what to say first in reply? ',
    id: 'cd391826-5d81-47c4-a1fd-8028152190c9',
    question: '',
    question_img: chat_character,
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: 'de6547b5-298a-4bd1-8f21-e0df15a2f801',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: 'cd6761df-640e-4d51-825c-b4406414b9a6',
    question: 'Hi! I’m Cort. I own a medical practice just down the road.',
    question_img: chat_question1,
    options: [
      {
        id: '6c252d86-e520-4bab-a0cf-693c4fd74064',
        option:
          'Hi! I’ve got an impressive resume myself. I’m the top medical sales rep at my company and, just last quarter alone, I exceeded my sales quota by 30%.',
        description: 'That’s … umm … great for you.',
        img: chat_answer1,
      },
      {
        id: '7c0a5ee9-a2ba-45a0-9ff1-5b96f7c0cb6d',
        option:
          'You started your own practice? That’s fantastic! What inspired you to set out on your own?',
        description: 'That’s a long story! Pull up a chair, let’s chat.',
        img: chat_answer2,
      },
      {
        id: '0416ad6a-3493-4c25-b81b-1ebb17207749',
        option:
          'I’m in medical sales. My company creates top-of-the-line equipment that you could certainly benefit from. Here’s a copy of our partnership agreement. ',
        description: 'Wow, you come on strong, don’t you? Sorry, I’m not interested.',
        img: chat_answer3,
      },
    ],
    answer: '7c0a5ee9-a2ba-45a0-9ff1-5b96f7c0cb6d',
    answers: [],
    answer_description: 'That’s a long story! Pull up a chair, let’s chat.',
    type: QuestionType.CHAT,
    chapter_id: 'de6547b5-298a-4bd1-8f21-e0df15a2f801',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    title: 'Open or Closed?',
    content:
      'You and your coworker Rosie are brainstorming about your company’s next marketing campaign. You need some fresh ideas. What type of questions should you ask?',
    id: 'd045e0a2-9ef7-4cfc-835c-a77c372980bc',
    question: '',
    question_img: chat_character_5,
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: '755d74e8-dc44-44c4-8aee-301ba6c2ccb2',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '29d81fef-3636-43fa-9d72-2b973113cd1e',
    question: 'I’m ready to brainstorm! Where should we start?',
    question_img: chap5_chat_question1,
    options: [
      {
        id: '10fabd89-09bd-4532-a37d-f99a4c30dfe9',
        option:
          'To keep our conversation on track, I have a few targeted questions. For starters, do you like a yellow color scheme?',
        description: 'No, I don’t. Is that my only option?',
        img: chap5_chat_answer1,
      },
      {
        id: '8b82ce33-1667-4cd5-b7af-b4ed0ed3730a',
        option:
          'Let’s think outside of the box. What do you think would make this campaign unique?',
        description:
          'I have so many ideas! We can put a twist on a cliché, create memes, use video, and so much more. ',
        img: chap5_chat_answer2,
      },
    ],
    answer: '8b82ce33-1667-4cd5-b7af-b4ed0ed3730a',
    answers: [],
    answer_description:
      'I have so many ideas! We can put a twist on a cliché, create memes, use video, and so much more. ',
    type: QuestionType.CHAT,
    chapter_id: '755d74e8-dc44-44c4-8aee-301ba6c2ccb2',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    title: 'How Will You Wrap Up the Conversation?',
    content:
      'It’s a Friday afternoon, and you’re having a conversation with your coworker, Sonee. You need to get back to work, but you don’t want to be rude. How could you end the discussion positively?',
    id: 'c707ce1c-1947-4e4c-a372-6a0dfcce1e65',
    question: '',
    question_img: chat_character_7,
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: 'bafb65dd-07e9-45e6-b019-a2a24d0fe387',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: 'a79340f1-0b80-4698-8bcc-4a24618810b2',
    question:
      'I can’t wait for the weekend! Some of my family are in town, and we’re going to my absolute favorite restaurant.',
    question_img: chap6_chat_question1,
    options: [
      {
        id: 'd87aff36-cb20-418c-b727-27eabe6894b6',
        option: ' I’m sorry, but I need to get back to work now.',
        description: 'Oh. Sorry to be a bother. ',
        img: chap6_chat_answer1,
      },
      {
        id: '968ad2d2-e697-47e4-b0bd-5b41a9565016',
        option: 'Oh? That sounds like fun! Who’s visiting you? ',
        description:
          'My siblings are visiting. We weren’t always on the best terms as kids but now ...',
        img: chap6_chat_answer2,
      },
      {
        id: '46aa0d03-143a-4ab5-8026-696a0067702d',
        option:
          'That sounds fun! I’d love to hear more, but I’ve got to get back to work. It’s always great catching up. Let me know how everything goes on Monday!',
        description: 'You got it! Talk soon.',
        img: chap6_chat_answer3,
      },
    ],
    answer: '46aa0d03-143a-4ab5-8026-696a0067702d',
    answers: [],
    answer_description: 'You got it! Talk soon.',
    type: QuestionType.CHAT,
    chapter_id: 'bafb65dd-07e9-45e6-b019-a2a24d0fe387',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    title: 'Test Your Understanding',
    content:
      'Your boss James needs a lot of help today. Can you navigate your way through the following four scenarios? Select the written medium that’s best for each situation.',
    id: 'cd8aa028-a0c8-4395-aa59-ba6174ccc193',
    question: '',
    question_img: chat_character_09,
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: '4920ded8-1bc5-4949-90ef-a15f202234ee',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '514791b1-d367-45c2-99bf-323452f9e229',
    question:
      'Could you compare and contrast different marketing strategies for me? Once you finish, please summarize your research and write up a recommendation.',
    question_img: chap9_chat_question1,
    options: [
      {
        id: '9c472867-362c-42b0-bdd4-7282e03fc5da',
        option:
          ' Sure thing! I’ll share my findings via instant message as soon as they’re ready. ',
        description:
          'I’m expecting an in-depth research summary. An instant message isn’t going to cut it.',
        img: chap9_chat_answer1,
      },
      {
        id: 'a0052ca7-0ddf-490c-8101-d2096ff70359',
        option: 'You got it. I’ll write a fact-based report with all my findings.',
        description: 'That’s exactly what I need! Thank you.',
        img: chap9_chat_answer2,
      },
      {
        id: '9d6bc811-206a-4c94-996b-8cc8cc1a52d4',
        option: 'No problem. I’ll write a letter with those details.',
        description: 'There’s no need for all that formality. I just need the information.',
        img: chap9_chat_answer3,
      },
    ],
    answer: 'a0052ca7-0ddf-490c-8101-d2096ff70359',
    answers: [],
    answer_description: 'That’s exactly what I need! Thank you.',
    type: QuestionType.CHAT,
    chapter_id: '4920ded8-1bc5-4949-90ef-a15f202234ee',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: 'e0871e03-675d-484b-adcb-8f3911cd8bff',
    question:
      'One of our tenants broke the terms of their lease. We need to send an official, formal complaint. It’s a serious matter. Can you take care of it?',
    question_img: chap9_chat_question1,
    options: [
      {
        id: 'd94612c3-d1e3-435c-89d5-062f0b38e6cf',
        option: ' Sure, I’ll write a report that we can send over.',
        description: 'A report is based on research. It doesn’t apply to this situation at all.',
        img: chap9_chat_answer1,
      },
      {
        id: '481941a7-0e6f-423d-a246-ca79717316df',
        option: 'Of course, I’ll write them an email now.',
        description:
          'I’m afraid an email won’t accurately depict the severity of this situation. Besides, we can’t risk it getting lost in the tenant’s inbox.',
        img: chap9_chat_answer3,
      },
      {
        id: '1a453a46-1f8d-469d-a245-3c88943a1dcd',
        option: 'Okay! I’ll write a letter that we can send out later today.',
        description:
          'Fantastic! Let’s get that letter sent via certified mail for proof and documentation.',
        img: chap9_chat_answer2,
      },
    ],
    answer: '1a453a46-1f8d-469d-a245-3c88943a1dcd',
    answers: [],
    answer_description:
      'Fantastic! Let’s get that letter sent via certified mail for proof and documentation.',
    type: QuestionType.CHAT,
    chapter_id: '4920ded8-1bc5-4949-90ef-a15f202234ee',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '227c2ab3-80e3-48b7-b8c0-4e5908d05c8d',
    question:
      'I completely forgot what time today’s conference call is! Could you reach out to Parker and ask?',
    question_img: chap9_chat_question1,
    options: [
      {
        id: '02809be9-5707-41d5-a481-d57d9dbf24d3',
        option: ' Sure thing. I’ll send him an instant message now.',
        description: 'Awesome. We should get a speedy reply over instant message.',
        img: chap9_chat_answer2,
      },
      {
        id: 'c3a56d0f-a1ab-4c86-ae68-f17df2759fda',
        option: 'Okay, I’ll write him an email now.',
        description: 'Parker’s not great with email. I don’t know if we’ll get an answer in time.',
        img: chap9_chat_answer1,
      },
      {
        id: '1b13ffdf-d1aa-438b-9c07-c9e5a6a9dc04',
        option: 'Sure, I’ll write out a quick letter now.',
        description: 'A letter? The call will be over by the time Parker reads that.',
        img: chap9_chat_answer3,
      },
    ],
    answer: '02809be9-5707-41d5-a481-d57d9dbf24d3',
    answers: [],
    answer_description: 'Awesome. We should get a speedy reply over instant message.',
    type: QuestionType.CHAT,
    chapter_id: '4920ded8-1bc5-4949-90ef-a15f202234ee',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
  {
    id: '32b0a2e4-6830-44c9-8915-0b159a7bdd1b',
    question:
      'There’s one last thing I need. Could you please reach out to Frankie about that new website design we had in mind? Please share our color scheme ideas and design inspiration.',
    question_img: chap9_chat_question1,
    options: [
      {
        id: '43d12942-efb1-4b98-ba70-52e2a7b085b0',
        option: ' Sure! I’ll send him an email with all the details.',
        description: 'That’s perfect. He’ll have everything he needs to get started.',
        img: chap9_chat_answer2,
      },
      {
        id: '900420f7-3ac7-43c6-9f31-b9d1b7644359',
        option: 'Okay! I’ll write him an instant message now.',
        description:
          'Frankie will need more direction than that. An instant message is too brief. ',
        img: chap9_chat_answer1,
      },
      {
        id: '8f387abf-864d-4216-aec9-52c5f00e62b5',
        option: 'I’ll mock up a report that we can send out later.',
        description:
          'We’re just sharing a few creative ideas. There’s no need to send a lengthy, factual report.',
        img: chap9_chat_answer3,
      },
    ],
    answer: '43d12942-efb1-4b98-ba70-52e2a7b085b0',
    answers: [],
    answer_description: 'That’s perfect. He’ll have everything he needs to get started.',
    type: QuestionType.CHAT,
    chapter_id: '4920ded8-1bc5-4949-90ef-a15f202234ee',
    course_id: '92d6f6cf-d6cd-4f3b-9515-c27b47d860ff',
  },
];
export const quizQuestions: JumpStartQuizQuestion[] = [
  {
    id: '81a0a6a7-1ab3-442e-8feb-29a69e6b3ef3',
    question:
      'Which of the following situations are good reasons to ask a closed-ended question? Select all answers that apply.',
    options: [
      {
        id: 'c0d9fcc0-0b45-4dc4-bfa1-e446198fbd64',
        option: 'I want an in-depth answer.',
      },
      {
        id: 'fe274a76-af9a-425e-bf4d-101058d2279e',
        option: 'I want to generate a spontaneous discussion.',
      },
      {
        id: 'a4f92792-2b07-4ea4-9c5b-b728ea8e07ff',
        option: 'I want to end a conversation.',
      },
      {
        id: '7bc1ec98-c88a-4d0b-8ec5-bf03342e426d',
        option: 'I want a concise reply.',
      },
    ],
    answers: ['a4f92792-2b07-4ea4-9c5b-b728ea8e07ff', '7bc1ec98-c88a-4d0b-8ec5-bf03342e426d'],
    answer_description:
      'Not quite. A closed-ended question is answered with a short phrase or single word. It’s best for getting a straightforward (not in-depth) response and generating a controlled and focused discussion (not spontaneous). If you want to end a conversation or get a concise reply, ask a closed-ended question.',
    type: QuestionType.CHECKBOX,
  },
  {
    id: '812cdfc3-5c37-4cba-bf9a-50ab12291b41',
    question:
      'What’s the purpose of a company training manual? Is it to inform, persuade, or convey goodwill?',
    options: [
      {
        id: '6b466688-5fa7-4edf-8f5a-5e0ebd36dce7',
        option: 'To inform',
      },
      {
        id: '6fbd15ac-bc5b-48a1-8e5b-6ec3d00a1945',
        option: 'To persuade',
      },
      {
        id: '7f6248e6-91f8-4608-9c50-68c6017643c1',
        option: 'To convey goodwill',
      },
    ],
    answers: ['6b466688-5fa7-4edf-8f5a-5e0ebd36dce7'],
    answer_description:
      'That’s it. A training manual is a perfect example of an informative message. Its purpose is to explain information and pass along details.',
    wrong_answer_description:
      'While a training manual might contain a goodwill message, its main purpose to inform and pass along information. Remember, a goodwill message is for building relationships with your readers by using generous, kind words.',
    type: QuestionType.RADIO,
  },
  {
    id: 'b6eff79d-703f-4468-845a-8808e7fed2a8',
    question:
      '<p>Heather is a physician at a large urban hospital. Wednesdays at 4 PM she attends a one-hour clinic administration meeting. If Heather leaves by 5 PM she arrives home in time to allow the nanny to get to her own children’s after-school program on time. At 5 PM, Heather stands up to leave and one of the clinic administrators asks if she can stay a few more minutes until they are done. Heather dreads saying she has to leave to relieve the nanny, because she knows her colleagues may judge her as having a poor work ethic.</p><p><br/></p><p>Heather replies to the administrator:  “Excuse me, I have another commitment. It’s just something I committed to long before this meeting was scheduled. I’ll swing by tomorrow to get caught up.” <br/></p><p>Why does this statement work? Select all that apply</p>',
    options: [
      {
        id: '66e7d2ca-6706-4f9e-af6b-ef84a5fc374c',
        option: 'serves as an implicit, respectable request for confidentiality.',
      },
      {
        id: '150672f1-6551-4a49-895c-a321fdc4432c',
        option:
          'establishes an information boundary that puts anyone who crosses it at risk of appearing intrusive.',
      },
      {
        id: 'c6cb3228-d3a2-43b4-ae54-95ad33f2232a',
        option: 'eliminates oversharing about the reason for your departure.',
      },
    ],
    answers: [
      '66e7d2ca-6706-4f9e-af6b-ef84a5fc374c',
      '150672f1-6551-4a49-895c-a321fdc4432c',
      'c6cb3228-d3a2-43b4-ae54-95ad33f2232a',
    ],
    answer_description: `<p>This statement will minimize your risk of backlash because it:</p>
    <ul><li>serves as an implicit, respectable request for confidentiality</li><li>establishes an information boundary that puts anyone who crosses it at risk of appearing intrusive</li><li>eliminates oversharing about the reason for your departure</li></ul>`,
    type: QuestionType.CHECKBOX,
  },
  {
    id: 'dda44758-f094-4db3-b9ba-d25c3abf18ec',
    question:
      'It’s your first day at a new job, and you’re hoping to kick off a few great conversations. Which of the following statements might help you break the ice with your new coworkers? Select all correct options.',
    options: [
      {
        id: '4bd294fa-a24d-4e24-ad62-3686e177290f',
        option: '“At my last company, I was the top salesperson for five years running.”',
      },
      {
        id: 'f2c5c80f-3254-460c-a72b-10b7bc20cb2b',
        option: '“What’s your favorite lunch spot in this area?”',
      },
      {
        id: '02f6d6e9-f9cb-4690-9f44-4980b4ce4708',
        option: '“I hear we’re from the same hometown. What brought you to this city?”',
      },
      {
        id: '6fc31abc-940a-4ff4-9086-b256445a4b98',
        option: '“You’re in such good shape! What’s your secret?”',
      },
      {
        id: '4686e3f9-a1c5-4e55-a78c-773f52a2e2a9',
        option: '“I loved the email promotion you just sent out! Where’d you get the idea?”',
      },
    ],
    answers: [
      'f2c5c80f-3254-460c-a72b-10b7bc20cb2b',
      '02f6d6e9-f9cb-4690-9f44-4980b4ce4708',
      '4686e3f9-a1c5-4e55-a78c-773f52a2e2a9',
    ],
    wrong_answer_description:
      'Not exactly. When kicking off a great conversation, your goal is to engage, rather than impress. So, instead of putting the spotlight on yourself, focus on the other person. Consider asking them for advice, finding things you have in common, and offering a sincere compliment. Just make sure that you don’t compliment someone on their physical appearance—as that can be viewed as unprofessional. ',
    answer_description:
      'That’s it! When kicking off a great conversation, your goal is to engage, rather than impress. So, instead of putting the spotlight on yourself, focus on the other person. Consider asking them for advice, finding things you have in common, and offering a sincere compliment. Just make sure that you don’t compliment someone on their physical appearance—as that can be viewed as unprofessional. ',
    type: QuestionType.CHECKBOX,
  },
  {
    id: 'ddfe9a46-d271-4019-9b87-b8308b595a64',
    question: `<p>Katie is the COO of a hospitality company. She has a keen strategic mind. In a contentious moment, she recommends that the C-suite move toward a new talent strategy. The idea is met with resistance. Then Dave, the head of IT, restates her idea in his own words. The rest of the C-suite supports him in “his” idea.</p>
    <p><br/></p><p>Katie replies in the meeting: “Thanks for spotlighting my point.”</p> <p><br/></p>
    <p>When spoken with composure this statement works because it __________.</p>`,
    options: [
      {
        id: 'a5933771-d2f5-4f9d-b139-30940b6eabf5',
        option:
          'prevents Katie from being trivialized by serving notice about the misappropriation of your contribution',
      },
      {
        id: '931ab081-6105-4f4b-a402-718299df9ba1',
        option: 'allows Katie to reclaim her idea without aspersion',
      },
      {
        id: 'a2786887-5761-4e78-acf3-1e75a3bd7c85',
        option: 'gives Katie the upper hand when addressing the matter with a manager',
      },
      {
        id: '4eabc787-a385-4f15-9655-3f1eadba16d5',
        option:
          'provides an opportunity for greater ownership, if delivered in front of others, by offering detail or clarification for impact',
      },
      {
        id: 'b7b8181e-d380-4753-ba3e-a801aae55bf4',
        option: 'All of the above',
      },
    ],
    answers: ['b7b8181e-d380-4753-ba3e-a801aae55bf4'],
    answer_description: 'This statement works for all of the above.',
    type: QuestionType.RADIO,
  },
  {
    id: '6b137b43-8150-4580-a52b-5243949e1300',
    question:
      'An employee is continuing to work remotely when company policy dictates a 50%-only remote work. What should you say?',
    options: [
      {
        id: '097b43b3-b2cb-4731-aa9b-d2016319e837',
        option:
          'Our company policy dictates a 50%-only remote work. You’ve been working remotely for longer, so please stop doing this. Next time you want to work remotely, let your manager know.',
      },
      {
        id: 'e7294ceb-1f5b-4b1c-9187-d2eb4267784e',
        option:
          'I understand that with previous COVID uncertainty, our remote work policy wasn’t clear. Now that we understand the trajectory, we feel it’s important for the company culture that we see each other at least 50% of the working week in person. This, of course, should be done in a healthy and safe way to ensure you are comfortable working alongside others. If there is something that’s making you feel uncomfortable, please let us know. Otherwise, would you be willing to let us know in the future what your working abroad schedule looks like? Maybe we can find a way to manage everyone’s time better. ',
      },
      {
        id: '1da7bd3d-ccf8-4ce5-98b1-5f14d0697b08',
        option:
          'You are not suppose to be working remotely for more than 50% of the time. If I catch you doing this again, I will report you and disciplinary again will be taken.',
      },
    ],
    wrong_answer_description:
      'This response may lead to results, but likely the person will not trust you. The person may also begin to feel that they are not trusted in completing their work which can lead to feelings of discontent and resignation.',
    answers: ['097b43b3-b2cb-4731-aa9b-d2016319e837'],
    answer_description: '',
    type: QuestionType.RADIO,
  },
  {
    id: '17034319-9781-49d3-bfa6-bac4cf653af0',
    question:
      'What’s the best way to edit the following sentence: I think it’d be best for us to meet on Thursday sometime in the afternoon.',
    options: [
      {
        id: '58fb1366-fc08-462c-b324-8906b06a23a4',
        option: 'I think it’d be best for us to meet on Thursday afternoon.',
      },
      {
        id: '03da4b59-6aee-4bb8-ba24-301335570a66',
        option: 'Let’s meet Thursday afternoon.',
      },
      {
        id: 'afacabff-c31b-46b8-ad1e-85652a8e95b7',
        option: 'I vote we meet on Thursday sometime in the afternoon.',
      },
      {
        id: '6f38ac25-f1ef-4847-b009-cc2d9820921d',
        option:
          'It’d work best for me if we could meet on Thursday. I’m available anytime in the afternoon.',
      },
    ],
    answers: ['03da4b59-6aee-4bb8-ba24-301335570a66'],
    wrong_answer_description:
      'Not quite! Think about how you might improve your writing by limiting prepositions and eliminating needless words or phrases.',
    answer_description:
      'Correct! Make your writing clear and concise by limiting prepositions and eliminating needless words or phrases.',
    type: QuestionType.RADIO,
  },
  {
    id: '41080084-8f8e-4fe3-b0ed-3e60cc55a7be',
    question:
      'You are a manager of a team of cashiers and one of your employees, Susan is speaking to another employee (Dan) in a disrespectful way. What would be an appropriate thing to say to Susan?',
    options: [
      {
        id: '1da3867e-3d34-4a30-ab19-7b675f7eb732',
        option: 'Hi Susan, I don’t like the way you’re speaking to Dan in that tone. Please stop. ',
      },
      {
        id: '4afa95f9-5048-4b10-88a1-763fcc3e13a8',
        option: 'Hi Susan, I don’t like the way you’re speaking to Dan in that tone. Please stop.',
      },
      {
        id: '4c4fefad-cd45-4b43-a821-6169a5d29831',
        option:
          'Hi Susan, do you have a second to talk about what happened with Dan? I wanted to let you know that I appreciate how hard you’ve been working and it hasn’t gone unnoticed. I know you snapped at Dan because you really want the rest of the team to do well at their job. But maybe in the future, you could approach Dan with more empathy – he’s also under immense pressure to meet his cashiering quota, so that the whole team can meet their targets. How do you feel about apologizing to him so he feels heard?',
      },
      {
        id: '458a2ad6-af28-4ab0-925e-746f0d5739d5',
        option:
          'Susan, I just seen you treat Dan in a disrespectful way. This is not acceptable and I don’t ever want to see it happen again or there will be disciplinary action taken with you being suspended.',
      },
    ],
    answers: ['4c4fefad-cd45-4b43-a821-6169a5d29831'],
    wrong_answer_description:
      '<p>Your goal in this situation is to realign your employees’ behavior. But responding this way will likely to make Susan feel:</p><ul><li>Defensive</li><li>Unappreciated</li><li>Misunderstood</li></ul>',
    answer_description:
      'This type of response will make Susan feel valued. You can empathize with your her situation and show her how to empathize with Dan too. Plus, you’re asking her to apologize – an end resolution that is a request, and will prime a response on your Susan’s part.',
    type: QuestionType.RADIO,
  },
  {
    id: '5b86108b-f69b-4b58-a2ea-d7ea87e31dd9',
    question: `<p>You’re writing a project proposal for a potential client. It’s a new relationship, the client doesn’t know much about your work, and they’re skeptical about signing you on. </p>
    <p><br/></p><p>Based on that information, how will you write for this audience? </p>`,
    options: [
      {
        id: '3298a424-2f08-43a3-a0e6-d28acf4de3ac',
        option:
          'I’ll establish formality with a professional tone. Then, I’ll use simple language and address the client’s skepticism.  ',
      },
      {
        id: 'c55bb645-cdfc-4956-8c72-33719eb2f94d',
        option:
          'I’ll gain this client’s trust by using simple language and a friendly, informal tone. To maintain positivity, I’ll also avoid focusing on the client’s skepticism.',
      },
      {
        id: '0f9fc528-f070-44a1-ad74-b2a3bd694b15',
        option:
          'I’ll use a professional tone and highlight my expertise by using technical vocabulary. I’ll also avoid addressing the client’s skepticism.',
      },
    ],
    answers: ['3298a424-2f08-43a3-a0e6-d28acf4de3ac'],
    answer_description:
      'Adjust your writing depending on your audience’s perspective, knowledge, and relationship with you. In this case, because it’s a new relationship, use a formal tone. Also, because the client is new to the material, you should adopt simple language. Finally, take your audience’s perspective into account by addressing their skepticism.',
    type: QuestionType.RADIO,
  },
];
