import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { AuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { Communication } from '../components/Skills/Communication';
import { CoreCompetency } from '../components/Skills/CoreCompetency';
import { DigitalTechnology } from '../components/Skills/DigitalTechnology';
import { Intercultural } from '../components/Skills/Intercultural';
import { Motivation } from '../components/Skills/Motivation';
import { Planning } from '../components/Skills/Planning';
import { ProblemSolving } from '../components/Skills/ProblemSolving';
import { Reliability } from '../components/Skills/Reliability';
import { SkillsHeader } from '../components/Skills/SkillsHeader';
import { StressTolerance } from '../components/Skills/StressTolerance';
import { TeamWork } from '../components/Skills/TeamWork';
import { WorkEthic } from '../components/Skills/WorkEthic';
import { useIntakeFormContext, useSkillsContext } from '../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../hooks/use-pre-employment-assessment.hooks';
// import { useSWOTContext } from '../hooks/use-swot-context.hooks';
// import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
// import { CustomUser } from '../redux/slice/auth/CustomUser';
import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { CustomUser } from '../redux/slice/auth/CustomUser';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import { dispatchGetSkillsValues } from '../redux/slice/form-skills';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../redux/slice/pre-employment-assessment';
import { dispatchGetSWOTReportValues } from '../redux/slice/swot/swot.operations';
// import { SWOTReport } from '../redux/slice/swot/swot.types';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';

export const Skills: React.FC = () => {
  const user: CustomUser | null = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userProfileContext = useUserProfileContext();
  const { componentName } = useParams();
  const [skillProgress, setSkillProgress] = useState(0);
  const skillContext = useSkillsContext();
  const { organization, email } = useIntakeFormContext();

  useEffect(() => {
    const answerSubmited = Object.values(skillContext).length;
    if (answerSubmited) {
      setSkillProgress(answerSubmited * 10);
    }
  }, [skillContext]);

  const {
    status: preEmploymentAssessmentStatus,
    progress,
    didAsaUserViewedSwot,
    activeStepUrl,
  } = usePreEmploymentAssessmentContext();

  useEffect(() => {
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchGetSkillsValues());
    void dispatch(dispatchUpsertPreEmploymentAssessment());
    void dispatch(dispatchGetSWOTReportValues());
    void dispatch(dispatchGetUserProfileValues());
  }, []);

  const [step, setStep] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBackButton = () => {
    void dispatch(
      dispatchUpsertPreEmploymentAssessment({
        activeStepUrl: `/pre-employment/skills/step${step - 1}`,
      }),
    );
    navigate(`/pre-employment/skills/step${step - 1}`);
  };

  useEffect(() => {
    setStep(parseInt(componentName?.replace('step', '') || '1'));
    window.scrollTo(0, 0);
  }, [componentName]);

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.NOT_STARTED) {
      void dispatch(
        dispatchUpsertPreEmploymentAssessment({
          status: PreEmploymentAssessmentStatus.ACTIVE,
        }),
      );
    }
  }, [preEmploymentAssessmentStatus]);

  useEffect(() => {
    if (
      Object.keys(userProfileContext).length &&
      user?.claims.isAsaUser &&
      userProfileContext.email &&
      !userProfileContext?.asaUserId
    ) {
      navigate('/pre-employment/start');
    } else if (
      Object.keys(userProfileContext).length &&
      (userProfileContext?.isPremium || organization) &&
      userProfileContext?.email &&
      email &&
      preEmploymentAssessmentStatus
    ) {
      activeStepUrl && activeStepUrl.includes('skills') && navigate(activeStepUrl);
    }
  }, [userProfileContext, preEmploymentAssessmentStatus, activeStepUrl]);

  const renderFormStep = () => {
    switch (componentName) {
      case 'step1':
        return <CoreCompetency step={step} />;
      case 'step2':
        return <Communication step={step} onBack={handleBackButton} />;
      case 'step3':
        return <TeamWork step={step} onBack={handleBackButton} />;
      case 'step4':
        return <Motivation step={step} onBack={handleBackButton} />;
      case 'step5':
        return <Planning step={step} onBack={handleBackButton} />;
      case 'step6':
        return <ProblemSolving step={step} onBack={handleBackButton} />;
      case 'step7':
        return <WorkEthic step={step} onBack={handleBackButton} />;
      case 'step8':
        return <Intercultural step={step} onBack={handleBackButton} />;
      case 'step9':
        return <DigitalTechnology step={step} onBack={handleBackButton} />;
      case 'step10':
        return <Reliability step={step} onBack={handleBackButton} />;
      case 'step11':
        return <StressTolerance step={step} onBack={handleBackButton} />;
      default:
        navigate('/pre-employment/skills/step1');
        return null;
    }
  };

  return (
    <div className={`steps overflow-hidden relative skills-${step}`}>
      <Container>
        <>
          {step === 1 ? (
            <>{renderFormStep()}</>
          ) : (
            <SkillsHeader
              progress={
                !didAsaUserViewedSwot && !organization && step - 1 >= 1
                  ? skillProgress > 0
                    ? skillProgress
                    : (step - 2) * 10
                  : progress || 0
              }
            >
              <>
                {!didAsaUserViewedSwot && !userProfileContext.isPremium && !organization ? (
                  <h3 className="text-right text-xs italic mt-3">Progress : {step - 1} / 10</h3>
                ) : null}
                {renderFormStep()}
              </>
            </SkillsHeader>
          )}
        </>
      </Container>
    </div>
  );
};

export const SkillsPage: React.FC = () => {
  return <AuthenticatedPage render={() => <Skills />} />;
};
