// import insights from '../../../../assets/images/courses/perception-understanding/chapter1/insights.jpg';
import introImg from '../../../../assets/images/courses/perception-understanding/chapter1/introImg.jpg';
import perciption_chap1_img1 from '../../../../assets/images/courses/perception-understanding/chapter1/perciption_chap1_img1.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { MediaBlock } from '../../MediaBlock';
interface PerciptionProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const PerceptionAndUnderstandingChapter1: React.FC<PerciptionProps> = ({
  chapter,
  onNextChapter,
}) => {
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-xl font-light">
        <h2 className="font-semibold text-3xl">Overview</h2>
        <p className="text-sm italic">Part 1 of 10</p>
        <MediaBlock mediaImage={introImg}></MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Introduction to Insights Discovery
        </h3>
        <div>
          <h4 className="text-xl font-bold mt-7 mb-5">
            Successful relationships are the key to success at work and in life. When working
            alongside others, you don't always appear to others the way you think you do - or wish
            you could. Even in most effective relationships, there are often things that go unsaid
            for fear of offending others or saying the wrong thing.
          </h4>
        </div>
        <div className="flex items-center">
          <img className="w-96 mr-9" src={perciption_chap1_img1} alt="" />
          <div>
            <p>
              How people work with those around them directly impacts productivity and work
              satisfaction, so it's critical that everyone takes the time to build good
              relationships.
              <br />
              <br />
              This can be done by increasing self-awareness and understanding the fundamental
              concepts of perception and preferences. Click the button below to learn about the
              learning objectives of this module.
            </p>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">Learning Objectives</h3>
        <p>Upon completion of this lesson, you will be able to:</p>
        <ul className="list-disc pl-5 mt-8">
          <li className="mb-7">Understand the power of perception and the Ladder of Inference</li>
          <li className="mb-7">
            Apply the 'Running up the Ladder' metaphor to understand the implications of negative
            perceptions
          </li>
          <li className="mb-7">Identify and explain the Jungian preferences</li>
          <li className="mb-7">
            Understand the influence our unique preferences has on our world-view
          </li>
          <li className="mb-7">Distinguish between the four Insights Color Energies</li>
          <li className="mb-7">
            Identify your, and others', color mix and understand the impact of your personal style{' '}
          </li>
          <li className="mb-7">
            Adapt your style to get the best out of a situation by understanding personal
            preferences
          </li>
        </ul>
        <div className="text-4xl mt-5">Let's start with the power of perception</div>
        <button onClick={handleNextChapterClick} className="w-full btn-primary uppercase mt-8">
          POWER OF PERCEPTION
        </button>
      </div>
    </>
  );
};
