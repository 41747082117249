import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import { CustomUser } from './CustomUser';

export interface AuthState {
  initialized: boolean;
  user: CustomUser | null;
}
const initialState: AuthState = {
  initialized: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthInitialized: (state, action: PayloadAction<void>) => {
      logger.debug('Updating auth state to initialized.', action);
      state.initialized = true;
    },
    setOTPAuth: (state) => {
      logger.debug('Updating auth state to initialized.', state);
      state.initialized = true;
    },
    login: (state, action: PayloadAction<CustomUser>) => {
      logger.debug('Login action.', { state, action });
      state.user = { ...action.payload };
      state.initialized = true;
    },
    logout: (state) => {
      logger.debug('Logout action.', { state });
      state.user = null;
      state.initialized = true;
    },
  },
});
export const {
  setAuthInitialized: setAuthInitializedAction,
  login: loginAction,
  logout: logoutAction,
  setOTPAuth: setOTPAuthAction,
} = authSlice.actions;

export default authSlice.reducer;
