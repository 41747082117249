import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CareerReadinessAnswer } from '../../../assessments/career-readiness/career-readiness.types';
import logger from '../../../services/logger';

export type CareerReadinessState = {
  [answerId: string]: CareerReadinessAnswer;
};

const initialState: CareerReadinessState = {};

const careerReadinessSlice = createSlice({
  name: 'formCareerReadiness',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<CareerReadinessState>) => {
      logger.debug('Updating career readiness state.', { state, action });
      Object.assign(state, { ...action.payload });
    },
  },
});

export const { update: updateCareerReadinessAction } = careerReadinessSlice.actions;
export default careerReadinessSlice.reducer;
