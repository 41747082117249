// FlipCard.tsx

import React, { useState } from 'react';

import flipIcon from '../../assets/icons/flipIcon.png';

interface FlipCardProps {
  children: React.ReactNode;
}

const FlipCard: React.FC<FlipCardProps> = ({ children }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`flip-card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardClick}>
      <div className="flip-card-inner">
        {React.Children.map(children, (child, index) => (
          <div key={index} className={`flip-card-${index % 2 === 0 ? 'front' : 'back'} p-5`}>
            {child}
            <div className="absolute bottom-4 right-4">
              <img className="w-4 h-4" src={flipIcon} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FlipCard;
