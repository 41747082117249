/**
 * the types of questions in the experience assessment
 */
export enum ExperienceAssessmentQuestionType {
  PROFICIENCY_AND_INTEREST = 'proficiency_and_interest',
  EXPERIENCE = 'experience',
}

/**
 * a question in which the user specifies two answers
 *   1. Whether they're proficient (yes / no)
 *   2. Whether it interests them (yes / no)
 */
export type ProficiencyAndInterestQuestion = {
  /**
   * a unique id for the question
   */
  id: string;

  /**
   * the type of question
   */
  type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST;

  /**
   * the question displayed to users
   */
  question: string;
};

/**
 * an answer to a proficiency and interest question
 */
export type ProficiencyAndInterestAnswer = {
  /**
   * the question that was asked
   */
  question: string;

  /**
   * whether or not the user is proficient
   */
  isProficient: boolean;

  /**
   * whether or not the user is interested in the type of work
   */
  isInterested: boolean;
};

/**
 * a question in which the user specifies two answers
 *   1. Whether or not they're experienced (yes / no)
 *   2. Additional comments (free-form text, optional)
 */
export type ExperienceQuestion = {
  /**
   * a unique id for the question
   */
  id: string;

  /**
   * the type of question
   */
  type: ExperienceAssessmentQuestionType.EXPERIENCE;

  /**
   * the question displayed to users
   */
  question: string;
};

/**
 * an answer to an experience question
 */
export type ExperienceAnswer = {
  /**
   * the question that was asked
   */
  question: string;

  /**
   * whether or not the user has experience
   */
  hasExperience: boolean;

  /**
   * comments the user has on their experience
   */
  comments: string;
};

/**
 * a question in the experience assessment
 */
export type ExperienceAssessmentQuestion = ProficiencyAndInterestQuestion | ExperienceQuestion;

/**
 * an answer to a question in the assessment
 */
export type ExperienceAssessmentAnswer = ProficiencyAndInterestAnswer | ExperienceAnswer;
