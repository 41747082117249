import { Replay } from '@mui/icons-material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  CourseSortingQuestion,
  QuestionOption,
  ShortingMatchingBase,
} from '../../../courses/jumpstart-communicator/jumpstart-communicator.types';

import AnswerCard from './AnswerCard';
import QuestionCard from './QuestionCard';

type AnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
interface QuestionCardProps {
  question: CourseSortingQuestion;
  submitSortingQuestion?: (answers: AnswersState) => void;
  onRetake?: (questionId: string) => void;
  submitedCorrectAnswerCount?: number | null;
  enableRetake?: boolean;
}

export const CourseSortingQuestionBlock: React.FC<QuestionCardProps> = ({
  question,
  submitSortingQuestion,
  onRetake,
  submitedCorrectAnswerCount,
  enableRetake,
}) => {
  const [incorrectAnswers, setIncorrectAnswers] = useState<AnswersState>({});
  const [, setAnswers] = useState<AnswersState>({});
  const [questionIndex, setQuestionIndex] = useState(0);
  const [, setIsAnswerCorrect] = useState<{
    isCorrect: boolean;
    answerOption: QuestionOption;
  } | null>(null);

  const questions = question?.questions;
  const correctAnswers = question?.options;
  const currentQuestion = questions[questionIndex];

  const handleDragEnd = () => {
    setIsAnswerCorrect((prevState) => {
      if (prevState) {
        setAnswers((prevAnswers) => {
          if (Object.keys(prevAnswers).length) {
            const prevQuestions = prevAnswers[question.id];
            if (!prevQuestions.filter((x) => x.question.id === currentQuestion.id).length) {
              prevQuestions.push({
                question: currentQuestion,
                correct_answer: question.answers[currentQuestion.id],
                answer: prevState.answerOption,
              });
            }

            return {
              [question.id]: [...prevQuestions],
            };
          } else {
            return {
              [question.id]: [
                {
                  question: currentQuestion,
                  correct_answer: question.answers[currentQuestion.id],
                  answer: prevState.answerOption,
                },
              ],
            };
          }
        });

        setAnswers((updatedState: AnswersState) => {
          return updatedState; // Return the updated state to make sure it's correctly set
        });
        if (prevState.isCorrect) {
          setQuestionIndex((prevIndex) => prevIndex + 1);
        } else {
          setIncorrectAnswers((prevAnswers) => {
            if (Object.keys(prevAnswers).length) {
              const prevQuestions = prevAnswers[question.id];
              if (!prevQuestions.filter((x) => x.question.id === currentQuestion.id).length) {
                prevQuestions.push({
                  question: currentQuestion,
                  correct_answer: question.answers[currentQuestion.id],
                  answer: prevState.answerOption,
                });
              }
              return {
                [question.id]: [...prevQuestions],
              };
            } else {
              return {
                [question.id]: [
                  {
                    question: currentQuestion,
                    correct_answer: question.answers[currentQuestion.id],
                    answer: prevState.answerOption,
                  },
                ],
              };
            }
          });
        }
      }

      return prevState; // Return the updated state to make sure it's correctly set
    });
    setIsAnswerCorrect(null); // Reset the correctness status for the next question
  };
  const handleReplaySorting = () => {
    setIncorrectAnswers({});
    setAnswers({});
    setQuestionIndex(0);
    setIsAnswerCorrect(null);
    onRetake && onRetake(question.id);
  };
  useEffect(() => {
    if (questionIndex === questions.length && submitSortingQuestion) {
      setAnswers((prevAnswers) => {
        submitSortingQuestion({ ...prevAnswers });
        return {
          ...prevAnswers,
        };
      });
    }
  }, [questionIndex]);
  return (
    <>
      {questionIndex === questions.length || !_.isNil(submitedCorrectAnswerCount) ? (
        <div className="max-w-sm h-48 text-center p-8 mx-auto mt-10 mb-20">
          <h3 className="text-3xl">
            {!_.isNil(submitedCorrectAnswerCount)
              ? `${submitedCorrectAnswerCount}/${questions.length} cards correct`
              : `${
                  questions.length -
                  (Object.keys(incorrectAnswers).length ? incorrectAnswers[question.id].length : 0)
                }/${questions.length} cards correct`}
          </h3>
          {enableRetake !== false && (
            <div className="mt-16 pt-5 border-1 border-t border-gray-700 inline-block">
              <button onClick={handleReplaySorting} className="btn-secondary min-w-[140px]">
                REPLAY
                <br />
                <Replay />
              </button>
            </div>
          )}
        </div>
      ) : (
        currentQuestion && <QuestionCard question={currentQuestion} onDrag={handleDragEnd} />
      )}
      <div
        className={`grid grid-cols-2 lg:grid-cols-${correctAnswers.length} gap-4 lg:gap-5 mt-10 lg:mt-20`}
      >
        {correctAnswers.map((answer, index) => (
          <AnswerCard
            key={index}
            correctAnswer={question.answers[currentQuestion?.id]}
            answerOption={answer}
            onDrop={(isCorrect, answerOption) => {
              setIsAnswerCorrect({
                isCorrect,
                answerOption,
              });
            }}
          />
        ))}
      </div>
    </>
  );
};
