import React, { ReactNode } from 'react';

interface ProfileCardProps {
  children: ReactNode;
  heading: string;
  buttonText: string;
  buttonIcon: React.ReactNode; // You can also specify a more specific type for the icon if needed
  isVisible: boolean;
  disabled?: boolean;
}

export const ProfileCard: React.FC<ProfileCardProps> = ({
  children,
  heading,
  buttonText,
  buttonIcon,
  isVisible,
  disabled,
}) => {
  return (
    <>
      <div
        className={`${disabled ? 'item-disabled' : ''} bg-white rounded-3xl md:px-8 md:py-6 p-4`}
      >
        <div className="flex justify-between mb-7">
          <h2 className="uppercase text-gray-400 tracking-widest">{heading}</h2>
          {isVisible && (
            <button className="text-blue-500">
              {buttonIcon}
              <span className="ml-2">{buttonText}</span>
            </button>
          )}
        </div>
        <div className="profile-card-body chart-ui">{children}</div>
      </div>
    </>
  );
};
