import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import {
  getUserCourseQuestionValues,
  getUserCourseSortingQuestionValues,
  updateUserCourseQuestion,
  updateUserCourseQuizQuestion,
} from '../../../services/database/course-question-response';

import {
  updateUserCourseQuestionAction,
  UserCourseQuestionState,
} from './user-course-question-response.slice';
import { UserQuizQuestionState } from './user-course-question-response.types';
import { updateUserCourseSortingQuestion } from './user-course-quiz-question-response.slice';

/**
 * retrieve previously saved values from the career readiness assessment
 */
export const dispatchGetUserCourseQuestionResponse =
  () =>
  async (dispatch: Dispatch<PayloadAction<UserCourseQuestionState>>): Promise<void> => {
    const values: UserCourseQuestionState = await getUserCourseQuestionValues();
    if (Object.keys(values).length) {
      dispatch(updateUserCourseQuestionAction(values));
    }
  };
export const dispatchGetUserCourseSortingQuestionResponse =
  () =>
  async (dispatch: Dispatch<PayloadAction<UserQuizQuestionState>>): Promise<void> => {
    const values: UserQuizQuestionState = await getUserCourseSortingQuestionValues();
    if (Object.keys(values).length) {
      dispatch(updateUserCourseSortingQuestion(values));
    }
  };

/**
 * update progress in the course
 */
export const dispatchUpdateUserCourseQuestionResponse =
  (dto: UserCourseQuestionState) =>
  async (dispatch: Dispatch<PayloadAction<UserCourseQuestionState>>): Promise<void> => {
    await updateUserCourseQuestion(dto);
    dispatch(updateUserCourseQuestionAction(dto));
  };

export const dispatchUpdateUserCourseQuizQuestionResponse =
  (dto: UserQuizQuestionState) =>
  async (dispatch: Dispatch<PayloadAction<UserQuizQuestionState>>): Promise<void> => {
    await updateUserCourseQuizQuestion(dto);
    dispatch(updateUserCourseSortingQuestion(dto));
  };
