import { KeyboardArrowDown, Menu } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import logo from '../../assets/images/landing/logo.svg';
import avatar from '../../assets/images/Muser.jpg';
import { useIntakeFormContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import { useUserProfileContext } from '../../hooks/user-user-profile-context.hooks';
import { dispatchLogout } from '../../redux/slice/auth/auth.operations';
import { PreEmploymentAssessmentState } from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import Container from '../container/Container';
interface NavBarProps {
  name: string;
}
export const DashboardNavbar: React.FC<NavBarProps> = ({ name }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const userProfileContext = useUserProfileContext();
  const { organization, email: userEmail } = useIntakeFormContext();
  const [preEmpolymentUrl, setPreEmpolymentUrl] = useState('');
  const preEmploymentAssessment: PreEmploymentAssessmentState = usePreEmploymentAssessmentContext();

  useEffect(() => {
    if (Object.keys(preEmploymentAssessment).length) {
      if (userProfileContext?.isPremium || organization) {
        if (organization && preEmploymentAssessment.status !== 'completed') {
          preEmploymentAssessment.activeStepUrl && navigate(preEmploymentAssessment.activeStepUrl);
        } else if (organization && !preEmploymentAssessment.didCompleteSkills) {
          preEmploymentAssessment.activeStepUrl &&
          preEmploymentAssessment.activeStepUrl.includes('skills')
            ? navigate(preEmploymentAssessment.activeStepUrl)
            : navigate('/pre-employment/start');
        } else if (!preEmploymentAssessment.didCompleteIntake) {
          setPreEmpolymentUrl('/pre-employment/intake/step1');
        } else if (
          preEmploymentAssessment.didCompleteIntake &&
          !preEmploymentAssessment.didCompleteEmploymentReadiness &&
          preEmploymentAssessment.requiresEmploymentReadiness
        ) {
          setPreEmpolymentUrl('/pre-employment/employment-readiness/step1');
        } else if (
          preEmploymentAssessment.didCompleteIntake &&
          !preEmploymentAssessment.didCompleteSkills
        ) {
          setPreEmpolymentUrl('/pre-employment/skills/step1');
        } else if (
          preEmploymentAssessment.didCompleteEmploymentReadiness &&
          !preEmploymentAssessment.didCompleteCareerReadiness &&
          preEmploymentAssessment.requiresCareerReadinessAssessment
        ) {
          setPreEmpolymentUrl('/pre-employment/career-readiness/step1');
        } else if (!preEmploymentAssessment.didCompleteExperience) {
          setPreEmpolymentUrl('/pre-employment/experience/step1');
        } else {
          setPreEmpolymentUrl('/pre-employment/intake/step1');
        }
      }
    }
  }, [preEmploymentAssessment, preEmpolymentUrl, userProfileContext, organization, userEmail]);

  const handleLogout = async () => {
    try {
      await dispatch(dispatchLogout());
      toast.success('Logged out!');
      navigate('/login');
      window.location.reload();
    } catch (e) {
      logger.error('Failed to logout.', e);
      toast.error('Logout failed.');
    }
  };

  const handleMenuToggle = () => {
    setToggleMenu((prevState) => !prevState);
  };

  const handleProfileToggle = () => {
    setProfileDropdown((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setProfileDropdown(false);
        if (event.target instanceof HTMLAnchorElement) {
          const href = event.target.href;
          const linkText = event.target.text;

          if (linkText.includes('Log Out')) {
            void handleLogout();
          } else if (href) {
            const url = new URL(href);
            const relativePath: string = url.pathname;
            setToggleMenu(false);
            navigate(relativePath);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className={`py-3 relative w-full z-10 shadow bg-white`}>
      <Container>
        <nav className="navbar flex justify-between">
          <div className="navbar-brand mr-4">
            {userProfileContext?.isPremium || organization ? (
              <Link className="cursor-pointer" to="/dashboard">
                <div>
                  <img src={logo} alt="" />
                </div>
              </Link>
            ) : (
              <div>
                <img src={logo} alt="" />
              </div>
            )}
          </div>
          <button onClick={handleMenuToggle} type="button" className="navbar-toggle hidden">
            <Menu sx={{ fontSize: '36px' }} />
          </button>
          <ul
            style={{ right: toggleMenu ? '0' : '100%' }}
            className={`navbar-nav ${toggleMenu ? 'relative' : ''} flex items-center gap-8 ml-auto`}
          >
            <div className="bg-purple-50">
              <div className="relative inline-block text-left lg:hidden">
                <div className="flex items-center gap-3 p-4" onClick={handleProfileToggle}>
                  <div className="w-20 h-20 rounded-full overflow-hidden">
                    <img
                      className="w-full h-full object-cover"
                      src={userProfileContext?.profileImagePath || avatar}
                      alt=""
                    />
                  </div>
                  <div className="relative">
                    <a className="font-bold capitalize">{name}</a>
                    <p className="text-sm text-blue-500 cursor-pointer">
                      View Profile
                      <KeyboardArrowDown />
                    </p>
                    {profileDropdown && (
                      <div
                        className="absolute left-0  z-10 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                        onClick={handleProfileToggle}
                        ref={menuRef}
                      >
                        <div className="py-1">
                          <ul>
                            <li>
                              <Link className="text-gray-700 block cursor-pointer" to="/dashboard">
                                Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="text-gray-700 block cursor-pointer"
                                to="/user-profile"
                              >
                                View Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="text-gray-700 block cursor-pointer"
                                to="/update-profile"
                              >
                                Update Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="text-gray-700 block cursor-pointer"
                                to="/update-password"
                              >
                                Change Password
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="text-purple-700 block font-semibold cursor-pointer"
                                to=""
                              >
                                Log Out
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ display: toggleMenu ? 'block' : 'none' }}
              className="absolute top-[30px] right-[16px]"
              onClick={handleMenuToggle}
            >
              <Close sx={{ fontSize: '36px' }} />
            </div>
            <li>
              <Link className="cursor-pointer" to={preEmpolymentUrl}>
                Pre-Employment Screening
              </Link>
            </li>
            <li>
              <Link className="cursor-pointer" to="/courses/eb7b1338-7fa1-4673-9b2f-4fe30c2a9069">
                Transferable Skill Modules
              </Link>
            </li>
            <li>
              <Link className="cursor-pointer" to="/swot">
                SWOT Report
              </Link>
            </li>
            <li className="hidden lg:block">
              <div className="relative inline-block text-left">
                <div className="flex items-center gap-3" onClick={handleProfileToggle}>
                  <div className="w-12 h-12 rounded-full overflow-hidden">
                    <img
                      className="w-full h-full object-cover"
                      src={userProfileContext?.profileImagePath || avatar}
                      alt=""
                    />
                  </div>
                  <div>
                    <p className="font-bold capitalize">{name}</p>
                    <a className="text-sm text-blue-500 cursor-pointer">
                      View Profile
                      <KeyboardArrowDown />
                    </a>
                  </div>
                </div>
                {profileDropdown && (
                  <div
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    onClick={handleProfileToggle}
                    ref={menuRef}
                  >
                    <div className="py-1">
                      <ul>
                        <li>
                          <Link
                            className="text-gray-700 block px-4 py-3 cursor-pointer"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="text-gray-700 block px-4 py-3 cursor-pointer"
                            to="/user-profile"
                          >
                            View Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="text-gray-700 block px-4 py-3 cursor-pointer"
                            to="/update-profile"
                          >
                            Update Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="text-gray-700 block px-4 py-3 cursor-pointer"
                            to="/update-password"
                          >
                            Change Password
                          </Link>
                        </li>
                        <li>
                          <button
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleLogout}
                            className="text-purple-700 block px-4 py-3 font-semibold"
                          >
                            Log Out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </nav>
      </Container>
    </header>
  );
};
