import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import star from '../../assets/images/intake/welcome/star-bg.svg';
import welcome_img from '../../assets/images/intake/welcome/welcome-img.svg';

interface WelcomeProps {
  step: number;
}

export const Welcome: React.FC<WelcomeProps> = ({ step }) => {
  const { handleSubmit } = useForm<FormData>();
  const navigate = useNavigate();
  const submitWelcome: SubmitHandler<FormData> = async () => {
    navigate(`/pre-employment/employment-readiness/step${step + 1}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll to the top
  }, []);

  return (
    <div className="text-center">
      <div className=" char-img inline-block relative mx-auto border-b-2 border-black">
        <img className=" absolute bottom-0 z-[-1]" src={star} alt="" />
        <img className="" src={welcome_img} alt="" />
      </div>
      <div className="text-center lg:w-10/12 mt-20 mx-auto">
        <h1 className="text-4xl">
          Welcome to
          <span className="font-semibold"> Vero learning</span>
        </h1>
        <h4 className="text-2xl text-gray-700 mb-6">Embark on a Journey of Self Discovery</h4>
        <div>
          <p className="text-purple-700 text-xl font-semibold mb-6">Hello there!</p>
          <p className="text-xl">
            We understand that navigating the world of employment can be both exciting and
            challenging for young adults like you. That's why we're here to help you embark on your
            journey towards self-discovery and career success.
          </p>
        </div>
        <div>
          <h3 className="text-2xl font-semibold mt-10 mb-1">Employment Readiness Assessment</h3>
          <p className="text-xl">
            In this section, we will dive into your employment readiness and working attitude. We
            want to empower you with the skills and knowledge needed to confidently step into the
            workforce.
          </p>
        </div>
        <div className="border rounded-2xl p-9 pt-0 mt-14">
          <p className="text-purple-700 text-xl font-semibold relative bg-white py-4 px-6 top-[-28px] inline-block">
            JOB SEEKING SKILLS
          </p>
          <p className="text-xl">
            Now, let's take a closer look at your job-seeking skills. These skills are essential in
            your quest to secure the perfect job opportunity. You can complete this assessment by
            rating your agreement with the following statements: Yes, Somewhat, or No.
          </p>
        </div>
        <p className="text-xl mt-6 mb-10">
          Remember, you can complete these sections in one go, or return later to finish it at your
          own pace. We're here to support you every step of the way in your journey towards
          self-discovery and career fulfillment.
          <br />
          <br />
          So, let's get started! Your future awaits, and we're excited to help you uncover your full
          potential and achieve your goals.
        </p>
        <button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(submitWelcome)}
          className="btn-primary mb-10"
        >
          OK
        </button>
      </div>
    </div>
  );
};
