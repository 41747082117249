import { useEffect } from 'react';

import Container from '../components/container/Container';

function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="text-center theme-gradient">
        <Container>
          <h1 className="font-semibold text-white py-6">Terms and Conditions</h1>
        </Container>
      </div>
      <Container className="pb-10">
        <p className="mt-10">
          The terms and conditions set forth below governs the terms of use of this website ("the
          Website") with respect to all existing Content on our Website. Throughout this Agreement,
          the term "You" or Your" shall refer to you, "the User" a “We” shall refer to the Company,
          Vero Learning LLC, a State of Florida, Limited Liability Company (hereinafter "Vero
          Learning" or "Company"), as the case may be, inclusive of Vero Learning's partners and
          other respective authorized agents.
        </p>
        <br />
        <p className="uppercase font-bold">1. scope of use</p>
        <br />
        <p className="">
          <span className="font-bold">1.1</span> Your use of this website is for the purposes of
          utilizing Vero Learning 's online platform upon being allowed by us use this Site. Vero
          Learning offers a cutting-edge prototype that allows young adults to showcase their career
          profiles to hiring managers seeking to fill entry-level job vacancies ("the Services").
          Your use of this Site, also allows you to view Vero Learning's overall general content and
          also includes the networking platform which Company has created. In consideration of your
          use of the Site, you agree to (a) provide accurate, current and complete information about
          you as may be prompted by any registration forms on the Site ("Registration Data"); (b)
          maintain the security of your password and identification; (c) maintain and promptly
          update the Registration Data, and any other information you provide to Vero Learning, to
          keep it accurate, current and complete; and (d) be fully responsible for all use of your
          account and for any actions that take place using your account.
        </p>
        <br />
        <p className="">
          <span className="font-bold">1.2</span> Proprietary Rights in Site Content; Limited
          License. All content on the Site, including designs, text, graphics, pictures, video,
          information, applications, software, music, sound and other files, and their selection and
          arrangemen (the "Site Content"), are the proprietary property of Vero Learning LLC, its
          users or its licensors with all rights reserv No Site Content may be modified, copied,
          distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted,
          transmitted, or sold in any form or by any means, in whole or in part, without Vero
          Learning's prio written permission, except that the foregoing does not apply to your own
          User Content (as defined below) that you legally post on the Site. Provided that you are
          eligible for use of the Site, you are granted a limited license to access and use the Site
          and the Site Content and to download or print a copy of any portion of the Site Content to
          which you have properly gained access solely for your personal, non-commercial use,
          provided that you keep all copyright or other proprietary notices intact. Except for your
          own User Content, you may not upload or republish Site Content on any Internet, Intranet
          or Extranet site or incorporate the information in any other database or compilation, and
          any other use of the Site Content is strictly prohibited. Such license is subject to these
          Terms of Use and does not permit use of any data mining, robots, scraping or similar data
          gathering or extraction methods. Any use of the Site or the Site Content other than as
          specifically authorized herein, without the prior written permission of the Vero Learning,
          is strictly prohibited and will immediately terminate the license granted herein. Such
          unauthorized use may also violat applicable laws including copyright and trademark laws
          and applicable communications regulations and statutes. Unless explicitly stated herein,
          nothing in these Terms of Use shall be construed as conferring any license to intellectual
          property rights, whether by estoppel, implication or otherwise.
        </p>
        <br />
        <p className="uppercase font-bold">2. ELIGIBILITY TO USE THE SITE</p>
        <br />
        <p className="">
          <span className="font-bold">2.1</span> The Website is provided for online participants
          using the Vero Learning platform. Vero Learning acknowledges tha given that the Company
          provides Services which includes, but is not limited to, tutoring and the provision of
          other coaching initiatives geared towards increasing the employability of young adults
          participating in its program, that users of this website may be under the age of 18. If
          this is the case, by using this Site, Company reasonably assumes that users are operating
          with parental, guardian or custodial authorization and the user otherwise understands
          these terms and conditions prior to using Vero Learning's online prototype and this Site.
          By using this Site you, the user, consent to completing a competencies-based intake form
          designed to evaluate participants' experience and interests. This evaluation will generate
          a preliminary Strength, Weaknesses, Opportunities, and Threats (SWOT) analysis report,
          providing participants with insights into their respective strengths, weaknesses, and
          opportunities fo improvement. Scammers, hackers promoters of unauthorized content will be
          barred from the website and prosecuted to the full extent of the law.
        </p>
        <br />
        <p className="uppercase font-bold">3. OPERATION OF THE WEBSITE</p>
        <br />
        <p className="">
          <span className="font-bold">3.1</span> The Website provides a marketplace for Users to
          book and otherwise utilize Vero Learning's online platform for performance of the Services
          offered through its Site.
        </p>
        <br />
        <p className="">
          <span className="font-bold">3.2</span> The role of Vero Learning is expressly limited to
          making the Website available to Users and maintaining the Website.
        </p>
        <br />
        <p className="">
          <span className="font-bold">3.3</span> Company neither accepts responsibility, nor is it
          liable in any manner for any negligence, misconduct or other inappropriate, unlawful or
          unprofessional behavior by the viewer in connection with content viewed on the Site.
        </p>
        <br />
        <p className="">
          <span className="font-bold">3.4</span> The Website is designed to provide Users access to
          its content. Vero Learning endeavors to verify any information provided by its sources and
          we make no representation with respect to the content or any information related to the
          Content provided.
        </p>
        <br />
        <p className="uppercase font-bold">4. REGISTRATION, AND SECURITY</p>
        <br />
        <p className="">
          <span className="font-bold">4.1</span> 4.1 By submitting registration information to us
          through our website, you represent and warrant that all information you provided is valid,
          complete and accurate, and you will inform us immediately of any updates or other changes
          to such information.
        </p>
        <br />
        <p className="">
          <span className="font-bold">4.2</span> You, the User, are fully responsible for
          maintaining the confidentiality of your account and the password, including all activities
          that occur under your account or password. User agrees to immediately notify us of any
          unauthorized use of your account or password and any breach of security or misuse or
          suspected breach of security or misuse of the service, and ensure that you exit from your
          account at the end of each session if you use a shared computer. Vero Learning shall not
          be liable for any loss or damage arising from your failure to comply with this Clause 4.e
          velit esse cillum dolore eu fugiat nulla pariatur.
        </p>
        <br />
        <p className="uppercase font-bold">5. CONTENT, ACTIVITY PROHIBITED</p>
        <br />
        <p className="">
          <span className="font-bold">5.1</span> You must not misuse the Vero Learning website. You
          will not: send or otherwise post unauthorized commercial communications to users (such as
          spam); upload viruses, trojans, worms, logic bombs or other malicious code; corrupt data;
          cause annoyance to others users; post content that is hateful, threatening, pornographic,
          or that contains nudity or graphic or gratuitous violence; use the Website to do anything
          unlawful, misleading, malicious or discriminatory; facilitate or encourage any violation
          of these Terms and Conditions.
        </p>
        <br />
        <p className="">
          <span className="font-bold">5.2</span> We reserve the right, in our sole discretion, to
          reject, edit or refuse to post any content and to remove any conter from the website,
          whether or not the content is expressly prohibited by these Terms and Conditions, or to
          restrict, suspend, or terminate your access to all or any part of the Services at any
          time, for any or no reason, with or without prior notice, and without liability.
        </p>
        <p className="">
          <span className="font-bold">5.3</span> Further, your use of our website is subject to the
          following Rules:
        </p>
        <br />
        <p className="">
          <span className="font-bold">(i)</span> Vero Learning advises you not to reveal any
          personal information about yourself or anyone else that would allow you to be identified
        </p>
        <br />
        <p className="">
          <span className="font-bold">(ii)</span> Vero Learning reserves the right to close user
          accounts if we believe a user is using proxy Internet Protocol addresses (IPs) as a method
          to hide the use of multiple accounts or to disrupt any of our services in any way. If you
          use multiple logins for the purpose of disrupting the community we may pursue legal action
          against you and close your accounts.
        </p>
        <br />
        <p className="">
          <span className="font-bold">(iii)</span> By submitting any material to us, you
          automatically grant Vero Learning a royalty-free, perpetual, exclusive righ and license to
          use, modify, edit, adapt, publish, re-use, translate, reverse engineer, distribute,
          perform and display such material in whole or part worldwide and/or to incorporate it in
          other works in any form, media, or technology now known or later developed.
        </p>
        <br />
        <p className="">
          <span className="font-bold">(iv)</span> By submitting any material to us, you agree to use
          the Website in accordance with these Rules and website Terms and Conditions.
        </p>
        <br />
        <p className="">
          <span className="font-bold">(v)</span> If you fail to abide by these Rules you may be sent
          an email which informs you why your application or registration has been refused or
          edited. This email will also include a warning that continuing to break the rules may
          result in action being brought against your account(s). Action may include any content
          posted by you being checked before being allowed to browse through the Website or a
          temporary or permanent suspension of your ability to participate in any or all of Vero
          Learning's Services.
        </p>
        <br />
        <p className="">
          <span className="font-bold">(vi)</span> Vero Learning reserves the right to edit or delete
          any contribution, or take action against any user account, at any time, for any reason.
        </p>
        <br />
        <p className="">
          <span className="font-bold">(vii)</span> If you do not want to grant Vero Learning the
          permission set out above on these terms, please do not submit o share any information to
          the Site.
        </p>
        <br />
        <p className="uppercase font-bold">6. COPYRIGHT</p>
        <br />
        <p className="">
          <span className="font-bold">6.1</span> Copyright and other intellectual property rights in
          any communications, ideas, or other materials submitted or offered to us by third parties
          or by you through on or by this website, unless specifically requested by us, shall become
          our property. You agree that submissions by you to this website must not risk infringing
          any right of any thir party and in addition, you agree that no submissions by you to this
          website will be or contain libelous or otherwise unlawful, abusive or obscene material or
          constitute an invasion of privacy. As such, you are and shall remain solely responsible
          for the content of any submissions you make to the website.
        </p>
        <br />
        <p className="">
          <span className="font-bold">6.2</span> You acknowledge and agree that the material and
          content contained within this website is made available for your viewing pleasure and that
          you may only download such material and content for the purpose of using this website.
          Accordingly, we suggest that you do not use this website to (and agree not to assist or
          facilitate any third party to) copy, reproduce, transmit, publish, display, distribute,
          commercially exploit or create derivative works of such material and content. Nothing
          contained in these Terms and Conditions shall be construed as conferring any license or
          right to use any trademark, design right or copyright on the Vero Learning website.
        </p>
        <br />
        <p className="uppercase font-bold">7. DISCLAIMER OF WARRANTIES</p>
        <br />
        <p className="">
          <span className="font-bold">7.1</span> The service, the content and the information on
          this Website are provided on an "AS-IS" and "AS AVAILABLE" bas Vero Learning, to the
          fullest extent permitted by law, disclaims all warranties, whether expressed, implied,
          statutory otherwise, with respect to the Website, any information offered on or through
          the Website or any third party.
        </p>
        <br />
        <p className="">
          <span className="font-bold">7.2</span> Notwithstanding the foregoing, Vero Learning
          disclaims any liability should any User's information or requests b submitted incorrectly
          or is not agreed upon in its agreement with User, Vero Learning shall have the right to
          decide on the course of action to be taken to resolve any discrepancy between User and
          Vero Learning.
        </p>
        <br />
        <p className="">
          <span className="font-bold">7.3</span> Vero Learning shall not liable for any
          mis-application made by User, inclusive of User's administrators inclusive o theft or any
          unilateral or mutual mistake that lead to loss of payments or loss of Users/projects
        </p>
        <br />
        <p className="">
          <span className="font-bold">7.4</span> Vero Learning reviews all User that signup for its
          Services, before allowing users access to its platform. Vero Learning has a right to
          refuse providing Services to User.
        </p>
        <br />
        <p className="uppercase font-bold">8. LIMITATION OF LIABILITY AND INDEMNITY</p>
        <br />
        <p className="uppercase">
          <span className="font-bold">8.1</span> TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT
          SHALL VERO LEARNING BE LIABLE FOR PERSONAL INJUR INCIDENTAL, SPECIAL, INDIRECT OR
          CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, D FOR LOSS OF PROFITS,
          LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES OUT OF OR
          RELATED TO YOUR USE OR INABILITY TO USE THE WEBSITE, HOWEVER CAUSED, REGARDLESS OF THE TI
          LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF VERO LEARNING HAS BEEN ADVISED OF THE
          POSSIBILITY DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
          LIABILITY SO SOME OF THE ABO LIMITATIONS MAY NOT APPLY TO YOU. IN NO EVENT SHALL THE TOTAL
          LIABILITY OF DAMAGES TO YOU FOR ALL DAMA LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT
          OR TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OTHERWISE) ARISING FROM THE TERMS OR
          YOUR USE OF THE SITE EXCEED, IN THE AGGREGATE, $100.00. WITHOUT THE FOREGOING, IN NO EVENT
          SHALL VERO LEARNING OR ITS RESPECTIVE OFFICERS DIRECTORS, EMPLOYEES, AGE SUCCESSORS,
          SUBSIDIARIES, DIVISIONS, DISTRIBUTORS, SUPPLIERS, AFFILIATES OR THIRD PARTIES PROVIDING
          INFO ON THIS SITE HAVE ANY LIABILITY FOR ANY DAMAGES OR LOSSES ARISING OUT OF OR OTHERWISE
          INCURRED IN CONNECTION WITH THE LOSS OF ANY DATA OR INFORMATION CONTAINED IN YOUR ACCOUNT
          OR OTHERWISE STORE OR ON BEHALF OF VERO LEARNING.
        </p>
        <br />
        <p className="uppercase font-bold">
          9. ARBITRATION; WAIVER OF JURY TRIAL AND CLASS ACTION; APPLICABLE LAW/JURISDICTION
        </p>
        <br />
        <p className="">
          <span className="font-bold">9.1(A)</span> Binding Arbitration. Any dispute, controversy or
          claim arising out of or relating to these Terms, or its breach, which cannot be resolved
          between the parties through negotiation within thirty (30) days, shall be submitted to the
          arbitration authority of the parties choosing, located in the State of Florida, for
          mandatory binding arbitration in fron of a single arbitrator chosen in accordance with the
          Rules of such entity. Discovery shall be permitted, but only to the extent that the
          documents are directly relevant to and needed for fair resolution of one or more of the
          issues of importance and can be located and produced at a cost that is reasonable in the
          context of all surrounding facts and circumstances. When the cost and burden of discovery
          are disproportionate to the likely importance of the requested materials, the arbitrator
          may deny the requests or require that the requesting party advance the reasonable cost of
          production to the other side.
        </p>
        <br />
        <p className="">
          <span className="font-bold">(B)</span> Additional Provisions. The arbitrator may not award
          non-monetary or equitable relief of any sort, nor award damages inconsistent with these
          Terms. All aspects of the arbitration shall be treated as confidential. Neither the
          parties nor the arbitrator may disclose the existence, content or results of the
          arbitration, except as necessary to enforce the results of the arbitration or to comply
          with legal or regulatory requirements. The arbitrator shall render it award in writing and
          will include the findings of fact and conclusion of law upon which the award is based. The
          result of the arbitration shall bind the parties and judgment on the arbitrators' award
          may be entered in any court having jurisdiction. In addition to any and all other relief
          to which a Party may be entitled, the arbitrator shall award reasonable attorneys' fees
          and costs, including reasonable expert witness fees and costs, to the prevailing Party
          (should there be one) in any such arbitration.
        </p>
        <br />
        <p className="">
          <span className="font-bold">(C)</span> Waiver of Jury Trial; Individual Basis; Equitable
          Relief. The parties surrender and waive the right to submit any to court or jury, or to
          appeal to a higher court. The parties agree to arbitration on an individual basis. Where
          enforceable, NEITHER YOU NOR WE SHALL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR
          AGAINST OTHER U PERSONS, OR ARBITRATE ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR
          IN A PRIVATE ATTORNEY GEN CAPACITY. UNLESS BOTH YOU AND WE AGREE OTHERWISE, THE ARBITRATOR
          MAY NOT CONSOLIDATE OR JOIN MORE ONE PERSON'S OR PARTY'S CLAIMS, AND MAY NOT OTHERWISE
          PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE
          ARBITRATOR MAY AWARD RELIEF ONLY IN FAVOR OF THE INDI PARTY SEEKING RELIEF AND ONLY TO THE
          EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S CLAIM(S). ANY RELIEF
          AWARDED CANNOT AFFECT OTHER SITE USERS. If any provision of this arbitration agreement
          found unenforceable, the unenforceable provision shall be severed, and the remaining
          arbitration terms shall be enforced (but in no case shall there be a class arbitration).
          Notwithstanding the foregoing, nothing in these Terms shall prohibit either party from
          seeking and obtaining from a court of competent jurisdiction (without necessity of posting
          bond) injunctive relief in order to preserve the status quo and/or avoid irreparable harm
          for which monetary damage would be insufficient.
        </p>
        <br />
        <p className="">
          <span className="font-bold">9.2</span> You agree to defend and indemnify us and our agents
          and officers, directors and employees, immediately on demand, against all claims,
          liability, damages, costs and expenses, including legal fees, arising out of or related to
          the use of the Website by you, for any breach of these Terms and Conditions by you.
        </p>
        <br />
        <p className="">
          <span className="font-bold">9.3</span> The Company website may also contain links to other
          websites, which are not operated by Vero Learning. When you activate any of these you will
          leave the website and we have no control over, and will accept no responsibility or
          liability in respect of, the material on any website which is not under our control.
        </p>
        <br />
        <p className="">
          <span className="font-bold">9.4</span> We may terminate your use of the website
          immediately if we consider that you have breached these Terms and Conditions.
        </p>
        <br />
        <p className="">
          <span className="font-bold">9.5</span> Notwithstanding the above sections, the Parties
          agree that if a dispute occurs, Vero Learning reserves the right t decide the course of
          action necessary to resolve the dispute, and further shall not be liable for the outcome
          of said decision. Vero Learning further agrees that an appeal may be allowed to any
          decision made by Vero Learning in the event of a dispute.
        </p>
        <br />
        <p className="uppercase font-bold">10. NON COMPETITION</p>
        <br />
        <p className="">
          <span className="font-bold">10.1</span> Vero Learning shall devote the necessary time and
          attention to protecting User's Content. During the Engagement Period, Vero Learning may
          engage, directly or indirectly, in any other unrelated business activity so lon that it's
          engagement neither compromises the User's business initiatives nor contaminates the User's
          intended mission, as the case may be.
        </p>
        <br />
        <p className="uppercase font-bold">11. JURISDICTION</p>
        <br />
        <p className="">
          <span className="font-bold">11.1</span> The website is controlled and operated in the
          State of Florida. Any terms and conditions concerning the usage this website will be
          governed by the laws of the State of Florida.
        </p>
        <br />
        <p className="uppercase font-bold">12. GENERAL</p>
        <br />
        <p className="">
          <span className="font-bold">12.1</span> Company may change these Terms and Conditions at
          any time. If any of these Terms and Conditions are invali or unenforceable, the remainder
          of these Terms and Conditions shall continue to have full force and effect.
        </p>
        <br />
        <p className="">
          <span className="font-bold">12.2</span> Company will not be responsible to you for any
          delay or failure to comply with our obligations under these Terms and Conditions if the
          delay or failure arises from any cause beyond our reasonable control.
        </p>
        <br />
        <p className="">
          <span className="font-bold">12.3</span> If you breach these Terms and Conditions and Vero
          Learning takes no action we will still be entitled to use our rights and remedies in other
          situations where you are in breach.
        </p>
        <br />
        <p className="">
          <span className="font-bold">12.4</span> Company reserves the right to amend, remove or
          vary the Services and/or any page of this Website at any time and without notice.
        </p>
        <br />
        <p className="uppercase font-bold">13. CUSTOMER SATISFACTION</p>
        <br />
        <p className="">
          <span className="font-bold">13.1</span> Company is committed to providing the best
          Services to Users throughout the globe. However, Vero Learning not obligated to provide a
          refund of any service under any circumstance unless explicitly agreed to by Vero Learning.
        </p>
        <br />
        <p className="">
          <span className="font-bold">13.2</span> If you create an account a logon ID and password
          is required. It is your responsibility to protect that information including without
          limitation, to use effective passwords that are not easily guessed or discoverable, and
          keep logon IDs and passwords confidential. You are responsible for any activity that
          occurs under your logon ID. You will advise Vero Learning immediately if you discover any
          compromise of your passwords or suspect unauthorized use of the site using your identity.
        </p>
        <br />
        <p className="">
          <span className="font-bold">13.3</span> Due to international privacy regulations and our
          constant commitment to protecting the confidentiality and security of our Users'
          documents, access to completed files is restricted to six months.
        </p>
        <br />
        <p className="uppercase font-bold">14. PRIVACY</p>
        <br />
        <p className="">
          <span className="font-bold">14.1</span> Please see our privacy policy page which is
          incorporated herein by reference.
        </p>
        <br />
        <p className="uppercase font-bold">15. ENTIRE AGREEMENT</p>
        <br />
        <p className="">
          <span className="font-bold">15.1</span> These Terms and Conditions constitute the entire
          agreement of the parties and supersede any and all preceding and contemporaneous
          agreements between you and Vero Learning. Any waiver of any provision of the Terms and
          Conditions will only be effective if in writing and signed by a Director of Company.
        </p>
      </Container>
    </>
  );
}

export default TermsAndConditions;
