import React from 'react';

import img5 from '../../../assets/images/swot/step4/char.png';

export const FeedbackSurveyTemplate: React.FC = () => {
  return (
    <>
      <div className="pt-4" style={{ height: '1122px' }}>
        <div className="text-center pt-3 pb-3 theme-gradient rounded-xl">
          <div className="text-xl text-white">
            <span className="font-semibold"> FEEDBACK </span> SURVEY
          </div>
        </div>
        <div className="text-center lg:px-16">
          <img className="mx-auto mt-5" src={img5} alt="" />
          <p className="mt-8 lg:text-xl">
            At Vero Learning, we believe that access to education and career opportunities should
            not be limited by systemic barriers. That's why we are committed to providing young
            adults with free vocational and skills-building courses, personalized career SWOT
            analysis reports and immediate job placement opportunities.
            <br /> <br />
            Your feedback is important to us, and we would love to hear about your experience using
            our platform. Please take a few minutes to complete our feedback survery so that we can
            continue to improve and provide you with the best possible service.
          </p>
        </div>
      </div>
    </>
  );
};
