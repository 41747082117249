import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import { getUserProfileValues, upsertUserProfile } from '../../../services/database/user-profile';

import { updateUserProfileInfoAction } from './user-profile.slice';
import { UserProfileDTO } from './user-profile.types';

/**
 * retrieve previously saved values from the intake form
 */
export const dispatchGetUserProfileValues =
  () =>
  async (dispatch: Dispatch<PayloadAction<UserProfileDTO>>): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values: any = await getUserProfileValues();
    if (values?.dateOfBirth?.seconds)
      values.dateOfBirth = new Date(values.dateOfBirth.seconds * 1000);
    dispatch(updateUserProfileInfoAction(values));
  };

/**
 * update user profile info
 */
export const dispatchUpdateUserProfileInfo =
  (dto: Partial<UserProfileDTO>) =>
  async (dispatch: Dispatch<PayloadAction<UserProfileDTO>>): Promise<void> => {
    await upsertUserProfile(dto);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values: any = await getUserProfileValues();
    if (values?.dateOfBirth?.seconds)
      values.dateOfBirth = new Date(values.dateOfBirth.seconds * 1000);
    dispatch(updateUserProfileInfoAction(values));
  };
