import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthenticatedPage } from '../components/AuthenticatedPage';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchPaymentStatusEmail } from '../redux/slice/form-send-email/payment-status-mail';
import {
  dispatchGetUserProfileValues,
  dispatchUpdateUserProfileInfo,
} from '../redux/slice/user-profile';
import { usertransactionUpdate } from '../redux/slice/user-transaction/user-transaction-operation';
import { useAppDispatch } from '../redux/store';
import { EnvironmentVariable } from '../utils/constants';

export const PaymentFailed: React.FC = () => {
  const asaUserDiscount = Number(process.env[EnvironmentVariable.REACT_APP_ASA_DISCOUNT_PERCENT]);
  const [productFinalPrice, setProductFinalPrice] = useState<number | null>(null);
  const userProfileContext = useUserProfileContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const calculateFinalPrice = (price: number, discount: number) => {
    return price - (price * discount) / 100;
  };
  const [isEmailSent, setIsEmailSent] = useState(false);

  useEffect(() => {
    void dispatch(dispatchGetUserProfileValues());
  }, []);

  useEffect(() => {
    if (Object.keys(userProfileContext).length && userProfileContext?.email) {
      if (!userProfileContext.paymentProcessing) {
        setTimeout(() => {
          return navigate(userProfileContext?.isPremium ? '/dashboard' : '/upgrade');
        }, 3000);
      } else {
        setProductFinalPrice(
          userProfileContext.asaUserId
            ? calculateFinalPrice(productPrice, asaUserDiscount)
            : calculateFinalPrice(productPrice, 0),
        );
        void usertransactionUpdate();
        void dispatch(
          dispatchUpdateUserProfileInfo({
            paymentProcessing: false,
            paymentSessionCreateDate: '',
            stripeSessionId: '',
          }),
        );
        if (!isEmailSent) {
          void dispatchPaymentStatusEmail({
            isAsaUser: !!userProfileContext.asaUserId,
            paymentStatus: false,
            to: userProfileContext?.email,
            userName: userProfileContext?.name,
          });
          setIsEmailSent(true);
        }
      }
    }
  }, [userProfileContext, isEmailSent]);
  const productPrice =
    Number(process.env[EnvironmentVariable.REACT_APP_SUBSCRIPTION_PRICE_CENTS]) / 100;
  return (
    <>
      <div className="h-screen flex pt-4 pb-4 justify-center items-center ">
        <div
          className={`mx-auto w-full max-w-3xl  justify-center payment-box   border-l border-r relative bg-white`}
        >
          <div className="bg-white text-center">
            <CancelOutlinedIcon style={{ fontSize: '120px', color: 'red' }} />
          </div>
          <div className=" rounded-lg p-3 text-center  text-xl ">
            <h3 className="font-bold ">Payment Failed</h3>
          </div>
          <div className=" p-3 text-center  text-xl text-gray-900">
            <p className="font-light mt-5 text-lg">We have not received payment amount of</p>
            <h4 className="text-4xl mt-5 font-bold ">$ {productFinalPrice}</h4>

            {/* <h4 className="text-lg mt-5">Request Id : x907615gh </h4> */}
          </div>
        </div>
      </div>
    </>
  );
};

export const PaymentFailedPage: React.FC = () => {
  return <AuthenticatedPage isPaymentFailedPage={true} render={() => <PaymentFailed />} />;
};
