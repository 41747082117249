import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import character from '../../assets/images/intake/step1/character.svg';
import logo_white from '../../assets/images/logo_white.svg';
import opportunities from '../../assets/images/swot/opportunities.svg';
import strength from '../../assets/images/swot/strength-icon.svg';
import swot_img from '../../assets/images/swot/swot-img.svg';
import threats from '../../assets/images/swot/threats-icon.svg';
import weekness from '../../assets/images/swot/weekness-icon.svg';
import { useSkillsContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import { useSWOTContext } from '../../hooks/use-swot-context.hooks';
import { dispatchGetSkillsValues, SkillsFields, SkillsState } from '../../redux/slice/form-skills';
import {
  PreEmploymentAssessmentState,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { dispatchGenerateSWOTReport } from '../../redux/slice/swot/swot.operations';
import {
  GenerateSWOTReportDTO,
  SWOTCategory,
  SWOTReport as SWOTReportType,
} from '../../redux/slice/swot/swot.types';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import { CircularLoader } from '../loader/CircularLoader';

export const SWOTReport: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const preEmploymentAssessment: PreEmploymentAssessmentState = usePreEmploymentAssessmentContext();
  const skills: SkillsState = useSkillsContext();
  const swot: SWOTReportType | undefined = useSWOTContext();

  function mapVariableToReadableString(key: keyof SkillsFields): string {
    switch (key) {
      case 'skillCommunication':
        return 'Communication';
      case 'skillCulture':
        return 'Culture';
      case 'skillMotivation':
        return 'Motivation';
      case 'skillPlanning':
        return 'Planning';
      case 'skillProblemSolving':
        return 'Problem Solving';
      case 'skillProfessionalism':
        return 'Professionalism';
      case 'skillReliability':
        return 'Reliability';
      case 'skillStressTolerance':
        return 'Stress Tolerance';
      case 'skillTeamwork':
        return 'Teamwork';
      case 'skillTechnology':
        return 'Technology';
    }
  }

  useEffect(() => {
    // TODO: cleanup this logic. The SWOT report may be being regenerated multiple times.
    if (!preEmploymentAssessment || !Object.keys(preEmploymentAssessment).length) {
      void dispatch(dispatchUpsertPreEmploymentAssessment());
    }
    if (!skills || !Object.keys(skills).length) {
      void dispatch(dispatchGetSkillsValues());
    }
  }, [preEmploymentAssessment, skills, dispatch]);

  useEffect(() => {
    if (
      !skills ||
      !Object.keys(skills).length ||
      !preEmploymentAssessment ||
      preEmploymentAssessment.isSWOTGenerated ||
      swot
    ) {
      swot && void dispatch(dispatchUpsertPreEmploymentAssessment({ isSWOTGenerated: true }));
      return;
    }

    void dispatch(dispatchGenerateSWOTReport(skills as GenerateSWOTReportDTO));
  }, [preEmploymentAssessment, skills, swot, dispatch]);

  const handleNextButton = async () => {
    try {
      setIsLoading(true);
      swot &&
        (await dispatch(
          dispatchUpsertPreEmploymentAssessment({
            isSWOTGenerated: true,
            didAsaUserViewedSwot: true,
          }),
        ));
      navigate('/swot/step4');
    } catch (error) {
      logger.error('error', error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading && <CircularLoader />}
      <img className="mx-auto mt-5" src={swot_img} alt="" />
      <div className="character bottom-[200px] absolute left-[-130px] z-[-1]">
        <img className="" src={character} alt="" />
      </div>
      <div className="lg:px-16 mt-9">
        <p className="text-xl text-center mb-9">
          Please access your comprehensive SWOT analysis report, which includes an overview of your
          Super Powers, Weaknesses, Opportunities, and Threats.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 relative">
          <div className="swot-card text-center border border-dashed border-[#663183] bg-[#FAF2FF] rounded-3xl p-5 min-h-[350px] relative">
            <img className="absolute -left-7 -top-7" src={strength} alt="" />
            <p className="text-xl text-[#9169DF] mb-6 font-bold">Super Powers</p>
            <ul className="text-base font-semibold leading-9">
              {swot?.[SWOTCategory.STRENGTHS].length ? (
                swot[SWOTCategory.STRENGTHS].map((value: keyof SkillsFields) => (
                  <li key={value}>
                    <Link
                      className="link"
                      target="_blank"
                      to={`/my-resources/${mapVariableToReadableString(value)}`}
                    >
                      {mapVariableToReadableString(value)}
                    </Link>
                  </li>
                ))
              ) : (
                <li>None</li>
              )}
            </ul>
          </div>
          <div className="swot-card text-center border border-dashed border-[#F9B23A] bg-[#FFFAF2] rounded-3xl p-5 min-h-[350px] relative">
            <img className="absolute -right-7 -top-7" src={weekness} alt="" />
            <p className="text-xl text-[#F9B33A] mb-6 font-bold">Weaknesses</p>
            <ul className="text-base font-semibold leading-9">
              {swot?.[SWOTCategory.WEAKNESSES].length ? (
                swot[SWOTCategory.WEAKNESSES].map((value: keyof SkillsFields) => (
                  <li key={value}>
                    <Link
                      className="link"
                      target="_blank"
                      to={`/my-resources/${mapVariableToReadableString(value)}`}
                    >
                      {mapVariableToReadableString(value)}
                    </Link>
                  </li>
                ))
              ) : (
                <li>None</li>
              )}
            </ul>
          </div>
          <div className="swot-card text-center border border-dashed border-[#89D2F5] bg-[#F2FBFF] rounded-3xl p-5 min-h-[350px] relative grid content-end">
            <ul className="text-base font-semibold leading-9">
              {swot?.[SWOTCategory.OPPORTUNITIES].length ? (
                swot[SWOTCategory.OPPORTUNITIES].map((value: keyof SkillsFields) => (
                  <li key={value}>
                    <Link
                      className="link"
                      target="_blank"
                      to={`/my-resources/${mapVariableToReadableString(value)}`}
                    >
                      {mapVariableToReadableString(value)}
                    </Link>
                  </li>
                ))
              ) : (
                <li>None</li>
              )}
            </ul>
            <p className="text-xl text-[#89D2F5] mt-6 font-bold">Opportunities</p>
            <img className="absolute -left-7 -bottom-7" src={opportunities} alt="" />
          </div>
          <div className="swot-card text-center border border-dashed border-[#E72F79] bg-[#FFF2F7] rounded-3xl p-5 min-h-[350px] relative grid content-end">
            <ul className="text-base font-semibold leading-9">
              {swot?.[SWOTCategory.THREATS].length ? (
                swot[SWOTCategory.THREATS].map((value: keyof SkillsFields) => (
                  <li key={value}>
                    <Link
                      className="link"
                      target="_blank"
                      to={`/my-resources/${mapVariableToReadableString(value)}`}
                    >
                      {mapVariableToReadableString(value)}
                    </Link>{' '}
                  </li>
                ))
              ) : (
                <li>None</li>
              )}
            </ul>
            <p className="text-xl text-[#E52F77] mt-6 font-bold">Threats</p>
            <img className="absolute -right-7 -bottom-7" src={threats} alt="" />
          </div>
          <div className="theme-gradient h-56 w-56 rounded-full shadow-xl border-white border-4 absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] flex items-center hidden lg:flex">
            <img className="m-auto" src={logo_white} alt="" />
          </div>
        </div>
      </div>
      <div className="text-center mt-12">
        <button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleNextButton}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    </>
  );
};
