import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import char from '../../assets/images/intake/step12/char.svg';
import { dispatchUpsertPreEmploymentAssessment } from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';

interface ComponentProps {
  step: number;
  onBack: () => void;
}

export const ProficiencyRatingScale: React.FC<ComponentProps> = ({ onBack }) => {
  const { handleSubmit } = useForm<FormData>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const backButtonClick = () => {
    onBack();
  };
  const submitForm: SubmitHandler<FormData> = async () => {
    setIsLoading(true);
    try {
      navigate('/pre-employment/experience/step1');
      await dispatch(
        dispatchUpsertPreEmploymentAssessment({
          activeStepUrl: `/pre-employment/experience/step1`,
          didCompleteCareerReadiness: true,
          syncedToAirtable: false,
        }),
      );
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <p className="text-lg mt-7 md:px-10">
        Thinking about all the work you have ever done, whether at a job or through volunteer work,
        have you:
      </p>
      <div className="character absolute bottom-[-93px] left-[-127px]">
        <img className="" src={char} alt="" />
      </div>
      <form
        className=" md:px-10"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitForm)}
      >
        <h3 className="font-semibold text-black text-lg mb-8 pt-5">
          Suggest using proficiency rating scale:
        </h3>
        <ol
          className="list-disc pl-6 font-semibold text-sm text-purple-700"
          style={{ listStyle: 'decimal' }}
          type="1"
        >
          <li className="mb-4">
            N/A
            <p className="text-sm text-black pt-1 font-light">(Not Applicable)</p>
          </li>
          <li className="mb-4">
            Fundamental awareness (basic knowledge)
            <p className="text-sm text-black pt-1 font-light">
              You have a basic understanding of common concepts and techniques.
            </p>
          </li>
          <li className="mb-4">
            Novice (limited experience)
            <p className="text-sm text-black pt-1 font-light">
              You have fundamental experience gained through learning, whether in a classroom or via
              sight training.
            </p>
          </li>
          <li className="mb-4">
            Intermediate (practical application)
            <p className="text-sm text-black pt-1 font-light">
              You can complete tasks successfully whilst working independently, without supervision.
              Help from an expert may be required at certain times. The focus remains on enhancing
              your own skill.
            </p>
          </li>
          <li className="mb-4">
            Advanced (applied theory)
            <p className="text-sm text-black pt-1 font-light">
              You can work autonomously without assistance. You are now considered a port of
              authority in your area, or a “person to ask” by those at lower proficiency levels.
            </p>
          </li>
          <li className="mb-4">
            Advanced (applied theory)
            <p className="text-sm text-black pt-1 font-light">
              You are regarded an expert in the area. You can deliver guidance, provide powerful
              speeches, and answer any questions related to this area of expertise.
            </p>
          </li>
        </ol>
        <div className="flex justify-end lg:justify-between lg:mt-20 mt-10">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
