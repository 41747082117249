import Socrates from '../../../../assets/images/courses/adapting-to-change/Chapter4/Socrates.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { MediaBlock } from '../../MediaBlock';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

export const AdaptingToChange4: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Summary</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock mediaImage={Socrates}>
          <p className="text-3xl leading-10 font-light w-3/4">
            The secret of change is to focus all of your energy not on fighting the old, but on
            building the new.
            <br />
            <span className="font-bold text-base">Socrates</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">Key Takeaways</h3>
        <p>
          Change in the workplace can feel overwhelming. For many, change is difficult because it
          introduces uncertainty, disrupts our routines, and threatens our competencies. It requires
          a learning curve—and that can make us afraid of failing. But with these summary tips, you
          can shorten the learning curve and successfully navigate change:
        </p>

        <ul className="list-disc pl-5 mt-7">
          <li className="mb-7 pl-4">
            <span className="font-bold">Recognize your reaction stage. </span>Are you experiencing
            shock and denial, anger and frustration, bargaining and negotiation, depression and
            detachment, or acceptance? Knowing where you are can help you move forward.
          </li>
          <li className="mb-7 pl-4">
            <span className="font-bold">Acknowledge your feelings. </span>Take time to understand
            your feelings and write them down. Acknowledging emotions can lessen their intensity.
          </li>
          <li className="mb-7 pl-4">
            <span className="font-bold">Seek information. </span>Avoid jumping to conclusions.
            Instead, ask questions to better understand what a change involves and how it will
            impact you. Removing uncertainty helps you prepare and feel more in control.
          </li>
          <li className="mb-7 pl-4">
            <span className="font-bold">Focus on what you can control. </span>What actionable steps
            can you take to create a smoother transition?
          </li>
          <li className="mb-7 pl-4">
            <span className="font-bold">Find the positives. </span>Combat negative or catastrophic
            thinking by identifying the benefits of change.
          </li>
        </ul>
        <p className="mt-7">
          Remember, no matter how unpredictable change may be, it doesn’t need to get in the way of
          your workplace success.
        </p>
        <p className="text-3xl leading-10 font-light mt-7 text-center">
          Thank you for completing this course! Check your knowledge with the following quiz
        </p>
        <button onClick={handleNextChapterClick} className="btn-primary w-full uppercase mt-7">
          Quiz
        </button>
      </div>
    </>
  );
};
