import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import { UpdateSkillsDTO } from './form-skills.types';

export type SkillsState = Partial<UpdateSkillsDTO>;

const initialState: SkillsState = {};

const skillsSlice = createSlice({
  // TODO: rename to `assessmentSkills`
  name: 'formSkills',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<SkillsState>) => {
      logger.debug('Updating skills state.', { state, action });
      Object.assign(state, { ...action.payload });
    },
  },
});

export const { update: updateSkillsAction } = skillsSlice.actions;
export default skillsSlice.reducer;
