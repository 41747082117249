import { TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import character from '../../assets/images/intake/step5/char.svg';
import { useIntakeFormContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  IntakeFormWorkHistoryDTO,
  WorkHistory as WorkHistoryValue,
} from '../../redux/slice/form-intake';
import { dispatchUpdateIntakeFormWorkHistory } from '../../redux/slice/form-intake/form-intake.operations';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import { allowOnlyNumbers, handlePasteOnlyNumbers } from '../../utils/helper';

interface WorkHistoryProps {
  step: number;
  onBack: () => void;
}

const history_options = [
  {
    value: WorkHistoryValue.LOOKING_FIRST_JOB,
    label: 'I am looking for my first job',
  },
  {
    value: WorkHistoryValue.NEVER_WORKED,
    label: 'I have never worked',
  },
  {
    value: WorkHistoryValue.RECENT_VOLUNTEER,
    label: 'I have recently worked as a volunteer',
  },
  {
    value: WorkHistoryValue.WORK_FULL_TIME,
    label: 'I have worked full time (30 or more hours a week)',
  },
  {
    value: WorkHistoryValue.WORK_PART_TIME,
    label: 'I have worked part time/casual time (less than 30 hours a week)',
  },
  {
    value: WorkHistoryValue.SELF_EMPLOYED,
    label: 'I have worked in my own business (self-employed)',
    is_editable: true,
  },
  {
    value: WorkHistoryValue.OTHER,
    label: 'Other',
    is_editable: true,
  },
];
const rating_options = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
];
export const WorkHistory: React.FC<WorkHistoryProps> = ({ step, onBack }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    workHistory,
    workHistorySelfEmployedComments,
    workHistoryOtherComments,
    workHistoryOrganizationName,
    workHistoryTotalExperienceInMonth,
    workHistoryFirstWorkplace,
    workHistoryFirstJobTitle,
    workHistoryCurrentJobRole,
    workHistoryFutureJobRole,
    workHistoryJobSatisfactionRating,
    workHistoryJobSatisfactionRatingReason,
  } = useIntakeFormContext();
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
  } = usePreEmploymentAssessmentContext();
  const [selectedWorkHistory, setSelectedWorkHistory] = useState(workHistory || '');
  const [selfRemarks, setSelfRemarks] = useState(workHistorySelfEmployedComments || '');
  const [otherRemarks, setOtherRemarks] = useState(workHistoryOtherComments || '');
  const [selectedJobSatisfactionRating, setSelectedJobSatisfactionRating] = useState(
    workHistoryJobSatisfactionRating ?? '',
  );
  const [jobRatingReason, setJobRatingReason] = useState(
    workHistoryJobSatisfactionRatingReason || '',
  );
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showOrganization, setShowOrganization] = useState(false);
  const [error, setError] = useState({
    summary: '',
    total_experience: '',
    organization: '',
    firstJobTitle: '',
    firstWorkplace: '',
    currentJobRole: '',
    futureJobRole: '',
    satisfactionRating: '',
    satisfactionRatingReason: '',
  });
  const dispatch = useAppDispatch();
  const organizationRef = useRef<HTMLInputElement>(null);
  const firstWorkplaceRef = useRef<HTMLInputElement>(null);
  const firstJobTitleRef = useRef<HTMLInputElement>(null);
  const currentJobRoleRef = useRef<HTMLInputElement>(null);
  const futureJobRoleRef = useRef<HTMLInputElement>(null);
  const experienceRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);
  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, isOther: boolean) => {
    if (!isOther) {
      setSelfRemarks(event.target.value);
      setOtherRemarks('');
      if (
        event.target.value.trim() &&
        !selectedWorkHistory.includes(WorkHistoryValue.SELF_EMPLOYED)
      ) {
        setSelectedWorkHistory(WorkHistoryValue.SELF_EMPLOYED);
      } else if (!event.target.value.trim()) {
        setSelectedWorkHistory('');
      }
    } else {
      setSelfRemarks('');
      setOtherRemarks(event.target.value);
      if (event.target.value.trim() && !selectedWorkHistory.includes(WorkHistoryValue.OTHER)) {
        setSelectedWorkHistory(WorkHistoryValue.OTHER);
      } else if (!event.target.value.trim()) {
        setSelectedWorkHistory('');
      }
    }
    setError((prev) => {
      return { ...prev, summary: '' };
    });
  };

  const jobRatingReasonTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setJobRatingReason(event.target.value);
  };

  const handleWorkHistoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFormDirty(true);
    setSelectedWorkHistory(event.target.value);
    if (
      event.target.value === WorkHistoryValue.RECENT_VOLUNTEER ||
      event.target.value === WorkHistoryValue.WORK_PART_TIME ||
      event.target.value === WorkHistoryValue.WORK_FULL_TIME
    ) {
      setShowOrganization(true);
    } else {
      setShowOrganization(false);
      const inputs = divRef?.current?.querySelectorAll('input');
      inputs &&
        inputs.forEach((input) => {
          input.value = ''; // Clear the value
        });
      setSelectedJobSatisfactionRating('');
    }
    setError((prev) => {
      return { ...prev, summary: '' };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll to the top
  }, []);

  useEffect(() => {
    if (workHistory) {
      setSelectedWorkHistory(workHistory ?? '');
      setSelfRemarks(workHistorySelfEmployedComments ?? '');
      setOtherRemarks(workHistoryOtherComments ?? '');
      setShowOrganization(
        workHistory === WorkHistoryValue.RECENT_VOLUNTEER ||
          workHistory === WorkHistoryValue.WORK_PART_TIME ||
          workHistory === WorkHistoryValue.WORK_FULL_TIME,
      );
      setSelectedJobSatisfactionRating(workHistoryJobSatisfactionRating || '');
      setJobRatingReason(workHistoryJobSatisfactionRatingReason || '');
    }
  }, [
    workHistory,
    workHistorySelfEmployedComments,
    workHistoryOtherComments,
    workHistoryOrganizationName,
    workHistoryTotalExperienceInMonth,
    workHistoryJobSatisfactionRating,
    workHistoryJobSatisfactionRatingReason,
  ]);

  const { handleSubmit } = useForm<FormData>();
  const submitWorkHistory: SubmitHandler<FormData> = async () => {
    logger.debug('Submitted work history form.', {
      selectedWorkHistory,
      selfRemarks,
      otherRemarks,
    });
    setIsLoading(true);
    try {
      const isSelfEmployedSelected: boolean = selectedWorkHistory.includes(
        WorkHistoryValue.SELF_EMPLOYED,
      );
      const isOtherSelected: boolean = selectedWorkHistory.includes(WorkHistoryValue.OTHER);
      if (!selectedWorkHistory) {
        setError((prev) => {
          return { ...prev, summary: 'Please select an option' };
        });
      } else if (isSelfEmployedSelected && !selfRemarks?.trim()?.length) {
        setError((prev) => {
          return { ...prev, summary: 'Please explain the self-employed category' };
        });
      } else if (isOtherSelected && !otherRemarks?.trim()?.length) {
        setError((prev) => {
          return { ...prev, summary: 'Please explain the other category' };
        });
      } else if (
        showOrganization &&
        (!organizationRef?.current?.value.trim() ||
          !experienceRef?.current?.value.trim() ||
          !firstJobTitleRef?.current?.value.trim() ||
          !firstWorkplaceRef?.current?.value.trim() ||
          !futureJobRoleRef?.current?.value.trim() ||
          !currentJobRoleRef?.current?.value.trim() ||
          !selectedJobSatisfactionRating ||
          !jobRatingReason?.trim())
      ) {
        !organizationRef?.current?.value.trim() &&
          setError((prev) => {
            return { ...prev, organization: 'Required' };
          });
        !experienceRef?.current?.value.trim() &&
          setError((prev) => {
            return { ...prev, total_experience: 'Required' };
          });
        !firstJobTitleRef?.current?.value.trim() &&
          setError((prev) => {
            return { ...prev, firstJobTitle: 'Required' };
          });
        !firstWorkplaceRef?.current?.value.trim() &&
          setError((prev) => {
            return { ...prev, firstWorkplace: 'Required' };
          });
        !futureJobRoleRef?.current?.value.trim() &&
          setError((prev) => {
            return { ...prev, futureJobRole: 'Required' };
          });
        !currentJobRoleRef?.current?.value.trim() &&
          setError((prev) => {
            return { ...prev, currentJobRole: 'Required' };
          });

        !selectedJobSatisfactionRating &&
          setError((prev) => {
            return { ...prev, satisfactionRating: 'Required' };
          });
        !jobRatingReason.trim() &&
          setError((prev) => {
            return { ...prev, satisfactionRatingReason: 'Required' };
          });
      } else {
        if (isDisabled) {
          navigate(`/pre-employment/intake/step${step + 1}`);
        } else {
          const dto: IntakeFormWorkHistoryDTO = {
            workHistory: selectedWorkHistory as WorkHistoryValue,
            workHistorySelfEmployedComments: isSelfEmployedSelected ? selfRemarks : undefined,
            workHistoryOtherComments: isOtherSelected ? otherRemarks : undefined,
            workHistoryOrganizationName: organizationRef?.current?.value.trim() ?? null,
            workHistoryTotalExperienceInMonth: experienceRef?.current?.value.trim() ?? null,
            workHistoryCurrentJobRole: currentJobRoleRef?.current?.value.trim() ?? null,
            workHistoryFirstJobTitle: firstJobTitleRef?.current?.value?.trim() ?? null,
            workHistoryFirstWorkplace: firstWorkplaceRef?.current?.value.trim() ?? null,
            workHistoryFutureJobRole: futureJobRoleRef?.current?.value.trim() ?? null,
            workHistoryJobSatisfactionRating: selectedJobSatisfactionRating ?? null,
            workHistoryJobSatisfactionRatingReason: jobRatingReason.trim() ?? null,
          };
          await dispatch(dispatchUpdateIntakeFormWorkHistory(dto));
          navigate(`/pre-employment/intake/step${step + 1}`);
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: workHistory ? progress : totalProgress,
                activeStepUrl: `/pre-employment/intake/step${step + 1}`,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/intake/step${step + 1}`,
              }),
            );
          }
        }
      }
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  const backButtonClick = () => {
    onBack();
  };

  const handleJobSatisfactionRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedJobSatisfactionRating(event.target.value);
    setError((prev) => {
      return { ...prev, satisfactionRating: '' };
    });
  };

  return (
    <div>
      <div className="character absolute top-96 left-[-130px] z-[-1]">
        <img className="" src={character} alt="" />
      </div>
      <form
        className="md:px-10"
        action=""
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitWorkHistory)}
      >
        <div className="flex mt-9 mb-10">
          <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">06</div>
          <div>
            <h4 className="text-2xl">
              Which of the following best describes your
              <span className="font-semibold"> previous work history</span>
            </h4>
          </div>
        </div>
        <div className="grid gap-y-3">
          {history_options.map((option) => (
            <div
              key={option.value}
              className={` ${option.is_editable ? 'h-[170px]' : 'h-[90px] lg:h-[60px]'} `}
            >
              <label className="radio-container h-full p-4 block relative">
                <input
                  disabled={isDisabled}
                  checked={selectedWorkHistory === option.value}
                  onChange={handleWorkHistoryChange}
                  value={option.value}
                  className="w-full h-full absolute top-0 left-0 opacity-0"
                  type="radio"
                  name="work history"
                />
                <span className="checkmark font-semibold !block h-auto pt-4">
                  {option.label}
                  {option.is_editable && (
                    <textarea
                      disabled={isDisabled}
                      onChange={(event) =>
                        textAreaChange(event, option.value.includes(WorkHistoryValue.OTHER))
                      }
                      placeholder="Explain"
                      value={
                        option.value.includes(WorkHistoryValue.OTHER) ? otherRemarks : selfRemarks
                      }
                      rows={2}
                      className="bg-white border rounded-lg w-full resize-none mt-4 p-3 font-light"
                      name=""
                    ></textarea>
                  )}
                </span>
              </label>
            </div>
          ))}
          {error?.summary && <p className="text-red-500">{error.summary}</p>}
        </div>
        {showOrganization && (
          <div ref={divRef}>
            <div className="flex items-center mt-9 mb-5 border-t border-purple-700 pt-10">
              <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
                6.1
              </div>
              <div>
                <p className="text-lg">
                  <span className="font-bold">What was your first job?</span> Please include job
                  title and workplace name.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <TextField
                className=""
                autoComplete="off"
                id="firstJobTitle"
                type="text"
                name="firstJobTitle"
                inputRef={firstJobTitleRef}
                defaultValue={!isFormDirty ? workHistoryFirstJobTitle : ''}
                disabled={isDisabled}
                label="First Job Title *"
                variant="outlined"
                onChange={(event) => {
                  !!event.target.value.trim() &&
                    setError((prev) => {
                      return { ...prev, firstJobTitle: '' };
                    });
                }}
                error={!!error.firstJobTitle}
                helperText={!!error.firstJobTitle && error.firstJobTitle}
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                className=""
                autoComplete="off"
                id="firstWorkplaceName"
                type="text"
                name="firstWorkplaceName"
                inputRef={firstWorkplaceRef}
                defaultValue={!isFormDirty ? workHistoryFirstWorkplace : ''}
                disabled={isDisabled}
                label="First Workplace Name *"
                variant="outlined"
                onChange={(event) => {
                  !!event.target.value.trim() &&
                    setError((prev) => {
                      return { ...prev, firstWorkplace: '' };
                    });
                }}
                error={!!error.firstWorkplace}
                helperText={!!error.firstWorkplace && error.firstWorkplace}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div className="flex items-center mt-2 mb-5 pt-5">
              <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
                6.2
              </div>
              <div>
                <p className="text-lg">
                  <span className="font-bold">Where do you currently work?</span> Please include the
                  company name and how long you have been working with them.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <TextField
                className=""
                autoComplete="off"
                id="organizationName"
                type="text"
                name="organizationName"
                inputRef={organizationRef}
                defaultValue={!isFormDirty ? workHistoryOrganizationName : ''}
                disabled={isDisabled}
                label="Workplace Name *"
                variant="outlined"
                onChange={(event) => {
                  !!event.target.value.trim() &&
                    setError((prev) => {
                      return { ...prev, organization: '' };
                    });
                }}
                error={!!error.organization}
                helperText={!!error.organization && error.organization}
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                className="w-full"
                autoComplete="off"
                inputRef={experienceRef}
                id="totalExperience"
                name="totalExperience"
                type="text"
                disabled={isDisabled}
                onChange={(event) => {
                  !!event.target.value.trim() &&
                    setError((prev) => {
                      return { ...prev, total_experience: '' };
                    });
                }}
                error={!!error.total_experience}
                helperText={!!error.total_experience && error.total_experience}
                inputProps={{
                  pattern: '^[0-9]*$',
                  onKeyPress: allowOnlyNumbers,
                  onPaste: handlePasteOnlyNumbers,
                  maxLength: 3,
                }}
                defaultValue={!isFormDirty ? workHistoryTotalExperienceInMonth : ''}
                label="Total Experience(In months) *"
                variant="outlined"
              />
            </div>
            <div className="flex items-center mt-2 mb-5 pt-5">
              <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
                6.3
              </div>
              <div>
                <p className="text-lg">What role are you currently in?</p>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6">
              <TextField
                autoComplete="off"
                className=""
                id="currentJobRole"
                type="text"
                name="currentJobRole"
                inputRef={currentJobRoleRef}
                defaultValue={!isFormDirty ? workHistoryCurrentJobRole : ''}
                disabled={isDisabled}
                label="Current Job Role *"
                variant="outlined"
                onChange={(event) => {
                  !!event.target.value.trim() &&
                    setError((prev) => {
                      return { ...prev, currentJobRole: '' };
                    });
                }}
                error={!!error.currentJobRole}
                helperText={!!error.currentJobRole && error.currentJobRole}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div className="flex items-center mt-2 mb-5 pt-5">
              <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
                6.4
              </div>
              <div>
                <p className="text-lg">In the next 10 years, what role do you see yourself in?</p>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6">
              <TextField
                autoComplete="off"
                className=""
                id="futureJobRole"
                type="text"
                name="futureJobRole"
                inputRef={futureJobRoleRef}
                defaultValue={!isFormDirty ? workHistoryFutureJobRole : ''}
                disabled={isDisabled}
                label="Job role in next 10 years *"
                variant="outlined"
                onChange={(event) => {
                  !!event.target.value.trim() &&
                    setError((prev) => {
                      return { ...prev, futureJobRole: '' };
                    });
                }}
                error={!!error.futureJobRole}
                helperText={!!error.futureJobRole && error.futureJobRole}
                inputProps={{ maxLength: 100 }}
              />
            </div>
            <div className="flex items-center mt-2 mb-5 pt-5">
              <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
                6.5
              </div>
              <div>
                <p className="text-lg">
                  Overall, how satisfied are you with your work experience? ( Scale of 1-5){' '}
                </p>
              </div>
            </div>
            <div className="lg:h-[60px] border rounded-lg flex flex-wrap md:flex-nowrap items-center gap-8 p-6 mb-3">
              <div className="lang-txt  flex lg:basis-full font-semibold">
                Satisfaction scale <em className="text-red-600">*</em>
              </div>
              <div className="rating-btns flex justify-start md:justify-end flex-wrap basis-full gap-4">
                {rating_options.map((option) => (
                  <div key={option.value} className="h-[35px] w-[44px]">
                    <label className="radio-container h-full block relative">
                      <input
                        disabled={isDisabled}
                        checked={selectedJobSatisfactionRating === option.value}
                        onChange={handleJobSatisfactionRatingChange}
                        value={option.value}
                        className="w-full h-full absolute top-0 left-0 opacity-0"
                        type="radio"
                        name="eng"
                      />
                      <span className="checkmark">{option.label}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {error?.satisfactionRating && (
              <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                {error.satisfactionRating}
              </p>
            )}

            <div className="flex items-center mt-2 mb-5 pt-5">
              <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
                6.6
              </div>
              <div>
                <p className="text-lg">Why did you rate your company the way you did? </p>
              </div>
            </div>
            <textarea
              disabled={isDisabled}
              onChange={(event) => jobRatingReasonTextArea(event)}
              placeholder="Explain"
              value={jobRatingReason}
              rows={2}
              maxLength={250}
              className="bg-white border rounded-lg w-full resize-none mt-4 p-3 font-light"
            ></textarea>
            {error?.satisfactionRatingReason && (
              <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                {error.satisfactionRatingReason}
              </p>
            )}
          </div>
        )}

        <div className="flex justify-end lg:justify-between mt-8">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
