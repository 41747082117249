import { Replay } from '@mui/icons-material';
import _ from 'lodash';
import { useEffect } from 'react';

import { CourseChapter } from '../../courses/courses.types';
import { useCourseProgressContext } from '../../hooks/use-user-course.hooks';
import { dispatchUpdateChapterProgress } from '../../redux/slice/user-course';
import { useAppDispatch } from '../../redux/store';

import { CircularProgressWithLabel } from './CircularProgressWithLabel';
interface quizResultProps {
  score: number;
  lastScore: number | null | undefined;
  onRetakeClick: () => void;
  courseId?: string;
  chapter: CourseChapter;
  onNextChapter: () => void;
  quizResultTitle?: string;
}

export const QuizResult: React.FC<quizResultProps> = ({
  score,
  onRetakeClick,
  courseId,
  chapter,
  quizResultTitle,
}) => {
  const courseProgressContext = useCourseProgressContext();
  const dispatch = useAppDispatch();
  const isPrePostAssessment: boolean = !!quizResultTitle;
  const handleRetakeQuiz = () => {
    onRetakeClick();
  };

  useEffect(() => {
    if (Object.keys(courseProgressContext).length && courseId) {
      if (courseProgressContext[courseId]?.chapters[chapter.id] < 100) {
        void dispatch(
          dispatchUpdateChapterProgress({
            [courseId]: {
              activeChapter: chapter.id,
              chapters: { [chapter.id]: 100 },
              progress: isPrePostAssessment
                ? courseProgressContext[courseId]?.progress + chapter.completion_percentage
                : _.clamp(
                    courseProgressContext[courseId]?.progress +
                      (score >= 80 ? chapter.completion_percentage : 0),
                    0,
                    100,
                  ),
            },
          }),
        );
      }
    }
  }, [courseProgressContext]);

  return (
    <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-lg w-full">
      <h2 className="font-semibold text-3xl text-purple-600">
        {quizResultTitle ? quizResultTitle : 'Quiz Results'}
      </h2>
      <div className="mt-14 ml-10 mb-32 relative">
        <CircularProgressWithLabel value={score} />
        <div className="pt-5 border-1 border-t border-gray-700 text-sm text-[#962CC6] flex absolute md:left-[90px] left-[58px] lg:left-[100px]">
          <button onClick={handleRetakeQuiz} className="btn-secondary min-w-[140px]">
            RETAKE
            <br />
            <Replay />
          </button>
        </div>
      </div>

      {/* <button onClick={handleNextChapter} className="btn-primary mt-10">
        QUIZ
      </button> */}
    </div>
  );
};
