import { Close, Menu } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import logo from '../../assets/images/landing/logo.svg';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  useAuthContext,
  useAuthInitializedContext,
  useTempAuthIntializedContext,
} from '../../hooks/use-user-auth-context.hooks';
import { dispatchLogout } from '../../redux/slice/auth/auth.operations';
import { CustomUser } from '../../redux/slice/auth/CustomUser';
import {
  PreEmploymentAssessmentState,
  PreEmploymentAssessmentStatus,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import { getIsOtpVerified } from '../../services/database/mfa-auth';
import logger from '../../services/logger';
import Container from '../container/Container';

export const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userInitialized = useAuthInitializedContext();
  const isTempAuthInitilized = useTempAuthIntializedContext();

  const user: CustomUser | null = useAuthContext();
  const isAuthenticated: boolean = user ? true : false;
  const isLandingPage = location.pathname === '/';
  const menuRef = useRef<HTMLDivElement>(null);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showHeaderLinks, setShowHeaderLinks] = useState(false);
  const preEmploymentAssessment: PreEmploymentAssessmentState = usePreEmploymentAssessmentContext();
  const isOtpVerified = getIsOtpVerified();
  const handleLogout = async () => {
    try {
      await dispatch(dispatchLogout());
      toast.success('Logged out!');
      navigate('/login');
      window.location.reload();
    } catch (e) {
      logger.error('Failed to logout.', e);
      toast.error('Logout failed.');
    }
  };
  const handleMenuToggle = () => {
    setToggleMenu((prevState) => !prevState);
  };

  useEffect(() => {
    if (isAuthenticated && Object.keys(preEmploymentAssessment).length) {
      if (
        preEmploymentAssessment.status === PreEmploymentAssessmentStatus.COMPLETE &&
        preEmploymentAssessment.isSWOTGenerated
      ) {
        setShowHeaderLinks(true);
      }
    }
  }, [userInitialized, preEmploymentAssessment]);

  useEffect(() => {
    const handleClickMenu = (event: MouseEvent) => {
      if (menuRef.current && menuRef.current.contains(event.target as Node)) {
        if (event.target instanceof HTMLAnchorElement) {
          const href = event.target.href;
          const linkText = event.target.text;

          if (linkText.includes('Log Out')) {
            void handleLogout();
          } else if (href) {
            const url = new URL(href);
            const relativePath: string = url.pathname;
            setToggleMenu(false);
            navigate(relativePath);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickMenu);

    return () => {
      document.removeEventListener('mousedown', handleClickMenu);
    };
  }, []);

  return (
    <header className={`py-3 relative w-full z-50 ${!isLandingPage ? 'shadow bg-white' : ''}`}>
      <Container>
        <nav className="navbar flex justify-between items-center">
          {isOtpVerified ? (
            <div className="navbar-brand mr-4">
              <Link className="cursor-pointer" to="/dashboard">
                <div>
                  <img src={logo} alt="" />
                </div>
              </Link>
            </div>
          ) : (
            <div className="navbar-brand mr-4">
              <Link className="cursor-pointer" to="/login">
                <div>
                  <img src={logo} alt="" />
                </div>
              </Link>
            </div>
          )}
          <button onClick={handleMenuToggle} type="button" className="navbar-toggle hidden">
            <Menu sx={{ fontSize: '36px' }} />
          </button>
          <ul
            style={{ right: toggleMenu ? '0' : '100%' }}
            className={`navbar-nav ${
              toggleMenu ? 'relative' : ''
            } flex items-center gap-8 ml-auto text-center pt-16 lg:pt-0 ${
              isLandingPage ? 'text-black lg:text-white' : 'text-black'
            } `}
          >
            <div
              style={{ display: toggleMenu ? 'block' : 'none' }}
              className="absolute top-[30px] right-[16px]"
              onClick={handleMenuToggle}
            >
              <Close sx={{ fontSize: '36px' }} />
            </div>
            {userInitialized || isTempAuthInitilized ? (
              !isAuthenticated ? (
                <div className="lg:flex gap-8 items-center" ref={menuRef}>
                  <li>
                    <Link className="cursor-pointer" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link className="cursor-pointer" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                  <li className="text-center">
                    <Link
                      to="/signup"
                      className={`cursor-pointer ${
                        isLandingPage ? 'btn-secondary' : 'btn-primary'
                      }`}
                    >
                      Get Started
                    </Link>
                  </li>
                </div>
              ) : (
                <div className="lg:flex gap-8 items-center" ref={menuRef}>
                  {showHeaderLinks && (
                    <>
                      <li>
                        <Link className="cursor-pointer" to="/pre-employment/intake/step1">
                          Pre-Employment Screening
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="cursor-pointer"
                          to="/courses/eb7b1338-7fa1-4673-9b2f-4fe30c2a9069"
                        >
                          Transferable Skill Modules
                        </Link>
                      </li>
                      <li>
                        <Link className="cursor-pointer" to="/swot/step1">
                          SWOT Report
                        </Link>
                      </li>
                    </>
                  )}

                  <li className="text-center">
                    <button
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={handleLogout}
                      className={`cursor-pointer ${
                        isLandingPage ? 'btn-secondary' : 'btn-primary'
                      }`}
                    >
                      Log Out
                    </button>
                  </li>
                </div>
              )
            ) : null}
          </ul>
        </nav>
      </Container>
    </header>
  );
};
