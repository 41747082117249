import {
  getUserEngagementRecord,
  updateUserEngagementRecord,
} from '../../../services/database/user-engagement';

import { UserEngagementType } from './user-engagement.type';

export const dispatchGetUserEngagementActivity = async () => {
  const values: UserEngagementType = await getUserEngagementRecord();
  return values;
};

export const dispatchUpdateUserEngagementActivity = async (dto: UserEngagementType) => {
  await updateUserEngagementRecord(dto);
};
