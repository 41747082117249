import React, { useEffect } from 'react';

import logo_white from '../../../assets/images/logo_white.svg';
import opportunities from '../../../assets/images/swot/opportunities.svg';
import strength from '../../../assets/images/swot/strength-icon.svg';
import threats from '../../../assets/images/swot/threats-icon.svg';
import weekness from '../../../assets/images/swot/weekness-icon.svg';
import { useSkillsContext } from '../../../hooks/use-form-context.hooks';
import { useSWOTContext } from '../../../hooks/use-swot-context.hooks';
import { SkillsFields, SkillsState } from '../../../redux/slice/form-skills';
import { dispatchGenerateSWOTReport } from '../../../redux/slice/swot/swot.operations';
import {
  GenerateSWOTReportDTO,
  SWOTCategory,
  SWOTReport as SWOTReportType,
} from '../../../redux/slice/swot/swot.types';
import { useAppDispatch } from '../../../redux/store';

type SwotReportProp = {
  isNoteVisible?: boolean;
  isHeaderVisible?: boolean;
};

export const SWOTReportTemplate: React.FC<SwotReportProp> = ({
  isNoteVisible,
  isHeaderVisible,
}) => {
  const dispatch = useAppDispatch();
  const skills: SkillsState = useSkillsContext();
  const swot: SWOTReportType | undefined = useSWOTContext();
  function mapVariableToReadableString(key: keyof SkillsFields): string {
    switch (key) {
      case 'skillCommunication':
        return 'Communication';
      case 'skillCulture':
        return 'Culture';
      case 'skillMotivation':
        return 'Motivation';
      case 'skillPlanning':
        return 'Planning';
      case 'skillProblemSolving':
        return 'Problem Solving';
      case 'skillProfessionalism':
        return 'Professionalism';
      case 'skillReliability':
        return 'Reliability';
      case 'skillStressTolerance':
        return 'Stress Tolerance';
      case 'skillTeamwork':
        return 'Teamwork';
      case 'skillTechnology':
        return 'Technology';
    }
  }

  useEffect(() => {
    if (!skills || !Object.keys(skills).length || swot) {
      swot;
      return;
    }

    void dispatch(dispatchGenerateSWOTReport(skills as GenerateSWOTReportDTO));
  }, [skills, swot, dispatch]);

  return (
    <>
      <div className="pt-4" style={{ height: '1122px' }}>
        <div hidden={isHeaderVisible} className="pt-3 pb-3 text-center theme-gradient rounded-xl">
          <div className=" text-xl text-white">
            <span className="font-semibold"> SWOT </span> ANALYSIS
          </div>
        </div>
        <div className="lg:px-16 mt-9">
          <div className="grid grid-cols-2 gap-8 relative">
            <div className="swot-card text-center border border-dashed border-[#663183] bg-[#FAF2FF] rounded-3xl p-5 min-h-[300px] max-h-[485px] lg:min-h-[350px] relative">
              <img
                className="absolute -left-5 lg:-left-7 -top-7 scale-75 md:scale-100"
                src={strength}
                alt=""
              />
              <p className="text-sm sm:text-xl text-[#9169DF] mb-6 font-bold">Super Powers</p>
              <ul className="text-sm lg:text-base font-semibold leading-7 lg:leading-9">
                {swot?.[SWOTCategory.STRENGTHS].length ? (
                  swot[SWOTCategory.STRENGTHS].map((value: keyof SkillsFields) => (
                    <li key={value}>
                      <a
                        className="link text-xs md:text-base"
                        href={`https://verolearning.com/free-resources/${mapVariableToReadableString(
                          value,
                        )}`}
                        target="_blank"
                      >
                        {mapVariableToReadableString(value)}
                      </a>
                    </li>
                  ))
                ) : (
                  <li>None</li>
                )}
              </ul>
            </div>
            <div className="swot-card border-[#F9B23A] bg-[#FFFAF2]">
              <img
                className="absolute -right-5 lg:-right-7 -top-7 scale-75 md:scale-100"
                src={weekness}
                alt=""
              />
              <p className="text-sm sm:text-xl text-[#F9B33A] mb-6 font-bold">Weaknesses</p>
              <ul className="text-sm lg:text-base font-semibold leading-7 lg:leading-9">
                {swot?.[SWOTCategory.WEAKNESSES].length ? (
                  swot[SWOTCategory.WEAKNESSES].map((value: keyof SkillsFields) => (
                    <li key={value}>
                      <a
                        className="link"
                        href={`https://verolearning.com/free-resources/${mapVariableToReadableString(
                          value,
                        )}`}
                        target="_blank"
                      >
                        {mapVariableToReadableString(value)}
                      </a>
                    </li>
                  ))
                ) : (
                  <li>None</li>
                )}
              </ul>
            </div>
            <div className="swot-card border-[#89D2F5] bg-[#F2FBFF] grid content-end">
              <ul className="text-sm lg:text-base font-semibold  leading-7 lg:leading-9">
                {swot?.[SWOTCategory.OPPORTUNITIES].length ? (
                  swot[SWOTCategory.OPPORTUNITIES].map((value: keyof SkillsFields) => (
                    <li key={value}>
                      <a
                        className="link"
                        href={`https://verolearning.com/free-resources/${mapVariableToReadableString(
                          value,
                        )}`}
                        target="_blank"
                      >
                        {mapVariableToReadableString(value)}
                      </a>
                    </li>
                  ))
                ) : (
                  <li>None</li>
                )}
              </ul>
              <p className="text-sm sm:text-xl text-[#89D2F5] mt-6 font-bold">Opportunities</p>
              <img
                className="absolute -left-5 lg:-left-7 -bottom-7 scale-75 md:scale-100"
                src={opportunities}
                alt=""
              />
            </div>
            <div className="swot-card border-[#E72F79] bg-[#FFF2F7] grid content-end">
              <ul className="text-sm lg:text-base font-semibold leading-7 lg:leading-9">
                {swot?.[SWOTCategory.THREATS].length ? (
                  swot[SWOTCategory.THREATS].map((value: keyof SkillsFields) => (
                    <li key={value}>
                      <a
                        className="link"
                        href={`https://verolearning.com/free-resources/${mapVariableToReadableString(
                          value,
                        )}`}
                        target="_blank"
                      >
                        {mapVariableToReadableString(value)}
                      </a>
                    </li>
                  ))
                ) : (
                  <li>None</li>
                )}
              </ul>
              <p className="text-sm sm:text-xl text-[#E52F77] mt-6 font-bold">Threats</p>
              <img
                className="absolute -right-5 lg:-right-7 -bottom-7 scale-75 md:scale-100"
                src={threats}
                alt=""
              />
            </div>
            <div className="theme-gradient lg:h-42 lg:w-42 h-28 w-28 p-4 rounded-full shadow-xl border-white border-4 absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] flex items-center">
              <img className="m-auto" src={logo_white} alt="" />
            </div>
          </div>
        </div>
        {isNoteVisible ? (
          <h4 className=" mt-10 text-sm font-semibold italic text-gray-600">
            *For a Full detailed report please download the report on a desktop or laptop.
          </h4>
        ) : null}
      </div>
    </>
  );
};
