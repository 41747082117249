/**
 * custom logger
 *
 * @todo conditionally log messages based on log level
 * @todo send error messages to error monitoring software
 */
class Logger {
  /**
   * logs a `trace` level message
   */
  trace(message: string, ...args: unknown[]): void {
    console.trace(message, ...args);
  }

  /**
   * logs a `debug` level message
   */
  debug(message: string, ...args: unknown[]): void {
    console.debug(message, ...args);
  }

  /**
   * logs a `info` level message
   */
  info(message: string, ...args: unknown[]): void {
    console.info(message, ...args);
  }

  /**
   * logs a `error` level message
   */
  error(message: string, error: Error | unknown, ...args: unknown[]): void {
    console.error(message, error, ...args);
  }
}

const logger: Logger = new Logger();
export default logger;
