import character from '../../../../assets/images/courses/jumpstart-communicator/chapter3/character.png';
import chat_background from '../../../../assets/images/courses/jumpstart-communicator/chapter3/chatBg.jpg';
import introImg from '../../../../assets/images/courses/jumpstart-communicator/chapter3/intro.jpeg';
import chat_character from '../../../../assets/images/courses/jumpstart-communicator/chapter3/intro.png';
import { CourseChapter } from '../../../../courses/courses.types';
import { course_chat_questions as CourseChatQuestions } from '../../../../courses/jumpstart-communicator/jumpstart-communicator.quiz';
import { CustomAccordion } from '../../../common/Accordion';
import { ActionableBlock } from '../../ActionableBlock';
import { InfoBlock } from '../../InfoBlock';
import { MediaBlock } from '../../MediaBlock';
interface JumpStartChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const JumpStartChapter3: React.FC<JumpStartChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const courseChatQuestions = CourseChatQuestions.filter((obj) => obj.chapter_id === chapter.id);
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const accordionData = [
    {
      id: 'panel1',
      title: 'The Sender is unclear.',
      content:
        'If the sender delivers an ambiguous, vague, or confusing message, the receiver might leave with the wrong interpretation. For example, consider how you might interpret this message from your boss: "The project is due next week." If your boss means "Monday," but you think he means "Friday," the communication has failed.',
    },
    {
      id: 'panel2',
      title: 'The message isn’t delivered through the most effective medium',
      content:
        'Depending on your message, different mediums will work better than others. For example, you may intend to add a bit of levity to an email by including sarcasm. However, an email doesn’t convey your tone of voice and body language. As a result, the receiver may misinterpret your playful sentence as something much more serious.',
    },
    {
      id: 'panel3',
      title: 'The message simply isn’t delivered.',
      content:
        'Have you ever had an email delivered to the spam folder by mistake? Or a text message show up two days later? If a message is never received, communication breaks down. That is why important messages should be conveyed in person or over the phone. ',
    },
    {
      id: 'panel4',
      title: 'The receiver is distracted',
      content:
        'Have you ever tried to give instructions to a friend while they’re immersed in a video game? Or wondered if the coworker who’s staring at her computer screen while saying “uh-huh” is really understanding your directions? Even if your message is clear, the receiver could miss important details (or all the details!) if they’re distracted.',
    },
    {
      id: 'panel5',
      title: 'The receiver can’t understand the information',
      content:
        'You just received a text message from a coworker, and it’s full of acronyms you’ve never seen before. You conduct a web search to identify the acronyms but come up empty. This moment is a great opportunity for you, as the receiver, to seek clarification from the sender.',
    },
  ];
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Why Does Communication Fail?</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock mediaImage={introImg}>
          <div>
            <p className="lg:w-3/4 text-2xl leading-10">
              “The kinds of errors that cause plane crashes are invariably errors of teamwork and
              communication.”
            </p>
            <p className="text-base mt-3">~ Malcolm Gladwell, best-selling author and journalist</p>
          </div>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
          Root Causes of Communication Fails
        </h3>
        <p className="text-base mb-8 font-medium">
          When communication works, both the sender and the receiver interpret the message the same
          way. If there’s an issue with any part of the communication process the communication may
          fail. Click through the tabs below to learn some of the more common reasons why
          communication can break down.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <div className="flex mb-16 mt-12">
          <div>
            <img className="" src={character} alt="" />
          </div>
          <div className="ml-5 w-3/4">
            <p className="text-xl font-normal">
              “The single biggest problem in communication is the illusion that it has taken place.”
            </p>
            <p className="text-sm font-semibold mt-4">George Bernard Shaw</p>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">Can You Help Emily?</h3>
        <p className="text-base mb-8 font-medium">
          Knowing the main ways communication fails can help identify the problem when your message
          doesn't land. Test your knowledge of communication problems and solutions in the scenario
          below.
        </p>

        <ActionableBlock
          backgroundImage={chat_background}
          courseChatQuestions={courseChatQuestions}
          characterImage={chat_character}
          feedbackTitle="Give Feedback"
          feedbackText="If you’re unable to interpret a message, ask for clarification by giving feedback and sending a message of your own. That will help you confirm that you and the sender are on the same page. "
          buttonName="Continue"
          cssClass="jumpstart-systemchat"
        />

        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">Summary</h3>
        <p className="text-base mb-8 font-medium">
          Let's do a quick review. Communication breakdown is likely to happen if:
        </p>
        <ul className="list-disc pl-5 text-base">
          <li className="mb-3">The sender delivers a vague or unclear message.</li>
          <li className="mb-3">The message isn’t delivered through the most effective medium.</li>
          <li className="mb-3">The message doesn’t make it to the receiver.</li>
          <li className="mb-3">The receiver is distracted.</li>
          <li className="mb-3">
            The receiver misunderstands the information and doesn’t give the sender feedback.
          </li>
        </ul>
        <p className="text-base italic mt-7 mb-10">
          Identifying these common roadblocks is key to avoiding them.
        </p>
        <InfoBlock
          title="Sender tip."
          text="placing yourself in the receiver’s shoes. If you were receiving this message, how would
            you answer the following two questions: “Is the message clear and straightforward?” and
            “What’s my overall interpretation of this message?”"
        />
        <p className="text-2xl leading-10 text-center font-light mb-10">
          Most job descriptions say “{' '}
          <span className="font-bold">Excellent communication skills required.</span> ” Click below
          to learn why communication skills impact your career success.
        </p>
        <button onClick={handleNextChapterClick} className="btn-primary w-full">
          WHY COMMUNICATION SKILLS MATTER
        </button>
      </div>
    </>
  );
};
