import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { getPreEmploymentData } from '../../redux/slice/organization-dashboard/organization-api';
import {
  OrganizationProps,
  preEmploymentColumns,
  PreEmploymentUserData,
} from '../../redux/slice/organization-dashboard/organization.types';
import { GenericDataTable } from '../common/GenericDataTable';

export const PreEmploymentStats: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  const [filters, setFilters] = useState({
    name: '',
    email: '',
  });

  const [data, setData] = useState<PreEmploymentUserData[]>([]);
  const [loading, setLoading] = useState(true);

  const limit = 100; // Adjust as needed

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = orgnizationId && (await getPreEmploymentData(orgnizationId, limit));
        if (result) {
          setData(result?.employeeData ?? []);
        }
      } catch (error) {
        console.error('Error fetching pre-employment data:', { error });
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [orgnizationId, limit]);

  const handleFilterChange = (newFilters: Record<string, string>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const filteredData = data.filter((user) => {
    return (
      (!filters.name || user?.name?.toLowerCase().includes(filters.name)) &&
      (!filters.email || user?.email?.toLowerCase().includes(filters.email))
    );
  });

  return (
    <>
      <Typography
        variant="h6"
        fontWeight="bold"
        className="border-purple-900 border-b-2 rounded-lg  mb-4 mt-4 text-purple-700 "
        gutterBottom
      >
        Pre-Employment Data (% completed)
      </Typography>
      <GenericDataTable
        isLoading={loading}
        columns={preEmploymentColumns}
        data={filteredData}
        filterFields={[
          { name: 'name', label: 'Filter by Name' },
          { name: 'email', label: 'Filter by Email' },
        ]}
        onFilterChange={handleFilterChange}
      />
    </>
  );
};
