import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useEffect, useState } from 'react';

dayjs.extend(duration);
type TimerObject = {
  days: number | null;
  hours: number | null;
  minutes: number | null;
  seconds: number | null;
};

const calculateTimeLeft = (endTime: dayjs.Dayjs): TimerObject => {
  const now = dayjs();
  const difference = endTime?.diff(now);
  let timeLeftObject: TimerObject = { days: null, hours: null, minutes: null, seconds: null };
  if (difference > 0) {
    const duration = dayjs.duration(difference);
    timeLeftObject = {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }
  return timeLeftObject;
};

const UpgradeTimer: React.FC = () => {
  const initialTime = dayjs().add(2, 'day');

  const getStoredEndTime = () => {
    const storedEndTime = localStorage.getItem('endTime');
    return storedEndTime ? dayjs(storedEndTime) : initialTime;
  };

  const [endTime, setEndTime] = useState(getStoredEndTime);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(getStoredEndTime()));

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(endTime);
      setTimeLeft(newTimeLeft);
      if (Object.values(newTimeLeft).filter((data) => data === null).length == 4) {
        const newEndTime = dayjs().add(2, 'day'); // Resetting the timer to 2 days if time is 0
        setEndTime(newEndTime);
        localStorage.setItem('endTime', newEndTime.toISOString());
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [endTime]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const storedEndTime = getStoredEndTime();
        setEndTime(storedEndTime);
        setTimeLeft(calculateTimeLeft(storedEndTime));
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('endTime', endTime.toISOString());
  }, [endTime]);

  const formatTime = (time: number | null) => {
    return time !== null && time < 10 ? `0${time}` : time;
  };

  return (
    <div className="flex items-center gap-2 justify-center text-purple-700 md:text-6xl text-2xl font-bold my-5">
      <div className="bg-purple-200 md:h-28 md:w-24 h-16 w-12 flex items-center justify-center rounded-xl">
        {formatTime(timeLeft?.days) ?? `00`}
      </div>
      :
      <div className="bg-purple-200 md:h-28 md:w-24 h-16 w-12 flex items-center justify-center rounded-xl">
        {formatTime(timeLeft?.hours) ?? `00`}
      </div>
      :
      <div className="bg-purple-200 md:h-28 md:w-24 h-16 w-12 flex items-center justify-center rounded-xl">
        {formatTime(timeLeft?.minutes) ?? `00`}
      </div>
      :
      <div className="bg-purple-200 md:h-28 md:w-24 h-16 w-12 flex items-center justify-center rounded-xl">
        {formatTime(timeLeft?.seconds) ?? `00`}
      </div>
    </div>
  );
};

export default UpgradeTimer;
