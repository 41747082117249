import discussionImg from '../../../../assets/images/courses/communication-styles/chapter1/discussionImg.jpg';
import introImg from '../../../../assets/images/courses/communication-styles/chapter1/introImg.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { MediaBlock } from '../../MediaBlock';

interface CommunicationStylesProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

export const CommunicationStylesChapter1: React.FC<CommunicationStylesProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">What is a Communication Style?</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock mediaImage={introImg}></MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Are We Speaking the Same Language?
        </h3>
        <p className="mb-7">
          Your friend asks for your design help on a project. After she gives you an overview of the
          project, you press for more details.
          <br />
          <br />
          <br />
          What’s the timeline? Where will this design live? What should it communicate? Your friend
          doesn’t seem too concerned with the details. She can tell you about the project goals—but
          that’s about it. After much back and forth, you are still not sure how you can help your
          friend.
          <br />
          <br />
          <br />
          So, what went wrong? The problem might be a difference in communication styles.
        </p>
        <p className="text-center text-2xl font-light mb-7">
          A communication style is the way in which people process information and share it with
          others
        </p>
        <p className="mb-7">
          Problems can occur when you’re working with someone who has a different communication
          style from your own. Operating with different communication styles can feel like speaking
          different languages and messages can get lost in translation.
        </p>
        <MediaBlock mediaImage={discussionImg}></MediaBlock>
        <p className="my-7 text-2xl font-light text-center">
          In this module, you’ll learn four communication styles and how to identify each. By
          identifying the style in which you and others communicate, you can learn to adapt your
          communication style to work with others more effectively.
        </p>
        <button onClick={handleNextChapterClick} className="w-full btn-primary">
          UNDERSTANDING DIFFERENT STYLES
        </button>
      </div>
    </>
  );
};
