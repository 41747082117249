import { useEffect } from 'react';

import Container from '../components/container/Container';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="text-center theme-gradient">
        <Container>
          <h1 className="font-semibold text-white py-6">
            Privacy <span className="font-light">Policy</span>
          </h1>
        </Container>
      </div>
      <Container className="pb-10">
        <p className="mt-10">
          The Company, Vero Learning LLC (hereinafter “the Company”) has created its Privacy Policy
          to inform users of the website related service including the Company’s online prototype,
          its content, social network, advertising, and promotional communications (collectively,
          the “Site”) in connection with use of the website. We will take reasonable steps to
          protect user privacy consistent with the guidelines set forth in our Privacy Policy.
        </p>
        <br />
        <p className="uppercase font-bold">1. WHAT INFORMATION DO WE COLLECT & HOW IS IT USED?</p>
        <br />
        <p className="">
          <span className="font-bold">Information You Voluntarily Submit to the Website:</span> We
          collect Personal Data from you when you voluntarily provide such information, such as when
          you contact us with inquiries, register for access to the Website, or purchase products or
          services from the Website. Personal Data may include, but is not limited to, the below:
        </p>
        <br />
        <p>● Your name and email address</p>
        <p>● Your age</p>
        <p>● Your address</p>
        <p>● Your prior work history, internships/work study</p>
        <p>● Your career and social interests</p>
        <br />
        <p>
          Our legal basis for processing this information is your consent, and by voluntarily
          providing us with Personal Data, you are consenting to our use of it in accordance with
          this Privacy Policy. If you provide Personal Data to us, you acknowledge and agree that
          such Personal Data may be transferred and stored from your current location to the offices
          and servers of Company and the authorized third parties referenced below.
        </p>
        <br />
        <p>
          <span className="font-bold">Information We Collect from Others:</span> We may receive
          information about you from other sources. For example, if you use third-party software
          through the site, they may transfer information to us for fulfillment. For more
          information about our use of third-party providers, please see below.
        </p>
        <br />
        <p>
          <span className="font-bold">Automatically-Collected Information:</span>
          We automatically collect certain information about you and the device with which you
          access Company. For example, when you use Company, we will log your IP address, operating
          system type, browser type, referring website, pages you viewed, and the dates/times when
          you accessed the website. We may also collect information about actions you take when
          using Company, such as links clicked.
        </p>

        <br />
        <p className="uppercase font-bold">2. HOW WE USE YOUR PERSONAL INFORMATION</p>
        <br />
        <p className="">
          Vero Learning and its subsidiaries and affiliates may use your personal information in the
          following ways:
        </p>
        <br />
        <ul className="list-disc pl-6">
          <li>To operate and maintain the website;</li>
          <li>
            To create your account, identify you as a user of the website, and customize the website
            to your account;
          </li>
          <li>
            To send you promotional information, such as newsletters. Each email newsletter will
            provide information on how to opt out of future mailings by unsubscribing;
          </li>
          <li>To conduct a preliminary analysis on participant</li>
          <li>
            To send you administrative communications, such as administrative emails, confirmation
            emails, technical notices, updates on policies, or security alerts;
          </li>
          <li>To respond to your comments or inquiries;</li>
          <li>To provide you with user support;</li>
          <li>To track and measure website performance;</li>
          <li>To process payment for purchases you make through Company</li>
          <li>To protect, investigate, and deter unauthorized or illegal activity;</li>
          <li>
            To review the effectiveness of our marketing programs and analyze other general
            demographic trends;
          </li>
          <li>To notify you of new information or services that may be of interest to you; or,</li>
          <li>To send promotional materials.</li>
          <li>To provide to Hiring Managers and Human Resources personnel</li>
        </ul>
        <br />
        <p className="uppercase font-bold">3. LEGAL BASIS FOR PROCESSING PERSONAL DATA</p>
        <br />
        <p className="">
          Below are the types of lawful basis that we will rely on to process your Personal Data:
        </p>
        <br />
        <ul className="list-disc pl-6">
          <li>
            Legitimate Interest means the interest of our business in conducting and managing our
            business to enable us to give you the best service/product and the best and most secure
            experience. We make sure we consider and balance any potential impact on you (both
            positive and negative) and your rights before we process your Personal Data for our
            legitimate interests. We do not use your Personal Data for activities where our
            interests are overridden by the impact on you (unless we have your consent or are
            otherwise required or permitted to by law). You can obtain further information about how
            we assess our legitimate interests against any potential impact on you in respect of
            specific activities by contacting us at info@verolearning.com
          </li>
          <li>
            Performance of Contract means processing your data where it is necessary for the
            performance of a contract to which you are a party or to take steps at your request
            before entering into such a contract.
          </li>
          <li>
            Comply with a legal or regulatory obligation means processing your Personal Data where
            it is necessary for compliance with a legal or regulatory obligation that we are
            subject.
          </li>
          <li>Consent means where you have consented to a certain use of your Personal Data.</li>
        </ul>
        <br />
        <div className="flex">
          <p className="font-bold mr-6">3.1</p>
          <p>
            Where we obtain the corresponding data subjects’ consent for processing their personal
            data outside of the jurisdiction of the United States, we agree that art. 6 paragraph 1
            point a of the EU General Data Protection Regulation (GDPR) shall serve as the legal
            basis.
          </p>
        </div>
        <br />
        <div className="flex">
          <p className="font-bold mr-6">3.2</p>
          <p>
            Where we need to process personal data for the purposes of fulfilling a contract, and
            the data subject is party to the contract, art. 6 paragraph 1 point b of the GDPR shall
            serve as the legal basis. This also applies to processing necessary to accommodate
            preparations for entering into a contract.
          </p>
        </div>
        <br />
        <div className="flex">
          <p className="font-bold mr-6">3.3</p>
          <p>
            Where processing of personal data is necessary for Company to fulfill a legal obligation
            that is within the jurisdiction of GDPR, art. 6 paragraph 1 point c of the GDPR shall
            serve as the legal basis.
          </p>
        </div>
        <br />
        <div className="flex">
          <p className="font-bold mr-6">3.4</p>
          <p>
            Where processing of personal data is necessary for protecting the vital interests of the
            data subject, or those of another individual, art. 6 paragraph 1 point d of the GDPR
            shall serve as the legal basis.
          </p>
        </div>
        <br />
        <div className="flex">
          <p className="font-bold mr-6">3.5</p>
          <p>
            Where processing is necessary to protect Company or a third party’s legitimate
            interests, and such interests are not overridden by the interests, fundamental rights
            and freedoms of the data subject, art. 6 paragraph 1 point f of the GDPR shall serves as
            the legal basis.
          </p>
        </div>
        <br />
        <div className="flex">
          <p className="font-bold mr-6">3.6</p>
          <p>
            By using the Site and our services you are consenting to the use of your personal
            information as set out in this Privacy Policy.
          </p>
        </div>
        <br />
        <div className="flex">
          <p className="font-bold mr-6">3.7</p>
          <p>
            Notwithstanding the foregoing, this page is in compliance with the California Consumer
            Privacy Act (CCPA), which act applies solely to Users who reside in the State of
            California.
          </p>
        </div>
        <br />
        <p>
          If you are a California resident, the CCPA provides you with the right to opt out of the
          sale of your personal information. The CCPA defines the “sale” of personal information as:
        </p>
        <br />
        <p>
          “Selling, renting, releasing, disclosing, disseminating, making available, transferring,
          or otherwise communicating orally, in writing, or by electronic or other means, a
          consumer's personal information by the business to another business or a third party for
          monetary or other valuable consideration.”
        </p>
        <br />
        <p>Consumer rights under the CCPA</p>
        <br />
        <p>The CCPA empowers residents of California with the following rights:</p>
        <br />
        <p>The right to be informed of personal information collection and CCPA rights</p>
        <br />
        <p>The right to access personal information that has been collected</p>
        <br />
        <p>The right to have collected information forgotten/deleted</p>
        <br />
        <p>The right to non-discrimination when privacy rights are exercised</p>
        <br />
        <p>The right to opt out of the sale of third-party information</p>
        <br />
        <p>
          Personal information does not include “publicly available” information. Publicly available
          information is not covered by the CCPA.
        </p>
        <br />
        <div className="flex">
          <p className="font-bold mr-6">3.8</p>
          <p>Consumer rights under the Canadian Anti Spam Legislation (CASL)</p>
        </div>
        <br />
        <p>
          For users in Canada, the privacy policy is compliant with the Canadian Anti Spam
          Legislation (CASL). From time to time, Company through its own marketing initiatives may
          send users updates concerning information regarding its Site, and other materials. The
          CASL establishes three primary rules 1. Senders must have consent from the recipient of
          the message before sending a CEM. 2. Senders must provide proper identification and
          contact information to the recipient in each CEM; and 3) senders must have a functioning
          unsubscribe mechanism in their CEMs. In the event that You as the User elect not to
          receive Company content to your CEM, You may Opt Out by replying “STOP” in the CEM message
          field.
        </p>
        <br />
        <p className="uppercase font-bold">4. CHANGES TO THE PRIVACY POLICY AND QUERIES</p>
        <br />
        <p>
          If we make changes to this Privacy Policy in the future, these will be posted on this page
          so please do check back from time to time. Any questions regarding our Privacy Policy
          should be directed to our Data Protection Officer. Please see the section ‘How to contact
          us’ below.
        </p>
        <br />
        <p className="uppercase font-bold">
          5. USE OF YOUR PERSONAL INFORMATION FOR MARKETING PURPOSES
        </p>
        <br />
        <p>
          (i) We may, with your agreement, give your personal information to other companies within
          the Company’s network for the purposes of providing products and services you have
          requested from us and so that those group companies can provide you with information
          relating to services and further info we think may interest you, by letter, email or on
          your mobile device.
        </p>
        <br />
        <p>
          (ii) If you do not wish to receive this promotional material, you may opt out at any time
          by:
        </p>
        <br />
        <ul className="list-disc pl-6">
          <li>
            Sending an e-mail or writing to us telling us you do not wish to receive further
            promotional material; or
          </li>
          <li>Using the "unsubscribe" process described in the promotional material.</li>
        </ul>
        <br />
        <p className="uppercase font-bold">
          6. DISCLOSURES OF PERSONAL INFORMATION TO THIRD PARTIES
        </p>
        <br />
        <p>
          (i) We will not disclose any personal information you provide to us to any third party
          organization outside Company , unless:
        </p>
        <br />
        <ul className="list-disc pl-6">
          <li>
            We need to do so to provide you with the products or services you select, for example
            where we use service providers to provide certain services in support of the Site;
          </li>
          <li>
            We are obliged or permitted by law to disclose it (for example if required by law or a
            court order);
          </li>
          <li>
            To protect the rights, property, or safety of Company, its customers, employees, or
            others. This includes exchanging information with other companies and organizations for
            the purposes of fraud protection and credit risk reduction; or
          </li>
          <li>
            The information is only in the form of aggregated statistics about visitors to the Site
            in order to describe our services to prospective partners, advertisers, sponsors and
            other reputable third parties and for other lawful purposes and which contain no
            personally identifiable information.
          </li>
        </ul>
        <br />
        <p className="uppercase font-bold">7. USE OF COOKIES AND SIMILAR TECHNOLOGY</p>
        <br />
        <p>
          (i) Cookies are text files stored by your browser software that identify your device to
          the cookie owner's server. We use cookies to remember you when you visit the Site or other
          services that we provide to you. There are two main types of cookies that we use:
        </p>
        <br />
        <ul className="list-disc pl-6">
          <li>
            'Session' cookies – where they only last for the duration of your browser session and
            expire when you close it.
          </li>
          <li>
            'Remember Me' cookies (aka “Persistent” cookies) - cookies that remain on your computer
            after you close your browser and computer to remember you when you return to the Site.
          </li>
        </ul>
        <br />
        <p>(ii) We use cookies to:</p>
        <br />
        <ul className="list-disc pl-6">
          <li>
            Obtain statistical information about your general internet usage and build up a profile
            of how you and others use the Site, and to improve our Site and deliver a better service
            to you;
          </li>
          <li>
            Remember your session so that you can move from one page to another within the Site;
          </li>
          <li>Store your preferences; and</li>
          <li>
            Customize elements of the layout and/or content of the pages of the Site for your
            preference and ease of use.
          </li>
          <li>We do not use cookies to store your credit or debit card details.</li>
        </ul>
        <br />
        <p>You can remove cookies from your Browser:</p>
        <br />
        <p>
          Most web browsers are set up to automatically accept cookies but, if you prefer, you may
          refuse to accept cookies by activating the setting on your browser which allows you to
          refuse the setting of cookies. However, if you select this setting, you may be unable to
          use the full functionality of the Site and your user experience may be adversely affected.
          You can learn more about cookies by visiting www.allaboutcookies.org which includes
          additional information on cookies and how to block cookies using different types of
          browser.
        </p>
        <br />
        <p>
          A simple way of removing cookies is to go to the “Tools” option in your web browser and
          select “Settings”. To delete all cookies on your computer to date select “Clear Browsing
          History” and to set options for future cookies select “Cookies” and the options you wish
          to apply to your web browser.
        </p>
        <br />
        <p className="font-bold">a) Our cookies</p>
        <br />
        <p>
          The majority of the cookies used on our Sites are used to record user preferences for
          repeat visitors and registered users. We use the following types of cookies on our Sites
          (please note that the exact cookies used on each Sites is dependent on the services
          provided): cookies-2017.png
        </p>
        <br />
        <p>If you have a concern about a particular cookie, please contact us.</p>
        <br />
        <p>
          Cookies are also set by third parties on the Site (such as Google Analytics, Google Maps
          and Google Ads).
        </p>
        <br />
        <p>
          We don't have access to these cookies, we only allow them to be served. However, we may
          use the anonymized statistical information provided to us by Google Analytics arising from
          these third party cookies, for example, to monitor usage of the Site or improve the
          targeting of advertisements to users of the Site.
        </p>
        <br />
        <p>
          The third parties that generate these cookies and action tags have their own privacy
          policies describing how they use the information that they collect. More information about
          cookies placed by specific third parties and how to restrict or block their cookies can be
          accessed by following the link to their website and to generate an "opt-out" cookie that
          will stop any further cookies being written to your device.
        </p>
        <br />
        <p>Useful links to the various Google services for instructions on how to opt out are:</p>
        <br />
        <p>• www.google.com/policies/privacy/</p>
        <br />
        <p>• tools.google.com/dlpage/gaoptout</p>
        <br />
        <p>• www.google.com/ads/preferences/plugin/</p>
        <br />
        <p>Applications (“Apps”)</p>
        <br />
        <p>
          Apps downloaded to your mobile phone or tablet may rely on location based services, such
          as your GPS coordinates to inform you about local forecasts. You may turn off this
          function within your mobile settings.
        </p>
        <br />
        <p>
          Analytical data may be collected from Apps. This is anonymous data from the App in your
          handset to monitor and optimize features on the handset. This does not identify your
          handset or you.
        </p>
        <br />
        <p>
          Cookies are not used within Apps unless your browser is opened as part of the App. In this
          case, there may be cookies on the phone web browser.
        </p>
        <br />
        <p>
          Vero Learning does not collect personal data unless you are required to provide your
          email, password, user name and address to receive any of our related subscriptions. This
          data is not given to any third parties and you will only be contacted for service updates.
        </p>
        <br />
        <p className="font-bold uppercase">8. LINKS TO OTHER WEBSITES</p>
        <br />
        <p>
          This Site may contain links to other websites which are outside our control and are not
          covered by this Privacy Policy. Please note that, if you access other websites using the
          links provided, the operators of these websites may collect information from you which
          will be used by them in accordance with their privacy policy, which may differ from ours.
        </p>
        <br />
        <p className="font-bold">9. STORAGE OF YOUR DATA</p>
        <br />
        <p>
          Information that you submit via the Site is sent to and stored on secure servers generally
          located in the United States and your information is processed in accordance with the data
          protection laws of the United States.
        </p>
        <br />
        <p className="font-bold">10. DATA SECURITY</p>
        <br />
        <p>
          All information you provide to us is stored on our secure servers or in secure filing
          systems.
        </p>
        <br />
        <p>
          Unfortunately, the transmission of information via the internet is not completely secure.
          Although we will do our best to protect your personal data, we cannot guarantee the
          security of your data transmitted to us via the internet; any transmission is at your own
          risk. Once we have received your information, we will use strict procedures and security
          features to try to prevent unauthorized access.
        </p>
      </Container>
    </>
  );
}
export default PrivacyPolicy;
