import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ClaudeLeviStrauss from '../../../../assets/images/courses/communication-basics/chapter5/ClaudeLeviStrauss.jpeg';
import chat_background from '../../../../assets/images/courses/communication-basics/chapter5/rosie/chat_background.jpeg';
import chat_character from '../../../../assets/images/courses/communication-basics/chapter5/rosie/chat_character.png';
import {
  course_chat_questions as CourseChatQuestions,
  course_sorting_questions as sortingQuestions,
} from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import { ShortingMatchingBase } from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { dispatchUpdateUserCourseQuestionResponse } from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { ActionableBlock } from '../../ActionableBlock';
import { MediaBlock } from '../../MediaBlock';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';
import { TabsBlock } from '../../TabsBlock';
interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const CommunicationBasicsChapter5: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const courseSortingQuestions = sortingQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );

  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingRetake = async (questionId: string) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const courseChatQuestions = CourseChatQuestions.filter((obj) => obj.chapter_id === chapter.id);
  const showYoureListeningTabData = [
    {
      title: 'ENDING A CONVERSATION',
      htmlContent: `A closed-ended question is great for ending a conversation. Let’s say you’re helping a customer. To wrap up the discussion, you might conclude with, “Is there anything else you need?”`,
    },
    {
      title: 'GETTING A STRAIGHTFORWARD ANSWER',
      htmlContent: `Have you ever asked a question and received an overly vague or confusing response? A closed-ended question can remove ambiguity and lead to a straightforward answer. For instance, if you ask, “Do you agree?” the receiver has only two options for a reply (“yes” or “no”) and they’re both definitive.`,
    },
    {
      title: 'CONTROLLING A DISCUSSION',
      htmlContent: `Control and focus a discussion by asking a closed-ended question. Let’s say you’re ordering new office pens. If you’re deciding between blue or black ink, you might ask your team, “What color would you prefer, blue or black?” In this case, you’re promoting specific answers. No one will answer “red” or “purple.” `,
    },
  ];

  useEffect(() => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userSortingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Asking Closed Versus Open Questions</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-16">
          Great questions are an important part of effective communication. The questions we ask
          shape our conversations. They can help us zero-in on a single point or take our
          discussions in new, unexpected directions. Questions can be closed-ended and require a
          simple answer. Or, they can be open-ended and exploratory.
          <br />
          <br />
          The question type you use, closed or open, depends on your objective.
        </p>
        <p className="mb-8">
          In this lesson, you’ll learn when to use each question type and explore examples to
          strengthen your understanding.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/AFXfa8jcN_ZH5f74wUC4NcBGgrxm353L/svVmBcGK/assets/rbc/1_Bmuzic4jrM7eeA_COMM-VC-04.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">What Are Closed Questions?</h3>
        <p className="mb-8">
          Closed questions are answered with a short phrase or a single word. For example, you might
          ask, “Are you hungry?” and the receiver will respond with “yes” or “no.”
          <br />
          <br />
          Closed questions usually begin with “is,” “do,” “where,” “when,” and “have,” and don’t
          inspire a flexible response. Think of closed questions like a multiple choice quiz: They
          have a limited number of possible answers. As a result, you can expect a concise reply.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          When to Use Closed Questions
        </h3>
        <p className="mb-12">
          Depending on your objective, a closed-ended question might be the best choice. Let’s look
          at situations where closed-ended questions are useful. Click each tab to learn more.
        </p>
        <TabsBlock tabsData={showYoureListeningTabData} />
        <MediaBlock mediaImage={ClaudeLeviStrauss}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            The wise man doesn’t give the right answers; he poses the right questions.
            <br />
            <span className="text-base">Claude Levi-Strauss</span>
          </p>
        </MediaBlock>
        <div className="bg-purple-100 p-5 rounded-3xl mt-10">
          <h4 className="text-xl text-purple-600 font-bold mb-4">What Are Open Questions?</h4>
          <p>
            Open questions are broad. When you ask an open question, your receiver has an unlimited
            number of ways to respond. For example, if you ask, “What are your hobbies?” the other
            person can take the conversation in any direction.
            <br />
            <br />
            Open questions usually begin with “how,” “what,” “why,” “describe,” and “explain,” and
            they typically generate a discussion.
          </p>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">When to Use Open Questions</h3>
        <p>Let’s look at some instances when open-ended questions are useful.</p>
        <ul className="list-decimal pl-7 mt-8 mb-10 text-xl">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Opening a conversation.</span> Open-ended questions are
            great conversation starters. Often, these questions will help someone open up. For
            example, you might ask a coworker, “What are you doing this weekend?” Next thing you
            know, you’re both discussing shared interests and favorite activities.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Getting an in-depth answer.</span> An open-ended question
            will invite your receiver to share an in-depth answer such as a story, opinion, or idea.
            These questions are helpful when you need more details about someone or something
            they’ve said. For instance, if a customer says, “I don’t like the new product,” you
            could get more information by asking, “What changes would you like to see?”
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Creating a spontaneous discussion.</span> Open-ended
            questions put the conversation in your receiver’s hands, often taking the discussion in
            unplanned directions. These questions are great when brainstorming. If you ask, “What
            can we do better?” you’re likely to hear unique ideas and strategies. If you’re looking
            to discover something new and unexpected, choose an open-ended question.
          </li>
        </ul>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          Closed and Open Questions in Action
        </h3>
        <p className="mb-8">
          While closed questions will give you a short, straightforward answer, open questions will
          lead to a more in-depth response. Considering that distinction, can you match each
          question to the right category?
        </p>
        <DndProvider backend={HTML5Backend}>
          <CourseSortingQuestionBlock // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetake={handleSortingRetake}
            question={courseSortingQuestions[0]}
            submitedCorrectAnswerCount={
              userCourseQuestionResponseContext[courseSortingQuestions[0].course_id]?.chapters[
                chapter.id
              ]?.sortingQuestions
                ?.filter((x) =>
                  Object.keys(x).filter((key) => key === courseSortingQuestions[0].id),
                )
                .map(
                  (x) =>
                    x[courseSortingQuestions[0].id].filter(
                      (x) => x.answer?.id === x.correct_answer?.id,
                    ).length,
                )[0] ?? null
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            submitSortingQuestion={handleSortingExcerciseCompleted}
          />
        </DndProvider>
        <p>
          <br />
        </p>
        <ActionableBlock
          backgroundImage={chat_background}
          courseChatQuestions={courseChatQuestions}
          characterImage={chat_character}
          feedbackTitle="When Brainstorming, Ask Open-Ended Questions"
          feedbackText="If you’re hoping to have an in-depth discussion, ask open-ended questions that elicit more than a short “yes” or “no” answer."
          buttonName="Continue"
        />
        <p className="text-2xl text-center leading-10 font-light mb-10">
          Ending a conversation can be tricky. In the next lesson, we'll take a look at how to
          gracefully wrap things up without sounding rude or offensive.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          ENDING A CONVERSATION POSITIVELY
        </button>
      </div>
    </>
  );
};
