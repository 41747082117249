import { Container } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export const FreeResources: React.FC = () => {
  const { resourceName } = useParams();

  return (
    <>
      <div className="text-center theme-gradient">
        <Container>
          <h1 className="font-semibold text-white py-6">
            Free <span className="font-light">Resources</span>
          </h1>
        </Container>
      </div>
      <Container className="pb-50">
        <p className="mt-10">
          We understand that career exploration can be overwhelming. As you prepare for the next
          steps, we have also included a complied list of free resources to help you increase your
          employability..
        </p>
        <br />
        {(resourceName === 'Communication' || !resourceName) && (
          <div>
            <p className="uppercase font-bold">Communication</p>
            <br />
            <ul className="list-disc pl-6 pb-10">
              <li>
                <Link
                  target="_blank"
                  className="link"
                  to="https://evolveme.asa.org/media-embed/331?deeplink=true"
                >
                  https://evolveme.asa.org/media-embed/331?deeplink=true
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://www.edx.org/learn/business-administration/fullbridge-communication-skills-and-teamwork-2
"
                >
                  https://www.edx.org/learn/business-administration/fullbridge-communication-skills-and-teamwork-2
                </Link>
              </li>
            </ul>
          </div>
        )}
        {(resourceName === 'Teamwork' || !resourceName) && (
          <div>
            <p className="uppercase font-bold">Teamwork</p>
            <br />
            <ul className="list-disc pl-6 pb-10">
              <li>
                <Link
                  target="_blank"
                  className="link"
                  to="https://evolveme.asa.org/task/70?deeplink=true"
                >
                  https://evolveme.asa.org/task/70?deeplink=true
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://www.coursera.org/learn/teamwork-skills-effective-communication"
                >
                  https://www.coursera.org/learn/teamwork-skills-effective-communication
                </Link>
              </li>
            </ul>
          </div>
        )}
        {(resourceName === 'Motivation' || !resourceName) && (
          <div>
            <p className="uppercase font-bold">Motivation and initiative </p>
            <br />
            <ul className="list-disc pl-6 pb-10">
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://alison.com/course/motivation-power-guide-to-motivating-yourself-and-others
"
                >
                  https://alison.com/course/motivation-power-guide-to-motivating-yourself-and-others
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://www.edx.org/learn/workplace-wellness/university-of-canterbury-performance-and-motivation-at-work"
                >
                  https://www.edx.org/learn/workplace-wellness/university-of-canterbury-performance-and-motivation-at-work
                </Link>
              </li>
            </ul>
          </div>
        )}
        {(resourceName === 'Planning' || !resourceName) && (
          <div>
            <p className="uppercase font-bold">Planning and organizing</p>
            <br />
            <ul className="list-disc pl-6 pb-10">
              <li>
                <Link
                  target="_blank"
                  className="link"
                  to="https://www.udemy.com/course/modern-productivity/"
                >
                  https://www.udemy.com/course/modern-productivity/
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  className="link"
                  to="https://evolveme.asa.org/media-embed/330?deeplink=true"
                >
                  https://evolveme.asa.org/media-embed/330?deeplink=true
                </Link>
              </li>
            </ul>
          </div>
        )}
        {(resourceName === 'Problem Solving' || !resourceName) && (
          <div>
            <p className="uppercase font-bold">Problem solving & decision making</p>
            <br />
            <ul className="list-disc pl-6 pb-10">
              <li>
                <Link
                  target="_blank"
                  className="link"
                  to="https://evolveme.asa.org/task/362?deeplink=true"
                >
                  https://evolveme.asa.org/task/362?deeplink=true
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://www.coursera.org/learn/ask-questions-make-decisions
"
                >
                  https://www.coursera.org/learn/ask-questions-make-decisions
                </Link>
              </li>
            </ul>
          </div>
        )}
        {(resourceName === 'Professionalism' || !resourceName) && (
          <>
            <div>
              <p className="uppercase font-bold">Professionalism and work ethic</p>
              <br />
              <ul className="list-disc pl-6 pb-10">
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://alison.com/course/professionalism-in-the-office
"
                  >
                    https://alison.com/course/professionalism-in-the-office
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://www.linkedin.com/learning/mistakes-you-should-avoid-at-work
"
                  >
                    https://www.linkedin.com/learning/mistakes-you-should-avoid-at-work
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <p className="uppercase font-bold">Job seeking skills</p>
              <br />
              <ul className="list-disc pl-6 pb-10">
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://www.linkedin.com/learning/nano-tips-for-working-with-recruiters-with-sho-dewan/different-types-of-recruiters"
                  >
                    https://www.linkedin.com/learning/nano-tips-for-working-with-recruiters-with-sho-dewan/different-types-of-recruiters
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    className="link"
                    to="https://www.coursera.org/learn/career-planning"
                  >
                    https://www.coursera.org/learn/career-planning{' '}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    className="link"
                    to="https://evolveme.asa.org/task/360?deeplink=true"
                  >
                    https://evolveme.asa.org/task/360?deeplink=true
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://www.udemy.com/course/the-eparachute-introduction-to-your-career-and-job-search/"
                  >
                    https://www.udemy.com/course/the-eparachute-introduction-to-your-career-and-job-search/{' '}
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <p className="uppercase font-bold">Self marketing package</p>
              <br />
              <ul className="list-disc pl-6 pb-10">
                <li>
                  <Link
                    target="_blank"
                    className="link"
                    to="https://www.coursera.org/learn/how-to-write-a-resume"
                  >
                    https://www.coursera.org/learn/how-to-write-a-resume{' '}
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://www.coursera.org/projects/create-resume-cover-letter-google-docs"
                  >
                    https://www.coursera.org/projects/create-resume-cover-letter-google-docs
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://www.linkedin.com/learning/writing-a-cover-letter"
                  >
                    https://www.linkedin.com/learning/writing-a-cover-letter
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    className="link"
                    to="https://www.livecareer.com/resume-templates/teen"
                  >
                    https://www.livecareer.com/resume-templates/teen{' '}
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <p className="uppercase font-bold">Job interview skills</p>
              <br />
              <ul className="list-disc pl-6 pb-10">
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://www.coursera.org/specializations/english-interview-resume
"
                  >
                    https://www.coursera.org/specializations/english-interview-resume
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://www.linkedin.com/learning/nano-tips-for-mastering-job-interviews-with-diana-yk-chan/how-to-prepare-for-a-job-interview
"
                  >
                    https://www.linkedin.com/learning/nano-tips-for-mastering-job-interviews-with-diana-yk-chan/how-to-prepare-for-a-job-interview
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://www.udemy.com/course/complete-job-interview-skills-with-real-life-examples/
"
                  >
                    https://www.udemy.com/course/complete-job-interview-skills-with-real-life-examples/
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://evolveme.asa.org/task/93?deeplink=true
"
                  >
                    https://evolveme.asa.org/task/93?deeplink=true
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <p className="uppercase font-bold">Work attitude</p>
              <br />
              <ul className="list-disc pl-6 pb-10">
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://www.udemy.com/course/attitudeisaltitude-excel-in-personal-professional-life/"
                  >
                    https://www.udemy.com/course/attitudeisaltitude-excel-in-personal-professional-life/
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    className="link"
                    to="https://evolveme.asa.org/task/829?deeplink=true"
                  >
                    https://evolveme.asa.org/task/829?deeplink=true
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://www.udemy.com/course/work-ethics/
"
                  >
                    https://www.udemy.com/course/work-ethics/
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://alison.com/course/organizational-behaviour-understanding-attitude-emotions-and-motivation"
                  >
                    https://alison.com/course/organizational-behaviour-understanding-attitude-emotions-and-motivation
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <p className="uppercase font-bold">Career management</p>
              <br />
              <ul className="list-disc pl-6 pb-10">
                <li>
                  <Link
                    target="_blank"
                    className="link"
                    to="https://evolveme.asa.org/task/459?deeplink=true"
                  >
                    https://evolveme.asa.org/task/459?deeplink=true
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    target="_blank"
                    to="https://alison.com/course/how-to-launch-a-successful-career
"
                  >
                    https://alison.com/course/how-to-launch-a-successful-career
                  </Link>
                </li>
              </ul>
            </div>
          </>
        )}
        {(resourceName === 'Culture' || !resourceName) && (
          <div>
            <p className="uppercase font-bold">Global / Intercultural Perspective - Culture</p>
            <br />
            <ul className="list-disc pl-6 pb-10">
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://www.coursera.org/learn/cultural-intelligence
"
                >
                  https://www.coursera.org/learn/cultural-intelligence
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://www.edx.org/learn/business-management/the-london-school-of-economics-and-political-science-intercultural-communication-and-management"
                >
                  https://www.edx.org/learn/business-management/the-london-school-of-economics-and-political-science-intercultural-communication-and-management
                </Link>
              </li>
            </ul>
          </div>
        )}
        {(resourceName === 'Technology' || !resourceName) && (
          <div>
            <p className="uppercase font-bold">Digital technology</p>
            <br />
            <ul className="list-disc pl-6 pb-10">
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://www.coursera.org/learn/intelligence-tools-digital-age"
                >
                  https://www.coursera.org/learn/intelligence-tools-digital-age
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://www.udacity.com/course/how-to-use-chatgpt--cd13054"
                >
                  https://www.udacity.com/course/how-to-use-chatgpt--cd13054
                </Link>
              </li>
            </ul>
          </div>
        )}
        {(resourceName === 'Reliability' || !resourceName) && (
          <div>
            <p className="uppercase font-bold">Reliability and adaptability</p>
            <br />
            <ul className="list-disc pl-6 pb-10">
              <li>
                <Link
                  target="_blank"
                  className="link"
                  to="https://www.udemy.com/course/cultivating-adaptability/"
                >
                  https://www.udemy.com/course/cultivating-adaptability/
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://esoftskills.com/courses/adaptability-training/
"
                >
                  https://esoftskills.com/courses/adaptability-training/
                </Link>
              </li>
            </ul>
          </div>
        )}
        {(resourceName === 'Stress Tolerance' || !resourceName) && (
          <div>
            <p className="uppercase font-bold">Stress tolerance</p>
            <br />
            <ul className="list-disc pl-6 pb-10">
              <li>
                <Link
                  target="_blank"
                  className="link"
                  to="https://evolveme.asa.org/task/83?deeplink=true"
                >
                  https://evolveme.asa.org/task/83?deeplink=true
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  target="_blank"
                  to="https://www.edx.org/executive-education/stanford-university-employee-wellness-and-stress-management"
                >
                  https://www.edx.org/executive-education/stanford-university-employee-wellness-and-stress-management
                </Link>
              </li>
            </ul>
          </div>
        )}
      </Container>
    </>
  );
};
