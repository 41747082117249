import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { FeedbackSurvey } from '../components/SWOT/FeedbackSurvey';
import { Greetings } from '../components/SWOT/Greetings';
import { Resources } from '../components/SWOT/Resources';
import { SWOTAnalysis } from '../components/SWOT/SWOTAnalysis';
import { SWOTHeader } from '../components/SWOT/SWOTHeader';
import { SWOTReport } from '../components/SWOT/SWOTReport';
//import { usePreEmploymentAssessmentContext } from '../hooks/use-pre-employment-assessment.hooks';
//import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
//import { CustomUser } from '../redux/slice/auth/CustomUser';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../hooks/use-pre-employment-assessment.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import {
  dispatchUpsertPreEmploymentAssessment,
  //PreEmploymentAssessmentStatus,
} from '../redux/slice/pre-employment-assessment';
//import { PreEmploymentAssessmentState } from '../redux/slice/pre-employment-assessment/pre-employment-assessment.slice';
import { dispatchGetSWOTReportValues } from '../redux/slice/swot/swot.operations';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';
//import logger from '../services/logger';

export const SWOT: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { componentName } = useParams();
  const [step, setStep] = useState(1);
  const userProfileContext = useUserProfileContext();
  const { organization, email: userEmail } = useIntakeFormContext();
  const {
    activeStepUrl,
    isSWOTGenerated,
    status: preEmploymentAssessmentStatus,
    didCompleteSkills,
  } = usePreEmploymentAssessmentContext();
  //const user: CustomUser | null = useAuthContext();
  const handleBackButton = () => {
    navigate(`/swot/step${step - 1}`);
  };
  useEffect(() => {
    void dispatch(dispatchUpsertPreEmploymentAssessment());
    void dispatch(dispatchGetSWOTReportValues());
    void dispatch(dispatchGetUserProfileValues());
    void dispatch(dispatchGetIntakeFormValues());
  }, []);

  useEffect(() => {
    if (
      Object.keys(userProfileContext).length &&
      !userProfileContext?.isPremium &&
      userProfileContext?.email &&
      userEmail &&
      preEmploymentAssessmentStatus &&
      !organization &&
      !didCompleteSkills
    ) {
      activeStepUrl && activeStepUrl.includes('skills')
        ? navigate(activeStepUrl)
        : isSWOTGenerated
        ? navigate('/upgrade')
        : navigate('/pre-employment/start');
    } else if (
      Object.keys(userProfileContext).length &&
      userProfileContext?.email &&
      userEmail &&
      organization &&
      preEmploymentAssessmentStatus &&
      !didCompleteSkills
    ) {
      activeStepUrl && activeStepUrl.includes('skills')
        ? navigate(activeStepUrl)
        : navigate('/pre-employment/start');
    }
  }, [organization, userProfileContext, userEmail, preEmploymentAssessmentStatus]);
  //const preEmploymentAssessment: PreEmploymentAssessmentState = usePreEmploymentAssessmentContext();

  // useEffect(() => {
  //   if (Object.keys(preEmploymentAssessment).length) {
  //     if (
  //       preEmploymentAssessment.status !== PreEmploymentAssessmentStatus.COMPLETE &&
  //       !preEmploymentAssessment.didCompleteExperience &&
  //       !user?.claims.isAsaUser
  //     ) {
  //       logger.debug('Invalid pre-employment assessment status.', {
  //         preEmploymentAssessment,
  //       });
  //       navigate('/pre-employment/start');
  //     }
  //   }
  // }, [navigate, preEmploymentAssessment]);

  useEffect(() => {
    if (!componentName) {
      navigate('/swot/step1');
    } else {
      setStep(parseInt(componentName?.replace('step', '') || '1'));
    }
    window.scrollTo(0, 0);
  }, [componentName]);

  const renderFormStep = () => {
    switch (componentName) {
      case 'step1':
        return <Greetings />;
      case 'step2':
        return <SWOTAnalysis />;
      case 'step3':
        return <SWOTReport />;
      case 'step4':
        return <FeedbackSurvey onBack={handleBackButton} />;
      case 'step5':
        return <Resources />;
      default:
        return null;
    }
  };

  return (
    <>
      <section className={`steps relative swot-step`}>
        <Container>
          <SWOTHeader step={step}>
            <>{renderFormStep()}</>
          </SWOTHeader>
        </Container>
      </section>
    </>
  );
};

export const SWOTPage: React.FC = () => {
  return <AuthenticatedPage isSwotPage={true} render={() => <SWOT />} />;
};
