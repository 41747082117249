import character from '../../../../assets/images/courses/jumpstart-communicator/chapter2/character.png';
import introImg from '../../../../assets/images/courses/jumpstart-communicator/chapter2/intro.jpeg';
import { CourseChapter } from '../../../../courses/courses.types';
import { MediaBlock } from '../../MediaBlock';
import { TextBlock } from '../../TextBlock';

interface JumpStartChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const JumpStartChapter2: React.FC<JumpStartChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">What Is Communication?</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock mediaImage={introImg}>
          <p className="lg:w-3/4 text-2xl leading-10">
            If we speak clearly, share our messages through appropriate mediums, and listen
            attentively, the communication process usually works great. But, as you’ve probably
            already experienced, that doesn’t always happen.
          </p>
        </MediaBlock>
        <p className="text-2xl text-center mt-7 mb-8 leading-10 font-light">
          Sometimes the sender gives unclear information, the message isn’t delivered through the
          most effective medium, or the receiver struggles to understand the details and fails to
          seek clarification. As a result, the communication process can break down, and there’s a
          misunderstanding.
        </p>
        <p className="text-base font-semibold">
          In this lesson, you’ll learn how and why this happens. We’ll demonstrate how communication
          is a process involving a sender, a message, and a receiver. We also demonstrate how
          communication can fail if that process breaks down. Click the play button to watch the
          video.
        </p>
        <div className="video-wrap mt-8 mb-12">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/-wzOUKIBklw6zeacpgA1J598HusNp3yw/CIZRiXpD/assets/rbc/PuwU5q56nlrJyvwY_COMM-FND-01.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mb-1">What Is Communication?</h3>
        <p className="text-base mb-8">
          Communication is the process of sharing information between two or more people. Among
          other things, information can take the form of requests, ideas, thoughts, feelings, and
          questions. The key word here is process. Communication is a process in which both a sender
          and a receiver exchange information.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mb-1">The Communication Process</h3>
        <p className="text-base mb-8">
          The communication process involves three primary elements: a sender, a message, and a
          receiver. Learn more about the primary elements of communication.
        </p>
        <TextBlock title="Sender">
          <p className="text-base">
            Communication starts with the sender. As the person who delivers the information, the
            sender decides what to share, who to share it with, and how to deliver the message.
            <br />
            <br />
            If you’re the sender, you’ll be most effective if your message is clear and
            straightforward. For example, consider how you might be more effective if you say: “I
            need this assignment by 3 p.m. today” instead of “I need this assignment soon.”
          </p>
        </TextBlock>
        <TextBlock title="Message">
          <p className="text-base">
            The message is the information the sender shares. You can deliver your message through
            various mediums (forms of verbal, nonverbal, and written communication).
          </p>
          <ul className="list-disc my-5 pl-5 text-base leading-8">
            <li>
              <strong>Verbal communication</strong> involves spoken words, such as phone calls.
            </li>
            <li>
              <strong>Nonverbal communication</strong> involves wordless messages, such as hand
              gestures.
            </li>
            <li>
              <strong>Written communication</strong> involves text-based exchanges, such as emails.
            </li>
          </ul>
          <p className="text-base">
            Your message works best when you use the most appropriate medium for the message. For
            example, complicated recipes are easier to understand when written instead of spoken
            aloud.
          </p>
        </TextBlock>
        <TextBlock title="Receiver">
          <p className="text-base">
            The receiver is the person who receives the message. As the receiver, it’s up to you to
            interpret the information and, hopefully, arrive at the same understanding as the
            sender. <br />
            <br />
            As the receiver, you might need to decode a lot of details, so resist the urge to let
            your mind wander. You’ll be most effective if you listen, read, and watch closely.{' '}
            <br />
            <br /> To improve your effectiveness, you can reply to the sender. That is called giving
            feedback, and it allows you to explain your interpretation of the information and seek
            clarification. As a bonus, by responding with feedback, you’ll help the sender
            understand how successful they were at conveying their message.
          </p>
        </TextBlock>
        <TextBlock title="Summary">
          <p className="text-base">
            So, communication is a process. First, a sender delivers a message through a verbal,
            non-verbal, or written medium. Then, a receiver receives and interprets the message. For
            the communication process to work, both the sender and the receiver must interpret the
            message in the same way.
          </p>
        </TextBlock>
        <div className="flex mt-4 mb-16">
          <div>
            <img className="" src={character} alt="" />
          </div>

          <div className="ml-5 w-3/4">
            <p className="text-xl font-normal">
              "The two words ‘information’ and ‘communication’ are often used interchangeably, but
              they signify quite different things. Information is giving out; communication is
              getting through."
            </p>
            <p className="text-sm font-semibold mt-4">Sydney J. Harris</p>
          </div>
        </div>
        <p className="text-2xl leading-9 text-center mb-10 font-light">
          When there is a disconnect between the sender, the message, or the receiver, the
          communication process breaks down. In the next lesson, learn communication mishaps to
          avoid.
        </p>
        <button onClick={handleNextChapterClick} className="btn-primary w-full">
          WHY DOES COMMUNICATION FAIL?
        </button>
      </div>
    </>
  );
};
