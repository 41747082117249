import React from 'react';
import { useDrop } from 'react-dnd';

import { QuestionOption } from '../../../courses/jumpstart-communicator/jumpstart-communicator.types';

interface AnswerCardProps {
  correctAnswer: QuestionOption;
  answerOption: QuestionOption;
  onDrop: (isCorrect: boolean, answerOption: QuestionOption) => void;
}

const AnswerCard: React.FC<AnswerCardProps> = ({ answerOption, onDrop, correctAnswer }) => {
  const [, drop] = useDrop({
    accept: 'QUESTION',
    drop: () => {
      const isCorrect = correctAnswer.id === answerOption.id;
      onDrop(isCorrect, answerOption);
    },
  });

  return (
    <div
      ref={drop}
      onDragOver={(e) => e.preventDefault()}
      className="text-center bg-gray-100  w-full h-32 lg:h-48 flex items-center justify-center border border-dashed border-gray-600 rounded-lg"
    >
      {answerOption.option}
    </div>
  );
};

export default AnswerCard;
