import { ConfidenceLevel, SkillsFields } from '../form-skills/form-skills.types';

/**
 * the categories in a SWOT analysis
 */
export enum SWOTCategory {
  STRENGTHS = 'strengths',
  WEAKNESSES = 'weaknesses',
  OPPORTUNITIES = 'opportunities',
  THREATS = 'threats',
}

/**
 * a generated SWOT report
 */
export type SWOTReport = Record<SWOTCategory, Array<keyof SkillsFields>>;

/**
 * payload to create SWOT report
 * it maps values from the skills assessment
 */
export type GenerateSWOTReportDTO = Record<keyof SkillsFields, ConfidenceLevel>;
