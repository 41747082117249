import { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { Dispatch } from 'react';

import { login, logout, signup } from '../../../services/database';
import { isOtpVerified, removeOtpFromSessionStorage } from '../../../services/database/mfa-auth';
import { EnvironmentVariable } from '../../../utils/constants';
import { clearTempLoginData, tempLogin } from '../auth/temp-auth.slice';
import { dispatchUpdateUserEngagementActivity } from '../user-engagement/user-engagement.operation';
import { UserEngagementType } from '../user-engagement/user-engagement.type';

import { loginAction, logoutAction, setOTPAuthAction } from './auth.slice';
import { LoginDTO, SignupDTO } from './auth.types';
import { CustomUser } from './CustomUser';

/**
 * registers a new user's account
 */
export const signupUser = async (dto: SignupDTO) => {
  const user: CustomUser = await signup(dto);

  return user;
};

/**
 * registers a new user's account
 */
export const dispatchSignup =
  (dto: SignupDTO) =>
  async (dispatch: Dispatch<PayloadAction<CustomUser>>): Promise<CustomUser> => {
    const user: CustomUser = await signup(dto);
    if (user) {
      dispatch(tempLogin(user));
    }
    //dispatch(loginAction(user));
    return user;
  };

/**
 * login to a user's account
 */
export const dispatchLogin =
  (dto: LoginDTO) =>
  async (dispatch: Dispatch<PayloadAction<CustomUser>>): Promise<CustomUser> => {
    const user: CustomUser = await login(dto);
    if (user) {
      dispatch(tempLogin(user));
    }
    // dispatch(loginAction(user));
    return user;
  };

/**
 * login to a user's account
 */
export const dispatchLogout =
  () =>
  async (dispatch: Dispatch<PayloadAction<void>>): Promise<void> => {
    const todayDate = dayjs().utc().format('YYYY-MM-DD');
    const savedData = JSON.parse(sessionStorage.getItem('userActivity') || '{}');

    const userEngagementDate: UserEngagementType = {
      [todayDate]: {
        lastUpdatedDate: new Date().toUTCString(),
        todayActiveTime: savedData[todayDate]?.todayActiveTime,
        totalActiveTime: savedData[todayDate]?.totalActiveTime,
      },
    };
    await dispatchUpdateUserEngagementActivity(userEngagementDate);

    await logout();
    await removeOtpFromSessionStorage();
    await isOtpVerified(false);

    dispatch(logoutAction());
    sessionStorage.removeItem('userActivity');
  };

export const dispatchUpdateUserState =
  (user: CustomUser) =>
  async (dispatch: Dispatch<PayloadAction<CustomUser>>): Promise<CustomUser> => {
    dispatch(loginAction(user));
    return user;
  };

export const clearUserTempData =
  () =>
  async (dispatch: Dispatch<PayloadAction<void>>): Promise<void> => {
    dispatch(clearTempLoginData());
  };

export const dispatchVerifyLogin =
  (dto: CustomUser) =>
  async (dispatch: Dispatch<PayloadAction<CustomUser>>): Promise<CustomUser> => {
    if (dto) {
      dispatch(loginAction(dto));
    }
    return dto;
  };

export const dispatchOtp = () => {
  async (dispatch: Dispatch<PayloadAction<void>>): Promise<void> => {
    dispatch(setOTPAuthAction());
  };
};

export const dispatchUpdatedPasswordEmail = async (email: string, userName: string) => {
  const updatePasswordEmailUrl =
    process.env[EnvironmentVariable.REACT_APP_UPDATED_PASSWORD_EMAIL_URL];
  if (updatePasswordEmailUrl) {
    const response = await fetch(updatePasswordEmailUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ to: email, userName }),
    });
    return response;
  }
  return;
};
