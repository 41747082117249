import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CustomUser } from './CustomUser';

export interface TempAuthState {
  user: CustomUser | null;
  initialized: boolean;
}
const initialState: TempAuthState = {
  user: null,
  initialized: true,
};

const tempAuthSlice = createSlice({
  name: 'tempAuth',
  initialState,
  reducers: {
    tempLoginData: (state, action: PayloadAction<CustomUser>) => {
      state.user = { ...action.payload };
    },
    clearTempLoginData: (state) => {
      state.user = null;
      state.initialized = false;
    },
  },
});

export const { tempLoginData: tempLogin, clearTempLoginData } = tempAuthSlice.actions;

export default tempAuthSlice.reducer;
