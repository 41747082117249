import emotional_chapter5_chatBackground1 from '../../../../assets/images/courses/emotional-intelligence/chapter5/emotional_chapter5_chatBackground1.jpg';
import emotional_chapter5_img1 from '../../../../assets/images/courses/emotional-intelligence/chapter5/emotional_chapter5_img1.jpg';
import emotional_chat_char_img2 from '../../../../assets/images/courses/emotional-intelligence/chapter5/emotional_chat_char_img2.png';
import m1 from '../../../../assets/images/courses/emotional-intelligence/chapter5/m1.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { course_chat_questions } from '../../../../courses/emotional-intelligence/emotional-intelligence-course-question';
import { CustomAccordion } from '../../../common/Accordion';
import { ActionableBlock } from '../../ActionableBlock';
import FlipCard from '../../FlipCard';
import { InfoBlock } from '../../InfoBlock';
import { MediaBlock } from '../../MediaBlock';
import { TabsBlock } from '../../TabsBlock';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

export const EmotionalIntelligence5: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const courseChatQuestions = course_chat_questions.filter((obj) => obj.chapter_id === chapter.id);

  const TabsData = [
    {
      title: 'Open communication',
      htmlContent: `Empathy creates a space for productive dialogue. When speaking empathetically, you can cater your messages to the other person—fine-tuning your words to fit them and their situation. When listening, empathy helps you put aside your thoughts and pay attention to someone's spoken and unspoken words. `,
    },
    {
      title: 'Productivity',
      htmlContent: `Rather than offering guidance based on your assumptions, empathy means looking outside your perspective, listening to others, and understanding what they need to succeed. With that understanding, you can customize your suggestions and advice to individual teammates—increasing their long-term productivity.`,
    },
    {
      title: 'Trust',
      htmlContent: `Empathy shows others that you’re aware of their feelings and are willing to listen to their concerns—even when you disagree. Your colleagues trust that their opinions are taken into consideration, and they have faith that you’ll give them the courtesy of being heard. It’s an approach that helps people let their guard down—trusting that you're not overlooking their needs.`,
    },
    {
      title: 'Positive morale',
      htmlContent: `By expressing empathy, you show others that you care about them as individuals, not just as employees. It helps people feel respected, safe, and heard. And that makes the workplace a supportive environment, which boosts overall morale and happiness.`,
    },
  ];
  const accordionData = [
    {
      id: 'panel1',
      title: 'Sympathy',
      content:
        '<div class="fr-view"><p><strong>Sympathy</strong> is looking at another’s experience and taking pity on them.&nbsp;</p><p><br></p><p>Rather than understanding or relating to a person, it’s simply acknowledging their emotions and feeling sorry for them. When expressing sympathy, you might say: <em>“That’s awful. I’m sorry you’re going through that.”</em></p></div>',
    },
    {
      id: 'panel2',
      title: 'Empathy',
      content:
        '<div class="fr-view"><p><strong>Empathy</strong> takes sympathy a step further by incorporating <em>understanding</em> into the equation.&nbsp;</p><p><br></p><p>When communicating empathetically, you might say:<em>&nbsp;“I understand what you’re feeling, I see how this must be difficult, and I’m in your corner.”</em>&nbsp;</p><p><br></p><p>Going beyond recognition, empathy asks that you consider another’s perspective as if it were your own. It requires setting aside your opinions and imagining yourself in another’s place so that you can better understand their situation, feelings, and motives.</p></div>',
    },
  ];
  const developingEmpathyAccordionData = [
    {
      id: 'panel1',
      title: 'Practice Active Listening',
      content:
        '<div class="fr-view"><p>Listening and <em>active</em> listening are two different concepts. When we listen, we hear. When we listen <em>actively</em>, we understand—and understanding is a core component of empathy. To practice active listening, you must redirect your focus and silence your internal monologue. It requires fully focusing on what another person is saying and not thinking about what you’re going to say next or rushing to interrupt.&nbsp;</p><p><br></p><p>To listen actively, try recognizing the emotion behind someone’s message. Use your ears, eyes, instinct, and heart. With your ears, listen to another’s words and tone of voice. With your eyes, look out for nonverbal cues such as a person’s mannerisms, facial expressions, and body language. With your instincts, try to sense what this person is communicating. And, with your heart, try connecting your emotions to the speaker so that you can feel with them.&nbsp;</p></div>',
    },
    {
      id: 'panel2',
      title: 'Be Present and Unplug',
      content:
        '<div class="fr-view"><p>To understand and empathize with a speaker, you must be present and give them your full, undivided attention. There can be no distractions, or else you may miss an important word or piece of context. So, put down your phone, step away from the computer, and silence any notifications. Don’t look at your watch or the clock on the wall. Don’t glance down at your shoes or up at the trees outside the window.</p><p><br></p><p>When someone else is speaking, the only thing you should do is listen. Be present, and you’ll absorb more information—and that information is key when trying to understand another person’s viewpoint.&nbsp;</p></div>',
    },
    {
      id: 'panel3',
      title: 'Get Curious, Ask Questions',
      content:
        '<div class="fr-view"><p>Empathy requires cultivating a strong sense of curiosity and inquisitiveness about others. If you only rely on your assumptions and don’t ask clarifying questions, you’ll likely leave a conversation with an incorrect interpretation of another’s feelings. Every person is unique, and different people will have different responses to the same event. So, rather than filling in the gaps yourself, ask respectful questions and let the speaker tell you for themselves.&nbsp;</p><p><br></p><p>When asking these questions, think about what information you’re missing or what you don’t understand. You might say,<em>&nbsp;“What motivated you to develop that idea?”</em> or <em>“How did that make you feel? Why?”</em> Try learning as many details as you can because the more you know, the more likely you are to understand the full picture of another’s reality and offer them the support they need.</p></div>',
    },
    {
      id: 'panel4',
      title: 'Put Yourself in Their Place, Literally',
      content:
        '<div class="fr-view"><p>If a specific task or assignment is causing an employee to feel frustrated, you can also try working alongside them—putting yourself in their place so that you can better understand their grievances.&nbsp;</p><p><br></p><p>Following this strategy, you’ll give an employee 15 to 30 minutes to walk you through what they’re doing and where they’re experiencing issues. As you work alongside them, try imagining the experience as if it were your own. How would you feel if you had to do this task? &nbsp;What support would you want? What wouldn’t you want?&nbsp;</p><p><br></p><p>By practicing this technique, you can see firsthand what the others are trying to express and better understand their pain. And, with that insight, you can come up with empathetic solutions.&nbsp;</p></div>',
    },
    {
      id: 'panel5',
      title: 'Relate to Feelings, Not Events',
      content:
        '<div class="fr-view"><p>Relating to every person’s experience is almost impossible. Perhaps someone is going through a painful event that seems unimaginable to you, and your first thought is: <em>“I have no idea how I’d react if that happened to me.”</em> Or, maybe, a colleague is upset about something that seems marginal to you, and your initial thought is:<em>&nbsp;“They have no idea how bad things can get.”</em> Either way, these internal monologues will not help you build connections.</p><p><br></p><p>So, instead of relating to events, practice relating to feelings. If someone is feeling frustrated, upset, or overwhelmed, remember a time when you felt similar emotions. For example, if a team member tells you,<em>&nbsp;“I messed up the presentation and feel like a failure,”</em> think back to a time when you felt similarly. Perhaps you’ve never messed up a presentation, but you have made mistakes at work and let your team down. Connect to that feeling, and you can develop empathy for this person—regardless of whether or not you relate to the event itself.&nbsp;</p></div>',
    },
  ];
  return (
    <>
      <div className="content-wrap border-l-0 lg:border-l pl-0 lg:pl-8 pt-8 pb-10">
        <h2 className="font-semibold text-3xl">Developing Empathy</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">What's the Best Reaction?</h3>
        <p>
          One of your coworkers lost a close family member and is returning to work today. You want
          to be empathetic, but what does that mean? ?
        </p>
        <br />
        <p>
          Flip the cards below to see three potential reactions. Which one is the most empathetic?
        </p>
        <div className="my-10">
          <div className="flex flex-wrap justify-center gap-10 lg:gap-5 text-2xl font-light">
            <FlipCard>
              <div className="front-content">
                <p>Option A</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center text-lg">
                <p>
                  Give them their space. Don’t say anything unless they ask for advice directly.
                </p>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content">
                <p>Option B</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center text-lg">
                <p>
                  Write a heartfelt sympathy card and say,<em>&nbsp;“I’m sorry for your loss”</em>{' '}
                  as you hand it to them.
                </p>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content">
                <p>Option C</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center text-lg">
                <p>
                  Customize your response by first imagining you were in their place—that way you
                  can understand how they’re feeling and what they need.
                </p>
              </div>
            </FlipCard>
          </div>
        </div>
        <p className="text-3xl font-light text-center my-8 leading-10">
          While Option A, B, and C are all potentially great responses, only{' '}
          <strong>Option C </strong>expresses empathy.
        </p>
        <p>
          Empathy is a social skill that thinks beyond your personal reality and considers that of
          another. It’s a mental leap that helps you understand what others are feeling and what
          support they need.
        </p>
        <br />
        <p>
          In this lesson, you’ll learn what empathy is, why it’s necessary, and how to develop your
          empathy skills.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/pmm84TfAS-6slTdJ5v37--eMH1JMGGF7/dpSdzXnW/assets/rbc/XJPOOD3lr1EWhxR0_LDRSHP-EQ-05.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <p>
          Watch the video to learn what empathy is, why it’s necessary, and how you can develop this
          emotional skill.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          Understanding Empathy vs. Sympathy
        </h3>
        <p>
          So, what is empathy, and how does it differ from sympathy? The two competencies are often
          confused, but there’s a clear-cut distinction. Expand the rows below to learn more.
        </p>
        <br />
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <br />
        <MediaBlock mediaImage={m1}>
          <p className="text-3xl leading-10 font-light w-3/4 text-center mx-auto">
            Empathy means both understanding others on their own terms and bringing them within the
            orbit of one’s own experience.
            <br />
            <span className="text-base font-bold">Jacob A. Belzen</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          Empathy and Workplace Success
        </h3>
        <p>
          A core component of social competence, empathy is understanding someone else's feelings
          and experiencing those emotions with them. It's a crucial skill that leads to several
          workplace benefits:
        </p>
        <br />
        <TabsBlock tabsData={TabsData} />
        <MediaBlock mediaImage={emotional_chapter5_img1} />
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">How Empathetic Are You?</h3>
        <p>
          Before learning how to develop empathy, do a quick self-check. Consider the statements
          below. Each of them describes the traits and thoughts of an empathetic person. How many
          can you relate to?
        </p>
        <br />
        <p>
          <label className="custom-checkbox">
            I can usually recognize and label the emotions of others.
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
        </p>
        <br />
        <p>
          <label className="custom-checkbox">
            I often consider the perspectives of those around me.
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
        </p>
        <br />
        <p>
          <label className="custom-checkbox">
            I can usually predict how my behavior will affect others.
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
        </p>
        <br />
        <p>
          <label className="custom-checkbox">
            Even when I disagree with someone, I can still understand their viewpoint.
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
        </p>
        <br />
        <p>
          <label className="custom-checkbox">
            Before speaking, I consider how my words might impact those around me.
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
        </p>
        <p className="text-3xl my-8 leading-10 font-light text-center">
          The more boxes you checked off, the better. But don't worry if you're struggling to relate
          to the above statements. You can develop empathy with practice.
        </p>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">Developing Empathy</h3>
        <p>
          To develop empathy, you’ll need to pay close attention to others—picking up on subtle cues
          and imagining how it might feel to be in their place. Expand the rows below to learn five
          tips that can help you perfect this skill.
        </p>
        <br />
        {developingEmpathyAccordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <br />
        <InfoBlock
          title="Have deeper conversations. "
          text="To understand where another person is coming from, you need to have conversations beyond the casual, “How are you?” or “Great weather today, huh?” Empathy requires asking people real questions about their feelings, challenges, and motivations. So, don’t shy away from those deeper conversations—they’re the key to understanding what your team members are thinking and feeling."
        />
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">Apply Your Understanding</h3>
        <p>
          Apply your understanding of how you might show empathy by navigating the following
          scenario.
        </p>
        {/* chat card */}
        <ActionableBlock
          backgroundImage={emotional_chapter5_chatBackground1}
          courseChatQuestions={courseChatQuestions}
          characterImage={emotional_chat_char_img2}
          feedbackText=""
          feedbackTitle=""
          buttonName="Continue"
          cssClass="emotional-chat"
        />
        <p className="text-3xl my-8 leading-10 font-light text-center">
          “Empathy is simply listening, holding space, withholding judgment, emotionally connecting,
          and communicating that incredibly healing message of you’re not alone.”
          <br />
          <span className="text-base font-bold">Brene Brown</span>
        </p>

        <button onClick={handleNextChapterClick} className="btn-primary w-full uppercase">
          Enhancing Social Skills
        </button>
      </div>
    </>
  );
};
