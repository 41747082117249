import { useSelector } from 'react-redux';

import { CoursePrePostAssessmentsState } from '../redux/slice/user-assesments/users-course-assesments.slice';
import {
  UserCourseQuestionState,
  UserQuizQuestionState,
} from '../redux/slice/user-course-question-response';
import { RootState } from '../redux/store';

export const useUserCourseQuestionResponseContext = (): UserCourseQuestionState => {
  const state: UserCourseQuestionState = useSelector(
    (state: RootState) => state.userCourseQuestion,
  );
  return state;
};
export const useUserCourseQuizQuestionContext = (): UserQuizQuestionState => {
  const state: UserQuizQuestionState = useSelector(
    (state: RootState) => state.userCourseQuizQuestion,
  );
  return state;
};

export const useUserCoursePrePostAssessmentQuestionsContext = (): CoursePrePostAssessmentsState => {
  const state: CoursePrePostAssessmentsState = useSelector(
    (state: RootState) => state.coursePrePostAssessments,
  );
  return state;
};
