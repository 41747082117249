import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import char from '../../assets/images/intake/step13/char.svg';
import { useIntakeFormContext, useSkillsContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import { useUserAsaTaskHistoryContext } from '../../hooks/use-user-asa-task-history-context.hooks';
import { useAuthContext } from '../../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../../hooks/user-user-profile-context.hooks';
import {
  AsaTaskName,
  AsaUserTasks,
  completeASATask,
  dispatchUpdateAsaTaskHistoryInfo,
} from '../../redux/slice/asa-task-history';
import { CustomUser } from '../../redux/slice/auth/CustomUser';
import { ConfidenceLevel, dispatchUpdateSkills } from '../../redux/slice/form-skills';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import { EnvironmentVariable } from '../../utils/constants';
import { buildASANextTaskUrl } from '../../utils/helper';
import { CircularLoader } from '../loader/CircularLoader';

import { skillOptions } from './Communication';

interface ComponentProps {
  step: number;
  onBack: () => void;
}

interface FormData {
  skillCulture: string;
}

export const Intercultural: React.FC<ComponentProps> = ({ step, onBack }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const asaUserTaskHistory = useUserAsaTaskHistoryContext();
  const currentUser: CustomUser | null = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentAsaTask, setCurrentAsaTask] = useState<AsaUserTasks | undefined>(undefined);
  const userProfileContext = useUserProfileContext();
  const { organization } = useIntakeFormContext();

  const { skillCulture } = useSkillsContext();
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
    didCompleteSkills,
    didAsaUserViewedSwot,
  } = usePreEmploymentAssessmentContext();
  const [error, setError] = useState('');
  const [selectedOption, setSelectedOption] = useState(skillCulture);
  const { handleSubmit } = useForm<FormData>();
  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    } else if (didCompleteSkills) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);

  useEffect(() => {
    if (
      currentUser?.claims?.isAsaUser &&
      Object.keys(asaUserTaskHistory).length &&
      asaUserTaskHistory?.tasks?.length
    ) {
      const currentTask = asaUserTaskHistory.tasks?.find(
        (x) => !x.isCompleted && x.taskName === AsaTaskName.SKILL,
      );
      setCurrentAsaTask(currentTask);
      if (currentAsaTask && (currentAsaTask?.taskStep as number) != 8) {
        const url = new URL(currentAsaTask?.taskUrl || '');
        navigate(`${url.pathname}${url.search}`);
        setIsDisabled(true);
      }
    }
  }, [asaUserTaskHistory, currentUser, currentAsaTask]);

  const backButtonClick = () => {
    onBack();
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(parseInt(event.target.value));
    setError('');
  };
  const submitForm: SubmitHandler<FormData> = async () => {
    logger.debug('Submitted culture form.', selectedOption);

    const error_message: string = 'Please select an option';
    setIsLoading(true);
    try {
      if (selectedOption === undefined) {
        setError(error_message);
      } else {
        if (isDisabled) {
          navigate(`/pre-employment/skills/step${step + 1}`);
        } else {
          const dto: Record<keyof FormData, ConfidenceLevel> = {
            skillCulture: selectedOption as ConfidenceLevel,
          };
          await dispatch(dispatchUpdateSkills(dto));
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: !_.isNil(skillCulture) ? progress : totalProgress,
                activeStepUrl: `/pre-employment/skills/step${step + 1}`,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/skills/step${step + 1}`,
              }),
            );
          }

          if (currentUser?.claims?.isAsaUser && currentAsaTask) {
            const endPointUrl = process.env[EnvironmentVariable.REACT_APP_ASA_COMPLETE_TASK_URL];
            const isStaging =
              process.env[EnvironmentVariable.REACT_APP_BASE_URL]?.includes('staging') ||
              process.env[EnvironmentVariable.REACT_APP_BASE_URL]?.includes('localhost');
            const taskResponse = await completeASATask(
              {
                asaUserId: currentUser?.claims?.asaUserId as number,
                taskId: currentAsaTask?.taskId,
                taskStep: currentAsaTask?.taskStep || step,
              },
              endPointUrl || '',
              isStaging || false,
            );

            if (!taskResponse || !taskResponse?.ok) {
              toast.error('Something went wrong!, Please try after sometime.');
              return;
            }
            let url = new URL(currentAsaTask?.taskUrl || '');
            const nextTaskUrl = buildASANextTaskUrl(url, AsaTaskName.SKILL, step + 1);
            url = new URL(nextTaskUrl || '');
            await dispatch(
              dispatchUpdateAsaTaskHistoryInfo({
                asaUserId: currentUser?.claims?.asaUserId || 0,
                taskId: currentAsaTask?.taskId || '',
                taskStep: parseInt(currentAsaTask?.taskStep.toString()) + 1 || 9,
                taskUrl: nextTaskUrl,
                startDate: new Date(),
                taskName: AsaTaskName.SKILL,
                totalSteps: 11,
              }),
            );
            await dispatch(
              dispatchUpdateAsaTaskHistoryInfo({
                asaUserId: currentUser?.claims?.asaUserId || 0,
                taskId: currentAsaTask?.taskId || '',
                taskStep: currentAsaTask?.taskStep || step,
                isCompleted: true,
                completedDate: new Date(),
              }),
            );
            toast.success('Task has been successfylly submitted');
            navigate(`${url.pathname}${url.search}`);
          } else {
            navigate(`/pre-employment/skills/step${step + 1}`);
          }
        }
      }
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (skillCulture !== undefined) {
      setSelectedOption(skillCulture);
    }
  }, [skillCulture]);

  return (
    <div>
      {isLoading && <CircularLoader />}

      <div className="flex mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
          {' '}
          {!didAsaUserViewedSwot && !userProfileContext.isPremium && !organization ? step - 1 : 23}
        </div>
        <div>
          <h4 className="text-2xl">
            <span className="font-semibold">How comfortable are you </span>
            with each of these competencies
          </h4>
        </div>
      </div>
      <div className="character absolute top-96 right-[-125px] z-[-1]">
        <img className="" src={char} alt="" />
      </div>
      <form
        className=" md:px-10"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitForm)}
      >
        <h4 className="mb-1 font-semibold text-purple-700 text-base">Competency</h4>
        <h3 className="font-semibold text-black text-xl mb-8">Global/ Intercultural Perspective</h3>
        <h4 className="mb-2 font-semibold text-purple-700 text-base">Description</h4>
        <p className="mb-6">
          Respect viewpoints from diverse cultures, races, ages, genders, religions and lifestyles
          to build collaborative relationships and communicate effectively. The ability to
          appreciate, value, and learn from other cultures and perspectives to move beyond
          tolerance.
        </p>
        <h4 className="mb-1 font-semibold text-purple-700 text-base mb-3">
          Competency items to be rated
        </h4>
        <ul className="list-disc pl-6">
          <li className="mb-2">Maintain a holistic curiosity about cultures.</li>
          <li className="mb-2">Possess a sensitivity to and a respect for differences. </li>
          <li className="mb-2">
            You are willing to extend beyond your cultural framework by trying new experiences.
          </li>
          <li className="mb-2">
            Engage in conversation with individuals who have different perspectives than your own.
          </li>
          <li className="mb-2">
            Modify outward behavior to show respect for different cultural preferences.
          </li>
          <li className="mb-2">
            Work effectively in diverse teams, initiating and developing interactions.
          </li>
          <li className="mb-2">
            Understand cultural differences in verbal and nonverbal communication and skillfully
            negotiate a shared understanding based on those differences.
          </li>
          <li className="mb-2">
            Act in a supportive manner that recognizes the feelings and values of another cultural.
          </li>
        </ul>
        <div className="rating-btns flex flex-wrap lg:flex-nowrap items-center mt-10">
          {skillOptions.map((option) => (
            <div key={option.label} className="h-[55px] mr-5 mb-3">
              <label className="radio-container h-full block relative">
                <input
                  disabled={isDisabled}
                  checked={selectedOption === option.value}
                  onChange={handleOptionChange}
                  className="w-full h-full absolute top-0 left-0 opacity-0"
                  type="radio"
                  name="communication"
                  value={option.value}
                />
                <span className="checkmark  !static !h-[55px] !w-auto !py-4 !px-5 !text-sm !whitespace-nowrap font-light !text-black">
                  {option.label}
                </span>
              </label>
            </div>
          ))}
        </div>
        {error && <p className="text-red-500 font-light !text-sm pt-5">{error}</p>}
        <div className="flex justify-end lg:justify-between lg:mt-20 mt-10">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className={`btn-primary  ${currentAsaTask ? 'hidden' : ''}`}
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            {currentAsaTask ? 'Submit' : ' Next'}
          </button>
        </div>
      </form>
    </div>
  );
};
