import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { EmploymentReadinessState } from '../../redux/slice/form-employment-readiness/form-employment-readiness.slice';
import { UpdateEmploymentReadinessDTO } from '../../redux/slice/form-employment-readiness/form-employment-readiness.types';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.EMPLOYMENT_READINESS_RESPONSES;

/**
 * upsert a record into the employment readiness responses collection
 */
async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

/**
 * retrieves the values of the previously inputted employment readiness if available
 */
export async function getEmploymentReadinessValues(): Promise<EmploymentReadinessState> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<EmploymentReadinessState> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return result.data() ?? {};
  } catch (e) {
    logger.error('Failed to retrieve employment readiness values.', e);
    throw e;
  }
}

/**
 * updates the employment readiness assessment
 */
export async function updateEmploymentReadiness(dto: UpdateEmploymentReadinessDTO): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved employment readiness.');
  } catch (e) {
    logger.error('Failed to update employment readiness.', e);
    throw e;
  }
}
