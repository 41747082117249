import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  EmploymentReadinessAnswer,
  MarketingToolkitQuestion,
} from '../../assessments/employment-readiness/employment-readiness.types';
import char from '../../assets/images/intake/step12/char.svg';
import { useEmploymentReadinessContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  ProfessionalResourceStatus,
  dispatchUpdateEmploymentReadiness,
  EmploymentReadinessState,
} from '../../redux/slice/form-employment-readiness';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';

import { convertProfessionalResourceStatusToString } from './CandidateMarketingToolkit';

interface ComponentProps {
  question: MarketingToolkitQuestion;
  step: number;
  onBack: () => void;
}

type AnswersState = {
  [questionId: string]: EmploymentReadinessAnswer;
};

export const ProfessionalProfile: React.FC<ComponentProps> = ({ step, question, onBack }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);

  const [error, setError] = useState('');
  const [answer, setAnswer] = useState<AnswersState>({});
  const savedAnswer: EmploymentReadinessState = useEmploymentReadinessContext();
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
  } = usePreEmploymentAssessmentContext();
  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);
  const updateAnswer = (
    questionId: string,
    updatedAnswer: Partial<EmploymentReadinessAnswer>,
  ): void => {
    setError('');
    setAnswer((previousState: AnswersState) => ({
      ...previousState,
      [questionId]: {
        ...answer[questionId],
        ...updatedAnswer,
      },
    }));
  };

  const { handleSubmit } = useForm();
  const submitProfileOption = async () => {
    logger.debug('Submitted professional profile form.', answer);
    setIsLoading(true);
    try {
      if (!answer[question.id]) {
        setError('Please select an option');
      } else {
        if (isDisabled) {
          navigate(`/pre-employment/employment-readiness/step${step + 1}`);
        } else {
          await dispatch(dispatchUpdateEmploymentReadiness(answer));
          navigate(`/pre-employment/employment-readiness/step${step + 1}`);
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: savedAnswer[question.id] ? progress : totalProgress,
                activeStepUrl: `/pre-employment/employment-readiness/step${step + 1}`,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/employment-readiness/step${step + 1}`,
              }),
            );
          }
        }
      }
    } catch (err) {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  const backButtonClick = () => {
    onBack();
  };

  useEffect(() => {
    if (savedAnswer && Object.keys(savedAnswer).length) {
      setAnswer(savedAnswer);
    }
  }, [savedAnswer]);

  return (
    <div>
      <div className="flex  mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">13</div>
        <div>
          <h4 className="text-2xl">
            I have an
            <span className="font-semibold"> {question.resource} </span>
            (LinkedIn, website, etc.)
          </h4>
        </div>
      </div>
      <div className="character absolute z-50 bottom-[-93px] left-[-120px]">
        <img className="" src={char} alt="" />
      </div>
      <form
        className="md:px-10"
        action=""
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitProfileOption)}
      >
        <div className="grid gap-y-3">
          {Object.entries(question.options).map(([label, value]) => (
            <div key={label} className="h-[90px] lg:h-[60px]">
              <label className="radio-container h-full p-4 block relative">
                <input
                  disabled={isDisabled}
                  checked={convertProfessionalResourceStatusToString(answer[question.id]) === value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    updateAnswer(question.id, {
                      question: question.id,
                      answer: event.target.value as ProfessionalResourceStatus,
                    })
                  }
                  value={value}
                  className="w-full h-full absolute top-0 left-0 opacity-0"
                  type="radio"
                  name="resume option"
                />
                <span className="checkmark !block h-auto pt-4">{label}</span>
              </label>
            </div>
          ))}
          {error && <p className="text-red-500">{error}</p>}
        </div>
        <div className="flex justify-end lg:justify-between lg:mt-20 mt-10">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
