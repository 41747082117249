import { Close, Done } from '@mui/icons-material';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  CourseMatchingQuestion,
  //CourseQuestionAnswer,
  CourseSortingQuestion,
  QuestionOption,
  QuestionType,
  QuizQuestion,
  ShortingMatchingBase,
} from '../../courses/jumpstart-communicator/jumpstart-communicator.types';

import { MatchingQuestionBlock } from './MatchingQuestion/CourseMatchingQuestion';
import { CourseSortingQuestionBlock } from './SortingQuestion/CourseSortingQuestion';

type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
interface QuizQuestionProps {
  currentQuestionIndex: number;
  totalQuestions: number;
  questionType: QuestionType;
  question: QuizQuestion | CourseSortingQuestion | CourseMatchingQuestion;
  onAnswerSubmit: (
    questionId: string,
    quizAnswers: QuestionOption[],
    isAnswerCorrect: boolean,
  ) => void;
  onActivityAnswerSubmit?: (activityAnswers: SortingAnswersState) => void;
}
export const GenericQuizQuestion: React.FC<QuizQuestionProps> = (props) => {
  const [selectedAnswers, setSelectedAnswers] = useState<QuestionOption[]>([]);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<boolean>(false);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState<boolean>(false);
  const [activityAnswer, setActivityAnswer] = useState<SortingAnswersState>({});

  const handleNextClick = () => {
    if (props.questionType === QuestionType.CHECKBOX || props.questionType === QuestionType.RADIO) {
      props.onAnswerSubmit(props.question.id, selectedAnswers, isAnswerCorrect);
    } else if (
      props.questionType === QuestionType.SORTING ||
      props.questionType === QuestionType.MATCHING
    ) {
      props.onActivityAnswerSubmit && props.onActivityAnswerSubmit(activityAnswer);
    }

    setSelectedAnswers([]);
    setIsAnswerCorrect(false);
    setIsAnswerSubmitted(false);
    setActivityAnswer({});
  };
  const handleAnswerSubmit = () => {
    setIsAnswerSubmitted(true);
    if (props.questionType === QuestionType.CHECKBOX || props.questionType === QuestionType.RADIO) {
      if (
        selectedAnswers.length === (props.question as QuizQuestion).answers.length &&
        (props.question as QuizQuestion).answers.every((value) =>
          selectedAnswers.find((obj) => obj.id === value),
        )
      ) {
        setIsAnswerCorrect(true);
      }
    }
  };

  const handleOptionSelect = (option: QuestionOption) => {
    if (props.question.type === QuestionType.RADIO) {
      setSelectedAnswers([option]);
    } else if (props.question.type === QuestionType.CHECKBOX) {
      if (selectedAnswers.includes(option)) {
        setSelectedAnswers(selectedAnswers.filter((obj) => obj !== option));
      } else {
        setSelectedAnswers([...selectedAnswers, option]);
      }
    }
  };
  const handleSortingExcerciseCompleted = (sortingAnswers: SortingAnswersState) => {
    setActivityAnswer(sortingAnswers);
    setIsAnswerSubmitted(true);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-lg">
        <p className="italic">Question</p>
        <h2 className="font-semibold text-3xl text-purple-600">
          {props.currentQuestionIndex} of {props.totalQuestions}
        </h2>
        {(props.questionType === QuestionType.CHECKBOX ||
          props.questionType === QuestionType.RADIO) && (
          <>
            {(props.question as QuizQuestion).question_description && (
              <>
                <p>
                  <br />
                </p>
                <p className="text-xl font-medium mb-10">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: (props.question as QuizQuestion).question_description || '',
                    }}
                  />
                </p>
              </>
            )}
            <div className="border p-5 lg:p-10 rounded-3xl">
              <p className="text-xl font-medium mb-10">
                <span
                  dangerouslySetInnerHTML={{ __html: (props.question as QuizQuestion).question }}
                />
              </p>
              <ul className="mt-7">
                {props.questionType === QuestionType.CHECKBOX
                  ? (props.question as QuizQuestion).options.map((option) => (
                      <li
                        key={option.id}
                        className={`p-3 mb-3 ${
                          isAnswerSubmitted && selectedAnswers.find((x) => x.id === option.id)
                            ? 'answer-active'
                            : ''
                        }`}
                      >
                        <label className="custom-checkbox text-lg">
                          {option.option}
                          <input
                            checked={!!selectedAnswers.find((obj) => obj.id === option.id)}
                            disabled={isAnswerSubmitted}
                            onChange={() => handleOptionSelect(option)}
                            type="checkbox"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    ))
                  : (props.question as QuizQuestion).options.map((option) => (
                      <li
                        key={option.id}
                        className={`p-3 mb-3 ${
                          isAnswerSubmitted && selectedAnswers.find((x) => x.id === option.id)
                            ? 'answer-active'
                            : ''
                        }`}
                      >
                        <label className="custom-radio">
                          {option.option}
                          <input
                            checked={!!selectedAnswers.find((obj) => obj.id === option.id)}
                            disabled={isAnswerSubmitted}
                            onChange={() => handleOptionSelect(option)}
                            type="radio"
                            name="radio"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    ))}
              </ul>

              {!isAnswerSubmitted && (
                <button
                  disabled={!selectedAnswers.length}
                  onClick={handleAnswerSubmit}
                  className="btn-primary"
                >
                  SUBMIT
                </button>
              )}
              {isAnswerSubmitted && (
                <div className="mt-7 text-center">
                  <div className="bg-gray-100 text-center px-10 md:px-20 py-10">
                    <div className="icon-wrap w-16 h-16 rounded-full border-2 border-gray-400 flex items-center justify-center mx-auto mb-3">
                      {!isAnswerCorrect ? (
                        <Close sx={{ color: '#962cc6' }} />
                      ) : (
                        <Done sx={{ color: '#962cc6' }} />
                      )}
                    </div>
                    <p className="mb-7">{isAnswerCorrect ? 'Correct' : 'Incorrect'}</p>
                    <p>
                      {isAnswerCorrect ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: (props.question as QuizQuestion).answer_description,
                          }}
                        />
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              (props.question as QuizQuestion).wrong_answer_description ||
                              (props.question as QuizQuestion).answer_description,
                          }}
                        />
                      )}
                    </p>
                  </div>
                  <button onClick={handleNextClick} className="btn-primary mt-10">
                    NEXT
                  </button>
                </div>
              )}
            </div>
          </>
        )}
        {props.questionType === QuestionType.SORTING && (
          <>
            <h2 className="text-2xl font-bold mt-10 mb-3">Sorting Exercise</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: (props.question as CourseSortingQuestion)?.question_description || '',
              }}
            ></p>
            <DndProvider backend={HTML5Backend}>
              <CourseSortingQuestionBlock
                question={props.question as CourseSortingQuestion}
                enableRetake={false}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                submitSortingQuestion={handleSortingExcerciseCompleted}
              />
            </DndProvider>
            {isAnswerSubmitted && (
              <div className="mt-7 text-center">
                <button onClick={handleNextClick} className="btn-primary mt-10">
                  NEXT
                </button>
              </div>
            )}
          </>
        )}
        {props.questionType === QuestionType.MATCHING && (
          <>
            <h2 className="text-2xl font-bold mt-10 mb-3">Matching Activity</h2>
            {(props.question as CourseMatchingQuestion).question_title && (
              <>
                <p>
                  <br />
                </p>
                <p className="text-xl font-medium mb-10">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: (props.question as CourseMatchingQuestion).question_title || '',
                    }}
                  />
                </p>
              </>
            )}
            <div className="border rounded-3xl p-8 text-lg">
              <p
                className="mb-6"
                dangerouslySetInnerHTML={{
                  __html: (props.question as CourseMatchingQuestion)?.question_description || '',
                }}
              ></p>
              <DndProvider backend={HTML5Backend}>
                <MatchingQuestionBlock
                  enableRetake={false}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  submitMatchingQuestion={handleSortingExcerciseCompleted}
                  questionData={props.question as CourseMatchingQuestion}
                />
              </DndProvider>
            </div>
            {isAnswerSubmitted && (
              <div className="mt-7 text-center">
                <button onClick={handleNextClick} className="btn-primary mt-10">
                  NEXT
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
