import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import {
  getUserPreAssesmentCourseQuestionValues,
  updateUserPreAssesmentQuestion,
} from '../../../services/database/course-assesment';

import {
  CoursePrePostAssessmentsState,
  updateCourseAssesmentsQuestionAction,
} from './users-course-assesments.slice';

export const dispatchUpdateUserCourseAssesmentQuestionResponse =
  (dto: CoursePrePostAssessmentsState) =>
  async (dispatch: Dispatch<PayloadAction<CoursePrePostAssessmentsState>>): Promise<void> => {
    try {
      await updateUserPreAssesmentQuestion(dto);
      dispatch(updateCourseAssesmentsQuestionAction(dto));
    } catch (error) {
      console.log('error:', error);
    }
  };

export const dispatchGetUserCourseAssesmentQuestionResponse =
  () =>
  async (dispatch: Dispatch<PayloadAction<CoursePrePostAssessmentsState>>): Promise<void> => {
    try {
      const values: CoursePrePostAssessmentsState = await getUserPreAssesmentCourseQuestionValues();
      if (Object.keys(values).length) {
        dispatch(updateCourseAssesmentsQuestionAction(values));
      }
    } catch (error) {
      console.log('error:', error);
    }
  };
