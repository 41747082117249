import chap3_answer1 from '../../assets/images/courses/jumpstart-communicator/chapter3/answer1.png';
import chap3_answer2 from '../../assets/images/courses/jumpstart-communicator/chapter3/answer2.png';
import chap3_answer3 from '../../assets/images/courses/jumpstart-communicator/chapter3/answer3.png';
import chat_character from '../../assets/images/courses/jumpstart-communicator/chapter3/intro.png';
import chap3_question1 from '../../assets/images/courses/jumpstart-communicator/chapter3/question1.png';
import chap4_answer1 from '../../assets/images/courses/jumpstart-communicator/chapter4/answer1.png';
import chap4_answer2 from '../../assets/images/courses/jumpstart-communicator/chapter4/answer2.png';
import chat_character_4 from '../../assets/images/courses/jumpstart-communicator/chapter4/intro.png';
import chap4_question1 from '../../assets/images/courses/jumpstart-communicator/chapter4/question1.png';
import chap4_question2 from '../../assets/images/courses/jumpstart-communicator/chapter4/question2.png';
import chap4_question2_answer from '../../assets/images/courses/jumpstart-communicator/chapter4/question2answer.png';

import {
  CourseChatQuestion,
  CourseQuestion,
  CourseSortingQuestion,
  JumpStartQuizQuestion,
  QuestionOption,
  QuestionType,
} from './jumpstart-communicator.types';
export const shorting_answer_options: QuestionOption[] = [
  {
    id: '71fd1af5-603b-46c4-abd2-711a275fc06d',
    option: 'Action-Based',
  },
  {
    id: 'a7a0bd25-2254-45a0-89bd-3fcbc935060b',
    option: 'Fact-Based',
  },
  {
    id: 'd110f480-3b50-4428-b658-8eab3753e22c',
    option: 'Idea-Based',
  },
  {
    id: '07bb73b2-38ae-4118-993e-b651ba478751',
    option: 'People-Based',
  },
];

export const communication_midium_answer_options: QuestionOption[] = [
  {
    id: '6e4aa2f8-2aa7-4bfe-93ec-ebb53f893b89',
    option: 'Email',
  },
  {
    id: '34dce2a1-63b1-4944-8aad-3dfdf812c743',
    option: 'In-Person',
  },
  {
    id: '5eadd8ac-4264-47d7-9b3b-d34f5c78aaf6',
    option: 'Phone Call',
  },
  {
    id: 'c72f7a85-f418-4815-bf94-a0e3289f3d02',
    option: 'Video Call',
  },
];

export const course_sorting_questions: CourseSortingQuestion[] = [
  {
    id: '06131615-aa57-440b-828f-7c6dd5f5faa0',
    chapter_id: '1087fc0c-37c1-4846-a2b9-e16c963cf417',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
    type: QuestionType.SORTING,
    questions: [
      {
        id: '61303b8e-54d0-4299-a117-7c83311b9e6a',
        question: 'You need to collaborate with many people but are separated by long distance.',
      },
      {
        id: 'b65bf7b9-e391-4acc-a321-6cfcc67dd7f1',
        question: 'Your communication is specific, not urgent, and requires a record.',
      },
      {
        id: 'efbde806-36d1-4e89-983d-452e8d00fe28',
        question: 'You have to share an important, time-sensitive message.',
      },
      {
        id: 'f2d437ea-cbc3-4d99-9e28-4714cf4acdcf',
        question: 'You need to deliver a personal, emotionally sensitive message.',
      },
    ],
    options: communication_midium_answer_options,
    answers: {
      'b65bf7b9-e391-4acc-a321-6cfcc67dd7f1': {
        id: '6e4aa2f8-2aa7-4bfe-93ec-ebb53f893b89',
        option: 'Email',
      },
      '61303b8e-54d0-4299-a117-7c83311b9e6a': {
        id: 'c72f7a85-f418-4815-bf94-a0e3289f3d02',
        option: 'Video Call',
      },
      'efbde806-36d1-4e89-983d-452e8d00fe28': {
        id: '5eadd8ac-4264-47d7-9b3b-d34f5c78aaf6',
        option: 'Phone Call',
      },
      'f2d437ea-cbc3-4d99-9e28-4714cf4acdcf': {
        id: '34dce2a1-63b1-4944-8aad-3dfdf812c743',
        option: 'In-Person',
      },
    },
  },
  {
    id: 'a452f853-f9c0-4972-aca4-8150f13f1412',
    chapter_id: 'cf54459b-86d5-4561-97f2-2404e28b9f99',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
    type: QuestionType.SORTING,
    questions: [
      {
        id: '08053c5e-c5a6-4352-9fa3-0fec22e61f24',
        question: 'Provide as many details as you can up front ',
      },
      {
        id: '53a099e6-fc7b-4424-8d7b-6a67a25aea1c',
        question: 'Schedule check-in meetings',
      },
      {
        id: 'c3ec41b6-b03f-4db0-935d-6edd262e18c5',
        question: 'Create a vision board',
      },
      {
        id: '6a439583-2562-4ddf-a6f1-c1cbc6f3ca95',
        question: 'Share a customer impact story',
      },
      {
        id: 'e7a62ac0-cf0c-4503-804f-17391337516f',
        question: 'Focus on big picture',
      },
      {
        id: '79278e1d-7ffd-4f8c-92f0-6fdfa420cd79',
        question: 'Give clear timelines',
      },
      {
        id: 'ee25f38b-74aa-4336-88a0-6e48fbe9c862',
        question: 'Give an overview of project goals',
      },
      {
        id: '994e6109-dc60-417a-947b-e1c2e1b4a3a1',
        question: 'Ask about their weekend',
      },
      {
        id: '92e1544f-b812-4f38-bec6-53c7d38ef217',
        question: 'Summarize meeting action items',
      },
      {
        id: 'c9a0586e-13f8-49ca-992a-1914239c1fc7',
        question: 'Prepare graphs for your meeting',
      },
      {
        id: 'f5011c86-b3e5-4190-9694-2a7ace1f1347',
        question: 'Break down a project into tasks',
      },
      {
        id: 'b07906cf-920f-47fe-8a59-149f0d844c7a',
        question: 'Give space to work independently',
      },
    ],
    options: shorting_answer_options,
    answers: {
      '08053c5e-c5a6-4352-9fa3-0fec22e61f24': {
        id: 'a7a0bd25-2254-45a0-89bd-3fcbc935060b',
        option: 'Fact-Based',
      },
      '53a099e6-fc7b-4424-8d7b-6a67a25aea1c': {
        id: '07bb73b2-38ae-4118-993e-b651ba478751',
        option: 'People-Based',
      },
      'c3ec41b6-b03f-4db0-935d-6edd262e18c5': {
        id: 'd110f480-3b50-4428-b658-8eab3753e22c',
        option: 'Idea-Based',
      },
      '6a439583-2562-4ddf-a6f1-c1cbc6f3ca95': {
        id: '07bb73b2-38ae-4118-993e-b651ba478751',
        option: 'People-Based',
      },
      'e7a62ac0-cf0c-4503-804f-17391337516f': {
        id: 'd110f480-3b50-4428-b658-8eab3753e22c',
        option: 'Idea-Based',
      },
      '79278e1d-7ffd-4f8c-92f0-6fdfa420cd79': {
        id: '71fd1af5-603b-46c4-abd2-711a275fc06d',
        option: 'Action-Based',
      },
      'ee25f38b-74aa-4336-88a0-6e48fbe9c862': {
        id: 'd110f480-3b50-4428-b658-8eab3753e22c',
        option: 'Idea-Based',
      },
      '994e6109-dc60-417a-947b-e1c2e1b4a3a1': {
        id: '07bb73b2-38ae-4118-993e-b651ba478751',
        option: 'People-Based',
      },
      '92e1544f-b812-4f38-bec6-53c7d38ef217': {
        id: '71fd1af5-603b-46c4-abd2-711a275fc06d',
        option: 'Action-Based',
      },
      'c9a0586e-13f8-49ca-992a-1914239c1fc7': {
        id: 'a7a0bd25-2254-45a0-89bd-3fcbc935060b',
        option: 'Fact-Based',
      },
      'f5011c86-b3e5-4190-9694-2a7ace1f1347': {
        id: '71fd1af5-603b-46c4-abd2-711a275fc06d',
        option: 'Action-Based',
      },
      'b07906cf-920f-47fe-8a59-149f0d844c7a': {
        id: 'a7a0bd25-2254-45a0-89bd-3fcbc935060b',
        option: 'Fact-Based',
      },
    },
  },
];
export const course_questions: CourseQuestion[] = [
  {
    id: '6e424c6b-dc24-4cad-b0b0-b2122d7af24a',
    question:
      'Imagine you’re emailing a coworker who works at the same gas station you do. You’ve been sending messages back and forth all day, but you can’t seem to understand one another. At this point, you’re both upset and frustrated. How do you send your next message?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'bc78d47f-73f5-4fb6-b151-549037b1c597',
        option: 'Write another email, since we’re already communicating this way. ',
      },
      {
        id: '0ba4976a-c4d5-475f-98bc-d3d6a0a52cab',
        option: 'Call my coworker on the phone to clarify my message.',
      },
      {
        id: '4ed923c5-e3b4-4243-8b27-d1a22cdfc5ab',
        option: 'Set up a video call so we can talk face-to-face.',
      },
      {
        id: '32fca4c2-3cff-444a-8afe-2b37cfa310cc',
        option: 'Go to the gas station and see if we can talk in person.',
      },
    ],
    answers: ['32fca4c2-3cff-444a-8afe-2b37cfa310cc'],
    answer_description:
      'Because you and your coworker are feeling emotional and experiencing miscommunication, this message is best shared in person. That way, you can use your verbal and nonverbal cues to better express yourself. Since you work at the same gas station, a remote video call isn’t necessary.',
    chapter_id: '1087fc0c-37c1-4846-a2b9-e16c963cf417',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
  },
  {
    id: 'aa43faa6-4584-43c9-978a-d5d54e2fe301',
    question:
      'Right before leaving the restaurant, your boss says: “I need you to step it up,” but she provides no further information. You don’t know how to interpret this vague message. What can you do?',
    options: [
      {
        id: '4d110158-5a6e-4f6b-9969-a133caab07bb',
        option: 'I’ll start coming to work earlier and leaving later to show my dedication.',
      },
      {
        id: 'f6730683-8162-47b4-8caf-24177eda97e6',
        option:
          'I’ll ask my boss for more information with questions like: “Can you give me a few more details?” and “Do you have an example of where I’ve fallen short?”',
      },
      {
        id: 'ef7dd6f5-a88d-4763-ba53-67e8e42e4ec7',
        option: 'I’ll seek advice from my coworkers to see what they think.',
      },
    ],
    answers: ['f6730683-8162-47b4-8caf-24177eda97e6'],
    answer_description:
      'While seeking a second opinion might help you get more information, are you sure you’ll get the right information? You’ll likely have better luck if you speak directly with the sender—in this case, your boss.',
    type: QuestionType.RADIO,
    chapter_id: 'a7d830fa-1176-4aa2-aeae-ee4c74ab3d0a',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
  },
  {
    id: '4e5ee78d-2978-4c24-a51b-cac07eb6f238',
    question:
      'You asked a friend to provide you with website reference by Friday. The project deadlines have shifted, and your teacher has moved the deadline up to Thursday. Considering your friend’s action-based communication style, how do you make that request? ',
    options: [
      {
        id: '8eb961dc-8d2d-43c9-ac29-9d82abd7823e',
        option:
          '“The teacher has moved deadlines forward and the project is due on Thursday because we don’t need that website reference by EOD Wednesday.”',
      },
      {
        id: '07f2b9a6-9c86-46af-b5ef-1a7d6d1604eb',
        option:
          '“When is the earliest I could get that website reference from you? You can get back to me after you have a chance to review the project and assess the time commitment.” ',
      },
      {
        id: 'e61a5e0a-d7f4-4279-9174-1995ca79b9d8',
        option:
          '“My deadlines have shifted, and it’d be a huge help to get that website reference from you by Wednesday instead. Do you feel that’s realistic with your current workload?”',
      },
      {
        id: 'c907cb56-c135-4edf-b084-beab426f6f63',
        option:
          '“I’m going to need that website reference by Wednesday instead of Friday now. The teacher has changed the deadline and now the entire project is due earlier, and the rest of the group wants time to do a great job on the remaining work by EOD Thursday."',
      },
    ],
    answers: ['8eb961dc-8d2d-43c9-ac29-9d82abd7823e'],
    answer_description:
      'This approach reflects an idea-based communication style. It connects the task (website reference) to the bigger group working and wanting to do a great job on the project.',
    type: QuestionType.RADIO,
    chapter_id: 'cf54459b-86d5-4561-97f2-2404e28b9f99',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
  },
];
export const course_chat_questions: CourseChatQuestion[] = [
  {
    title: '',
    content:
      'Your friend Emily is experiencing a communication problem at the restaurant where she works. Can you give her some advice?',
    id: '9e3addbe-3dc1-4906-82a1-323c414a9918',
    question: '',
    question_img: chat_character,
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: '793f6d0e-2c51-4df5-b526-2b282724260f',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
  },
  {
    id: 'f11e8296-8885-4544-a1f6-607208265879',
    question:
      'My boss just called me about cleaning out the storage room, but his directions seem incomplete and unclear. What should I do?',
    question_img: chap3_question1,
    options: [
      {
        id: '5a26464d-e741-469a-ba65-ead03fea0521',
        option:
          'Start working on what you can and don’t waste any time. Your boss can provide more instructions when he arrives to check your work later.',
        description:
          'I’m in so much trouble! Everything I did was wrong, and my boss is disappointed. I need to stay late and reorganize the entire room.',
        img: chap3_answer1,
      },
      {
        id: '8a4c88a4-bd8c-4253-859b-5cbf9188689f',
        option:
          'Call your boss back right away. Seek clarification by asking questions about anything you don’t understand.',
        description:
          'Thank you, what great advice! My boss clarified everything, and I’m feeling a lot more confident.',
        img: chap3_answer2,
      },
      {
        id: 'e73d84c8-c4eb-4753-bdc6-ac89d82571e0',
        option:
          'There’s nothing you can do. This isn’t your fault. The communication process failed because your boss was unclear.',
        description:
          'That didn’t go well with my boss. He told me that communication is a two-way street, and I should have said something.',
        img: chap3_answer3,
      },
    ],
    answer: '8a4c88a4-bd8c-4253-859b-5cbf9188689f',
    answers: [],
    answer_description:
      'Thank you, what great advice! My boss clarified everything, and I’m feeling a lot more confident.',
    type: QuestionType.CHAT,
    chapter_id: '793f6d0e-2c51-4df5-b526-2b282724260f',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
  },
  {
    title: '',
    content:
      'You and your classmate Paul are disagreeing about a project. He doesn’t understand your opinion, and you’re in a heated argument. Based on what Paul says next, how should you respond?',
    id: '120fcffc-8b0f-45e9-abd0-4f237c184195',
    question: '',
    question_img: chat_character_4,
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: '43472023-17df-4363-8878-9d18fdfd9b74',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
  },
  {
    id: 'ab039b69-74e0-4237-9ce5-2b4a7997e81b',
    question:
      'I hate that we can’t see eye-to-eye. Do you think there’s a way for us to stop arguing?',
    question_img: chap4_question1,
    options: [
      {
        id: '28477349-c549-4068-9598-1150a1f3634e',
        option:
          'Yes, I think there’s a way. Even though we disagree, we don’t have to argue like this.',
        description: 'You’re right! Conflicts happen, but they don’t need to end negatively.',
        img: chap4_answer1,
      },
      {
        id: '4731dfbb-fd63-40b8-8cff-198f4fccf9ee',
        option: 'Not in this situation. We disagree, which makes arguments like these inevitable.',
        description:
          'I don’t think that’s true. Disagreements might be inevitable, but they don’t need to end negatively.',
        img: chap4_answer2,
      },
    ],
    answer: '28477349-c549-4068-9598-1150a1f3634e',
    answers: [],
    answer_description: 'You’re right! Conflicts happen, but they don’t need to end negatively.',
    type: QuestionType.CHAT,
    chapter_id: '43472023-17df-4363-8878-9d18fdfd9b74',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
  },
  {
    id: '9391d311-410c-4815-ab8c-d88f64e99ece',
    question: 'I think there’s a way we can solve this. I’m just not sure how.',
    question_img: chap4_question2,
    options: [
      {
        id: '82b019a6-5964-4e35-9426-1e95c3cdc7ba',
        option:
          'Yes, I think there’s a way. Even though we disagree, we don’t have to argue like this.',
        description: 'You’re right! Conflicts happen, but they don’t need to end negatively.',
        img: chap4_answer1,
      },
      {
        id: 'db7678ed-241b-4c04-8260-4a07076400b2',
        option: 'Not in this situation. We disagree, which makes arguments like these inevitable.',
        description:
          'I don’t think that’s true. Disagreements might be inevitable, but they don’t need to end negatively.',
        img: chap4_question2_answer,
      },
    ],
    answer: '82b019a6-5964-4e35-9426-1e95c3cdc7ba',
    answers: [],
    answer_description:
      'I like that plan! If we listen to one another and show some empathy, we might be able to defuse this conflict and remove a few misunderstandings.',
    type: QuestionType.CHAT,
    chapter_id: '43472023-17df-4363-8878-9d18fdfd9b74',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
  },
];
export const questions: JumpStartQuizQuestion[] = [
  {
    id: '29d33d30-bea0-4ead-953e-8a2847c2abbf',
    question: 'The communication process involves three primary elements: .',
    options: [
      { id: 'ed513464-243b-49e5-8070-037eb779f101', option: 'Sender' },
      { id: '626891d3-92d1-405e-9bda-814b5efb57f3', option: 'Deliver' },
      { id: 'cc2ad9d7-fc2c-4b1c-a3b3-ddf3495ff598', option: 'Message' },
      { id: '1912b46c-9ae7-4976-98b7-ac3df2a25d72', option: 'Receiver' },
    ],
    answers: [
      'ed513464-243b-49e5-8070-037eb779f101',
      'cc2ad9d7-fc2c-4b1c-a3b3-ddf3495ff598',
      '1912b46c-9ae7-4976-98b7-ac3df2a25d72',
    ],
    answer_description:
      'The communication process involves three primary elements: a sender, a message, and a receiver.',
    type: QuestionType.CHECKBOX,
  },
  {
    id: '76ae9099-4a28-437a-b830-ac23dc931d0e',
    question:
      'As the person who delivers the information, the _____ decides what to share, who to share it with, and how to deliver the message.',
    options: [
      { id: '36149453-d05e-4a1c-b903-b081a6057167', option: 'Sender' },
      { id: 'f7c9d9d4-504e-4b20-9836-fb40dbfb50f2', option: 'Messager' },
      { id: '062eeb6f-398a-469c-bf51-bfc7ffb5d9e9', option: 'Receiver' },
      { id: '16d26e88-994f-497b-8d33-d1b7f0c8aae5', option: 'Deliverer' },
    ],
    answers: ['36149453-d05e-4a1c-b903-b081a6057167'],
    answer_description:
      'As the person who delivers the information, the sender decides what to share, who to share it with, and how to deliver the message.',
    type: QuestionType.RADIO,
  },
  {
    id: '12bf4e28-2c0c-4167-a2e6-85a2ce7af4af',
    question:
      'Feedback allows you to explain your interpretation of the information and seek clarification.',
    options: [
      { id: 'a1b59720-83a2-49d3-8406-1c0014b0f660', option: 'True' },
      { id: '6f08b684-d1ae-4b28-856a-24061a6067ec', option: 'False' },
    ],
    answers: ['a1b59720-83a2-49d3-8406-1c0014b0f660'],
    answer_description: 'The statement is true.',
    type: QuestionType.RADIO,
  },
  {
    id: '77581683-6108-4004-ab1c-0397ca3eae03',
    question: 'Some of the more common reasons why communication can break down include:',
    options: [
      {
        id: 'ca3c6ac1-b477-4513-bb12-a752e1390da5',
        option: 'The sender delivers a vague or unclear message.',
      },
      {
        id: '74bf2502-0443-4637-9554-64289c86e304',
        option: 'The message isn’t delivered through the most effective medium.',
      },
      {
        id: '439a651b-75c0-421c-bc51-1c3d800e10ed',
        option: 'The message doesn’t make it to the receiver.',
      },
      {
        id: '3ee97721-c86f-40b0-84ed-7c25ea008fc7',
        option: 'The receiver is distracted.',
      },
      {
        id: 'f97eb653-1ebb-4a8d-86c9-d1f8e3e3508b',
        option: 'The receiver misunderstands the information and doesn’t give the sender feedback.',
      },
    ],
    answers: [
      'ca3c6ac1-b477-4513-bb12-a752e1390da5',
      '74bf2502-0443-4637-9554-64289c86e304',
      '439a651b-75c0-421c-bc51-1c3d800e10ed',
      '3ee97721-c86f-40b0-84ed-7c25ea008fc7',
      'f97eb653-1ebb-4a8d-86c9-d1f8e3e3508b',
    ],
    answer_description: 'All of these are common reasons why communication can break down.',
    type: QuestionType.CHECKBOX,
  },
  {
    id: '7f5ab076-74bf-4bd7-8880-f96655eb7ab4',
    question: 'Which of the following is a type of non-verbal communication?',
    options: [
      { id: '986c731b-a108-44ba-b3d1-6a9f4356b299', option: 'Letter' },
      { id: 'fb463516-11ac-4c75-8b1d-ab0e199fb361', option: 'Body language' },
      { id: '6c07cf08-a16e-4570-91fe-a136d42acfac', option: 'Telephone calls' },
      { id: '437660e2-8c55-4ed8-9dcd-a59cadae39e7', option: 'Emails' },
    ],
    answers: ['fb463516-11ac-4c75-8b1d-ab0e199fb361'],
    answer_description:
      'Body language is a type of non-verbal communication. It refers to the gestures, facial expressions, postures, and movements that convey messages without the use of words. Body language plays a crucial role in communication as it can convey emotions, attitudes, and intentions. It is a powerful tool for expressing oneself and understanding others, as it often provides more accurate information than verbal communication alone.',
    type: QuestionType.RADIO,
  },
  {
    id: '131be5a0-c8cf-41b8-bb66-f080208b0aad',
    question: 'Which of the following is not an example of effective communication skills?',
    options: [
      { id: '9893487d-01ad-48b0-89a5-1954cb7a00fe', option: 'Arms crossed' },
      { id: 'e1bf5377-c1d5-4e34-a1e3-2fb1fa4647d1', option: 'Open body posture' },
      { id: '87dfbadc-99d2-495e-8a4c-00b465b12bf9', option: 'Eye contact' },
      { id: '4c96aa1b-3f2e-4e71-ba91-dbb2829c7ec3', option: 'Speaker calm voice' },
    ],
    answers: ['9893487d-01ad-48b0-89a5-1954cb7a00fe'],
    answer_description:
      'Arms crossed is not an example of effective communication skills because it can indicate defensiveness, resistance, or a closed-off attitude. Effective communication involves open body posture, maintaining eye contact, and using a calm voice, which encourage active listening, understanding, and positive engagement between individuals.',
    type: QuestionType.RADIO,
  },
  {
    id: '2ab86b3d-08fd-42f7-a158-42644cc6b182',
    question: 'Which of the following is an example of nonverbal communication?',
    options: [
      {
        id: '8c7cb050-e4c6-4232-8d93-052885956bd5',
        option: 'Speaking',
      },
      {
        id: '17b38e96-7e6b-44b3-9231-0c389c60397e',
        option: 'Writing',
      },
      {
        id: '3c7c57e9-e77e-4e35-87a1-096c93d2a7cd',
        option: 'Using hand gestures',
      },
      {
        id: 'c94eba78-9fb0-43e4-9724-4e5ab842b1a9',
        option: 'Sending an email',
      },
    ],
    answers: ['3c7c57e9-e77e-4e35-87a1-096c93d2a7cd'],
    answer_description: 'Using hand gestures is an example of nonverbal communication.',
    type: QuestionType.RADIO,
  },
  {
    id: '3d97ae79-8111-416d-88d3-2df5d53dc9ee',
    question: 'Which of the following is an example of written communication?',
    options: [
      {
        id: '1b27dcdb-3e5c-4263-bb59-8f780fb3cd7a',
        option: 'Having a conversation',
      },
      {
        id: 'b69c036b-e130-4533-a732-f7ccb68a4bc7',
        option: 'Sending a text message',
      },
      {
        id: '9d619f2a-3a38-4d12-b0e4-41710f9f947a',
        option: 'Making a phone call',
      },
      {
        id: '1f2a3fa5-730f-4fa0-85b8-542ea451a6ec',
        option: 'Attending a meeting',
      },
    ],
    answers: ['b69c036b-e130-4533-a732-f7ccb68a4bc7'],
    answer_description: 'Sending a text message is an example of written communication.',
    type: QuestionType.RADIO,
  },
  {
    id: '1a2354c1-d25a-4b33-88fd-84913588edfb',
    question: 'What is the purpose of feedback in communication?',
    options: [
      {
        id: '2586e2d8-49da-442d-9f30-57f743c5c42e',
        option: 'To criticize and belittle others',
      },
      {
        id: '1ea9f582-9ef6-40bc-8231-77b93a5cb621',
        option: 'To express personal opinions',
      },
      {
        id: '95bfc0f3-bec8-485f-acb7-6be43ee72eeb',
        option: 'To evaluate and improve communication',
      },
      {
        id: '4a83fe4d-2c36-40bb-abfd-3e4d7c203808',
        option: 'To dominate the conversation',
      },
    ],
    answers: ['95bfc0f3-bec8-485f-acb7-6be43ee72eeb'],
    answer_description:
      'The purpose of feedback in communication is to evaluate and improve communication.',
    type: QuestionType.RADIO,
  },
  {
    id: 'fee4d0ba-6781-46ab-bf85-87ccb12dec46',
    question: 'Which communication skill involves using appropriate vocabulary and tone?',
    options: [
      {
        id: '98618929-1c08-4923-9793-ae2099f56696',
        option: 'Verbal communication',
      },
      {
        id: 'b87865f3-932a-4828-afe3-75389da6b1cc',
        option: 'Using appropriate grammar',
      },
      {
        id: 'b5530065-3f6d-4370-9717-70dca663171c',
        option: 'Nodding your head in agreement',
      },
      {
        id: '0a639fa5-fa41-48d4-8c7c-2b3e263feed9',
        option: 'Writing an email',
      },
    ],
    answers: ['98618929-1c08-4923-9793-ae2099f56696'],
    answer_description: 'Verbal communication involves using appropriate vocabulary and tone.',
    type: QuestionType.RADIO,
  },
  {
    id: 'f31dd8e4-1e31-4574-92df-e79a29df0a8d',
    question:
      'When it comes to conversations about performance at work, which of the following would help to promote the most effective communication?',
    options: [
      {
        id: '3b81c582-608c-4fcd-a515-6a85ed74d16d',
        option: 'Ask questions that require only yes or no answers',
      },
      {
        id: 'a408024b-afde-4be2-9386-e0e0ed371c5c',
        option: 'Keep the conversation one-sided to avoid losing focus',
      },
      {
        id: '22bd5da2-e0b5-482b-840f-7c251d00601f',
        option: 'Make sure any feedback is given in an assertive and direct manner',
      },
      {
        id: '3e053c04-7a39-4761-afb2-8bbb1a649c1e',
        option: 'Ask open questions',
      },
    ],
    answers: ['3e053c04-7a39-4761-afb2-8bbb1a649c1e'],
    answer_description: 'Being an active listener means asking questions and providing feedback.',
    type: QuestionType.RADIO,
  },
];
