import React from 'react';

import introImg from '../../../../assets/images/courses/perception-understanding/chapter9/introImg.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { MediaBlock } from '../../MediaBlock';
import { TabsBlock } from '../../TabsBlock';

interface PerciptionProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const PerceptionAndUnderstandingChapter9: React.FC<PerciptionProps> = ({
  onNextChapter,
  chapter,
}) => {
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const TabsData = [
    {
      title: 'Do',
      htmlContent: `<ul class="list-disc pl-5"><li class="mb-5 pl-5">Treat everyone as a unique individual – remembering we are a blend of ALL the colour energies – not just one.</li><li class="mb-5 pl-5">Be mindful of other factors in an individual’s life which could affect their choices – take a holistic approach where necessary.</li><li class="mb-5 pl-5">Identify, and draw attention to, the difference between your opinions (or personal judgements) – and facts.</li><li class="mb-5 pl-5">Keep your input as general as possible – it’s easy to prejudice others’ opinions. It is far better to ask more questions than you answer.</li><li class="mb-5 pl-5">Jung said “Every individual is an exception to the rule.” Understand and allow for this.</li><li class="mb-5 pl-5">Remember this is a behavioural model – it is about preference, not capability. It is not a measure of skill or intelligence. It describes but does not define.</li></ul>`,
    },
    {
      title: 'Donts',
      htmlContent: `<ul class="list-disc pl-5"><li class="mb-5 pl-5">Box anyone in – ever!</li><li class="mb-5 pl-5">‘Diagnose’ or interpret ‘facts’ using the colours.</li><li class="mb-5 pl-5">Make suppositions about a given colour spread.</li><li class="mb-5 pl-5">Use the colours in a belittling or disparaging &nbsp;way – or ‘hide’ behind your own colour spread (e.g. just because you have Blue as least preferred, doesn’t mean you can’t do a spreadsheet!).</li><li class="mb-5 pl-5">Restrict anyone’s ability to have transcended their behavioural preferences by experience, education, and perseverance.</li><li class="mb-5 pl-5">Say someone is ‘wrong’ or demean their choice of colour spread (even to someone else) or tell someone what colour you believe they are before they have chosen for themselves.</li></ul>`,
    },
  ];
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-xl font-light">
        <h2 className="font-semibold text-3xl">Summary</h2>
        <p className="text-sm italic">Part 9 of 10</p>
        <MediaBlock mediaImage={introImg}>
          <p className="text-3xl w-3/4">
            Thank you for completing this lesson. You may exit the lesson by closing the browser
            window.
          </p>
        </MediaBlock>
        <p className="mt-8 mb-8">
          Our perception of the world influences how we react or respond. Preferences shape our
          experience of the world and our behavior in it. The Ladder of Inference and Insights
          Discovery provides us with tools to understand ourselves and those around us.
          <br />
          <br />
          Our cultural narrative shapes our individual experiences of how we perceive and explain
          what is out there. Becoming more aware of this process is the first step to a new way of
          thinking that might help us resolve the many problems our cultures face today. By becoming
          aware of these different steps in the ladder, we can empower ourselves to question our own
          conclusions and beliefs.
        </p>
        <p className="font-bold mb-7">
          Throughout this lesson we covered the following objectives:
        </p>
        <ul className="list-disc pl-5">
          <li className="pl-5 mb-7">
            Understand the power of perception and the Ladder of Inference
          </li>
          <li className="pl-5 mb-7">
            Apply the 'Running up the Ladder' metaphor to understand the implications of negative
            perceptions
          </li>
          <li className="pl-5 mb-7">Identify and explain the Jungian preferences</li>
          <li className="pl-5 mb-7">
            Understand the influence our unique preferences has on our world-view
          </li>
          <li className="pl-5 mb-7">Distinguish between the four Insights Color Energies</li>
          <li className="pl-5 mb-7">
            Identify your, and others', color mix and understand the impact of your personal style{' '}
          </li>
          <li className="pl-5 mb-7">
            Adapt your style to get the best out of a situation by understanding personal
            preferences
          </li>
        </ul>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-6">
          Do & Don'ts with Insights Discovery
        </h3>
        <p className="font-bold mb-7">
          As you work and interact with others, keep the following do and don'ts in mind:
        </p>
        <TabsBlock tabsData={TabsData} />
        <button onClick={handleNextChapterClick} className="w-full btn-primary uppercase mt-8">
          QUIZ
        </button>
      </div>
    </>
  );
};
