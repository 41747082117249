import { Close, KeyboardArrowDown, Menu } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import logo from '../../assets/images/landing/logo.svg';
import avatar from '../../assets/images/Muser.jpg';
import { useUserProfileContext } from '../../hooks/user-user-profile-context.hooks';
import { dispatchLogout } from '../../redux/slice/auth/auth.operations';
import { dispatchGetUserProfileValues } from '../../redux/slice/user-profile';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import Container from '../container/Container';

import { Footer } from './Footer';

export const OrganizationAdminLayout: React.FC = () => {
  const userProfileContext = useUserProfileContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    void dispatch(dispatchGetUserProfileValues());
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [userProfileContext]);

  const handleMenuToggle = () => {
    setToggleMenu((prevState) => !prevState);
  };

  const handleProfileToggle = () => {
    setProfileDropdown((prevState) => !prevState);
  };

  const handleLogout = async () => {
    try {
      await dispatch(dispatchLogout());
      toast.success('Logged out!');
      navigate('/login');
      window.location.reload();
    } catch (e) {
      logger.error('Failed to logout.', e);
      toast.error('Logout failed.');
    }
  };

  return (
    <>
      <div className="min-h-screen">
        <ToastContainer theme="colored" limit={1} autoClose={1000} position="top-center" />
        <div className="w-full block">
          <header className={`py-3 relative w-full z-10 shadow bg-white`}>
            <Container>
              <nav className="navbar flex justify-between">
                <div className="navbar-brand mr-4">
                  <Link className="cursor-pointer" to="/organization-dashboard">
                    <div>
                      <img src={logo} alt="" />
                    </div>
                  </Link>
                </div>
                <button onClick={handleMenuToggle} type="button" className="navbar-toggle hidden">
                  <Menu sx={{ fontSize: '36px' }} />
                </button>
                <ul
                  style={{ right: toggleMenu ? '0' : '100%' }}
                  className={`navbar-nav ${
                    toggleMenu ? 'relative' : ''
                  } flex items-center gap-8 ml-auto`}
                >
                  <div className="bg-purple-50">
                    <div className="relative inline-block text-left lg:hidden">
                      <div className="flex items-center gap-3 p-4" onClick={handleProfileToggle}>
                        <div className="w-20 h-20 rounded-full overflow-hidden">
                          <img className="w-full h-full object-cover" src={avatar} alt="" />
                        </div>
                        <div className="relative">
                          <a className="font-bold capitalize">{userProfileContext.name}</a>
                          <p className="text-sm text-blue-500 cursor-pointer">
                            <KeyboardArrowDown />
                          </p>
                          {profileDropdown && (
                            <div
                              className="absolute left-0  z-10 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              role="menu"
                              aria-orientation="vertical"
                              aria-labelledby="menu-button"
                              onClick={handleProfileToggle}
                              ref={menuRef}
                            >
                              <div className="py-1">
                                <ul>
                                  <li>
                                    <Link
                                      className="text-gray-700 block cursor-pointer"
                                      to="/organization-dashboard"
                                    >
                                      Dashboard
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="text-gray-700 block cursor-pointer"
                                      to="/change-password"
                                    >
                                      Change Password
                                    </Link>
                                  </li>
                                  <li>
                                    <button
                                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                      onClick={handleLogout}
                                      className="text-purple-700 block font-semibold cursor-pointer"
                                    >
                                      Log Out
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: toggleMenu ? 'block' : 'none' }}
                    className="absolute top-[30px] right-[16px]"
                    onClick={handleMenuToggle}
                  >
                    <Close sx={{ fontSize: '36px' }} />
                  </div>
                  <li className="hidden lg:block">
                    <div className="relative inline-block text-left">
                      <div className="flex items-center gap-3" onClick={handleProfileToggle}>
                        <div className="w-12 h-12 rounded-full overflow-hidden">
                          <img
                            className="w-full h-full object-cover"
                            src={userProfileContext?.profileImagePath || avatar}
                            alt=""
                          />
                        </div>
                        <div>
                          <p className="font-bold capitalize">{userProfileContext?.name}</p>
                          <a className="text-sm text-blue-500 cursor-pointer">
                            <KeyboardArrowDown />
                          </a>
                        </div>
                      </div>
                      {profileDropdown && (
                        <div
                          className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          onClick={handleProfileToggle}
                          ref={menuRef}
                        >
                          <div className="py-1">
                            <ul>
                              <li>
                                <Link
                                  className="text-gray-700 block px-4 py-3 cursor-pointer"
                                  to="/dashboard"
                                >
                                  Dashboard
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="text-gray-700 block px-4 py-3  cursor-pointer"
                                  to="/change-password"
                                >
                                  Change Password
                                </Link>
                              </li>
                              <li>
                                <button
                                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                  onClick={handleLogout}
                                  className="text-purple-700 block px-4 py-3 font-semibold"
                                >
                                  Log Out
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              </nav>
            </Container>
          </header>
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};
