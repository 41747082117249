import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller, Resolver } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import character from '../../assets/images/intake/step2/char.svg';
import { useIntakeFormContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import { dispatchUpdateIntakeFormAgeRange } from '../../redux/slice/form-intake/form-intake.operations';
import { Gender, IntakeFormAgeRangeDTO } from '../../redux/slice/form-intake/form-intake.types';
import { AgeRange as AgeRangeValue } from '../../redux/slice/form-intake/form-intake.types';
import { PreEmploymentAssessmentStatus } from '../../redux/slice/pre-employment-assessment';
import { dispatchUpsertPreEmploymentAssessment } from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import { allowOnlyNumbers, handlePasteOnlyNumbers } from '../../utils/helper';

interface FormData extends Omit<IntakeFormAgeRangeDTO, 'ageRange'> {
  ageRange: string;
  age: number | null;
  gender: Gender | null;
  otherGender: string | null;
}
interface AgeRangeProps {
  step: number;
  onBack: () => void;
}
const age_options = (Object.keys(AgeRangeValue) as Array<keyof typeof AgeRangeValue>)
  .filter((key) => typeof AgeRangeValue[key] === 'string')
  .map((key) => ({ label: AgeRangeValue[key], value: AgeRangeValue[key] }));
const gender_options = (Object.keys(Gender) as Array<keyof typeof Gender>)
  .filter((key) => typeof Gender[key] === 'string')
  .map((key) => ({ label: Gender[key], value: Gender[key] }));
const ageSchema = yup
  .object({
    ageRange: yup.string().required('Required'),
    age: yup
      .number()
      .label('Age')
      .typeError('Age must be a valid number')
      .when('ageRange', {
        is: (val: string) => String(val) === AgeRangeValue.VALUE_35_AND_OVER,
        then: (ageSchema) =>
          ageSchema
            .required('Required')
            .positive()
            .integer()
            .min(36, 'Age must be greater than 35'),
        otherwise: (ageSchema) => ageSchema.nullable().default(null),
      }),
    gender: yup.string().required('Gender is required'),
    otherGender: yup.string().when('gender', {
      is: (val: string) => val === 'Other',
      then: (genderSchema) => genderSchema.required('Required'),
      otherwise: (genderSchema) => genderSchema.nullable().default(null),
    }),
  })
  .required();
export const AgeRange: React.FC<AgeRangeProps> = ({ step, onBack }) => {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const {
    status: preEmploymentAssessmentStatus,
    disablePersonalInfo,
    progress,
    defaultStepProgress,
  } = usePreEmploymentAssessmentContext();

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);

  const { ageRange: ageRanges, age: actualAge, gender, otherGender } = useIntakeFormContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: { ageRange: String(ageRanges) || '', age: null },
    resolver: yupResolver(ageSchema) as Resolver<FormData>,
  });
  const dispatch = useAppDispatch();
  const submitAgeRange: SubmitHandler<FormData> = async (data: FormData) => {
    if (!data?.ageRange?.includes(AgeRangeValue.VALUE_35_AND_OVER)) {
      data.age = null;
    }
    if (!data?.gender?.includes(Gender.OTHER)) {
      data.otherGender = null;
    }
    logger.debug('Submitted age range.', data);
    setIsLoading(true);
    if (isDisabled) {
      navigate(`/pre-employment/intake/step${step + 1}`);
    } else {
      try {
        await dispatch(dispatchUpdateIntakeFormAgeRange(data as IntakeFormAgeRangeDTO));
        navigate(`/pre-employment/intake/step${step + 1}`);
        if (defaultStepProgress) {
          const totalProgress = (progress ?? 0) + defaultStepProgress;
          void dispatch(
            dispatchUpsertPreEmploymentAssessment({
              progress: ageRanges ? progress : totalProgress,
              activeStepUrl: `/pre-employment/intake/step${step + 1}`,
            }),
          );
        } else {
          void dispatch(
            dispatchUpsertPreEmploymentAssessment({
              activeStepUrl: `/pre-employment/intake/step${step + 1}`,
            }),
          );
        }
      } catch {
        toast.error('Something went wrong!');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const backButtonClick = () => {
    onBack();
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll to the top
  }, []);

  useEffect(() => {
    const data = {
      ageRange: ageRanges || '',
      age: actualAge || null,
      gender: gender || null,
      otherGender: otherGender || '',
    };
    reset(data);
  }, [ageRanges, actualAge, gender, otherGender, reset]);

  return (
    <div>
      <div className="flex mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">02</div>
        <div>
          <h4 className="text-2xl">
            Age <span className="font-semibold"> Range</span>
          </h4>
          <p className="text-lg">Help us know you better by selecting your age range:</p>
        </div>
      </div>
      <div className="character absolute bottom-0 left-[-85px] lg:left-[-138px]">
        <img className="" src={character} alt="" />
      </div>
      <Box
        className="md:px-10"
        component="form"
        sx={{
          '& > :not(style)': { mb: 4 },
        }}
        autoComplete="off"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitAgeRange)}
      >
        <div className="">
          <div>
            <Controller
              control={control}
              name="ageRange"
              render={({ field: { value, onChange } }) => (
                <TextField
                  className="w-full"
                  id="outlined-select-age"
                  select
                  disabled={isDisabled}
                  label="Age Range"
                  onChange={(event) => onChange(event.target.value)}
                  value={value}
                  helperText=""
                >
                  <MenuItem key="select" value="">
                    Select
                  </MenuItem>
                  {age_options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            ></Controller>
            <div>
              {errors.ageRange && (
                <div>
                  <p className="text-red-500">{errors.ageRange.message}</p>
                </div>
              )}
            </div>
          </div>

          <div>
            {watch().ageRange?.includes(AgeRangeValue.VALUE_35_AND_OVER) ? (
              <div className="mt-7">
                <Controller
                  control={control}
                  name="age"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      className="w-full"
                      id="outlined-select-age"
                      disabled={isDisabled}
                      label="Age"
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      helperText=""
                      inputProps={{
                        pattern: '^[0-9]*$',
                        onKeyPress: allowOnlyNumbers,
                        onPaste: handlePasteOnlyNumbers,
                        maxLength: 2,
                      }}
                    ></TextField>
                  )}
                ></Controller>
                <div>
                  {errors.age && (
                    <div>
                      <p className="text-red-500">{errors.age.message}</p>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="mt-5">
            <label className="text-purple-700 text-sm font-semibold mb-2 inline-block">
              Gender
            </label>
            <Controller
              control={control}
              name="gender"
              render={({ field: { value, onChange } }) => (
                <div className="grid grid-cols-1 gap-3">
                  <div className="border-gray-500 py-3 px-6 rounded-lg flex md:flex-nowrap flex-wrap label-width-wrapper">
                    {gender_options.map((option) => (
                      <label
                        key={option.label}
                        className="cr-radio-container font-light mr-8 label-width"
                      >
                        {option.label}
                        <input
                          disabled={isDisabled}
                          type="radio"
                          checked={value == option.value}
                          onChange={(event) => onChange(event.target.value)}
                          value={option.value}
                          name="gender"
                        />
                        <span className="cr-checkmark"></span>
                      </label>
                    ))}
                  </div>
                </div>
              )}
            ></Controller>
            <div>
              {errors.gender && (
                <div>
                  <p className="text-red-500">{errors.gender.message}</p>
                </div>
              )}
            </div>
          </div>
          {watch().gender?.includes(Gender.OTHER) ? (
            <div className="mt-7">
              <Controller
                control={control}
                name="otherGender"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    className="w-full"
                    id="outlined-select-gender"
                    disabled={isDisabled}
                    label="Other Gender"
                    onChange={(event) => onChange(event.target.value)}
                    value={value}
                    helperText=""
                    error={!!errors.otherGender}
                    inputProps={{
                      maxLength: 20,
                    }}
                  ></TextField>
                )}
              ></Controller>
              <div>
                {errors.otherGender && (
                  <div>
                    <p className="text-red-500">{errors.otherGender.message}</p>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
        <div
          className={`flex justify-end ${!disablePersonalInfo ? 'lg:justify-between' : ''} mt-8`}
        >
          {!disablePersonalInfo && (
            <button onClick={backButtonClick} className="btn-primary">
              Back
            </button>
          )}
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </Box>
    </div>
  );
};
