import React from 'react';

interface IIntakeProps {
  children: React.ReactNode;
  className?: string;
  step: number;
}
export const SWOTHeader: React.FC<IIntakeProps> = ({ children, step }) => {
  const renderSWOTHeader = () => {
    switch (step) {
      case 1:
      case 2:
      case 3:
        return (
          <div className="text-2xl p-4 text-white ">
            <span className="font-semibold"> SWOT </span> ANALYSIS
          </div>
        );
      case 4:
        return (
          <div className="text-2xl p-4 text-white ">
            <span className="font-semibold"> FEEDBACK </span> SURVEY
          </div>
        );
      default:
        return (
          <div className="text-2xl p-4 text-white ">
            <span className="font-semibold"> RESOURCES </span>
          </div>
        );
    }
  };
  return (
    <div className="flex items-center justify-center w-full">
      <div
        className={`mx-auto w-full max-w-4xl bg-white-100 py-8 px-5 shadow-xl border-l border-r relative bg-white`}
      >
        <div className="mb-2 text-center theme-gradient rounded-xl">
          <>{renderSWOTHeader()}</>
        </div>

        <div>{children}</div>
      </div>
    </div>
  );
};
