import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  AgreementLevelQuestion,
  EmploymentReadinessAnswer,
} from '../../assessments/employment-readiness/employment-readiness.types';
import character from '../../assets/images/intake/step10/char.svg';
import { useEmploymentReadinessContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  AgreementLevel,
  dispatchUpdateEmploymentReadiness,
  EmploymentReadinessState,
} from '../../redux/slice/form-employment-readiness';
import {
  dispatchUpsertPreEmploymentAssessment,
  PreEmploymentAssessmentStatus,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';

import { convertValueAgreementLevelToString, JobSkillOptions } from './JobInterview';

interface ComponentProps {
  questions: AgreementLevelQuestion[];
  step: number;
  onBack: () => void;
}

type AnswersState = {
  [questionId: string]: EmploymentReadinessAnswer;
};

export const WorkAttitude: React.FC<ComponentProps> = ({ questions, onBack }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
  } = usePreEmploymentAssessmentContext();
  const savedAnswers: EmploymentReadinessState = useEmploymentReadinessContext();
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const [answers, setAnswers] = useState<AnswersState>({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);
  function updateAnswer(
    questionId: string,
    updatedAnswer: Partial<EmploymentReadinessAnswer>,
  ): void {
    setError('');
    setAnswers((previousState: AnswersState) => ({
      ...previousState,
      [questionId]: {
        ...answers[questionId],
        ...updatedAnswer,
      },
    }));
  }

  const { handleSubmit } = useForm();
  const backButtonClick = () => {
    onBack();
  };
  const hasMissingAnswers: boolean = questions.some((question: AgreementLevelQuestion): boolean => {
    if (!answers[question.id]) {
      return true;
    }

    return false;
  });
  const submitAssessment = async () => {
    logger.debug('Submitted job attitude skills form.', answers);
    setIsLoading(true);
    const error_message: string = 'Please select an option for each question';

    try {
      if (hasMissingAnswers) {
        setError(error_message);
      } else {
        if (isDisabled) {
          navigate('/pre-employment/skills/step1');
        } else {
          await dispatch(dispatchUpdateEmploymentReadiness(answers));
          navigate('/pre-employment/skills/step1');
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: savedAnswers[questions[0].id] ? progress : totalProgress,
                activeStepUrl: `/pre-employment/skills/step1`,
                didCompleteEmploymentReadiness: true,
                syncedToAirtable: false,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/skills/step1`,
                didCompleteEmploymentReadiness: true,
                syncedToAirtable: false,
              }),
            );
          }
        }
      }
    } catch (err) {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (savedAnswers && Object.keys(savedAnswers).length) {
      setAnswers(savedAnswers);
    }
  }, [savedAnswers]);

  return (
    <div>
      <p className="text-lg mt-7 md:px-10">
        Welcome to the "Toward Self Discovery" series, focusing on Work Attitude!
      </p>
      <p className="text-lg mt-7 md:px-10">
        Work attitude is the feeling you have towards the different aspects of the environment you
        have at work. How you feel about your work often affects your behavior in the workplace.
      </p>
      <p className="text-lg mt-7 md:px-10">
        Your work attitude plays a significant role in how you approach and engage with your
        professional environment. It reflects your feelings and behaviors within the workplace,
        ultimately shaping your overall experience. In this section, we'll explore various aspects
        of your work attitude.
      </p>
      <p className="text-lg mt-7 md:px-10">
        You have the flexibility to complete this assessment in one sitting or return later to
        finish the remaining sections. Remember, this journey is all about self-discovery, and we're
        excited to be a part of it with you.
      </p>
      <p className="text-lg mt-7 md:px-10">
        We're here to help you understand your natural competencies, experiences, interests, and
        skill sets related to your work attitude. By reflecting on these statements and rating your
        agreement, you'll gain valuable insights into your work-related attitudes.
      </p>
      <p className="text-lg mt-7 md:px-10">
        Let's embark on this path toward self-improvement and career success together!
      </p>
      <div className="flex mt-9 mb-10 md:px-10">
        <div>
          <h4 className="text-2xl"></h4>
        </div>
      </div>
      <div className="character absolute top-96 right-[-125px] z-[-1]">
        <img className="" src={character} alt="" />
      </div>
      <form
        className=" md:px-16"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitAssessment)}
      >
        <ol className="pl-5" style={{ listStyle: 'upper-latin' }} type="A">
          {questions.map((question: AgreementLevelQuestion) => (
            <li className="mb-5 font-semibold" key={question.id}>
              {question.question}
              <div className="rating-btns flex flex-wrap items-center mt-3">
                {JobSkillOptions.map((option) => (
                  <div key={option.label} className="h-[55px] mr-4 mb-3 lg:mb-0">
                    <label className="radio-container h-full block relative">
                      <input
                        disabled={isDisabled}
                        checked={
                          convertValueAgreementLevelToString(answers[question.id]) === option.value
                        }
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          updateAnswer(question.id, {
                            question: question.question,
                            answer: event.target.value as AgreementLevel,
                          })
                        }
                        value={option.value}
                        className="w-full h-full absolute top-0 left-0 opacity-0"
                        type="radio"
                        name={`work-${question.id}`}
                      />
                      <span className="checkmark  !static !h-[55px] !w-auto !py-4 !px-5 !text-sm !whitespace-nowrap font-light !text-black">
                        {option.label}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </li>
          ))}
        </ol>
        {error && <p className="text-red-500 font-light !text-sm">{error}</p>}
        <div className="flex justify-end lg:justify-between lg:mt-20 mt-10">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
