import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import chat_background from '../../../../assets/images/courses/communication-basics/chapter9/james/chat_background.jpeg';
import chat_character from '../../../../assets/images/courses/communication-basics/chapter9/james/chat_character.png';
import {
  course_chat_questions as CourseChatQuestions,
  course_sorting_questions as sortingQuestions,
} from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import { ShortingMatchingBase } from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { dispatchUpdateUserCourseQuestionResponse } from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { ActionableBlock } from '../../ActionableBlock';
import { SimpleCarouselComponent } from '../../SimpleCarouselComponent';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const CommunicationBasicsChapter9: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();

  const chapterSortingQuestions = sortingQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );

  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingRetake = async (questionId: string) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const courseChatQuestions = CourseChatQuestions.filter((obj) => obj.chapter_id === chapter.id);
  const slidesData = [
    {
      heading: 'Emails',
      content: `<p>Often seen as a workplace staple, emails are typed-out messages sent electronically over the internet to one or several people. These messages are great for both <span class="font-bold">formal</span> and <span class="font-bold">informal</span> communication with colleagues, business associates, and friends.</p><p><br/></p>
      <p>Immediately after you tap “send,” an email will land in your recipient’s inbox. But there’s no expectation that your receiver will respond right away. That’s because emails are <span class="font-bold">asynchronous</span>, which means the communication is not immediate.</p>`,
    },
    {
      heading: 'Instant Messages',
      content: `<p>Instant messages are a type of friendly, <span class="font-bold">informal</span> communication. These messages are electronically sent over the internet and pop up on your recipient’s screen as soon as you press “enter.” Similar to email, you can send an instant message to a group of people or just one person.</p>
      <p><br/></p><p>This type of communication is rapidly changing the modern workplace. Services such as Slack, Google Hangouts, Jabber, and Spark (to name a few) are making it possible for professionals to have <span class="font-bold">synchronous</span>, or real-time, written conversations with colleagues and external professionals alike.</p>`,
    },
    {
      heading: 'Reports',
      content: `<p>Reports are detailed written messages that cover data and in-depth information. You can present a report to those within your office or outside of your business.</p>
      <p><br/></p>
      <p>This medium is factual, based on research, and formal. A report can be shared as an email attachment or through paper mail—both methods being <span class="font-bold">asynchronous</span> (delayed).</p>`,
    },
    {
      heading: 'Letters',
      content: `<p>A letter will immediately set a more <span class="font-bold">formal</span> tone for your communication. These messages can be handwritten or typed and sent to both those within and outside of your business.</p><p><br/></p>
      <p>Letters are most often transmitted by paper mail and are a form of <span class="font-bold">asynchronous</span> (delayed) communication. After you send a letter, there is no expectation that you’ll hear back from your reader right away.</p>`,
    },
  ];
  const accordionData = [
    {
      id: 'panel1',
      title: 'Emails',
      content:
        '<div class="fr-view"><p><strong>Your message requires extra thought. </strong>Because emails are asynchronous, they give both you and your receiver extra time to think through your writing. Think about how you can edit and change an email several times before you finally press “send.”</p><p>&nbsp;</p><p><strong>You need to share brief information. </strong>Have you ever opened an email that seemed to go on forever? How eager were you to read that entire message? Emails are best when your communication is short and centered on one topic. (Here’s a trick: If you can’t easily fit the purpose of your message into the subject line, then you might be using the wrong medium.)</p><p>&nbsp;</p><p><strong>Your message isn’t urgent. </strong>Because emails are asynchronous (delayed), there’s no telling when your receiver will get back to you. For example, if you send an email to several people with the message: <em>“We’re having a team conference in 5 minutes,” </em>there’s a high probability your meeting will have a low attendance rate.</p></div>',
    },
    {
      id: 'panel2',
      title: 'Instant Messages',
      content:
        '<div class="fr-view"><p><strong>You need to connect in real time.&nbsp;</strong>Instant messages are a unique form of written communication because they are synchronous (immediate). Your receiver will see your note and be able to respond right away.</p><p>&nbsp;</p><p><strong>Your message is urgent.</strong> Let’s say you’re on a call and your colleague is about to share the wrong data. Instead of interrupting the conversation or waiting to correct your coworker afterward, you could send them a quick instant message: <em>“The numbers just changed! We’re now at 30% instead of 28%.”</em></p><p><strong>&nbsp;</strong></p><p><strong>Your message is simple and short.&nbsp;</strong>Because instant messages will disrupt your receiver, they’re best used when your communication is straightforward and quick. One-sentence statements or questions such as, <em>“What time is that meeting again?</em>” or <em>“The video has been uploaded.”&nbsp;</em>are perfect for this medium.</p></div>',
    },
    {
      id: 'panel3',
      title: 'Reports',
      content:
        '<div class="fr-view"><p><strong>You need to share in-depth information.</strong> Business reports are lengthy. Unlike other types of written communication, your report can be several pages long and include a title page, summary, and conclusion. If you have a lot of information to share, a report is a great way to keep those details structured.</p><p><strong>&nbsp;</strong></p><p><strong>Your message is factual.&nbsp;</strong>A business report is based on research and facts. If you need to share data, a report is an ideal medium. Think about how businesses write financial reports to summarize their earnings and growth.</p><p><br></p><p><strong>Y</strong><strong>our message is formal and impartial.&nbsp;</strong>Because reports are research-based, it’s best only to use them if your message is formal and unbiased. Consider how you can discredit a report if the details are highly opinionated or one-sided.&nbsp;</p></div>',
    },
    {
      id: 'panel4',
      title: 'Letters',
      content:
        '<div class="fr-view"><p><strong>Your message is private.&nbsp;</strong>Have you ever received a letter with <em>“confidential”</em> written across the top? Because letters are sent through paper mail and individually addressed, they’re often regarded as a more private mode of communication.</p><p>&nbsp;</p><p><strong>Your message is formal.&nbsp;</strong>You open the mailbox and see a handwritten, sealed envelope with your name etched across the top. If your first thought is,&nbsp;<em>“This must be important!”</em> you’re not alone. Because of their formal nature, letters are excellent for invitations and significant announcements. For example, if your business is moving or if you’re announcing a merger, a letter is a great medium to choose.</p><p>&nbsp;</p><p><strong>Your message needs to be documented.&nbsp;</strong>Often, businesses will send letters for messages that are more serious or require a paper trail. In this case, you can send a certified business letter to confirm the message has been received. Consider how we often send legal documents and warning notices through certified mail.</p></div>',
    },
  ];

  useEffect(() => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userSortingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Choosing the Right Writing Medium</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-16">
          There are countless ways we share words with others. We can send brief emails to our
          colleagues, clients, and friends. We can quickly type out instant messages to deliver
          urgent information, carefully compose in-depth reports, or, for those occasions that
          require some extra care, send out formal letters.
          <br />
          <br />
          Each of these four communication mediums has a unique function. By understanding these
          functions, you can better determine how to select the right medium for your communication.
        </p>
        <p className="mb-8">
          In this lesson, we’ll explore what makes emails, instant messages, reports, and letters
          unique. We’ll then take an in-depth look at the functions of each of these mediums so that
          you can determine which one will serve you best.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/AFXfa8jcN_ZH5f74wUC4NcBGgrxm353L/svVmBcGK/assets/rbc/t7zEf-3SrTHDP67L_COMM-WW-02.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          Four Written Communication Mediums
        </h3>
        <p className="mb-8">
          When sharing your written messages with others, there are numerous mediums from which you
          can choose. If we take a look at the ones you’ll be most likely to find in the business
          world, the selection boils down to four options: emails, instant messages, reports, or
          letters. Click on the forward arrow to see a breakdown of these mediums and what makes
          them unique:.
        </p>
        <div className="border rounded-3xl">
          <SimpleCarouselComponent data={slidesData} />
        </div>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          The Functions of Each Written Medium
        </h3>
        <p className="mb-8">
          When deciding which written medium to select, consider the unique functions that emails,
          instant messages, reports, and letters serve. Expand the rows below by clicking on the (+)
          signs to learn when to use each of these methods.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Select the Right Medium for Your Communication
        </h3>
        <p>Based on what you’ve learned, can you match the messages below with the right medium?</p>
        <DndProvider backend={HTML5Backend}>
          <CourseSortingQuestionBlock // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetake={handleSortingRetake}
            question={chapterSortingQuestions[0]}
            submitedCorrectAnswerCount={
              userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[
                chapter.id
              ]?.sortingQuestions
                ?.filter((x) =>
                  Object.keys(x).filter((key) => key === chapterSortingQuestions[0].id),
                )
                .map(
                  (x) =>
                    x[chapterSortingQuestions[0].id].filter(
                      (x) => x.answer?.id === x.correct_answer?.id,
                    ).length,
                )[0] ?? null
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            submitSortingQuestion={handleSortingExcerciseCompleted}
          />
        </DndProvider>
        <ActionableBlock
          backgroundImage={chat_background}
          courseChatQuestions={courseChatQuestions}
          characterImage={chat_character}
          feedbackTitle="Congratulations"
          feedbackText="You successfully fielded all those questions from your boss. Great job!"
          buttonName="Continue"
        />
        <p className="text-2xl text-center leading-10 font-light mb-10">
          Once you know the purpose of your message, it's time to choose the right medium to
          communicate that message. Continue on to the next lesson for some tips on how to do that.
        </p>

        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          WRITING FOR YOUR AUDIENCE
        </button>
      </div>
    </>
  );
};
