import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import { useUserAsaTaskHistoryContext } from '../../hooks/use-user-asa-task-history-context.hooks';
import { useAuthContext, useAuthInitializedContext } from '../../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../../hooks/user-user-profile-context.hooks';
import { AsaUserTasks, dispatchGetAsaTaskHistoryValues } from '../../redux/slice/asa-task-history';
import { UserRole } from '../../redux/slice/auth';
import { CustomUser } from '../../redux/slice/auth/CustomUser';
import { dispatchUpsertPreEmploymentAssessment } from '../../redux/slice/pre-employment-assessment';
import { dispatchGetUserProfileValues } from '../../redux/slice/user-profile';
import { useAppDispatch } from '../../redux/store';
import { isASATaskRequest } from '../../utils/helper';
import { CircularLoader } from '../loader/CircularLoader';

export interface AuthenticatedPageProps {
  render: () => JSX.Element;
  isAsaInfoPage?: boolean;
  isStartPage?: boolean;
  isUpgradePage?: boolean;
  isPaymentSuccessPage?: boolean;
  isPaymentFailedPage?: boolean;
  isSwotPage?: boolean;
}

export const AuthenticatedPage: React.FC<AuthenticatedPageProps> = ({
  render,
  isAsaInfoPage,
  isStartPage,
  isUpgradePage,
  isPaymentSuccessPage,
  isPaymentFailedPage,
  isSwotPage,
}: AuthenticatedPageProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user: CustomUser | null = useAuthContext();
  const isAuthenticated: boolean = user ? true : false;
  const isAuthInitialized: boolean = useAuthInitializedContext();
  const [targetInitialized, setTargetInitialized] = useState(false);
  const [targetUrl, setTargetUrl] = useState<string | undefined>(undefined);
  const [currentAsaTask, setCurrentAsaTask] = useState<AsaUserTasks | undefined>(undefined);
  const queryParams: URLSearchParams = new URLSearchParams(location.search);
  const isASARequest = isASATaskRequest(queryParams);
  const asaTaskHistoryContext = useUserAsaTaskHistoryContext();
  const asaUserProfileContext = useUserProfileContext();

  useEffect(() => {
    if (
      isAuthenticated &&
      user?.claims.isAsaUser &&
      !targetInitialized &&
      !asaTaskHistoryContext?.tasks?.length
    ) {
      void dispatch(dispatchGetUserProfileValues());
      void dispatch(dispatchGetAsaTaskHistoryValues());
      void dispatch(dispatchUpsertPreEmploymentAssessment());
    } else if (isAuthenticated) {
      setTargetInitialized(true);
    }
  }, [isAuthenticated, asaTaskHistoryContext]);

  const { didAsaUserViewedSwot, isSWOTGenerated, activeStepUrl, didCompleteIntake } =
    usePreEmploymentAssessmentContext();

  useEffect(() => {
    if (
      Object.keys(asaTaskHistoryContext).length &&
      asaTaskHistoryContext?.tasks?.length &&
      Object.keys(asaUserProfileContext).length
    ) {
      const currentTask = asaTaskHistoryContext.tasks?.find((x) => !x.isCompleted);
      setCurrentAsaTask(currentTask);
      if (asaUserProfileContext.asaUserId)
        setTargetUrl(
          currentTask?.taskUrl
            ? currentTask?.taskUrl
            : asaUserProfileContext?.isPremium
            ? '/dashboard'
            : didAsaUserViewedSwot
            ? '/upgrade'
            : '/swot',
        );
      else if (!isAsaInfoPage) {
        if (activeStepUrl) {
          if (isSWOTGenerated) {
            if (!asaUserProfileContext.isPremium) {
              navigate('/upgrade');
            } else {
              navigate('/dashboard');
            }
          } else {
            if (!didCompleteIntake) {
              navigate('/pre-employment/start');
            } else {
              navigate(activeStepUrl);
            }
          }
        }
      }
    }
  }, [asaTaskHistoryContext, asaUserProfileContext, targetUrl, didAsaUserViewedSwot]);

  if (isASARequest) {
    const user_id: string | null = queryParams.get('user_id');
    const unique_task_key: string | null = queryParams.get('unique_task_key');
    const step_number: string | null = queryParams.get('step_number');
    sessionStorage.setItem(
      'asa_params',
      JSON.stringify({ user_id, unique_task_key, step_number, task_url: location.href }),
    );
  }
  if (!isAuthInitialized || (!targetInitialized && isAuthenticated)) {
    return (
      <div>
        <CircularLoader />
      </div>
    );
  } else if (!isAuthenticated && isASARequest) {
    return <Navigate to="/signup" />;
  } else if (!isAuthenticated) {
    sessionStorage.setItem('previousUrl', window.location.pathname);
    return <Navigate to="/login" />;
  } else if (user?.claims.role === UserRole.ORGANIZATION_ADMIN) {
    return <Navigate to="/organization-dashboard" />;
  } else if (
    user?.claims.isAsaUser &&
    targetUrl &&
    isStartPage &&
    !asaUserProfileContext?.isPremium
  ) {
    window.location.href = targetUrl;
    return null;
  } else if (
    user?.claims.isAsaUser &&
    targetUrl &&
    !currentAsaTask &&
    !isUpgradePage &&
    !isPaymentSuccessPage &&
    !isPaymentFailedPage &&
    !asaUserProfileContext?.isPremium &&
    !isSwotPage
  ) {
    return <Navigate to={targetUrl} />;
  } else {
    return render();
  }
};
