import { uuidv4 } from '@firebase/util';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircle, Delete, Edit, Upload } from '@mui/icons-material';
import { Box, CircularProgress, MenuItem, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import heic2any from 'heic2any';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Pdf from '../assets/images/dashboard/Pdf.svg';
import Word from '../assets/images/dashboard/Word.svg';
import avatar from '../assets/images/Muser.jpg';
import { AuthenticatedPage } from '../components/AuthenticatedPage';
import MuiDropdown from '../components/common/Dropdown';
import Container from '../components/container/Container';
import { ProfileCard } from '../components/Dashboard/ProfileCard';
import { uploadFile } from '../functions/functionClient';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { CustomUser } from '../redux/slice/auth/CustomUser';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import { dispatchUpsertPreEmploymentAssessment } from '../redux/slice/pre-employment-assessment';
import {
  DocumentTypes as DocumentTypeValue,
  UserUploadedDocuments,
} from '../redux/slice/user-documents/user-documents-type';
import {
  dispatchGetUserDocument,
  dispatchUpsertUserDocument,
} from '../redux/slice/user-documents/user-documents.operation';
import {
  dispatchGetUserProfileValues,
  dispatchUpdateUserProfileInfo,
  UserGoalDTO,
  UserProfileDTO,
} from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';
import logger from '../services/logger';
import { allowOnlyNumbers, handlePasteOnlyNumbers } from '../utils/helper';
interface FormData extends Omit<UserProfileDTO, 'dateOfBirth'> {
  // Exclude the existing 'dateOfBirth' property from UserProfileDTO
  dateOfBirth: Date; // Override to make it required
}
const ProfileInfoSchema = yup
  .object({
    name: yup.string().trim().max(50, 'Name must not exceed 50 characters').required('Required'),
    zip: yup
      .string()
      .trim()
      .required('Required')
      .test('len', 'Must be exactly 5 characters', (val) => val?.length === 5),
    //.min(5, 'Invalid zip code')
    //.max(5, 'Zip must not exceeds 5 character'),

    email: yup
      .string()
      .max(100, 'Email must not exceed 100 characters')
      .email('Invalid email address')
      .required('Required'),
    phoneCountryCode: yup.string().required('Required.'),
    phone: yup
      .string()
      .min(10, 'Phone must be 10 characters')
      .max(10, 'Phone must not exceed 10 characters')
      .required(),
    searchingJob: yup.string().required('Searching job is required.'),
    dateOfBirth: yup.date().required('Required.'),
    // dateOfBirth: yup.date().optional(),
    profile_img: yup.string().optional(),
    sharedWithHiringManager: yup.boolean().optional(),
    goals: yup.array().optional(),
  })
  .required();
const UserProfileUpdate: React.FC = () => {
  const today = dayjs();
  const tenYearsAgo = today.subtract(10, 'year');
  const [goals, setGoals] = useState<UserGoalDTO[]>([]);
  const [goalError, setGoalError] = useState('');
  const [profileInfoShared, setProfileInfoShared] = useState(false);
  const [currentGoal, setCurrentGoal] = useState<UserGoalDTO | null>(null);
  const { name, phoneCountryCode, phone, email } = useIntakeFormContext();
  const userProfileContext = useUserProfileContext();
  const currentUser: CustomUser | null = useAuthContext();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(ProfileInfoSchema),
    defaultValues: {
      name: userProfileContext?.name || name || '',
      phoneCountryCode: userProfileContext?.phoneCountryCode || '+1',
      phone: userProfileContext?.phone || phone || '',
      email: userProfileContext?.email || email || '',
      dateOfBirth: userProfileContext?.dateOfBirth || undefined,
      profileImagePath: userProfileContext?.profileImagePath || '',
      searchingJob: userProfileContext?.searchingJob || 'No',
      sharedWithHiringManager: userProfileContext?.sharedWithHiringManager || false,
      goals: userProfileContext?.goals || [],
      zip: userProfileContext?.zip || '',
    },
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedDoc, setUploadedDoc] = useState<UserUploadedDocuments[] | []>([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState(
    DocumentTypeValue.RESUME as string,
  );
  const [uploadDataLoader, setUploadDataLoader] = useState({
    profilePic: false,
    uploadedDoc: false,
    deleteDoc: false,
  });
  const document_type_options = (
    Object.keys(DocumentTypeValue) as Array<keyof typeof DocumentTypeValue>
  )
    .filter((key) => typeof DocumentTypeValue[key] === 'string')
    .map((key) => ({ label: DocumentTypeValue[key], value: DocumentTypeValue[key] }));

  const handleDocumentTypeChange = (newValue: string) => {
    setSelectedDocumentType(newValue);
  };

  useEffect(() => {
    const fetchUserDocuments = async () => {
      try {
        const result = await dispatch(dispatchGetUserDocument());
        if (result.length) {
          setUploadedDoc(result);
        }
      } catch (error) {
        console.error('Error fetching user documents:', error);
      }
    };

    void fetchUserDocuments();
  }, [dispatch]);

  useEffect(() => {
    void dispatch(dispatchGetUserProfileValues());
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchUpsertPreEmploymentAssessment());
  }, []);

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const uploadFileInputRef = useRef<HTMLInputElement | null>(null);

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        let fileName = `profile_${uuidv4()}.${file.name.split('.')[1]}`;
        const fileType = 'profile-picture';
        let contentType = file.type;
        const userId = currentUser?.user?.uid || '';
        setUploadDataLoader({ ...uploadDataLoader, profilePic: true });
        let convertedFile = file;

        if (file.type === 'image/heic' || file.name.toLowerCase().endsWith('.heic')) {
          try {
            const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });

            // Create a File object from the converted Blob
            if (convertedBlob instanceof Blob) {
              convertedFile = new File([convertedBlob], file.name.replace(/.heic$/i, '.jpeg'), {
                type: 'image/jpeg',
                lastModified: new Date().getTime(), // Add a lastModified timestamp
              });
              contentType = 'image/jpeg';
              fileName = `profile_${uuidv4()}.jpeg`; // Update file name to .jpeg after conversion
            }
          } catch (error) {
            console.error('Error converting HEIC to JPEG:', error);
            toast.error('Error converting image. Please try again.');
            setUploadDataLoader({ ...uploadDataLoader, profilePic: false });
            return;
          }
        }
        const reader = new FileReader();
        reader.readAsDataURL(convertedFile);

        reader.onload = async () => {
          const base64String = (reader.result as string).split(',')[1]; // Extract the base64 part of the result

          try {
            const response = await uploadFile({
              file: base64String,
              fileName,
              fileType,
              contentType,
              userId,
            });

            if (response && response.url) {
              const profileDto = {
                profileImagePath: response.url,
              };
              await dispatch(dispatchUpdateUserProfileInfo(profileDto));
              toast.success('Profile picture updated.');
            } else {
              toast.error('Something went wrong! Please try again');
            }
          } catch (error) {
            console.error(error);
            toast.error(`Error uploading file: ${(error as Error).message}`);
          } finally {
            setUploadDataLoader({ ...uploadDataLoader, profilePic: false });
          }
        };

        reader.onerror = () => {
          toast.error('Error reading file. Please try again.');
        };
      }
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadDataLoader({ ...uploadDataLoader, uploadedDoc: true });
      const file = e.target.files[0];
      const fileName = `${selectedDocumentType}.${file.name.split('.')[1]}`;
      const fileType = 'document';
      const contentType = file.type;
      const userId = currentUser?.user?.uid || '';
      const dto: UserUploadedDocuments = {
        docId: new Date().getTime(),
        fileName,
        fileType: selectedDocumentType,
        uploadedDate: new Date().toISOString(),
        filePath: '', // Will be updated after successful upload
      };
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64String = (reader.result as string).split(',')[1]; // Extract the base64 part of the result

        try {
          const response = await uploadFile({
            file: base64String,
            fileName,
            fileType,
            contentType,
            userId,
          });

          if (response && response.url) {
            // Update dto with filePath or other relevant response data
            dto.filePath = response.url;
            setUploadedDoc((prevDocs) => {
              const updatedDocs = [...prevDocs.filter((x) => x.fileType != dto.fileType), dto];
              void dispatch(dispatchUpsertUserDocument({ documents: updatedDocs }));
              toast.success('Document successfully added.');
              return updatedDocs;
            });
          } else {
            toast.error('Something went wrong! Please try again');
          }
        } catch (error) {
          console.error(error);
          toast.error(`Error uploading file: ${(error as Error).message}`);
        } finally {
          setUploadDataLoader({ ...uploadDataLoader, uploadedDoc: false });
        }
      };

      reader.onerror = () => {
        toast.error('Error reading file. Please try again.');
      };
    }
  };

  useEffect(() => {
    setProfileInfoShared(userProfileContext?.sharedWithHiringManager || false);
    setGoals(userProfileContext?.goals || []);
    reset({
      name: userProfileContext?.name || name,
      phoneCountryCode: userProfileContext?.phoneCountryCode || '+1',
      phone: userProfileContext?.phone || phone,
      email: userProfileContext?.email || email || '',
      dateOfBirth: userProfileContext?.dateOfBirth,
      sharedWithHiringManager: userProfileContext?.sharedWithHiringManager || false,
      goals: userProfileContext?.goals || [],
      searchingJob: userProfileContext?.searchingJob || 'No',
      zip: userProfileContext?.zip || '',
    });
  }, [name, phoneCountryCode, phone, email, reset, userProfileContext]);

  const submitUserProfileInfo: SubmitHandler<FormData> = async (data: FormData) => {
    logger.debug('Submitted user profile info.', data);
    data.goals = [...goals];
    data.sharedWithHiringManager = profileInfoShared;
    setIsLoading(true);
    try {
      await dispatch(dispatchUpdateUserProfileInfo(data));
      toast.success('Profile successfully updated!');
      navigate('/user-profile');
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteGoal = (goal: UserGoalDTO) => {
    setUploadDataLoader({ ...uploadDataLoader, deleteDoc: true });
    try {
      setGoals(goals.filter((x) => x.id !== goal.id));
      if (currentGoal?.id === goal.id) {
        setCurrentGoal(null);
      }
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setUploadDataLoader({ ...uploadDataLoader, deleteDoc: false });
    }
  };

  const handleDeleteDocument = (doc: UserUploadedDocuments) => {
    setUploadedDoc((prevDocs) => {
      const updatedDocs = prevDocs.filter((x) => x.docId !== doc.docId);
      void dispatch(dispatchUpsertUserDocument({ documents: updatedDocs }));
      toast.success('Document successfully deleted.');
      return updatedDocs;
    });
  };
  const handleAddGoal = () => {
    setGoals((prev) => {
      if (prev.length) {
        const existingGoalIndex = prev.findIndex((x) => x.id === currentGoal?.id);
        if (prev.length === 3 && existingGoalIndex === -1) {
          setGoalError('You can only add up to 3 goals');
          return prev; // Return previous state without changes
        } else if (currentGoal && existingGoalIndex === -1) {
          return [...prev, currentGoal]; // Add new goal to the array
        } else if (currentGoal && existingGoalIndex !== -1) {
          // Update existing goal if it exists
          return prev.map((goal, index) => {
            if (index === existingGoalIndex) {
              return { ...goal, goal: currentGoal.goal }; // Update the 'goal' property
            }
            return goal; // Return other goals without changes
          });
        }
        return prev; // Return previous state without changes if no action is taken
      } else {
        return currentGoal ? [currentGoal] : prev; // If previous state is empty, return either the new goal or the previous state
      }
    });
    setCurrentGoal(null);
  };
  useEffect(() => {
    if (currentGoal === null) {
      setCurrentGoal({ goal: '' });
    }
  }, [currentGoal]);
  useEffect(() => {
    if (errors) {
      window.scrollTo(0, 0);
    }
  }, [errors]);
  return (
    <>
      <div className="dashboard">
        <Box
          className="md:px-10"
          component="form"
          sx={{
            '& > :not(style)': { mb: 4 },
          }}
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(submitUserProfileInfo)}
        >
          <Container>
            <ProfileCard
              heading="PERSONAL INFORMATION"
              buttonText=""
              buttonIcon={[]}
              isVisible={false}
            >
              <div className="lg:flex items-center">
                <div className="user text-center relative inline-block">
                  <img
                    className="h-44 w-44 object-cover rounded-full mb-3 mx-auto border-[6px] border-purple-600"
                    src={userProfileContext?.profileImagePath || avatar}
                    alt=""
                  />

                  <input
                    className="absolute top-0 h-full w-full cursor-pointer opacity-0"
                    type="file"
                    accept=".png, .jpg, .heic"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onChange={handleFileChange}
                  />
                  <div
                    className="w-12 h-12 rounded-full bg-purple-600  border-4 border-white flex items-center justify-center text-white absolute right-3 bottom-3 cursor-pointer "
                    onClick={handleEditClick}
                  >
                    {uploadDataLoader && uploadDataLoader.profilePic ? (
                      <CircularProgress
                        sx={{ color: 'white' }} // Set color to white
                        size={20}
                      />
                    ) : (
                      <Edit />
                    )}
                  </div>
                </div>
                <div className="basic-details lg:ml-14 flex-1">
                  <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <Controller
                      control={control}
                      name="name"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="name"
                          type="text"
                          value={value}
                          error={!!errors.name}
                          helperText={errors.name && errors.name.message}
                          onChange={(event) => onChange(event.target.value)}
                          label="Name"
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}
                        />
                      )}
                    ></Controller>
                    <Controller
                      control={control}
                      name="email"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="email"
                          error={!!errors.email}
                          helperText={errors.email && errors.email.message}
                          type="email"
                          value={value}
                          onChange={(event) => onChange(event.target.value)}
                          label="Email"
                          variant="outlined"
                        />
                      )}
                    ></Controller>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        control={control}
                        name="dateOfBirth"
                        render={({ field: { value, onChange } }) => {
                          return (
                            <div>
                              <DatePicker
                                format="MMMM DD, YYYY"
                                name="dateOfBirth"
                                className={`w-full ${errors?.dateOfBirth ? 'field-error' : ''}`}
                                closeOnSelect
                                label="Birthday"
                                maxDate={tenYearsAgo}
                                value={value && dayjs(value)}
                                onChange={(date) => onChange(date)}
                                disableFuture
                                slotProps={{
                                  field: {
                                    readOnly: true,
                                  },
                                }}
                              />
                              {errors && errors.dateOfBirth && (
                                <span className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                                  {errors.dateOfBirth.message}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      ></Controller>
                    </LocalizationProvider>
                    <Controller
                      control={control}
                      name="zip"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="zip"
                          error={!!errors.zip}
                          helperText={errors.zip && errors.zip.message}
                          type="text"
                          inputProps={{
                            pattern: '^[0-9]*$',
                            onKeyPress: (event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            },
                            onPaste: (event) => {
                              const paste = event.clipboardData?.getData('text/plain');
                              const cleanPaste = paste.replace(/[^0-9]/g, '');
                              if (cleanPaste.length > 5) {
                                event.preventDefault();
                              } else {
                                onChange(cleanPaste);
                              }
                            },
                            maxLength: 5, // Maintain this for desktop browsers
                          }}
                          value={value}
                          onChange={(event) => {
                            const newValue = event.target.value.slice(0, 5); // Enforce max length manually
                            onChange(newValue);
                          }}
                          label="Zip"
                          variant="outlined"
                        />
                      )}
                    ></Controller>

                    <Controller
                      control={control}
                      name="phoneCountryCode"
                      render={({ field: { value, onChange } }) => (
                        <div>
                          <TextField
                            required
                            className={`w-full ${
                              errors?.phoneCountryCode ? 'field-error' : undefined
                            }`}
                            id="outlined-select-currency"
                            select
                            label="Country code"
                            error={!!errors.phoneCountryCode}
                            helperText={errors.phoneCountryCode && errors.phoneCountryCode.message}
                            value={value}
                            onChange={(event) => onChange(event.target.value)}
                            defaultValue="+1"
                          >
                            <MenuItem value="+1">+1</MenuItem>
                          </TextField>
                          {errors && errors.phoneCountryCode && (
                            <span className="text-red-500 text-sm">
                              {errors.phoneCountryCode.message}
                            </span>
                          )}
                        </div>
                      )}
                    ></Controller>
                    <Controller
                      control={control}
                      name="phone"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          error={!!errors.phone}
                          helperText={errors.phone && errors.phone.message}
                          id="phone"
                          type="text"
                          inputProps={{
                            pattern: '^[0-9]*$',
                            onKeyPress: allowOnlyNumbers,
                            onPaste: handlePasteOnlyNumbers,
                            maxLength: 10,
                            minLength: 10,
                          }}
                          value={value}
                          onChange={(event) => onChange(event.target.value)}
                          label="Phone"
                          variant="outlined"
                        />
                      )}
                    ></Controller>
                    <Controller
                      control={control}
                      name="searchingJob"
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          required
                          //className="w-2/6 sm:w-1/6"
                          id="searchingJob"
                          select
                          label="Actively looking for a job"
                          value={value}
                          onChange={(event) => onChange(event.target.value)}
                          helperText=""
                          defaultValue="No"
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </TextField>
                      )}
                    ></Controller>
                  </div>
                </div>
              </div>
            </ProfileCard>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-5">
              <div>
                <ProfileCard
                  heading="DOCUMENT MANAGEMENT"
                  buttonText="Upload Document"
                  buttonIcon={[]}
                  isVisible={false}
                >
                  <div className="grid mb-2">
                    <MuiDropdown
                      defaultValue={selectedDocumentType}
                      items={document_type_options}
                      onSelectionChange={handleDocumentTypeChange}
                    />
                  </div>
                  <div className="bg-gray-100 border border-dashed border-gray-400 rounded-2xl h-32 mb-8 cursor-pointer relative flex items-center justify-center">
                    <input
                      className="absolute top-0 h-full w-full cursor-pointer opacity-0"
                      type="file"
                      accept=".docx, .pdf"
                      name=""
                      id=""
                      ref={uploadFileInputRef}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onChange={handleUploadFile}
                    />
                    <div className="text-center cursor-pointer">
                      <p className="text-sm text-gray-400 mb-3">Document type .docx or .pdf</p>
                      {uploadDataLoader.uploadedDoc ? (
                        <CircularProgress size={30} />
                      ) : (
                        <div className="border border-blue-500 rounded-xl p-2 inline-block text-blue-500">
                          <Upload /> Upload Document
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="text-sm text-gray-400 mb-3 font-semibold">UPLOADED DOCUMENTS</p>
                  {uploadedDoc?.length ? (
                    uploadedDoc?.map((doc: UserUploadedDocuments, key) => (
                      <div
                        key={key}
                        className="border rounded-lg px-5 py-4 flex items-center justify-between mb-3"
                      >
                        <div className="flex items-center">
                          <img src={doc?.fileType.includes('.docx') ? Word : Pdf} alt="" />
                          <p className="ml-5">{doc.fileName}</p>
                        </div>

                        <button
                          type="button"
                          className="text-red-600"
                          onClick={() => handleDeleteDocument(doc)}
                        >
                          <Delete />
                        </button>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm text-gray-800 mb-3 ml-5 font-semibold">
                      No Documents Uploaded
                    </p>
                  )}
                </ProfileCard>
                <div className="flex items-center mt-5">
                  <p className="mr-5">Profile Information With Hiring Managers</p>
                  <Switch
                    checked={profileInfoShared}
                    onChange={() => setProfileInfoShared(!profileInfoShared)}
                    color="secondary"
                    {...label}
                  />
                </div>
              </div>
              <ProfileCard heading="GOAL SETTING" buttonText="" buttonIcon={[]} isVisible={false}>
                {goals &&
                  goals.map((goal, index) => (
                    <div
                      key={goal.id}
                      className="border rounded-lg px-5 py-4 flex items-center justify-between mb-3"
                    >
                      <div className="">
                        <p className="font-bold mb-2">Goal {index + 1}</p>
                        <p className="break-all">{goal.goal}</p>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            setCurrentGoal(goal);
                            setGoalError('');
                          }}
                          className="text-purple-500 ml-8"
                        >
                          <Edit />
                        </button>
                        <br />
                        {uploadDataLoader.deleteDoc ? (
                          <CircularProgress size={10} />
                        ) : (
                          <button
                            type="button"
                            onClick={() => handleDeleteGoal(goal)}
                            className="text-red-600 ml-8"
                          >
                            <Delete />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                <div className="border-t mt-6 pt-6 flex items-center">
                  <TextField
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCurrentGoal({
                        id: currentGoal?.id || uuidv4(),
                        goal: event.target.value,
                      });
                      setGoalError('');
                    }}
                    inputProps={{ maxLength: 250 }}
                    InputLabelProps={{ shrink: !!currentGoal?.goal }}
                    value={currentGoal?.goal}
                    fullWidth
                    id="outlined-basic"
                    label="What's your goal?"
                    variant="outlined"
                  />
                  <div className="text-blue-500 ml-5">
                    <button
                      type="button"
                      disabled={!currentGoal?.goal.trim()}
                      onClick={handleAddGoal}
                    >
                      <AddCircle sx={{ fontSize: '50px' }} />
                    </button>
                  </div>
                </div>
                <div>{goalError && <p className="text-red-500">{goalError}</p>}</div>
              </ProfileCard>
            </div>
            <div className="border-t pt-7 mt-7 text-right">
              <button disabled={isLoading} className="btn-primary">
                Update
              </button>
            </div>
          </Container>
        </Box>
      </div>
    </>
  );
};

export const UserProfileUpdatePage: React.FC = () => {
  return <AuthenticatedPage render={() => <UserProfileUpdate />} />;
};
