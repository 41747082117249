import { PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { Dispatch } from 'react';

import { upsertPreEmploymentAssessment } from '../../../services/database/pre-employment-assessment';

import { updatePreEmploymentAssessmentAction } from './pre-employment-assessment.slice';
import { PreEmploymentAssessment } from './pre-employment-assessment.types';

/**
 * retrieves or updates a pre-employment assessment
 */
export const dispatchUpsertPreEmploymentAssessment =
  (dto?: Partial<PreEmploymentAssessment>) =>
  async (dispatch: Dispatch<PayloadAction<PreEmploymentAssessment>>): Promise<void> => {
    const queryParams: URLSearchParams = new URLSearchParams(location.search);
    const disablePersonalInfoFromCookie: string | undefined =
      queryParams.get('disablePersonalInfo') ?? Cookies.get('disablePersonalInfo');
    const disablePersonalInfo: boolean | undefined =
      dto?.disablePersonalInfo ?? disablePersonalInfoFromCookie === 'true';
    const transformedDTO: Partial<PreEmploymentAssessment> = {
      ...dto,
      ...(disablePersonalInfo ? { disablePersonalInfo } : {}),
    };
    const values: PreEmploymentAssessment = await upsertPreEmploymentAssessment(transformedDTO);
    dispatch(updatePreEmploymentAssessmentAction(values));

    if (disablePersonalInfoFromCookie !== undefined) {
      Cookies.remove('disablePersonalInfo');
    }
  };
