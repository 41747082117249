import { ExperienceAssessmentQuestionType, ExperienceAssessmentQuestion } from './experience.types';

/**
 * questions ƒor the experience assessment
 *
 * @todo write test to ensure ids are unique across all questions
 * @todo write test to ensure no duplicate questions
 */
export const questions: ExperienceAssessmentQuestion[] = [
  {
    id: 'ade8fca0-4481-42b2-acfb-62e5957720ec',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Communicated with customers by phone or email?',
  },
  {
    id: 'ef0a8c23-8888-4a01-a98b-daf6fdce916d',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Communicated and/or interacted with customers in person?',
  },
  {
    id: '9caf2fc1-e08c-4001-8448-f9123fa72613',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Resolved customer inquiries or complaints?',
  },
  {
    id: 'c086fea6-2852-47d1-a540-5f284f6f2200',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Worked in retail or food position working directly with customers?',
  },
  {
    id: '7eff0b8b-36f1-4cb4-a3bc-b0ea19462489',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Taken food or beverage orders?',
  },
  {
    id: '3ab6a422-299f-4416-b71c-75c98c0a23af',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Cleaned tables and/or eating areas?',
  },
  {
    id: 'f4af58a3-5d82-44a3-a7dd-085f73a6b7df',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Served food or beverages?',
  },
  {
    id: '128c82e7-7c67-4314-8aec-df7934d80277',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Prepared food?',
  },
  {
    id: 'cabb0997-6e4c-4d23-ac93-43b942e0e386',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question:
      'Worked with an electronic machine such as a cash register, bar code scanner, or calculator?',
  },
  {
    id: '5bc1cf0f-7b0d-4fdc-92ba-6d1fa7dc00be',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question:
      'Used math skills. For example, adding subtracting, making change, counting money in a cash drawer, or balancing money from a cash drawer?',
  },
  {
    id: '40c8f680-871f-4884-9581-9a08df32a80b',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question:
      'Worked on a computer (typing, basic knowledge of word processers, entering information/data)?',
  },
  {
    id: 'a771ff56-54c1-42ca-81ab-addd1a6cb5d7',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Read instructions or reports?',
  },
  {
    id: 'cdc980ae-fa12-41d2-a85d-574fc6804f69',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Written business letters, memorandums, or other office documents?',
  },
  {
    id: 'f0d9b01a-e1fa-462f-83c1-c5860c14b668',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Performed housekeeping tasks such as vacuuming, cleaning, or dusting?',
  },
  {
    id: 'fece5bad-c1fe-49f2-ad06-6f4e6b9834e7',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question:
      'Assisted a handicapped or elderly person with daily living tasks such as grooming, dressing, eating?',
  },
  {
    id: '86a535f6-bd3e-47b6-8717-ef5efcf244de',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Taken care of children?',
  },
  {
    id: '84036580-f54b-48f7-a0ce-db849c72efdf',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Worked in cosmetology (for example, cut or braided hair, painted nails)?',
  },
  {
    id: 'e76cadc6-5f6c-4cf3-a772-c49933d48b68',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Supervised other people who reported to you?',
  },
  {
    id: '1f51d292-f537-435a-a369-e148da4104e3',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question:
      'Worked with the government or government agencies, including State, Tribal, county, local, etc.?',
  },
  {
    id: '3e9ebbd7-3aca-4e7e-96ed-ff8eab6ed244',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Contributed to a local community event?',
  },
  {
    id: 'c5412e28-6784-4b67-8aa5-7133cf225a79',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Worked in local tourism?',
  },
  {
    id: '560ddc86-c953-4180-9a39-8b9bb91858c0',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question:
      'Served as a guide for visitors to local community (hunting/fishing guide, activities guide, hiking guide, etc.)?',
  },
  {
    id: '6959e6c0-2eaa-4e89-a1ab-0137b94b892a',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Harvested local commodities such as timber, farming, local product manufacturing?',
  },
  {
    id: '8cec12be-ada6-43e3-a3b5-570b97794dd5',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question:
      'Acquired skills through cultural activities such as canoe-building, beadwork, hide tanning, totem-pole building, cultural dress sewing, etc.?',
  },
  {
    id: 'd229e95c-4050-4a41-8e71-beca4e6867b7',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question: 'Participated in local subsistence hunting, fishing, and gathering?',
  },
  {
    id: 'a5998e6f-c6b6-478f-acc2-20c26291bea8',
    type: ExperienceAssessmentQuestionType.PROFICIENCY_AND_INTEREST,
    question:
      'Worked in preserving, protecting, or conserving natural resources (fisheries, forestry, land management, etc.)?',
  },
  {
    id: '8d0c20ad-8f94-4d82-b57c-c62dcbb2139b',
    type: ExperienceAssessmentQuestionType.EXPERIENCE,
    question: 'Do you have experience serving in the military?',
  },
];
