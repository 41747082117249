import { CheckCircle, Close, Done, Replay } from '@mui/icons-material';
import { useEffect, useState } from 'react';

//import { useDrag, useDrop } from 'react-dnd';
import {
  CourseMatchingQuestion,
  MatchingQuestionBase,
  ShortingMatchingBase,
} from '../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { UserChapterSortingMachingQuestion } from '../../../redux/slice/user-course-question-response';

import { MatchingAnswerCard } from './AnswerCard';
import { MatchingQuestionCard } from './QuestionCard';

interface IComponentProps {
  questionData: CourseMatchingQuestion;
  onRetake?: (questionId: string) => void;
  submitedAnswer?: UserChapterSortingMachingQuestion[] | null | undefined;
  submitMatchingQuestion?: (answers: AnswersState) => void;
  enableRetake?: boolean;
  question_title?: string;
}
type AnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const MatchingQuestionBlock: React.FC<IComponentProps> = ({
  questionData,
  onRetake,
  submitMatchingQuestion,
  submitedAnswer,
  enableRetake,
  question_title,
}) => {
  const [unmatched, setUnmatched] = useState(questionData.questions);
  const [matched, setMatched] = useState<MatchingQuestionBase[]>([]);
  const [dragging, setDragging] = useState<MatchingQuestionBase | null>(null);
  const [machingData, setMachingData] = useState<MatchingQuestionBase[]>([]);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<boolean>(false);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (submitedAnswer && submitedAnswer.length) {
      const matchedAnswers: MatchingQuestionBase[] = [];
      submitedAnswer.forEach((answer) => {
        if (answer.question && answer.answer && answer.correct_answer) {
          matchedAnswers.push({
            answer: answer.answer,
            question: answer.question,
            correct_answer: answer.correct_answer,
            index: questionData.questions.findIndex((x) => x.question.id === answer.question?.id),
            matched: true,
          });
        }
      });
      setMatched(matchedAnswers.sort((a, b) => a.index - b.index));
      setIsAnswerSubmitted(true);

      if (!matchedAnswers.filter((x) => x.answer.id !== x.correct_answer.id).length) {
        setIsAnswerCorrect(true);
      }
    }
  }, [submitedAnswer]);
  const handleDrag = (dragItem: MatchingQuestionBase) => {
    setDragging(dragItem);
  };
  const handleDrop = (dropOnItem: MatchingQuestionBase) => {
    const alreadyMatched = matched.find(
      (item: MatchingQuestionBase) =>
        item.answer.id === dragging?.answer.id || item.question.id === dropOnItem.question.id,
    );
    if (alreadyMatched) {
      let updatedMatched = matched
        .filter((item: MatchingQuestionBase) => item.question.id !== alreadyMatched.question.id)
        .filter((item: MatchingQuestionBase) => item.answer.id !== dropOnItem.answer.id);

      updatedMatched = updatedMatched.filter(
        (x: MatchingQuestionBase) =>
          x.question.id !== dragging?.question.id && x.question.id !== dragging?.answer.id,
      );
      setMatched([
        ...updatedMatched,
        {
          question: dropOnItem.question,
          answer: dragging?.answer || dropOnItem.answer,
          matched: true,
          index: dropOnItem.index,
          correct_answer: dropOnItem.correct_answer,
        },
      ]);

      setMatched((matchedState) => {
        const unMatchedquestions = questionData.questions
          .filter((item1) => !matchedState.some((item2) => item1.question.id === item2.question.id))
          .map((x) => x.question);

        const unMatchedanswers = questionData.questions
          .filter((item1) => !matchedState.some((item2) => item1.answer.id === item2.answer.id))
          .map((x) => x.answer);
        const newUnMatched = unMatchedquestions.map((question, index) => {
          return {
            question,
            answer: unMatchedanswers[index],
            matched: false,
            index:
              questionData.questions.find((x) => x.question.id === unMatchedquestions[index].id)
                ?.index || 0,
            correct_answer:
              questionData.questions.find((x) => x.question.id === unMatchedquestions[index].id)
                ?.correct_answer || dropOnItem.correct_answer,
          };
        });
        setUnmatched([...newUnMatched]);
        return [...matchedState];
      });
    } else {
      dragging &&
        setMatched([
          ...matched,
          {
            question: dropOnItem.question,
            answer: dragging?.answer,
            matched: true,
            index: dropOnItem.index,
            correct_answer: dropOnItem.correct_answer,
          },
        ]);
      const unMatchedquestions = unmatched
        .filter((x) => x.question.id !== dropOnItem.question.id)
        .map((x) => x.question);
      const unMatchedanswers = unmatched
        .filter((x) => x.answer.id !== dragging?.answer.id)
        .map((x) => x.answer);
      const newUnMatched = unMatchedquestions.map((question, index) => {
        return {
          question,
          answer: unMatchedanswers[index],
          matched: false,
          index:
            questionData.questions.find((x) => x.question.id === unMatchedquestions[index].id)
              ?.index || 0,
          correct_answer:
            questionData.questions.find((x) => x.question.id === unMatchedquestions[index].id)
              ?.correct_answer || dropOnItem.correct_answer,
        };
      });
      setUnmatched([...newUnMatched]);
    }
  };
  useEffect(() => {
    const data = [...matched, ...unmatched].sort((a, b) => a.index - b.index);
    setMachingData(data);
  }, [matched, unmatched]);
  const handleAnswerSubmit = () => {
    setIsAnswerSubmitted(true);
    if (!matched.filter((x) => x.answer.id !== x.correct_answer.id).length) {
      setIsAnswerCorrect(true);
    }
    const shortingMatchingAnswers: ShortingMatchingBase[] = [];
    const answers: AnswersState = {};

    matched.forEach((value) => {
      shortingMatchingAnswers.push({
        question: value.question,
        correct_answer: value.correct_answer,
        answer: value.answer,
      });
      answers[questionData.id] = shortingMatchingAnswers;
    });
    submitMatchingQuestion && submitMatchingQuestion(answers);
  };

  const handleTakeAgainClick = () => {
    setMachingData([]);
    setMatched([]);
    setDragging(null);
    setUnmatched(questionData.questions);
    setIsAnswerCorrect(false);
    setIsAnswerSubmitted(false);
    onRetake && onRetake(questionData.id);
  };
  const renderItems = (items: MatchingQuestionBase[]) => {
    return (
      <>
        <div className="border-b-2 mb-5 p-4">
          <h5 className="">{question_title ? question_title : ''}</h5>
        </div>
        {items.map((item: MatchingQuestionBase, index: number) => (
          <div key={index} className="flex items-center  mb-5">
            <div className="">
              {isAnswerSubmitted && (
                <>
                  {item.answer.id === item.correct_answer.id ? (
                    <CheckCircle sx={{ fontSize: '30px', color: '#7e23cf' }} />
                  ) : (
                    <div className="flex items-center justify-center bg-black text-white w-6 h-6 rounded-full">
                      {(questionData.questions.find((x) => x.correct_answer.id === item.answer.id)
                        ?.index || 0) + 1}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="flex items-center gap-4 ml-5 w-full" key={index}>
              <MatchingAnswerCard item={item} onDrag={handleDrag} />
              <MatchingQuestionCard item={item} onDrop={handleDrop} />
            </div>
          </div>
        ))}
        <div className="text-center">
          <button
            disabled={isAnswerSubmitted || matched.length !== questionData.questions.length}
            onClick={handleAnswerSubmit}
            className="btn-primary"
          >
            Submit
          </button>
        </div>

        {isAnswerSubmitted && (
          <div className="mt-7 text-center">
            <div className="bg-gray-100 text-center px-20 py-10">
              <div className="icon-wrap w-16 h-16 rounded-full border-2 border-gray-400 flex items-center justify-center mx-auto mb-3">
                {!isAnswerCorrect ? (
                  <Close sx={{ color: '#962cc6' }} />
                ) : (
                  <Done sx={{ color: '#962cc6' }} />
                )}
              </div>
              <p className="mb-7">{isAnswerCorrect ? 'Correct' : 'Incorrect'}</p>
              {enableRetake !== false && (
                <button
                  onClick={handleTakeAgainClick}
                  className="!bg-transparent btn-secondary min-w-[140px]"
                >
                  RETAKE
                  <br />
                  <Replay />
                </button>
              )}
            </div>
          </div>
        )}
      </>
    );
  };
  return <>{renderItems(isAnswerSubmitted ? matched : machingData)}</>;
};
