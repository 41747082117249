import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import {
  getEmploymentReadinessValues,
  updateEmploymentReadiness,
} from '../../../services/database/form-employment-readiness';

import {
  EmploymentReadinessState,
  updateEmploymentReadinessAction,
} from './form-employment-readiness.slice';
import { UpdateEmploymentReadinessDTO } from './form-employment-readiness.types';

/**
 * retrieve previously saved values from the employment readiness assessment
 */
export const dispatchGetEmploymentReadinessValues =
  () =>
  async (dispatch: Dispatch<PayloadAction<EmploymentReadinessState>>): Promise<void> => {
    const values: EmploymentReadinessState = await getEmploymentReadinessValues();
    dispatch(updateEmploymentReadinessAction(values));
  };

/**
 * update progress in the employment readiness assessment
 */
export const dispatchUpdateEmploymentReadiness =
  (dto: UpdateEmploymentReadinessDTO) =>
  async (dispatch: Dispatch<PayloadAction<UpdateEmploymentReadinessDTO>>): Promise<void> => {
    await updateEmploymentReadiness(dto);
    dispatch(updateEmploymentReadinessAction(dto));
  };
