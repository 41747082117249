import chat_background from '../../../../assets/images/courses/communication-basics/chapter6/sonee/chat_background.jpeg';
import chat_character from '../../../../assets/images/courses/communication-basics/chapter6/sonee/chat_character.png';
import { course_chat_questions as CourseChatQuestions } from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import { ActionableBlock } from '../../ActionableBlock';
import { CarouselComponent } from '../../CarouselComponent';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const CommunicationBasicsChapter6: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const slidesData = [
    {
      heading: 'Ending a Conversation in Four Steps',
      paragraph:
        'You’ve put time, energy, and thought into this talk and it’s time to bring your discussion to a crisp close with a positive conclusion. Click through this process to see how to wrap up a conversation smoothly.',
      list: [],
    },
    {
      heading: 'Summarize What You’ve Talked About',
      paragraph: `<p>To signal the end of a conversation, summarize what you’ve talked about. That will bring the discussion full circle. If a friend is recommending a great book, you might say: <span class="italic">“Thanks for the suggestion! I’ll have to add that to my reading list.” </span>If a coworker is sharing a story about their weekend, you might say: <span class="italic">“That’s a great story! It sounds like you had a pretty exciting weekend.”</span></p>
      <p><br/></p><p>These types of statements signal that the topic has run its course. They bring your discussion to a natural close. Plus, you’ll have the added benefit of showing your conversation partner that you’ve been paying attention.</p>`,
      list: [],
    },
    {
      heading: 'Give a Reason',
      paragraph: `<p>Now that you’ve created the perfect moment, give a reason for why you need to wrap things up. That way, the other person won’t be left questioning: <span class="italic">“Why did they walk away? Was it something I said?”</span></p><p><br/></p>
      <p>Your reason doesn’t need to be complicated. Something as simple as: <span class="italic">“Anyway, I have to run because I have a call in a few minutes”</span> or <span class="italic">“I’ve got to get through some emails”</span> works great.</p><p><br/></p>
      <p>So long as your reason is sincere and polite, the other person will understand and not feel abandoned. </p>`,
      list: [],
    },
    {
      heading: 'Express Appreciation',
      paragraph: `<p>After giving your reason, express appreciation for the conversation. Show this person that you enjoyed the talk, and you valued their time. Your goal is to help them feel positive about you, the discussion, and themselves.</p><p><br/></p>
      <p>For example, you might say: <span class="italic">“I’m so glad we had a chance to catch up!”</span> or <span class="italic">“It’s been great chatting with you!”</span> No matter the topic of your conversation, these little comments of appreciation will help end your discussion on a positive note. </p>`,
      list: [],
    },
    {
      heading: 'Suggest a Future Meeting',
      paragraph: `<p>This last step is optional, but if you enjoyed talking to the person, suggest a future meeting. It’s much easier to say goodbye to someone you plan to see again.</p><p><br/></p>
      <p>If you’re speaking to a coworker or a friend, a comment such as <span class="italic">“Let’s talk later!”</span> or <span class="italic">“I’ll see you tomorrow!”</span> is perfect. If you just met this person, ask for a phone number, email, or business card so that you can meet again. Try making concrete plans. For instance, you might say: <span class="italic">“I’d like to stay in touch. Are you free to grab coffee next week?”</span></p>`,
      list: [],
    },
    {
      heading: 'Summary',
      paragraph: `<p>To smoothly wrap up a conversation, remember these four steps. First, bring the discussion full circle by <span class="font-bold">summarizing</span> what you’ve talked about. Then, <span class="font-bold">give a reason</span> for why you need to go, <span class="font-bold">express appreciation</span> for the other person’s time, and <span class="font-bold">suggest a future meeting</span> if you’d like to stay in touch.</p>`,
      list: [],
    },
  ];
  const courseChatQuestions = CourseChatQuestions.filter((obj) => obj.chapter_id === chapter.id);
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Ending a Conversation Positively</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-16">
          The trick to ending a conversation without making{' '}
          <span className="font-bold italic"> “goodbye”</span> sound like rejection is to perfect
          your timing and your phrasing. You’ll need to find the right moment, and you’ll need to
          come off as courteous, sincere, and kind.
        </p>
        <p className="mb-8">
          In this lesson, we demonstrate how to end a conversation on a positive note. First, we’ll
          explain how to pick the right timing and wrap things up smoothly. Then, we’ll offer three
          compelling reasons you can give for needing to say goodbye.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/AFXfa8jcN_ZH5f74wUC4NcBGgrxm353L/svVmBcGK/assets/rbc/vxbkpsln1F0PkZY__COMM-VC-06.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">Picking the Right Moment</h3>
        <p className="mb-8">
          Knowing when to end a conversation is just as crucial as knowing how to end it. If you’re
          too abrupt, you might come off as rude or impatient. If you let the dialogue drag on too
          long, you might diminish the positive impression you’ve already made.
          <br />
          <br />
          Every discussion has ebbs and flows. The key to ending a conversation is to wait for a
          natural pause between topics. Here are four clues to look out for:
        </p>
        <ul className="mt-7">
          <li className="pb-5">
            <label className="custom-checkbox">
              Has the topic run its course?
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="pb-5">
            <label className="custom-checkbox">
              Is the other person giving you one-word answers such as:
              <span className="italic"> “okay,” “sure,”</span> and
              <span className="italic"> “yeah”?</span>
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="pb-5">
            <label className="custom-checkbox">
              Has the enthusiasm or energy for the discussion died down?
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="pb-7">
            <label className="custom-checkbox">
              Is the other person avoiding eye contact, turning their body away, or checking their
              phone?
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
        </ul>
        <p>
          If you can answer <span className="italic">“yes”</span> to any of those questions, then
          now is likely a good time to start wrapping up.
        </p>
        <div className="border rounded-3xl mt-8">
          <CarouselComponent data={slidesData} />
        </div>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          Reasons to End a Conversation
        </h3>
        <p>
          If you’re struggling to think of a compelling reason to end a conversation, try following
          one of these common approaches.
        </p>
        <ul className="list-decimal pl-7 mt-8 mb-10 text-xl">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Mention another commitment.</span> Life gets busy. No one
            will hold it against you if you have another responsibility. Maybe there’s a
            presentation or report you need to finish. Perhaps you need to get home and make dinner.
            A simple comment such as, “I wish I could keep talking, but I promised a friend I’d call
            them in five minutes,” works great.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Use your immediate surroundings.</span> Consider where you
            are. Is there anything that can help you end the conversation? If you’re at an event,
            you could kindly excuse yourself to grab an appetizer or thank the host. Even if you’re
            just at the office, you could say: “I need to get a drink of water. Can I get you
            anything?” Likely, the other person will say no. If they accept, just say goodbye when
            you return with the drink: “It was great chatting with you about ___. I’ll see you
            around!”
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Give the other person an excuse.</span> If you don’t have a
            natural excuse, try offering the other person a reason to conclude the conversation.
            Show respect for this person’s time with a statement such as: “I can’t believe how long
            we’ve been talking! I’m sure you have to get back to work.” or “Anyway, I don’t want to
            monopolize your time. Thanks so much for chatting. I’ll let you get back to it.”
          </li>
        </ul>
        <p className="text-2xl text-center leading-10 font-light mb-10">
          How you choose to end a conversation will determine how it’s remembered. Be polite and
          appreciative. Leave a positive impression.
        </p>
        <ActionableBlock
          backgroundImage={chat_background}
          courseChatQuestions={courseChatQuestions}
          characterImage={chat_character}
          feedbackTitle="Be Polite, But Say What You Need"
          feedbackText="To end a conversation positively, say what you need and aim for a smooth transition. Restate what you talked about, give a reason for leaving, express appreciation, and make plans to chat later. "
          buttonName="Continue"
        />
        <p className="text-2xl text-center leading-10 font-light mb-10">
          Now that you've got the hang of verbal communication, let's move on to written
          communication.
        </p>
        <button onClick={handleNextChapterClick} className="btn-primary w-full">
          IMPROVING YOUR BUSINESS WRITING SKILLS
        </button>
      </div>
    </>
  );
};
