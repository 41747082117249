import React, { useState, useEffect } from 'react';

type CountdownTimerProps = {
  isRegenerated: boolean;
  timer: number;
  onRegenerate: () => void;
};

export const CountdownTimer: React.FC<CountdownTimerProps> = ({
  isRegenerated,
  timer,
  onRegenerate,
}) => {
  const [time, setTime] = useState(timer);

  useEffect(() => {
    if (isRegenerated) {
      setTime(timer);
    }
  }, [isRegenerated, timer]);

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
    return undefined;
  }, [time, onRegenerate]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return <div>{formatTime(time)}</div>;
};
