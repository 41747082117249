import _ from 'lodash';
import { useEffect, useState } from 'react';

import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestion,
  QuestionOption,
  QuizAnswer,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { course_quiz_questions as quizQuestions } from '../../../../courses/perception-understanding/perciption-understanding-questions';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { useCourseProgressContext } from '../../../../hooks/use-user-course.hooks';
import {
  dispatchGetChapterProgress,
  dispatchUpdateChapterProgress,
} from '../../../../redux/slice/user-course';
import {
  UserChapterQuestion,
  dispatchUpdateUserCourseQuestionResponse,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { GenericQuizQuestion } from '../../QuizQuestion';
import { QuizResult } from '../../QuizResult';
import { QuizStart } from '../../QuizStart';
type AnswersState = {
  [questionId: string]: QuizAnswer;
};
type SortingMatchingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
  courseId?: string;
  onQuizResult?: (result: boolean | undefined) => void;
}

export const PerciptionQuiz: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
  courseId,
  onQuizResult,
}) => {
  const courseProgressContext = useCourseProgressContext();
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();

  const dispatch = useAppDispatch();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [quizScore, setQuizScore] = useState<number | null>(null);
  const [lastQuizScore, setLastQuizScore] = useState<number | null>(null);
  const [, setAnswers] = useState<AnswersState>({});
  const [, setActivityAnswers] = useState<SortingMatchingAnswersState>({});
  const [courseProgress, setCourseProgress] = useState(
    courseProgressContext[courseId || '']?.progress || 0,
  );
  const [correctQuestions, setCorrectQuestions] = useState<string[]>([]);
  const [mcqQuizAnswers, setMcqQuizAnswers] = useState<UserChapterQuestion[]>([]);
  const [activityQuizAnswers, setActivityQuizAnswers] = useState<SortingMatchingAnswersState[]>([]);
  const handleQuizStart = () => {
    void dispatch(
      dispatchUpdateChapterProgress({
        [courseId || '']: {
          activeChapter: chapter.id,
          chapters: { [chapter.id]: 0 },
          progress: _.clamp(courseProgress, 0, 100),
        },
      }),
    );
    setCurrentQuestionIndex(0);
  };

  const handleQuizRetake = async () => {
    onQuizResult && onQuizResult(undefined);
    setCurrentQuestionIndex(-1);
    setQuizScore(null);
    setAnswers({});
    setActivityQuizAnswers([]);
    setMcqQuizAnswers([]);
    setCorrectQuestions([]);
    await dispatch(
      dispatchUpdateChapterProgress({
        [courseId || '']: {
          activeChapter: chapter.id,
          chapters: { [chapter.id]: 0 },
          progress: _.clamp(
            (courseProgress || 0) -
              ((quizScore && quizScore >= 80) || courseProgress === 100
                ? chapter.completion_percentage
                : 0),
            0,
            100,
          ),
        },
      }),
    );
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseId || '']: {
          chapters: {
            [chapter.id]: {
              questions: [],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  useEffect(() => {
    void dispatch(dispatchGetChapterProgress());
  }, []);
  useEffect(() => {
    if (Object.keys(courseProgressContext).length) {
      setCourseProgress(courseProgressContext[courseId || '']?.progress || 0);
    }
  }, [courseProgressContext]);

  useEffect(() => {
    //Calculate course progress based on the previous quiz answers
    if (
      courseId &&
      Object.keys(userCourseQuestionResponseContext[courseId]?.chapters[chapter.id]?.quiz || {})
    ) {
      setLastQuizScore(
        userCourseQuestionResponseContext[courseId]?.chapters[chapter.id]?.quiz?.score ?? null,
      );
      setQuizScore(
        userCourseQuestionResponseContext[courseId]?.chapters[chapter.id]?.quiz?.score ?? null,
      );
    }
  }, [userCourseQuestionResponseContext]);

  const handleSortingMatchingAnswerSubmit = async (
    activityAnswers: SortingMatchingAnswersState,
  ) => {
    setActivityAnswers((prevAnswers) => {
      return {
        ...prevAnswers,
        ...activityAnswers,
      };
    });
    setActivityAnswers((prevAnswers) => {
      if (currentQuestionIndex + 1 === quizQuestions.length) {
        calculateQuizScore();
      } else {
        updateQuizProgress();
      }
      return { ...prevAnswers };
    });
  };
  const handleAnswerSubmit = async (
    questionId: string,
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
  ) => {
    setAnswers((prevAnswers) => {
      return {
        ...prevAnswers,
        [questionId]: {
          question: (quizQuestions[currentQuestionIndex] as CourseQuestion).question,
          is_correct: isCorrect,
          answer_options: quizAnswers,
        },
      };
    });
    setAnswers((prevAnswers) => {
      if (currentQuestionIndex + 1 === quizQuestions.length) {
        calculateQuizScore();
      } else {
        updateQuizProgress();
      }
      return { ...prevAnswers };
    });
  };
  const calculateQuizScore = () => {
    setAnswers((prevAnswers) => {
      const correctAnswerIds = Object.entries(prevAnswers)
        .filter(([, answer]) => answer.is_correct)
        .map(([questionId]) => questionId);

      setCorrectQuestions((prev) => {
        return [...prev, ...correctAnswerIds];
      });

      const answersArray = Object.entries(prevAnswers);
      const mcqAnswers: UserChapterQuestion[] = [];
      answersArray.map(([questionId, quizAnswer]) => {
        mcqAnswers.push({
          question_id: questionId,
          answers: quizAnswer.answer_options,
          isCorrect: quizAnswer.is_correct,
          question: quizAnswer.question,
        });
      });
      setMcqQuizAnswers(mcqAnswers);
      return prevAnswers;
    });

    setActivityAnswers((prevAnswers) => {
      const correctAnswerIds = Object.entries(prevAnswers)
        .filter(
          ([, answers]) =>
            answers.filter((x) => x.answer.id === x.correct_answer.id).length === answers.length,
        )
        .map(([questionId]) => questionId);

      setCorrectQuestions((prev) => {
        return [...prev, ...correctAnswerIds];
      });

      const answersArray = Object.entries(prevAnswers);
      const activityAnswers: SortingMatchingAnswersState[] = [];
      answersArray.map(([questionId, quizAnswers]) => {
        activityAnswers.push({ [questionId]: quizAnswers });
      });
      setActivityQuizAnswers(activityAnswers);

      return prevAnswers;
    });
  };
  const updateQuizProgress = () => {
    const quizProgress = Math.floor(((currentQuestionIndex + 1) / quizQuestions.length) * 100);
    void dispatch(
      dispatchUpdateChapterProgress({
        [courseId || '']: {
          activeChapter: chapter.id,
          chapters: { [chapter.id]: quizProgress },
          progress: _.clamp(courseProgress, 0, 100),
        },
      }),
    );
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  useEffect(() => {
    if (mcqQuizAnswers.length + activityQuizAnswers.length === quizQuestions.length) {
      const score = (new Set(correctQuestions).size / quizQuestions.length) * 100;
      let chapterProgress = 100;
      setQuizScore(score);
      if (score < 80 && onQuizResult) {
        chapterProgress = 0;
        onQuizResult(false);
      } else {
        onQuizResult && onQuizResult(true);
      }
      void dispatch(
        dispatchUpdateChapterProgress({
          [courseId || '']: {
            activeChapter: chapter.id,
            chapters: { [chapter.id]: chapterProgress },
            progress: _.clamp(courseProgress, 0, 100),
          },
        }),
      );

      void dispatch(
        dispatchUpdateUserCourseQuestionResponse({
          [courseId || '']: {
            chapters: {
              [chapter.id]: {
                questions: [],
                quiz: {
                  isPassed: score >= 80,
                  questions: mcqQuizAnswers,
                  activityQuestions: activityQuizAnswers,
                  score: score,
                },
              },
            },
          },
        }),
      );
    }
  }, [mcqQuizAnswers, activityQuizAnswers]);
  return (
    <>
      {quizScore !== null ? (
        <QuizResult
          lastScore={lastQuizScore}
          courseId={courseId}
          chapter={chapter}
          onNextChapter={handleNextChapterClick}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeClick={handleQuizRetake}
          score={quizScore}
        />
      ) : currentQuestionIndex > -1 ? (
        <GenericQuizQuestion
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAnswerSubmit={handleAnswerSubmit}
          questionType={quizQuestions[currentQuestionIndex].type}
          question={quizQuestions[currentQuestionIndex]}
          currentQuestionIndex={currentQuestionIndex + 1}
          totalQuestions={quizQuestions.length}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onActivityAnswerSubmit={handleSortingMatchingAnswerSubmit}
        />
      ) : (
        <QuizStart
          onQuizStart={handleQuizStart}
          pageIndex={chapter.index}
          totalPage={totalPages}
          description="Check your knowledge and complete the following quiz questions."
        />
      )}
    </>
  );
};
