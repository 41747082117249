import { DocumentSnapshot, doc, getDoc } from 'firebase/firestore';

import {
  AsaUserTasks,
  UserAsaTaskHistory,
  UserAsaTaskHistoryDTO,
} from '../../redux/slice/asa-task-history';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import {
  getIdForFormRecord,
  replaceUndefinedWithDeleteField,
  updateRecord,
} from './database.utils';

const database: DatabaseTable = DatabaseTable.ASA_TASk_HISTORY_RESPONSES;

/**
 * retrieves previously stored asa task history values if available
 */
export async function getAsaTaskHistoryValues(): Promise<UserAsaTaskHistory> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<Partial<UserAsaTaskHistory>> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return (result.data() as UserAsaTaskHistory) ?? {};
  } catch (e) {
    logger.error('Failed to retrieve asa task history values.', e);
    throw e;
  }
}

/**
 * creates or updates a user profile
 */
export async function upsertAsaTaskHistory(dto: UserAsaTaskHistoryDTO): Promise<void> {
  logger.debug('Upserting asa task history.');
  try {
    const recordId: string = await getIdForFormRecord();
    const asaTaskHistory = await getAsaTaskHistoryValues();
    const currentTask = asaTaskHistory?.tasks?.find(
      (x) => x.taskId === dto.taskId && x.taskStep === dto.taskStep,
    );

    if (
      asaTaskHistory?.tasks?.find((x) => x.taskId === dto.taskId && x.taskStep === dto.taskStep)
        ?.isCompleted &&
      !dto.isCompleted
    ) {
      throw new Error('Task already completed');
    }

    const userTask: AsaUserTasks = replaceUndefinedWithDeleteField({
      taskId: dto.taskId,
      taskName: dto.taskName || currentTask?.taskName || '',
      startDate: dto.startDate || currentTask?.startDate || null,
      completedDate: dto.completedDate || currentTask?.completedDate || null,
      taskStep: dto.taskStep,
      totalSteps: dto.totalSteps || currentTask?.totalSteps || 0,
      isCompleted: dto.isCompleted || currentTask?.isCompleted || false,
      taskUrl: dto.taskUrl || currentTask?.taskUrl || '',
    });

    if (!Object.keys(asaTaskHistory).length || !Object.keys(asaTaskHistory?.tasks).length) {
      const taskHistoryDto: UserAsaTaskHistory = {
        userId: recordId,
        asaUserId: dto.asaUserId,
        tasks: [{ ...userTask }],
      };
      await updateRecord(taskHistoryDto, database);
    } else {
      const taskHistoryDto: UserAsaTaskHistory = {
        userId: recordId,
        asaUserId: dto.asaUserId,
        tasks: [
          ...asaTaskHistory.tasks.filter((x) => x.taskId !== dto.taskId),
          ...asaTaskHistory.tasks.filter(
            (x) => x.taskId == dto.taskId && x.taskStep !== dto.taskStep,
          ),
          { ...userTask },
        ],
      };
      await updateRecord(taskHistoryDto, database);
    }
  } catch (e) {
    logger.error('Failed to upsert asa user task history', e);
    throw e;
  }
}
