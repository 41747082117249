export type UserUploadedDocuments = {
  docId: number;
  fileName: string;
  filePath: string;
  uploadedDate: string;
  fileType: string;
};

export enum DocumentTypes {
  RESUME = 'Resume',
  COVER = 'Cover Letter',
  BACKGROUND = 'Background Check',
  REFERENCES = 'References',
}

export type UserDocumentState = {
  documents: UserUploadedDocuments[] | [];
};
