import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header } from './Header';

const CourseLayout: React.FC = () => {
  return (
    <>
      <div className="min-h-screen">
        <ToastContainer theme="colored" limit={1} autoClose={1000} position="top-center" />
        <div className="w-full block">
          <Header />
          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default CourseLayout;
