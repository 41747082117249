import { useEffect } from 'react';

import founderBg from '../assets/images/founderBg.svg';
import founderImg from '../assets/images/Fuser.jpg';
import aboutImg from '../assets/images/Raising hand-bro.svg';
import Container from '../components/container/Container';

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about-wrap relative flex items-center py-24">
        <Container>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className="banner-content">
              <h1 className="!font-semibold">About Us</h1>
              <h4 className="mt-5 mb-12">
                Vero Learning is launching an education technology platform that aims to match
                students with compatible vocational opportunities by evaluating their aptitude and
                generating career SWOT analysis reports. The platform includes pre-employment
                screening tests, transferable skills modules, and interactive project-based
                assignments. The team behind Vero Learning consists of a diverse group of
                individuals with expertise in education technology product design and development,
                education theory and practice, education research, and commercialization of
                education and technology-related products.
              </h4>
            </div>
            <div className="">
              <img className="ml-auto mt-6" src={aboutImg} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <section className="founder-wrap bg-[#f8f8f8] rounded-tl-[271px] py-28">
        <Container>
          <div className="flex items-center md:flex-nowrap flex-wrap">
            <div className="flex items-center justify-center relative w-72 h-72 flex-none m-auto">
              <img className="absolute top-0 left-0" src={founderBg} alt="" />
              <img
                className="rounded-full w-64 z-10 border-4 border-white"
                src={founderImg}
                alt=""
              />
            </div>
            <div className="md:ml-16 mt-6 md:mt-0">
              <h3 className="text-purple-600 font-bold text-2xl">Our Founder</h3>
              <h1 className="mb-4">Chrissybil Boulin</h1>
              <p className="text-xl">
                The President of Vero Learning, serves as the principle investigator and project
                manager. She has a Master's in Development Studies from the University of Cambridge
                and is an accomplished entrepreneur, educator, and development specialist with a
                focus on public- private sector development initiatives, economic development and
                education policy research.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-20">
        <Container>
          <div className="text-center">
            <h1 className="mb-9">
              Our <span className="font-bold"> Advisory Board</span>
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-14 justify-items-center">
              <div className="ab-member">
                <img
                  className="rounded-full w-32 z-10 border-2 border-purple-600 mx-auto mb-4"
                  src={founderImg}
                  alt=""
                />
                <h2 className="text-2xl font-bold">Alan Catlett</h2>
                <p className="text-gray-400 italic text-base">VP Growth and Operations</p>
                <p className="text-base font-normal">International Franchise Association</p>
              </div>
              <div className="ab-member">
                <img
                  className="rounded-full w-32 z-10 border-2 border-purple-600 mx-auto mb-4"
                  src={founderImg}
                  alt=""
                />
                <h2 className="text-2xl font-bold">Ann Remy</h2>
                <p className="text-gray-400 italic text-base">
                  Learning And Development Specialist
                </p>
                <p className="text-base font-normal">Remy Consulting</p>
              </div>
              <div className="ab-member">
                <img
                  className="rounded-full w-32 z-10 border-2 border-purple-600 mx-auto mb-4"
                  src={founderImg}
                  alt=""
                />
                <h2 className="text-2xl font-bold">Mary Bryan</h2>
                <p className="text-gray-400 italic text-base">Vice President Human Resources</p>
                <p className="text-base font-normal">The ODP Corporation</p>
              </div>
              <div className="ab-member">
                <img
                  className="rounded-full w-32 z-10 border-2 border-purple-600 mx-auto mb-4"
                  src={founderImg}
                  alt=""
                />
                <h2 className="text-2xl font-bold">Jennifer Schiffner</h2>
                <p className="text-gray-400 italic text-base">
                  Director of Global Employee Relations
                </p>
                <p className="text-base font-normal">VISA</p>
              </div>
              <div className="ab-member">
                <img
                  className="rounded-full w-32 z-10 border-2 border-purple-600 mx-auto mb-4"
                  src={founderImg}
                  alt=""
                />
                <h2 className="text-2xl font-bold">Joanna Mitka Townsend</h2>
                <p className="text-gray-400 italic text-base">
                  Vice President, Global Product Commercialization, Strategy & Operations
                </p>
                <p className="text-base font-normal">VISA</p>
              </div>
              <div className="ab-member">
                <img
                  className="rounded-full w-32 z-10 border-2 border-purple-600 mx-auto mb-4"
                  src={founderImg}
                  alt=""
                />
                <h2 className="text-2xl font-bold">Tom Portesy</h2>
                <p className="text-gray-400 italic text-base">
                  International, Acquisitions and Strategic Partnerships
                </p>
                <p className="text-base font-normal">Principal MFV Expositions</p>
              </div>
              <div className="ab-member">
                <img
                  className="rounded-full w-32 z-10 border-2 border-purple-600 mx-auto mb-4"
                  src={founderImg}
                  alt=""
                />
                <h2 className="text-2xl font-bold">Amanda Dainis</h2>
                <p className="text-gray-400 italic text-base">Founder</p>
                <p className="text-base font-normal">Dainis and Company, Inc</p>
              </div>
              <div className="ab-member">
                <img
                  className="rounded-full w-32 z-10 border-2 border-purple-600 mx-auto mb-4"
                  src={founderImg}
                  alt=""
                />
                <h2 className="text-2xl font-bold">Jonathan Waugh</h2>
                <p className="text-gray-400 italic text-base">Vice President Office Depot</p>
                <p className="text-base font-normal">Office Max</p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
export default AboutUs;
