import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// import { useDispatch } from 'react-redux';

import adaptive_chap3_bg from '../../../../assets/images/courses/adapting-to-change/chapter3/adaptive_chap3_bg.jpg';
import AlanWatts from '../../../../assets/images/courses/adapting-to-change/chapter3/AlanWatts.jpg';
import media from '../../../../assets/images/courses/adapting-to-change/chapter3/media.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  CourseSortingQuestion,
  QuestionOption,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import {
  course_chat_questions,
  course_sorting_matching_questions,
  course_questions as courseQuestions,
} from '../../../../courses/understanding-self-and-others/adapting-to-change/adapting-to-change.quiz';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { ActionableBlock } from '../../ActionableBlock';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import FlipCard from '../../FlipCard';
import { MediaBlock } from '../../MediaBlock';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const AdaptingToChange3: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const dispatch = useAppDispatch();
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();

  const [, setAnswers] = useState<AnswersState>({});
  const [isPageDirty, setIsPageDirty] = useState(true);

  const courseChatQuestions = course_chat_questions.filter(
    (course) => course.chapter_id === chapter.id,
  );
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const chapterSortingQuestions = course_sorting_matching_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );

  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleSortingRetake = async (questionId: string) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    const useCourseQuestionResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              questions: [...useCourseQuestionResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    const useCourseQuestionResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              questions: [...useCourseQuestionResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };

  useEffect(() => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    const useCourseQuestionResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userSortingAnswerResponse?.length &&
      useCourseQuestionResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);

  return (
    <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
      <h2 className="font-semibold text-3xl">4 Strategies for Accepting Change</h2>
      <p className="text-sm italic">
        Part {chapter.index} of {totalPages}
      </p>
      <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">
        Strategies for Accepting Change
      </h3>
      <p>
        So, how can you cope with change and arrive at that final acceptance stage? With the right
        strategies, you can progress quickly and even take advantage of all the benefits change has
        to offer. Explore four strategies below.
      </p>
      <div className="border-t relative my-20">
        <div className="bg-purple-600 w-12 h-12 rounded-full absolute left-1/2 -translate-x-1/2 -top-6 text-white flex items-center justify-center text-2xl ">
          1
        </div>
      </div>
      <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">1. Write Down Your Emotions</h3>
      <p className="mb-7">
        You can’t move toward acceptance by pushing your feelings down. To move forward in a healthy
        way, you must recognize where you are and understand why you feel that way. So, start by
        writing down your emotions. Then, next to each feeling, list out some “whys.” Flip the
        flashcards below to see some examples:
      </p>
      <div className="my-10">
        <div className="flex justify-center gap-5 text-2xl font-light">
          <FlipCard>
            <div className="front-content">
              <p>Anger</p>
            </div>
            <div className="back-content text-center  w-full h-full flex items-center">
              <p>I feel left in the dark. I don’t know why this is happening.</p>
            </div>
          </FlipCard>
          <FlipCard>
            <div className="front-content">
              <p>Fear</p>
            </div>
            <div className="back-content text-center  w-full h-full flex items-center">
              <p>I don’t have time to adapt. </p>
            </div>
          </FlipCard>
          <FlipCard>
            <div className="front-content">
              <p>Sadness</p>
            </div>
            <div className="back-content text-center  w-full h-full flex items-center">
              <p>I’ll have fewer opportunities to see my coworkers. </p>
            </div>
          </FlipCard>
        </div>
      </div>
      <p>
        By compartmentalizing your emotions in this way, you’ll take the first step toward
        understanding them and making them less overwhelming. Plus, as an added bonus, you’ll now
        have a list of concerns that you can bring up to others.
      </p>
      <div className="border-t relative my-20">
        <div className="bg-purple-600 w-12 h-12 rounded-full absolute left-1/2 -translate-x-1/2 -top-6 text-white flex items-center justify-center text-2xl ">
          2
        </div>
      </div>
      <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">2. Seek Information</h3>
      <p className="mb-7">
        Change introduces the unknown, which is a major stressor. So, to counteract those emotions,
        seek information. Be proactive and don’t wait for details to fall into your lap. When
        following this strategy, first consider your top concerns. Then, write down a list of
        questions for each. For example:
      </p>
      <table className="seek-information">
        <thead>
          <tr>
            <th>Concern</th>
            <th>Question</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>This change is pointless.</td>
            <td>Why is this change happening?</td>
          </tr>
          <tr>
            <td>I don’t know how to use that software.</td>
            <td>Will I receive training?</td>
          </tr>
          <tr>
            <td>I won't be able to work with my old team.</td>
            <td>Will I have opportunities to see my former coworkers?</td>
          </tr>
        </tbody>
      </table>
      <MediaBlock mediaImage={media}>
        <p className="text-3xl leading-10 font-light w-3/4">
          Reach out to your manager or human resources department to ask those questions. Remove
          obscurity, and the upcoming change will feel less daunting.
        </p>
      </MediaBlock>
      <div className="border-t relative my-20">
        <div className="bg-purple-600 w-12 h-12 rounded-full absolute left-1/2 -translate-x-1/2 -top-6 text-white flex items-center justify-center text-2xl ">
          3
        </div>
      </div>
      <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">
        3. Focus on What You Can Control
      </h3>
      <p className="mb-7">
        During periods of change, we can get sidetracked by all the things we can’t control. But
        regardless of what’s new, you’ll always have some control over your life and workday. You
        don’t need to feel like a victim. So, consider your concerns again and identify which ones
        you can do something about. For example:
      </p>
      <div className="my-10">
        <div className="flex justify-center gap-5 text-2xl font-light">
          <FlipCard>
            <div className="front-content text-center">
              <p>
                <strong>Concern:&nbsp;</strong>I don’t know how to use that software.
              </p>
            </div>
            <div className="back-content text-center  w-full h-full flex items-center">
              <p>
                <strong>Action:</strong> I can watch training videos online.
              </p>
            </div>
          </FlipCard>
          <FlipCard>
            <div className="front-content text-center">
              <p>
                <strong>Concern:</strong> I won’t be able to work with my old team.
              </p>
            </div>
            <div className="back-content text-center  w-full h-full flex items-center">
              <p>
                <strong>Action:&nbsp;</strong>I can invite former teammates to meet outside of
                work.&nbsp;
              </p>
            </div>
          </FlipCard>
        </div>
      </div>
      <p>
        Overall, by being proactive and addressing what you can control, your concern will shrink,
        and the impending change will start to feel more manageable.
      </p>
      <div className="border-t relative my-20">
        <div className="bg-purple-600 w-12 h-12 rounded-full absolute left-1/2 -translate-x-1/2 -top-6 text-white flex items-center justify-center text-2xl ">
          4
        </div>
      </div>
      <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">4. Consider the Positives</h3>
      <p className="mb-7">
        There are two ways of looking at everything: You can think negatively, or you can think
        positively. New, doesn’t always mean worse. And by focusing on the benefits of change—rather
        than the drawbacks—you can influence your reaction.
        <br />
        <br />
        So, consider the plusses that come with change and make a list that you can refer back to,
        if needed. For example, you might write down benefits such as:
      </p>
      <ul className="list-disc pl-5">
        <li className="mb-7">I’ll learn a new skill, which will help me grow as a professional.</li>
        <li className="mb-7">This change will teach me how to be more flexible.</li>
        <li className="mb-7">
          I’ll get to interact with new people and form positive relationships.
        </li>
        <li className="mb-7">This shift will offer new professional opportunities.</li>
      </ul>
      <p>
        By taking the time to list the benefits, you’ll learn not only to accept the change but also
        feel excited and optimistic about what’s to come.{' '}
      </p>
      <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">
        Check your understanding with the following scenario:
      </h3>
      {/* <MediaBlock mediaImage={adaptive_chap3_bg}> */}
      <ActionableBlock
        backgroundImage={adaptive_chap3_bg}
        courseChatQuestions={courseChatQuestions}
        characterImage={''}
        feedbackTitle="Scenario End"
        feedbackText="With the right strategies, you can progress quickly and even take advantage of all the benefits change has to offer. "
        buttonName="Continue"
        cssClass="adaptchange-chat"
      />

      {/* </MediaBlock> */}
      <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">
        Positive or Negative: Sorting Activity
      </h3>
      <p className="mb-7">
        There are two ways of looking at everything: You can think negatively, or you can think
        positively. Sort the following statements into their perspective piles, consider whether
        thoughts are positive or negative in reacting to change.
      </p>
      <DndProvider backend={HTML5Backend}>
        <CourseSortingQuestionBlock
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetake={handleSortingRetake}
          question={chapterSortingQuestions[0] as CourseSortingQuestion}
          submitedCorrectAnswerCount={
            userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[
              chapter.id
            ]?.sortingQuestions
              ?.filter((x) => Object.keys(x).filter((key) => key === chapterSortingQuestions[0].id))
              .map(
                (x) =>
                  x[chapterSortingQuestions[0].id].filter(
                    (x) => x.answer?.id === x.correct_answer?.id,
                  ).length,
              )[0] ?? null
          }
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          submitSortingQuestion={handleSortingExcerciseCompleted}
        />
      </DndProvider>
      <MediaBlock mediaImage={AlanWatts}>
        <p className="text-3xl leading-10 font-light w-3/4">
          The only way to make sense out of change is to plunge into it, move with it, and join the
          dance.
          <br />
          <span className="font-bold text-base">Alan Watts</span>
        </p>
      </MediaBlock>
      <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">Check Your Understanding</h3>
      <CourseQuestionCard
        submittedAnswer={userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[
          chapter.id
        ]?.questions?.at(0)}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmitAnswer={handleCourseAnswerSubmit}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onRetakeQuestion={handleQuestionRetake}
        question={chapterQuestions[0]}
      />
      <p className="text-3xl leading-10 font-light mt-7 text-center">
        Continue to the next lesson to review a course summary.
      </p>
      <button
        disabled={isPageDirty}
        onClick={handleNextChapterClick}
        className="btn-primary w-full uppercase mt-7"
      >
        Summary
      </button>
    </div>
  );
};
