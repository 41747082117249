import { User as FirebaseUser } from 'firebase/auth';

/**
 * Type of user roles
 */
export enum UserRole {
  ASA_USER = 'Asa_User',
  STUDENT = 'Student',
  ORGANIZATION_ADMIN = 'Organization_Admin',
  ADMIN = 'Admin',
  ORGANIZATION_USER = 'Organization_User',
}

/**
 * parameters for registering an account
 */
export type SignupDTO = {
  email: string;
  password: string;
};

/**
 * parameters for logging into an account
 */
export type LoginDTO = {
  email: string;
  password: string;
};

/**
 * parameters to add user claims
 */
export type AddUserClaimDto = {
  id: string;
  isAsaUser: boolean;
  asaUserId?: number | null;
  role: UserRole;
  organizationId?: string | null;
};

/**
 * Vero authenticated user
 */
export type User = FirebaseUser;
