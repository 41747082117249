import { DocumentSnapshot, doc, getDoc, setDoc } from 'firebase/firestore';

import {
  UserDocumentState,
  UserUploadedDocuments,
} from '../../redux/slice/user-documents/user-documents-type';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.USER_DOCUMENTS;

async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  try {
    const recordId: string = await getIdForFormRecord();
    await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
      merge: true,
    });
  } catch (error) {
    logger.error('Failed to update upload document records', error);
  }
}

export async function getUserDocuments(): Promise<UserUploadedDocuments[] | []> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result = (await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    )) as DocumentSnapshot<UserDocumentState>;

    return result.data()?.documents ?? [];
  } catch (error) {
    logger.error('Failed to retrieve upload document records', error);
    return [];
  }
}

export async function updateUserDocumentRecords(dto: UserDocumentState) {
  try {
    await updateRecord(dto);
  } catch (error) {
    console.log('error:', error);
    logger.error('Failed to update user document records', error);
  }
}
