import _ from 'lodash';
import React from 'react';

import { useAuthContext } from '../../hooks/use-user-auth-context.hooks';
import { CustomUser } from '../../redux/slice/auth/CustomUser';

interface ComponentProps {
  children: React.ReactNode;
  progress: number;
}
export const SkillsHeader: React.FC<ComponentProps> = ({ children, progress }) => {
  const currentUser: CustomUser | null = useAuthContext();

  const renderHeader = () => {
    return (
      <div className="text-2xl p-4 text-white ">
        <span className="font-semibold">SKILLS </span>
        SECTION
      </div>
    );
  };
  return (
    <div className="flex items-center justify-center w-full">
      <div
        className={`mx-auto w-full max-w-4xl bg-white-100 py-8 px-5 shadow-xl border-l border-r relative bg-white`}
      >
        <div className="mb-2 text-center theme-gradient rounded-xl">
          <>{renderHeader()}</>
          {currentUser?.claims.isAsaUser ? (
            <div className="w-3/4 bg-gray-200 rounded-full h-2.5 dark:bg-gray-100 m-auto relative bottom-[-5px]">
              <div
                className="bg-green-600 h-2.5 rounded-full dark:bg-green-500"
                style={{ width: `${_.clamp(progress, 0, 100)}%` }}
              ></div>
            </div>
          ) : (
            <div className="w-3/4 bg-gray-200 rounded-full h-2.5 dark:bg-gray-100 m-auto relative bottom-[-5px]">
              <div
                className="bg-green-600 h-2.5 rounded-full dark:bg-green-500"
                style={{ width: `${_.clamp(progress, 0, 100)}%` }}
              ></div>
            </div>
          )}
        </div>

        <div>{children}</div>
      </div>
    </div>
  );
};
