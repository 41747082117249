import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { questions as readinessQuestion } from '../assessments/employment-readiness/employment-readiness.assessments';
import {
  AgreementLevelQuestion,
  MarketingToolkitQuestion,
  ReadinessQuestionType,
  YesNoQuestion,
} from '../assessments/employment-readiness/employment-readiness.types';
import { AuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { CandidateMarketingToolkit } from '../components/EmploymentReadiness/CandidateMarketingToolkit';
import { EmploymentReadinessHeader } from '../components/EmploymentReadiness/EmploymentReadinessHeader';
import { JobInterview } from '../components/EmploymentReadiness/JobInterview';
import { ProfessionalProfile } from '../components/EmploymentReadiness/ProfessionalProfile';
import { ProfessionalEmail } from '../components/EmploymentReadiness/ProfessionEmailAccount';
import { ReadinessAssessment } from '../components/EmploymentReadiness/ReadinessAssessment';
import { SampleCover } from '../components/EmploymentReadiness/SampleCover';
import { Welcome } from '../components/EmploymentReadiness/Welcome';
import { WorkAttitude } from '../components/EmploymentReadiness/WorkAttitude';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../hooks/use-pre-employment-assessment.hooks';
//import { useSWOTContext } from '../hooks/use-swot-context.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchGetEmploymentReadinessValues } from '../redux/slice/form-employment-readiness';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../redux/slice/pre-employment-assessment';
import { dispatchGetSWOTReportValues } from '../redux/slice/swot/swot.operations';
//import { SWOTReport } from '../redux/slice/swot/swot.types';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';
import { splitArrayIntoChunks } from '../utils/form.utils';
export const EmploymentReadiness: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const swot: SWOTReport | undefined = useSWOTContext();
  const userProfileContext = useUserProfileContext();
  const { organization, email: userEmail } = useIntakeFormContext();

  const { componentName } = useParams();
  const {
    status: preEmploymentAssessmentStatus,
    progress,
    activeStepUrl,
    isSWOTGenerated,
  } = usePreEmploymentAssessmentContext();
  const markettingToolkitChunks: Array<MarketingToolkitQuestion[]> = splitArrayIntoChunks(
    readinessQuestion.filter(
      (question): question is MarketingToolkitQuestion =>
        question.type === ReadinessQuestionType.MARKETING_TOOLKIT,
    ),
    4,
  );
  useEffect(() => {
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchGetEmploymentReadinessValues());
    void dispatch(dispatchUpsertPreEmploymentAssessment());
    void dispatch(dispatchGetSWOTReportValues());
    void dispatch(dispatchGetUserProfileValues());
  }, []);

  const [step, setStep] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBackButton = () => {
    void dispatch(
      dispatchUpsertPreEmploymentAssessment({
        activeStepUrl: `/pre-employment/employment-readiness/step${step - 1}`,
      }),
    );
    navigate(`/pre-employment/employment-readiness/step${step - 1}`);
  };

  useEffect(() => {
    setStep(parseInt(componentName?.replace('step', '') || '1'));
    window.scrollTo(0, 0);
  }, [componentName]);

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.NOT_STARTED) {
      void dispatch(
        dispatchUpsertPreEmploymentAssessment({
          status: PreEmploymentAssessmentStatus.ACTIVE,
        }),
      );
    }
  }, [preEmploymentAssessmentStatus]);

  useEffect(() => {
    if (
      Object.keys(userProfileContext).length &&
      !userProfileContext?.isPremium &&
      userProfileContext?.email &&
      userEmail &&
      preEmploymentAssessmentStatus &&
      !organization
    ) {
      activeStepUrl
        ? navigate(activeStepUrl)
        : isSWOTGenerated
        ? navigate('/upgrade')
        : navigate('/pre-employment/start');
    } else if (
      Object.keys(userProfileContext).length &&
      (userProfileContext?.isPremium || organization) &&
      userProfileContext?.email &&
      userEmail &&
      preEmploymentAssessmentStatus
    ) {
      activeStepUrl && activeStepUrl.includes('employment-readiness') && navigate(activeStepUrl);
    }
  }, [organization, userProfileContext, userEmail, preEmploymentAssessmentStatus]);

  const yesNoQuestions: YesNoQuestion[] = readinessQuestion.filter(
    (question): question is YesNoQuestion => question.type === ReadinessQuestionType.YES_NO,
  );
  const jobInterviewQuestions: AgreementLevelQuestion[] = readinessQuestion
    .filter(
      (question): question is AgreementLevelQuestion =>
        question.type === ReadinessQuestionType.AGREEMENT_LEVEL,
    )
    .slice(0, 11);

  const workAttitudeQuestions: AgreementLevelQuestion[] = readinessQuestion
    .filter(
      (question): question is AgreementLevelQuestion =>
        question.type === ReadinessQuestionType.AGREEMENT_LEVEL,
    )
    .slice(11);

  const renderFormStep = () => {
    switch (componentName) {
      case 'step1':
        return <Welcome step={step} />;
      case 'step2':
        return (
          <ReadinessAssessment step={step} questions={yesNoQuestions} onBack={handleBackButton} />
        );
      case 'step3':
        return (
          <CandidateMarketingToolkit
            step={step}
            question={markettingToolkitChunks[0][0]}
            onBack={handleBackButton}
          />
        );
      case 'step4':
        return (
          <SampleCover
            step={step}
            question={markettingToolkitChunks[1][0]}
            onBack={handleBackButton}
          />
        );
      case 'step5':
        return (
          <ProfessionalProfile
            step={step}
            question={markettingToolkitChunks[2][0]}
            onBack={handleBackButton}
          />
        );
      case 'step6':
        return (
          <ProfessionalEmail
            step={step}
            question={markettingToolkitChunks[3][0]}
            onBack={handleBackButton}
          />
        );
      case 'step7':
        return (
          <JobInterview step={step} questions={jobInterviewQuestions} onBack={handleBackButton} />
        );
      case 'step8':
        return (
          <WorkAttitude step={step} questions={workAttitudeQuestions} onBack={handleBackButton} />
        );
      default:
        navigate('/pre-employment/employment-readiness/step1');
        return null;
    }
  };

  return (
    <div className={`steps overflow-hidden relative employment-${step}`}>
      <Container>
        <>
          {step === 1 ? (
            <>{renderFormStep()}</>
          ) : (
            <EmploymentReadinessHeader progress={progress || 0} step={step}>
              <>{renderFormStep()}</>
            </EmploymentReadinessHeader>
          )}
        </>
      </Container>
    </div>
  );
};

export const EmploymentReadinessPage: React.FC = () => {
  return <AuthenticatedPage render={() => <EmploymentReadiness />} />;
};
