import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import character from '../assets/images/intake/step1/character.svg';
import { AuthenticatedPage } from '../components/AuthenticatedPage';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { useUserAsaTaskHistoryContext } from '../hooks/use-user-asa-task-history-context.hooks';
import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchGetAsaTaskHistoryValues } from '../redux/slice/asa-task-history';
import { CustomUser } from '../redux/slice/auth/CustomUser';
import { IntakeFormEducationDTO, State } from '../redux/slice/form-intake';
import {
  dispatchGetIntakeFormValues,
  dispatchUpdateIntakeFormEducation,
} from '../redux/slice/form-intake/form-intake.operations';
import {
  ASAUserProfileDto,
  dispatchGetUserProfileValues,
  dispatchUpdateUserProfileInfo,
} from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';
import logger from '../services/logger';

interface FormData extends ASAUserProfileDto {}

const ProfileInfoSchema = yup
  .object({
    name: yup.string().trim().max(50, 'Name must not exceed 50 characters').required('Required'),
    zip: yup
      .string()
      .trim()
      .required('Required')
      .test('len', 'Must be exactly 5 characters', (val) => val?.length === 5),
    email: yup
      .string()
      .max(100, 'Email must not exceed 100 characters')
      .email('Invalid email address')
      .required('Required'),
    searchingJob: yup.string().required('Searching job is required.'),
    dateOfBirth: yup.date().optional(),
    schoolName: yup
      .string()
      .trim()
      .max(75, 'School name must not exceed 75 characters')
      .required('Required'),
    schoolState: yup.string().required('Required.'),
    schoolCity: yup
      .string()
      .trim()
      .max(20, 'School City must not exceed 20 characters')
      .required('Required'),
  })
  .required();

const AsaUserInfo: React.FC = () => {
  const navigate = useNavigate();
  const asaUserTaskHistory = useUserAsaTaskHistoryContext();
  const currentUser: CustomUser | null = useAuthContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const state_options = (Object.keys(State) as Array<keyof typeof State>)
    .filter((key) => typeof State[key] === 'string')
    .map((key) => ({ label: key, value: State[key] }));

  const {
    name: profileName,
    zip,
    dateOfBirth,
    searchingJob,
    email: userEmail,
    asaUserId,
  } = useUserProfileContext();
  const { schoolCity, schoolState, schoolName } = useIntakeFormContext();

  useEffect(() => {
    if (asaUserId) {
      setIsDisabled(true);
    }
  }, [asaUserId]);

  useEffect(() => {
    if (!Object.keys(asaUserTaskHistory).length && !asaUserTaskHistory?.tasks?.length) {
      void dispatch(dispatchGetUserProfileValues());
      void dispatch(dispatchGetAsaTaskHistoryValues());
    }
    void dispatch(dispatchGetIntakeFormValues());
  }, [asaUserTaskHistory]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(ProfileInfoSchema),
    defaultValues: {
      name: profileName || '',
      zip: zip,
      dateOfBirth: dateOfBirth,
      searchingJob: searchingJob || 'No',
      email: userEmail || '',
      schoolName: schoolName || '',
      schoolCity: schoolCity || '',
      schoolState: schoolState || '',
    },
  });
  const dispatch = useAppDispatch();
  const submitPersonalInfo: SubmitHandler<FormData> = async (data: FormData) => {
    logger.debug('Submitted personal info.', data);
    const formData = {
      name: data.name,
      phoneCountryCode: '+1',
      phone: '',
      email: data.email || '',
      dateOfBirth: data.dateOfBirth,
      profile_img: '',
      searchingJob: data.searchingJob || 'No',
      sharedWithHiringManager: false,
      goals: [],
      zip: data?.zip || '',
      asaUserId: asaUserTaskHistory.asaUserId,
    };
    const schoolInfo: IntakeFormEducationDTO = {
      schoolName: data?.schoolName || '',
      schoolState: data?.schoolState || '',
      schoolCity: data?.schoolCity || '',
    };

    setIsLoading(true);
    if (currentUser?.claims.isAsaUser) {
      const currentTask = asaUserTaskHistory.tasks?.find((x) => !x.isCompleted);
      const url = new URL(currentTask?.taskUrl || '');
      if (isDisabled) {
        navigate(`${url.pathname}${url.search}`);
      } else {
        try {
          await dispatch(dispatchUpdateUserProfileInfo(formData));
          await dispatch(dispatchUpdateIntakeFormEducation(schoolInfo));

          navigate(`${url.pathname}${url.search}`);
        } catch {
          toast.error('Something went wrong!');
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      await dispatch(dispatchUpdateUserProfileInfo(formData));
      await dispatch(dispatchUpdateIntakeFormEducation(schoolInfo));
      navigate('/pre-employment/skills/step1');
    }
  };

  useEffect(() => {
    reset({
      name: profileName,
      zip: zip,
      dateOfBirth: dateOfBirth,
      searchingJob: searchingJob || 'No',
      email: userEmail || currentUser?.user.email || '',
      schoolName: schoolName || '',
      schoolCity: schoolCity || '',
      schoolState: schoolState || '',
    });
  }, [
    reset,
    profileName,
    zip,
    dateOfBirth,
    searchingJob,
    currentUser,
    schoolCity,
    schoolName,
    schoolState,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="w-full max-w-7xl mx-auto px-4">
      <div className="border-2 border-grey-100 rounded-xl w-1/2 mx-auto w-full max-w-4xl bg-white-100 py-8 px-5 shadow-xl border-l border-r relative bg-white">
        <div className="theme-gradient text-white m-3 rounded-xl text-center text-2xl p-4">
          <span className="font-semibold"> PRELIMINARY </span> ASSESSMENT
        </div>

        <div className="flex  mt-9 mb-10 md:px-10">
          <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">01</div>
          <div>
            <p className="text-lg">Let's start by understanding a bit about you:</p>
            <h4 className="text-2xl">
              My Personal <span className="font-semibold">Information</span>
            </h4>
          </div>
        </div>
        <div className="character absolute left-[-130px] z-[-1]">
          <img className="" src={character} alt="" />
        </div>
        <Box
          className="md:px-10"
          component="form"
          sx={{
            '& > :not(style)': { mb: 4 },
          }}
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(submitPersonalInfo)}
        >
          <Controller
            control={control}
            name="name"
            render={({ field: { value, onChange } }) => (
              <TextField
                className="w-full"
                id="name"
                type="text"
                value={value}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
                onChange={(event) => onChange(event.target.value)}
                label="Name"
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            )}
          ></Controller>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Controller
              control={control}
              name="email"
              render={({ field: { value, onChange } }) => (
                <TextField
                  className="w-full"
                  id="email"
                  type="email"
                  value={value}
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  onChange={(event) => onChange(event.target.value)}
                  label="Email"
                  variant="outlined"
                  inputProps={{ maxLength: 100 }}
                />
              )}
            ></Controller>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                control={control}
                name="dateOfBirth"
                render={({ field: { value, onChange } }) => {
                  return (
                    <div>
                      <DatePicker
                        format="MMMM DD, YYYY"
                        name="dateOfBirth"
                        className="w-full"
                        closeOnSelect
                        label="Birthday"
                        value={value && dayjs(value)}
                        onChange={(date) => onChange(date)}
                        disableFuture
                        slotProps={{
                          field: {
                            readOnly: true,
                          },
                        }}
                      />
                      {errors && errors.dateOfBirth && (
                        <span className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                          {errors.dateOfBirth.message}
                        </span>
                      )}
                    </div>
                  );
                }}
              ></Controller>
            </LocalizationProvider>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Controller
                control={control}
                name="searchingJob"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id="searchingJob"
                    select
                    label="Actively looking for a job"
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    helperText=""
                    defaultValue="No"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </TextField>
                )}
              ></Controller>
              <Controller
                control={control}
                name="zip"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id="zip"
                    error={!!errors.zip}
                    helperText={errors.zip && errors.zip.message}
                    type="text"
                    inputProps={{
                      pattern: '^[0-9]*$',
                      onKeyPress: (event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      },
                      onPaste: (event) => {
                        const paste = event.clipboardData?.getData('text/plain');
                        const cleanPaste = paste.replace(/[^0-9]/g, '');
                        if (cleanPaste.length > 5) {
                          event.preventDefault();
                        } else {
                          onChange(cleanPaste);
                        }
                      },
                      maxLength: 5, // Maintain this for desktop browsers
                    }}
                    value={value}
                    onChange={(event) => {
                      const newValue = event.target.value.slice(0, 5); // Enforce max length manually
                      onChange(newValue);
                    }}
                    label="Zip"
                    variant="outlined"
                  />
                )}
              ></Controller>
            </div>
          </div>
          <div>
            {/* school info............................................................................... */}
            <div className="flex mt-9 mb-10 md:pr-10">
              <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
                1.1
              </div>
              <div>
                <p className="text-lg">Please include name, state and city of your school:</p>
                <h4 className="text-2xl">
                  My School <span className="font-semibold">Information</span>
                </h4>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6 ">
              <Controller
                control={control}
                name="schoolName"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    className="col-span-2"
                    autoComplete="off"
                    id="schoolName1"
                    type="text"
                    name="schoolName1"
                    label="School Name"
                    variant="outlined"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    inputProps={{ maxLength: 75 }}
                    error={!!errors.schoolName}
                    helperText={errors.schoolName && errors.schoolName.message}
                  />
                )}
              ></Controller>

              <Controller
                control={control}
                name="schoolState"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    className=""
                    id="outlined-select-state"
                    select
                    label="State"
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    error={!!errors.schoolState}
                    helperText={errors?.schoolState && errors?.schoolState?.message}
                    defaultValue=""
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200, // Set the maximum height of the dropdown
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem key="select" value="">
                      Select
                    </MenuItem>
                    {state_options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              ></Controller>

              <Controller
                control={control}
                name="schoolCity"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    className=""
                    id="city"
                    type="text"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    label="City/Town"
                    variant="outlined"
                    error={!!errors.schoolCity}
                    helperText={errors?.schoolCity && errors.schoolCity.message}
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              ></Controller>
            </div>
          </div>

          <div className="text-right">
            <button className="btn-primary" disabled={isLoading}>
              Next
            </button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export const AsaUserInfoPage: React.FC = () => {
  return <AuthenticatedPage render={() => <AsaUserInfo />} isAsaInfoPage={true} />;
};
