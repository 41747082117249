import _ from 'lodash';
import { useEffect, useState } from 'react';

import { quizQuestions } from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  QuestionOption,
  QuizAnswer,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { useCourseProgressContext } from '../../../../hooks/use-user-course.hooks';
import {
  dispatchGetChapterProgress,
  dispatchUpdateChapterProgress,
} from '../../../../redux/slice/user-course';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { GenericQuizQuestion } from '../../QuizQuestion';
import { QuizResult } from '../../QuizResult';
import { QuizStart } from '../../QuizStart';

type AnswersState = {
  [questionId: string]: QuizAnswer;
};
interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
  courseId?: string;
  onQuizResult?: (result: boolean | undefined) => void;
}
export const CommunicationBasicsQuiz: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
  courseId,
  onQuizResult,
}) => {
  const courseProgressContext = useCourseProgressContext();
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const dispatch = useAppDispatch();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [quizScore, setQuizScore] = useState<number | null>(null);
  const [lastQuizScore, setLastQuizScore] = useState<number | null>(null);
  const [, setAnswers] = useState<AnswersState>({});
  const [courseProgress, setCourseProgress] = useState(
    courseProgressContext[courseId || '']?.progress || 0,
  );

  const handleQuizStart = () => {
    setCurrentQuestionIndex(0);
  };

  const handleAnswerSubmit = (
    questionId: string,
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
  ) => {
    setAnswers((prevAnswers) => {
      return {
        ...prevAnswers,
        [questionId]: {
          question: quizQuestions[currentQuestionIndex].question,
          is_correct: isCorrect,
          answer_options: quizAnswers,
        },
      };
    });
    setAnswers((prevAnswers) => {
      if (currentQuestionIndex + 1 === quizQuestions.length) {
        const totalQuestions = Object.keys(prevAnswers).length;
        const correctAnswers = Object.values(prevAnswers).filter(
          (answer) => answer.is_correct,
        ).length;

        // Calculate percentage
        const score = (correctAnswers / totalQuestions) * 100;
        let chapterProgress = 100;
        setQuizScore(score);
        if (score < 80 && onQuizResult) {
          chapterProgress = 0;
          onQuizResult(false);
        } else {
          onQuizResult && onQuizResult(true);
        }
        void dispatch(
          dispatchUpdateChapterProgress({
            [courseId || '']: {
              activeChapter: chapter.id,
              chapters: { [chapter.id]: chapterProgress },
              progress: _.clamp(courseProgress, 0, 100),
            },
          }),
        );
        //return percentage;
        setQuizScore(score);
        const answersArray = Object.entries(prevAnswers);
        const quizQuestionAnswers: UserChapterQuestion[] = [];
        answersArray.map(([questionId, quizAnswer]) => {
          quizQuestionAnswers.push({
            question_id: questionId,
            answers: quizAnswer.answer_options,
            isCorrect: quizAnswer.is_correct,
            question: quizAnswer.question,
          });
        });
        void dispatch(
          dispatchUpdateUserCourseQuestionResponse({
            [courseId || '']: {
              chapters: {
                [chapter.id]: {
                  questions: [],
                  quiz: {
                    isPassed: score >= 80,
                    questions: quizQuestionAnswers,
                    score: score,
                  },
                },
              },
            },
          }),
        );
      } else {
        const quizProgress = Math.floor(((currentQuestionIndex + 1) / quizQuestions.length) * 100);
        void dispatch(
          dispatchUpdateChapterProgress({
            [courseId || '']: {
              activeChapter: chapter.id,
              chapters: { [chapter.id]: quizProgress },
              progress: _.clamp(courseProgress, 0, 100),
            },
          }),
        );
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      return { ...prevAnswers };
    });
  };
  const handleQuizRetake = async () => {
    onQuizResult && onQuizResult(undefined);
    setCurrentQuestionIndex(-1);
    setQuizScore(null);
    setAnswers({});
    await dispatch(
      dispatchUpdateChapterProgress({
        [courseId || '']: {
          activeChapter: chapter.id,
          chapters: { [chapter.id]: 0 },
          progress: _.clamp(
            (courseProgress || 0) -
              ((quizScore && quizScore >= 80) || courseProgress === 100
                ? chapter.completion_percentage
                : 0),
            0,
            100,
          ),
        },
      }),
    );
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseId || '']: {
          chapters: {
            [chapter.id]: {
              questions: [],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  useEffect(() => {
    void dispatch(dispatchGetChapterProgress());
  }, []);
  useEffect(() => {
    if (Object.keys(courseProgressContext).length) {
      setCourseProgress(courseProgressContext[courseId || '']?.progress || 0);
    }
  }, [courseProgressContext]);

  useEffect(() => {
    //Calculate course progress based on the previous quiz answers
    if (
      courseId &&
      Object.keys(userCourseQuestionResponseContext[courseId]?.chapters[chapter.id]?.quiz || {})
    ) {
      setLastQuizScore(
        userCourseQuestionResponseContext[courseId]?.chapters[chapter.id]?.quiz?.score ?? null,
      );
      setQuizScore(
        userCourseQuestionResponseContext[courseId]?.chapters[chapter.id]?.quiz?.score ?? null,
      );
    }
  }, [userCourseQuestionResponseContext]);
  return (
    <>
      {quizScore !== null ? (
        <QuizResult
          lastScore={lastQuizScore}
          courseId={courseId}
          chapter={chapter}
          onNextChapter={handleNextChapterClick}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeClick={handleQuizRetake}
          score={quizScore}
        />
      ) : currentQuestionIndex > -1 ? (
        <GenericQuizQuestion
          onAnswerSubmit={handleAnswerSubmit}
          questionType={quizQuestions[currentQuestionIndex].type}
          question={quizQuestions[currentQuestionIndex]}
          currentQuestionIndex={currentQuestionIndex + 1}
          totalQuestions={quizQuestions.length}
        />
      ) : (
        <QuizStart
          onQuizStart={handleQuizStart}
          pageIndex={chapter.index}
          totalPage={totalPages}
          description="Think you've got a handle on what it takes to improve your verbal and written communication in the workplace? Test your comprehension by answering a few questions."
        />
      )}
    </>
  );
};
