import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import character from '../../assets/images/intake/step9/char.svg';
import img5 from '../../assets/images/swot/step4/char.svg';
// import { useAuthContext } from '../../hooks/use-user-auth-context.hooks';
import { useIntakeFormContext } from '../../hooks/use-form-context.hooks';
import { useUserProfileContext } from '../../hooks/user-user-profile-context.hooks';
// import { CustomUser } from '../../redux/slice/auth/CustomUser';
import { dispatchUpsertPreEmploymentAssessment } from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import { CircularLoader } from '../loader/CircularLoader';
interface ComponentProps {
  onBack: () => void;
}
export const FeedbackSurvey: React.FC<ComponentProps> = ({ onBack }) => {
  const dispatch = useAppDispatch();
  // const user: CustomUser | null = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { organization } = useIntakeFormContext();

  const { isPremium } = useUserProfileContext();

  const navigate = useNavigate();
  const handleNextButton = async () => {
    try {
      setIsLoading(true);
      if (!isPremium && !organization) {
        navigate('/upgrade');
        void dispatch(
          dispatchUpsertPreEmploymentAssessment({
            activeStepUrl: `/upgrade`,
          }),
        );
      } else {
        navigate('/swot/step5');
      }
    } catch (error) {
      logger.error('error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const backButtonClick = () => {
    onBack();
  };
  return (
    <>
      {isLoading && <CircularLoader />}
      <div className="character absolute bottom-[-70px] right-[-200px]">
        <img className="" src={character} alt="" />
      </div>
      <div className="text-center lg:px-16">
        <img className="mx-auto mt-5" src={img5} alt="" />
        <p className="mt-8 text-xl">
          At Vero Learning, we believe that access to education and career opportunities should not
          be limited by systemic barriers. That's why we are committed to providing young adults
          with free vocational and skills-building courses, personalized career SWOT analysis
          reports and immediate job placement opportunities.
          <br /> <br />
          Your feedback is important to us, and we would love to hear about your experience using
          our platform. Please take a few minutes to complete our feedback survery so that we can
          continue to improve and provide you with the best possible service.
        </p>
      </div>
      <div className="text-center mt-8">
        <Link
          onClick={(event) => event.preventDefault()}
          target="_blank"
          to="#"
          className="btn-primary !bg-purple-500 mr-3 cursor-default"
        >
          Feedback
        </Link>
      </div>
      <div className="text-center mt-16">
        <button onClick={backButtonClick} className="btn-primary mr-5">
          Back
        </button>

        <button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleNextButton}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    </>
  );
};
