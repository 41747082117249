import { useState } from 'react';

import cbColor from '../../../../assets/images/courses/perception-understanding/chapter4/cbColor.jpg';
import cbGray from '../../../../assets/images/courses/perception-understanding/chapter4/cbGray.jpg';
import colorImg from '../../../../assets/images/courses/perception-understanding/chapter4/colorImg.jpg';
import egColor from '../../../../assets/images/courses/perception-understanding/chapter4/egColor.jpg';
import egGray from '../../../../assets/images/courses/perception-understanding/chapter4/egGray.jpg';
import frColor from '../../../../assets/images/courses/perception-understanding/chapter4/frColor.jpg';
import frGray from '../../../../assets/images/courses/perception-understanding/chapter4/frGray.jpg';
import introImg from '../../../../assets/images/courses/perception-understanding/chapter4/introImg.jpg';
import syColor from '../../../../assets/images/courses/perception-understanding/chapter4/syColor.jpg';
import syGray from '../../../../assets/images/courses/perception-understanding/chapter4/syGray.jpg';
import yourColor from '../../../../assets/images/courses/perception-understanding/chapter4/yourColor.png';
import { CourseChapter } from '../../../../courses/courses.types';
import FlipCard from '../../FlipCard';
import { InfoBlock } from '../../InfoBlock';
import { MediaBlock } from '../../MediaBlock';

interface PerciptionProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

interface ColorCounts {
  Red: number;
  Yellow: number;
  Green: number;
  Blue: number;
}
type Characteristic = {
  Characteristic: string;
  Color: string;
};

export const PerceptionAndUnderstandingChapter4: React.FC<PerciptionProps> = ({
  chapter,
  onNextChapter,
}) => {
  const characteristics: Characteristic[] = [
    {
      Characteristic: 'determined',
      Color: 'Red',
    },
    {
      Characteristic: 'strong-willed',
      Color: 'Red',
    },
    {
      Characteristic: 'fun',
      Color: 'Yellow',
    },
    {
      Characteristic: 'outgoing',
      Color: 'Yellow',
    },
    {
      Characteristic: 'patient',
      Color: 'Green',
    },
    {
      Characteristic: 'reliable',
      Color: 'Green',
    },
    {
      Characteristic: 'active',
      Color: 'Yellow',
    },
    {
      Characteristic: 'firm',
      Color: 'Red',
    },
    {
      Characteristic: 'structured',
      Color: 'Blue',
    },
    {
      Characteristic: 'assertive',
      Color: 'Red',
    },
    {
      Characteristic: 'driving',
      Color: 'Red',
    },
    {
      Characteristic: 'purposeful',
      Color: 'Red',
    },
    {
      Characteristic: 'steady',
      Color: 'Green',
    },
    {
      Characteristic: 'cheerful',
      Color: 'Yellow',
    },
    {
      Characteristic: 'logical',
      Color: 'Blue',
    },
    {
      Characteristic: 'caring',
      Color: 'Green',
    },
    {
      Characteristic: 'accommodating',
      Color: 'Green',
    },
    {
      Characteristic: 'challenging',
      Color: 'Red',
    },
    {
      Characteristic: 'objective',
      Color: 'Red',
    },
    {
      Characteristic: 'friendly',
      Color: 'Yellow',
    },
    {
      Characteristic: 'accurate',
      Color: 'Blue',
    },
    {
      Characteristic: 'enthusiastic',
      Color: 'Yellow',
    },
    {
      Characteristic: 'stable',
      Color: 'Green',
    },
    {
      Characteristic: 'convincing',
      Color: 'Yellow',
    },
    {
      Characteristic: 'calm',
      Color: 'Green',
    },
    {
      Characteristic: 'diplomatic',
      Color: 'Blue',
    },
    {
      Characteristic: 'calculating',
      Color: 'Green',
    },
    {
      Characteristic: 'influencing',
      Color: 'Yellow',
    },
    {
      Characteristic: 'reflective',
      Color: 'Green',
    },
    {
      Characteristic: 'amenable',
      Color: 'Green',
    },
    {
      Characteristic: 'mobile',
      Color: 'Yellow',
    },
    {
      Characteristic: 'consistent',
      Color: 'Blue',
    },
    {
      Characteristic: 'realistic',
      Color: 'Red',
    },
    {
      Characteristic: 'correct',
      Color: 'Blue',
    },
    {
      Characteristic: 'courageous',
      Color: 'Red',
    },
    {
      Characteristic: 'factual',
      Color: 'Blue',
    },
    {
      Characteristic: 'harmonious',
      Color: 'Green',
    },
    {
      Characteristic: 'analytical',
      Color: 'Blue',
    },
    {
      Characteristic: 'engaging',
      Color: 'Yellow',
    },
    {
      Characteristic: 'cautious',
      Color: 'Green',
    },
    {
      Characteristic: 'optimistic',
      Color: 'Yellow',
    },
    {
      Characteristic: 'sensitive',
      Color: 'Green',
    },
    {
      Characteristic: 'sociable',
      Color: 'Yellow',
    },
    {
      Characteristic: 'persuasive',
      Color: 'Yellow',
    },
    {
      Characteristic: 'conventional',
      Color: 'Blue',
    },
    {
      Characteristic: 'impulsive',
      Color: 'Yellow',
    },
    {
      Characteristic: 'concise',
      Color: 'Blue',
    },
    {
      Characteristic: 'exact',
      Color: 'Blue',
    },
    {
      Characteristic: 'forceful',
      Color: 'Red',
    },
    {
      Characteristic: 'calm',
      Color: 'Green',
    },
    {
      Characteristic: 'daring',
      Color: 'Red',
    },
    {
      Characteristic: 'decisive',
      Color: 'Red',
    },
    {
      Characteristic: 'cooperative',
      Color: 'Green',
    },
    {
      Characteristic: 'loyal',
      Color: 'Green',
    },
    {
      Characteristic: 'tactful',
      Color: 'Blue',
    },
  ];
  const [selectedWords, setSelectedWords] = useState<Characteristic[]>([]);
  const [counts, setCounts] = useState<ColorCounts>({
    Red: 0,
    Yellow: 0,
    Green: 0,
    Blue: 0,
  });
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };

  const handleSelection = (selectedItem: Characteristic) => {
    if (selectedWords.filter((x) => x.Characteristic === selectedItem.Characteristic).length) {
      setSelectedWords((prevSelected) =>
        prevSelected.filter((word) => word.Characteristic !== selectedItem.Characteristic),
      );
      setCounts((prevCounts) => ({
        ...prevCounts,
        [selectedItem.Color]: prevCounts[selectedItem.Color as keyof ColorCounts] - 1,
      }));
    } else {
      setSelectedWords((prevSelected) => [...prevSelected, selectedItem]);
      setCounts((prevCounts) => ({
        ...prevCounts,
        [selectedItem.Color]: prevCounts[selectedItem.Color as keyof ColorCounts] + 1,
      }));
    }
  };

  const handlePrint = () => {
    const printArea = document.getElementById('print-view');
    if (printArea) {
      printArea.classList.add('print-view');
      window.print();
      printArea.classList.remove('print-view');
    }
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-xl font-light">
        <h2 className="font-semibold text-3xl">Insights Discovery</h2>
        <p className="text-sm italic">Part 4 of 10</p>
        <MediaBlock mediaImage={introImg}></MediaBlock>

        <p className="text-2xl leading-10 mb-8 mt-8">
          Insights Discovery uses color energies to describe our Jungian preferences. The color
          energies that make up our styles include: Fiery Red, Sunshine Yellow, Earth Green, and
          Cool Blue.
        </p>
        <div className="flex items-center mb-11">
          <img className="w-96 mr-9" src={yourColor} alt="" />
          <div>
            <p>
              <span className="font-bold">Your Color Mix Carl</span>
              <br />
              <br />
              Jung posits that every person is unique and has deep-rooted psychological preferences
              that effect our world-view. In the Insights world, we talk about the four color
              energies we all posses and how the combination of the four energies creates the unique
              YOU.
            </p>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          You are packing to go on a vacation. What does your suitcase look like?
        </h3>
        <p>Click on the card that most resonates with what your suitcase would look like.</p>
        <div className="my-10">
          <div className="flex justify-center gap-5 mb-7">
            <FlipCard>
              <div className="front-content">
                <img className="w-full h-full object-contain" src={cbGray} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full">
                <img className="w-full h-full object-contain" src={cbColor} alt="" />
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content">
                <img className="w-full h-full object-contain" src={frGray} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full">
                <img className="w-full h-full object-contain" src={frColor} alt="" />
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content">
                <img className="w-full h-full object-contain" src={egGray} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full">
                <img className="w-full h-full object-contain" src={egColor} alt="" />
              </div>
            </FlipCard>
          </div>
          <div className="flex justify-center gap-5">
            <FlipCard>
              <div className="front-content">
                <img className="w-full h-full object-contain" src={syGray} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full">
                <img className="w-full h-full object-contain" src={syColor} alt="" />
              </div>
            </FlipCard>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Activity: My Characteristics
        </h3>
        <p>
          This quick exercise will give you a rough sketch of your personal preferences and dynamic
          colors.
        </p>
        <p className="font-bold my-8">
          Click to select (or de-select) all the characteristics that you feel describe or resonate
          with you.
        </p>
        <InfoBlock title="Note: " text="You can save or print your responses upon completion." />

        <div id="print-view" className="words-wrap bg-purple-100 rounded-3xl p-5">
          <div className="words-container bg-white p-5 rounded-3xl shadow">
            {characteristics.map((characteristic, index) => (
              <span
                key={index}
                className={`cursor-pointer p-2 m-1 rounded ${
                  selectedWords.filter((x) => x.Characteristic === characteristic.Characteristic)
                    .length
                    ? 'bg-gray-200'
                    : ''
                }`}
                onClick={() => handleSelection(characteristic)}
              >
                {characteristic.Characteristic}
              </span>
            ))}
          </div>
          <div className="flex justify-between items-center gap-4 mt-4">
            <p className="text-2xl">YOUR TOTALS:</p>
            <div className="total-cards flex gap-4">
              <div className="bg-red rounded-xl shadow text-white px-10 py-1 text-center">
                <p className="text-2xl font-bold">{counts.Red}</p>
                <p>Red</p>
              </div>
              <div className="bg-[#fdda6b] rounded-xl shadow text-white px-10 py-1 text-center">
                <p className="text-2xl font-bold">{counts.Yellow}</p>
                <p>Yellow</p>
              </div>
              <div className="bg-green rounded-xl shadow text-white px-10 py-1 text-center">
                <p className="text-2xl font-bold">{counts.Green}</p>
                <p>Green</p>
              </div>
              <div className="bg-blue rounded-xl shadow text-white px-10 py-1 text-center">
                <p className="text-2xl font-bold">{counts.Blue}</p>
                <p>Blue</p>
              </div>
            </div>
            <button onClick={handlePrint} className="btn-primary">
              PRINT
            </button>
          </div>
        </div>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-6">
          What color was your highest scoring color?
        </h3>
        <p className="text-2xl font-medium mb-2">What's your learning style?</p>

        <p>Click on the card that most resonates with how you prefer to learn.</p>
        <div className="my-10">
          <div className="flex justify-center gap-5 mb-7">
            <FlipCard>
              <div className="front-content text-center">
                <p>Prefer to learn with others and have 'hands on' experience</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div className="bg-yellow w-32 h-32 flex items-center justify-center p-5">
                  Sunshin Yellow
                </div>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content text-center">
                <p>Prefer to learn by reflecting on information gathered</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div className="bg-green w-32 h-32 flex items-center justify-center p-5">
                  Earth Green
                </div>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content text-center">
                <p>Prefer to learn without any distractions so you can concentrate</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div className="bg-blue w-32 h-32 flex items-center justify-center p-5">
                  Cool Blue
                </div>
              </div>
            </FlipCard>
          </div>
          <div className="flex justify-center gap-5">
            <FlipCard>
              <div className="front-content text-center">
                <p>Prefer to learn practical information you can use right away</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div className="bg-red w-32 h-32 flex items-center justify-center p-5">
                  Fiery Red
                </div>
              </div>
            </FlipCard>
          </div>
        </div>

        <InfoBlock
          title=""
          text="If you would like a comprehensive analysis we recommend completing the Insights Discovery Profile and have it interpreted by a professional. "
        />
        <p className="text-3xl leading-10 mt-8">
          The best thing about these four colors is that{' '}
          <span className="font-bold"> NO COLOR IS BETTER THAN THE OTHERS</span> and the best teams
          often represent all colors. Knowing your and your colleagues' preferred ways of working
          can help you improve team work.
        </p>
        <MediaBlock mediaImage={colorImg}>
          <p className="text-2xl leading-10 w-3/4">
            Most people are not 100% one color. However, most people do have one dominant colour.
          </p>
        </MediaBlock>
        <p className="text-3xl leading-10 mt-8">
          Let's learn more about the specific color energies.
        </p>
        <button onClick={handleNextChapterClick} className="w-full btn-primary uppercase mt-8">
          COLOR ENERGIES & PREFERENCES
        </button>
      </div>
    </>
  );
};
