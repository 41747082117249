import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import { upsertNewsletterSignup } from '../../../services/database/form-newsletter';

import { updateNewsletterSignupAction } from './form-newsletter.slice';
import { NewsLetterDTO } from './form-newsletter.types';

/**
 * update personal information in the intake form
 */
export const dispatchUpdateNewsletterFormSignup =
  (dto: NewsLetterDTO) =>
  async (dispatch: Dispatch<PayloadAction<NewsLetterDTO>>): Promise<void> => {
    await upsertNewsletterSignup(dto);
    dispatch(updateNewsletterSignupAction(dto));
  };
