import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import char from '../../assets/images/intake/step13/char.svg';
import { useIntakeFormContext, useSkillsContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import { useUserAsaTaskHistoryContext } from '../../hooks/use-user-asa-task-history-context.hooks';
import { useAuthContext } from '../../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../../hooks/user-user-profile-context.hooks';
import {
  AsaTaskName,
  AsaUserTasks,
  completeASATask,
  dispatchUpdateAsaTaskHistoryInfo,
} from '../../redux/slice/asa-task-history';
import { CustomUser } from '../../redux/slice/auth/CustomUser';
import { ConfidenceLevel, dispatchUpdateSkills } from '../../redux/slice/form-skills';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import { EnvironmentVariable } from '../../utils/constants';
import { buildASANextTaskUrl } from '../../utils/helper';
import { CircularLoader } from '../loader/CircularLoader';

import { skillOptions } from './Communication';

interface ComponentProps {
  step: number;
  onBack: () => void;
}

interface FormData {
  skillTeamwork: string;
}

export const TeamWork: React.FC<ComponentProps> = ({ step, onBack }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const asaUserTaskHistory = useUserAsaTaskHistoryContext();
  const currentUser: CustomUser | null = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currsentAsaTask, setCurrentAsaTask] = useState<AsaUserTasks | undefined>(undefined);
  const { skillTeamwork } = useSkillsContext();
  const userProfileContext = useUserProfileContext();
  const { organization } = useIntakeFormContext();

  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
    didCompleteSkills,
    didAsaUserViewedSwot,
  } = usePreEmploymentAssessmentContext();
  const [error, setError] = useState('');
  const [selectedOption, setSelectedOption] = useState(skillTeamwork);
  const { handleSubmit } = useForm<FormData>();
  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    } else if (didCompleteSkills) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);

  useEffect(() => {
    if (
      currentUser?.claims?.isAsaUser &&
      Object.keys(asaUserTaskHistory).length &&
      asaUserTaskHistory?.tasks?.length
    ) {
      const currentTask = asaUserTaskHistory.tasks?.find(
        (x) => !x.isCompleted && x.taskName === AsaTaskName.SKILL,
      );
      setCurrentAsaTask(currentTask);
      if (currsentAsaTask && (currsentAsaTask?.taskStep as number) != 3) {
        const url = new URL(currsentAsaTask?.taskUrl || '');
        navigate(`${url.pathname}${url.search}`);
        setIsDisabled(true);
      }
    }
  }, [asaUserTaskHistory, currentUser, currsentAsaTask]);

  const backButtonClick = () => {
    onBack();
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(parseInt(event.target.value));
    setError('');
  };

  const submitForm: SubmitHandler<FormData> = async () => {
    logger.debug('Submitted skill teamwork form.', selectedOption);

    const error_message: string = 'Please select an option';
    setIsLoading(true);
    try {
      if (selectedOption === undefined) {
        setError(error_message);
      } else {
        if (isDisabled) {
          navigate(`/pre-employment/skills/step${step + 1}`);
        } else {
          const dto: Record<keyof FormData, ConfidenceLevel> = {
            skillTeamwork: selectedOption as ConfidenceLevel,
          };
          await dispatch(dispatchUpdateSkills(dto));
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: !_.isNil(skillTeamwork) ? progress : totalProgress,
                activeStepUrl: `/pre-employment/skills/step${step + 1}`,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/skills/step${step + 1}`,
              }),
            );
          }
          if (currentUser?.claims?.isAsaUser && currsentAsaTask) {
            const endPointUrl = process.env[EnvironmentVariable.REACT_APP_ASA_COMPLETE_TASK_URL];
            const isStaging =
              process.env[EnvironmentVariable.REACT_APP_BASE_URL]?.includes('staging') ||
              process.env[EnvironmentVariable.REACT_APP_BASE_URL]?.includes('localhost');
            const taskResponse = await completeASATask(
              {
                asaUserId: currentUser?.claims?.asaUserId as number,
                taskId: currsentAsaTask?.taskId,
                taskStep: currsentAsaTask?.taskStep || step,
              },
              endPointUrl || '',
              isStaging || false,
            );

            if (!taskResponse || !taskResponse?.ok) {
              toast.error('Something went wrong!, Please try after sometime.');
              return;
            }

            let url = new URL(currsentAsaTask?.taskUrl || '');
            const nextTaskUrl = buildASANextTaskUrl(url, AsaTaskName.SKILL, step + 1);
            url = new URL(nextTaskUrl || '');
            await dispatch(
              dispatchUpdateAsaTaskHistoryInfo({
                startDate: new Date(),
                asaUserId: currentUser?.claims?.asaUserId || 0,
                taskId: currsentAsaTask?.taskId || '',
                taskStep: parseInt(currsentAsaTask?.taskStep.toString()) + 1 || 4,
                taskUrl: nextTaskUrl,
                taskName: AsaTaskName.SKILL,
                totalSteps: 11,
              }),
            );

            await dispatch(
              dispatchUpdateAsaTaskHistoryInfo({
                asaUserId: currentUser?.claims?.asaUserId || 0,
                taskId: currsentAsaTask?.taskId || '',
                taskStep: currsentAsaTask?.taskStep || step,
                isCompleted: true,
                completedDate: new Date(),
              }),
            );
            toast.success('Task has been successfylly submitted');
            navigate(`${url.pathname}${url.search}`);
          } else {
            navigate(`/pre-employment/skills/step${step + 1}`);
          }
        }
      }
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (skillTeamwork !== undefined) {
      setSelectedOption(skillTeamwork);
    }
  }, [skillTeamwork]);

  return (
    <div>
      {isLoading && <CircularLoader />}

      <div className="flex mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
          {!didAsaUserViewedSwot && !userProfileContext.isPremium && !organization ? step - 1 : 18}
        </div>
        <div>
          <h4 className="text-2xl">
            <span className="font-semibold">How comfortable are you </span>
            with each of these competencies
          </h4>
        </div>
      </div>
      <div className="character absolute top-96 right-[-125px] z-[-1]">
        <img className="" src={char} alt="" />
      </div>
      <form
        className=" md:px-10"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitForm)}
      >
        <h4 className="mb-1 font-semibold text-purple-700 text-base">Competency</h4>
        <h3 className="font-semibold text-black text-xl mb-8">Teamwork</h3>
        <h4 className="mb-2 font-semibold text-purple-700 text-base">Description</h4>
        <p className="mb-6">
          Build and maintain collaborative relationships to work effectively with others in a team
          setting through shared responsibility, empathy and respect.
        </p>
        <h4 className="mb-1 font-semibold text-purple-700 text-base mb-3">
          Competency items to be rated
        </h4>
        <ul className="list-disc pl-6">
          <li className="mb-2">Able and willing to share and receive information.</li>
          <li className="mb-2">Encourage and respect the input of other team members.</li>
          <li className="mb-2">Communicate openly and honestly.</li>
          <li className="mb-2">Co-operate within the group and across groups.</li>
          <li className="mb-2">Support group decisions and put group goals ahead of own goals.</li>
          <li className="mb-2">
            Commit to the common goal and fulfill individual responsibilities.
          </li>
          <li className="mb-2">Handle difficult conversations with respect.</li>
        </ul>
        <div className="rating-btns flex flex-wrap lg:flex-nowrap items-center mt-10">
          {skillOptions.map((option) => (
            <div key={option.label} className="h-[55px] mr-5 mb-3">
              <label className="radio-container h-full block relative">
                <input
                  disabled={isDisabled}
                  checked={selectedOption === option.value}
                  onChange={handleOptionChange}
                  className="w-full h-full absolute top-0 left-0 opacity-0"
                  type="radio"
                  name="communication"
                  value={option.value}
                />
                <span className="checkmark  !static !h-[55px] !w-auto !py-4 !px-5 !text-sm !whitespace-nowrap font-light !text-black">
                  {option.label}
                </span>
              </label>
            </div>
          ))}
        </div>
        {error && <p className="text-red-500 font-light !text-sm pt-5">{error}</p>}
        <div className="flex justify-end lg:justify-between lg:mt-20 mt-10">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className={`btn-primary  ${currsentAsaTask ? 'hidden' : ''}`}
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            {currsentAsaTask ? 'Submit' : ' Next'}
          </button>
        </div>
      </form>
    </div>
  );
};
