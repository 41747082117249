import React from 'react';
import { useNavigate } from 'react-router-dom';

import character from '../../assets/images/intake/step2/char.svg';
import img5 from '../../assets/images/swot/step1/char.svg';

export const Greetings: React.FC = () => {
  const navigate = useNavigate();
  const handleNextButton = () => {
    navigate('/swot/step2');
  };
  return (
    <>
      <div className="character absolute bottom-0 left-[-138px] z-0">
        <img className="" src={character} alt="" />
      </div>
      <div className="text-center lg:px-16">
        <img className="mx-auto mt-5" src={img5} alt="" />
        <p className="mt-8 text-xl">
          <span className="uppercase font-bold text-purple-700">
            <span>
              Hello There!
              <br />
            </span>
          </span>
          <br />
          Congratulations on talking the first step towards your career goals with Vero Learning! We
          are thrilled to have you on board and excited to help you reach your full potential. Our
          platform is designed to help you identify your unique skill sets and areas for
          improvement. Use this information to carve out a personalized training plan to boost your
          employability and help you make more informed career choices.
        </p>
      </div>
      <div className="text-center mt-12">
        <button onClick={handleNextButton} className="btn-primary">
          Next
        </button>
      </div>
    </>
  );
};
