import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  AssessmentTooltip,
  EmploymentReadinessAnswer,
  YesNoAnswer,
  YesNoQuestion,
} from '../../assessments/employment-readiness/employment-readiness.types';
import character from '../../assets/images/intake/step9/char.svg';
import { useEmploymentReadinessContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  YesNoSomewhat,
  dispatchUpdateEmploymentReadiness,
  EmploymentReadinessState,
} from '../../redux/slice/form-employment-readiness';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
interface ComponentProps {
  questions: YesNoQuestion[];
  step: number;
  onBack: () => void;
}

type AnswersState = {
  [questionId: string]: EmploymentReadinessAnswer;
};

const radio_options = [
  {
    label: 'Yes',
    value: YesNoSomewhat.YES,
  },
  {
    label: 'No',
    value: YesNoSomewhat.NO,
  },
  {
    label: 'Somewhat',
    value: YesNoSomewhat.SOMEWHAT,
  },
];
export const ReadinessAssessment: React.FC<ComponentProps> = ({ questions, step, onBack }) => {
  const dispatch = useAppDispatch();
  const [isDisabled, setIsDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const savedAnswers: EmploymentReadinessState = useEmploymentReadinessContext();
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
  } = usePreEmploymentAssessmentContext();
  const [error, setError] = useState('');
  const [answers, setAnswers] = useState<AnswersState>({});
  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);
  function updateAnswer(
    questionId: string,
    updatedAnswer: Partial<EmploymentReadinessAnswer>,
  ): void {
    setError('');
    setAnswers((previousState: AnswersState) => ({
      ...previousState,
      [questionId]: {
        ...answers[questionId],
        ...updatedAnswer,
      },
    }));
  }
  const { handleSubmit } = useForm();
  const backButtonClick = () => {
    onBack();
  };
  const hasMissingAnswers: boolean = questions.some((question: YesNoQuestion): boolean => {
    if (!answers[question.id]) {
      return true;
    }

    return false;
  });
  const submitAssessment = async () => {
    logger.debug('Submitted employment readiness assessment form.', answers);
    setIsLoading(true);
    const error_message: string = 'Please select an option from each section';
    try {
      if (hasMissingAnswers) {
        setError(error_message);
      } else {
        if (isDisabled) {
          navigate(`/pre-employment/employment-readiness/step${step + 1}`);
        } else {
          await dispatch(dispatchUpdateEmploymentReadiness(answers));
          navigate(`/pre-employment/employment-readiness/step${step + 1}`);
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: savedAnswers[questions[0].id] ? progress : totalProgress,
                activeStepUrl: `/pre-employment/employment-readiness/step${step + 1}`,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/employment-readiness/step${step + 1}`,
              }),
            );
          }
        }
      }
    } catch (err) {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (savedAnswers && Object.keys(savedAnswers).length) {
      setAnswers(savedAnswers);
    }
  }, [savedAnswers]);

  const renderTextWithTooltips = (text: string, tooltip: AssessmentTooltip) => {
    const words = text.split(' ');

    return words.map((word, index) => {
      if (word === tooltip.title) {
        return (
          <span key={index} className="tooltip text-purple-500 underline font-semibold">
            {word}
            <span className="tooltiptext">
              {tooltip.content}
              <ul className="list-disc pl-5 mt-4">
                {tooltip.bulletList?.map((option: string) => (
                  <li key={option}>{option}</li>
                ))}
              </ul>
            </span>
          </span>
        );
      } else {
        return <React.Fragment key={index}> {word} </React.Fragment>;
      }
    });
  };
  function convertValueYesNoSomewhatToString(
    answer: EmploymentReadinessAnswer,
  ): 'yes' | 'no' | 'somewhat' | undefined {
    const typedAnswer: YesNoAnswer = answer as YesNoAnswer;
    if (typedAnswer?.answer === undefined) {
      return undefined;
    } else if (typedAnswer?.answer === YesNoSomewhat.YES) {
      return 'yes';
    } else if (typedAnswer?.answer === YesNoSomewhat.NO) {
      return 'no';
    } else {
      return 'somewhat';
    }
  }
  return (
    <>
      <div className="flex  mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">10</div>
        <div>
          <h4 className="text-2xl">
            <span className="font-semibold">Do you agree</span> with the following statements?
          </h4>
        </div>
      </div>
      <div className="character absolute top-96 right-[-200px] z-[-1]">
        <img className="" src={character} alt="" />
      </div>
      <form
        className=" md:px-16"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitAssessment)}
      >
        <ol className="pl-5" style={{ listStyle: 'upper-latin' }} type="A">
          {questions.map((question: YesNoQuestion) => (
            <li className="mb-5" key={question.id}>
              {question.tooltip
                ? renderTextWithTooltips(question.question, question.tooltip)
                : question.question}
              <div className="rating-btns flex items-center flex-wrap mt-3">
                {radio_options.map((option) => (
                  <div key={option.label} className="h-[55px] mr-5 mb-3">
                    <label className="radio-container h-full block relative">
                      <input
                        disabled={isDisabled}
                        checked={
                          convertValueYesNoSomewhatToString(answers[question.id]) === option.value
                        }
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          updateAnswer(question.id, {
                            question: question.question,
                            answer: event.target.value as YesNoSomewhat,
                          })
                        }
                        value={option.value}
                        className="w-full h-full absolute top-0 left-0 opacity-0"
                        type="radio"
                        name={`employment-${question.id}`}
                      />
                      <span className="checkmark  !static !h-[55px] !w-auto !py-4 !px-5 !text-sm !whitespace-nowrap font-light !text-black">
                        {option.label}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </li>
          ))}
        </ol>
        {error && <p className="text-red-500 font-light !text-sm">{error}</p>}
        <div className="flex justify-end lg:justify-between lg:mt-20 mt-10">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </>
  );
};
