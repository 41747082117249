import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useIntakeFormContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  CareerPlanDirection,
  CareerPlan as CareerPlanValue,
  IntakeFormCareerPlanDTO,
} from '../../redux/slice/form-intake';
import {
  dispatchGetIntakeFormValues,
  dispatchUpdateIntakeFormCareerPlan,
} from '../../redux/slice/form-intake/form-intake.operations';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';

interface CareerPlanProps {
  step: number;
  onBack: () => void;
}

interface FormData {
  careerPlan: string;
  careerPlanDirection: string;
}

const career_options = [
  {
    value: CareerPlanValue.FIND_EMPLOYMENT,
    label: 'Find employment',
  },
  {
    value: CareerPlanValue.CHANGE_JOB,
    label: 'Change job',
  },
  {
    value: CareerPlanValue.COLLEGE,
    label: 'Go to college',
  },
  {
    value: CareerPlanValue.UNIVERSITY,
    label: 'Go to university',
  },
  {
    value: CareerPlanValue.INTERNSHIP,
    label: 'Start an internship',
  },
  {
    value: CareerPlanValue.APPRENTICESHIP,
    label: 'Start an apprenticeship',
  },
  {
    value: CareerPlanValue.NOT_SURE,
    label: 'Not Sure',
  },
  {
    value: CareerPlanValue.OTHER,
    label: 'Other',
    is_editable: true,
  },
];
export const CareerPlan: React.FC<CareerPlanProps> = ({ step, onBack }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    void dispatch(dispatchGetIntakeFormValues());
  }, []);

  const { careerPlan, careerPlanRemarks, careerPlanDirection, futureGoal } = useIntakeFormContext();
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
  } = usePreEmploymentAssessmentContext();
  const [selectedCareerPlan, setSelectedCareerPlan] = useState(careerPlan || '');
  const [selectedCareerPlanDirection, setSelectedCareerPlanDirection] = useState(
    careerPlanDirection || '',
  );
  const [otherRemarks, setOtherRemarks] = useState(careerPlanRemarks || '');
  const [futureGoals, SetFutureGoals] = useState(futureGoal || '');
  const [error, setError] = useState({
    plan: '',
    work_knowledge: '',
    other: '',
    futurePlan: '',
  });
  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);

  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, isOther: boolean) => {
    if (!isOther) {
      setOtherRemarks('');
    } else {
      setOtherRemarks(event.target.value);
      if (event.target.value.trim() && !selectedCareerPlan.includes(CareerPlanValue.OTHER)) {
        setSelectedCareerPlan(CareerPlanValue.OTHER);
      } else if (!event.target.value.trim()) {
        setSelectedCareerPlan('');
      }
    }
  };

  const handleFutureGoal = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    SetFutureGoals(event.target.value);
    setError({ ...error, futurePlan: '' });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll to the top
  }, []);

  useEffect(() => {
    if (careerPlan) {
      setSelectedCareerPlan(careerPlan);
    }
    if (careerPlanDirection) {
      setSelectedCareerPlanDirection(careerPlanDirection);
    }
    if (careerPlanRemarks) {
      setOtherRemarks(careerPlanRemarks);
    }
    if (futureGoal) {
      SetFutureGoals(futureGoal);
    }
  }, [careerPlan, careerPlanDirection, careerPlanRemarks, futureGoal]);

  const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCareerPlan(event.target.value);
    setError({ ...error, plan: '' });
  };

  const handleWorkKnowledgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCareerPlanDirection(event.target.value);
    setError({ ...error, work_knowledge: '' });
  };

  const { handleSubmit } = useForm<FormData>();
  const submitCareerPlan: SubmitHandler<FormData> = async () => {
    logger.debug('Submitted career plan form.', {
      selectedCareerPlan,
      selectedCareerPlanDirection,
    });
    setIsLoading(true);
    try {
      const isOtherSelected: boolean = selectedCareerPlan.includes(CareerPlanValue.OTHER);
      if (!selectedCareerPlan) {
        setError({ ...error, plan: 'Please select an option' });
      } else if (isOtherSelected && !otherRemarks?.trim()?.length) {
        setError({ ...error, other: 'Please explain the other category' });
      } else if (!selectedCareerPlanDirection.length) {
        setError({ ...error, work_knowledge: 'Please select an option.' });
      } else if (futureGoals && !futureGoals?.trim()?.length) {
        setError({ ...error, futurePlan: 'Please explain the future goals' });
      } else {
        if (isDisabled) {
          navigate(`/pre-employment/intake/step${step + 1}`);
        } else {
          const dto: IntakeFormCareerPlanDTO = {
            careerPlan: selectedCareerPlan as CareerPlanValue,
            careerPlanDirection: selectedCareerPlanDirection as CareerPlanDirection,
            careerPlanRemarks: isOtherSelected ? otherRemarks : undefined,
            futureGoal: futureGoals,
          };

          await dispatch(dispatchUpdateIntakeFormCareerPlan(dto));

          if (progress && defaultStepProgress) {
            const preEmploymentProgress = careerPlan
              ? progress -
                defaultStepProgress * (dto.careerPlanDirection === CareerPlanDirection.NO ? 8 : 0)
              : progress +
                defaultStepProgress * (dto.careerPlanDirection === CareerPlanDirection.NO ? 8 : 1);
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: preEmploymentProgress,
                activeStepUrl: `/pre-employment/intake/step${step + 1}`,
                requiresCareerReadinessAssessment:
                  dto.careerPlanDirection !== CareerPlanDirection.NO,
                requiresEmploymentReadiness: dto.careerPlanDirection !== CareerPlanDirection.NO,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/intake/step${step + 1}`,
                requiresCareerReadinessAssessment:
                  dto.careerPlanDirection !== CareerPlanDirection.NO,
                requiresEmploymentReadiness: dto.careerPlanDirection !== CareerPlanDirection.NO,
              }),
            );
          }
          navigate(`/pre-employment/intake/step${step + 1}`);
        }
      }
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  const backButtonClick = () => {
    onBack();
  };
  return (
    <div>
      <p className="text-lg mt-7 md:px-10">
        Do you have a clear career path in mind and well-defined goals? We're here to guide you
        through understanding your aspirations and crafting a roadmap to your dream career.
      </p>
      <div className="flex items-center mt-9 mb-10  md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">07</div>
        <div>
          <h4 className="text-2xl">
            What is your
            <span className="font-semibold"> career plan?</span>
          </h4>
        </div>
      </div>
      <form
        className="md:px-10"
        action=""
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitCareerPlan)}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-3">
          {career_options.map((option) => (
            <div
              key={option.value}
              className={` ${option.is_editable ? 'h-[170px]' : 'h-[90px] lg:h-[60px]'} `}
            >
              <label className="radio-container h-full p-4 block relative">
                <input
                  disabled={isDisabled}
                  checked={selectedCareerPlan === option.value}
                  onChange={handlePlanChange}
                  className="w-full h-full absolute top-0 left-0 opacity-0"
                  value={option.value}
                  type="radio"
                  name="cr"
                />
                <span className="checkmark font-semibold !block h-auto pt-4">
                  {option.label}
                  {option.is_editable && (
                    <textarea
                      disabled={isDisabled}
                      onChange={(event) =>
                        textAreaChange(event, option.value.includes(CareerPlanValue.OTHER))
                      }
                      placeholder="Explain"
                      value={option.value.includes(CareerPlanValue.OTHER) ? otherRemarks : ''}
                      rows={2}
                      className="bg-white border rounded-lg w-full resize-none mt-4 p-3 font-light"
                      name=""
                    ></textarea>
                  )}
                </span>
              </label>
            </div>
          ))}
        </div>
        {error.plan ? (
          <p className="text-red-500">{error.plan}</p>
        ) : error.other ? (
          <p className="text-red-500">{error.other}</p>
        ) : (
          ''
        )}

        <div className="flex items-center mt-9 mb-10">
          <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">7.1</div>
          <div>
            <h4 className="text-2xl">Where do you see yourself in 5 years?</h4>
          </div>
        </div>
        <textarea
          required
          disabled={isDisabled}
          onChange={(event) => handleFutureGoal(event)}
          placeholder="Explain"
          value={futureGoals}
          rows={2}
          className="bg-white border rounded-lg w-full resize-none mt-4 p-3 font-light"
          name=""
        ></textarea>
        {error.futurePlan && <p className="text-red-500">{error.futurePlan}</p>}

        <div className="flex items-center mt-9 mb-10">
          <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">08</div>
          <div>
            <h4 className="text-2xl">
              Do you know
              <span className="font-semibold"> what kind of work </span>
              you want to do?
            </h4>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-3">
          <div className="age-box h-[60px]">
            <label className="radio-container h-full p-4 block relative">
              <input
                checked={selectedCareerPlanDirection === CareerPlanDirection.YES}
                onChange={handleWorkKnowledgeChange}
                className="w-full h-full absolute top-0 left-0 opacity-0"
                value={CareerPlanDirection.YES}
                type="radio"
                name="wp"
              />
              <span className="checkmark">Yes</span>
            </label>
          </div>
          <div className="radio-container  h-[60px]">
            <label className="age-wrap h-full p-4 block relative">
              <input
                disabled={isDisabled}
                checked={selectedCareerPlanDirection === CareerPlanDirection.SOMEWHAT}
                onChange={handleWorkKnowledgeChange}
                className="w-full h-full absolute top-0 left-0 opacity-0"
                value={CareerPlanDirection.SOMEWHAT}
                type="radio"
                name="wp"
              />
              <span className="checkmark">Somewhat</span>
            </label>
          </div>
          <div className="radio-container  h-[60px]">
            <label className="age-wrap h-full p-4 block relative">
              <input
                disabled={isDisabled}
                checked={selectedCareerPlanDirection === CareerPlanDirection.NO}
                onChange={handleWorkKnowledgeChange}
                className="w-full h-full absolute top-0 left-0 opacity-0"
                value={CareerPlanDirection.NO}
                type="radio"
                name="wp"
              />
              <span className="checkmark">No</span>
            </label>
          </div>
        </div>
        {error.work_knowledge && <p className="text-red-500">{error.work_knowledge}</p>}
        <div className="flex justify-end lg:justify-between mt-8">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
