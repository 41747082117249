import React, { useEffect, useState } from 'react';

import blue from '../../../../assets/images/courses/perception-understanding/chapter6/blue.jpg';
import bodyLanguage from '../../../../assets/images/courses/perception-understanding/chapter6/bodyLanguage.png';
import cbDesk from '../../../../assets/images/courses/perception-understanding/chapter6/cbDesk.png';
import egDesk from '../../../../assets/images/courses/perception-understanding/chapter6/egDesk.png';
import environmentImg from '../../../../assets/images/courses/perception-understanding/chapter6/environmentImg.jpg';
import frDesk from '../../../../assets/images/courses/perception-understanding/chapter6/frDesk.png';
import green from '../../../../assets/images/courses/perception-understanding/chapter6/green.jpg';
import MicroExpressions from '../../../../assets/images/courses/perception-understanding/chapter6/MicroExpressions.jpg';
import introImg from '../../../../assets/images/courses/perception-understanding/chapter6/perciption_chap6_img1.jpg';
import recognizaionType from '../../../../assets/images/courses/perception-understanding/chapter6/perciption_chapt6_typeimg.png';
import red from '../../../../assets/images/courses/perception-understanding/chapter6/red.jpg';
import syDesk from '../../../../assets/images/courses/perception-understanding/chapter6/syDesk.png';
import yellow from '../../../../assets/images/courses/perception-understanding/chapter6/yellow.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import FlipCard from '../../FlipCard';
import { MediaBlock } from '../../MediaBlock';
import { ToggleCard } from '../../ToggleCard';
interface PerciptionProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

interface Option {
  id: string;
  option: string;
  answer: string;
}

interface RecognizeType {
  id: string;
  question: string;
  options: Option[];
}
export const PerceptionAndUnderstandingChapter6: React.FC<PerciptionProps> = ({
  chapter,
  onNextChapter,
}) => {
  const [openCardPosition, setOpenCardPosition] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<{ [key: string]: string }>({});
  const handleToggleCard = (position: string) => {
    setOpenCardPosition((prevPosition) => (prevPosition === position ? null : position));
  };
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const recognizeTypes: RecognizeType[] = [
    {
      id: '73036515-2ebd-479b-a0f0-a810ca4d5791',
      question: '1. Are they more...',
      options: [
        {
          id: '32b32143-4882-42cd-ae03-518d3ea138d2',
          option: 'quiet, reflective, considered',
          answer: 'Quiet',
        },
        {
          id: 'e2f2f714-4745-47ea-b3ee-ee659326e239',
          option: 'quiet, talkative and immediate',
          answer: 'Talkative',
        },
      ],
    },
    {
      id: '63fe3bff-5ad0-43ee-a3fa-df849ad63966',
      question: '2. Do they...',
      options: [
        {
          id: 'd5fa3918-e09c-4fd5-b678-f4c4d5e07ad6',
          option: '"Speak to think"',
          answer: 'Speak to think',
        },
        {
          id: '671f2f7e-51a4-4718-8f8f-2ae8f8b03d5d',
          option: '"Think to speak"',
          answer: 'Think to speak',
        },
      ],
    },
    {
      id: '20787538-9da9-4aa1-b6a6-c0f3206c0ec6',
      question: '3. Are they more...',
      options: [
        {
          id: '074596d4-2f29-4981-b495-ba616ee946fc',
          option: 'Formal structured, objective and task focused',
          answer: 'Formal/Task',
        },
        {
          id: '81e27b67-43e3-4ef2-a821-8381f99931bd',
          option: '"Relaxed, harmonious, emotive and relationship-focused"',
          answer: 'Informal/Relationship',
        },
      ],
    },
  ];

  const handleSelection = (optionId: string, dataId: string) => {
    const selectedQuestionId = recognizeTypes.find((question) =>
      question.options.some((opt) => opt.id === optionId),
    )?.id;
    if (selectedQuestionId) {
      setSelectedOption((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [dataId]: optionId,
      }));
    }
  };

  useEffect(() => {}, [selectedOption]);
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-xl font-light">
        <h2 className="font-semibold text-3xl">Recognizing Type</h2>
        <p className="text-sm italic">Part 6 of 10</p>
        <MediaBlock mediaImage={introImg}></MediaBlock>

        <p className="text-2xl leading-10 mb-8 mt-8">
          Body language is a silent orchestra. People constantly give non-verbal messages about
          their thoughts and feelings. These include body movements, facial expressions, vocal tone
          and volume, and other signals.
        </p>
        <div className="flex items-center">
          <img className="w-96 mr-9 rounded-3xl" src={MicroExpressions} alt="" />
          <div>
            <p className="font-bold mb-3">Micro-expressions</p>
            <p>
              Micro-expressions (brief displays of emotion on the face), hand gestures, and posture
              all register in the human brain almost immediately—even when a person is not
              consciously aware they have perceived anything. For this reason, body language can
              strongly color how an individual is perceived, and how they, in turn, interprets
              others’ motivation, mood, and openness.
            </p>
          </div>
        </div>
        <p className="font-bold mt-10 mb-2">
          How the four color energies show up in body language.
        </p>
        <p>What color energy clues do you observe in body language?</p>
        <img src={bodyLanguage} alt="" />
        <p className="my-8">Click each image below to flip and learn more.</p>
        <div className="my-10">
          <div className="flex justify-center gap-5 mb-7">
            <FlipCard>
              <div className="front-content text-center">
                <img src={blue} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>
                  <p className="text-blue-500 font-bold mb-5">Cool Blue</p>
                  <p>Quiet/reserved Likes personal space Less facial expression</p>
                </div>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content text-center">
                <img src={red} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>
                  <p className="text-red-500 font-bold mb-5">Fiery Red</p>
                  <p>Firm handshake Direct eye-contact Formal</p>
                </div>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content text-center">
                <img src={yellow} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>
                  <p className="text-yellow-500 font-bold mb-5">Sunshine Yellow </p>
                  <p>Relaxed Open and responsive Physical contact</p>
                </div>
              </div>
            </FlipCard>
          </div>
          <div className="flex justify-center gap-5">
            <FlipCard>
              <div className="front-content text-center">
                <img src={green} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>
                  <p className="text-green-500 font-bold mb-5">Earth Green </p>
                  <p>Warm smile Some eye-contact Gentle handshake</p>
                </div>
              </div>
            </FlipCard>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-6">
          Activity: Recognizing Types
        </h3>
        <p>
          In this activity, think of a person you work with or outside of work. For each question,
          click on the most appropriate answer for that person. Observe where they fall on the wheel
          as you click your responses.
        </p>
        {/* Que Card */}
        <div className="flex gap-4">
          <div className="mt-8 mb-8">
            {recognizeTypes.map((data, index) => (
              <div key={index}>
                <div>
                  <h5 className="text-blue-600 font-bold">{data.question}</h5>
                  <div>
                    {data?.options.map((opt) => (
                      <div className="ml-5">
                        {' '}
                        <div className="mt-2 mb-2">
                          <input
                            onChange={() => handleSelection(opt.id, data.id)}
                            className="w-4 h-4"
                            type="radio"
                            checked={selectedOption[data.id] === opt.id}
                          />
                          <label className="ml-4" key={opt.id}>
                            {opt.option}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="p-1">
            <div className="relative ">
              {Object.entries(selectedOption)?.map(([questionId, optionId]) => {
                const question = recognizeTypes.find((q) => q.id === questionId);
                if (!question) return null;

                const options = question.options.find((opt) => opt.id === optionId);
                if (!options) return null;

                if (options.id === recognizeTypes[0].options[0].id) {
                  return (
                    <div
                      key={`${questionId}-${optionId}`}
                      className="absolute font-bold left-8 top-1/2"
                    >
                      {recognizeTypes[0].options[0].answer}
                    </div>
                  );
                } else if (options.id === recognizeTypes[0].options[1].id) {
                  return (
                    <div
                      key={`${questionId}-${optionId}`}
                      className="absolute font-bold top-1/2 right-0"
                    >
                      {recognizeTypes[0].options[1].answer}
                    </div>
                  );
                }

                if (options.id === recognizeTypes[1].options[0].id) {
                  return (
                    <div className="absolute top-1/4 right-1">
                      {recognizeTypes[1].options[0].answer}
                    </div>
                  );
                } else if (options.id === recognizeTypes[1].options[1].id) {
                  return (
                    <div className="absolute bottom-1/4 left-1">
                      {recognizeTypes[1].options[1].answer}
                    </div>
                  );
                }
                if (options.id === recognizeTypes[2].options[0].id) {
                  return (
                    <div className="absolute font-bold top-12 left-1/3">
                      {recognizeTypes[2].options[0].answer}
                    </div>
                  );
                } else if (options.id === recognizeTypes[2].options[1].id) {
                  return (
                    <div className="absolute font-bold bottom-8 left-1/3 ">
                      {recognizeTypes[2].options[1].answer}
                    </div>
                  );
                }

                return null;
              })}

              <img className="p-24" src={recognizaionType} alt="" />
            </div>
          </div>
        </div>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-6">Color Energy Clues</h3>
        <p className="text-2xl">
          Along with body language, there are color energy clues given in one’s work environment.
        </p>
        <p className="font-bold mb-3 mt-6">Which is Your Desk?</p>
        <p>
          Review the work environment cues on each desk below and determine which color energy type
          is reflected on each. Click on the cards to flip for the answers.
        </p>
        <div className="my-10">
          <div className="flex justify-center gap-5 mb-7">
            <FlipCard>
              <div className="front-content text-center">
                <img src={cbDesk} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>Cool Blue</div>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content text-center">
                <img src={frDesk} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>Fiery Red</div>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content text-center">
                <img src={egDesk} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>Earth Green</div>
              </div>
            </FlipCard>
          </div>
          <div className="flex justify-center gap-5">
            <FlipCard>
              <div className="front-content text-center">
                <img src={syDesk} alt="" />
              </div>
              <div className="back-content text-center  w-full h-full flex items-center justify-center">
                <div>Sunshine Yellow</div>
              </div>
            </FlipCard>
          </div>
        </div>
        <p className="font-bold">Click each marker(+) to learn more about each environment.</p>
        <div className="">
          <div className="w-full mx-auto relative">
            <img className="w-full" src={environmentImg} alt="" />
            <div className="absolute top-[35%] left-[16%]">
              <ToggleCard
                position="top-left"
                isOpen={openCardPosition === 'top-left'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#388dbb] mb-8">COOL BLUE</p>
                    <p>
                      Those that lead with Cool Blue would most likely have a very orderly and
                      organized work environment.&nbsp;
                    </p>
                  </div>
                }
              />
            </div>
            <div className="absolute top-[35%] right-[16%]">
              <ToggleCard
                position="top-right"
                isOpen={openCardPosition === 'top-right'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#d3473c] mb-8">FIERY RED</p>
                    <p>
                      The environment of those that lead with Fiery Red would be simple, sparse,
                      with perhaps symbols of recognition in place.
                    </p>
                  </div>
                }
              />
            </div>
            <div className="absolute bottom-[5%] left-[16%]">
              <ToggleCard
                position="bottom-left"
                isOpen={openCardPosition === 'bottom-left'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#8ba40a] mb-8">EARTH GREEN</p>
                    <p>
                      Those that lead with Earth Green would have a very comfortable environment
                      with personal items and candid photos of friends and family.&nbsp;
                    </p>
                  </div>
                }
              />
            </div>
            <div className="absolute bottom-[5%] right-[16%]">
              <ToggleCard
                position="bottom-right"
                isOpen={openCardPosition === 'bottom-right'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#fbc600] mb-8">SUNSHINE YELLOW</p>
                    <p>
                      Those that lead with Sunshine Yellow energy probably have a chaotic looking
                      space even thought they would not describe it as such.
                    </p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <p className="text-2xl mt-7">
          Next we will look at stress and how it emerges in color energies.
        </p>
        <button onClick={handleNextChapterClick} className="w-full btn-primary uppercase mt-8">
          STRESS & BEHAVIOURAL STYLE
        </button>
      </div>
    </>
  );
};
