import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import character from '../../assets/images/intake/step4/char.svg';
import i_icon from '../../assets/images/intake/step4/i-icon.svg';
import { useIntakeFormContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import { dispatchUpdateIntakeFormLanguageProficiency } from '../../redux/slice/form-intake/form-intake.operations';
import {
  IntakeFormLanguageProficiencyDTO,
  LanguageProficiencyValue,
} from '../../redux/slice/form-intake/form-intake.types';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';

interface LanguageProficiencyProps {
  step: number;
  onBack: () => void;
}

export const LanguageProficiency: React.FC<LanguageProficiencyProps> = ({ step, onBack }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [fields, setFields] = useState([{ language: '', proficiency: '' }]);
  const { languageProficiency } = useIntakeFormContext();
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
  } = usePreEmploymentAssessmentContext();
  const [selectedEnglishProficiency, setSelectedEnglishProficiency] = useState(
    languageProficiency?.English ? `${languageProficiency.English}` : '',
  );
  const [selectedSpanishProficiency, setSelectedSpanishProficiency] = useState(
    languageProficiency?.Spanish ? `${languageProficiency.Spanish}` : '',
  );

  const [error, setError] = useState('');
  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);
  const handleAddField = () => {
    if (fields.filter((x) => !x.language.trim()).length) {
      setError('Please enter the language');
    } else if (fields.filter((x) => !!x.language.trim() && !x.proficiency).length) {
      setError('Please select the proficiency');
    } else if (fields.length < 10) {
      setFields([...fields, { language: '', proficiency: '' }]);
    }
  };

  const handleTextChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const values = [...fields];
    values[index].language = event.target.value;
    setFields(values);
  };
  const handleRadioChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const values = [...fields];
    values[index].proficiency = event.target.value;
    setFields(values);
  };

  const handleEnglishProficiencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEnglishProficiency(event.target.value);
    setError('');
  };

  const handleSpanishProficiencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSpanishProficiency(event.target.value);
    setError('');
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll to the top
  }, []);
  useEffect(() => {
    setSelectedEnglishProficiency(`${languageProficiency?.English ?? ''}`);
    setSelectedSpanishProficiency(`${languageProficiency?.Spanish ?? ''}`);

    if (languageProficiency) {
      const filteredLanguages = Object.fromEntries(
        Object.entries(languageProficiency).filter(([key, value]) => {
          // Filter out 'English' and 'Spanish', and also check if value is not undefined
          return key !== 'English' && key !== 'Spanish' && value !== undefined;
        }),
      );

      const otherLanguages = Object.entries(filteredLanguages).map(([language, proficiency]) => ({
        language,
        proficiency: proficiency?.toString() ?? '',
      }));

      setFields(otherLanguages);
    }
  }, [languageProficiency]);
  const { handleSubmit } = useForm<IntakeFormLanguageProficiencyDTO>();
  const submitLanguageProficiency: SubmitHandler<IntakeFormLanguageProficiencyDTO> = async () => {
    setIsLoading(true);
    try {
      const dto: IntakeFormLanguageProficiencyDTO = {
        languageProficiency: {
          English: parseInt(selectedEnglishProficiency) as LanguageProficiencyValue,
          Spanish: parseInt(selectedSpanishProficiency) as LanguageProficiencyValue,
        },
      };
      logger.debug('Submitted language proficiency.', dto);
      if (
        `${selectedEnglishProficiency ?? ''}` === '' ||
        `${selectedSpanishProficiency ?? ''}` === ''
      ) {
        setError('Please select an option in each language.');
      } else if (fields.filter((x) => !!x.language.trim() && !x.proficiency).length) {
        setError('Please select the proficiency');
      } else {
        if (isDisabled) {
          navigate(`/pre-employment/intake/step${step + 1}`);
        } else {
          fields
            .filter((option) => option.language.trim())
            .map(
              (option) =>
                (dto.languageProficiency[option.language] = parseInt(
                  option.proficiency,
                ) as LanguageProficiencyValue),
            );

          await dispatch(dispatchUpdateIntakeFormLanguageProficiency(dto));
          navigate(`/pre-employment/intake/step${step + 1}`);
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: languageProficiency?.English ? progress : totalProgress,
                activeStepUrl: `/pre-employment/intake/step${step + 1}`,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/intake/step${step + 1}`,
              }),
            );
          }
        }
      }
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  const backButtonClick = () => {
    onBack();
  };
  const proficiency_options = [
    {
      label: '0',
      value: '0',
    },
    {
      label: '1',
      value: '1',
    },
    {
      label: '2',
      value: '2',
    },
    {
      label: '3',
      value: '3',
    },
    {
      label: '4',
      value: '4',
    },
    {
      label: '5',
      value: '5',
    },
  ];
  return (
    <div>
      <div>
        <p className="text-lg mt-7 md:px-10">
          At Vero Learning, we understand the importance of effective communication in today's
          diverse and interconnected world. Language proficiency unlocks your possibilities. This
          section of our pre- employment screening is designed to help us assess your language
          skills accurately.
        </p>
      </div>
      <div className="flex mt-9 mb-10 md:px-10">
        <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">05</div>
        <div>
          <h4 className="text-2xl">
            Rate Your Level of
            <span className="font-semibold"> Language Proficiency</span>
          </h4>
        </div>
      </div>
      <div className="character absolute top-96 left-[-250px] z-[-1]">
        <img className="" src={character} alt="" />
      </div>
      <form
        className="md:px-10"
        action=""
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitLanguageProficiency)}
      >
        <div className="lg:h-[60px] border rounded-lg flex items-center gap-8 p-6 mb-3">
          <div className="lang-txt  flex lg:basis-full font-semibold">English</div>
          <div className="rating-btns flex justify-end flex-wrap basis-full gap-4">
            {proficiency_options.map((option) => (
              <div key={option.value} className="h-[35px] w-[44px]">
                <label className="radio-container h-full block relative">
                  <input
                    disabled={isDisabled}
                    checked={selectedEnglishProficiency === option.value}
                    onChange={handleEnglishProficiencyChange}
                    value={option.value}
                    className="w-full h-full absolute top-0 left-0 opacity-0"
                    type="radio"
                    name="eng"
                  />
                  <span className="checkmark">{option.label}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="lg:h-[60px] border rounded-lg flex items-center gap-8 p-6 mb-3">
          <div className="lang-txt  flex lg:basis-full font-semibold">Spanish</div>
          <div className="rating-btns flex justify-end flex-wrap basis-full gap-4">
            {proficiency_options.map((option) => (
              <div key={option.value} className="h-[35px] w-[44px]">
                <label className="radio-container h-full block relative">
                  <input
                    disabled={isDisabled}
                    checked={selectedSpanishProficiency === option.value}
                    onChange={handleSpanishProficiencyChange}
                    value={option.value}
                    className="w-full h-full absolute top-0 left-0 opacity-0"
                    type="radio"
                    name="spn"
                  />
                  <span className="checkmark">{option.label}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div>
          {fields.map((field, index) => (
            <div
              key={index}
              className="lg:h-[60px] border rounded-lg flex items-center gap-8 p-6 mb-3"
            >
              <input
                disabled={isDisabled}
                className="lang-txt flex lg:basis-full font-semibold max-w-[150px]"
                type="text"
                maxLength={20}
                value={field.language}
                onChange={(event) => handleTextChange(index, event)}
                placeholder="Enter (If Other)"
              />
              <div className="rating-btns flex justify-end flex-wrap basis-full gap-4">
                {proficiency_options.map((option) => (
                  <div key={option.value} className="h-[35px] w-[44px]">
                    <label className="radio-container h-full block relative">
                      <input
                        disabled={isDisabled}
                        onChange={(event) => handleRadioChange(index, event)}
                        value={option.value}
                        checked={field.proficiency === option.value}
                        name={`radio-${index}`}
                        className="w-full h-full absolute top-0 left-0 opacity-0"
                        type="radio"
                      />
                      <span className="checkmark">{option.label}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {error && <p className="text-red-500">{error}</p>}
          {!isDisabled ? (
            <Link to="" onClick={handleAddField} className="text-purple-500 underline">
              Add New
            </Link>
          ) : null}
        </div>

        <div className="rating-scale mt-8 mb-4">
          <p className="text-lg font-bold mb-4">Rating Scale</p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-3">
            <div className="bg-gray-100 flex items-center justify-between rounded-md px-5 py-3">
              <div className="flex">
                <div className="font-bold mr-3 text-sm">0</div>
                <p className="text-sm">No Proficiency</p>
              </div>
              <div>
                <img className="" src={i_icon} alt="" />
              </div>
            </div>
            <div className="bg-gray-100 flex items-center justify-between rounded-md px-5 py-3">
              <div className="flex">
                <div className="font-bold mr-3 text-sm">1</div>
                <p className="text-sm">Elementary Proficiency</p>
              </div>
              <div>
                <img className="" src={i_icon} alt="" />
              </div>
            </div>
            <div className="bg-gray-100 flex items-center justify-between rounded-md px-5 py-3">
              <div className="flex">
                <div className="font-bold mr-3 text-sm">2</div>
                <p className="text-sm">Limited Working Proficiency</p>
              </div>
              <div>
                <img className="" src={i_icon} alt="" />
              </div>
            </div>
            <div className="bg-gray-100 flex items-center justify-between rounded-md px-5 py-3">
              <div className="flex">
                <div className="font-bold mr-3 text-sm">3</div>
                <p className="text-sm">Professional Working Proficiency</p>
              </div>
              <div>
                <img className="" src={i_icon} alt="" />
              </div>
            </div>
            <div className="bg-gray-100 flex items-center justify-between rounded-md px-5 py-3">
              <div className="flex">
                <div className="font-bold mr-3 text-sm">4</div>
                <p className="text-sm">Full Professional Proficiency</p>
              </div>
              <div>
                <img className="" src={i_icon} alt="" />
              </div>
            </div>
            <div className="bg-gray-100 flex items-center justify-between rounded-md px-5 py-3">
              <div className="flex">
                <div className="font-bold mr-3 text-sm">5</div>
                <p className="text-sm">Native / Bilingual Proficiency</p>
              </div>
              <div>
                <img className="" src={i_icon} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end lg:justify-between mt-8 ml-5">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
