import chat_background from '../../../../assets/images/courses/jumpstart-communicator/chapter4/background.jpeg';
import introImg from '../../../../assets/images/courses/jumpstart-communicator/chapter4/Intro.jpeg';
import chat_character from '../../../../assets/images/courses/jumpstart-communicator/chapter4/intro.png';
import listening from '../../../../assets/images/courses/jumpstart-communicator/chapter4/listening.jpeg';
import nonverbal from '../../../../assets/images/courses/jumpstart-communicator/chapter4/nonverbal.jpeg';
import verbal from '../../../../assets/images/courses/jumpstart-communicator/chapter4/verbal.jpeg';
import writing from '../../../../assets/images/courses/jumpstart-communicator/chapter4/writing.jpeg';
import { CourseChapter } from '../../../../courses/courses.types';
import { course_chat_questions as CourseChatQuestions } from '../../../../courses/jumpstart-communicator/jumpstart-communicator.quiz';
import { ActionableBlock } from '../../ActionableBlock';
import { InfoBlock } from '../../InfoBlock';
import { TabsBlock } from '../../TabsBlock';

interface JumpStartChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const JumpStartChapter4: React.FC<JumpStartChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const courseChatQuestions = CourseChatQuestions.filter((obj) => obj.chapter_id === chapter.id);
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const communicatorsTabData = [
    {
      title: 'VERBAL',
      mediaImage: verbal,
      htmlContent: `Great verbal communicators speak clearly and share straightforward messages. They adjust
        their tone and inflection to match their purpose, and they give varying amounts of
        information depending on their audience.
        <br />
        <br />
        Whether speaking to a group or one person, great verbal communicators enunciate their words,
        stay on target, and are direct about their needs and intentions. Instead of saying, “I
        guess,” a skilled verbal communicator will deliver a clear “Yes” or “No.” Instead of saying,
        “When you get the chance,” these speakers will provide a specific time frame or deadline.`,
    },
    {
      title: 'LISTENING',
      mediaImage: listening,
      htmlContent: `Skilled communicators know how to listen. They remove distractions, stop themselves from jumping to conclusions, and avoid interrupting others. Great listeners pay close attention, help their speakers feel heard, and ask questions to improve their comprehension.
      <br />
        <br />
        If a message is vague, skilled listeners don’t try to figure out the meaning for themselves. Instead, they provide direct feedback, asking the sender for more information with questions such as, <span className="font-bold">“Can you please provide more details or an example, so I can understand what you’re saying?”</span>
      `,
    },
    {
      title: 'WRITING',
      mediaImage: writing,
      htmlContent: `Strong writing skills go hand-in-hand with excellent communication. Skilled communicators know how to write clearly, confidently, and efficiently—writing messages that are grammatically accurate and easy to understand.
      <br />
        <br />
        In every medium, skilled writers craft clear messages that people can easily understand. Their writing is well-organized, concise, and to the point.
      `,
    },
    {
      title: 'NONVERBAL',
      mediaImage: nonverbal,
      htmlContent: `Skillful communicators are actively aware of how nonverbal cues can either reinforce or discredit their messages. Whether speaking or listening, they use nonverbal cues to nod in agreement, make eye contact, and use signals such as hand gestures to express themselves and reinforce their messages.
       <br />
        <br />Skilled communicators also pay close attention to the nonverbal signals of others—observing facial expressions, body movements, posture, and nonverbal actions. As a result, skilled communicators avoid misunderstandings, respond appropriately, and forge positive interactions with others. `,
    },
  ];
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-base">
        <h2 className="font-semibold text-3xl">Why Communication Skills Matter</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <div className="relative mt-8 rounded-3xl overflow-hidden">
          <img className="" src={introImg} alt="" />
          <div className="img-content absolute top-0 left-0 w-full h-full bg-[#000000b3] text-white pl-11 flex items-center"></div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
          “Must Have Great Communication Skills”
        </h3>
        <p className="text-base mb-8 font-medium">
          We see this phrase: “Must Have Great Communication Skills” almost everywhere. It’s
          included in virtually every job listing and considered by managers to be a key attribute
          of a great employee. But what is it about excellent communication skills that make them so
          essential?
          <br />
          <br />
          The answer is simple: Great communicators are skilled at speaking, listening, writing, and
          using nonverbal cues, which helps them excel in five key areas:
        </p>
        <ul className="list-disc pl-5 mb-6">
          <li className="mb-6 pl-2">Team collaboration</li>
          <li className="mb-6 pl-2">Team cohesion </li>
          <li className="mb-6 pl-2">Customer trust </li>
          <li className="mb-6 pl-2">Employee management </li>
          <li className="mb-6 pl-2">Conflict resolution</li>
        </ul>
        <p>
          In this lesson, you’ll learn why great communication skills matter to your professional
          success. We’ll start by examining the characteristics that define a great communicator.
          Then, we’ll explore how these qualities can help you excel in the workplace.
        </p>
        <div className="video-wrap mt-8 mb-12">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/-wzOUKIBklw6zeacpgA1J598HusNp3yw/CIZRiXpD/assets/rbc/KhtfozESHsNF6D6t_COMM-FND-02.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
          What Makes a Great Communicator?
        </h3>
        <p className="text-base mb-8 font-medium">
          When asked to name one trait of “great communicators,” people tend to imagine skilled
          speakers. But communication is about much more than just talking; it’s also about
          listening, writing, and nonverbal cues. Click the tabs below to learn more about the four
          defining characteristics of excellent communication.
        </p>
        <div className="mb-11">
          <TabsBlock tabsData={communicatorsTabData} />
        </div>
        <div className="text-xl font-light">
          <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
            Five Professional Benefits of Great Communication
          </h3>
          <p>
            When you’re skilled at sending and receiving verbal, nonverbal, and written messages,
            you’re likely to improve your workplace performance. Here are five great reasons to
            strive for effective communication:
          </p>
          <ul className="list-decimal pl-7 mt-8 mb-10">
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Improved collaboration.</span> When you speak clearly,
              listen attentively, and use the appropriate nonverbal cues, conversations become
              easier, improving your team’s ability to brainstorm, develop new ideas, and
              collaborate with one another.
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Improved team cohesion.</span>A team that communicates
              well is more likely to agree on overall goals and individual responsibilities. They
              can reference excellent written documentation to avoid misunderstandings.
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">High levels of customer trust.</span>
              Regardless of how you choose to communicate, it’s easier to earn the trust of
              customers if you openly share your services and vision. You foster trust by listening
              to customer needs and concerns, and offering feedback that demonstrates your interest
              in their success.
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Effective performance management.</span>
              By actively listening and picking up on your co-worker's nonverbal cues, you can more
              easily understand their needs, concerns, and ideas. When you communicate well, your
              co-workers receive clear direction and feel heard and valued. That, in turn, helps to
              improve your and their performance and build trust.
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Conflict resolution.</span>
              Conflicts happen, but they don’t need to end negatively. Show empathy and remove
              misunderstandings by sharing carefully constructed messages paired with appropriate
              verbal and nonverbal signals. Listening attentively, responding to nonverbal cues, and
              offering thoughtful feedback can help resolve misunderstandings.
            </li>
          </ul>
        </div>
        <InfoBlock
          title="Build relationships from conflict."
          text="Conflicts can be nerve-wracking, but they’re also a chance for growth! The next time you
            experience conflict, take a step back and seek to understand the other person’s
            perspective. Once you fully understand where they’re coming from, let them know you do.
            They’ll feel valued, increasing the chances you’ll achieve a resolution."
        />
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
          Can Communication Skills Help?
        </h3>
        <ActionableBlock
          backgroundImage={chat_background}
          courseChatQuestions={courseChatQuestions}
          characterImage={chat_character}
          feedbackTitle="Excellent Thinking!"
          feedbackText="You correctly answered both questions. The next time you have a conflict, try to defuse the situation by clarifying your message and listening empathetically to the other person’s point of view."
          buttonName="Continue"
        />
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">Summary</h3>
        <p className="mb-8">
          Great communicators tend to excel in the workplace because they’re excellent verbal
          speakers, listeners, nonverbal communicators, and writers. They know how to express
          themselves clearly, and they’re adept at listening to others. <br />
          <br /> By cultivating these characteristics in the workplace, you also can improve team
          collaboration and cohesion, create higher levels of trust with your customers, more
          effectively manage your employees, and resolve conflicts with ease.
        </p>

        <p className="text-2xl leading-10 text-center font-light mb-10">
          Excellent communication skills will benefit you in your professional career but also
          throughout your personal life. In the next lesson, you’ll take a deeper look at
          communication mediums and how to choose the best one for any scenario.
        </p>
        <button onClick={handleNextChapterClick} className="btn-primary w-full">
          CHOOSING THE RIGHT COMMUNICATION MEDIUM
        </button>
      </div>
    </>
  );
};
