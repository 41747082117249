import { useSelector } from 'react-redux';

import { CustomUser } from '../redux/slice/auth/CustomUser';
import { RootState } from '../redux/store';

export const useAuthContext = () => {
  const user: CustomUser | null = useSelector((state: RootState) => state.auth?.user);
  return user;
};

export const useAuthInitializedContext = () => {
  const initialized: boolean = useSelector((state: RootState): boolean => state.auth.initialized);
  return initialized;
};

export const useTempAuthContect = () => {
  const user: CustomUser | null = useSelector((state: RootState) => state.tempAuth.user);
  return user;
};

export const useTempAuthIntializedContext = () => {
  const user: boolean = useSelector((state: RootState) => state.tempAuth.initialized);
  return user;
};
