import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ArnoldBennett from '../../../../assets/images/courses/adapting-to-change/chapter1/ArnoldBennett.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseSortingQuestion,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { course_sorting_matching_questions as courseSortingMatchingQuestions } from '../../../../courses/understanding-self-and-others/adapting-to-change/adapting-to-change.quiz';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { dispatchUpdateUserCourseQuestionResponse } from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { MediaBlock } from '../../MediaBlock';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';
interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const AdaptingToChange1: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const [isPageDirty, setIsPageDirty] = useState(true);
  const dispatch = useAppDispatch();
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const chapterSortingQuestions = courseSortingMatchingQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingMatchingQuestions[0].course_id]?.chapters[
        chapter.id
      ]?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseSortingMatchingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingRetake = async (questionId: string) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingMatchingQuestions[0].course_id]?.chapters[
        chapter.id
      ]?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseSortingMatchingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  useEffect(() => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingMatchingQuestions[0].course_id]?.chapters[
        chapter.id
      ]?.sortingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userSortingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Why Do We Struggle With Change?</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">We Need to Make a Change</h3>
        <div>
          <p>
            It’s a typical Monday morning. You enter the office, say hello to your coworkers, grab
            some coffee from the kitchen, and power up the computer—ready to start today’s tasks.
            But as you open the latest email from your boss, you spot the unexpected subject line:{' '}
            <em>
              “We’re making some changes around here.<em>”</em>
            </em>
          </p>
          <br />
          <p>
            What’s your reaction to that email? Are you nervous, excited, or angry? Fearful,
            hopeful, or confused? A mix of several emotions? If you’re feeling conflicted, you’re in
            the majority. For many, workplace change is an emotional process. It brings about
            questions, such as: <em>“What happens next?”&nbsp;</em>and{' '}
            <em>“What does this mean for me?”</em> And that uncertainty can make coping difficult.
          </p>
          <br />
          <p>
            Still, regardless of your response, if you want to succeed in the workplace, you must
            know how to cope with change. And, in this course, you’ll learn how to do that. First,
            you’ll learn why change is often unsettling and what reaction stages we tend to
            experience. Then, you’ll find out how to accept change by following a few expert
            strategies.
          </p>
        </div>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/xijk4FWbIUjQFJHdxeCTrOBZw-ze-04_/6Rqg1iiZ/assets/rbc/9b8JSmhJhd__-QSq_CM-OC-04.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">Why Is Change Unsettling?</h3>
        <p>
          Workplace change follows the typical motto: “Out with the old, in with the new.” And, for
          many, that new is unsettling and difficult to navigate. Most often, change is challenging
          because it leads to:
        </p>
        <ul className="list-decimal pl-7 mt-7">
          <li className="mb-7 marker:font-bold pl-5">
            <span className="font-bold">Future uncertainty. </span>All change—whether positive or
            negative—comes with uncertainty. It asks us to leave behind what we know, acknowledge
            the unpredictable, and move into stressful, uncharted territory.
          </li>
          <li className="mb-7 marker:font-bold pl-5">
            <span className="font-bold">Threatened competency. </span>The longer things stay the
            same, the more time we have to perfect our skills, knowledge, and understanding.
            Introduce change, however, and we’re beginners all over again, which can threaten our
            sense of personal competency.
          </li>
          <li className="mb-7 marker:font-bold pl-5">
            <span className="font-bold">Disrupted routines. </span>Change asks that we replace our
            everyday habits and routines with unfamiliar approaches. As a result, we can lose our
            sense of control and feel powerless and vulnerable on that new shaky ground.
          </li>
          <li className="mb-7 marker:font-bold pl-5">
            <span className="font-bold">Fear of failure. </span>Change requires a learning curve.
            That might mean learning a new policy, procedure, or system, using new software or
            equipment, or working with a new manager or coworker. Regardless of what that learning
            curve requires, it’s normal to feel anxious and fearful that we might fall short.
          </li>
        </ul>
        <div>
          <h3 className="font-semibold text-3xl mt-7 mb-7">
            Workplace Change Challenge: Sorting the Challenges
          </h3>
          <p>
            Connect the workplace change challenge statements with their respective category
            (reasons why change can be unsettling) by dragging and dropping each statement into
            their appropriate pile.
          </p>
          <DndProvider backend={HTML5Backend}>
            <CourseSortingQuestionBlock
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onRetake={handleSortingRetake}
              question={chapterSortingQuestions[0] as CourseSortingQuestion}
              submitedCorrectAnswerCount={
                userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[
                  chapter.id
                ]?.sortingQuestions
                  ?.filter((x) =>
                    Object.keys(x).filter((key) => key === chapterSortingQuestions[0].id),
                  )
                  .map(
                    (x) =>
                      x[chapterSortingQuestions[0].id].filter(
                        (x) => x.answer?.id === x.correct_answer?.id,
                      ).length,
                  )[0] ?? null
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              submitSortingQuestion={handleSortingExcerciseCompleted}
            />
          </DndProvider>
        </div>
        <MediaBlock mediaImage={ArnoldBennett}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            Any change, even a change for the better, is always accompanied by discomforts.
            <br />
            <span className="text-base">Arnold Bennett</span>
          </p>
        </MediaBlock>
        <p className="text-3xl my-20 leading-10 font-light text-center">
          In the next lesson, learn about five common emotional reactions to change.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full uppercase"
        >
          Common Reactions to Change
        </button>
      </div>
    </>
  );
};
