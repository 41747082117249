import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import blue from '../../../../assets/images/courses/perception-understanding/chapter5/blue.png';
import color_energies from '../../../../assets/images/courses/perception-understanding/chapter5/color_energies.jpg';
import colorEn from '../../../../assets/images/courses/perception-understanding/chapter5/colorEn.jpg';
import DisrespectfulLanguage from '../../../../assets/images/courses/perception-understanding/chapter5/DisrespectfulLanguage.jpg';
import goodBad from '../../../../assets/images/courses/perception-understanding/chapter5/goodBad.png';
import green from '../../../../assets/images/courses/perception-understanding/chapter5/green.png';
import introImg from '../../../../assets/images/courses/perception-understanding/chapter5/introImg.jpg';
import motivatingFactors from '../../../../assets/images/courses/perception-understanding/chapter5/motivatingFactors.png';
import red from '../../../../assets/images/courses/perception-understanding/chapter5/red.png';
import RespectfulLanguage from '../../../../assets/images/courses/perception-understanding/chapter5/RespectfulLanguage.jpg';
import yellow from '../../../../assets/images/courses/perception-understanding/chapter5/yellow.png';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  CourseSortingQuestion,
  QuestionOption,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import {
  course_questions,
  course_sorting_matching_questions,
} from '../../../../courses/perception-understanding/perciption-understanding-questions';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  UserChapterQuestion,
  dispatchUpdateUserCourseQuestionResponse,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { CustomAudioTabsBlock, CustomTabPanel } from '../../CustomAudioTabs';
import { MediaBlock } from '../../MediaBlock';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';
import { TabsBlock } from '../../TabsBlock';
import { ToggleCard } from '../../ToggleCard';
interface PerciptionProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};

type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const PerceptionAndUnderstandingChapter5: React.FC<PerciptionProps> = ({
  chapter,
  onNextChapter,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);
  const [energyTabValue, setEnergyTabValue] = useState<number | null>(null);

  const [openCardPosition, setOpenCardPosition] = useState<string | null>(null);
  const [openFearCardPosition, setOpenFearCardPosition] = useState<string | null>(null);
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});

  const chapterQuestions = course_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );

  const chapterSortingQuestions = course_sorting_matching_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };

  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              questions: [...userAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingRetake = async (questionId: string) => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              questions: [...userAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };

  const handleToggleCard = (position: string) => {
    setOpenCardPosition((prevPosition) => (prevPosition === position ? null : position));
    setOpenFearCardPosition(null);
  };
  const handleToggleFearCard = (position: string) => {
    setOpenFearCardPosition((prevPosition) => (prevPosition === position ? null : position));
    setOpenCardPosition(null);
  };

  const languageTabData = [
    {
      title: 'Disrespectful language',
      mediaImage: DisrespectfulLanguage,
      htmlContent: `<p><strong>Disrespectful language</strong> uses stereotypes, creates negative images and limits potential</p>`,
    },
    {
      title: 'Smile and be friendly',
      mediaImage: RespectfulLanguage,
      htmlContent: `<p><strong>Respectful language</strong> acknowledges all preferences &amp; encourages development of potential</p>`,
    },
  ];
  const tabsData = [
    {
      title: 'Red',
      textColor: '',
      bgColor: 'bg-fiery-red',
    },
    {
      title: 'Yellow',
      textColor: '',
      bgColor: 'bg-sunshine-yellow',
    },
    {
      title: 'Green',
      textColor: '',
      bgColor: 'bg-earth-green',
    },
    {
      title: 'Blue',
      textColor: '',
      bgColor: 'bg-cool-blue',
    },
  ];
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  useEffect(() => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions;
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userSortingAnswerResponse?.length &&
      userAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext, isPageDirty]);
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-xl font-light">
        <h2 className="font-semibold text-3xl">Color Energies & Preferences</h2>
        <p className="text-sm italic">Part 5 of 10</p>
        <MediaBlock mediaImage={introImg}></MediaBlock>

        <p className="text-2xl leading-10 mb-8 mt-8">
          Each of us have all the energies to a different degree. It’s our unique make-up of them
          that create our behavioral style.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Click the tabs to explore each color energy in more detail.
        </h3>
        <CustomAudioTabsBlock tabsData={tabsData} onTabChange={(value) => setEnergyTabValue(value)}>
          {!_.isNil(energyTabValue) && (
            <>
              <CustomTabPanel index={0} value={energyTabValue}>
                <div className="flex">
                  <div className="w-1/2 border-r border-dashed border-black pr-10">
                    <h3 className="text-lg border-b-2 border-red-500 mb-5 pb-2">
                      Individuals with a preference for Fiery Red energy
                    </h3>
                    <ul className="list-disc pl-5 mb-10">
                      <li className="mb-2 pl-4">
                        Are active and move ina positive and firm direction
                      </li>
                      <li className="mb-2 pl-4">Seek an outcome that is specific and tangible</li>
                      <li className="mb-2 pl-4">
                        Are single-minded and determined in their focus on results
                      </li>
                      <li className="mb-2 pl-4">
                        Have a strong determination that influences those they interact with
                      </li>
                      <li className="mb-2 pl-4">
                        Approach others in a direct and straightforward manner
                      </li>
                    </ul>
                    <p className="border border-black p-5 text-lg">
                      <span className="font-bold">MOTTO:</span> BE BRIEF, BE BRIGHT, BE GONE
                    </p>
                  </div>
                  <div className="w-1/2 pl-10">
                    <h3 className="text-lg border-b-2 border-red-500 mb-5 pb-2">Fiery Red Style</h3>
                    <p className="mb-7">Which Terms Speak to You?</p>
                    <div className="mb-5">
                      <p className="bg-red-500 text-white mb-1 inline-block">
                        GOOD DAY DESCRIPTORS:
                      </p>
                      <p>Competitive, Demanding, Determined, Strong-willed, Purposeful</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-red-500 text-white mb-1 inline-block">APPROACH:</p>
                      <p>Fast Paced and Decisive, Direct, Assertive</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-red-500 text-white mb-1 inline-block">GENERAL:</p>
                      <p>Busy, Formal, Efficient, Structured, Bold</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-red-500 text-white mb-1 inline-block">LIKES:</p>
                      <p>Productivity, Objectives, Control, Fast</p>
                    </div>
                    <div className="flex justify-end">
                      <div className="w-32 shadow-md bh-white">
                        <img src={red} alt="red" />
                      </div>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel index={1} value={energyTabValue}>
                <div className="flex">
                  <div className="w-1/2 border-r border-dashed border-black pr-10">
                    <h3 className="text-lg border-b-2 border-yellow-500 mb-5 pb-2">
                      Individuals with a preference for Sunshine Yellow energy
                    </h3>
                    <ul className="list-disc pl-5 mb-10">
                      <li className="mb-2 pl-4">Have a desire to be involved</li>
                      <li className="mb-2 pl-4">Radiate enthusiasm and encourage participation</li>
                      <li className="mb-2 pl-4">Enjoy and seek the company of others</li>
                      <li className="mb-2 pl-4">
                        Approach others in a persuasive, engaging and inviting manner
                      </li>
                      <li className="mb-2 pl-4">
                        Like to be noticed and appreciated for their contributions
                      </li>
                    </ul>
                    <p className="border border-black p-5 text-lg">
                      <span className="font-bold">MOTTO:</span> INVOLVE ME
                    </p>
                  </div>
                  <div className="w-1/2 pl-10">
                    <h3 className="text-lg border-b-2 border-yellow-500 mb-5 pb-2">
                      Sunshine Yellow Style
                    </h3>
                    <p className="mb-7">Which Terms Speak to You?</p>
                    <div className="mb-5">
                      <p className="bg-yellow-500 text-white mb-1 inline-block">
                        GOOD DAY DESCRIPTORS:
                      </p>
                      <p>Sociable, Dynamic, Demonstrative, Enthusiastic, Persuasive</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-yellow-500 text-white mb-1 inline-block">APPROACH:</p>
                      <p>Fast-paced, Spontaneous, Uplifting, Spirited</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-yellow-500 text-white mb-1 inline-block">GENERAL:</p>
                      <p>Personal, Friendly, Outgoing, Creative</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-yellow-500 text-white mb-1 inline-block">LIKES:</p>
                      <p>Variety, Relationships, People, Flexibility</p>
                    </div>
                    <div className="flex justify-end">
                      <div className="w-32 shadow-md bh-white">
                        <img src={yellow} alt="red" />
                      </div>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel index={2} value={energyTabValue}>
                <div className="flex">
                  <div className="w-1/2 border-r border-dashed border-black pr-10">
                    <h3 className="text-lg border-b-2 border-green-500 mb-5 pb-2">
                      Individuals with a preference for Earth Green energy
                    </h3>
                    <ul className="list-disc pl-5 mb-10">
                      <li className="mb-2 pl-4">Seek harmony and depth in relationships</li>
                      <li className="mb-2 pl-4">
                        View the world through what they value and what is important to them
                      </li>
                      <li className="mb-2 pl-4">
                        Ensure all individual perspectives are heard and considered in making
                        choices or decisions
                      </li>
                      <li className="mb-2 pl-4">
                        Prefer democratic approaches that respect the individual
                      </li>
                      <li className="mb-2 pl-4">
                        Defend what they value with quiet determination and persistence
                      </li>
                    </ul>
                    <p className="border border-black p-5 text-lg">
                      <span className="font-bold">MOTTO:</span> SHOW ME YOU CARE
                    </p>
                  </div>
                  <div className="w-1/2 pl-10">
                    <h3 className="text-lg border-b-2 border-green-500 mb-5 pb-2">
                      Earth Green Style
                    </h3>
                    <p className="mb-7">Which Terms Speak to You?</p>
                    <div className="mb-5">
                      <p className="bg-green-500 text-white mb-1 inline-block">
                        GOOD DAY DESCRIPTORS:
                      </p>
                      <p>Caring, Encouraging, Sharing, Patient</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-green-500 text-white mb-1 inline-block">APPROACH:</p>
                      <p>Slow-paced, Open, Casual, Conforming</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-green-500 text-white mb-1 inline-block">GENERAL:</p>
                      <p>Informal, Friendly, Calming, Personal</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-green-500 text-white mb-1 inline-block">LIKES:</p>
                      <p>Harmony, Close Relationships, Loyalty</p>
                    </div>
                    <div className="flex justify-end">
                      <div className="w-32 shadow-md bh-white">
                        <img src={green} alt="red" />
                      </div>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel index={3} value={energyTabValue}>
                <div className="flex">
                  <div className="w-1/2 border-r border-dashed border-black pr-10">
                    <h3 className="text-lg border-b-2 border-blue-500 mb-5 pb-2">
                      Individuals with a preference for Cool Blue energy
                    </h3>
                    <ul className="list-disc pl-5 mb-10">
                      <li className="mb-2 pl-4">Maintain a detached and objective standpoint</li>
                      <li className="mb-2 pl-4">Value independence and intellect</li>
                      <li className="mb-2 pl-4">
                        Think things through before committing to action
                      </li>
                      <li className="mb-2 pl-4">
                        Like information to be accurate and complete before proceeding
                      </li>
                      <li className="mb-2 pl-4">
                        Desire to know and understand the world around them
                      </li>
                    </ul>
                    <p className="border border-black p-5 text-lg">
                      <span className="font-bold">MOTTO:</span> GIVE ME THE DETAILS
                    </p>
                  </div>
                  <div className="w-1/2 pl-10">
                    <h3 className="text-lg border-b-2 border-blue-500 mb-5 pb-2">
                      Cool Blue Style
                    </h3>
                    <p className="mb-7">Which Terms Speak to You?</p>
                    <div className="mb-5">
                      <p className="bg-blue-500 text-white mb-1 inline-block">
                        GOOD DAY DESCRIPTORS:
                      </p>
                      <p>Cautious, Precise, Deliberate, Questioning, Formal</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-blue-500 text-white mb-1 inline-block">APPROACH:</p>
                      <p>Slower Paced, Systematic, Conservative</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-blue-500 text-white mb-1 inline-block">GENERAL:</p>
                      <p>Structured, Organized, Functional, Precise</p>
                    </div>
                    <div className="mb-5">
                      <p className="bg-blue-500 text-white mb-1 inline-block">LIKES:</p>
                      <p>Accuracy, Correctness, Thoroughness, Analysis</p>
                    </div>
                    <div className="flex justify-end">
                      <div className="w-32 shadow-md bh-white">
                        <img src={blue} alt="red" />
                      </div>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
            </>
          )}
        </CustomAudioTabsBlock>
        {_.isNil(energyTabValue) && (
          <MediaBlock mediaImage={color_energies} cssClass="!mt-3"></MediaBlock>
        )}
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Good Day / Bad Day Behaviors
        </h3>
        <p>
          Our color energies can appear as “good day” behaviors or can manifest in “bad day”
          behaviors.
        </p>
        <div>
          <img src={goodBad} alt="" />
        </div>
        {/* Que card */}
        <CourseQuestionCard
          submittedAnswer={userCourseQuestionResponseContext[
            chapterQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterQuestions[0]}
        />
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">Motivators & Fears</h3>
        <p>
          Bad day behaviors can be tied to our “motivators” and our “fears.” Keep this in mind as
          you build muscle around using good day behaviors.
        </p>
        <br />
        <p className="font-bold">
          Click each marker(+) to learn more about the motivating factors and fears for each color
          energy.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5 text-center">
          Motivating Factors
        </h3>
        <div className="">
          <div className="w-[635px] mx-auto relative">
            <img className="w-full" src={motivatingFactors} alt="" />
            <div className="absolute top-[10%] left-0">
              <ToggleCard
                position="top-left"
                isOpen={openCardPosition === 'top-left'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#388dbb] mb-8">COOL BLUE</p>
                    <p>Task Oriented (Problem Solving)</p>
                  </div>
                }
              />
            </div>
            <div className="absolute top-[10%] right-0">
              <ToggleCard
                position="top-right"
                isOpen={openCardPosition === 'top-right'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#d3473c] mb-8">FIERY RED</p>
                    <p>Goals Oriented (Results)</p>
                  </div>
                }
              />
            </div>
            <div className="absolute bottom-[10%] left-0">
              <ToggleCard
                position="bottom-left"
                isOpen={openCardPosition === 'bottom-left'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#8ba40a] mb-8">EARTH GREEN</p>
                    <p>Others Oriented (Maintaining Harmony)</p>
                  </div>
                }
              />
            </div>
            <div className="absolute bottom-[10%] right-0">
              <ToggleCard
                position="bottom-right"
                isOpen={openCardPosition === 'bottom-right'}
                onToggle={handleToggleCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#fbc600] mb-8">SUNSHINE YELLOW</p>
                    <p>Relationship Oriented (Interaction)</p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-20 mb-5 text-center">Fear</h3>
        <div className="">
          <div className="w-[635px] mx-auto relative">
            <img className="w-full" src={motivatingFactors} alt="" />
            <div className="absolute top-[10%] left-0">
              <ToggleCard
                position="top-left"
                isOpen={openFearCardPosition === 'top-left'}
                onToggle={handleToggleFearCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#388dbb] mb-8">COOL BLUE</p>
                    <p>Embarrassment (Making a Mistake)</p>
                  </div>
                }
              />
            </div>
            <div className="absolute top-[10%] right-0">
              <ToggleCard
                position="top-right"
                isOpen={openFearCardPosition === 'top-right'}
                onToggle={handleToggleFearCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#d3473c] mb-8">FIERY RED</p>
                    <p>Losing Control</p>
                  </div>
                }
              />
            </div>
            <div className="absolute bottom-[10%] left-0">
              <ToggleCard
                position="bottom-left"
                isOpen={openFearCardPosition === 'bottom-left'}
                onToggle={handleToggleFearCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#8ba40a] mb-8">EARTH GREEN</p>
                    <p>Confrontation</p>
                  </div>
                }
              />
            </div>
            <div className="absolute bottom-[10%] right-0">
              <ToggleCard
                position="bottom-right"
                isOpen={openFearCardPosition === 'bottom-right'}
                onToggle={handleToggleFearCard}
                cardContent={
                  <div>
                    <p className="text-2xl font-bold text-[#fbc600] mb-8">SUNSHINE YELLOW</p>
                    <p>Disapproval (Rejection)</p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-20 mb-5">
          Preferences & Color Energies
        </h3>
        <p>Preferences combine to inform the color energies.</p>
        <div className="flex text-base font-normal items-center mt-16">
          <div className="left-sec w-1/3">
            <p className="mb-7">
              Combination of <strong>Introverted</strong> and <strong>Thinking</strong> preferences:
            </p>
            <ul className="list-disc pl-5 mb-20">
              <li className="mb-2 pl-4">task-focused</li>
              <li className="mb-2 pl-4">calm under pressure</li>
              <li className="mb-2 pl-4">thoughtful</li>
              <li className="mb-2 pl-4">objective</li>
            </ul>
            <p className="mb-7">
              Combination of <strong>Introverted</strong> and <strong>Feeling</strong> preferences:
            </p>
            <ul className="list-disc pl-5">
              <li className="mb-2 pl-4">depth in relationships</li>
              <li className="mb-2 pl-4">reflection</li>
              <li className="mb-2 pl-4">harmony</li>
              <li className="mb-2 pl-4">consensus</li>
            </ul>
          </div>
          <div className="img-wrap w-1/3 mx-8">
            <img src={colorEn} alt="" />
          </div>
          <div className="right-sec w-1/3">
            <p className="mb-7">
              Combination of <strong>Extraverted</strong> and <strong>Thinking</strong> preferences:
            </p>
            <ul className="list-disc pl-5 mb-20">
              <li className="mb-2 pl-4">enjoying high activity</li>
              <li className="mb-2 pl-4">working with others</li>
              <li className="mb-2 pl-4">being logical</li>
              <li className="mb-2 pl-4">focusing on facts</li>
            </ul>
            <p className="mb-7">
              Combination of <strong>Extraverted</strong> and <strong>Feeling</strong> preferences:
            </p>
            <ul className="list-disc pl-5">
              <li className="mb-2 pl-4">sociability</li>
              <li className="mb-2 pl-4">consideration for others</li>
              <li className="mb-2 pl-4"> being action-oriented</li>
              <li className="mb-2 pl-4">entertaining</li>
            </ul>
          </div>
        </div>
        <p className="mt-10">
          The four personality colors described are the first step in analyzing our personal
          preferences.
        </p>
        <div className="border-t mt-8 pt-8"></div>
        <h3 className="text-2xl text-purple-600 font-bold mb-5">
          Use of Language Around Color Energies
        </h3>
        <p className="text-2xl leading-9 mb-8">
          As we identify our strongest color energies, remember to respectfully refer to color
          energies in yourself and others. Instead of saying "you are so RED,” you might say "I see
          that you are dialing up that red energy as you are making this decision.”
        </p>
        <p className="font-bold">Click on each tab to go through different use of languages.</p>
        <TabsBlock tabsData={languageTabData} />
        <p className="font-bold mt-8">
          Try this activity: Distinguish between respectful and disrespectful use of language.
        </p>
        <p className="mt-4">
          Instructions: Identify the disrespectful and respectful statements from the card pile and
          sort them by dragging and dropping them into their appropriate box.
        </p>
        <div className="border-t mt-8 pt-8"></div>
        {/* drag-drop */}
        <div className="mb-8">
          <DndProvider backend={HTML5Backend}>
            <CourseSortingQuestionBlock
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onRetake={handleSortingRetake}
              question={chapterSortingQuestions[0] as CourseSortingQuestion}
              submitedCorrectAnswerCount={
                userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[
                  chapter.id
                ]?.sortingQuestions
                  ?.filter((x) =>
                    Object.keys(x).filter((key) => key === chapterSortingQuestions[0].id),
                  )
                  .map(
                    (x) =>
                      x[chapterSortingQuestions[0].id].filter(
                        (x) => x.answer?.id === x.correct_answer?.id,
                      ).length,
                  )[0] ?? null
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              submitSortingQuestion={handleSortingExcerciseCompleted}
            />
          </DndProvider>
        </div>

        <p className="text-2xl">
          Do you recognize yourself and your peers within these types? Let's dig deeper into
          recognizing types in others.
        </p>

        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="w-full btn-primary uppercase mt-8"
        >
          RECOGNIZING TYPE
        </button>
      </div>
    </>
  );
};
