import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import { SWOTReport } from './swot.types';

export type SWOTState = {
  report?: SWOTReport;
};

const initialState: SWOTState = {};

const swotSlice = createSlice({
  name: 'swot',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<SWOTReport>) => {
      logger.debug('Updating swot state.', { state, action });
      state.report = action.payload;
    },
  },
});

export const { save: saveSWOTReportAction } = swotSlice.actions;
export default swotSlice.reducer;
