import { ConfidenceLevel, SkillsFields } from '../form-skills';

import { SWOTCategory, GenerateSWOTReportDTO, SWOTReport } from './swot.types';

/**
 * utility to generate a SWOT report
 */
export function generateSWOTReport(input: GenerateSWOTReportDTO): SWOTReport {
  const report: SWOTReport = {
    [SWOTCategory.STRENGTHS]: [],
    [SWOTCategory.WEAKNESSES]: [],
    [SWOTCategory.OPPORTUNITIES]: [],
    [SWOTCategory.THREATS]: [],
  };

  const keys: Array<keyof SkillsFields> = Object.keys(input) as Array<keyof SkillsFields>;
  for (const skill of keys) {
    const value: ConfidenceLevel = input[skill];
    if (value === 4) {
      report[SWOTCategory.STRENGTHS].push(skill);
    } else if (value >= 3) {
      report[SWOTCategory.OPPORTUNITIES].push(skill);
    } else if (value >= 2) {
      report[SWOTCategory.WEAKNESSES].push(skill);
    } else {
      report[SWOTCategory.THREATS].push(skill);
    }
  }

  // sort alphabetically
  report[SWOTCategory.STRENGTHS] = report[SWOTCategory.STRENGTHS].sort();
  report[SWOTCategory.WEAKNESSES] = report[SWOTCategory.WEAKNESSES].sort();
  report[SWOTCategory.OPPORTUNITIES] = report[SWOTCategory.OPPORTUNITIES].sort();
  report[SWOTCategory.THREATS] = report[SWOTCategory.THREATS].sort();

  if (
    !report[SWOTCategory.STRENGTHS].length &&
    !report[SWOTCategory.OPPORTUNITIES].length &&
    !report[SWOTCategory.WEAKNESSES].length
  ) {
    // if everything is a threat, make them all opportunities
    report[SWOTCategory.OPPORTUNITIES] = [...report[SWOTCategory.THREATS]];
    report[SWOTCategory.THREATS] = [];
  } else if (
    !report[SWOTCategory.STRENGTHS].length &&
    !report[SWOTCategory.OPPORTUNITIES].length &&
    report[SWOTCategory.WEAKNESSES].length > 0 &&
    report[SWOTCategory.THREATS].length > 0
  ) {
    // if there are weaknesses and threats but no strengths or opportunities,
    // make all the weaknesses -> opportunities
    report[SWOTCategory.OPPORTUNITIES] = [...report[SWOTCategory.WEAKNESSES]];
    report[SWOTCategory.WEAKNESSES] = [];
  }

  return report;
}
