import React from 'react';

import img5 from '../../../assets/images/swot/img5.png';
export const ResourcesTemplate: React.FC = () => {
  return (
    <>
      <div className="pt-4" style={{ height: '1122px' }}>
        <div className="text-center pt-3 pb-3 theme-gradient rounded-xl">
          <div className="text-xl text-white">
            <span className="font-semibold"> RESOURCES </span>
          </div>
        </div>
        <div className="text-center lg:px-16">
          <img className="mx-auto mt-2 w-2/5 lg:w-4/12" src={img5} alt="" />
          <p className="mt-2 lg:text-xl">
            We also want to encourage you to share Vero Learning with your peers and invite them to
            explore our career exploration questionnaire. We believe that everyone deserves a fair
            chance to pursue their dream careers and we are excited for the opportunity to serve
            more students.
            <br />
            <br />
            <span className="uppercase font-bold !text-purple-700">
              <a className="font-bold text-purple-700" href="https://verolearning.com/">
                VISIT NOW
              </a>
              <br />
              <br />
            </span>
            We understand that career exploration can be overwhelming. As you prepare for the next
            steps, we have also included a complied list of free resources to help you increase your
            employability.
          </p>
          <br />
          <a
            className="bg-purple-600 text-white pt-3  px-3 !pb-3 rounded-xl"
            href="https://verolearning.com/free-resources"
          >
            Free Resources
          </a>
          <br />
          <br />
          <p className="mt-2 lg:text-xl">
            Our team is hard at work preparing for the launch our transferable skills modules,
            digital badging and job matching technology in early June of 2024. These new features
            will help you enhance your skill sets and find compatible vocational opportunities from
            top companies that match your interests and abilities.
            <br />
            <br />
            With our platform, we hope to bridge the gap between education and employment and help
            prepare young adults like you for fulfilling careers. Thank you for choosing Vero
            Learning, We look forward to being part of your success story!
          </p>
          <br />
          <p className="uppercase font-bold text-purple-700">ALL THE BEST - VERO LEARNING TEAM</p>
        </div>
      </div>
    </>
  );
};
