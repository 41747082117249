import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import { UserCourse } from './user-course-question-response.types';

export type UserCourseQuestionState = {
  [courseId: string]: UserCourse;
};
const initialState: UserCourseQuestionState = {};

const userCourseQuestionSlice = createSlice({
  name: 'userCourseQuestion',
  initialState,
  reducers: {
    updateUserCourseQuestion: (state, action: PayloadAction<UserCourseQuestionState>) => {
      logger.debug('Updating user course question state.', { state, action });

      if (Object.keys(action.payload).length > 1) {
        return { ...action.payload };
      } else {
        const courseId = Object.keys(action.payload)[0];
        const chapterId = Object.keys(action.payload[courseId].chapters)[0];

        if (!state[courseId]) {
          state[courseId] = { chapters: {} };
        }

        if (Object.keys(action.payload[courseId].chapters).length > 1) {
          state[courseId].chapters = action.payload[courseId].chapters;
        } else {
          state[courseId].chapters[chapterId] = action.payload[courseId].chapters[chapterId];
        }
        return state;
      }
    },
  },
});

export const { updateUserCourseQuestion: updateUserCourseQuestionAction } =
  userCourseQuestionSlice.actions;
export default userCourseQuestionSlice.reducer;
