import { collection, addDoc, setDoc, doc } from 'firebase/firestore';

import { NewsLetterDTO } from '../../redux/slice/form-newsletter';
import { hash } from '../../utils/crypto';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';

const database: DatabaseTable = DatabaseTable.NEWSLETTER_SIGNUPS;

/**
 * upsert a record into the organization
 */
async function updateRecord<T extends Record<string, unknown>>(
  dto: T,
  recordId?: string,
): Promise<void> {
  if (recordId) {
    await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
      merge: true,
    });
  } else {
    await addDoc(collection(getFirestoreDatabase(), database), dto);
  }
}

/**
 * creates an newsletter signup
 */
export async function upsertNewsletterSignup(dto: NewsLetterDTO): Promise<void> {
  logger.debug('Upserting newsletter signup.');
  try {
    const { email } = dto;
    const trimmedEmail: string = email.trim();
    const hashedId: string = await hash(trimmedEmail);
    await updateRecord(dto, hashedId);
  } catch (e) {
    logger.debug('Failed to upsert newsletter signup.', e);
    throw e;
  }
}
