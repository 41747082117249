import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import {
  getCourseProgressValues,
  updateCourseProgress,
} from '../../../services/database/course-progress';

import { CourseProgressState, updateCourseProgressAction } from './user-course.slice';

/**
 * retrieve previously saved values from the career readiness assessment
 */
export const dispatchGetChapterProgress =
  () =>
  async (dispatch: Dispatch<PayloadAction<CourseProgressState>>): Promise<void> => {
    const values: CourseProgressState = await getCourseProgressValues();
    if (Object.keys(values).length) {
      dispatch(updateCourseProgressAction(values));
    }
  };

/**
 * update progress in the course
 */
export const dispatchUpdateChapterProgress =
  (dto: CourseProgressState) =>
  async (dispatch: Dispatch<PayloadAction<CourseProgressState>>): Promise<void> => {
    await updateCourseProgress(dto);
    dispatch(updateCourseProgressAction(dto));
  };
