import _ from 'lodash';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import adapting_chapt2_chatbg from '../../../../assets/images/courses/adapting-to-change/chapter2/adapting_chapt2_chatbg.jpg';
import angerImage from '../../../../assets/images/courses/adapting-to-change/chapter2/angerAndFrustration.jpeg';
import bargainingImage from '../../../../assets/images/courses/adapting-to-change/chapter2/bargainingAndNegotiation.jpeg';
import depressionImage from '../../../../assets/images/courses/adapting-to-change/chapter2/depressionAndDetachment.jpeg';
import movingForwardImage from '../../../../assets/images/courses/adapting-to-change/chapter2/movingForward.jpeg';
import shockImage from '../../../../assets/images/courses/adapting-to-change/chapter2/shockAndDenial.jpeg';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseMatchingQuestion,
  CourseSortingQuestion,
  QuestionType,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import {
  course_sorting_matching_questions,
  course_chat_questions,
} from '../../../../courses/understanding-self-and-others/adapting-to-change/adapting-to-change.quiz';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { dispatchUpdateUserCourseQuestionResponse } from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { ActionableBlock } from '../../ActionableBlock';
import { CarouselComponent } from '../../CarouselComponent';
import { InfoBlock } from '../../InfoBlock';
import { MatchingQuestionBlock } from '../../MatchingQuestion/CourseMatchingQuestion';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const AdaptingToChange2: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const [isPageDirty, setIsPageDirty] = useState(true);
  const dispatch = useAppDispatch();
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const courseChatQuestions = course_chat_questions.filter(
    (course) => course.chapter_id === chapter.id,
  );
  const chapterSortingQuestions = course_sorting_matching_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const chapterMatchingQuestions = course_sorting_matching_questions.filter(
    (courseChapter) =>
      courseChapter.chapter_id === chapter.id && courseChapter.type === QuestionType.MATCHING,
  );
  const slidesData = [
    {
      heading: '',
      paragraph: `Move through these cards to
          learn about the five emotional stages of reacting to change.`,
      list: [],
    },
    {
      heading: 'Shock and Denial',
      paragraph: `<p><em>“I can’t believe this is happening.”</em></p><br/><p>At first, an unexpected change can feel like a physical blow. It’s surprising. And that leads to feelings of shock, disorientation, and confusion. During this initial stage, you might struggle to come to terms with the change—denying its occurrence and feeling anxious and uneasy.</p>`,
      list: [],
      image: shockImage,
    },
    {
      heading: 'Anger and Frustration',
      paragraph: `<p><em>“No! This isn’t fair.”</em></p><br/><p>After the shock wears off, you may then feel angry or frustrated about this change. You might feel powerless and overwhelmed by the newness of everything, and that can cause you to feel emotionally charged, irritable, and short-tempered. During this stage, you might lash out or direct your anger toward yourself and others.</p>`,
      list: [],
      image: angerImage,
    },
    {
      heading: 'Bargaining and Negotiation',
      paragraph: `<p><em>“Is there a way out of this?” </em></p><br/><p>After your anger subsides, you might try to regain control by bargaining or negotiating with others. You might ask: <em>“Can we postpone this? Make it smaller? Or limit its impact?”</em> This third stage is characterized by a last-ditch effort to delay the inevitable—or stop the change from happening altogether.&nbsp;</p>`,
      list: [],
      image: bargainingImage,
    },
    {
      heading: 'Depression and Detachment',
      paragraph: `<p><em>“What’s the point of doing anything?”</em></p><br/><p>After those strong emotions have run their course, you can feel numb and detached. As you think through all the things you must leave behind, then you may feel a strong sense of loss. And that can lead to a type of sadness and depression that drains your energy and isolates you from others.</p>`,
      list: [],
      image: depressionImage,
    },
    {
      heading: 'Acceptance and Moving Forward',
      paragraph: `<p><em>“It’s going to be OK.”</em></p><br/><p>The final stage is the one you’ve been waiting for: acceptance. At this point, you’ll welcome the change. You may not feel entirely happy with your new circumstances, but you’ll also no longer feel a strong negative response. As a result, you can now approach your new reality with an objective, focused perspective—directing your attention toward what’s new, rather than on what you’ve lost.</p>`,
      list: [],
      image: movingForwardImage,
    },
    {
      heading: 'Summary',
      list: [],
      paragraph: `When reacting to workplace change, people tend to move through five emotional stages: shock and denial, anger and frustration, bargaining and negotiation, depression and detachment, and finally, acceptance and moving forward.`,
    },
  ];
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[chapterMatchingQuestions[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              matchingQuestions: [...userMatchingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingRetake = async (questionId: string) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[chapterMatchingQuestions[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              matchingQuestions: [...userMatchingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleMatchingRetake = async (questionId: string) => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[chapterMatchingQuestions[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterMatchingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              matchingQuestions: [
                ...userMatchingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleMatchingExcerciseCompleted = async (matchingAnswers: SortingAnswersState) => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[chapterMatchingQuestions[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterMatchingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              matchingQuestions: [
                ...userMatchingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(matchingAnswers)[0]),
                ),
                matchingAnswers,
              ],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  useEffect(() => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions;
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[chapterMatchingQuestions[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userSortingAnswerResponse?.length &&
      userMatchingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Common Reactions to Change</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">
          5 Reaction Stages to Change
        </h3>
        <p>
          Change is disruptive, and that can make it uncomfortable for many people. But that
          discomfort is rarely stagnant. Often, it moves through stages. And, over time, discomfort
          brings us to a place of acceptance. By recognizing which stage you’re experiencing, you
          can take the first steps toward a healthy transition.
        </p>
        <div className="border rounded-3xl pt-10 mt-7">
          <CarouselComponent data={slidesData} />
        </div>
        <div className="content-wrap lg:pt-8 pt-4 pb-10">
          <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">Sorting Activity</h3>
          <p>
            Determine which stage of change the following statements best represents and drag and
            drop them into their appropriate pile.
          </p>
          <DndProvider backend={HTML5Backend}>
            <CourseSortingQuestionBlock
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onRetake={handleSortingRetake}
              question={chapterSortingQuestions[0] as CourseSortingQuestion}
              submitedCorrectAnswerCount={
                userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[
                  chapter.id
                ]?.sortingQuestions
                  ?.filter((x) =>
                    Object.keys(x).filter((key) => key === chapterSortingQuestions[0].id),
                  )
                  .map(
                    (x) =>
                      x[chapterSortingQuestions[0].id].filter(
                        (x) => x.answer?.id === x.correct_answer?.id,
                      ).length,
                  )[0] ?? null
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              submitSortingQuestion={handleSortingExcerciseCompleted}
            />
          </DndProvider>
        </div>
        <ActionableBlock
          backgroundImage={adapting_chapt2_chatbg}
          courseChatQuestions={courseChatQuestions}
          characterImage={''}
          feedbackTitle="Scenario End"
          feedbackText=" Embrace change and remember the stages of change when you experience change in your life."
          buttonName="Continue"
          cssClass="adaptchange-chat"
        />

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">A Bumpy Ride</h3>
        <p className="mb-7">
          Your progress through the five emotional stages might not be smooth or linear. You may
          skip over certain stages, experience several at once, or move around between them. But
          regardless of where you stand, your goal is to reach (and remain) at acceptance.
        </p>
        <InfoBlock
          title="Give it time. "
          text="As the five reaction stages prove, accepting change takes time. So, don’t rush your emotions or get frustrated if you’re feeling stuck. Everyone moves at a different pace. What matters is not how quickly you progress, but that you do so in a healthy and productive way. "
        />
        <div className="content-wrap lg:pt-8 pt-4 pb-10">
          <h2 className="text-3xl font-bold mt-7 mb-7">Check Your Knowledge</h2>
          <div className="border rounded-3xl p-8 text-lg">
            <DndProvider backend={HTML5Backend}>
              <MatchingQuestionBlock
                questionData={chapterMatchingQuestions[0] as CourseMatchingQuestion}
                question_title={chapterMatchingQuestions[0].question_description}
                submitedAnswer={
                  _.flatMap(
                    userCourseQuestionResponseContext[
                      chapterMatchingQuestions[0].course_id
                    ]?.chapters[chapter.id]?.matchingQuestions?.filter((x) =>
                      Object.keys(x).filter((key) => key === chapterMatchingQuestions[0].id),
                    ),
                    _.values,
                  )[0]
                }
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onRetake={handleMatchingRetake}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                submitMatchingQuestion={handleMatchingExcerciseCompleted}
              />
            </DndProvider>
          </div>
        </div>
        <p className="text-3xl leading-10 font-light mt-7 text-center">
          So, how can you embrace change and navigate these stages effectively? Continue to the next
          lesson to learn four strategies.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full uppercase mt-7"
        >
          4 Strategies for Accepting Change
        </button>
      </div>
    </>
  );
};
