/**
 * the status of a user's pre-employment assessment
 */
export enum PreEmploymentAssessmentStatus {
  NOT_STARTED = 'not_started',
  ACTIVE = 'active',
  COMPLETE = 'completed',
  SYNC_TO_AIRTABLE = 'synced_to_airtable',
}

/**
 * model for the pre-employment assessment
 */
export type PreEmploymentAssessment = {
  userId: string;
  status: PreEmploymentAssessmentStatus;
  disablePersonalInfo?: boolean;
  requiresEmploymentReadiness: boolean;
  requiresCareerReadinessAssessment: boolean;
  requiresExperienceAssessment: boolean;
  isSWOTGenerated?: boolean;
  didCompleteIntake?: boolean;
  didCompleteEmploymentReadiness?: boolean;
  didCompleteSkills?: boolean;
  didCompleteCareerReadiness?: boolean;
  didCompleteExperience?: boolean;
  activeStepUrl?: string;
  progress?: number;
  defaultStepProgress?: number;
  completeDate?: Date | null;
  syncedToAirtable?: boolean;
  preEmployementSubmitDate?: string | null;
  didAsaUserViewedSwot?: boolean;
};

/**
 * the modules that make up the pre-employment assessment
 */
export enum PreEmploymentAssessmentModule {
  INTAKE = 'intake',
  EMPLOYMENT_READINESS = 'employment_readiness',
  SKILLS = 'skills',
  CAREER_READINESS = 'career_readiness',
  EXPERIENCE = 'experience',
}
