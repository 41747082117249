import { CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import char1 from '../assets/images/upgrade/char1.png';
import char2 from '../assets/images/upgrade/char2.png';
import char3 from '../assets/images/upgrade/char3.png';
import char4 from '../assets/images/upgrade/char4.png';
import image3 from '../assets/images/upgrade/image3.png';
import image4 from '../assets/images/upgrade/image4.png';
import image5 from '../assets/images/upgrade/image5.png';
import image6 from '../assets/images/upgrade/image6.png';
import image7 from '../assets/images/upgrade/image7.png';
import opportunities from '../assets/images/upgrade/Opportunities.svg';
import rect from '../assets/images/upgrade/rectangle.svg';
import strengths from '../assets/images/upgrade/Strengths.svg';
import image2 from '../assets/images/upgrade/swot.png';
import Threats from '../assets/images/upgrade/Threats.svg';
import image1 from '../assets/images/upgrade/upgrade.png';
import weaknesses from '../assets/images/upgrade/Weaknesses.svg';
import { AuthenticatedPage } from '../components/AuthenticatedPage';
import { CircularLoader } from '../components/loader/CircularLoader';
import UpgradeTimer from '../components/Timer/UpgradeTimer';
import { useIntakeFormContext, useSkillsContext } from '../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../hooks/use-pre-employment-assessment.hooks';
import { useSWOTContext } from '../hooks/use-swot-context.hooks';
import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { CustomUser } from '../redux/slice/auth/CustomUser';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import { SkillsFields, SkillsState } from '../redux/slice/form-skills';
import { PreEmploymentAssessmentState } from '../redux/slice/pre-employment-assessment';
import {
  dispatchGenerateSWOTReport,
  dispatchGetSWOTReportValues,
} from '../redux/slice/swot/swot.operations';
import { GenerateSWOTReportDTO, SWOTCategory, SWOTReport } from '../redux/slice/swot/swot.types';
import {
  dispatchGetUserProfileValues,
  dispatchUpdateUserProfileInfo,
} from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';
import { createPaymentSession } from '../services/stripe/stripe';
import { EnvironmentVariable } from '../utils/constants';
import { calculateFinalPrice } from '../utils/helper';
const Upgrade: React.FC = () => {
  const user: CustomUser | null = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [asaUrl, setAsaUrl] = useState('');
  const [finalProductPrice, setFinalProductPrice] = useState(
    Number(process.env[EnvironmentVariable.REACT_APP_SUBSCRIPTION_PRICE_CENTS]) / 100,
  );
  const userProfileContext = useUserProfileContext();
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const preEmploymentAssessment: PreEmploymentAssessmentState = usePreEmploymentAssessmentContext();
  const { organization, email: userEmail } = useIntakeFormContext();
  const asaUserDiscount = Number(process.env[EnvironmentVariable.REACT_APP_ASA_DISCOUNT_PERCENT]);
  useEffect(() => {
    if (user) {
      setIsLoading(false);
      if (user.claims?.isAsaUser) {
        setFinalProductPrice(calculateFinalPrice(finalProductPrice, asaUserDiscount));
      }
    }
  }, [user]);

  useEffect(() => {
    if (user?.claims.isAsaUser && !preEmploymentAssessment?.didAsaUserViewedSwot) {
      navigate('/swot');
    }
  }, [preEmploymentAssessment.didAsaUserViewedSwot, user]);

  useEffect(() => {
    setAsaUrl(process.env[EnvironmentVariable.REACT_APP_ASA_DASHBOARD_URL] || '');

    window.scrollTo(0, 0);
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchGetUserProfileValues());
    void dispatch(dispatchGetSWOTReportValues());
  }, []);

  const upgradeToPro = async () => {
    if (user) {
      setIsLoading(true);
      const paymentSessionData = (await createPaymentSession(user)) as {
        url: string;
        sessionId: string;
      } | null;
      if (paymentSessionData) {
        await dispatch(
          dispatchUpdateUserProfileInfo({
            paymentProcessing: true,
            paymentSessionCreateDate: new Date().toISOString(),
            stripeSessionId: paymentSessionData?.sessionId,
          }),
        );
        location.href = paymentSessionData.url;
      } else {
        setIsLoading(false);
        toast.error('Something went wrong, Please try after some time.');
      }
    }
  };
  useEffect(() => {
    if (userProfileContext?.email && userProfileContext?.paymentProcessing) {
      setIsPaymentProcessing(userProfileContext?.paymentProcessing);
    } else if (userProfileContext?.email && userProfileContext?.isPremium) {
      return navigate('/dashboard');
    } else if (!preEmploymentAssessment.didCompleteSkills) {
      preEmploymentAssessment.activeStepUrl &&
      preEmploymentAssessment.activeStepUrl.includes('skills')
        ? navigate(preEmploymentAssessment.activeStepUrl)
        : navigate('/pre-employment/start');
    } else if (
      preEmploymentAssessment.didCompleteSkills &&
      !preEmploymentAssessment.isSWOTGenerated
    ) {
      navigate('/swot');
    } else if (organization && userEmail) {
      navigate('/dashboard');
    }
  }, [userProfileContext, preEmploymentAssessment, userEmail, organization]);

  const skills: SkillsState = useSkillsContext();
  const swot: SWOTReport | undefined = useSWOTContext();
  function mapVariableToReadableString(key: keyof SkillsFields): string {
    switch (key) {
      case 'skillCommunication':
        return 'Communication';
      case 'skillCulture':
        return 'Culture';
      case 'skillMotivation':
        return 'Motivation';
      case 'skillPlanning':
        return 'Planning';
      case 'skillProblemSolving':
        return 'Problem Solving';
      case 'skillProfessionalism':
        return 'Professionalism';
      case 'skillReliability':
        return 'Reliability';
      case 'skillStressTolerance':
        return 'Stress Tolerance';
      case 'skillTeamwork':
        return 'Teamwork';
      case 'skillTechnology':
        return 'Technology';
    }
  }

  useEffect(() => {
    if (isPaymentProcessing) {
      const interval = setInterval(() => {
        window.location.reload(); // Reload the entire page
      }, 60000); // Refresh every 1 minute (60000 milliseconds)

      return () => clearInterval(interval);
    }
    return () => {};
  }, [isPaymentProcessing]);

  useEffect(() => {
    if (!skills || !Object.keys(skills).length || swot) {
      swot;
      return;
    }

    void dispatch(dispatchGenerateSWOTReport(skills as GenerateSWOTReportDTO));
  }, [skills, swot, dispatch]);

  return (
    <>
      {isLoading && <CircularLoader />}
      <div className="upgrade-wrap relative">
        <div className="text-center theme-gradient">
          <Container>
            <h1 className="text-2xl lg:text-5xl font-semibold text-white py-2">Congratulations!</h1>
          </Container>
        </div>
        <>
          <Container>
            <div className="max-w-[800px] mx-auto shadow md:px-16 px-2 py-8 relative bg-white">
              {user?.claims?.isAsaUser ? (
                <p className="md:text-xl font-medium text-center">
                  You've successfully completed the Vero Learning task on EvolveMe. Check your email
                  for your Personalized Career Power-Up Report and head back to{' '}
                  <Link to={asaUrl} target="_blank" className="underline text-purple-700 font-bold">
                    EvolveMe
                  </Link>{' '}
                  to see your points
                </p>
              ) : (
                <p className="md:text-xl font-medium text-center">
                  You've successfully completed the Personal Career Skill Assessment. Check your
                  email for your Personalized Career Power-Up Report.
                </p>
              )}

              <div className="text-center">
                {isPaymentProcessing ? (
                  <div className="mt-10 text-[#962cc6]">
                    {' '}
                    <CircularProgress color="inherit" />
                    <p className="mt-2 text-center font-bold">Payment is under process...</p>
                  </div>
                ) : user?.claims.isAsaUser ? (
                  <button
                    disabled={isLoading}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={upgradeToPro}
                    className="cursor-pointer btn-primary flex justify-center align-middle w-3/4 mx-auto mt-5 underline underline-offset-4"
                  >
                    {user?.claims.isAsaUser} {asaUserDiscount}% OFF Premium Upgrade!
                  </button>
                ) : (
                  <button
                    disabled={isLoading}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={upgradeToPro}
                    className="cursor-pointer btn-primary flex justify-center align-middle w-3/4 mx-auto mt-5 underline underline-offset-4"
                  >
                    Premium Upgrade!
                  </button>
                )}
              </div>

              <div className="text-center my-10">
                <img className="mx-auto" src={image1} alt="" />
              </div>
              <p className="md:text-xl text-center">
                <span className="text-purple-700 font-bold">
                  Hey future trailblazers and job seekers!
                </span>
                <br />
                Welcome to Vero Learning, where your journey to success begins. Whether you're
                exploring your strengths or gearing up for your dream job, we're here to guide you
                every step of the way.
              </p>
              <h2 className="md:text-3xl text-2xl font-bold text-purple-700 max-w-3xl mx-auto my-9 text-center">
                Ready to take charge?
                <br />
                Let's dive into your future together!
              </h2>
              <div className="text-center mb-9">
                <img className="mx-auto" src={image2} alt="" />
              </div>
              <p className="md:text-xl font-medium text-center">
                You've taken the first step with our free mini SWOT report – now take the leap and
                go big!{' '}
                <span className="font-bold">
                  For only ${finalProductPrice} {user?.claims.isAsaUser} {user?.claims.isAsaUser},
                  upgrade to our Premium Package.
                </span>
              </p>
              <div className="text-center my-5">
                {isPaymentProcessing ? (
                  <div className="mt-10 text-[#962cc6]">
                    {' '}
                    <CircularProgress color="inherit" />
                    <p className="mt-2 text-center font-bold">Payment is under process...</p>
                  </div>
                ) : (
                  <button
                    disabled={isLoading}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={upgradeToPro}
                    className="cursor-pointer bg-purple-200 text-purple-600 border border-dashed border-purple-600 py-2 p-4 rounded-lg flex justify-center align-middle  mx-auto mt-5 mb-5 md:mb-0 underline"
                  >
                    Upgrade Now
                  </button>
                )}
              </div>
              <p className="md:text-xl font-medium mb-9 text-center">
                Gain access to our full pre-employment screening, essential transferable skills
                courses, and upcoming AI tools that connect you directly with{' '}
                <span className="text-purple-700 font-bold">
                  Fortune 500 companies for first-round interviews.
                </span>
              </p>
              <div className="flex items-center flex-wrap md:flex-nowrap gap-4 justify-center md:justify-between mb-9">
                <div className="text-center flex-none">
                  <img className="mx-auto" src={image3} alt="" />
                </div>
                <div>
                  <p className="md:text-xl">
                    <span className="text-purple-700 font-bold">
                      Full Pre-Employment Screening:
                    </span>
                    <br />
                    Discover what makes you shine.
                  </p>
                </div>
              </div>
              <div className="flex items-center flex-wrap md:flex-nowrap gap-4 justify-center md:justify-between mb-9">
                <div className="md:order-1 order-2">
                  <p className="md:text-xl">
                    <span className="text-purple-700 font-bold">Transferable Skills Courses:</span>
                    <br />
                    Master skills employers crave
                  </p>
                </div>
                <div className="text-center flex-none  md:order-2 order-1">
                  <img className="mx-auto" src={image4} alt="" />
                </div>
              </div>
              <div className="flex items-center flex-wrap md:flex-nowrap gap-4  justify-center md:justify-between ">
                <div className="text-center flex-none">
                  <img className="mx-auto" src={image5} alt="" />
                </div>
                <div>
                  <p className="md:text-xl text-center">
                    <span className="text-purple-700 font-bold">
                      AI Tools for First-Round Interviews:
                    </span>
                    <br />
                    Land your dream job faster than ever by connecting with hiring mangers in tech,
                    healthcare, IT, retail manufacturing, skills trades and more!
                  </p>
                </div>
              </div>
              <div className="text-center my-9">
                <p className="md:text-xl font-bold">
                  At Vero Learning, we're revolutionizing career readiness for young adults like
                  you. Empower yourself with data-driven insights and personalized career guidance
                  to secure your dream job, no college degree required!
                </p>
              </div>
              <div className="text-center">
                {isPaymentProcessing ? (
                  <div className="mt-10 text-[#962cc6]">
                    {' '}
                    <CircularProgress color="inherit" />
                    <p className="mt-2 text-center font-bold">Payment is under process...</p>
                  </div>
                ) : (
                  <button
                    disabled={isLoading}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={upgradeToPro}
                    className="cursor-pointer btn-primary flex justify-center align-middle lg:w-3/4 w-full  mx-auto mt-5 mb-5 md:mb-0 underline underline-offset-4"
                  >
                    Unleash Your Potential: Upgrade Today
                  </button>
                )}
              </div>

              {/* new cards design starts */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-auto md:mt-16 dsk-txt">
                <div className="bg-purple-500 text-white rounded-2xl px-3 pt-16 pb-16 relative text-center mt-8  lg:mt-0">
                  <div className="imgwrapper">
                    <div className="flex justify-center">
                      <div className="bg-white rounded-full">
                        <img src={strengths} alt="Icon" className="w-20 h-20" />
                      </div>
                    </div>
                  </div>
                  <h2 className="text-xl font-bold mb-4">Strengths</h2>
                  <hr className="w-5 mx-auto my-0 mb-4" />
                  <ul className="space-y-2 cstm-unordered-list">
                    {swot?.[SWOTCategory.STRENGTHS].length ? (
                      swot[SWOTCategory.STRENGTHS].map((value: keyof SkillsFields) => (
                        <li key={value}>
                          <a className="text-base cst-text-base" target="_blank">
                            {mapVariableToReadableString(value)}
                          </a>
                        </li>
                      ))
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
                <div className="bg-goldenrod text-white rounded-2xl px-3 pt-16 pb-16 relative text-center mt-8  lg:mt-0">
                  <div className="imgwrapper">
                    <div className="flex justify-center">
                      <div className="bg-white rounded-full">
                        <img src={weaknesses} alt="Icon" className="w-20 h-20" />
                      </div>
                    </div>
                  </div>
                  <h2 className="text-xl font-bold mb-4">Weakness</h2>
                  <hr className="w-5 mx-auto my-0 mb-4" />
                  <ul className="space-y-2 cstm-unordered-list">
                    {swot?.[SWOTCategory.WEAKNESSES].length ? (
                      swot[SWOTCategory.WEAKNESSES].map((value: keyof SkillsFields) => (
                        <li key={value}>
                          <a className="text-base cst-text-base" target="_blank">
                            {mapVariableToReadableString(value)}
                          </a>
                        </li>
                      ))
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
                <div className="bg-blue text-white rounded-2xl px-3 pt-16 pb-16 relative text-center mt-8  lg:mt-0">
                  <div className="imgwrapper">
                    <div className="flex justify-center">
                      <div className="bg-white rounded-full">
                        <img src={opportunities} alt="Icon" className="w-20 h-20" />
                      </div>
                    </div>
                  </div>
                  <h2 className="text-xl font-bold mb-4">Opportunities</h2>
                  <hr className="w-5 mx-auto my-0 mb-4" />
                  <ul className="space-y-2 cstm-unordered-list">
                    {swot?.[SWOTCategory.OPPORTUNITIES].length ? (
                      swot[SWOTCategory.OPPORTUNITIES].map((value: keyof SkillsFields) => (
                        <li key={value}>
                          <a className="text-base cst-text-base" target="_blank">
                            {mapVariableToReadableString(value)}
                          </a>
                        </li>
                      ))
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
                <div className="bg-darkred text-white rounded-2xl px-3 pt-16 pb-16 relative text-center mt-8  lg:mt-0">
                  <div className="imgwrapper">
                    <div className="flex justify-center">
                      <div className="bg-white rounded-full">
                        <img src={Threats} alt="Icon" className="w-20 h-20" />
                      </div>
                    </div>
                  </div>
                  <h2 className="text-xl font-bold mb-4">Threats</h2>
                  <hr className="w-5 mx-auto my-0 mb-4" />
                  <ul className="space-y-2 cstm-unordered-list">
                    {swot?.[SWOTCategory.THREATS].length ? (
                      swot[SWOTCategory.THREATS].map((value: keyof SkillsFields) => (
                        <li key={value}>
                          <a className="text-base cst-text-base" target="_blank">
                            {mapVariableToReadableString(value)}
                          </a>
                        </li>
                      ))
                    ) : (
                      <li>None</li>
                    )}
                  </ul>
                </div>
              </div>
              {/* new cards design ends*/}
              {/* swot card here */}
              <div className="flex flex-wrap md:flex-nowrap items-center gap-4 justify-center md:justify-between mb-9 mt-5">
                <div className="text-center flex-none order-1 h-auto max-w-full">
                  <img className="mx-auto" src={image6} alt="" />
                </div>
                <div className="order-2">
                  <p className="md:text-xl">
                    Join thousands of peers choosing Vero for their career journeys.{' '}
                    <span className=" font-bold">
                      With a 90% recommendation rate and our partnership
                    </span>{' '}
                    with American Student Assistance, you’re in good company! Trust Vero to guide
                    you towards success.
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap md:flex-nowrap items-center gap-4 justify-between">
                <div className=" md:order-1 order-2">
                  <p className="md:text-xl">
                    <span className=" font-bold">
                      Don't miss out on this exclusive offer to shape your future with Vero
                      Learning.
                    </span>{' '}
                    Upgrade today to embark on your path to career greatness!
                  </p>
                </div>
                <div className="text-center flex-none order-1 md:order-2 h-auto max-w-full">
                  <img className="mx-auto" src={image7} alt="" />
                </div>
              </div>
              {user?.claims.isAsaUser && (
                <div>
                  <h2 className="md:text-3xl text-2xl font-bold  max-w-3xl mx-auto text-center mt-9">
                    Limited Offer
                  </h2>
                  <UpgradeTimer />
                  <p className="md:text-xl text-center">
                    Unlock your potential with Vero Learning's Premium Package. This exclusive offer
                    won't last long – upgrade now and take the next step towards your dream career!
                  </p>
                </div>
              )}
              <div className="justify-end lg:justify-between mt-8">
                <div className="text-center">
                  {isPaymentProcessing ? (
                    <div className="mt-10 text-[#962cc6]">
                      {' '}
                      <CircularProgress color="inherit" />
                      <p className="mt-2 text-center font-bold">Payment is under process...</p>
                    </div>
                  ) : (
                    <button
                      disabled={isLoading}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={upgradeToPro}
                      className="cursor-pointer bg-purple-200 text-purple-600 border border-dashed border-purple-600 py-2 p-4 rounded-lg flex justify-center align-middle  mx-auto mt-5 mb-5 md:mb-0 underline"
                    >
                      Upgrade Now
                    </button>
                  )}
                </div>
              </div>
              <div className="text-center absolute top-[750px] -right-48 -z-10 hidden lg:block">
                <img className="mx-auto" src={char1} alt="" />
              </div>
              <div className="text-center absolute top-[1400px] -left-28 -z-10 hidden lg:block">
                <img className="mx-auto" src={char2} alt="" />
              </div>
              <div className="text-center absolute top-[2100px] -right-28 -z-10  hidden lg:block">
                <img className="mx-auto" src={char3} alt="" />
              </div>
              <div className="text-center absolute bottom-0 -left-40  hidden lg:block">
                <img className="mx-auto" src={char4} alt="" />
              </div>
            </div>
          </Container>
        </>
        <div className="text-center absolute top-[1300px] right-[-85px] hidden lg:block">
          <img className="mx-auto" src={rect} alt="" />
        </div>
        <div className="text-center absolute top-[2300px] left-[-85px]  hidden lg:block">
          <img className="mx-auto" src={rect} alt="" />
        </div>
        <div className="text-center absolute bottom-[700px] right-[-85px]  hidden lg:block">
          <img className="mx-auto" src={rect} alt="" />
        </div>
      </div>
    </>
  );
};

export const UpgradePage: React.FC = () => {
  return <AuthenticatedPage render={() => <Upgrade />} isUpgradePage={true} />;
};
