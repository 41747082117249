import React from 'react';
import { useNavigate } from 'react-router-dom';

import img5 from '../../assets/images/swot/step2/char.svg';
import character from '../../assets/images/swot/step2/char2.svg';

export const SWOTAnalysis: React.FC = () => {
  const navigate = useNavigate();
  const handleNextButton = () => {
    navigate('/swot/step3');
  };
  return (
    <>
      <div className="character absolute bottom-[-95px] left-[-50px] lg:left-auto lg:right-[-100px]">
        <img className="" src={character} alt="" />
      </div>
      <div className="text-center lg:px-16">
        <img className="mx-auto mt-5" src={img5} alt="" />
        <p className="mt-8 text-xl">
          We are delighted to share with you the results of your preliminary career SWOT analysis
          report. Our assessment tools have evaluated your attitude, skills and experience and have
          generated a personalized report that provides insights into your career readiness. Based
          on your responses to the intake questionnaire, our platform has identified your strengths
          and opportunities for growth to support you as you explore potential career paths.
        </p>
      </div>
      <div className="text-center mt-12">
        <button onClick={handleNextButton} className="btn-primary">
          Next
        </button>
      </div>
    </>
  );
};
