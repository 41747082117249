import CancelIcon from '@mui/icons-material/Cancel';

import completedIcon from '../../assets/icons/check-circle.svg';
import { Course, CourseChapter, CourseSection } from '../../courses/courses.types';
import { useCourseProgressContext } from '../../hooks/use-user-course.hooks';
import { FixedScrollProgress } from '../common/FixedScrollProgress';
import { PageScrollProgress } from '../common/PageScrollProgress';
interface LeftPanelProps {
  course: Course;
  selected: CourseChapter | undefined;
  onSelectChapter: (chapter: CourseChapter) => void;
  quizPassed: boolean | undefined;
  postAssementQuizPassed: boolean | undefined;
  sideBarEnabled: boolean;
}
export const LeftPanel: React.FC<LeftPanelProps> = ({
  course,
  onSelectChapter,
  selected,
  quizPassed,
  sideBarEnabled,
  postAssementQuizPassed,
}) => {
  const courseProgress = useCourseProgressContext();
  const handleChapterClick = (chapter: CourseChapter) => {
    onSelectChapter(chapter);
  };

  return (
    <div className={`sidebar-nav flex-none ${sideBarEnabled ? 'sidebar-enabled' : ''}`}>
      {course.sections.map((section: CourseSection) => (
        <div key={section.id}>
          <p className="text-base tracking-widest font-semibold text-gray-400 mt-4 mb-3 uppercase">
            {section.name}
          </p>
          <ul>
            {section.chapters.map((chapter: CourseChapter) => (
              <li
                id={chapter.id}
                key={chapter.id}
                className={`flex justify-between ${selected?.id === chapter.id ? 'active' : ''}`}
              >
                <button className="text-left" onClick={() => handleChapterClick(chapter)}>
                  {chapter.name}
                </button>
                <span className="flex-none ml-1">
                  {courseProgress &&
                  Object.keys(courseProgress).length &&
                  courseProgress?.[course.id] &&
                  Object.keys(courseProgress?.[course.id]).length &&
                  courseProgress[course.id]?.chapters[chapter.id] === 100 ? (
                    <>
                      <div>
                        {(chapter.name.includes('Quiz') &&
                          quizPassed !== undefined &&
                          !quizPassed) ||
                        (chapter.name.includes('Post-Assessment') &&
                          postAssementQuizPassed !== undefined &&
                          !postAssementQuizPassed) ? (
                          <CancelIcon
                            style={{
                              filter: `${
                                selected?.id === chapter.id ? 'brightness(1) invert(1)' : 'none'
                              }`,
                            }}
                          />
                        ) : (chapter.name.includes('Quiz') && !quizPassed) ||
                          (chapter.name.includes('Post-Assessment') && !postAssementQuizPassed) ? (
                          <FixedScrollProgress scrollTop={0} />
                        ) : (
                          <img
                            style={{
                              filter: `${
                                selected?.id === chapter.id ? 'brightness(0) invert(1)' : 'none'
                              }`,
                            }}
                            src={completedIcon}
                            alt=""
                          />
                        )}
                      </div>
                    </>
                  ) : selected?.id === chapter.id ? (
                    <PageScrollProgress
                      enableCompleteScroll={chapter.enableCompleteScroll || false}
                      disableContentScroll={chapter.disableContentScroll || false}
                      chapterId={chapter.id}
                      courseId={course.id}
                    />
                  ) : (
                    <FixedScrollProgress
                      scrollTop={
                        (courseProgress &&
                          Object.keys(courseProgress).length &&
                          courseProgress?.[course.id] &&
                          Object.keys(courseProgress?.[course.id]).length &&
                          courseProgress[course.id]?.chapters[chapter.id]) ||
                        0
                      }
                    />
                  )}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
