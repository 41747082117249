import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { SkillsState } from '../../redux/slice/form-skills/form-skills.slice';
import { UpdateSkillsDTO } from '../../redux/slice/form-skills/form-skills.types';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.SKILLS_RESPONSES;

/**
 * upsert a record into the skills responses collection
 */
async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

/**
 * retrieves the values of the previously inputted skills if available
 */
export async function getSkillsValues(): Promise<SkillsState> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<SkillsState> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return result.data() ?? {};
  } catch (e) {
    logger.error('Failed to retrieve skills values.', e);
    throw e;
  }
}

/**
 * updates the skills assessment
 */
export async function updateSkills(dto: UpdateSkillsDTO): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved skills.');
  } catch (e) {
    logger.error('Failed to update skills.', e);
    throw e;
  }
}
