/**
 * environment variables used in the application
 */
export enum EnvironmentVariable {
  REACT_APP_FIREBASE_API_KEY = 'REACT_APP_FIREBASE_API_KEY',
  REACT_APP_FIREBASE_AUTH_DOMAIN = 'REACT_APP_FIREBASE_AUTH_DOMAIN',
  REACT_APP_FIREBASE_PROJECT_ID = 'REACT_APP_FIREBASE_PROJECT_ID',
  REACT_APP_FIREBASE_STORAGE_BUCKET = 'REACT_APP_FIREBASE_STORAGE_BUCKET',
  REACT_APP_FIREBASE_MESSAGE_SENDER_ID = 'REACT_APP_FIREBASE_MESSAGE_SENDER_ID',
  REACT_APP_FIREBASE_APP_ID = 'REACT_APP_FIREBASE_APP_ID',
  REACT_APP_FIREBASE_MEASUREMENT_ID = 'REACT_APP_FIREBASE_MEASUREMENT_ID',
  REACT_APP_PARAGON_CREATE_CONTACT_ENDPOINT = 'REACT_APP_PARAGON_CREATE_CONTACT_ENDPOINT',
  REACT_APP_NODE_ENV = 'REACT_APP_NODE_ENV',
  REACT_APP_PARAGON_ADD_USER_CLAIMS_ENDPOINT = 'REACT_APP_PARAGON_ADD_USER_CLAIMS_ENDPOINT',
  REACT_APP_PARAGON_DELETE_USER_ENDPOINT = 'REACT_APP_PARAGON_DELETE_USER_ENDPOINT',
  REACT_APP_OTP_EXP_TIME = 'REACT_APP_OTP_EXP_TIME',
  REACT_APP_SEND_OTP_EMAIL_URL = 'REACT_APP_SEND_OTP_EMAIL_URL',
  REACT_APP_SEND_SWOT_REPORT_URL = 'REACT_APP_SEND_SWOT_REPORT_URL',
  REACT_APP_BASE_URL = 'REACT_APP_BASE_URL',
  REACT_APP_OTP_REGENRATE_TIME = 'REACT_APP_OTP_REGENRATE_TIME',
  REACT_APP_ASA_COMPLETE_TASK_URL = 'REACT_APP_ASA_COMPLETE_TASK_URL',
  REACT_APP_SUBSCRIPTION_PRICE_CENTS = 'REACT_APP_SUBSCRIPTION_PRICE_CENTS',
  REACT_APP_STRIPE_CREATE_SESSION_URL = 'REACT_APP_STRIPE_CREATE_SESSION_URL',
  REACT_APP_STRIPE_PAYMENT_FAILED_URL = 'REACT_APP_STRIPE_PAYMENT_FAILED_URL',
  REACT_APP_STRIPE_PAYMENT_SUCCESS_URL = 'REACT_APP_STRIPE_PAYMENT_SUCCESS_URL',
  REACT_APP_ASA_DASHBOARD_URL = 'REACT_APP_ASA_DASHBOARD_URL',
  REACT_APP_ASA_DISCOUNT_PERCENT = 'REACT_APP_ASA_DISCOUNT_PERCENT',
  REACT_APP_PAYMENT_STATUS_EMAIL_URL = 'REACT_APP_PAYMENT_STATUS_EMAIL_URL',
  REACT_APP_TRANSACTION_UPDATE_URL = 'REACT_APP_TRANSACTION_UPDATE_URL',
  REACT_APP_ORGANIZATION_PRE_EMPLOYMENT_URL = 'REACT_APP_ORGANIZATION_PRE_EMPLOYMENT_URL',
  REACT_APP_ORGANIZATION_USER_ENGAGEMENT_URL = 'REACT_APP_ORGANIZATION_USER_ENGAGEMENT_URL',
  REACT_APP_ORGANIZATION_USER_COURSE_DATA_URL = 'REACT_APP_ORGANIZATION_USER_COURSE_DATA_URL',
  REACT_APP_ORGANIZATION_ALL_USER_STASTIC_URL = 'REACT_APP_ORGANIZATION_ALL_USER_STASTIC_URL',
  REACT_APP_ORGANIZATION_REMINDER_EMAIL_URL = 'REACT_APP_ORGANIZATION_REMINDER_EMAIL_URL',
  REACT_APP_UPDATED_PASSWORD_EMAIL_URL = 'REACT_APP_UPDATED_PASSWORD_EMAIL_URL',
}
