import _ from 'lodash';
import React from 'react';
interface IntakeProps {
  children: React.ReactNode;
  className?: string;
  step: number;
  progress: number;
}
export const IntakeHeader: React.FC<IntakeProps> = ({ children, step, progress }) => {
  const renderIntakeHeader = () => {
    switch (step) {
      case 1:
        return (
          <div className="text-2xl p-4 text-white ">
            <span className="font-semibold"> PRELIMINARY </span> ASSESSMENT
          </div>
        );
      case 3:
        return (
          <div className="text-2xl p-4 text-white ">
            LIFE<span className="font-semibold"> STAGE</span>
          </div>
        );
      default:
        return (
          <div className="text-2xl p-4 text-white ">
            INTAKE<span className="font-semibold"> QUESTIONS</span>
          </div>
        );
    }
  };
  return (
    <div className="flex items-center justify-center w-full">
      <div
        className={`mx-auto w-full max-w-4xl bg-white-100 py-8 px-5 shadow-xl border-l border-r relative bg-white`}
      >
        <div className="mb-2 text-center theme-gradient rounded-xl">
          <>{renderIntakeHeader()}</>
          <div className="w-3/4 bg-gray-200 rounded-full h-2.5 dark:bg-gray-100 m-auto relative bottom-[-5px]">
            <div
              className="bg-green-600 h-2.5 rounded-full dark:bg-green-500"
              style={{ width: `${_.clamp(progress, 0, 100)}%` }}
            ></div>
          </div>
        </div>

        <div>{children}</div>
      </div>
    </div>
  );
};
