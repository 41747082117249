import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import start1 from '../../assets/images/intake/step15/char1.svg';
import start2 from '../../assets/images/intake/step15/char2.svg';
import start3 from '../../assets/images/intake/step15/char3.svg';

interface ComponentProps {
  step: number;
}

export const CoreCompetency: React.FC<ComponentProps> = ({ step }) => {
  const { handleSubmit } = useForm<FormData>();
  const navigate = useNavigate();
  const submitCoreCompetency: SubmitHandler<FormData> = async () => {
    navigate(`/pre-employment/skills/step${step + 1}`);
  };
  return (
    <div className="pt-20">
      <div className="flex items-center gap-20 py-8  flex-wrap md:flex-nowrap">
        <img className=" mx-auto" src={start1} alt="" />
        <div className="start-point">
          <h4>
            <span className="sp-heading block">
              <span className="font-bold">Skills </span>
              Section
            </span>
            Let's kickstart our exploration by delving into the vital tools needed to conquer the
            job market. Have you built an impressive list of references? Ever thought about
            leveraging your network to pave the way towards your dream job? We'll navigate through
            these aspects and more to prepare you for success.
          </h4>
        </div>
      </div>
      <div className="flex items-center gap-20 py-8  flex-wrap md:flex-nowrap">
        <div className="start-point order-2 md:order-1">
          <h4 className="">
            <span className="sp-heading block">
              <span className="font-bold">Core Competencies </span>
              Assessment
            </span>
            Competencies are like the gears that make the professional world move smoothly. This
            self-assessment journey will help us understand where you excel and where you can grow,
            focusing on crucial areas like communication, teamwork, motivation, planning,
            problem-solving, professionalism, global perspective, digital technology, reliability,
            adaptability, and stress tolerance. We're here to guide you every step of the way.
          </h4>
        </div>
        <img className=" mx-auto order-1 md:order-2" src={start2} alt="" />
      </div>
      <div className="flex items-center gap-20 py-8  flex-wrap md:flex-nowrap">
        <img className=" mx-auto" src={start3} alt="" />
        <div className="start-point">
          <h4>
            For each competency, we'll walk you through a detailed understanding of what it entails,
            allowing you to self-assess and rate your confidence levels. Don't worry, you can
            complete this at your own pace. Feel free to return and conquer the remaining sections
            whenever you're ready.
            <p className="font-semibold py-8">
              Let's embark on this exciting journey of self-discovery and empowerment! Your future
              awaits, and we're here to help you build the foundation for it.
            </p>
          </h4>
        </div>
      </div>
      <div className="flex items-center gap-20 py-8 flex-wrap md:flex-nowrap">
        <h4>
          <p>
            Feel free to complete this assessment in one sitting or come back to it later. Your
            journey toward self-discovery and a bright career begins here. Let's uncover the amazing
            potential within you!
          </p>
        </h4>
      </div>
      <div className="text-center mb-16 mt-10">
        <button
          className="btn-primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(submitCoreCompetency)}
        >
          OK
        </button>
      </div>
    </div>
  );
};
