import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ExperienceAssessmentAnswer } from '../../../assessments/experience/experience.types';
import logger from '../../../services/logger';

export type ExperienceAssessmentState = {
  [answerId: string]: ExperienceAssessmentAnswer;
};

const initialState: ExperienceAssessmentState = {};

const experienceSlice = createSlice({
  name: 'formExperience',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<ExperienceAssessmentState>) => {
      logger.debug('Updating experinece assessment state.', { state, action });
      Object.assign(state, { ...action.payload });
    },
  },
});

export const { update: updateExperienceAssessmentAction } = experienceSlice.actions;
export default experienceSlice.reducer;
