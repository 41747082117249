import { insertContactUs } from '../../../services/database/form-contactus';
import { EnvironmentVariable } from '../../../utils/constants';

import { ContactUseInfo } from './form-contact-us.types';

export const dispatchSyncContactToParagon = async (dto: ContactUseInfo) => {
  const syncContactUrl = process.env[EnvironmentVariable.REACT_APP_PARAGON_CREATE_CONTACT_ENDPOINT];
  const envType = process.env[EnvironmentVariable.REACT_APP_NODE_ENV];
  if (envType === 'Prod' && syncContactUrl) {
    await fetch(`${syncContactUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ firstName: dto.name, email: dto.email }),
    });
  }
};

export const dispacthInsertContactUs = (dto: ContactUseInfo) => async (): Promise<void> => {
  await insertContactUs(dto);
};
