import { shorting_answer_options } from '../jumpstart-communicator/jumpstart-communicator.quiz';
import {
  CourseMatchingQuestion,
  CourseQuestion,
  CourseSortingQuestion,
  QuestionType,
} from '../jumpstart-communicator/jumpstart-communicator.types';

export const course_questions: CourseQuestion[] = [
  {
    id: 'd027654b-395a-401c-9630-0fbbf6401ffc',
    question_description:
      'Caspar is super organized. He always wants all the details upfront and tends to want to know every single possibility with all the pros and cons carefully laid out. He cares about the “how” of an idea and wants to understand all the necessary steps in a process. He carries a map in his back pocket when on holiday.',
    question: 'What communication style is Caspar most likely?',
    options: [
      {
        id: '28a87b8a-f4a6-49e9-a5ff-57c09502e3de',
        option: 'Action',
      },
      {
        id: 'fad6d8c0-e945-4ceb-abab-0d431b6ac121',
        option: 'Process',
      },
      {
        id: 'd6213caa-d43a-4333-8ad2-e4325d84c5d4',
        option: 'Idea',
      },
      {
        id: 'd5b4bbb4-a9e4-439d-a3fd-7a873a6dfe3f',
        option: 'People',
      },
    ],
    answers: ['fad6d8c0-e945-4ceb-abab-0d431b6ac121'],
    answer_description:
      'Caspar is most likely a process-oriented style communicator. This style likes to know all the options and they definitely don’t rush the decision-making process in the communication. ',
    type: QuestionType.RADIO,
    chapter_id: 'f7905a29-f712-4519-a529-dac4bf207f46',
    course_id: 'abb1771f-d555-47c9-961c-9bffefbd7951',
  },
  {
    id: '9b702cf9-1dbb-45d4-beea-b6f9f3a3bb6a',
    question:
      'Going back to the question about Caspar in the first section, who is a process-oriented style communicator, what are some ways that one might be able to communicate the best with him. Select all that apply. ',
    options: [
      {
        id: '3a5aca3d-ccf6-453b-8843-7b84d2fa15d6',
        option:
          'Make sure to outline the information logically: background first, explain the present situation and finally go into your desired outcomes. ',
      },
      {
        id: 'edacd046-c022-40bf-9e94-239fe3535a40',
        option: 'Don’t rush the decision-making process. ',
      },
      {
        id: '9ecfcc11-67e0-46c4-9a49-2bb3b79b1916',
        option: 'Provide options and the pros and cons behind each of them.',
      },
    ],
    answers: [
      '3a5aca3d-ccf6-453b-8843-7b84d2fa15d6',
      'edacd046-c022-40bf-9e94-239fe3535a40',
      '9ecfcc11-67e0-46c4-9a49-2bb3b79b1916',
    ],
    answer_description: 'All of these are strategies you can use when communicating with Caspar.',
    type: QuestionType.CHECKBOX,
    chapter_id: 'ac4efa31-a198-4a31-bbdf-bbe8e8d55f5b',
    course_id: 'abb1771f-d555-47c9-961c-9bffefbd7951',
  },
  {
    id: '9b6ad92b-aa12-4d3b-8eac-2bbf906d134c',
    question:
      'You asked a friend to provide you with website reference by Friday. The project deadlines have shifted, and your teacher has moved the deadline up to Thursday. Considering your friend’s action-based communication style, how do you make that request? ',
    options: [
      {
        id: '7de94084-26ba-48e4-9997-75917b73cc71',
        option:
          '“The teacher has moved deadlines forward and the project is due on Thursday because we don’t have classes on Friday. I’ll need that website reference by EOD Wednesday.”',
      },
      {
        id: '58146713-5f98-4f31-b2f5-1161dac18e6d',
        option:
          '“When is the earliest I could get that website reference from you? You can get back to me after you have a chance to review the project and assess the time commitment.” ',
      },
      {
        id: '891a0715-3177-4f07-b3b5-9b38ae32be0b',
        option:
          '“My deadlines have shifted, and it’d be a huge help to get that website reference from you by Wednesday instead. Do you feel that’s realistic with your current workload?”',
      },
      {
        id: '539ec407-f5ed-400d-b2ed-eb1bd7ada9ac',
        option:
          '“I’m going to need that website reference by Wednesday instead of Friday now. The teacher has changed the deadline and now the entire project is due earlier, and the rest of the group wants time to do a great job on the remaining work by EOD Thursday."',
      },
    ],
    answers: ['7de94084-26ba-48e4-9997-75917b73cc71'],
    answer_description:
      'This approach reflects an idea-based communication style. It connects the task (website reference) to the bigger group working and wanting to do a great job on the project.',
    type: QuestionType.RADIO,
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'abb1771f-d555-47c9-961c-9bffefbd7951',
  },
];

export const course_quiz_questions: (
  | CourseSortingQuestion
  | CourseMatchingQuestion
  | CourseQuestion
)[] = [
  {
    id: '587ca2ec-19da-4328-9d44-55894a72ca9c',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'abb1771f-d555-47c9-961c-9bffefbd7951',
    type: QuestionType.SORTING,
    question_description: `Do you understand the differences between all four communication styles? What about
    how to adapt to each? Try this sorting activity to check your understanding. Drag and
    drop each example into the correct communication style.`,
    questions: [
      {
        id: '63d53ee0-3638-4b38-9b68-f1193c195e65',
        question: 'Give an overview of project goals',
      },
      {
        id: '4636843b-8784-4389-ac05-5b2cf88b6599',
        question: 'Schedule check-in meetings',
      },
      {
        id: 'a49aac47-a1aa-4903-a7a2-875e7fd853ec',
        question: 'Break down a project into tasks',
      },
      {
        id: '9b697885-3519-43a6-ac7a-d4dfb4a109a8',
        question: 'Give clear timelines',
      },
      {
        id: '2bb49d7c-cc1d-4c95-b72a-5916c9734301',
        question: 'Give space to work independently',
      },
      {
        id: 'd23129cf-0db1-4736-b065-0dd4cde2f4f3',
        question: 'Ask about their weekend',
      },
      {
        id: 'c6dd0f46-dec4-4031-ad9e-ae7e960b88c6',
        question: 'Share a customer impact story',
      },
      {
        id: 'acf96348-3799-4daf-97f0-68592542caca',
        question: 'Summarize meeting action items',
      },
      {
        id: 'cf281fda-140e-4fb1-9fbe-6eefde83269d',
        question: 'Create a vision board',
      },
      {
        id: '43b0650b-2e53-4092-a72b-760e4f679e2d',
        question: 'Focus on the big picture',
      },
      {
        id: '7fcb3614-56aa-4fd2-bf07-cea75391dc32',
        question: 'Provide as many details as you can up front',
      },
      {
        id: '4e46dba7-6622-429b-83ef-704fc55b6d45',
        question: 'Prepare graphs for your meeting',
      },
    ],
    options: shorting_answer_options,
    answers: {
      '63d53ee0-3638-4b38-9b68-f1193c195e65': {
        id: 'd110f480-3b50-4428-b658-8eab3753e22c',
        option: 'Idea-Based',
      },
      '4636843b-8784-4389-ac05-5b2cf88b6599': {
        id: '07bb73b2-38ae-4118-993e-b651ba478751',
        option: 'People-Based',
      },
      'a49aac47-a1aa-4903-a7a2-875e7fd853ec': {
        id: '71fd1af5-603b-46c4-abd2-711a275fc06d',
        option: 'Action-Based',
      },
      '9b697885-3519-43a6-ac7a-d4dfb4a109a8': {
        id: '71fd1af5-603b-46c4-abd2-711a275fc06d',
        option: 'Action-Based',
      },
      '2bb49d7c-cc1d-4c95-b72a-5916c9734301': {
        id: 'a7a0bd25-2254-45a0-89bd-3fcbc935060b',
        option: 'Fact-Based',
      },
      'd23129cf-0db1-4736-b065-0dd4cde2f4f3': {
        id: '07bb73b2-38ae-4118-993e-b651ba478751',
        option: 'People-Based',
      },
      'c6dd0f46-dec4-4031-ad9e-ae7e960b88c6': {
        id: '07bb73b2-38ae-4118-993e-b651ba478751',
        option: 'People-Based',
      },
      'acf96348-3799-4daf-97f0-68592542caca': {
        id: '71fd1af5-603b-46c4-abd2-711a275fc06d',
        option: 'Action-Based',
      },
      'cf281fda-140e-4fb1-9fbe-6eefde83269d': {
        id: 'd110f480-3b50-4428-b658-8eab3753e22c',
        option: 'Idea-Based',
      },
      '43b0650b-2e53-4092-a72b-760e4f679e2d': {
        id: 'd110f480-3b50-4428-b658-8eab3753e22c',
        option: 'Idea-Based',
      },
      '7fcb3614-56aa-4fd2-bf07-cea75391dc32': {
        id: 'a7a0bd25-2254-45a0-89bd-3fcbc935060b',
        option: 'Fact-Based',
      },
      '4e46dba7-6622-429b-83ef-704fc55b6d45': {
        id: 'a7a0bd25-2254-45a0-89bd-3fcbc935060b',
        option: 'Fact-Based',
      },
    },
  },
  {
    id: '1407afcb-a390-4506-aed6-7c75aac1594f',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'abb1771f-d555-47c9-961c-9bffefbd7951',
    type: QuestionType.MATCHING,
    question_description: ` When you're communicating with someone with the different communication styles, it's
    important to consider the following<span className="font-bold"> DON'Ts.</span> Match
    each communication style with their corresponding
    <span className="font-bold"> DON'T</span> statement.`,
    questions: [
      {
        question: {
          id: '158de404-58de-4128-bd62-78ead71778cb',
          question:
            'Weigh down communications with the small details; a simple outline or summary is ideal.',
        },
        answer: {
          id: '71fd1af5-603b-46c4-abd2-711a275fc06d',
          option: 'Action-Based',
        },
        correct_answer: {
          id: 'd110f480-3b50-4428-b658-8eab3753e22c',
          option: 'Idea-Based',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: 'cdf56ff3-6adf-4055-9e51-871844de2e53',
          question: 'Don’t be too formal or underestimate the power of personal connection.',
        },
        answer: {
          id: 'a7a0bd25-2254-45a0-89bd-3fcbc935060b',
          option: 'Fact-Based',
        },
        correct_answer: {
          id: '07bb73b2-38ae-4118-993e-b651ba478751',
          option: 'People-Based',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: '9efe9473-42a8-4292-8768-549ffe77ec66',
          question: 'Take blunt feedback personally or waffle on decisions.',
        },
        answer: {
          id: 'd110f480-3b50-4428-b658-8eab3753e22c',
          option: 'Idea-Based',
        },
        correct_answer: {
          id: '71fd1af5-603b-46c4-abd2-711a275fc06d',
          option: 'Action-Based',
        },
        index: 2,
        matched: false,
      },
      {
        question: {
          id: 'f82c101a-d42d-4f45-95bb-3ab44dd10a84',
          question:
            'Don’t ask for an opinion or recommendation without giving them time to research.',
        },
        answer: {
          id: '07bb73b2-38ae-4118-993e-b651ba478751',
          option: 'People-Based',
        },
        correct_answer: {
          id: 'a7a0bd25-2254-45a0-89bd-3fcbc935060b',
          option: 'Fact-Based',
        },
        index: 3,
        matched: false,
      },
    ],
  },
  {
    id: '9b6ad92b-aa12-4d3b-8eac-2bbf906d134c',
    question:
      'You asked a friend to provide you with website reference by Friday. The project deadlines have shifted, and your teacher has moved the deadline up to Thursday. Considering your friend’s action-based communication style, how do you make that request? ',
    options: [
      {
        id: '7de94084-26ba-48e4-9997-75917b73cc71',
        option:
          '“The teacher has moved deadlines forward and the project is due on Thursday because we don’t have classes on Friday. I’ll need that website reference by EOD Wednesday.”',
      },
      {
        id: '58146713-5f98-4f31-b2f5-1161dac18e6d',
        option:
          '“When is the earliest I could get that website reference from you? You can get back to me after you have a chance to review the project and assess the time commitment.” ',
      },
      {
        id: '891a0715-3177-4f07-b3b5-9b38ae32be0b',
        option:
          '“My deadlines have shifted, and it’d be a huge help to get that website reference from you by Wednesday instead. Do you feel that’s realistic with your current workload?”',
      },
      {
        id: '539ec407-f5ed-400d-b2ed-eb1bd7ada9ac',
        option:
          '“I’m going to need that website reference by Wednesday instead of Friday now. The teacher has changed the deadline and now the entire project is due earlier, and the rest of the group wants time to do a great job on the remaining work by EOD Thursday."',
      },
    ],
    answers: ['7de94084-26ba-48e4-9997-75917b73cc71'],
    answer_description:
      'This approach reflects an idea-based communication style. It connects the task (website reference) to the bigger group working and wanting to do a great job on the project.',
    type: QuestionType.RADIO,
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'abb1771f-d555-47c9-961c-9bffefbd7951',
  },
];
