import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import {
  UserCourseQuestionState,
  UserQuizQuestion,
  UserQuizQuestionState,
} from '../../redux/slice/user-course-question-response';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.COURSE_QUESTION_RESPONSE;

async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

export async function getUserCourseQuestionValues(): Promise<UserCourseQuestionState> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<UserCourseQuestionState> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return result.data() ?? {};
  } catch (e) {
    logger.error('Failed to retrieve user course question values.', e);
    throw e;
  }
}
export async function getUserCourseSortingQuestionValues(): Promise<UserQuizQuestionState> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<UserQuizQuestionState> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return result.data() ?? {};
  } catch (e) {
    logger.error('Failed to retrieve user course question values.', e);
    throw e;
  }
}

export async function updateUserCourseQuestion(dto: UserCourseQuestionState): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved user course question.');
  } catch (e) {
    logger.error('Failed to update user course question.', e);
    throw e;
  }
}
export async function updateUserCourseQuizQuestion(dto: UserQuizQuestion): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved user course question.');
  } catch (e) {
    logger.error('Failed to update user course question.', e);
    throw e;
  }
}
