import { EnvironmentVariable } from '../../utils/constants';
import { getCookie, setCookie } from '../../utils/cookieUtils';

const otpExpTime = process.env[EnvironmentVariable.REACT_APP_OTP_EXP_TIME];

export const sendOtpToEmail = async () => {
  const randomOtp = Math.floor(100000 + Math.random() * 900000);
  await saveOtpToSessionStorage(randomOtp.toString());
  await isOtpVerified(false);
  return randomOtp;
};

const saveOtpToSessionStorage = async (otp: string) => {
  const tokenTime = Number(otpExpTime) * 60 * 1000;
  const sessionDetails = {
    sessionOtp: Number(otp),
    sessionExpTime: new Date().getTime() + tokenTime,
  };
  sessionStorage.setItem('sessionOtp', JSON.stringify(sessionDetails));
};
export const getOtpFromSessionStorage = async () => {
  const data = sessionStorage.getItem('sessionOtp');
  if (data) {
    return JSON.parse(data);
  }
};

export const removeOtpFromSessionStorage = async () => {
  sessionStorage.removeItem('sessionOtp');
  // sessionStorage.removeItem('sessionOtpAuthVerified');
};

export const isTokenExpired = async (otpExpTime: number) => {
  const expTime = otpExpTime;
  const currentTime = new Date().getTime();
  const diffInTime = Number(expTime) - currentTime;

  if (diffInTime <= 0) {
    return true;
  }
  return false;
};

export const isOtpVerified = async (isAuthenticated: boolean) => {
  setCookie('authVerified', JSON.stringify(isAuthenticated));
};

export const getIsOtpVerified = () => {
  const data = getCookie('authVerified');
  if (data) {
    return JSON.parse(data);
  }
};
