import { useSelector } from 'react-redux';

import { SWOTState } from '../redux/slice/swot/swot.slice';
import { SWOTReport } from '../redux/slice/swot/swot.types';
import { RootState } from '../redux/store';

export const useSWOTContext = (): SWOTReport | undefined => {
  const state: SWOTState = useSelector((state: RootState) => state.swot);
  return state.report;
};
