import charImage1 from '../../assets/images/courses/perception-understanding/chapter2/char/perciption_chap2_img1.png';
import charImage11 from '../../assets/images/courses/perception-understanding/chapter2/char/perciption_chap2_img11.png';
import charImage2 from '../../assets/images/courses/perception-understanding/chapter2/char/perciption_chap2_img3.png';
import charImage3 from '../../assets/images/courses/perception-understanding/chapter2/char/perciption_chap2_img8.png';
import intro from '../../assets/images/courses/perception-understanding/chapter2/intro.png';
import {
  CourseChatQuestion,
  CourseMatchingQuestion,
  CourseQuestion,
  CourseSortingQuestion,
  QuestionType,
} from '../jumpstart-communicator/jumpstart-communicator.types';

export type CourseBasicDetails = {
  course_id: string;
  chapter_id: string;
};
export const course_basic_details = [
  {
    // chapter 2
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    chapter_id: 'b3045f7e-05f8-422e-a3f2-b3806c145d80',
  },
  {
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    chapter_id: '58181a06-5fb6-4804-a855-658c462e2230',
  },
  {
    // chapter 8
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    chapter_id: 'fedbc4ce-86ce-4d6d-9022-48fe7a674cd5',
  },
];
export const course_chat_questions: CourseChatQuestion[] = [
  // 0
  {
    title: 'Here’s a simple example of a few moves up the ladder:',
    content: 'Jane arranges to meet Mary for coffee at 10.30am.',
    id: '46b923f9-6d54-4fe5-94df-b093eba9e021',
    question: '',
    question_img: intro,
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: 'b56d8d23-4081-4293-9c3a-be1a5fca2a2f',
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
  // 1
  {
    id: '400a9165-b5c9-4962-874e-de9bc4b7ff7b',
    question:
      'Mary is late and does not explain why. In fact, she doesn’t seem to have noticed that she’s late at all. This is an example of which rung on the ladder?',
    question_img: charImage1,
    options: [
      {
        id: 'a1334757-e7d0-4084-8b18-5c135eed8128',
        option: 'Conclusion',
        description: 'Incorrect, this is an example of the observation rung.',
        img: charImage3,
      },
      {
        id: 'ca2c8cac-9ef6-4fd5-af23-84b2f5cd1e04',
        option: 'Observation',
        description: 'Correct, this is an example of the observation rung.',
        img: charImage2,
      },
    ],
    answer: '',
    answers: ['a1334757-e7d0-4084-8b18-5c135eed8128', 'ca2c8cac-9ef6-4fd5-af23-84b2f5cd1e04'],
    answer_description:
      'I am trying. My silence isn’t because of a lack of effort. You don’t understand what this feels like for me.',
    type: QuestionType.CHAT,
    chapter_id: 'b56d8d23-4081-4293-9c3a-be1a5fca2a2f',
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
  // 2
  {
    id: 'f28579cd-5149-4492-9c15-add68f61f47e',
    question:
      'Jane decides that Mary simply couldn’t be bothered to turn up on time, and that Mary values her own time more highly than Jane’s. Which rung of the ladder would Jane be on at this point?',
    question_img: charImage1,
    options: [
      {
        id: 'c3010144-e5c5-435a-be90-576d8812eea2',
        option: 'Assumptions',
        description:
          'Correct, Jane is making assumptions based on the meanings she decided on regarding Mary being late.',
        img: charImage2,
      },
      {
        id: '310f937a-6325-410d-afe6-8e0805356026',
        option: 'Actions',
        description:
          'Incorrect, Jane has not taken action yet. She is making assumptions based on the meaning she has placed based on her selected data.',
        img: charImage3,
      },
    ],
    answer: '',
    answers: ['c3010144-e5c5-435a-be90-576d8812eea2', '310f937a-6325-410d-afe6-8e0805356026'],
    answer_description:
      'I am trying. My silence isn’t because of a lack of effort. You don’t understand what this feels like for me.',
    type: QuestionType.CHAT,
    chapter_id: 'b56d8d23-4081-4293-9c3a-be1a5fca2a2f',
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
  // 3
  {
    id: '53129728-a70e-4351-8828-a98bdfeeb58b',
    question:
      'Jane concludes that it’s not worth bothering to meet up in future, because Mary obviously doesn’t want to see her. Which rung level would Jane be at this point?',
    question_img: charImage1,
    options: [
      {
        id: '49aea3b2-dac4-4bb5-beef-ab7a433acd1e',
        option: 'Conclusion',
        description:
          'Correct, Jane has drawn to a conclusion, based on her previous assumption, that Mary doesn"t want to see her.',
        img: charImage2,
      },
      {
        id: '8a6b7c60-835d-4d98-91c7-09a57afdaffa',
        option: 'Actions',
        description:
          'Incorrect, Jane has not taken any action yet. She has drawn to the conclusion, based on her previous assumption, that Mary doesn"t want to see her.',
        img: charImage11,
      },
    ],
    answer: '',
    answers: ['49aea3b2-dac4-4bb5-beef-ab7a433acd1e', '8a6b7c60-835d-4d98-91c7-09a57afdaffa'],
    answer_description:
      'I am trying. My silence isn’t because of a lack of effort. You don’t understand what this feels like for me.',
    type: QuestionType.CHAT,
    chapter_id: 'b56d8d23-4081-4293-9c3a-be1a5fca2a2f',
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
  // 4
  {
    id: 'cdd3b6cb-53c8-4d4d-bfa3-fda704d14feb',
    question: 'When Mary suggests meeting the next week, Jane makes an excuse to avoid it.',
    question_img: charImage1,
    options: [
      {
        id: '47e5db8b-8f5c-4049-890d-b72587b9753d',
        option: 'Conclusion',
        description:
          'Incorrect, Jane has now taken action by making an excuse to avoid a meeting next week.',
        img: charImage11,
      },
      {
        id: 'efcb9815-cb92-46c7-830f-6dfbfc0cf8c4',
        option: 'Actions',
        description:
          'Correct, Jane has now taken action by making an excuse to avoid meeting with Mary again.',
        img: charImage2,
      },
    ],
    answer: '',
    answers: ['47e5db8b-8f5c-4049-890d-b72587b9753d', 'efcb9815-cb92-46c7-830f-6dfbfc0cf8c4'],
    answer_description:
      'I am trying. My silence isn’t because of a lack of effort. You don’t understand what this feels like for me.',
    type: QuestionType.CHAT,
    chapter_id: 'b56d8d23-4081-4293-9c3a-be1a5fca2a2f',
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
  // 5
  {
    title: 'Summary',
    content:
      'Jane started by selecting observable data, translated it based on the meaning she made  of this data, made assumptions, then drew conclusions, and took action . ',
    id: '738932af-19fb-412e-8cbd-9d988e4f7a2f',
    question: '',
    question_img: '',
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: 'b56d8d23-4081-4293-9c3a-be1a5fca2a2f',
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
];

export const course_questions: CourseQuestion[] = [
  {
    id: '50aa6208-69f1-48ea-914d-cb744760f77f',
    question: 'What might make it more likely that a “bad day” behavior will show up?',
    options: [
      {
        id: '4629f666-d0c0-47bb-bdfe-64b21d2a0108',
        option: 'under stress',
      },
      {
        id: '5f6c09e8-cbaa-4d05-a807-622285baf438',
        option: 'tired',
      },
      {
        id: '2293a9db-bfb0-4b8e-9b60-40660447834e',
        option: 'moving quickly',
      },
      {
        id: 'e4acf184-465f-4f14-96a4-7b4b1554fab0',
        option: 'ill (sick)',
      },
      {
        id: 'd6d265a0-1e7d-410a-958f-9a9e67c3128f',
        option: 'All the above',
      },
    ],
    answers: ['d6d265a0-1e7d-410a-958f-9a9e67c3128f'],
    wrong_answer_description:
      'Avoiding Bad Day = Self-awareness + monitoring our stress level can keep these in check.',
    answer_description:
      'Avoiding Bad Day = Self-awareness + monitoring our stress level can keep these in check.',
    type: QuestionType.RADIO,
    chapter_id: 'e9f653d6-0b97-4206-ac17-53ca9c35b661',
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
];

export const course_sorting_matching_questions: CourseSortingQuestion[] = [
  {
    id: 'ad009ad9-9762-421a-92c7-9dfece1fe489',
    chapter_id: 'e9f653d6-0b97-4206-ac17-53ca9c35b661',
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    type: QuestionType.SORTING,
    questions: [
      {
        id: 'f200e2aa-60d3-409b-8c1e-f993dbb16908',
        question: 'I don’t do yellow',
      },
      {
        id: '5fe11e72-3e34-408f-883c-76ead85da55b',
        question: 'When we draw on our yellow energy',
      },
      {
        id: 'bf5d898f-3563-4f04-83bf-4eb23b83a4ca',
        question: 'Thinking about this from a green energy perspective',
      },
      {
        id: '8cf4422d-09b5-4281-94c1-ef979dd6f5c8',
        question: 'With my dominant blue energy',
      },
      {
        id: '8b630852-5920-4b6d-941d-ecf2c88e81b8',
        question: 'Reds are so',
      },
      {
        id: '6a53e391-5df3-40eb-8a58-1ce04e0829fc',
        question: 'Blue people',
      },
      {
        id: 'b0793783-faa4-4034-8777-5600fa3a0936',
        question: 'Our preference for using red energy',
      },
      {
        id: '1a2e200e-1733-448e-8c46-c1362c4a6aa5',
        question: 'Greens always',
      },
    ],
    options: [
      {
        id: '15765d82-abe4-46cd-b890-df933911da1e',
        option: 'Disrespectful statements',
      },
      {
        id: '674bd70c-5988-46bc-b40b-ba1249b791c5',
        option: 'Respectful statements',
      },
    ],
    answers: {
      'f200e2aa-60d3-409b-8c1e-f993dbb16908': {
        id: '15765d82-abe4-46cd-b890-df933911da1e',
        option: 'Disrespectful statements',
      },
      '5fe11e72-3e34-408f-883c-76ead85da55b': {
        id: '674bd70c-5988-46bc-b40b-ba1249b791c5',
        option: 'Respectful statements',
      },
      'bf5d898f-3563-4f04-83bf-4eb23b83a4ca': {
        id: '674bd70c-5988-46bc-b40b-ba1249b791c5',
        option: 'Respectful statements',
      },
      '8cf4422d-09b5-4281-94c1-ef979dd6f5c8': {
        id: '674bd70c-5988-46bc-b40b-ba1249b791c5',
        option: 'Respectful statements',
      },
      '8b630852-5920-4b6d-941d-ecf2c88e81b8': {
        id: '15765d82-abe4-46cd-b890-df933911da1e',
        option: 'Disrespectful statements',
      },
      '6a53e391-5df3-40eb-8a58-1ce04e0829fc': {
        id: '15765d82-abe4-46cd-b890-df933911da1e',
        option: 'Disrespectful statements',
      },
      'b0793783-faa4-4034-8777-5600fa3a0936': {
        id: '674bd70c-5988-46bc-b40b-ba1249b791c5',
        option: 'Respectful statements',
      },
      '1a2e200e-1733-448e-8c46-c1362c4a6aa5': {
        id: '15765d82-abe4-46cd-b890-df933911da1e',
        option: 'Disrespectful statements',
      },
    },
  },
];

export const course_matching_questions: CourseMatchingQuestion[] = [
  {
    id: '4927381b-068a-4014-9859-ddcef8e25006',
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    chapter_id: 'fedbc4ce-86ce-4d6d-9022-48fe7a674cd5', // changed
    question_title: 'Adapting & Connecting – Do',
    question_description: 'Drag and drop each statement option to its appropriate color code.',
    type: QuestionType.MATCHING,
    questions: [
      {
        question: {
          id: '261cba09-9485-4703-960c-d7b6e2966984',
          question: 'Cool Blue',
        },
        answer: {
          id: 'b8c67218-008d-41db-9790-0518151f1f55',
          option: 'Be friendly and sociable',
        },
        correct_answer: {
          id: '3d4256a4-69d3-4b4a-84e8-89f04bf86ffa',
          option: ' Be well prepared and thorough',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: '5926d989-a90b-40fb-ac6f-071c92768563',
          question: 'Fiery Red',
        },
        answer: {
          id: '865b553f-107c-4df3-b0ee-49e9287c8d39',
          option: 'Be patient and supportive',
        },
        correct_answer: {
          id: '2373ccb2-7e39-42d3-9369-22c5bbd629ea',
          option: 'Be direct and to the point',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: '1718e628-173c-4ad1-81f1-e00a2853e2d9',
          question: 'Sunshine Yellow',
        },
        answer: {
          id: '2373ccb2-7e39-42d3-9369-22c5bbd629ea',
          option: 'Be direct and to the point',
        },
        correct_answer: {
          id: 'b8c67218-008d-41db-9790-0518151f1f55',
          option: 'Be friendly and sociable',
        },
        index: 2,
        matched: false,
      },
      {
        question: {
          id: '91689069-3bcc-49d9-b467-e4c130d7e818',
          question: 'Earth Green',
        },
        answer: {
          id: '3d4256a4-69d3-4b4a-84e8-89f04bf86ffa',
          option: 'Be well prepared and thorough',
        },
        correct_answer: {
          id: '865b553f-107c-4df3-b0ee-49e9287c8d39',
          option: 'Be patient and supportive',
        },
        index: 3,
        matched: false,
      },
    ],
  },
  {
    id: '076f015b-f9d9-4f56-85b7-688a8e80aad6',
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    chapter_id: 'fedbc4ce-86ce-4d6d-9022-48fe7a674cd5', // changed
    question_title: 'Adapting & Connecting –  Don’t',
    question_description: 'Drag and drop each statement option to its appropriate color code.',
    type: QuestionType.MATCHING,
    questions: [
      {
        question: {
          id: '0e38ce0e-872b-4afa-8658-9db249b0a3f7',
          question: 'Cool Blue',
        },
        answer: {
          id: '50376b70-af94-4d37-be60-d983d0ced722',
          option: 'Do not be flippant on important issues',
        },
        correct_answer: {
          id: '50376b70-af94-4d37-be60-d983d0ced722',
          option: 'Do not be flippant on important issues',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: 'cfbf3bb5-057a-486a-9340-a640ea00a802',
          question: 'Fiery Red',
        },
        answer: {
          id: '52066ca2-617a-423a-b604-f85063f235bd',
          option: 'Do not push them to make quick decisions',
        },
        correct_answer: {
          id: 'b6a5b286-da24-49f2-9a46-e615295652e2',
          option: 'Do not hesitate or waffle',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: '1bbb562a-fde3-4380-9bbf-261fb487c11a',
          question: 'Sunshine Yellow',
        },
        answer: {
          id: 'b6a5b286-da24-49f2-9a46-e615295652e2',
          option: 'Do not hesitate or waffle',
        },
        correct_answer: {
          id: '0c1cfe8d-9258-4204-b6b2-fde439ada8db',
          option: 'Do not tie them down with routine',
        },
        index: 2,
        matched: false,
      },
      {
        question: {
          id: 'df32e90c-d4c1-4a4d-87f7-9a602ba87d71',
          question: 'Earth Green',
        },
        answer: {
          id: '0c1cfe8d-9258-4204-b6b2-fde439ada8db',
          option: 'Do not tie them down with routine',
        },
        correct_answer: {
          id: '52066ca2-617a-423a-b604-f85063f235bd',
          option: 'Do not push them to make quick decisions',
        },
        index: 3,
        matched: false,
      },
    ],
  },
];

export const course_quiz_questions: (
  | CourseSortingQuestion
  | CourseMatchingQuestion
  | CourseQuestion
)[] = [
  {
    id: '17315200-15b5-4dff-9db5-1122c86352aa',
    chapter_id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b', // changed
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    question_title: '1. Sorting Exercise',
    question_description:
      'Do you understand the differences between introverted and extroverted? Try this sorting activity to check your understanding. Drag and drop each statement into the correct energy type. ',
    type: QuestionType.SORTING,
    questions: [
      {
        id: 'ac2f8c7a-318e-4f0d-a2e3-d93872bf935b',
        question:
          'Ty prefers to think about problems on his own vs. talking about them with others',
      },
      {
        id: 'aef7fbcb-efbd-4b11-87bd-45230e590b2f',
        question: 'Carl is typically very open and willing to share his thoughts and feelings.',
      },
      {
        id: '66e3ad47-6dd4-4207-9187-32984194dc98',
        question: 'Anderson doesn’t like or need to spend too much time alone',
      },
      {
        id: '1a9ec7fb-2602-49e5-a11c-3e6cc6a86109',
        question: 'Evan is sometimes perceived as closed-off and aloof.',
      },
      {
        id: '17de130a-4d8b-4157-a180-bbd8353b7eb1',
        question:
          'When Samuel is stressed, he likes to be by himself and in the comfort of silence',
      },
      {
        id: 'e0e23be8-a2de-4576-a330-4baba09228b7',
        question: 'Jennifer has a wide circle of friends, loves to socialize, life of the party',
      },
    ],
    options: [
      {
        id: 'cda8aa8f-fd95-4278-bafa-2bd6d6a34533',
        option: 'Introverted',
      },
      {
        id: '84c89f35-a06c-47a0-a17d-6657b9bff5dd',
        option: 'Extroverted',
      },
    ],
    answers: {
      'ac2f8c7a-318e-4f0d-a2e3-d93872bf935b': {
        id: 'cda8aa8f-fd95-4278-bafa-2bd6d6a34533',
        option: 'Introverted',
      },
      'aef7fbcb-efbd-4b11-87bd-45230e590b2f': {
        id: '84c89f35-a06c-47a0-a17d-6657b9bff5dd',
        option: 'Extroverted',
      },
      '66e3ad47-6dd4-4207-9187-32984194dc98': {
        id: '84c89f35-a06c-47a0-a17d-6657b9bff5dd',
        option: 'Extroverted',
      },
      '1a9ec7fb-2602-49e5-a11c-3e6cc6a86109': {
        id: 'cda8aa8f-fd95-4278-bafa-2bd6d6a34533',
        option: 'Introverted',
      },
      '17de130a-4d8b-4157-a180-bbd8353b7eb1': {
        id: 'cda8aa8f-fd95-4278-bafa-2bd6d6a34533',
        option: 'Introverted',
      },
      'e0e23be8-a2de-4576-a330-4baba09228b7': {
        id: '84c89f35-a06c-47a0-a17d-6657b9bff5dd',
        option: 'Extroverted',
      },
    },
  },
  {
    id: 'e33f2e95-4485-4470-8668-e48c7c33e0e0',
    question:
      '2. Which level of inference (rung) best characterizes the following statement: "My friends must not be interested in my ideas because they are looking at their phones."',
    question_description: '',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'bfcd4197-7296-4852-b8a6-d1a42d878606',
        description: 'The correct response is  assumptions.',
        option: 'Data and facts',
      },
      {
        id: '2f8353ba-4138-424c-8665-3b6567f8f1b2',
        description: 'The correct response is  assumptions.',
        option: 'Selected data',
      },
      {
        id: '2ee084b2-1392-494f-8187-c426f96ec4dd',
        option: 'Assumptions',
        description: 'The correct response is  assumptions.',
      },
      {
        id: 'd4ad3c12-7d1c-46e3-a19e-e3387699e888',
        option: 'Actions',
        description: 'The correct response is  assumptions.',
      },
    ],
    answers: ['2ee084b2-1392-494f-8187-c426f96ec4dd'],
    wrong_answer_description: 'The correct response is  assumptions.',
    answer_description: 'The correct response is  assumptions.',
    chapter_id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b', // changed
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
  {
    id: '85a2619e-b366-4827-a94d-432ca65d2ed0',
    question:
      '3. "There are four boys and three girls in the classroom" belongs in which level (rung) on the ladder of inference?',
    question_description: '',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'ae5eb8d3-3563-40c4-8b27-f701693e3236',
        description: 'The correct response is  data and facts.',
        option: 'Data and facts',
      },
      {
        id: 'ea293f4f-93e2-4542-b190-aa1d1d0c364d',
        description: 'The correct response is  data and facts.',
        option: 'Selected data',
      },
      {
        id: '76031800-2b70-4d3e-a0e1-55cb059c6756',
        option: 'Assumptions',
        description: 'The correct response is  data and facts.',
      },
      {
        id: '11f07e77-4fc2-46fc-bdd8-2347eb3e56dc',
        option: 'Actions',
        description: 'The correct response is  data and facts.',
      },
    ],
    answers: ['ae5eb8d3-3563-40c4-8b27-f701693e3236'],
    wrong_answer_description: 'The correct response is  data and facts.',
    answer_description: 'The correct response is  data and facts.',
    chapter_id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b', // changed
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
  {
    id: '9342fed9-dda4-4f8c-905a-944f71ad31f6',
    chapter_id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b', // changed
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    question_description:
      'The three correct responses include:<ul> <li> become more aware of your own thinking and reasoning (reflection, or reflective practice);</li> <li> make sure that others understand your thinking and reasoning (advocacy);</li> <li> ask questions of others about what they are thinking, and test your assumptions (inquiry).</li> </ul>',
    question:
      '4. Select three ways you can change to improve the way you communicate and avoid you or others climbing the ladder of inference:',
    options: [
      {
        id: '45f6f641-b228-479b-8c3b-251312178066',
        description:
          'The three correct responses include:<ul> <li> become more aware of your own thinking and reasoning (reflection, or reflective practice);</li> <li> make sure that others understand your thinking and reasoning (advocacy);</li> <li> ask questions of others about what they are thinking, and test your assumptions (inquiry).</li> </ul>',

        option: 'Taking action based on the meaning you made from what your observed.',
      },
      {
        id: 'a42df563-a281-4583-b1b0-f2f2bfd05e57',
        description:
          'The three correct responses include:<ul> <li> become more aware of your own thinking and reasoning (reflection, or reflective practice);</li> <li> make sure that others understand your thinking and reasoning (advocacy);</li> <li> ask questions of others about what they are thinking, and test your assumptions (inquiry).</li> </ul>',

        option:
          'Become more aware of your own thinking and reasoning (reflection, or reflective practice).',
      },
      {
        id: '5216a779-c954-4ce3-b154-ccfabc8529d1',
        description:
          'The three correct responses include:<ul> <li> become more aware of your own thinking and reasoning (reflection, or reflective practice);</li> <li> make sure that others understand your thinking and reasoning (advocacy);</li> <li> ask questions of others about what they are thinking, and test your assumptions (inquiry).</li> </ul>',

        option: 'Make sure that others understand your thinking and reasoning (advocacy).',
      },
      {
        id: 'f4460d90-a55e-410a-9f2f-de717fa380ba',
        description:
          'The three correct responses include:<ul> <li> become more aware of your own thinking and reasoning (reflection, or reflective practice);</li> <li> make sure that others understand your thinking and reasoning (advocacy);</li> <li> ask questions of others about what they are thinking, and test your assumptions (inquiry).</li> </ul>',

        option:
          'Ask questions of others about what they are thinking, and test your assumptions (inquiry).',
      },
    ],
    answers: ['45f6f641-b228-479b-8c3b-2513121780660', 'f4460d90-a55e-410a-9f2f-de717fa380ba'],
    answer_description:
      'The three correct responses include:<ul> <li> become more aware of your own thinking and reasoning (reflection, or reflective practice);</li> <li> make sure that others understand your thinking and reasoning (advocacy);</li> <li> ask questions of others about what they are thinking, and test your assumptions (inquiry).</li> </ul>',

    type: QuestionType.CHECKBOX,
  },
  {
    id: 'b42aa43a-7a80-42dc-8373-182936ee88e4',
    question:
      '5. Our beliefs loop back to affect how we selectively filter our experiences. We see the world not as it is, but as we believe it to be',
    question_description: '',
    type: QuestionType.RADIO,
    options: [
      {
        id: '6d831187-58cc-438f-9e59-566928741f2a',
        description: 'The statement is true.',
        option: 'True',
      },
      {
        id: 'e1a58fef-2814-42ec-8171-3870fdad858d',
        description: 'The statement is true.',
        option: 'False',
      },
    ],
    answers: ['6d831187-58cc-438f-9e59-566928741f2a'],
    wrong_answer_description: 'The statement is true.',
    answer_description: 'The statement is true.',
    chapter_id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b', // changed
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
  {
    id: '8b599293-6b1f-4b50-8c5e-1f0b5e0aee33',
    question:
      '6. When we act on a situation in a way that accords with our beliefs (and our underlying assumptions), we may create circumstances that only serve to reinforce faulty assumptions.',
    question_description: '',
    type: QuestionType.RADIO,
    options: [
      {
        id: '53456fc3-bbaa-4ce0-ac28-0149d6f2f881',
        description: 'The statement is true.',
        option: 'True',
      },
      {
        id: 'f62b9348-d71e-4258-9826-3d978df92f20',
        description: 'The statement is true.',
        option: 'False',
      },
    ],
    answers: ['53456fc3-bbaa-4ce0-ac28-0149d6f2f881'],
    wrong_answer_description: 'The statement is true.',
    answer_description: 'The statement is true.',
    chapter_id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b', // changed
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
  },
  {
    id: '61719e88-5f57-4c61-ab97-7ea859096898',
    chapter_id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b', // changed
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    question_title: '7. Matching Activity',
    question_description: 'Match the color energy with it’s corresponding learning style.',
    type: QuestionType.MATCHING,

    answer_description:
      'Yellow energy - Prefer to learn with others and have "hands on" experience Green energy - Prefer to learn by reflecting on information gathered Blue energy - Prefer to learn without any distractions so you can concentrate Red energy - Prefer to learn practical information you can use right away',
    questions: [
      {
        question: {
          id: '63bc9cd8-3d3f-4eeb-aa05-94579ec9e56f',
          question: 'Prefer to learn with others and have "hands on" experience',
        },
        answer: {
          id: '4cf657d6-1bbf-475b-979a-d75453c0479b',
          option: 'Blue energy',
        },
        correct_answer: {
          id: 'ae649d04-e78d-48ae-bd61-a5c7f8424b04',
          option: 'Yellow energy',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: '1911f224-7d00-4e5e-bd46-40c439b6f422',
          question: 'Prefer to learn by reflecting on information gathered',
        },
        answer: {
          id: 'ae649d04-e78d-48ae-bd61-a5c7f8424b04',
          option: 'Yellow energy',
        },
        correct_answer: {
          id: '810cd353-16c6-4029-a45a-ca286696bb98',
          option: 'Green energy',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: '23c5b284-9ed3-446b-b111-246f2fc3feee',
          question: 'Prefer to learn without any distractions so you can concentrate',
        },
        answer: {
          id: '8998ea25-f1fa-4f83-988d-d5d75a60054b',
          option: 'Red energy',
        },
        correct_answer: {
          id: '8998ea25-f1fa-4f83-988d-d5d75a60054b',
          option: 'Red energy',
        },
        index: 2,
        matched: false,
      },
      {
        question: {
          id: 'c77d06d6-45f5-4902-8f46-005672301603',
          question: 'Prefer to learn practical information you can use right away',
        },
        answer: {
          id: '810cd353-16c6-4029-a45a-ca286696bb98',
          option: 'Green energy',
        },
        correct_answer: {
          id: '4cf657d6-1bbf-475b-979a-d75453c0479b',
          option: 'Blue energy',
        },
        index: 3,
        matched: false,
      },
    ],
  },
  {
    id: 'c58d4179-1482-434c-a76f-78dd2d98b4f6',
    chapter_id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b', // changed
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    question_description: '',
    question: '8. What are the Good Day behaviors for a Fiery Red energy? (select all that apply)',
    options: [
      {
        id: '78490d89-9e8d-42eb-ab8d-b43120e6f981',
        description:
          'The correct responses include: Competitive, Demanding, Strong-willed, Determined',

        option: 'Competitive',
      },
      {
        id: '3df23100-d415-42cc-86b5-dccd0540b85e',
        description:
          'The correct responses include: Competitive, Demanding, Strong-willed, Determined',

        option: 'Demanding',
      },
      {
        id: 'b772a442-91d6-4715-bb37-bdd126c5f5ae',
        description:
          'The correct responses include: Competitive, Demanding, Strong-willed, Determined',

        option: 'Enthusiastic',
      },
      {
        id: '3273296e-b85e-40b2-85b8-35aa79cc89bc',
        description:
          'The correct responses include: Competitive, Demanding, Strong-willed, Determined',

        option: 'Strong-willed',
      },
      {
        id: 'bb857c8c-ff08-41f6-826b-8bf51a1b9941',
        description:
          'The correct responses include: Competitive, Demanding, Strong-willed, Determined',

        option: 'Determined',
      },
      {
        id: '3a6dcb06-2bf7-4484-b691-aff6b01c96ac',
        description:
          'The correct responses include: Competitive, Demanding, Strong-willed, Determined',

        option: 'Deliberate',
      },
    ],
    answers: [
      '78490d89-9e8d-42eb-ab8d-b43120e6f981',
      'bb857c8c-ff08-41f6-826b-8bf51a1b9941',
      '3df23100-d415-42cc-86b5-dccd0540b85e',
      '3273296e-b85e-40b2-85b8-35aa79cc89bc',
    ],
    answer_description:
      'The correct responses include: Competitive, Demanding, Strong-willed, Determined',

    type: QuestionType.CHECKBOX,
  },
  {
    id: 'a421f179-52d8-468d-a051-f8c290c47de0',
    chapter_id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b', // changed
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    question_description: '',
    question:
      '9. What are the Good Day behaviors for a Sunshine Yellow energy? (select all that apply)',
    options: [
      {
        id: '88211cdd-92fa-4d38-b708-ef7f3a63b8b5',
        description:
          'The correct responses include: Sociable, Dynamic, Enthusiastic, and Pursuasive.',

        option: 'Sociable',
      },
      {
        id: '323d7a14-05cf-4f71-a1d0-ef8146dd7afe',
        description:
          'The correct responses include: Sociable, Dynamic, Enthusiastic, and Pursuasive.',

        option: 'Dynamic',
      },
      {
        id: '616dac86-c00e-412d-9191-69e04c484fd8',
        description:
          'The correct responses include: Sociable, Dynamic, Enthusiastic, and Pursuasive.',

        option: 'Enthusiastic',
      },
      {
        id: 'ad770f26-83d8-4845-ac08-7d64d015cd78',
        description:
          'The correct responses include: Sociable, Dynamic, Enthusiastic, and Pursuasive.',

        option: 'Persuasive',
      },
      {
        id: '8beb17f7-3973-4107-840e-f7ca335514b1',
        description:
          'The correct responses include: Sociable, Dynamic, Enthusiastic, and Pursuasive.',

        option: 'Determined',
      },
      {
        id: '79c5fec5-34ba-4e7c-9afa-bc83e4b3f5b8',
        description:
          'The correct responses include: Sociable, Dynamic, Enthusiastic, and Pursuasive.',

        option: 'Patient',
      },
    ],
    answers: [
      '88211cdd-92fa-4d38-b708-ef7f3a63b8b5',
      '323d7a14-05cf-4f71-a1d0-ef8146dd7afe',
      '616dac86-c00e-412d-9191-69e04c484fd8',
      'ad770f26-83d8-4845-ac08-7d64d015cd78',
    ],
    answer_description:
      'The correct responses include: Sociable, Dynamic, Enthusiastic, and Pursuasive.',

    type: QuestionType.CHECKBOX,
  },
  {
    id: '8e09b922-65c7-46c3-ad30-99510fb52a45',
    chapter_id: '2801ca8a-e21f-499f-9271-bd0b30b24a5b', // changed
    course_id: 'e38f6a77-5c97-4118-92c8-e080a0111bd7',
    question_title: '10. Sorting Activity',
    question_description:
      'Each color energy can exhibit stress differently. Try this sorting activity to check your understanding. Drag and drop each behavior into the correct color energy.',
    type: QuestionType.SORTING,
    questions: [
      {
        id: 'd585d554-4e03-4ed4-869c-703c549ac886',
        question: 'Impatient',
      },
      {
        id: '4b21f0e4-4401-4725-9aae-4d7b09b519ca',
        question: 'Nit picking',
      },
      {
        id: '9107b6be-8331-475b-b310-61e77233660c',
        question: 'Become aggressive',
      },
      {
        id: 'a0cd2b52-0517-4c91-a048-672a6f29ff85',
        question: 'Become silent, withdrawn or hurt',
      },
      {
        id: 'f773429d-0154-4aef-a11b-00b06482194c',
        question: 'Stubborn and resistant',
      },
      {
        id: 'bc823726-c4fb-4c6f-9e22-196b130a1de4',
        question: 'Becomes questioning and deliberate',
      },
      {
        id: '94400e2d-5513-4eda-b7dc-e0b6281f964b',
        question: 'Argumentative',
      },
      {
        id: '2a8cbcab-f178-4e56-a3a7-a48dc7d44d55',
        question: 'Over responsive',
      },
    ],
    options: [
      {
        id: 'c8175787-c79d-4a0d-a3cc-f9bfc7bc3904',
        option: 'Yellow Energy',
      },
      {
        id: '0f928606-9431-4959-bf11-82bad3301595',
        option: 'Blue Energy',
      },
      {
        id: '0f65db44-0830-430c-abcc-3c8ee63bbac7',
        option: 'Red Energy',
      },
      {
        id: '0f2c921c-dbb9-4a0e-963e-f52501f55117',
        option: 'Green Energy',
      },
    ],
    answers: {
      'd585d554-4e03-4ed4-869c-703c549ac886': {
        id: '0f65db44-0830-430c-abcc-3c8ee63bbac7',
        option: 'Red Energy',
      },
      '4b21f0e4-4401-4725-9aae-4d7b09b519ca': {
        id: '0f928606-9431-4959-bf11-82bad3301595',
        option: 'Blue Energy',
      },
      '9107b6be-8331-475b-b310-61e77233660c': {
        id: '0f65db44-0830-430c-abcc-3c8ee63bbac7',
        option: 'Red Energy',
      },
      'a0cd2b52-0517-4c91-a048-672a6f29ff85': {
        id: '0f2c921c-dbb9-4a0e-963e-f52501f55117',
        option: 'Green Energy',
      },
      'f773429d-0154-4aef-a11b-00b06482194c': {
        id: '0f2c921c-dbb9-4a0e-963e-f52501f55117',
        option: 'Green Energy',
      },
      'bc823726-c4fb-4c6f-9e22-196b130a1de4': {
        id: '0f928606-9431-4959-bf11-82bad3301595',
        option: 'Blue Energy',
      },
      '94400e2d-5513-4eda-b7dc-e0b6281f964b': {
        id: 'c8175787-c79d-4a0d-a3cc-f9bfc7bc3904',
        option: 'Yellow Energy',
      },
      '2a8cbcab-f178-4e56-a3a7-a48dc7d44d55': {
        id: 'c8175787-c79d-4a0d-a3cc-f9bfc7bc3904',
        option: 'Yellow Energy',
      },
    },
  },
];
