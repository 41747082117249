import { deleteField, doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { IntakeFormState } from '../../redux/slice/form-intake';
import {
  IntakeFormAgeRangeDTO,
  IntakeFormCareerConstraintsDTO,
  IntakeFormCareerPlanDTO,
  IntakeFormEducationDTO,
  IntakeFormLanguageProficiencyDTO,
  IntakeFormWorkHistoryDTO,
  OrganizationDTO,
  PersonalInfoDTO,
} from '../../redux/slice/form-intake/form-intake.types';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.INTAKE_RESPONSES;

/**
 * upsert a record into the intake from responses
 */
async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

/**
 * updates `undefined` properties in a DTO with firestore's `deleteField`
 *
 * if `undefined` properties are attempted to be set, firestore throws an error
 * this method is used to intentionally unset values
 *
 * @tutorial https://firebase.google.com/docs/firestore/manage-data/delete-data#fields
 */
function replaceUndefinedWithDeleteField<T extends Record<string, unknown>>(dto: T): T {
  const transformedDTO = Object.keys(dto).reduce(
    (updated: Partial<T>, key: string): Partial<T> => ({
      ...updated,
      [key]: dto[key] === undefined ? deleteField() : dto[key],
    }),
    {},
  );
  return transformedDTO as T;
}

/**
 * retrieves the values of the previously inputted intake form if available
 */
export async function getIntakeFormValues(): Promise<IntakeFormState> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<IntakeFormState> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return result.data() ?? {};
  } catch (e) {
    logger.error('Failed to retrieve in take form values.', e);
    throw e;
  }
}

/**
 * update a user's age in the intake form
 */
export async function updateIntakeFormAge(dto: IntakeFormAgeRangeDTO): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved intake form update: age.');
  } catch (e) {
    logger.error('Failed to update intake form: ageRange.', e);
    throw e;
  }
}

/**
 * update a user's personal information
 */
export async function updatePersonalInfo(dto: PersonalInfoDTO): Promise<void> {
  try {
    const modifiedDTO: PersonalInfoDTO = replaceUndefinedWithDeleteField(dto);
    await updateRecord(modifiedDTO);
    logger.debug('Saved intake form update: personal Info.');
  } catch (e) {
    logger.error('Failed to update intake form: personalInfo.', e);
    throw e;
  }
}

/**
 * update a user's education and current work status
 */
export async function updateEducation(dto: IntakeFormEducationDTO): Promise<void> {
  try {
    const modifiedDTO: IntakeFormEducationDTO = replaceUndefinedWithDeleteField(dto);
    await updateRecord(modifiedDTO);
    logger.debug('Saved intake form update: education and work status.');
  } catch (e) {
    logger.error('Failed to update intake form: education and work status.', e);
    throw e;
  }
}

/**
 * update a user's language proficiency
 */
export async function updateLanguageProficiency(
  dto: IntakeFormLanguageProficiencyDTO,
): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved intake form update: language proficiency.');
  } catch (e) {
    logger.error('Failed to update intake form: language proficiency.', e);
    throw e;
  }
}

/**
 * update a user's work history
 */
export async function updateWorkHistory(dto: IntakeFormWorkHistoryDTO): Promise<void> {
  try {
    const modifiedDTO: IntakeFormWorkHistoryDTO = replaceUndefinedWithDeleteField(dto);
    await updateRecord(modifiedDTO);
    logger.debug('Saved intake form update: work history.');
  } catch (e) {
    logger.error('Failed to update intake form: work history.', e);
    throw e;
  }
}

/**
 * update a user's career plan
 */
export async function updateCareerPlan(dto: IntakeFormCareerPlanDTO): Promise<void> {
  try {
    const modifiedDTO: IntakeFormCareerPlanDTO = replaceUndefinedWithDeleteField(dto);
    await updateRecord(modifiedDTO);
    logger.debug('Saved intake form update: career plan.');
  } catch (e) {
    logger.error('Failed to update intake form: career plan.', e);
    throw e;
  }
}

/**
 * update a user's career constraints
 */
export async function updateCareerConstraints(dto: IntakeFormCareerConstraintsDTO): Promise<void> {
  try {
    const modifiedDTO: IntakeFormCareerConstraintsDTO = replaceUndefinedWithDeleteField(dto);
    await updateRecord(modifiedDTO);
    logger.debug('Saved intake form update: career constraints.');
  } catch (e) {
    logger.error('Failed to update intake form: career constraints.', e);
    throw e;
  }
}

/**
 * update a user's organization
 */
export async function updateOrganization(dto: OrganizationDTO): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved intake form update: organization.');
  } catch (e) {
    logger.error('Failed to update intake form: organization.', e);
    throw e;
  }
}

/**
 * update a user's organization
 */
export async function updateUserEmail(dto: { email: string }): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved intake form update: email.');
  } catch (e) {
    logger.error('Failed to update intake form: email.', e);
    throw e;
  }
}
