import { East } from '@mui/icons-material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import achievementEarnedIcon from '../assets/icons/achievement_earned.svg';
import pointsNeededIcon from '../assets/icons/points_needed.svg';
import pointsTodayIcon from '../assets/icons/points_today.svg';
import Abilities from '../assets/images/dashboard/Abilities.svg';
import ccIcon from '../assets/images/dashboard/ccIcon.svg';
import cipIcon from '../assets/images/dashboard/cipIcon.svg';
import communication from '../assets/images/dashboard/communication.png';
import Confidence from '../assets/images/dashboard/Confidence.svg';
import Emotion from '../assets/images/dashboard/Emotion.png';
import SelfConfidence from '../assets/images/dashboard/SelfConfidence.png';
import Pre_assesment_bg from '../assets/images/Pre-assesment-bg.jpg';
import { AuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { CourseCard } from '../components/Dashboard/CourseCard';
import { CourseProgressBar } from '../components/Dashboard/CourseProgressBar';
import { OverallProgress } from '../components/Dashboard/OverallProgress';
import { courses } from '../courses/courses';
import { Course } from '../courses/courses.types';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../hooks/use-pre-employment-assessment.hooks';
import { useSWOTContext } from '../hooks/use-swot-context.hooks';
import { useCourseProgressContext } from '../hooks/use-user-course.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import {
  dispatchUpsertPreEmploymentAssessment,
  PreEmploymentAssessmentState,
  PreEmploymentAssessmentStatus,
} from '../redux/slice/pre-employment-assessment';
import { dispatchGetSWOTReportValues } from '../redux/slice/swot/swot.operations';
import { SWOTReport as SWOTReportType } from '../redux/slice/swot/swot.types';
import { dispatchGetChapterProgress } from '../redux/slice/user-course';
import { useAppDispatch } from '../redux/store';

const UserDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const courseProgressContext = useCourseProgressContext();
  const { organization, email: userEmail } = useIntakeFormContext();
  const [progressCount, setProgressCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);
  const [preEmpolymentUrl, setPreEmpolymentUrl] = useState('');
  const preEmploymentAssessment: PreEmploymentAssessmentState = usePreEmploymentAssessmentContext();
  const user = useUserProfileContext();
  const swot: SWOTReportType | undefined = useSWOTContext();
  const coursesByModule = _.groupBy(courses, 'module_id');

  useEffect(() => {
    if (Object.keys(preEmploymentAssessment).length) {
      if (user?.isPremium || organization) {
        if (organization && !preEmploymentAssessment.didCompleteSkills) {
          preEmploymentAssessment.activeStepUrl &&
          preEmploymentAssessment.activeStepUrl.includes('skills')
            ? navigate(preEmploymentAssessment.activeStepUrl)
            : navigate('/pre-employment/start');
        } else if (!preEmploymentAssessment.didCompleteIntake) {
          setPreEmpolymentUrl('/pre-employment/intake/step1');
        } else if (
          preEmploymentAssessment.didCompleteIntake &&
          !preEmploymentAssessment.didCompleteEmploymentReadiness &&
          preEmploymentAssessment.requiresEmploymentReadiness
        ) {
          setPreEmpolymentUrl('/pre-employment/employment-readiness/step1');
        } else if (
          preEmploymentAssessment.didCompleteIntake &&
          !preEmploymentAssessment.didCompleteSkills
        ) {
          setPreEmpolymentUrl('/pre-employment/skills/step1');
        } else if (
          preEmploymentAssessment.didCompleteEmploymentReadiness &&
          !preEmploymentAssessment.didCompleteCareerReadiness &&
          preEmploymentAssessment.requiresCareerReadinessAssessment
        ) {
          setPreEmpolymentUrl('/pre-employment/career-readiness/step1');
        } else if (!preEmploymentAssessment.didCompleteExperience) {
          setPreEmpolymentUrl('/pre-employment/experience/step1');
        }
      } else if (!preEmploymentAssessment.didCompleteSkills) {
        preEmploymentAssessment.activeStepUrl &&
        preEmploymentAssessment.activeStepUrl.includes('skills')
          ? navigate(preEmploymentAssessment.activeStepUrl)
          : navigate('/pre-employment/start');
      } else if (!preEmploymentAssessment.isSWOTGenerated && !swot) {
        navigate('/swot/step1');
      } else if (!organization && userEmail) {
        navigate('/upgrade');
      }
    }
  }, [preEmploymentAssessment, preEmpolymentUrl, user, organization, userEmail]);
  useEffect(() => {
    if (Object.keys(courseProgressContext).length) {
      //   void dispatch(dispatchGetChapterProgress());
      // } else {
      const progressValues = _.values(courseProgressContext).filter((x) => x.progress > 0);

      const counts = _.countBy(progressValues, (course) =>
        course.progress < 100 ? 'inProgress' : 'completed',
      );

      // Get counts of in-progress and completed courses
      setProgressCount(counts['inProgress'] || 0);
      setCompletedCount(counts['completed'] || 0);

      // Calculate overall progress
      const totalProgress = progressValues.reduce((sum, course) => sum + course.progress, 0);
      setTotalProgress(totalProgress > 0 ? Math.round(totalProgress / progressValues.length) : 0);

      //enable next module if first is completed
      _.forEach(coursesByModule, (moduleCourses) => {
        let firstEnabledCourse: Course | undefined = undefined;
        _.forEach(moduleCourses, (course, index) => {
          if (!firstEnabledCourse && !course.disabled) {
            firstEnabledCourse = course;
          } else if (course.disabled && firstEnabledCourse && course !== firstEnabledCourse) {
            const progress = courseProgressContext[moduleCourses[index - 1].id]?.progress ?? 0;
            if (progress === 100) {
              course.disabled = false;
            }
          }
        });
      });
    }
  }, [courseProgressContext]);
  useEffect(() => {
    void dispatch(dispatchUpsertPreEmploymentAssessment());
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchGetSWOTReportValues());
    void dispatch(dispatchGetChapterProgress());
  }, []);
  return Object.keys(preEmploymentAssessment).length ? (
    <div className="dashboard">
      <Container>
        <div className="lg:flex items-center justify-between mb-5">
          <h2 className="dashboard-heading mb-4 lg:mb-0">Dashboard</h2>
          <div className="Overallprogress-bar border border-purple-600 bg-white rounded-full lg:w-1/2 p-1 relative">
            <OverallProgress progress={totalProgress} />
            <div className="flex justify-between px-6 text-gray-400 absolute top-1/2 -translate-y-1/2 w-full">
              <p>{totalProgress}%</p>
              <p>OVERALL PROGRESS</p>
              <p></p>
            </div>
          </div>
        </div>
        <div className="grid gap-5 grid-cols-1 lg:grid-cols-2">
          <div className="grid gap-5 grid-cols-2">
            <div className="info-card bg-[#3A82DF]">
              <div className="flex items-center justify-between mb-4">
                <div className="card-icon">
                  <img src={cipIcon} alt="" />
                </div>
                <div className="card-number">{progressCount}</div>
              </div>
              <div className="card-content">Courses in Progress</div>
            </div>
            <div className="info-card bg-[#2CB22C]">
              <div className="flex items-center justify-between mb-4">
                <div className="card-icon">
                  <img src={ccIcon} alt="" />
                </div>
                <div className="card-number">{completedCount}</div>
              </div>
              <div className="card-content">Courses Completed</div>
            </div>
          </div>
          <div>
            <div
              className="flex w-full h-16 bg-white rounded-[20px] overflow-hidden relative mb-4"
              role="progressbar"
            >
              <div
                className="flex flex-col justify-center rounded-[20px] overflow-hidden bg-[#C0F5F3] text-lg  whitespace-nowrap transition duration-500 pl-6 item-disabled"
                style={{ width: '66%' }}
              >
                Target Practice: # of days this week
              </div>
              <p className="absolute right-5 top-1/2 -translate-y-1/2">0/0</p>
            </div>
            <div
              className="flex w-full h-16 bg-white rounded-[20px] overflow-hidden relative"
              role="progressbar"
            >
              <div
                className="flex flex-col justify-center rounded-[20px] overflow-hidden bg-[#FFE9A1] text-lg  whitespace-nowrap transition duration-500 pl-6 item-disabled"
                style={{ width: '60%' }}
              >
                Target Practice: Time spent this week
              </div>
              <p className="absolute right-5 top-1/2 -translate-y-1/2">0/0 min</p>
            </div>
          </div>
        </div>
        <h2 className="dashboard-heading mb-5 mt-8">Course Progress</h2>

        {_.values(courseProgressContext).filter((x) => x.progress > 0).length ||
        preEmploymentAssessment?.progress ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
          >
            {preEmploymentAssessment?.progress &&
              preEmploymentAssessment?.status != PreEmploymentAssessmentStatus.COMPLETE && (
                <SwiperSlide key={0}>
                  <CourseProgressBar
                    organization={organization}
                    isPremium={true}
                    courseId={''}
                    color={'#1c09f8'}
                    name={'Pre-Employment Assessment'}
                    progress={Math.ceil(preEmploymentAssessment?.progress) || 0}
                    preAssementStep={preEmpolymentUrl}
                    isPreAssesment={true}
                  />
                </SwiperSlide>
              )}
            {_.orderBy(
              _.values(courseProgressContext)
                .filter((x) => x.progress > 0)
                .map((course) => ({
                  ...course,
                  courseId:
                    _.findKey(courseProgressContext, {
                      activeChapter: course.activeChapter,
                    }) || '',
                })),
              'progress',
              'asc',
            ).map((course, index) => (
              <SwiperSlide key={index}>
                <CourseProgressBar
                  organization={organization}
                  courseId={course.courseId}
                  color={_.find(courses, (x) => x.id === course.courseId)?.progress_color || ''}
                  name={_.find(courses, (x) => x.id === course.courseId)?.name || ''}
                  progress={courseProgressContext[course.courseId].progress}
                  isPremium={user?.isPremium || false}
                  preAssementStep=""
                  isPreAssesment={false}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="flex justify-between gap-10">
            <h2>No Course in progress</h2>
          </div>
        )}
      </Container>
      <div className="p-5 text-center bg-[#DBE2E9] mt-12 mb-7">
        <Container>
          <h2 className="dashboard-heading">Metrics Section</h2>
        </Container>
      </div>
      <Container>
        <h2 className="dashboard-heading mb-5">Points and Achievements</h2>
        <div className="grid gap-5 grid-cols-1 lg:grid-cols-2">
          <div className="grid gap-5 grid-cols-2" style={{ filter: 'grayscale(1)' }}>
            <div className="info-card bg-[#7B6BD9]">
              <div className="flex items-center justify-between mb-4">
                <div className="card-icon">
                  <img src={pointsTodayIcon} alt="" />
                </div>
                <div className="card-number">0</div>
              </div>
              <div className="card-content">Points Earned Today</div>
            </div>
            <div className="info-card bg-[#D99336]">
              <div className="flex items-center justify-between mb-4">
                <div className="card-icon">
                  <img src={pointsNeededIcon} alt="" />
                </div>
                <div className="card-number">0</div>
              </div>
              <div className="card-content">
                Points Needed <p className="text-xs font-light">for Next Achievement Badge</p>
              </div>
            </div>
          </div>
          <div style={{ filter: 'grayscale(1)' }}>
            <div className="info-card bg-[#962CC6] !p-0 h-full">
              <div className="flex items-center justify-between mb-4 h-full">
                <div className="md:p-7 p-4">
                  <div className="card-icon mb-4">
                    <img src={achievementEarnedIcon} alt="" />
                  </div>
                  <div className="card-content font-bold">Achievements Earned</div>
                </div>
                <div className="md:flex justify-around h-full">
                  <div className="card-number font-light text-center border-l-4 border-white md:py-7 py-3 px-4">
                    <div className="text-xs text-light bg-purple-500 px-3 py-2 rounded-md mb-5">
                      Today
                    </div>
                    0
                  </div>
                  <div className="card-number font-light text-center border-l-4 border-white md:py-7 py-3 px-4">
                    <div className="text-xs text-light bg-purple-500 px-3 py-2 rounded-md mb-5">
                      This Week
                    </div>
                    0
                  </div>
                  <div className="card-number font-light text-center border-l-4 border-white md:py-7 py-3 px-4">
                    <div className="text-xs text-light bg-purple-500 px-3 py-2 rounded-md mb-5">
                      This Month
                    </div>
                    0
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mt-9 mb-5">
          <h2 className="dashboard-heading">Recommended Courses</h2>
          {
            <Link className="text-purple-600" to="/courses/eb7b1338-7fa1-4673-9b2f-4fe30c2a9069">
              View All
            </Link>
          }
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {preEmploymentAssessment.status?.includes('completed') ? null : (
            <SwiperSlide>
              <div className="course-card">
                <div className="img-wrap">
                  <img src={Pre_assesment_bg} alt="" />
                </div>
                <div className={`course-body ${organization && '!pb-16'}`}>
                  <div className="course-type">
                    <p></p>
                  </div>
                  <div className="course-title">
                    <h2>Pre-Employment Assessment</h2>
                  </div>
                  <div className="course-content">
                    <p> Description</p>
                  </div>
                </div>
                <Link to={preEmpolymentUrl} className="course-action">
                  Resume
                  <East />
                </Link>
              </div>
            </SwiperSlide>
          )}

          {courses.map((course, index) => (
            <SwiperSlide key={index}>
              <CourseCard
                organization={organization}
                courseImage={course.course_image}
                courseDescription={course.description}
                courseName={course.name}
                courseId={course.id}
                disabled={false}
                disabledDescription={course.disabled_description}
                isPremium={user?.isPremium}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex items-center justify-between mt-9 mb-5">
          <h2 className="dashboard-heading">Achievement Showcase</h2>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          <SwiperSlide>
            <div className="item-disabled">
              <div
                className="p-11 rounded-2xl flex justify-center"
                style={{
                  background:
                    'linear-gradient(180deg, rgba(193,72,229,0.15) 0%, rgba(90,72,212,0.15) 100%)',
                }}
              >
                <img className="h-24" src={SelfConfidence} alt="" />
              </div>
              <p className="text-sm font-semibold mt-4 text-center">Self-Discovery</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="item-disabled">
              <div
                className="p-11 rounded-2xl flex justify-center"
                style={{
                  background:
                    'linear-gradient(180deg, rgba(0,171,255,.15) 0%, rgba(205,202,49,.15) 100%)',
                }}
              >
                <img className="h-24" src={Emotion} alt="" />
              </div>
              <p className="text-sm font-semibold mt-4 text-center">Emotional Intelligence</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="item-disabled">
              <div
                className="p-11 rounded-2xl flex justify-center"
                style={{
                  background:
                    'linear-gradient(180deg, rgba(255,97,0,.15) 0%, rgba(250,189,3,.15) 100%)',
                }}
              >
                <img className="h-24" src={communication} alt="" />
              </div>
              <p className="text-sm font-semibold mt-4 text-center">Communication Skills</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="item-disabled">
              <div
                className="p-11 rounded-2xl flex justify-center"
                style={{
                  background: 'white',
                }}
              >
                <img className="h-24" src={Confidence} alt="" />
              </div>
              <p className="text-sm font-semibold mt-4 text-center text-gray-500">
                Confidence and Self-Esteem
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="item-disabled">
              <div
                className="p-11 rounded-2xl flex justify-center"
                style={{
                  background: 'white',
                }}
              >
                <img className="h-24" src={Abilities} alt="" />
              </div>
              <p className="text-sm font-semibold mt-4 text-center text-gray-500">
                Adaptability and Resilience
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="flex items-center justify-between mt-9 mb-5 hidden">
          <h2 className="dashboard-heading">Leadership Board</h2>
        </div>
        <div className="flex flex-col bg-white rounded-2xl overflow-hidden hidden">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-purple-600 item-disabled">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start font-medium text-white capitalize"
                      >
                        Rank
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start font-medium text-white capitalize"
                      >
                        User ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start font-medium text-white capitalize"
                      >
                        Username
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start  font-medium text-white capitalize"
                      >
                        Score
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start font-medium text-white capitalize"
                      >
                        Last Update
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700 p-3">
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-800 dark:text-gray-200">
                        0
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-800 dark:text-gray-200">
                        0
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-800 dark:text-gray-200">
                        0
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">
                        NA
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export const UserDashboardPage: React.FC = () => {
  return <AuthenticatedPage render={() => <UserDashboard />} />;
};
