import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';
import { useState } from 'react';

type CarouselComponentData = {
  heading: string;
  content: string;
  image?: string;
};
interface CarouselComponentProps {
  data: CarouselComponentData[];
}

export const SimpleCarouselComponent: React.FC<CarouselComponentProps> = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % data.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + data.length) % data.length);
  };
  return (
    <div className="carousel-container pb-10">
      {currentSlide > 0 && (
        <button className="prev" onClick={prevSlide}>
          <ArrowBackIosOutlined />
        </button>
      )}
      <div className="carousel">
        {data.map((content, index) => (
          <div
            key={index}
            className={`flex items-center px-10 lg:px-20 slide ${
              index === currentSlide ? 'active' : ''
            }`}
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            <div>
              <>
                <h2 className="text-2xl font-semibold mb-7">{content.heading}</h2>
                {content.image && (
                  <span className="block mt-7 rounded-3xl overflow-hidden mb-7">
                    <img
                      className="object-cover h-full w-full  max-h-[300px]"
                      src={content.image}
                      alt=""
                    />
                  </span>
                )}
                <p>
                  <span dangerouslySetInnerHTML={{ __html: content.content }} />
                </p>
              </>
            </div>
          </div>
        ))}
      </div>
      {currentSlide < data.length - 1 && (
        <button className="next" onClick={nextSlide}>
          <ArrowForwardIosOutlined />
        </button>
      )}
      {data.length > 1 && ( // Check if there is more than one slide
        <div className="pagination">
          {data.map((_, index) => (
            <div
              key={index}
              className={`pagination-dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => setCurrentSlide(index)}
            >
              {index + 1}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
