import { configureStore } from '@reduxjs/toolkit';

import userAsaTaskHistoryReducer from '../slice/asa-task-history/asa-task-history.slice';
import authReducer from '../slice/auth/auth.slice';
import tempAuthSliceReducer from '../slice/auth/temp-auth.slice';
import formCareerReadinessReducer from '../slice/form-career-readiness/form-career-readiness.slice';
import formEmploymentReadinessReducer from '../slice/form-employment-readiness/form-employment-readiness.slice';
import formExperienceAssessmentReducer from '../slice/form-experience/form-experience.slice';
import formIntakeReducer from '../slice/form-intake/form-intake.slice';
import formSkillsReducer from '../slice/form-skills/form-skills.slice';
import preEmploymentAssessmentReducer from '../slice/pre-employment-assessment/pre-employment-assessment.slice';
import swotReducer from '../slice/swot/swot.slice';
import coursePrePostAssessmentsReducer from '../slice/user-assesments/users-course-assesments.slice';
import courseProgressReducer from '../slice/user-course/user-course.slice';
import userCourseQuestionReducer from '../slice/user-course-question-response/user-course-question-response.slice';
import userCourseQuizQuestionResponseReducer from '../slice/user-course-question-response/user-course-quiz-question-response.slice';
import userProfileReducer from '../slice/user-profile/user-profile.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    formIntake: formIntakeReducer,
    formEmploymentReadiness: formEmploymentReadinessReducer,
    formSkills: formSkillsReducer,
    formCareerReadiness: formCareerReadinessReducer,
    formExperienceAssessment: formExperienceAssessmentReducer,
    preEmploymentAssessment: preEmploymentAssessmentReducer,
    swot: swotReducer,
    courseProgress: courseProgressReducer,
    userProfile: userProfileReducer,
    userCourseQuestion: userCourseQuestionReducer,
    userCourseQuizQuestion: userCourseQuizQuestionResponseReducer,
    coursePrePostAssessments: coursePrePostAssessmentsReducer,
    userAsaTaskHistory: userAsaTaskHistoryReducer,
    tempAuth: tempAuthSliceReducer,
  },
});

export default store;

/**
 * inferred state
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * dispatch method with inferred types from store
 */
export type AppDispatch = typeof store.dispatch;
