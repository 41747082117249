/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CircularLoader } from '../components/loader/CircularLoader';
import ErrorPopup from '../components/Popup/ErrorPopup';
import { changePassword, checkResetLinkStatus } from '../services/database';
import { calculatePasswordStrength } from '../utils/helper';

export const ForgotPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [isValidParams, setIsValidParams] = useState(false);
  const [oobPrivateCode, setOobPrivateCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [paramsLoading, setParamsLoading] = useState(true);

  useEffect(() => {
    const pramsCheck = async () => {
      const queryParams = new URLSearchParams(location.search);
      const mode = queryParams.get('mode');
      const oobCode = queryParams.get('oobCode');
      const apiKey = queryParams.get('apiKey');

      if (mode === 'resetPassword' && oobCode && apiKey) {
        try {
          const obbCodeStatusCheck = await checkResetLinkStatus(oobCode);
          if (obbCodeStatusCheck && !obbCodeStatusCheck.valid) {
            setErrorMessage(obbCodeStatusCheck?.error || 'Invalid reset link.');
            setTimeout(() => {
              navigate('/login');
            }, 3500);
          } else {
            setOobPrivateCode(oobCode);
            setIsValidParams(true); // Valid parameters
          }
        } catch (error) {
          setErrorMessage('An unexpected error occurred. Please try again.');
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        }
      } else {
        setErrorMessage(`Please make sure you're using the correct password reset link.`);
        setTimeout(() => {
          navigate('/login');
        }, 3500);
      }
      setParamsLoading(false); // Set loading to false after check
    };

    void pramsCheck();
  }, [location, navigate]);

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setNewPassword(value);
    setPasswordStrength(calculatePasswordStrength(value));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (newPassword.length < 8) {
      toast.error('Password must be at least 8 characters long.');
      setLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      if (oobPrivateCode) {
        const response = await changePassword(oobPrivateCode, newPassword);
        if (response) {
          toast.success('Your password has been set. You may go ahead and log in now.');
          setTimeout(() => {
            navigate('/login');
          }, 3500);
        }
      }
    } catch (error: any) {
      if (error.code === 'auth/expired-action-code') {
        toast.error('The reset link has expired. Please request a new one.');
      } else if (error.code === 'auth/invalid-action-code') {
        toast.error('The reset link is invalid or has already been used.');
      } else {
        toast.error('Error resetting password: ' + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const getPasswordStrengthLabel = () => {
    if (passwordStrength === 5) return { label: 'Strong', color: 'green' };
    if (passwordStrength >= 3) return { label: 'Medium', color: 'orange' };
    return { label: 'Weak', color: 'red' };
  };

  const { label: strengthLabel, color: strengthColor } = getPasswordStrengthLabel();

  return (
    <div className="m-2">
      {paramsLoading ? (
        <CircularLoader />
      ) : isValidParams && !errorMessage ? (
        <div className="flex items-center min-h-[50vh] justify-center">
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
            <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Reset Password</h2>
            <form onSubmit={handleSubmit}>
              {/* New Password */}
              <div className="mb-4">
                <TextField
                  required
                  label="New Password"
                  type={showNewPassword ? 'text' : 'password'}
                  variant="outlined"
                  fullWidth
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  className="w-full"
                  placeholder="Enter your new password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {newPassword && (
                  <div className="mt-2">
                    <LinearProgress
                      variant="determinate"
                      value={(passwordStrength / 5) * 100}
                      sx={{
                        backgroundColor: '#e0e0e0',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: strengthColor,
                        },
                      }}
                    />
                    <p className="text-sm mt-1" style={{ color: strengthColor }}>
                      Password Strength: {strengthLabel}
                    </p>
                  </div>
                )}
              </div>

              {/* Confirm Password */}
              <div className="mb-4">
                <TextField
                  required
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  variant="outlined"
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value.trim())}
                  className="w-full"
                  placeholder="Confirm your new password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              {/* Submit Button */}
              <button
                disabled={!newPassword || !confirmPassword}
                type="submit"
                className="w-1/2 justify-center bg-purple-600 disabled:bg-purple-400 text-white py-2 rounded-md hover:bg-purple-700 transition duration-300"
              >
                {loading ? (
                  <CircularProgress size={20} sx={{ color: 'white' }} />
                ) : (
                  'Reset Password'
                )}
              </button>
            </form>
          </div>
        </div>
      ) : (
        <ErrorPopup>
          <div>
            <p>{errorMessage && errorMessage}</p>
          </div>
        </ErrorPopup>
      )}
    </div>
  );
};
