import React from 'react';
import { Navigate } from 'react-router-dom';

import { Login as LoginComponent } from '../components/Login';
import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
import { CustomUser } from '../redux/slice/auth/CustomUser';

export const Login: React.FC = () => {
  const user: CustomUser | null = useAuthContext();
  const isAuthenticated: boolean = user ? true : false;
  const previousUrl = sessionStorage.getItem('previousUrl');

  if (previousUrl) {
    sessionStorage.removeItem('previousUrl');
  }

  return isAuthenticated ? (
    previousUrl ? (
      <Navigate to={previousUrl} />
    ) : (
      <Navigate to="/dashboard" />
    )
  ) : (
    <div className="login-signup-bg px-4 py-24">
      <LoginComponent />
    </div>
  );
};
