import { Close, Done } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import {
  CourseQuestion,
  QuestionOption,
  QuestionType,
} from '../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { UserChapterQuestion } from '../../redux/slice/user-course-question-response';

interface ICourseQuestionCard {
  label?: string;
  question: CourseQuestion;
  onSubmitAnswer: (quizAnswers: QuestionOption[], isCorrect: boolean, questionId?: string) => void;
  submittedAnswer?: UserChapterQuestion;
  onRetakeQuestion: (questionId: string) => void;
}
export const CourseQuestionCard: React.FC<ICourseQuestionCard> = ({
  label,
  question,
  onSubmitAnswer,
  submittedAnswer,
  onRetakeQuestion,
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState<QuestionOption[]>([]);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<boolean>(false);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState<boolean>(false);

  const handleOptionSelect = (option: QuestionOption) => {
    if (question?.type === QuestionType.RADIO) {
      setSelectedAnswers([option]);
    } else if (question?.type === QuestionType.CHECKBOX) {
      if (selectedAnswers.includes(option)) {
        setSelectedAnswers(selectedAnswers.filter((obj) => obj !== option));
      } else {
        setSelectedAnswers([...selectedAnswers, option]);
      }
    }
  };
  const handleAnswerSubmit = () => {
    setIsAnswerSubmitted(true);
    if (
      selectedAnswers.length === question?.answers.length &&
      question?.answers.every((value) => selectedAnswers.find((obj) => obj.id === value))
    ) {
      setIsAnswerCorrect(true);
      onSubmitAnswer(selectedAnswers, true, question?.id);
    } else {
      onSubmitAnswer(selectedAnswers, false, question?.id);
    }
  };
  const handleTakeAgainClick = () => {
    setSelectedAnswers([]);
    setIsAnswerCorrect(false);
    setIsAnswerSubmitted(false);
    onRetakeQuestion(question?.id);
  };
  useEffect(() => {
    setSelectedAnswers((submittedAnswer && submittedAnswer?.answers) || []);
    setIsAnswerCorrect(submittedAnswer?.isCorrect || false);
    setIsAnswerSubmitted(submittedAnswer?.question_id ? true : false);
  }, [submittedAnswer]);
  return (
    <>
      <div className="border p-5 lg:p-10 rounded-3xl">
        {label && <p className="font-bold pb-5 text-2xl">{label}</p>}
        {question?.question_description && (
          <>
            <p>
              <br />
            </p>
            <p className="text-xl font-medium mb-10">
              <span dangerouslySetInnerHTML={{ __html: question?.question_description }} />
            </p>
          </>
        )}
        <p className="text-xl font-medium mb-10">
          <span dangerouslySetInnerHTML={{ __html: question?.question }} />
        </p>
        <ul className="mt-7">
          {question?.type === QuestionType.CHECKBOX
            ? question?.options.map((option) => (
                <li
                  key={option.id}
                  className={`lg:p-3 mb-3 ${
                    isAnswerSubmitted && selectedAnswers.find((x) => x.id === option.id)
                      ? 'answer-active'
                      : ''
                  }`}
                >
                  <label className="custom-checkbox">
                    {option.option}
                    <input
                      checked={!!selectedAnswers.find((obj) => obj.id === option.id)}
                      disabled={isAnswerSubmitted}
                      onChange={() => handleOptionSelect(option)}
                      type="checkbox"
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))
            : question?.options.map((option) => (
                <li
                  key={option.id}
                  className={`lg:p-3 mb-3 ${
                    isAnswerSubmitted && selectedAnswers.find((x) => x.id === option.id)
                      ? 'answer-active'
                      : ''
                  }`}
                >
                  <label key={option.id} className="custom-radio">
                    {option.option}
                    <input
                      checked={!!selectedAnswers.find((obj) => obj.id === option.id)}
                      disabled={isAnswerSubmitted}
                      onChange={() => handleOptionSelect(option)}
                      type="radio"
                      name={question?.id}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))}
          <button
            disabled={isAnswerSubmitted || !selectedAnswers.length}
            onClick={handleAnswerSubmit}
            className="btn-primary"
          >
            Submit
          </button>
        </ul>
        {isAnswerSubmitted && (
          <div className="mt-7 text-center">
            <div className="bg-gray-100 text-center px-10 md:px-20 py-10">
              <div className="icon-wrap w-16 h-16 rounded-full border-2 border-gray-400 flex items-center justify-center mx-auto mb-3">
                {!isAnswerCorrect ? (
                  <Close sx={{ color: '#962cc6' }} />
                ) : (
                  <Done sx={{ color: '#962cc6' }} />
                )}
              </div>
              <p className="mb-7">{isAnswerCorrect ? 'Correct' : 'Incorrect'}</p>
              <p>
                {isAnswerCorrect ? (
                  <span dangerouslySetInnerHTML={{ __html: question?.answer_description }} />
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        question?.wrong_answer_description || selectedAnswers[0]?.description || '',
                    }}
                  />
                )}
              </p>
            </div>
            <button onClick={handleTakeAgainClick} className="btn-primary mt-10">
              Take Again
            </button>
          </div>
        )}
      </div>
    </>
  );
};
