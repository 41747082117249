import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { questions as readinessQuestion } from '../assessments/career-readiness/career-readiness.assessments';
import { AuthenticatedPage } from '../components/AuthenticatedPage';
import { CareerReadinessAssessment } from '../components/CareerReadiness/CareerReadinessAssessment';
import { CareerReadinessHeader } from '../components/CareerReadiness/CareerReadinessHeader';
import { ProficiencyRatingScale } from '../components/CareerReadiness/ProficiencyRatingScale';
import Container from '../components/container/Container';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../hooks/use-pre-employment-assessment.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchGetCareerReadinessValues } from '../redux/slice/form-career-readiness';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../redux/slice/pre-employment-assessment';
import { dispatchGetSWOTReportValues } from '../redux/slice/swot/swot.operations';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';

export const CareerReadiness: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { componentName } = useParams();
  const {
    status: preEmploymentAssessmentStatus,
    progress,
    activeStepUrl,
    isSWOTGenerated,
  } = usePreEmploymentAssessmentContext();
  const { organization, email: userEmail } = useIntakeFormContext();
  const userProfileContext = useUserProfileContext();

  useEffect(() => {
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchGetCareerReadinessValues());
    void dispatch(dispatchUpsertPreEmploymentAssessment());
    void dispatch(dispatchGetSWOTReportValues());
    void dispatch(dispatchGetUserProfileValues());
  }, []);

  const [step, setStep] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBackButton = () => {
    if (step == 1) {
      navigate('/pre-employment/skills/step11');
    } else {
      void dispatch(
        dispatchUpsertPreEmploymentAssessment({
          activeStepUrl: `/pre-employment/career-readiness/step${step - 1}`,
        }),
      );
      navigate(`/pre-employment/career-readiness/step${step - 1}`);
    }
  };

  useEffect(() => {
    setStep(parseInt(componentName?.replace('step', '') || '1'));
    window.scrollTo(0, 0);
  }, [componentName]);

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.NOT_STARTED) {
      void dispatch(
        dispatchUpsertPreEmploymentAssessment({
          status: PreEmploymentAssessmentStatus.ACTIVE,
        }),
      );
    }
  }, [preEmploymentAssessmentStatus]);

  useEffect(() => {
    if (
      Object.keys(userProfileContext).length &&
      !userProfileContext?.isPremium &&
      userProfileContext?.email &&
      userEmail &&
      preEmploymentAssessmentStatus &&
      !organization
    ) {
      activeStepUrl
        ? navigate(activeStepUrl)
        : isSWOTGenerated
        ? navigate('/upgrade')
        : navigate('/pre-employment/start');
    } else if (
      Object.keys(userProfileContext).length &&
      (userProfileContext?.isPremium || organization) &&
      userProfileContext?.email &&
      userEmail &&
      preEmploymentAssessmentStatus
    ) {
      activeStepUrl && activeStepUrl.includes('career-readiness') && navigate(activeStepUrl);
    }
  }, [organization, userProfileContext, userEmail, preEmploymentAssessmentStatus]);
  const renderFormStep = () => {
    switch (componentName) {
      case 'step1':
        return (
          <CareerReadinessAssessment
            step={step}
            questions={readinessQuestion}
            onBack={handleBackButton}
          />
        );
      case 'step2':
        return <ProficiencyRatingScale step={step} onBack={handleBackButton} />;
      default:
        navigate('/pre-employment/career-readiness/step1');
        return null;
    }
  };

  return (
    <div className={`steps overflow-hidden relative career-${step}`}>
      <Container>
        <>
          <CareerReadinessHeader progress={progress || 0}>
            <>{renderFormStep()}</>
          </CareerReadinessHeader>
        </>
      </Container>
    </div>
  );
};

export const CareerReadinessPage: React.FC = () => {
  return <AuthenticatedPage render={() => <CareerReadiness />} />;
};
