import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

export const NaturalCompetenciesPieChart: React.FC = () => {
  return (
    <PieChart
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
        },
      }}
      series={[
        {
          data: [
            { id: 0, value: 8, label: 'Competency 1', color: '#00a0cf' },
            { id: 1, value: 12, label: 'Competency 2', color: '#ff3169' },
            { id: 2, value: 40, label: 'Competency 3', color: '#00b4b0' },
            { id: 3, value: 40, label: 'Competency 4', color: '#ffa523' },
          ],
          innerRadius: 80,

          arcLabel: (item) => `${item.value}`,
        },
      ]}
      width={500}
      height={250}
    />
  );
};
