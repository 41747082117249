interface TextBlockProps {
  title: string;
  children: React.ReactNode;
}
export const TextBlock: React.FC<TextBlockProps> = ({ title, children }) => {
  return (
    <div className="bg-[#EFF2FB] p-5 rounded-2xl mb-5">
      <h4 className="text-xl font-semibold mb-3">{title}</h4>
      {children}
    </div>
  );
};
