import { TableFooter } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { dispatchGetUserEngagementActivity } from '../../redux/slice/user-engagement/user-engagement.operation';
import { UserEngagementType } from '../../redux/slice/user-engagement/user-engagement.type';

interface UserActivity {
  lastUpdatedDate: string;
  todayActiveTime: number;
}

interface Row {
  name: string;
  week1: number;
  week2: number;
  week3: number;
  week4: number;
  week5: number;
}

function createData(
  name: string,
  week1: number,
  week2: number,
  week3: number,
  week4: number,
  week5: number,
): Row {
  return { name, week1, week2, week3, week4, week5 };
}
export const formatTime = (seconds: number) => {
  if (seconds === 0) {
    return '-'; // Show "-" if time is zero
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours}h: ${minutes}m`;
};

const getEngagementData = (dataArray: UserActivity[]): Row[] => {
  const week1 = [0, 0, 0, 0, 0, 0, 0]; // Mon to Sun for week 1
  const week2 = [0, 0, 0, 0, 0, 0, 0];
  const week3 = [0, 0, 0, 0, 0, 0, 0];
  const week4 = [0, 0, 0, 0, 0, 0, 0];
  const week5 = [0, 0, 0, 0, 0, 0, 0]; // Mon to Sun for week 5 (if it exists)

  if (dataArray.length) {
    dataArray.forEach((dto) => {
      const date = Number(dayjs(dto.lastUpdatedDate).utc().format('DD'));
      const dayIndex = dayjs(dto.lastUpdatedDate).utc().day(); // 0 = Sun, 1 = Mon, etc.
      const totalHours = dto.todayActiveTime;

      if (date >= 1 && date <= 7) {
        week1[dayIndex] += totalHours;
      } else if (date >= 8 && date <= 14) {
        week2[dayIndex] += totalHours;
      } else if (date >= 15 && date <= 21) {
        week3[dayIndex] += totalHours;
      } else if (date >= 22 && date <= 28) {
        week4[dayIndex] += totalHours;
      } else if (date >= 29 && date <= 31) {
        week5[dayIndex] += totalHours;
      }
    });
  }

  // Return formatted data for each day of the week
  return [
    createData('Sun', week1[0], week2[0], week3[0], week4[0], week5[0]), // Sun is dayIndex 0
    createData('Mon', week1[1], week2[1], week3[1], week4[1], week5[1]),
    createData('Tue', week1[2], week2[2], week3[2], week4[2], week5[2]),
    createData('Wed', week1[3], week2[3], week3[3], week4[3], week5[3]),
    createData('Thu', week1[4], week2[4], week3[4], week4[4], week5[4]),
    createData('Fri', week1[5], week2[5], week3[5], week4[5], week5[5]),
    createData('Sat', week1[6], week2[6], week3[6], week4[6], week5[6]),
  ];
};

type PlatformEngagementTableProps = {
  engagementDate: string;
};

export const PlatformEngagementTable: React.FC<PlatformEngagementTableProps> = ({
  engagementDate,
}) => {
  const [rows, setRows] = useState<Row[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      // const currentMonth = Number(dayjs(engagementDate).utc().format('MM'));
      const fetchedData: UserEngagementType = await dispatchGetUserEngagementActivity();

      if (fetchedData) {
        // Convert the object to an array of UserActivity
        const fetchDataValues: UserActivity[] = Object.values(fetchedData);
        const currentMonthData = fetchDataValues.filter(
          (dto) => dayjs(dto.lastUpdatedDate).utc().format('YYYY-MM') === engagementDate,
        );

        const engagementRows = getEngagementData(currentMonthData);
        setRows(engagementRows); // Update the table rows based on the engagement data
      }
    };

    void fetchData();
  }, [engagementDate]);

  return (
    <TableContainer className="custom-callender" sx={{ boxShadow: 'none' }} component={Paper}>
      <Table sx={{ fontSize: '13px', textAlign: 'start' }} size="medium" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="!text-purple-700 !font-extrabold !text-[11.5px] md:min-[96px]">
              Days
            </TableCell>
            <TableCell
              className="!text-black !font-extrabold !text-[11.5px] md:min-[96px]"
              align="center"
            >
              1st-7th
            </TableCell>
            <TableCell
              className="!text-black !font-extrabold !text-[11.5px] md:min-[96px]"
              align="center"
            >
              8th-14th
            </TableCell>
            <TableCell
              className="!text-black !font-extrabold !text-[11.5px] md:min-[96px]"
              align="center"
            >
              15th-21st
            </TableCell>
            <TableCell
              className="!text-black !font-extrabold !text-[11.5px] md:min-[96px]"
              align="center"
            >
              22nd-28th
            </TableCell>
            <TableCell
              className="!text-black !font-extrabold !text-[11.5px] md:min-[96px]"
              align="center"
            >
              29th-End
            </TableCell>
          </TableRow>
        </TableHead>
        <TableFooter>
          <TableRow>
            <TableCell className="!text-purple-700 !font-extrabold !text-[11.5px]">
              Total Hours
            </TableCell>
            <TableCell className="!text-black  !text-[11.5px] !font-extrabold" align="center">
              {formatTime(rows.reduce((acc, row) => acc + row.week1, 0))}
            </TableCell>
            <TableCell className="!text-black !text-[11.5px] !font-extrabold" align="center">
              {formatTime(rows.reduce((acc, row) => acc + row.week2, 0))}
            </TableCell>
            <TableCell className="!text-black !text-[11.5px] !font-extrabold" align="center">
              {formatTime(rows.reduce((acc, row) => acc + row.week3, 0))}
            </TableCell>
            <TableCell className="!text-black !text-[11.5px] !font-extrabold" align="center">
              {formatTime(rows.reduce((acc, row) => acc + row.week4, 0))}
            </TableCell>
            <TableCell className="!text-black !text-[11.5px] !font-extrabold" align="center">
              {formatTime(rows.reduce((acc, row) => acc + row.week5, 0))}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
