import React from 'react';

import swot_img from '../../../assets/images/swot/swot-img.png';

export const SWOTIntroTemplate: React.FC = () => {
  return (
    <>
      <div className="pt-4" style={{ height: '1122px' }}>
        <div className="pt-3 pb-3 text-center theme-gradient rounded-xl">
          <div className=" text-xl text-white">
            <span className="font-semibold"> SWOT </span> ANALYSIS
          </div>
        </div>
        <img className="mx-auto mt-5" src={swot_img} alt="" />
        <div className="lg:px-16 mt-9">
          <p className="lg:text-xl text-center mb-9">
            Please access your comprehensive SWOT analysis report, which includes an overview of
            your Super Powers, Weaknesses, Opportunities, and Threats.
          </p>
        </div>
      </div>
    </>
  );
};
