import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, IconButton, Alert, AlertTitle } from '@mui/material';
import React, { useEffect } from 'react';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  //   bgcolor: '#bb6dff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 5,
};

type PopupProps = {
  children: React.ReactNode;
};

const ErrorPopup: React.FC<PopupProps> = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (children) {
      setOpen(true);
    }
  }, [children]);

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
        BackdropProps={{
          onClick: (e) => e.stopPropagation(),
        }}
      >
        <Box className="cstm-popup bg-[#fff4e5]" sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[700],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Alert severity="warning">
            <AlertTitle style={{ fontWeight: 'bold' }}>Error</AlertTitle>
            {children}
          </Alert>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ErrorPopup;
