import { useEffect, useState } from 'react';

import charIntro from '../../../../assets/images/courses/perception-understanding/chapter2/char/perciption_chap2_img3.png';
import ladder from '../../../../assets/images/courses/perception-understanding/chapter2/ladder.jpg';
import loopLadder from '../../../../assets/images/courses/perception-understanding/chapter2/loopLadder.jpg';
import perciption_chap2_img1 from '../../../../assets/images/courses/perception-understanding/chapter2/perciption_chap2_img1.jpg';
import manLadder from '../../../../assets/images/courses/perception-understanding/chapter2/perciption_chap2_img12.jpg';
import PreferencesInfluencePerception from '../../../../assets/images/courses/perception-understanding/chapter2/perciption_chap2_img2.jpg';
import PreferencesInfluencePerception1 from '../../../../assets/images/courses/perception-understanding/chapter2/perciption_chap2_img3.jpg';
import perciption_chap3_chatbg from '../../../../assets/images/courses/perception-understanding/chapter2/perciption_chap3_chatbg.jpg';
import introImg from '../../../../assets/images/courses/perception-understanding/chapter2/perciption_chap3_img1.jpg';
import quoteImg from '../../../../assets/images/courses/perception-understanding/chapter2/quoteImg.jpg';
import RunningUp from '../../../../assets/images/courses/perception-understanding/chapter2/RunningUp.jpg';
import vImg from '../../../../assets/images/courses/perception-understanding/chapter2/vImg.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  course_basic_details,
  course_chat_questions,
  CourseBasicDetails,
} from '../../../../courses/perception-understanding/perciption-understanding-questions';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { UserChapterQuestion } from '../../../../redux/slice/user-course-question-response';
import { CustomQuestionAccordion } from '../../../common/QuestionAccordion';
import { ActionableBlock } from '../../ActionableBlock';
import FlipCard from '../../FlipCard';
import { InfoBlock } from '../../InfoBlock';
import { MediaBlock } from '../../MediaBlock';
import { TabsBlock } from '../../TabsBlock';

interface PerciptionProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const PerceptionAndUnderstandingChapter2: React.FC<PerciptionProps> = ({
  chapter,
  onNextChapter,
}) => {
  const [isFormDirty, setIsFormDirty] = useState(true);
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const courseFilter: CourseBasicDetails[] = course_basic_details.filter(
    (course) => course.chapter_id === chapter.id,
  );
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const validateAnswer = (data: string) => {
    if (!data) {
      setIsFormDirty(true);
    }
  };
  const TabsData = [
    {
      title: 'Self-awareness ',
      htmlContent: `<p>When considering your own thought processes, beware particularly of pieces of information that you take for granted. They are likely to be deeply rooted in your belief system, and it’s worth stopping to examine them to make sure that they really are facts. Some of the time, at least, you will find that others do not see them as ‘right’ at all.</p>`,
    },
    {
      title: 'Explain your Reasoning',
      htmlContent: `<p>In explaining your reasoning and thinking, key phrases to use are “So, I’m hearing that you like this part, but not that aspect. Would you agree?”, “It sounds to me like…” and “I’m thinking that x makes sense, but do others agree?” &nbsp;</p>`,
    },
    {
      title: 'Ask Questions',
      htmlContent: `<p>You can also ask questions to test the data. There are three main types of questions. You can ask for data, in an open-ended way, test your assumptions, or just note the observable data.</p><p><br></p><p>If we consider the example scenario with Jane and Mary above, Jane might say to Mary:</p><p><br></p><p>“Is everything OK?”<br>“Did you have trouble with the traffic this morning?”<br>“Was 10.30 too early for you? We could have made it later.”<br>“Was it inconvenient to meet up this morning? You can always let me know if so, and we can rearrange.”</p><p><br></p><p>Any of these could open up a conversation about why Mary was late, or uncover the fact that she had no idea she was late.</p><p><br></p><p>&nbsp;Alternatively, when Jane says that she doesn’t want to meet up next week, Mary might say to Jane:</p><p><br></p><p>“Are you OK? You’ve been very quiet this morning.”</p>`,
    },
  ];
  const accordionData = [
    {
      id: 'panel1',
      title: 'Question1',
      content:
        '1. Describe a time when you ran up your ladder at work or at home. (Event or situation)',
    },
    {
      id: 'panel2',
      title: 'Question2',
      content:
        '2. How did you go up the rungs? Include: data based on your perceptions, value judgments, interpretation/meaning, logical conclusions, emotional/physical responses, and the beliefs you formed.',
    },
    {
      id: 'panel3',
      title: 'Question3',
      content: '3. What action did you take in the situation?  Was it accurate or wrong?',
    },
  ];

  useEffect(() => {
    if (
      chapter &&
      courseFilter[0]?.course_id &&
      Object.keys(userCourseQuestionResponseContext).length
    ) {
      const userAnswerResponse: UserChapterQuestion[] =
        userCourseQuestionResponseContext[courseFilter[0].course_id]?.chapters[chapter.id]
          ?.questions || [];

      if (userAnswerResponse.length === 3) {
        setIsFormDirty(false);
      }
    }
  }, [userCourseQuestionResponseContext]);
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-xl font-light">
        <h2 className="font-semibold text-3xl">Perception & The Ladder of Inference</h2>
        <p className="text-sm italic">Part 2 of 10</p>
        <MediaBlock mediaImage={perciption_chap2_img1}></MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">The Power of Perception</h3>
        <p className="text-2xl">
          A key element to understanding who we are is Perception: how we as humans perceive the
          world.
          <br />
          <br />
          What do you see when you look at this image?
        </p>
        <div className="mt-20">
          <div className="flex justify-center mb-20">
            <FlipCard>
              <div className="front-content w-full h-full">
                <img
                  className="w-full h-full object-contain"
                  src="https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/520zrbJW/assets/KSLMpLJm-7uVWNnK_nlabfeOGjee47qfc.png"
                  alt=""
                />
              </div>
              <div className="back-content text-center">
                <p>Did you see an elderly lady or a young woman?</p>
              </div>
            </FlipCard>
          </div>
          <div className="flex justify-center gap-5">
            <FlipCard>
              <div className="front-content">
                <p>Young Woman</p>
              </div>
              <div className="back-content text-center  w-full h-full">
                <img
                  className="w-full h-full object-contain"
                  src="https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/520zrbJW/assets/paip3hT8T7nLcDuD_1X19sZq2ahWEZpWH.png"
                  alt=""
                />
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content">
                <p>Elderly Woman</p>
              </div>
              <div className="back-content text-center  w-full h-full">
                <img
                  className="w-full h-full object-contain"
                  src="https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/520zrbJW/assets/BH6APUjYwavwT--g_UWbK29py_EPw9dgg.png"
                  alt=""
                />
              </div>
            </FlipCard>
          </div>
        </div>
        <p className="text-2xl mt-16">
          Let's Look at Another One - What do you see when you look at this image.
        </p>
        <div className="mt-20">
          <div className="flex justify-center mb-20">
            <FlipCard>
              <div className="front-content w-full h-full">
                <img
                  className="w-full h-full object-contain"
                  src="https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/520zrbJW/assets/XNhbs8tkD2SA8Hly_Sp5zTgyhUSFWBXbP.jpg"
                  alt=""
                />
              </div>
              <div className="back-content text-center">
                <p>Did you see a man playing a saxophone or a woman?</p>
              </div>
            </FlipCard>
          </div>
          <div className="flex justify-center gap-5">
            <FlipCard>
              <div className="front-content text-center">
                <p>Man playing a saxophone</p>
              </div>
              <div className="back-content text-center  w-full h-full">
                <img
                  className="w-full h-full object-contain"
                  src="https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/520zrbJW/assets/M9caKw6Zs278ZF0s_v3Sv2cRBnK5DxSWH.jpg"
                  alt=""
                />
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content text-center">
                <p>Woman</p>
              </div>
              <div className="back-content text-center  w-full h-full">
                <img
                  className="w-full h-full object-contain"
                  src="https://articulateusercontent.com/review/uploads/FwdIyWDpCXZw_2YasHZLKf5aNzFn_r5U/520zrbJW/assets/ahrZLflfRmJOU5_8_MVpx5mBr8oj3oWGX.jpg"
                  alt=""
                />
              </div>
            </FlipCard>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Preferences Influence Perception
        </h3>
        <div className="flex items-center mb-12">
          <img className="w-96 mr-9" src={PreferencesInfluencePerception} alt="" />
          <div>
            <p>
              Carl Jung strove to understand why people behave differently. He demonstrated that we
              all have unique preferences that influence our worldly perception.
              <br />
              <br />
              Our experiences culture, and values shape our perception of the world.
              <br />
              <br />
              While we all share some common understanding of the human experience, we are all
              different and this shapes us and our behavior.
            </p>
          </div>
        </div>
        <div className="flex items-center mb-10">
          <div>
            <p>
              When we examine how and why we perceive the world the way we do, we begin to
              understand why we prefer to do things the way we do and why we are drawn toward
              certain activities. Insights Discovery will help us understand this more.
              <br />
              <br />
              Before we jump into Insights Discovery, let’s delve into the Ladder of
              Perception/Inference.
            </p>
          </div>
          <img className="w-96 ml-9" src={PreferencesInfluencePerception1} alt="" />
        </div>
        <MediaBlock mediaImage={introImg} />
        <p className="text-2xl leading-10 text-center">
          Have you ever been in a disagreement or intense discussion with someone in a group and
          wondered how in the world you could see the same thing so differently? Have you ever
          accused someone else – or been accused – of jumping to conclusions?
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-4">
          The Ladder of Perception/Inference
        </h3>
        <p>
          The reason we jump to conclusions can be explained by a mental model called the “Ladder of
          Inference.” The model explains how we quickly and unconsciously move from the facts of a
          situation to a conclusion (and why our conclusions are sometimes wrong).
        </p>
        <div className="flex items-center my-12">
          <img className="w-96 mr-9" src={ladder} alt="" />
          <div>
            <h3 className="text-2xl text-purple-600 font-bold mb-4">
              What’s the ladder of inference theory?
            </h3>
            <p>
              Chris Argyris and Peter Senge proposed and explained this theory in their book titled
              The Fifth Discipline: The Art and Practice of the Learning Organization. The theory
              was designed in the context of organizations, seeking to explain why people make bad
              decisions in the workplace.
              <br />
              <br />
              The authors used the metaphor of a ladder to explain the process by which people
              arrive at certain conclusions.
            </p>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-4">Rungs on the Ladder</h3>
        <p>
          The ladder is made up of 7 rungs or stages that outline the rapid process our minds go
          through to make conclusions and take action in a given situation. This entire step-by-step
          process on the Ladder of Inference happens almost instantaneously, thousands of times a
          day, inside our minds. It is a normal and natural process.
          <br />
          <br />
          Click on each marker (+) starting from the bottom and working your way up to learn the
          rungs on the ladder.
        </p>
        <ul className="list-disc pl-5 mt-8">
          <li className="mb-7">Observing what’s around you.</li>
          <li className="mb-7">Selecting certain data or information about what you see.</li>
          <li className="mb-7">Assigning meaning to that data or information.</li>
          <li className="mb-7">4 Developing assumptions.</li>
          <li className="mb-7">Drawing conclusions.</li>
          <li className="mb-7">Adjusting your beliefs.</li>
          <li className="mb-7">
            Taking some kind of action based on these conclusions and beliefs.
          </li>
        </ul>
        <InfoBlock
          title="Important Note: "
          text="Your beliefs influence this whole process. They determine how you select certain information and ignore the rest. They also filter your reality and often make you jump from an observation to a conclusion in a millisecond."
        />
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-8">
          Trevor Maber in his TED-ED talk, explains the Ladder of Inference in more detail and shows
          what jumping to conclusions looks like in a real-world situation.
        </h3>
        <div className="border p-10 text-sm font-normal">
          <div className="video-wrap">
            <iframe
              className="embed__media"
              src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FKJLqOclPqis%3Ffeature%3Doembed%26start%3D220&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DKJLqOclPqis&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FKJLqOclPqis%2Fhqdefault.jpg&amp;key=40cb30655a7f4a46adaaf18efb05db21&amp;type=text%2Fhtml&amp;schema=youtube"
              title="Rethinking thinking - Trevor Maber"
            ></iframe>
          </div>
          <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-4">
            Rethinking thinking - Trevor Maber
          </h3>
          <p className="mb-4">
            View full lesson: http://ed.ted.com/lessons/rethinking-thinking-trevor-maber Every day,
            we meet people and process our interactions--making inferences and developing beliefs
            about the world around us. In this lesson, Trevor Maber introduces us to the idea of a
            ladder of inference and a process for rethinking the way we interact. Lesson by Trevor
            Maber, animation by TED-Ed.
          </p>
          <a
            className="font-bold"
            target="_blank"
            href="https://www.youtube.com/watch?v=KJLqOclPqis&t=220s"
          >
            VIEW ON YOUTUBE
          </a>
        </div>
        {/* Actionable section */}
        <ActionableBlock
          backgroundImage={perciption_chap3_chatbg}
          courseChatQuestions={course_chat_questions}
          characterImage={charIntro}
          feedbackTitle="Scenario Complete!"
          feedbackText=" "
          buttonName="Continue"
          cssClass="perception-chat"
        />
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-4">
          What's the problem with Jane running up her ladder?
        </h3>
        <p className="mb-8">
          Jane started by selecting from the observable data, translated it into her own terms
          (based on her own meaning and beliefs), explained it to herself with assumptions, and then
          drew conclusions, which then made her act and decide not to meet with Mary again. This is
          dangerous, because it all happened extremely quickly in her head, and she is probably
          unaware that she is only selecting some of the data. Nobody else saw her thought
          processes, or knows what stages Jane have gone through to reach her conclusions. All that
          Mary saw was the action Jane took as a result.
          <br />
          <br />
          At the end of this, all Mary saw was that Jane did not want to meet up again. She may have
          no idea why. There could be any number of reasons why Mary was late, and hasn’t explained:
          a doctor’s appointment, perhaps, or it could be as simple as her watch being slow. Mary
          may not even have known she was late. Meanwhile, Jane has decided the friendship is not
          worth pursuing.
        </p>
        <InfoBlock
          title="Note: "
          text="A lot of the time, you won’t even be aware of the beliefs and assumptions underlying your data selection and the inferences that you draw. They may go right back to childhood, and a chance comment, even one overheard and only half-understood. "
        />
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-8">
          What implications does this have for me?
        </h3>
        <p className="text-2xl text-center">
          The ladder works because it's a very efficient process of filtering, interpreting,
          applying, and acting on our experiences.
          <br />
          <br />
          But there is a problem -{' '}
          <span className="font-bold">not everyone sees the world the same as we do.</span>
        </p>
        <div className="flex items-center my-12">
          <img className="w-96 mr-9" src={manLadder} alt="" />
          <div>
            <p>
              When we disagree with others, we usually disagree about conclusions. We assume that we
              have selected the same sub-set of data and interpreted the meaning in the same way. We
              assume that our assumptions are the same. And so, we “leap” up the rungs of the ladder
              unconsciously, without testing or questioning our underlying assumptions. That is what
              we mean when we say someone is “jumping to conclusions.”
            </p>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-8">
          At the heart of the problem are our unspoken assumptions. In addition, there are two
          reinforcing loops in the process that affect how we see the world, and thus, how we
          interpret the world and act upon our experiences.
        </h3>
        <div className="flex items-center">
          <div className="loop2 border rounded-2xl p-5 w-56 flex-none text-base font-normal">
            <h4 className="font-bold mb-4">Loop 2</h4>
            <p>
              The second loop has to do with how our actions can create self-fulfilling prophecies.
              When we act on a situation in a way that accords with our beliefs (and our underlying
              assumptions), we may create circumstances that only serve to reinforce faulty
              assumptions.&nbsp;
            </p>
          </div>
          <div className="mx-5">
            <img src={loopLadder} alt="" />
          </div>
          <div className="loop1 border rounded-2xl p-5 w-56 flex-none text-base font-normal">
            <h4 className="font-bold mb-4">Loop 1</h4>
            <p>
              The first loop begins with our beliefs. Our beliefs loop back to affect how we
              selectively filter our experiences. We see the world not as it is, but as we believe
              it to be. Our minds need to filter reality because there is too much going on to
              process it all. We create generalizations and stereotypes because the abstractions are
              easier to manage than the constant barrage of details in the real world.&nbsp;
            </p>
            <br />
            <p>
              The more narrow and rigid our beliefs become, the smaller our filtered or selected
              reality becomes.
            </p>
          </div>
        </div>
        <InfoBlock
          title=""
          text="Don’t be tempted to think that jumping to conclusions on the Ladder of Inference is only applicable to our little everyday disagreements. The way our beliefs lead us to selectively filter our experiences can have huge implications in our life and work! "
        />
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-8">
          What can you do to avoid running up the ladder of inference, or help others to avoid it?
        </h3>
        <p>
          First of all, you have to accept that you are always going to draw meaning and inferences
          from what others say and do, based on your past experience. It’s how people work.
          <br />
          <br />
          If we did not use past experience to help us interpret the world, we would be absolutely
          lost. Nobody would be able to ‘learn from experience’ at all.
          <br />
          <br />
          The issue, then, is to draw on experience, but in a way that does not make assumptions
          about others’ behavior, or which allows us to check back on those assumptions.
        </p>
        <div className="flex items-center mb-12 mt-11">
          <img className="w-96 mr-9" src={vImg} alt="" />
          <div>
            <p>
              Rick Ross, in The Fifth Discipline Fieldbook, describes three ways you can change to
              improve the way you communicate and avoid you or others climbing the ladder of
              inference:
            </p>
            <ul className="list-decimal mt-5 pl-5">
              <li className="mb-5 pl-4 marker:font-bold">
                You can become more aware of your own thinking and reasoning (reflection, or
                reflective practice);
              </li>
              <li className="mb-5 pl-4 marker:font-bold">
                You can make sure that others understand your thinking and reasoning (advocacy);
              </li>
              <li className="mb-5 pl-4 marker:font-bold">
                You can ask questions of others about what they are thinking, and test your
                assumptions (inquiry).
              </li>
            </ul>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-8">
          Click on the tabs below to look at each of the ways you can improve the way you
          communicate and avoid climbing up the ladder of inference.
        </h3>
        <TabsBlock tabsData={TabsData} />
        <div className="bg-purple-100 p-5 rounded-3xl font-normal mt-8">
          <h4 className="text-purple-700 font-bold mb-4">Keep the following in mind: </h4>
          <p className="text-base">
            It can be difficult to test your final assumptions directly without sounding either
            foolish or rude, and inviting a particular answer. For example, it would have been hard
            for Jane to ask Mary whether Mary valued their meetings. She could hardly have relied on
            the answer, since Mary was bound to say that she did, out of politeness, even if she did
            not. Therefore, it's important to think about how you ask the questions to test the data
            and your assumptions.
          </p>
        </div>
        <MediaBlock mediaImage={quoteImg}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            "The Ladder of Influence is a tool that helps us form a systemic understanding of a
            situation, offering opportunities to discover common ground, which helps us move forward
            on issues in a more inclusive way, especially during times of conflict or
            misunderstandings. Going through this process of deeper questioning, informs us of
            gateways that we might have once perceived as impossible."
            <br />
            <span className="text-base">
              ~ Inspired by Designing Regenerative Cultures, by Daniel Wahl, 2016
            </span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-8">
          Activity - Running Up the Ladder
        </h3>
        <div className="flex items-center mb-12 mt-11">
          <img className="w-96 mr-9" src={RunningUp} alt="" />
          <div>
            <h4 className="text-xl font-bold mb-10">Instructions:</h4>
            <p>
              Using the Ladder model, think of an event during which you reacted strongly or drew
              the wrong conclusions. Click on the question tabs below and answer each question.
            </p>
          </div>
        </div>
        <InfoBlock
          title="Note: "
          text="Upon completion of answering all three questions, if you wish, you may click next and save as a pdf or print your responses. "
        />
        {accordionData.map((data) => (
          <CustomQuestionAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
            chapter={chapter}
            course={courseFilter[0]}
            onChange={validateAnswer}
          />
        ))}
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-8">"Running Up Your Ladder"</h3>
        <p>
          As a final recap, this tool offers a series of questions that we ask ourselves and others
          to become more aware of this process. This ultimately guides how we respond to situations
          and highlights how beliefs are formed based on past experiences. Showing our dominant
          belief system and worldviews that sometimes ignore facts or other interpretations.
          <br />
          <br />
          So, the next time you feel that you are "Running Up Your Ladder' here is a list of
          questions that can be used in taking yourself (and others) through the process of
          realization:
        </p>
        <ul className="list-decimal mt-5 pl-5">
          <li className="mb-5 pl-4 marker:font-bold">
            Which observable facts and experiences am I basing my reasoning on, and are there other
            facts to consider?
          </li>
          <li className="mb-5 pl-4 marker:font-bold">
            How and why did I choose certain data and regard other data as less relevant?.
          </li>
          <li className="mb-5 pl-4 marker:font-bold">
            What are the underlying assumptions I am employing and are they valid? (Based on what
            underlying assumptions am I judging their validity?)
          </li>
          <li className="mb-5 pl-4 marker:font-bold">
            What beliefs underlie my perspective and how have these beliefs influenced what I
            observed and which data I chose?
          </li>
          <li className="mb-5 pl-4 marker:font-bold">
            Why am I proposing to follow this course of action and what alternatives or
            complementary actions should/could we consider?
          </li>
        </ul>
        <p className="text-2xl leading-10 text-center mr-10">
          Regardless of your role, you need to be conscious of running up your ladder and jumping to
          the wrong conclusions. You may be right, but always double-check your ladder before you
          take definitive action. Next, we will explore more from Carl Jung and begin your journey
          into your own character.
        </p>

        <button
          disabled={isFormDirty}
          onClick={handleNextChapterClick}
          className="w-full btn-primary uppercase mt-8"
        >
          PREFERENCE
        </button>
      </div>
    </>
  );
};
