import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';

import { useAuthContext } from '../../hooks/use-user-auth-context.hooks';
import { CustomUser } from '../../redux/slice/auth/CustomUser';
import {
  dispatchGetUserEngagementActivity,
  dispatchUpdateUserEngagementActivity,
} from '../../redux/slice/user-engagement/user-engagement.operation';
import { UserEngagementType } from '../../redux/slice/user-engagement/user-engagement.type';

dayjs.extend(utc);

const ActiveTimeTracker: React.FC = () => {
  const user: CustomUser | null = useAuthContext();
  const isAuthenticated: boolean = user ? true : false;

  const [todayActiveTime, setTodayActiveTime] = useState<number>(0);
  const [totalActiveTime, setTotalActiveTime] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        const todayDate = dayjs().utc().format('YYYY-MM-DD');
        const fetchedData: UserEngagementType = await dispatchGetUserEngagementActivity();
        const fetchDataKeys = Object.keys(fetchedData);
        if (fetchedData) {
          const lastSavedDate = fetchedData[todayDate]?.lastUpdatedDate;
          const isSameDay = lastSavedDate && dayjs().isSame(lastSavedDate, 'day');

          if (isSameDay) {
            setTodayActiveTime(fetchedData[todayDate].todayActiveTime || 0);
            setTotalActiveTime(fetchedData[todayDate].totalActiveTime || 0);
          } else {
            setTodayActiveTime(0); // Reset todayActiveTime if the day has changed
            if (fetchDataKeys.length) {
              setTotalActiveTime(
                fetchedData[fetchDataKeys[fetchDataKeys.length - 1]]?.totalActiveTime,
              ); // Use last day's totalActiveTime or 0
            } else {
              setTotalActiveTime(0); // Use last day's totalActiveTime or 0
            }
          }
        }
      }
    };

    void fetchData();
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      let intervalId: NodeJS.Timeout | null = null;
      let saveIntervalId: NodeJS.Timeout | null = null;

      const updateDisplay = () => {
        setTodayActiveTime((prevTime) => prevTime + 1);
        setTotalActiveTime((prevTime) => prevTime + 1);
      };

      const saveActiveTime = () => {
        const todayDate = dayjs().utc().format('YYYY-MM-DD');
        const data: UserEngagementType = {
          [todayDate]: {
            lastUpdatedDate: new Date().toUTCString(),
            todayActiveTime,
            totalActiveTime,
          },
        };
        sessionStorage.setItem('userActivity', JSON.stringify(data));
      };

      const startTracking = () => {
        if (!intervalId) {
          intervalId = setInterval(updateDisplay, 1000);
        }

        if (!saveIntervalId) {
          saveIntervalId = setInterval(saveActiveTime, 10000); // Save every 10 seconds
        }
      };

      const stopTracking = () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
        if (saveIntervalId) {
          clearInterval(saveIntervalId);
        }

        intervalId = null;
        saveIntervalId = null;
      };

      const handleVisibilityChange = async () => {
        if (document.visibilityState === 'visible') {
          startTracking();
        } else {
          const todayDate = dayjs().utc().format('YYYY-MM-DD');
          const userEngagementDate: UserEngagementType = {
            [todayDate]: {
              lastUpdatedDate: new Date().toUTCString(),
              todayActiveTime,
              totalActiveTime,
            },
          };
          await dispatchUpdateUserEngagementActivity(userEngagementDate);
          stopTracking();
          saveActiveTime(); // Save immediately when the user switches tabs
        }
      };

      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('beforeunload', saveActiveTime); // Save active time when the user leaves the page

      if (document.visibilityState === 'visible') {
        startTracking();
      }

      return () => {
        stopTracking();
        saveActiveTime(); // Save when the component unmounts
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        window.removeEventListener('beforeunload', saveActiveTime);
      };
    }
    return;
  }, [todayActiveTime, totalActiveTime, isAuthenticated]);

  const formatTime = (seconds: number) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${days}d ${hours}h ${minutes}m ${secs}s`;
  };

  return (
    <div hidden>
      <p>Today's Active Time: {formatTime(todayActiveTime)}</p>
      <p>Total Active Time: {formatTime(totalActiveTime)}</p>
    </div>
  );
};

export default ActiveTimeTracker;
