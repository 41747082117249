interface MediaBlockProps {
  mediaImage: string;
  children?: React.ReactNode;
  cssClass?: string;
}
export const MediaBlock: React.FC<MediaBlockProps> = ({ mediaImage, children, cssClass }) => {
  return (
    <div
      style={{ backgroundImage: `url(${mediaImage})` }}
      className={` bg-center bg-cover min-h-[414px] h-full relative mt-8 rounded-3xl overflow-hidden ${
        cssClass || ''
      }`}
    >
      <div
        className={`img-content min-h-[414px] w-full h-full ${
          !children ? '' : 'bg-[#000000a6]'
        }  text-white px-5 lg:px-11 py-10 flex items-center`}
      >
        {children}
      </div>
    </div>
  );
};
