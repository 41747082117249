import char_img_00 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_00.png';
import char_img_01 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_01.png';
import char_img_02 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_02.png';
import char_img_03 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_03.png';
import char_img_04 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_04.png';
import char_img_05 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_05.png';
import char_img_06 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_06.png';
import char_img_07 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_07.png';
import char_img_08 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_08.png';
import char_img_09 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_09.png';
import char_img_10 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_10.png';
import char_img_11 from '../../../assets/images/courses/adapting-to-change/chapter3/char_img_11.png';
import chapter2_chat_char4 from '../../../assets/images/courses/jumpstart-communicator/chapter4/answer1.png';
import chapter2_chat_char5 from '../../../assets/images/courses/jumpstart-communicator/chapter4/answer2.png';
import chapter2_chat_char1 from '../../../assets/images/courses/jumpstart-communicator/chapter4/intro.png';
import chapter2_chat_char2 from '../../../assets/images/courses/jumpstart-communicator/chapter4/question1.png';
import chapter2_chat_char3 from '../../../assets/images/courses/jumpstart-communicator/chapter4/question2.png';
import {
  CourseChatQuestion,
  CourseMatchingQuestion,
  CourseQuestion,
  CourseSortingQuestion,
  QuestionType,
} from '../../jumpstart-communicator/jumpstart-communicator.types';

export const course_questions: CourseQuestion[] = [
  {
    chapter_id: '40909258-081c-48a5-8767-2b8ebfc85fdc',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
    id: '3ebb2b22-0ee4-40fe-b4ec-cfb672f42fc2',
    question:
      'Can you accept this change? Your company is reorganizing departments and your coworkers, schedule, and job title are going to change. You’re feeling shocked and angry. What can you do to move past those emotions and accept this change?',
    options: [
      {
        id: 'd6346b88-5fc0-4ba0-9ef1-e5e2a9d2879b',
        option:
          'My feelings are getting in the way, so I should push them aside and focus on objective truths.',
        description:
          'Not quite. While it’s tempting to push down your feelings, burying those emotions will cause them to bubble under the surface. If you want to move past them, you must confront them.',
      },
      {
        id: '8876fc93-a271-4abf-9009-8dd4f9b6256c',
        option:
          'I must come to terms with reality—writing down the things I can’t control and learning to accept them.',
        description:
          'That isn’t necessary. While there will always be things you can’t control, focus on the positives instead. Write down everything you do have control over, as that will make you feel more empowered.',
      },
      {
        id: '55dc2f7d-e3e5-41f4-9ab2-0199cfee6211',
        option:
          'I need to learn more. I should ask questions and seek out information to make the change feel less foreign.',
        description:
          'That’s great! Change comes with a lot of unknowns, but you can eliminate that obscurity by seeking information to make the shift feel less daunting.',
      },
    ],
    answers: ['55dc2f7d-e3e5-41f4-9ab2-0199cfee6211'],
    answer_description:
      'That’s great! Change comes with a lot of unknowns, but you can eliminate that obscurity by seeking information to make the shift feel less daunting',
    type: QuestionType.RADIO,
  },
];

export const course_sorting_matching_questions: (
  | CourseQuestion
  | CourseSortingQuestion
  | CourseMatchingQuestion
)[] = [
  {
    id: '587ca2ec-19da-4328-9d44-55894a72ca9c',
    chapter_id: 'f1ab52f3-1be6-4b79-b893-f888bbf85561',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
    type: QuestionType.SORTING,
    questions: [
      {
        id: 'a789a676-64bb-4897-a03e-48204e8d70c2',
        question: 'Feeling like beginners again when change occurs.',
      },
      {
        id: '8d824041-212c-4593-9ad8-f6fd3268fbe2',
        question: 'Replacing everyday habits with unfamiliar approaches.',
      },
      {
        id: '31cc8882-f86c-4faa-bd24-5588ec3641b0',
        question: 'Loss of control due to changes in familiar routines.',
      },
      {
        id: '7d1a0f70-6808-4a69-b0f5-1db0a362a22d',
        question: 'Doubt about whether our skills will still be relevant.',
      },
      {
        id: 'f99e162a-0b68-400d-94ee-09ada99772e8',
        question: 'Anxiety and fear about falling short during the learning curve.',
      },
      {
        id: '624c48a7-da52-4318-ae1b-ab74c75a5930',
        question: 'Uncertainty about the outcomes of the changes.',
      },
      {
        id: '1b12f988-ff20-4521-b4ed-61130f56e4c3',
        question: 'Worry about not meeting new expectations.',
      },
      {
        id: '93e216a2-2f33-4305-bb5c-493509f1f4af',
        question: 'Leaving behind what we know and facing the unpredictable. ',
      },
    ],
    options: [
      {
        id: '162fca85-0590-4bc2-b47a-6aa4e00c73e6',
        option: 'Future Uncertainty',
      },
      {
        id: '04c8338a-0d7b-4520-a37c-778d928c26ed',
        option: 'Threatened Competency',
      },
      {
        id: 'f2d47ae4-fbce-4e9c-9380-cb5dd23db4f1',
        option: 'Disrupted Routines',
      },
      {
        id: 'cfd4952e-cfff-4b3a-be7c-7126af5ddc49',
        option: 'Fear of Failure',
      },
    ],
    answers: {
      'a789a676-64bb-4897-a03e-48204e8d70c2': {
        id: '04c8338a-0d7b-4520-a37c-778d928c26ed',
        option: 'Threatened Competency',
      },
      '8d824041-212c-4593-9ad8-f6fd3268fbe2': {
        id: 'f2d47ae4-fbce-4e9c-9380-cb5dd23db4f1',
        option: 'Disrupted Routines',
      },
      '31cc8882-f86c-4faa-bd24-5588ec3641b0': {
        id: 'f2d47ae4-fbce-4e9c-9380-cb5dd23db4f1',
        option: 'Disrupted Routines',
      },
      '7d1a0f70-6808-4a69-b0f5-1db0a362a22d': {
        id: '04c8338a-0d7b-4520-a37c-778d928c26ed',
        option: 'Threatened Competency',
      },
      'f99e162a-0b68-400d-94ee-09ada99772e8': {
        id: 'cfd4952e-cfff-4b3a-be7c-7126af5ddc49',
        option: 'Fear of Failure',
      },
      '624c48a7-da52-4318-ae1b-ab74c75a5930': {
        id: '162fca85-0590-4bc2-b47a-6aa4e00c73e6',
        option: 'Future Uncertainty',
      },
      '1b12f988-ff20-4521-b4ed-61130f56e4c3': {
        id: 'cfd4952e-cfff-4b3a-be7c-7126af5ddc49',
        option: 'Fear of Failure',
      },
      '93e216a2-2f33-4305-bb5c-493509f1f4af': {
        id: '162fca85-0590-4bc2-b47a-6aa4e00c73e6',
        option: 'Future Uncertainty',
      },
    },
  },
  {
    id: '37645b54-8c84-42fa-b4d6-87c44acea932',
    chapter_id: '8b8d1c69-6b57-448e-a31c-c94a442c6f89',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
    type: QuestionType.SORTING,
    questions: [
      {
        id: '50f929f5-b80f-468c-b1e0-45f4cf86f214',
        question: 'Feeling anxious and uneasy',
      },
      {
        id: '44afb0d0-a82f-4851-91fe-dd61c67c6f51',
        question: 'Feelings of confusion',
      },
      {
        id: '3682f68c-84c8-495a-b495-30ac69f7f03b',
        question: 'feeling helpless about what is happening',
      },
      {
        id: 'a6710575-aa25-434e-a5d2-bd2bdf439ae9',
        question: 'Going for coffee with your new team',
      },
      {
        id: '91dae8ad-e842-400d-a92c-bc93f47a5c69',
        question: 'Delaying participating in team activities',
      },
      {
        id: '8da144e6-6f21-458a-a017-ab92e3c18c5e',
        question: 'Continuing to complete tasks related to prior job although moved into new job',
      },
    ],
    options: [
      {
        id: '12ca8330-1e6b-48de-bd89-2db6f26e1906',
        option: 'Stage 1',
      },
      {
        id: '07fa2f69-0e12-4228-b8a3-53a787c59832',
        option: 'Stage 2',
      },
      {
        id: '669ee0f5-772c-48a2-b2cc-60f473aa5da3',
        option: 'Stage 3 & 4',
      },
      {
        id: '12f914c2-1bff-4539-83f9-c61dcc6d7d1a',
        option: 'Stage 5',
      },
    ],
    answers: {
      '50f929f5-b80f-468c-b1e0-45f4cf86f214': {
        id: '07fa2f69-0e12-4228-b8a3-53a787c59832',
        option: 'Stage 2',
      },
      '44afb0d0-a82f-4851-91fe-dd61c67c6f51': {
        id: '12ca8330-1e6b-48de-bd89-2db6f26e1906',
        option: 'Stage 1',
      },
      '3682f68c-84c8-495a-b495-30ac69f7f03b': {
        id: '07fa2f69-0e12-4228-b8a3-53a787c59832',
        option: 'Stage 2',
      },
      'a6710575-aa25-434e-a5d2-bd2bdf439ae9': {
        id: '12f914c2-1bff-4539-83f9-c61dcc6d7d1a',
        option: 'Stage 5',
      },
      '91dae8ad-e842-400d-a92c-bc93f47a5c69': {
        id: '669ee0f5-772c-48a2-b2cc-60f473aa5da3',
        option: 'Stage 3 & 4',
      },
      '8da144e6-6f21-458a-a017-ab92e3c18c5e': {
        id: '669ee0f5-772c-48a2-b2cc-60f473aa5da3',
        option: 'Stage 3 & 4',
      },
    },
  },
  {
    id: 'a1b442f1-a1fa-48ee-9b73-b20d5a247afd',
    chapter_id: '8b8d1c69-6b57-448e-a31c-c94a442c6f89',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
    question_description: 'Match each emotional stage with their corresponding statements.',
    type: QuestionType.MATCHING,
    questions: [
      {
        question: {
          id: '00ee82e6-b472-49cc-9734-b664652b576d',
          question: 'I can’t believe this is happening.',
        },
        answer: {
          id: '9df46b64-c872-411c-9362-472f7075ad68',

          option: 'Bargaining and Negotiation',
        },
        correct_answer: {
          id: '663dbdba-c04a-4f89-ba96-86e1fc324305',
          option: 'Shock and Denial',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: 'ef140d8e-7819-4541-b487-971dc3c783ef',
          question: 'No! This isn’t fair.',
        },
        answer: {
          id: '219df97f-8639-4977-928c-0f8df5281ab8',
          option: 'Depression and Detachment',
        },
        correct_answer: {
          id: 'd7d6c0d0-f0fb-4d86-9981-b21cace192b1',
          option: 'Anger and Frustration',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: 'dc6cf57c-689f-4d08-ab90-4cf9172eba51',
          question: 'Is there a way out of this?',
        },
        answer: {
          id: '53a2bb0e-a6fc-47ee-a84b-2926a2dab4a6',
          option: 'Acceptance and Moving Forward',
        },
        correct_answer: {
          id: '9df46b64-c872-411c-9362-472f7075ad68',
          option: 'Bargaining and Negotiation',
        },
        index: 2,
        matched: false,
      },
      {
        question: {
          id: 'cd2a2e2b-e1b4-4879-9b7a-2297a71e8e75',
          question: 'Feeling sadness and isolation due to the change.',
        },
        answer: {
          id: '663dbdba-c04a-4f89-ba96-86e1fc324305',
          option: 'Shock and Denial',
        },
        correct_answer: {
          id: '219df97f-8639-4977-928c-0f8df5281ab8',
          option: 'Depression and Detachment',
        },
        index: 3,
        matched: false,
      },
      {
        question: {
          id: '7db1bda4-5e2e-4ed9-8d84-df24313d3e55',
          question: 'It’s going to be OK.',
        },
        answer: {
          id: 'd7d6c0d0-f0fb-4d86-9981-b21cace192b1',
          option: 'Anger and Frustration',
        },
        correct_answer: {
          id: '53a2bb0e-a6fc-47ee-a84b-2926a2dab4a6',
          option: 'Acceptance and Moving Forward',
        },
        index: 4,
        matched: false,
      },
    ],
  },
  {
    id: '08314cc1-07bf-4933-bd77-04e7aaa3316d',
    chapter_id: '40909258-081c-48a5-8767-2b8ebfc85fdc',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
    type: QuestionType.SORTING,
    question_description: 'Positive or Negative: Sorting Activity',
    questions: [
      {
        id: '8ef0cfec-cfdd-432f-999e-a08dba7d832b',
        question: 'I can’t learn quickly.',
      },
      {
        id: '39b3a218-df13-475f-849e-36e354f54f5f',
        question: 'I know a colleague who is an expert and can help.',
      },
      {
        id: '0194eb75-382e-451e-8557-7b40d26a0637',
        question: 'I can’t learn anything new.',
      },
      {
        id: 'be585128-059e-4a2d-b9f7-66482bcc249f',
        question: 'I will do some research.',
      },
      {
        id: '98dee5e8-6c48-412e-ad05-626732b3b0c3',
        question: 'I will sign up for training sessions.',
      },
    ],
    options: [
      {
        id: 'a7205e69-6264-48e7-8567-8d72d701937e',
        option: 'Think Positive',
      },
      {
        id: 'b1ce7013-d91b-401e-ad46-ec455c71e01b',
        option: 'Think Negative',
      },
    ],
    answers: {
      '8ef0cfec-cfdd-432f-999e-a08dba7d832b': {
        id: 'b1ce7013-d91b-401e-ad46-ec455c71e01b',
        option: 'Think Negative',
      },
      '39b3a218-df13-475f-849e-36e354f54f5f': {
        id: 'a7205e69-6264-48e7-8567-8d72d701937e',
        option: 'Think Positive',
      },
      '0194eb75-382e-451e-8557-7b40d26a0637': {
        id: 'b1ce7013-d91b-401e-ad46-ec455c71e01b',
        option: 'Think Negative',
      },
      'be585128-059e-4a2d-b9f7-66482bcc249f': {
        id: 'a7205e69-6264-48e7-8567-8d72d701937e',
        option: 'Think Positive',
      },
      '98dee5e8-6c48-412e-ad05-626732b3b0c3': {
        id: 'a7205e69-6264-48e7-8567-8d72d701937e',
        option: 'Think Positive',
      },
    },
  },
  {
    chapter_id: '40909258-081c-48a5-8767-2b8ebfc85fdc',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
    id: '3ebb2b22-0ee4-40fe-b4ec-cfb672f42fc2',
    question:
      'Can you accept this change? Your company is reorganizing departments and your coworkers, schedule, and job title are going to change. You’re feeling shocked and angry. What can you do to move past those emotions and accept this change?',
    options: [
      {
        id: 'd6346b88-5fc0-4ba0-9ef1-e5e2a9d2879b',
        option:
          'My feelings are getting in the way, so I should push them aside and focus on objective truths.',
        description:
          'Not quite. While it’s tempting to push down your feelings, burying those emotions will cause them to bubble under the surface. If you want to move past them, you must confront them.',
      },
      {
        id: '8876fc93-a271-4abf-9009-8dd4f9b6256c',
        option:
          'I must come to terms with reality—writing down the things I can’t control and learning to accept them.',
        description:
          'That isn’t necessary. While there will always be things you can’t control, focus on the positives instead. Write down everything you do have control over, as that will make you feel more empowered.',
      },
      {
        id: '55dc2f7d-e3e5-41f4-9ab2-0199cfee6211',
        option:
          'I need to learn more. I should ask questions and seek out information to make the change feel less foreign.',
        description:
          'That’s great! Change comes with a lot of unknowns, but you can eliminate that obscurity by seeking information to make the shift feel less daunting.',
      },
    ],
    answers: ['55dc2f7d-e3e5-41f4-9ab2-0199cfee6211'],
    answer_description:
      'That’s great! Change comes with a lot of unknowns, but you can eliminate that obscurity by seeking information to make the shift feel less daunting',
    type: QuestionType.RADIO,
  },
];

export const course_quiz_questions: (
  | CourseSortingQuestion
  | CourseMatchingQuestion
  | CourseQuestion
)[] = [
  {
    id: 'cb31d5d7-1e94-4aef-b1b1-61c089b919f9',
    question: 'Check the items that make change unsettling.',
    question_description:
      'Workplace change follows the typical motto: “Out with the old, in with the new.” And, for many, that new is unsettling and difficult to navigate. Most often, change is challenging. Below are some reasons this is the case.',
    options: [
      {
        id: 'a31c8677-ca24-48c7-9b15-613bd9b65081',
        option: 'Future uncertainty',
      },
      {
        id: 'd0ffaed1-148a-415b-8ce8-b8e073734d02',
        option: 'Threatened competency',
      },
      {
        id: '05a15412-e6c9-4db0-a850-d955ac65ec13',
        option: 'Disrupted routines',
      },
      {
        id: '2e5d87f1-b3e0-4057-a779-35e340bdbe3c',
        option: 'Fear of failure',
      },
      {
        id: 'cdbfcc92-958c-4c75-a9ad-0ded9ac09931',
        option: 'None of the above',
      },
      {
        id: '044966e1-ffa6-40d1-ad51-903f102bd984',
        option: 'All of the above',
      },
    ],
    answers: ['044966e1-ffa6-40d1-ad51-903f102bd984'],
    answer_description:
      'Change unsettles us because we don’t know what it means for the future and how we’ll fare there.',
    type: QuestionType.CHECKBOX,
    wrong_answer_description: '',
    chapter_id: 'e5201a33-f7b2-4a6e-b1bc-11d12628595e',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829sss',
  },
  {
    id: 'ec615502-ed91-49b1-b7cf-c96374931f7f',
    chapter_id: 'e5201a33-f7b2-4a6e-b1bc-11d12628595e',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
    type: QuestionType.SORTING,
    question_description:
      'Often, discomfort with change moves through stages. And, over time, it brings us to a place of acceptance. By recognizing which stage you’re experiencing, you can take the first steps toward a healthy transition. Organize the stages below into their proper order.',
    questions: [
      {
        id: 'f7abe690-8c33-4c57-ad1a-05ac0969ca1e',
        question: 'Shock and Denial',
      },
      {
        id: '7cccc75f-6490-46ad-aa1b-ccffdc6b19b8',
        question: 'Bargaining and Negotiation',
      },
      {
        id: 'c99f4788-6a5c-40d2-b300-dbd5f7b03f34',
        question: 'Anger and Frustration',
      },
      {
        id: '9e278650-e009-4ae7-9706-e82dd77aa325',
        question: 'Acceptance and Moving Forward',
      },
      {
        id: '2eac3c22-4c17-4e9f-a993-e243bd891726',
        question: 'Depression and Detachment',
      },
    ],
    options: [
      {
        id: 'eee2c24a-c53a-4401-a795-480329591fca',
        option: 'Stage 1',
      },
      {
        id: 'c07f15da-7a0c-4bdc-aabf-0f76688c97dd',
        option: 'Stage 2',
      },
      {
        id: '3ecb7cd6-a726-4a80-9a9b-697a98270568',
        option: 'Stage 3 & 4',
      },
      {
        id: '714f3cd9-aca0-47f4-96b8-6b32aaf7d41c',
        option: 'Stage 5',
      },
    ],
    answers: {
      'f7abe690-8c33-4c57-ad1a-05ac0969ca1e': {
        id: 'eee2c24a-c53a-4401-a795-480329591fca',
        option: 'Stage 1',
      },
      '7cccc75f-6490-46ad-aa1b-ccffdc6b19b8': {
        id: '3ecb7cd6-a726-4a80-9a9b-697a98270568',
        option: 'Stage 3 & 4',
      },
      'c99f4788-6a5c-40d2-b300-dbd5f7b03f34': {
        id: 'c07f15da-7a0c-4bdc-aabf-0f76688c97dd',
        option: 'Stage 2',
      },
      '9e278650-e009-4ae7-9706-e82dd77aa325': {
        id: '714f3cd9-aca0-47f4-96b8-6b32aaf7d41c',
        option: 'Stage 5',
      },
      '2eac3c22-4c17-4e9f-a993-e243bd891726': {
        id: '3ecb7cd6-a726-4a80-9a9b-697a98270568',
        option: 'Stage 3 & 4',
      },
    },
  },
  {
    id: 'b13dbfa1-7c1c-4073-a4fa-d1786ea32905',
    chapter_id: 'e5201a33-f7b2-4a6e-b1bc-11d12628595e',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
    type: QuestionType.MATCHING,
    question_title:
      'One strategy for adapting to change is to write down your emotions. You can’t move toward acceptance by pushing your feelings down. To move forward in a healthy way, you must recognize where you are and understand why you feel that way. Below are some emotions you may feel in response to change.',
    question_description: 'Match the emotion to a potential "why."',
    questions: [
      {
        question: {
          id: '64a775ae-68a7-47ca-a430-a00124d3ffad',
          question: 'I feel left in the dark. I don’t know why this is happening.',
        },
        answer: {
          id: '16aaccd0-a2d1-4300-94ca-ade747fa796c',
          option: 'Fear',
        },
        correct_answer: {
          id: '03b9537a-dcb6-4a05-a710-87b8d2f5d1d0',
          option: 'Anger',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: 'bbf92e56-48c1-4424-877b-753cbaced385',
          question: 'I don’t have time to adapt.',
        },
        answer: {
          id: '713d2695-af9d-4606-9450-80492430e218',
          option: 'Sadness',
        },
        correct_answer: {
          id: '16aaccd0-a2d1-4300-94ca-ade747fa796c',
          option: 'Fear',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: '514c275f-aa21-4bf0-a060-d4f5eecf8d02',
          question: 'I’ll have fewer opportunities to see my coworkers.',
        },
        answer: {
          id: '03b9537a-dcb6-4a05-a710-87b8d2f5d1d0',
          option: 'Anger',
        },
        correct_answer: {
          id: '713d2695-af9d-4606-9450-80492430e218',
          option: 'Sadness',
        },
        index: 2,
        matched: false,
      },
    ],
    answer_description:
      'By compartmentalizing your emotions in this way, you’ll take the first step toward understanding them and making them less overwhelming.',
  },
  {
    id: 'ffa738dc-ae42-4b9b-9217-444016ca49b0',
    question_description:
      'During periods of change, we can get sidetracked by all the things we can’t control. Is the below statement true or false?',
    question:
      'By being unproductive and not addressing what you can control, your concern will shrink, and the impending change will start to feel more manageable.',
    options: [
      {
        id: '53db1dd6-600d-4216-a3e0-3bb45760bfaa',
        option: 'True',
      },
      {
        id: '81f7736e-09be-41e6-b980-18895d6fe2d0',
        option: 'False',
      },
    ],
    answers: ['81f7736e-09be-41e6-b980-18895d6fe2d0'],
    answer_description:
      'One of the strategies for accepting change is to focus on what you can control. Consider your concerns, identify which you can do something about, and then address them.',
    type: QuestionType.RADIO,
    chapter_id: 'e5201a33-f7b2-4a6e-b1bc-11d12628595e',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829sss',
  },
  {
    id: '426f39af-ea4b-4dfc-ac05-d9a5dead902f',
    chapter_id: 'e5201a33-f7b2-4a6e-b1bc-11d12628595e',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
    type: QuestionType.MATCHING,
    question_description: 'Match the emotion to a potential "why."',
    question_title:
      'Change introduces the unknown, which is a major stressor. So, to counteract those emotions, seek information. Be proactive and don’t wait for details to fall into your lap. Below are some concerns you may have.',
    questions: [
      {
        question: {
          id: 'c5078cb4-359b-4503-baff-7cc618eed03b',
          question: 'Why is this change happening?',
        },
        answer: {
          id: 'a492f400-fe86-4237-9675-e3a940d81b66',
          option: 'I won’t be able to work with my old team.',
        },
        correct_answer: {
          id: '04d4778e-15d3-4d1d-a48a-adba8db17324',
          option: 'This change is pointless.',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: 'd2b15ce7-1ac1-40ec-9691-1992e5ed2101',
          question: 'Will I receive training?',
        },
        answer: {
          id: '9cf2c736-00b3-4612-9584-543bdd96c5ee',
          option: 'I don’t know how to use that software.',
        },
        correct_answer: {
          id: '9cf2c736-00b3-4612-9584-543bdd96c5ee',
          option: 'I don’t know how to use that software.',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: '5cbb1020-4105-4017-8586-27450b04976d',
          question: 'Will I have opportunities to see my former coworkers?',
        },
        answer: {
          id: '04d4778e-15d3-4d1d-a48a-adba8db17324',
          option: 'This change is pointless.',
        },
        correct_answer: {
          id: 'a492f400-fe86-4237-9675-e3a940d81b66',
          option: 'I won’t be able to work with my old team.',
        },
        index: 2,
        matched: false,
      },
    ],
    answer_description:
      'When addressing concerns with change, it is important to use the resources around you to ask clarifying questions. Remove obscurity, and the upcoming change will feel less daunting.',
  },
  {
    id: 'e6d1c5e8-5393-4d7d-8b09-8c8eb7b19747',
    question_description:
      'Another strategy to accepting change is to consider your outlook. There are two ways of looking at everything: You can think negatively, or you can think positively. New, doesn’t always mean worse. Consider the scenario below and pick the answer that will help you change your outlook on the situation.',
    question:
      'One day, your manager announces the company plans on moving from one file-sharing website to another. You immediately feel uncomfortable with this impending change, as you have used the current website for years and are very familiar with it. You do not understand the need for a new system and negatively suspect the company is solely changing it due to costs. How might you improve your outlook in this situation?',
    options: [
      {
        id: 'bf502a05-ff3c-45ae-886d-3b770a09ce5d',
        option:
          'You make no attempt to accept the change at all and inform your boss you will not be switching.',
      },
      {
        id: '6c4ad6cb-70a4-4c12-9b54-4debb3300c43',
        option:
          'You bite your tongue and go along with the change, but make no attempt to accept it.',
      },
      {
        id: '00f63e34-a513-47ab-a87f-13d99bfb134e',
        option:
          'You sit down to make a list of positives this change may bring and use the info seeking strategy to better understand the benefits of the change.',
      },
    ],
    answers: ['00f63e34-a513-47ab-a87f-13d99bfb134e'],
    wrong_answer_description:
      'While it is nice to dream of a world where we can continue with our same routines, change will happen whether we like it or not. This response may paint you as stubborn and resistant to change.',
    answer_description:
      'This is the best approach. By focusing on the benefits of the change, you are more likely to view it with a positive outlook, feel optimistic about the change, and prep yourself to learn a new skill.',
    type: QuestionType.RADIO,
    chapter_id: 'e5201a33-f7b2-4a6e-b1bc-11d12628595e',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829sss',
  },
];

export const course_chat_questions: CourseChatQuestion[] = [
  {
    title: "You've just been informed that your company is undergoing restructuring.",
    content:
      'The restructuring includes a shift to remote work, new team dynamics, and altered project scopes. Initially, the news hits you like a wave of cold water—unexpected and shocking. ',
    id: '563f0026-98e3-461c-bba4-b5c34151992e',
    question: '',
    question_img: '',
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: '40909258-081c-48a5-8767-2b8ebfc85fdc',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    title: "After the initial shock wears off, you're left navigating a sea of emotions",
    content:
      'You find yourself cycling through a whirlwind of feelings: anxiety, excitement, confusion, and even a bit of relief at the thought of ditching the commute.',
    id: '559bb48a-f225-4544-9c62-d92748cb944c',
    question: '',
    question_img: '',
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: '40909258-081c-48a5-8767-2b8ebfc85fdc',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    id: '2881123c-0b96-4159-bf04-9632553a6e62',
    question: 'How can you effectively deal with these feelings?',
    question_img: char_img_03,
    options: [
      {
        id: '10d0dc5b-688f-40d1-b11f-9ec5a1a76c20',
        option: 'Ignore your emotions, hoping they will go away on their own.',
        description:
          'Not recommended: Ignoring your emotions might make you more stressed and could make it harder for you to adjust to new situations.',
        img: char_img_01,
      },
      {
        id: '1c9bd7d2-a1b0-4ad1-bc80-441c42ec5758',
        option: 'Talk about all your feelings with a coworker right away to get their view.',
        description:
          'Somewhat helpful: Talking to a coworker about how you feel can help and might give you different insights, but its important to understand your own emotions first so you can explain them well.',
        img: char_img_00,
      },
      {
        id: '4209f64c-d7a7-4188-9eeb-fa1dae36b3ee',
        option: 'Write down your feelings and think about why you feel each one.',
        description:
          'Recommended approach: Writing about your feelings and thinking about the reasons behind them helps you deal with your emotions in a positive way, which can make it easier for you to handle changes.',
        img: char_img_02,
      },
    ],
    answer: '4209f64c-d7a7-4188-9eeb-fa1dae36b3ee',
    answers: [],
    answer_description:
      'Recommended approach: Writing about your feelings and thinking about the reasons behind them helps you deal with your emotions in a positive way, which can make it easier for you to handle changes.',
    type: QuestionType.CHAT,
    chapter_id: '40909258-081c-48a5-8767-2b8ebfc85fdc',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    title: '',
    content: `After you've identified your feelings, you realize that some of your worry is because you don't know much about the changes happening. To help, you become more proactive and get more information.`,
    id: '563f0026-98e3-461c-bba4-b5c34151992e',
    question: '',
    question_img: '',
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: '40909258-081c-48a5-8767-2b8ebfc85fdc',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    id: '8e9c5433-f62c-478b-bb45-a72f18ebb6a0',
    question:
      'Considering your worries about moving to working from home, what should you do next?',
    question_img: char_img_03,
    options: [
      {
        id: '671b6e8e-c510-4849-b97a-8e6c9c14a3b9',
        option: 'Wait to hear more details in future meetings.',
        description: 'You’re right! Conflicts happen, but they don’t need to end negatively.',
        img: char_img_06,
      },
      {
        id: 'be5ee717-1ad9-4a4e-aec2-62e1f38311a7',
        option: `Think that everything will work out and avoid asking questions so you don't seem unskilled.`,
        description:
          'Not recommended: Not asking questions might leave you without important information and less ready for the changes, making you feel more out of place.',
        img: char_img_04,
      },
      {
        id: '5ab00952-0995-418c-afee-0e81d4a993ad',
        option: `Make a list of detailed questions about your worries to discuss with your immediate supervisor or manager.`,
        description:
          'Recommended approach: Actively asking detailed questions gives you control and helps you feel more confident about the changes, making things less uncertain.',
        img: char_img_05,
      },
    ],
    answer: '5ab00952-0995-418c-afee-0e81d4a993ad',
    answers: [],
    answer_description:
      'Recommended approach: Actively asking detailed questions gives you control and helps you feel more confident about the changes, making things less uncertain.',
    type: QuestionType.CHAT,
    chapter_id: '40909258-081c-48a5-8767-2b8ebfc85fdc',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    id: '9fe71030-4018-42de-a35f-4ba73163116a',
    question: `You realize that while you can't control everything about this change, there are things you can do. What should you do next?`,
    question_img: char_img_07,
    options: [
      {
        id: '16693ae4-c373-4de0-8a5a-0ea32e674bbf',
        option: 'Try to make your home exactly like an office',
        description: `Not recommended: Trying to make your home just like an office might not work out or be helpful, since it doesn't take into account the good parts of working from home.`,
        img: char_img_08,
      },
      {
        id: '69c32915-9bfe-4893-b0d0-20164b25d206',
        option: 'Put effort into creating a nice home office',
        description:
          'Recommended approach: Making a comfortable work area at home and setting clear boundaries are things you can control, which helps you adjust and stay productive.',
        img: char_img_05,
      },
      {
        id: '9828fa84-3401-4b01-8afe-40bba037cfea',
        option: 'Focus on the worry that team togetherness and work efficiency might get worse.',
        description: `Somewhat helpful: It's normal to be concerned about possible problems, but spending too much time on these worries can make you feel stuck and miss out on actions you can take to make things better.`,
        img: char_img_09,
      },
    ],
    answer: '69c32915-9bfe-4893-b0d0-20164b25d206',
    answers: [],
    answer_description:
      'Recommended approach: Making a comfortable work area at home and setting clear boundaries are things you can control, which helps you adjust and stay productive.',
    type: QuestionType.CHAT,
    chapter_id: '43472023-17df-4363-8878-9d18fdfd9b74',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
  },
  {
    id: '9ee2fbd7-e5ec-4564-8384-cc4d081d91d5',
    question: `Instead of focusing on what might go wrong, you decide to think about the good things this change can bring. What should you focus on?`,
    question_img: char_img_03,
    options: [
      {
        id: 'aa7739b4-faa7-4fba-8c65-8aad2209c5c2',
        option: `The idea that this change might not last long and things will go back to how they were, so there's no need to really get used to the new way.`,
        description: `Not recommended:  This type of thinking could leave you unprepared if the change is for the long haul.`,
        img: char_img_11,
      },
      {
        id: 'c4258733-8ba6-49a2-994a-f9ad066ef275',
        option:
          'Advantages like saving time on commuting, having a more flexible schedule and the coziness of working from your own space, to keep a positive mindset.',
        description:
          'Recommended approach: Focusing on the good points such as schedule flexibility and the comfort of your home can help change how you see things, making the switch easier and more pleasant.',
        img: char_img_10,
      },
      {
        id: '8eb4bbde-9ccd-4abe-8fd7-974ad38764bc',
        option:
          'C.	The possible negatives and problems of the change, to be ready for the toughest scenarios. ',
        description:
          'Somewhat helpful: Being cautious about possible issues is wise, but only looking at the downsides can make you miss out on the good opportunities the change brings, making it harder for you to adjust.',
        img: char_img_09,
      },
    ],
    answer: 'c4258733-8ba6-49a2-994a-f9ad066ef275',
    answers: [],
    answer_description:
      'Recommended approach: Focusing on the good points such as schedule flexibility and the comfort of your home can help change how you see things, making the switch easier and more pleasant.',
    type: QuestionType.CHAT,
    chapter_id: '43472023-17df-4363-8878-9d18fdfd9b74',
    course_id: '0b1b086c-3f2e-430c-bff6-75097a79761e',
  },

  // chapter 2 chat questions
  {
    title: 'Workplace Change',
    content:
      'Kia just moved from working part-time at a small coffee shop to his first office job at an exciting new company.',
    id: '7ad19a20-64de-42ce-8c7e-4b82e4ba2d21',
    question: '',
    question_img: chapter2_chat_char1,
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: '8b8d1c69-6b57-448e-a31c-c94a442c6f89',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    title: '',
    content:
      'Kia is already getting used to the lively atmosphere at the office, until one day he receives an email sent to everyone, "Big Changes Ahead!"',
    id: '27c8de9a-e3f1-43eb-b981-80f73a8e0fdf',
    question: '',
    question_img: chapter2_chat_char1,
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: '8b8d1c69-6b57-448e-a31c-c94a442c6f89',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    id: '75dd357f-f1a1-4ef9-a9b0-5d15977d8a10',
    question:
      'After reading the email about the changes, which of the following do you think is most likely Kia’s reaction?',
    question_img: chapter2_chat_char2,
    options: [
      {
        id: '5a3941b7-5790-459e-9b76-73c6a63681a9', // correct
        option: 'Nervous, because you’re not sure how these changes will impact your position.',
        description:
          'It’s normal to feel nervous about job-related changes due to uncertainty. Acknowledging these feelings can help open up discussions and ease the transition.',
        img: chapter2_chat_char4,
      },
      {
        id: 'de371fe9-955e-42d8-b5a0-2d89bf8c03d2',
        option: 'Excited, looking forward to new possibilities that might arise.',
        description:
          'Excitement for change is positive, but it’s also crucial to be realistic and prepare for potential challenges. Optimism should go hand in hand with strategizing for obstacles.',
        img: chapter2_chat_char5,
      },
      {
        id: 'b38d5ed6-19e2-4ea8-aca2-a4b2437c36f8',
        option:
          'Indifferent, since you think these changes will not impact your everyday responsibilities.',
        description:
          'Not caring can be dangerous; it’s important to pay attention to changes to adjust well.',
        img: chapter2_chat_char5,
      },
    ],
    answer: '5a3941b7-5790-459e-9b76-73c6a63681a9',
    answers: [],
    answer_description:
      'It’s normal to feel nervous about job-related changes due to uncertainty. Acknowledging these feelings can help open up discussions and ease the transition.',
    type: QuestionType.CHAT,
    chapter_id: '8b8d1c69-6b57-448e-a31c-c94a442c6f89',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    id: '581c42d3-218f-4d42-9270-7b3dc958cb45',
    question:
      'After processing his initial feelings, Kia realizes it’s time to understand more about these changes. What’s Kia’s next step to understand the changes better?',
    question_img: chapter2_chat_char3,
    options: [
      {
        id: '88a2df1d-1acc-4a11-bfc4-ea14dd987d02',
        option: 'Wait for more information to be accounced in the coming weeks.',
        description:
          'Waiting passively might increase anxiety and leave you unprepared. In times of changes, it pays to be more proactive.',
        img: chapter2_chat_char5,
      },
      {
        id: 'ae994baa-b503-4243-aaa3-d45fb1616f96',
        option: 'Talk to yours supervisor to get more details.', // correct
        description:
          'Seeking information directly from your supervisor is proactive and ensures you receive clear, accurate guidance. This approach shows responsibility and helps promptly resolve any uncertainties.',
        img: chapter2_chat_char4,
      },
      {
        id: 'f7c290b8-e62a-4c6b-9229-f5e58c6b9645',
        option: 'Discuss with colleagues over kunch to hear their opinions',
        description:
          'While discussing with peers can offer valuable insights, there’s a risk of encountering misinformation. Peer advice can be useful, but it’s important to verify it against reliable sources.',
        img: chapter2_chat_char5,
      },
    ],
    answer: 'ae994baa-b503-4243-aaa3-d45fb1616f96',
    answers: [],
    answer_description:
      'Seeking information directly from your supervisor is proactive and ensures you receive clear, accurate guidance. This approach shows responsibility and helps promptly resolve any uncertainties.',
    type: QuestionType.CHAT,
    chapter_id: '8b8d1c69-6b57-448e-a31c-c94a442c6f89',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    id: '09e9a297-1208-47d7-8f18-0055d31550ed',
    question:
      'Kia starts to see the bigger picture of what is happening and realizes that he might require new skills. What should he do?',
    question_img: chapter2_chat_char2,
    options: [
      {
        id: '5c20653f-ad88-46b5-a6b6-9835d13b2440',
        option: 'Hope that these changes won’t impact your current job responsiblities.',
        description:
          'Choosing to ignore possible changes isn’t a proactive approach. Not being open to change can mean missing out on chances to improve and struggling to adapt to new job requirements.',
        img: chapter2_chat_char5,
      },
      {
        id: '822a916d-9e65-46a8-9b80-305959d4895e',
        option: 'Begin searching for online courses to learn any new skills that might be needed.', // correct answer
        description:
          'This positive actions shows you’re dedicated to improving and staying prepared for changes in your work environment.',
        img: chapter2_chat_char4,
      },
      {
        id: '2b9e7aa9-67cd-4517-ae99-dca505975435',
        option: 'Wait for your employer to offer officical training sessions.',
        description:
          'Employer training usually focuses on what the company needs right now. Adding your learning efforts can expand your abilities, keep you up to date in your field, and ensure your stay competitive.',
        img: chapter2_chat_char5,
      },
    ],
    answer: '822a916d-9e65-46a8-9b80-305959d4895e',
    answers: [],
    answer_description:
      'This positive actions shows you’re dedicated to improving and staying prepared for changes in your work environment.',
    type: QuestionType.CHAT,
    chapter_id: '8b8d1c69-6b57-448e-a31c-c94a442c6f89',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    id: 'c536a2cd-d2f5-46da-9a2c-fdd7d7198fd6',
    question:
      'As he prepares for the upcoming changes, he reflects on the potential impacts on your daily routine.  How should he adapt?',
    question_img: chapter2_chat_char3,
    options: [
      {
        id: 'cae1ab2e-b7c0-40e8-8d4f-26cfcf41d692',
        option: 'Keep your usual routine untill the changes start, then adjust as needed',
        description:
          'This might be okay for small changes, but big ones can overwhelm you, leading to too much work, not enough time to learn new things, and more stress.  ',
        img: chapter2_chat_char5,
      },
      {
        id: '3a80b953-3b2e-47c0-88c3-e9b7db0a5da1',
        option: 'Talk to coworkers about following their rotine.',
        description:
          'Just copying someone else’s routine might not work for you. Your job, responsibilities, and the changes you face are unique. What helps one person might not help another. ',
        img: chapter2_chat_char5,
      },
      {
        id: '4e8c021d-6faf-4e54-ab9e-e2ea19bd7954',
        option: 'Make a new daily plan that includes expected changes', // correct answer
        description:
          'Think about how these changes will affect your work, decide what’s most important, and plan your time. Planning ahead makes adjusting easier, helps you learn new things faster, and keeps stress low.  ',
        img: chapter2_chat_char4,
      },
    ],
    answer: '4e8c021d-6faf-4e54-ab9e-e2ea19bd7954',
    answers: [],
    answer_description:
      'Think about how these changes will affect your work, decide what’s most important, and plan your time. Planning ahead makes adjusting easier, helps you learn new things faster, and keeps stress low.  ',
    type: QuestionType.CHAT,
    chapter_id: '8b8d1c69-6b57-448e-a31c-c94a442c6f89',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
  {
    id: '7e9fc1ae-d3a8-49d2-9249-b0b58b622c19',
    question:
      'With a plan starting to form, Kia considers how to maintain a positive outlook through this transition. What could he do to stay positive?',
    question_img: chapter2_chat_char2,
    options: [
      {
        id: 'c6814f97-1db3-4309-8ad8-2b72055da2cb',
        option: 'Think about the good things and chances to grow that these changes could bring. ', // correct answer
        description:
          'See changes as chances to get better at things, you feel more ready to face challenges and keep going. This positive view helps you see problems as chances to learn and get new skills.',
        img: chapter2_chat_char4,
      },
      {
        id: '71e613f1-1cad-4b9a-9cb0-369f8ac41607',
        option: 'Just ignore any bad feelings and keep working hard.',
        description:
          'It might seem like a quick solution, but hiding your feelings can make you more stressed, lead to burnout, and lower your work quality.',
        img: chapter2_chat_char5,
      },
      {
        id: '893ca67a-dfe7-419f-a08a-b142760a2f44',
        option: 'Talk about your worries with your coworkers to feel less stressed.',
        description:
          'Talking about what’s bothering you with your coworkers can help you feel supported and less stressed, but you need to focus on finding solutions, not just talking about problems.',
        img: chapter2_chat_char5,
      },
    ],
    answer: 'c6814f97-1db3-4309-8ad8-2b72055da2cb',
    answers: [],
    answer_description:
      'See changes as chances to get better at things, you feel more ready to face challenges and keep going. This positive view helps you see problems as chances to learn and get new skills.',
    type: QuestionType.CHAT,
    chapter_id: '8b8d1c69-6b57-448e-a31c-c94a442c6f89',
    course_id: 'c0d5913a-ff0d-4e0a-8932-82fad1e35829',
  },
];
