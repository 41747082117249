import React from 'react';

import { Signup as SignupComponent } from '../components/Signup';
export const Signup: React.FC = () => {
  return (
    <div className="login-signup-bg px-4 py-24">
      <SignupComponent />
    </div>
  );
};
