import { useSelector } from 'react-redux';

import { PreEmploymentAssessmentState } from '../redux/slice/pre-employment-assessment/pre-employment-assessment.slice';
import { RootState } from '../redux/store';

export const usePreEmploymentAssessmentContext = (): PreEmploymentAssessmentState => {
  const state: PreEmploymentAssessmentState = useSelector(
    (state: RootState) => state.preEmploymentAssessment,
  );
  return state;
};
