import Cookies, { CookieAttributes } from 'js-cookie';

// Function to get cookie value by name
const getCookie = (name: string): string | undefined => {
  return Cookies.get(name);
};

// Function to set cookie
const setCookie = (name: string, value: string, options?: CookieAttributes): void => {
  Cookies.set(name, value, options);
};

// Function to remove cookie with expiration time
const removeCookie = (name: string): void => {
  Cookies.remove(name);
};

export { getCookie, setCookie, removeCookie };
