import emotional_chapter5_img1 from '../../assets/images/courses/emotional-intelligence/chapter5/emotional_chat_char_img1.png';
import emotional_chapter5_img10 from '../../assets/images/courses/emotional-intelligence/chapter5/emotional_chat_char_img10.png';
import emotional_chapter5_img2 from '../../assets/images/courses/emotional-intelligence/chapter5/emotional_chat_char_img2.png';
import emotional_chapter5_img5 from '../../assets/images/courses/emotional-intelligence/chapter5/emotional_chat_char_img5.png';
import emotional_chapter5_img8 from '../../assets/images/courses/emotional-intelligence/chapter5/emotional_chat_char_img8.png';
import {
  CourseChatQuestion,
  CourseMatchingQuestion,
  CourseQuestion,
  CourseSortingQuestion,
  QuestionType,
} from '../jumpstart-communicator/jumpstart-communicator.types';

export const course_sorting_matching_questions: CourseMatchingQuestion[] = [
  {
    id: 'e8a16e12-76ce-46c6-9ffc-be69c6590f4a',
    chapter_id: 'd5eca8f6-5731-4588-b531-0e596d27adc8', // changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9', // changed
    type: QuestionType.MATCHING,
    questions: [
      {
        question: {
          id: '96c930f1-5675-42bc-91ba-4bde3b5ae904',
          question: 'You’re skeptical of your gut reactions and impulse feelings.',
        },
        answer: {
          id: '983700bb-51c8-4ad5-a8e2-dae4e247ee5c',
          option: 'Empathy', // 3 index-number
        },
        correct_answer: {
          id: '95ae383c-8975-43a6-b811-20246ee148d5',
          option: 'Self-awareness',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: '441abc51-e1e5-4400-9874-0c952a120df3',
          question: 'You manage your emotions and think before you act. ',
        },
        answer: {
          id: 'f894bc44-fba8-4aa3-b7a5-3ad5aea4304b',
          option: 'Social skills', // 4 index-number
        },
        correct_answer: {
          id: 'a85ebb4f-33f0-42b0-91f1-c4b2abba07f1',
          option: 'Self-regulation',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: '6cf13a19-c7fb-4660-82e2-0c5f5227cead',
          question: 'You have a strong personal drive.',
        },
        answer: {
          id: '06ebfae9-cccd-412a-bb75-8ff355515497',
          option: 'Self-motivation', // 2 index-number
        },
        correct_answer: {
          id: '06ebfae9-cccd-412a-bb75-8ff355515497',
          option: 'Self-motivation',
        },
        index: 2,
        matched: false,
      },
      {
        question: {
          id: '7273b563-5287-42ce-a466-4538e9c2db36',
          question: 'You’re skilled at putting yourself in another’s shoes.',
        },
        answer: {
          id: 'a85ebb4f-33f0-42b0-91f1-c4b2abba07f1',
          option: 'Self-regulation', // 1 index-number
        },
        correct_answer: {
          id: '983700bb-51c8-4ad5-a8e2-dae4e247ee5c',
          option: 'Empathy',
        },
        index: 3,
        matched: false,
      },
      {
        question: {
          id: 'a1589c1e-6a96-45a0-a49e-bc249d05b523',
          question: 'You’re adept at creating meaningful interactions with others.',
        },
        answer: {
          id: '95ae383c-8975-43a6-b811-20246ee148d5',
          option: 'Self-awareness', // 0 index-number
        },
        correct_answer: {
          id: 'f894bc44-fba8-4aa3-b7a5-3ad5aea4304b',
          option: 'Social skills',
        },
        index: 4,
        matched: false,
      },
    ],
  },
  {
    id: '87a5d796-17c0-4722-8240-c4df63938653',
    chapter_id: '630bdddb-d573-4c8a-af3c-9a17b8a636fb', // changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9', // changed
    type: QuestionType.MATCHING,
    answer_description:
      'You are in control of your motivation. If you feel like you’ll never achieve your goals, try breaking them down into smaller, more attainable short-term tasks. If you’re comparing yourself to others, remind yourself to track your progress—not someone else’s. And if you’re losing sight of your “why,” remember your motivations by considering the six-cylinders: career, family, finance, health, social, and spiritual.',
    questions: [
      {
        question: {
          id: '9f966787-3740-4ab5-a59e-21c6ebc08de2',
          question: 'I need to break my goal down into smaller pieces.',
        },
        answer: {
          id: 'a5a31228-fdbf-4101-b1dd-18dc8cfbffa5',
          option: 'Why am I even doing this? ',
        },
        correct_answer: {
          id: '7b3c71a3-9034-479f-99e2-1fcb4e5cc7c5',
          option: 'My objective is too big. I’ll never achieve it.',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: '27402807-6e8e-438d-b2c8-d1bbe9b151c9',
          question: 'I need to track my personal progress.',
        },
        answer: {
          id: 'b250ee87-1db5-4145-a369-055d0ed58da3',
          option: 'My coworker Dylan is better than I am.',
        },
        correct_answer: {
          id: 'b250ee87-1db5-4145-a369-055d0ed58da3',
          option: 'My coworker Dylan is better than I am.',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: '16e4a727-b301-4fe4-b326-75c590e59da3',
          question: 'I should consider my motivators with the six-cylinder technique.',
        },
        answer: {
          id: '7b3c71a3-9034-479f-99e2-1fcb4e5cc7c5',
          option: 'My objective is too big. I’ll never achieve it.',
        },
        correct_answer: {
          id: 'a5a31228-fdbf-4101-b1dd-18dc8cfbffa5',
          option: 'Why am I even doing this? ',
        },
        index: 2,
        matched: false,
      },
    ],
  },
  {
    id: '8d3ed889-2432-4fda-bed8-91ab8f8e21ad',
    chapter_id: 'e029a466-ade8-4a26-8f44-0d4ea394e550', // changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9', // changed
    type: QuestionType.MATCHING,
    answer_description: '',
    questions: [
      {
        question: {
          id: '03d7305f-fcf6-4e0f-9948-ea429c1398e1',
          question: 'Whether speaking or listening, I should ask questions.',
        },
        answer: {
          id: '9a0e48de-c658-4f43-b8b0-bc22fcda931b',
          option: 'Collaboration and cooperation',
        },
        correct_answer: {
          id: 'bd04aebb-54f6-4afc-9934-8fb303fe3611',
          option: 'Communication skills',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: '9769ffcc-f903-4474-95d3-835d05401675',
          question: 'Before kicking off a conversation, roles and objectives must be clear.',
        },
        answer: {
          id: '5d9f4766-8e2b-42ca-862f-c64527077b9d',
          option: 'Conflict management',
        },
        correct_answer: {
          id: '9a0e48de-c658-4f43-b8b0-bc22fcda931b',
          option: 'Collaboration and cooperation',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: '23c0eed2-526d-44d4-9f91-4f6749ef9c12',
          question: 'I must tap into a person’s motivations and practice assertive communication.',
        },
        answer: {
          id: 'b66afee3-6152-4711-a3e2-0b48fb6e4139',
          option: 'Inspirational leadership',
        },
        correct_answer: {
          id: '919db184-0379-44d8-8316-93e1aac4043f',
          option: 'Persuasion and influence',
        },
        index: 2,
        matched: false,
      },
      {
        question: {
          id: '1a75c52f-a18c-4d6d-841d-ac37a69bfa20',
          question: 'Employees should know their purpose and have autonomy.',
        },
        answer: {
          id: 'ec71b185-e9d6-4f59-916d-1a574bf3d614',
          option: 'Developing and maintaining relationships',
        },
        correct_answer: {
          id: 'b66afee3-6152-4711-a3e2-0b48fb6e4139',
          option: 'Inspirational leadership',
        },
        index: 3,
        matched: false,
      },
      {
        question: {
          id: 'e6daa7d5-ba47-42d8-8f19-2aff91daefee',
          question: 'The first step is to identify and deal with emotions.',
        },
        answer: {
          id: '919db184-0379-44d8-8316-93e1aac4043f',
          option: 'Persuasion and influence',
        },
        correct_answer: {
          id: '5d9f4766-8e2b-42ca-862f-c64527077b9d',
          option: 'Conflict management',
        },
        index: 4,
        matched: false,
      },
      {
        question: {
          id: 'd77c7d9d-d051-4813-9123-5eeb08f50eb0',
          question: 'I should show an interest in others and communicate empathetically.',
        },
        answer: {
          id: 'bd04aebb-54f6-4afc-9934-8fb303fe3611',
          option: 'Communication skills',
        },
        correct_answer: {
          id: 'ec71b185-e9d6-4f59-916d-1a574bf3d614',
          option: 'Developing and maintaining relationships',
        },
        index: 5,
        matched: false,
      },
    ],
  },
];

export const course_sorting_questions: CourseSortingQuestion[] = [
  {
    id: '587ca2ec-19da-4328-9d44-55894a72ca9c',
    chapter_id: '630bdddb-d573-4c8a-af3c-9a17b8a636fb', // changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
    type: QuestionType.SORTING,
    questions: [
      {
        id: 'f4ea9ad7-43a9-4faf-a938-d3e1f6280ff2',
        question: 'You attend a friend’s birthday party because it’s the right thing to do.',
      },
      {
        id: '507c0f86-c4c1-40fe-b951-f6740644ec68',
        question: 'You exercise because you want to feel healthy.',
      },
      {
        id: '1a92bb70-1f9a-4160-80b7-279aae68528c',
        question: 'You’re training for a race because you want to win a gold medal.',
      },
      {
        id: '388305b7-0432-4c72-a625-2889d80fe2e9',
        question:
          'You take on extra responsibilities at work because you’re hoping for a promotion',
      },
      {
        id: '10e61caa-c506-4cd9-ac7e-4bb82e00de93',
        question: 'You push yourself at work because you love what you do and want to improve.',
      },
      {
        id: '1ae36c28-677b-4827-889f-1d0d33d718b8',
        question: 'You missed your cousin’s wedding but feel pressured to still send a gift.',
      },
    ],
    options: [
      {
        id: 'cddf211f-dfeb-426a-943d-0a2b7f230e96',
        option: 'Obligatory',
      },
      {
        id: '11ff51d4-6956-4044-a0b0-e0b3bf06cc7c',
        option: 'Extrinsic',
      },
      {
        id: '0a046e91-7add-4492-b3ec-74248f3f161e',
        option: 'Intrinsic',
      },
    ],
    answers: {
      'f4ea9ad7-43a9-4faf-a938-d3e1f6280ff2': {
        id: 'cddf211f-dfeb-426a-943d-0a2b7f230e96',
        option: 'Obligatory',
      },
      '507c0f86-c4c1-40fe-b951-f6740644ec68': {
        id: '0a046e91-7add-4492-b3ec-74248f3f161e',
        option: 'Intrinsic',
      },
      '1a92bb70-1f9a-4160-80b7-279aae68528c': {
        id: '11ff51d4-6956-4044-a0b0-e0b3bf06cc7c',
        option: 'Extrinsic',
      },
      '388305b7-0432-4c72-a625-2889d80fe2e9': {
        id: '11ff51d4-6956-4044-a0b0-e0b3bf06cc7c',
        option: 'Extrinsic',
      },
      '10e61caa-c506-4cd9-ac7e-4bb82e00de93': {
        id: '0a046e91-7add-4492-b3ec-74248f3f161e',
        option: 'Intrinsic',
      },
      '1ae36c28-677b-4827-889f-1d0d33d718b8': {
        id: 'cddf211f-dfeb-426a-943d-0a2b7f230e96',
        option: 'Obligatory',
      },
    },
  },
];

export const course_questions: CourseQuestion[] = [
  {
    id: 'a2098850-219d-4452-96f0-3a6775cf5463',
    question:
      'After asking others for anonymous feedback, you discover that they think you’re prone to angry outbursts. You want to be better. What’s something you can do to improve your self-awareness?',
    type: QuestionType.RADIO,
    options: [
      {
        id: '1dc96b4b-34b4-414d-8aad-dca9b83ecb97',
        description:
          'Suppressing your emotions is rarely useful. By bottling up how you feel, your feelings may come out later in unexpected ways—potentially leading to even more violent outbursts. Instead, it’s best to acknowledge and accept your feelings as they happen. That way, you can better deal with them',
        option:
          'These outbursts are dangerous. Now that I’m aware of them, I should make a conscious effort to suppress negative emotions.',
      },
      {
        id: 'a5663cae-6884-443c-80d5-57b48670fccc',
        description:
          'That’s it! To better control your emotions, you must first be aware of them. Rather than holding your feelings in, set aside time to acknowledge them. Then, look back on the experience and reflect on the situation and how you reacted.',
        option:
          'I should accept my feelings as they happen, analyze them, and then later reflect on the experience.',
      },
      {
        id: 'ffc53a1d-f3b1-4dea-a578-f35e55dfc7ff',
        option: 'I should ask “why” questions like, “Why do I lash out?”',
        description:
          'Not quite. “Why” questions can lead to false assumptions and self-blaming. Instead, try asking, “What?” For example: “What situations make me feel angry?” and “What can I do to handle those emotions more effectively?”',
      },
    ],
    answers: ['a5663cae-6884-443c-80d5-57b48670fccc'],
    answer_description:
      'That’s it! To better control your emotions, you must first be aware of them. Rather than holding your feelings in, set aside time to acknowledge them. Then, look back on the experience and reflect on the situation and how you reacted.',
    chapter_id: '9339fd90-854d-4757-91cd-ce240fb8ef8b', // changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
  },
  {
    id: 'a5184b8e-e39e-441a-ad68-ea2be0fbf4ec',
    question:
      'You need to have a difficult conversation with one of your board members. This person tends to get angry—and you tend to match that anger. <br/><br/>You’re worried that you’ll both say things that you will later regret. Below, check off all of the strategies that you can use to regulate your emotional responses.',
    options: [
      {
        id: '7d477cbd-293b-4be2-8802-c06b2b2fd018',
        description:
          'To start, you should prepare for the situation. That way, you can anticipate potential negative reactions. Next, during the conversation, don’t ignore your emotions. Instead, STOPP: pause, take a breath, observe your emotions, and pull back before responding. Also, practice cognitive reappraisal by reframing the situation so that you can see things from another angle.',

        option:
          'Worrying about the situation will only make it worse. Rather than preparing, I should deal with the emotions as they come.',
      },
      {
        id: '5a314ad2-8c6e-4f84-9fe2-0e33ca5fe02a',
        description:
          'To start, you should prepare for the situation. That way, you can anticipate potential negative reactions. Next, during the conversation, don’t ignore your emotions. Instead, STOPP: pause, take a breath, observe your emotions, and pull back before responding. Also, practice cognitive reappraisal by reframing the situation so that you can see things from another angle.',

        option: 'I should push my emotions aside to avoid potentially irrational responses.',
      },
      {
        id: 'df70c1af-b008-43a1-9153-d6fc1634cb69',
        description:
          'To start, you should prepare for the situation. That way, you can anticipate potential negative reactions. Next, during the conversation, don’t ignore your emotions. Instead, STOPP: pause, take a breath, observe your emotions, and pull back before responding. Also, practice cognitive reappraisal by reframing the situation so that you can see things from another angle.',

        option:
          'If I feel angry, I should pause, take a breath, observe how I’m feeling, and pull back before taking action.',
      },
      {
        id: '5c1a7820-fa88-4362-b069-6dfa47c38fe7',
        description:
          'To start, you should prepare for the situation. That way, you can anticipate potential negative reactions. Next, during the conversation, don’t ignore your emotions. Instead, STOPP: pause, take a breath, observe your emotions, and pull back before responding. Also, practice cognitive reappraisal by reframing the situation so that you can see things from another angle.',

        option:
          'I should try to see things from the other person’s perspective and reframe the situation in my mind.',
      },
    ],
    answers: ['5c1a7820-fa88-4362-b069-6dfa47c38fe7', 'df70c1af-b008-43a1-9153-d6fc1634cb69'],
    answer_description:
      'To start, you should prepare for the situation. That way, you can anticipate potential negative reactions. Next, during the conversation, don’t ignore your emotions. Instead, STOPP: pause, take a breath, observe your emotions, and pull back before responding. Also, practice cognitive reappraisal by reframing the situation so that you can see things from another angle.',
    type: QuestionType.CHECKBOX,
    chapter_id: '3f0f5e32-3bb7-41d2-80e7-32793b55a10b', // changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
  },
];

export const course_chat_questions: CourseChatQuestion[] = [
  {
    title: 'Meet Anton',
    content:
      'This is your team member, Anton. He never speaks up during team meetings, and you need him to participate. So, you’ve decided to talk to him one-on-one and have an empathetic conversation.',
    id: '9996c2b6-ba69-4488-9ac8-b003cc14d985',
    question: '',
    question_img: emotional_chapter5_img1,
    options: [],
    answer: '',
    answers: [],
    answer_description: '',
    type: QuestionType.INFOBLOCK,
    chapter_id: 'b56d8d23-4081-4293-9c3a-be1a5fca2a2f',
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
  },
  {
    id: '62f27195-fb57-4c2d-a63c-8aa43ec7a752',
    question:
      'It’s not that I don’t have anything to say. It’s just that I often feel shy and overshadowed during these large group discussions.',
    question_img: emotional_chapter5_img2,
    options: [
      {
        id: 'dcf83010-621c-44e5-bb8d-837a451e43d4',
        option:
          'Why don’t you try what I do? I commit to saying the first thing on my mind. I don’t self-censor. Remember, you don’t need to have all of the answers.',
        description:
          'You don’t understand. That might work for you, but it’s not who I am. I’ve never been someone who just blurts something out. I like to think things through.',
        img: emotional_chapter5_img5,
        feedback_title: 'Everyone’s Experience Is Different',
        feedback_content:
          'If you only think about what you would do, then you’re unlikely to understand where someone else is coming from and what they need. Remember, everyone’s experience is different.',
      },
      {
        id: 'ac389a32-ed7f-42cb-9220-80c5215a5c44',
        feedback_title: 'Relate to Feelings. Cater Your Solutions.',
        feedback_content:
          'Great job! By relating to Anton’s feelings, you can better understand his perspective. And, with that understanding, you can then devise empathetic solutions catered to what he needs as an individual.',

        option:
          'I understand what it’s like to feel overshadowed. Let’s get to the bottom of these emotions so that we develop some improvement tips together.',
        description:
          'Thank you! It’s nice to know that I’m not alone in feeling this way. I like the idea of finding solutions that are catered to my needs!',
        img: emotional_chapter5_img10,
      },
      {
        id: '767e46ac-e7b1-4b98-a0a3-078da82dc0d5',
        option:
          'I understand that you’re struggling, and I pity your situation. But you still need to try to speak up. This is a workplace, after all.',
        description:
          'I am trying. My silence isn’t because of a lack of effort. You don’t understand what this feels like for me.',
        img: emotional_chapter5_img8,
        feedback_content:
          'Feeling pity for Anton is a form of sympathy, not empathy. To develop empathy, you also need to understand those feelings and consider how you can help Anton as an individual.',
        feedback_title: 'Express Empathy, Not Sympathy',
      },
    ],
    answer: '',
    answers: [
      '767e46ac-e7b1-4b98-a0a3-078da82dc0d5',
      'ac389a32-ed7f-42cb-9220-80c5215a5c44',
      'dcf83010-621c-44e5-bb8d-837a451e43d4',
    ],
    answer_description:
      'I am trying. My silence isn’t because of a lack of effort. You don’t understand what this feels like for me.',
    type: QuestionType.CHAT,
    chapter_id: 'b56d8d23-4081-4293-9c3a-be1a5fca2a2f',
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
  },
];

export const course_quiz_questions: (
  | CourseSortingQuestion
  | CourseMatchingQuestion
  | CourseQuestion
)[] = [
  {
    id: '9cd651ae-f067-464c-9773-629cd03e09e6',
    question_description: 'Select the correct answers below',
    question:
      'EQ measures your ability to ____, ____, and ____ your emotions and the emotions of others.',
    options: [
      {
        id: '18171cde-6ac6-4055-9914-24921d3ed010',
        description:
          'Identifying, understanding, and managing yours and other’s emotions are the corner stones of emotional intelligence. Assessing your capacity for these behaviors is key to improving your EQ.',

        option: 'Identifying',
      },
      {
        id: 'ad1b2d49-3412-42a4-ab99-5fe46203c017',
        description:
          'Identifying, understanding, and managing yours and other’s emotions are the corner stones of emotional intelligence. Assessing your capacity for these behaviors is key to improving your EQ.',

        option: 'Delegating',
      },
      {
        id: '52927be9-851c-4176-bad5-f98ecd79d0c3',
        description:
          'Identifying, understanding, and managing yours and other’s emotions are the corner stones of emotional intelligence. Assessing your capacity for these behaviors is key to improving your EQ.',

        option: 'Understanding',
      },
      {
        id: 'eafcba3d-4ca7-4cea-b2cb-c2f30045e140',
        description:
          'Identifying, understanding, and managing yours and other’s emotions are the corner stones of emotional intelligence. Assessing your capacity for these behaviors is key to improving your EQ.',

        option: 'Managing',
      },
    ],
    answers: [
      '18171cde-6ac6-4055-9914-24921d3ed010',
      '52927be9-851c-4176-bad5-f98ecd79d0c3',
      'eafcba3d-4ca7-4cea-b2cb-c2f30045e140',
    ],
    answer_description:
      'Identifying, understanding, and managing yours and other’s emotions are the corner stones of emotional intelligence. Assessing your capacity for these behaviors is key to improving your EQ.',
    type: QuestionType.CHECKBOX,
    chapter_id: '94d3c511-f514-4a7d-8e97-1d1230d99ca3', //changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
  },
  {
    id: '6b767067-bb5c-41ec-b868-51f29c470bab',
    question_description:
      'Emotional intelligence has two areas of competence: personal and social. Select all items that apply to each competence area.',
    question:
      'Personal competence is how well you recognize and manage your emotions. It covers which three elements?',
    options: [
      {
        id: 'f0ab8262-3786-4be4-b799-098299cb2278',
        description:
          'Personal competence covers self-awareness, self-regulation, and self-motivation.',

        option: 'self-awareness',
      },
      {
        id: '14c14068-c5f3-4d9e-a22d-ae33e8b976cf',
        description:
          'Personal competence covers self-awareness, self-regulation, and self-motivation.',

        option: 'self-direction',
      },
      {
        id: '411513e1-1a81-4352-997b-52769d8e67ff',
        description:
          'Personal competence covers self-awareness, self-regulation, and self-motivation.',

        option: 'self-regulation',
      },
      {
        id: 'f32973ff-3acc-4f9d-a0c1-80d2ea03b49c',
        description:
          'Personal competence covers self-awareness, self-regulation, and self-motivation.',

        option: 'self-motivation',
      },
    ],
    answers: [
      'f0ab8262-3786-4be4-b799-098299cb2278',
      '411513e1-1a81-4352-997b-52769d8e67ff',
      'f32973ff-3acc-4f9d-a0c1-80d2ea03b49c',
    ],
    wrong_answer_description:
      'Personal competence covers self-awareness, self-regulation, and self-motivation.',
    answer_description: 'That is correct',
    type: QuestionType.CHECKBOX,
    chapter_id: '94d3c511-f514-4a7d-8e97-1d1230d99ca3', //changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
  },
  {
    id: 'ba27c854-d02b-4404-8f5f-657c9ecb8eca',
    question_description: ' ',
    question:
      'Social competence is how well you recognize and manage others emotions. It covers which two elements?',
    options: [
      {
        id: 'ea086160-ba03-49a0-a991-3ad57472fa63',
        description: 'That is correct',

        option: 'Empathy',
      },
      {
        id: 'be72c463-e69d-48ea-a9d2-968b72855523',
        description: 'Social competence covers empathy and social skills.',

        option: 'Sympathy',
      },
      {
        id: '0c03d91f-2636-47e5-8b8a-bd1a16af1cba',
        description: 'That is correct',

        option: 'Social skills',
      },
    ],
    answers: ['ea086160-ba03-49a0-a991-3ad57472fa63', '0c03d91f-2636-47e5-8b8a-bd1a16af1cba'],
    wrong_answer_description: 'Social competence covers empathy and social skills.',
    answer_description: 'That is correct',
    type: QuestionType.CHECKBOX,
    chapter_id: '94d3c511-f514-4a7d-8e97-1d1230d99ca3', //changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
  },
  {
    id: '51b82020-59cb-4506-8e15-9c59ca2b3716',
    question: 'What is not a benefit of using EQ in the workplace?',
    question_description:
      'Workplace success spans more than just intellectual smarts. Top employees are also adept at managing their reactions, gauging others’ needs, and responding appropriately. These are some of the benefits of emotional intelligence. Below are more benefits.',
    type: QuestionType.RADIO,
    options: [
      {
        id: '5f07351b-c9a4-42db-a360-545faa3f7344',
        description:
          'Emotional intelligence, when used in the workplace, allows you to better communicate and empathize with your superiors and coworkers. The goal is not to gain power over them or manipulate their feelings.',
        option: 'Objective decision-making',
      },
      {
        id: '32c0cb4e-534e-4c49-8840-355a68390c17',
        description:
          'Emotional intelligence, when used in the workplace, allows you to better communicate and empathize with your superiors and coworkers. The goal is not to gain power over them or manipulate their feelings.',
        option: 'Fewer information gaps',
      },
      {
        id: 'eaf4d5cf-772e-4b84-9cd7-ec078dba8fc0',
        option: 'Power over coworkers',
        description:
          'Emotional intelligence, when used in the workplace, allows you to better communicate and empathize with your superiors and coworkers. The goal is not to gain power over them or manipulate their feelings.',
      },
      {
        id: 'eeef6aab-c4ff-4c0f-9983-89ffc8dd2e50',
        option: 'Effective communication',
        description:
          'Emotional intelligence, when used in the workplace, allows you to better communicate and empathize with your superiors and coworkers. The goal is not to gain power over them or manipulate their feelings.',
      },
      {
        id: '3b666207-a188-4fe7-9f69-e65fd532c2af',
        option: 'Improved morale',
        description:
          'Emotional intelligence, when used in the workplace, allows you to better communicate and empathize with your superiors and coworkers. The goal is not to gain power over them or manipulate their feelings.',
      },
    ],
    answers: ['eaf4d5cf-772e-4b84-9cd7-ec078dba8fc0'],
    wrong_answer_description:
      'Emotional intelligence, when used in the workplace, allows you to better communicate and empathize with your superiors and coworkers. The goal is not to gain power over them or manipulate their feelings.',
    answer_description:
      'Emotional intelligence, when used in the workplace, allows you to better communicate and empathize with your superiors and coworkers. The goal is not to gain power over them or manipulate their feelings.',
    chapter_id: '94d3c511-f514-4a7d-8e97-1d1230d99ca3', // changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
  },
  {
    id: 'e2c692f8-e23a-4040-ad38-e0c972aea240',
    chapter_id: '94d3c511-f514-4a7d-8e97-1d1230d99ca3', // changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
    question_description:
      'Rather than avoiding your feelings or seeking temporary distractions, you’ll need to confront emotions head-on. Below are some actions you can take to improve your self-regulation. There are also negative behaviors that work against self-regulation. Sort the cards into regulating and non-regulating behaviors.',
    type: QuestionType.SORTING,
    questions: [
      {
        id: '52482cbb-cf8f-44c0-a01c-741a50f0ecb0',
        question: 'Take Time to STOPP',
      },
      {
        id: '15af1fcb-26ce-4851-8596-c06d930744d4',
        question: 'Prepare for the Emotion',
      },
      {
        id: 'a20466ed-82cc-498e-a036-48c199016f3f',
        question: 'Act on impulsive emotions',
      },
      {
        id: 'c44db6ee-2f85-4bc8-98e9-01d0fdc4aaa4',
        question: 'Practice Mindful Observation',
      },
      {
        id: '75ca3506-4f3c-46d0-930a-e80d53d5ece9',
        question: 'Assume negative opinions about other’s behaviors',
      },
      {
        id: 'f0783844-82a9-46b2-9c17-66e844982f34',
        question: 'Ignore or deny the emotion',
      },
      {
        id: '705eb501-c80a-43f8-8132-ae1abbda2b7d',
        question: 'Practice Letting Go',
      },
      {
        id: '19d4b1a9-9fe7-45f2-89f9-f8b377681a93',
        question: 'Reframe Through Cognitive Reappraisal',
      },
      {
        id: '0b482bab-8767-4388-997c-252e6471b55a',
        question: 'Let the emotion escalate',
      },
    ],
    options: [
      {
        id: 'ff351a5b-fa5f-4249-a1a2-c489ca082107',
        option: 'Regulating',
      },
      {
        id: '6015d989-c6da-4ec7-81b7-f00f2a0f8a33',
        option: 'Non-regulating',
      },
    ],
    answers: {
      '52482cbb-cf8f-44c0-a01c-741a50f0ecb0': {
        id: 'ff351a5b-fa5f-4249-a1a2-c489ca082107',
        option: 'Regulating',
      },
      '15af1fcb-26ce-4851-8596-c06d930744d4': {
        id: 'ff351a5b-fa5f-4249-a1a2-c489ca082107',
        option: 'Regulating',
      },
      'a20466ed-82cc-498e-a036-48c199016f3f': {
        id: '6015d989-c6da-4ec7-81b7-f00f2a0f8a33',
        option: 'Non-regulating',
      },
      'c44db6ee-2f85-4bc8-98e9-01d0fdc4aaa4': {
        id: 'ff351a5b-fa5f-4249-a1a2-c489ca082107',
        option: 'Regulating',
      },
      '75ca3506-4f3c-46d0-930a-e80d53d5ece9': {
        id: '6015d989-c6da-4ec7-81b7-f00f2a0f8a33',
        option: 'Non-regulating',
      },
      'f0783844-82a9-46b2-9c17-66e844982f34': {
        id: '6015d989-c6da-4ec7-81b7-f00f2a0f8a33',
        option: 'Non-regulating',
      },
      '705eb501-c80a-43f8-8132-ae1abbda2b7d': {
        id: 'ff351a5b-fa5f-4249-a1a2-c489ca082107',
        option: 'Regulating',
      },
      '19d4b1a9-9fe7-45f2-89f9-f8b377681a93': {
        id: 'ff351a5b-fa5f-4249-a1a2-c489ca082107',
        option: 'Regulating',
      },
      '0b482bab-8767-4388-997c-252e6471b55a': {
        id: '6015d989-c6da-4ec7-81b7-f00f2a0f8a33',
        option: 'Non-regulating',
      },
    },
  },
  {
    id: '8ad7da79-87f8-47a0-b9e2-6274dca45a59',
    chapter_id: '94d3c511-f514-4a7d-8e97-1d1230d99ca3', // changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9', // changed
    question_title:
      'Motivation inspires us to act. It helps us accomplish what needs to get done, stay energized, and move forward even when an easier or more attractive option exists. We can cultivate motivation from many sources, some external and some internal. Below are three types of motivators and their descriptions.',
    question_description: ' Match the motivator to its description.',
    type: QuestionType.MATCHING,

    answer_description:
      'You are in control of your motivation. If you feel like you’ll never achieve your goals, try breaking them down into smaller, more attainable short-term tasks. If you’re comparing yourself to others, remind yourself to track your progress—not someone else’s. And if you’re losing sight of your “why,” remember your motivations by considering the six-cylinders: career, family, finance, health, social, and spiritual.',
    questions: [
      {
        question: {
          id: '35fb6a90-e053-4d09-88f8-2d5ee96beae3',
          question: 'Comes from a sense of duty and ethics.',
        },
        answer: {
          id: '09121571-c635-486e-87db-5f1258434c56',
          option: 'Intrinsic',
        },
        correct_answer: {
          id: 'e1eb335b-6c45-467d-9805-4a21c2ac6c9f',
          option: 'Obligatory',
        },
        index: 0,
        matched: false,
      },
      {
        question: {
          id: '41e45255-968e-464c-9863-3fae5074341e',
          question:
            'Comes from outside. You’re motivated to action because you expect an external reward.',
        },
        answer: {
          id: 'b3f5214f-2e53-4c30-87b1-bc651a7720ea',
          option: 'Extrinsic',
        },
        correct_answer: {
          id: 'b3f5214f-2e53-4c30-87b1-bc651a7720ea',
          option: 'Extrinsic',
        },
        index: 1,
        matched: false,
      },
      {
        question: {
          id: '60d0e17c-dd1b-4be0-84e4-fe1d0ed1a0f9',
          question: 'Comes from within. You strive toward individual fulfillment.',
        },
        answer: {
          id: 'e1eb335b-6c45-467d-9805-4a21c2ac6c9f',
          option: 'Obligatory',
        },
        correct_answer: {
          id: '09121571-c635-486e-87db-5f1258434c56',
          option: 'Intrinsic',
        },
        index: 2,
        matched: false,
      },
    ],
  },
  {
    id: 'e239fc4a-db66-49d1-95ac-e570796529b1',
    question_description:
      'Knowing that empathy requires you to place yourself in other peoples shoes to understand their situation and emotions, how would you react in the following scenario to show empathy with your coworker?',
    question:
      '7. Your coworker’s wife has recently given birth to the couple’s first child. Your coworker has expressed frustration with lack of sleep and being unable to calm their infant. It is clearly affecting his attitude at work and he has been unable to complete an assignment you asked him to finish, which has set you back on your deadlines. What would you say to him to show empathy for his circumstances while expression your deadline needs?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'a4f1aedb-b888-4d59-874f-3406de330ff7',
        description:
          'This is a highly unempathetic response. This statement implies you find his situation as a mere inconvenience and that you are only thinking of yourself and your needs.',
        option:
          '“That sounds annoying, but I really need that document. Can"t you just finish it?”',
      },
      {
        id: '3688a686-731b-46a8-96ec-9c8e9131b319',
        option:
          '“I"ve never had a child, but I can relate, having taken care of my young niece for a couple weeks. I understand the lack of sleep is making it hard to complete that assignment. Let me know what I can do to help get that assignment completed.” ',
        description:
          'This is the most empathetic response. You are acknowledging his situation by thinking of a similar situation you have been in yourself. Offering assistance in completing the assignment also shows you understand finishing it on time may not be within his ability at that time.',
      },
      {
        id: '9575b29d-3a58-4f15-ab3f-b0b4f123b633',
        option:
          '“I"m sorry to hear that. Can you give me a potential timeline for when you"ll have it done so I can adjust my schedule?”',
        description:
          'While this response is sympathetic, it does not demonstrate your understanding of his situation. While adjusting your schedule is a nice offer, it does little to help him or keep a productive pace in the workplace.',
      },
    ],
    answers: ['3688a686-731b-46a8-96ec-9c8e9131b319'],
    answer_description:
      'This is the most empathetic response. You are acknowledging his situation by thinking of a similar situation you have been in yourself. Offering assistance in completing the assignment also shows you understand finishing it on time may not be within his ability at that time.',
    chapter_id: '94d3c511-f514-4a7d-8e97-1d1230d99ca3', // changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
  },
  {
    id: '8cc35c7c-224c-4ddf-8ef2-564918093c62',
    question_description:
      'Top workplace performers know how to communicate with others and maintain positive relationships. They’re adept at navigating through the shaky waters of conflict and change. Social skills are defined by several hallmarks.',
    question: 'Check the hallmarks of good social skills.Communication',
    options: [
      {
        id: 'a2b8b170-bf50-4897-b676-b279b39d32ff',
        description:
          'Each of these items define good social skills and are useful tools within the workplace.',

        option: 'Communication',
      },
      {
        id: 'dc668c8f-82cb-4c6e-8a10-860edac8b669',
        description:
          'Each of these items define good social skills and are useful tools within the workplace.',

        option: 'Collaboration and Cooperation',
      },
      {
        id: 'ab207bdf-b8eb-4d74-b8b8-b7ca5c302f9e',
        description:
          'Each of these items define good social skills and are useful tools within the workplace.',

        option: 'Persuasion and Influence',
      },
      {
        id: 'bd161983-1025-4ba5-88b4-23e66643edd0',
        description:
          'Each of these items define good social skills and are useful tools within the workplace.',

        option: 'Inspirational Leadership',
      },
      {
        id: '64abc544-8cb7-46a6-a562-17d591deacac',
        description:
          'Each of these items define good social skills and are useful tools within the workplace.',

        option: 'Conflict Management',
      },
      {
        id: '3882f62d-3699-40ce-961c-177a3fd43f5f',
        description:
          'Each of these items define good social skills and are useful tools within the workplace.',

        option: 'Developing and Maintaining Relationships',
      },
      {
        id: 'ae3d510c-79fb-4810-9b2d-32107cbc0290',
        description:
          'Each of these items define good social skills and are useful tools within the workplace.',

        option: 'Change Management',
      },
    ],
    answers: [
      'a2b8b170-bf50-4897-b676-b279b39d32ff',
      'dc668c8f-82cb-4c6e-8a10-860edac8b669',
      'ab207bdf-b8eb-4d74-b8b8-b7ca5c302f9e',
      'bd161983-1025-4ba5-88b4-23e66643edd0',
      '64abc544-8cb7-46a6-a562-17d591deacac',
      '3882f62d-3699-40ce-961c-177a3fd43f5f',
      'ae3d510c-79fb-4810-9b2d-32107cbc0290',
    ],
    wrong_answer_description:
      'Each of these items define good social skills and are useful tools within the workplace.',
    answer_description:
      'Each of these items define good social skills and are useful tools within the workplace.',
    type: QuestionType.CHECKBOX,
    chapter_id: '94d3c511-f514-4a7d-8e97-1d1230d99ca3', //changed
    course_id: '8302a4a4-f22a-4cdb-8254-c0122317f6c9',
  },
];
