import { CourseQuestion, QuestionType } from './jumpstart-communicator.types';

export const course_questions: CourseQuestion[] = [
  {
    id: '3f993231-4de6-430f-8312-165e9bad8787',
    question:
      'When you need to explain a tricky or complex process to somone who has just joind the team, what is the bes way to make sure they rellay get it?',
    type: QuestionType.RADIO,
    options: [
      {
        id: '91e8d3e0-1816-4e90-9127-56636389eda9',
        option:
          'Start by giving them the big picture of how things work, using technical terms to sound more formal an serious.',
      },
      {
        id: '3a530217-f3b3-4a85-a1b9-43605e21da64',
        option:
          'Give them a quick rundown of how things work without diving int hte specifies, to keep it brief',
      },
      {
        id: '2e6fd4fb-47b5-4949-a160-db8edc323225',
        option: 'Break down the process into step-by-step instructions using everyday language',
      },
      {
        id: '3c71e93a-4f73-40bb-9cc9-fa90bb1afeed',
        option:
          'Point them towards the written guides, so they can go through everything on their own time, at their own speed.',
      },
    ],
    answers: ['2e6fd4fb-47b5-4949-a160-db8edc323225'],
    answer_description:
      'The most effective way to ensure understanding, especially for someone new, is to break down complex information into clear, step-bystep instructions using simple language (C).',
    chapter_id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '3c71e93a-4f73-40bb-9cc9-fa90bb1afeed',
    question:
      'At a team meeting, you’re trying to convince your team to start using a new app for work. Which statement do you think would work best to persuade them?',
    type: QuestionType.RADIO,
    options: [
      {
        id: '8cd45c3f-60a2-4040-a99a-38687ccd134f',
        option: '"This app is user-friendly and is highly recommended by other professionals."',
      },
      {
        id: '6b4edb30-b594-4c35-8563-00621c9dfce8',
        option: '"I believe this tool could make our projects go smoother."',
      },
      {
        id: 'bdd432ec-7b78-43bf-a7a3-37b5302e5427',
        option:
          '"Using this app could make us 30% faster, allowing us to meet deadlines consistently."',
      },
      {
        id: '38b4a484-fc33-4020-83df-19d5202ecf29',
        option: 'O "Many companies are switching to this tool because it’s new and popular."',
      },
    ],
    answers: ['bdd432ec-7b78-43bf-a7a3-37b5302e5427'],
    answer_description:
      'Providing specific benefits and quantifiable improvements is the most persuasive approach as it directly relates to the team’s goals.',
    chapter_id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: 'd6133810-4e75-41f9-90b6-5729e9e4c460',
    question:
      'You receive helpful suggestions or feedback through an email. What does it mean that they chose email to tell you this?',
    type: QuestionType.RADIO,
    options: [
      {
        id: '37fec0b2-5523-4a51-914a-494edb940e66',
        option: 'The advice is just for you, giving you a chance to think it over.',
      },
      {
        id: 'be7d963b-7b5c-42ba-802e-2bf0652ce455',
        option: 'The advice is very important and you need to act on it right away.',
      },
      {
        id: '0eb4d410-8ca3-48f2-af17-c3b62677a3b0',
        option: 'The advice is just casual, nothing too serious.',
      },
      {
        id: '6f2a66f2-8af7-48ee-b0a3-de0d537ec36f',
        option:
          'The advice is official and might be brought up when checking how well you’re doing at work.',
      },
    ],
    answers: ['37fec0b2-5523-4a51-914a-494edb940e66'],
    answer_description:
      'Email allows for private communication and allows the recipient to process the feedback at their own pace, making option A the correct interpretation.',
    chapter_id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: 'e67e1132-dbe0-4658-b0d6-acec9c356a59',
    question:
      'You are working on a project with Lucy, who often starts discussions with phrases like "Let’s outline the main steps" and "What are the main goals?". What communication style does Lucy use?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'afdff05c-f71e-4521-8310-63e6bcd17d81',
        option: 'Idea-Based',
      },
      {
        id: 'f4d73cb9-5e61-46cf-87c6-f55621fc3c09',
        option: 'Action-Based',
      },
      {
        id: '645987f8-3bfb-4da5-b6de-d7b7ca601db6',
        option: 'People-Based',
      },
      {
        id: 'b94c4903-d9b6-4300-936d-0e9a06ce1302',
        option: 'Fact-Based',
      },
    ],
    answers: ['f4d73cb9-5e61-46cf-87c6-f55621fc3c09'],
    answer_description:
      'Action-based communicators focus on tasks, results, and actions, often employing direct language about plans, steps, and deadlines, much like Lucy does in this scenario.',
    chapter_id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: 'ff4dff04-d84c-4854-a6c1-e4d0f251e05d',
    question:
      'You are suggesting a new project plan to someone at work known for a Fact-Based communication style. What is the best way to communicate?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'b8c6cb1f-db20-4cee-ae2b-e6578fe4d34d',
        option: 'Start with a step-by-step plan and explain why each part matters.',
      },
      {
        id: '2fafd8f6-44ca-43a1-a557-27d7434389a3',
        option: 'O Talk about how the project might make the team feel.',
      },
      {
        id: '0ce6060f-55d2-4480-a85b-e192818d1f49',
        option: 'Give the big picture of the project first, then dive into the specifics.',
      },
      {
        id: '94431f0c-351c-42c0-8d65-ea4753d111bc',
        option: 'Point out what needs to be done right away to get things moving.',
      },
    ],
    answers: ['b8c6cb1f-db20-4cee-ae2b-e6578fe4d34d'],
    answer_description:
      'Fact-based communicators appreciate systematic, logical presentations of information, so the most effective approach is a detailed outline with rationales.',
    chapter_id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '61b88e47-21b6-4803-ac03-68ac04f7f736',
    question:
      'You’re in charge of a group where everyone has their own way of talking and sharing ideas. You have a new project and need to give out jobs. Alex, one of your team members, is known for their action-based communication style. How can you make sure Alex really gets what you need done for an important job?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'd16eee12-a7cc-4354-96d9-64cba873a005',
        option:
          '"Alex, we have a goal for this project and I want you to lead this part. I trust you to figure out the best way to do it, and I’m here if you need help."',
      },
      {
        id: 'c82e33d6-ac85-4fc4-b2cb-bcf6fede1dd1',
        option:
          '"Alex, the job I’m giving you is really important for our project. It has a few steps, and I want us to review them together to make sure we’re on the same page."',
      },
      {
        id: '20522c64-7e4f-4a48-b7c3-2d5bb7bc11af',
        option:
          '"Alex, I’m counting on you for this important job. It needs to be done by next Friday, and I’d like updates along the way. Let’s aim for the finish line we’ve set."',
      },
      {
        id: 'f866bd86-430d-4341-a499-794e3d79cfdb',
        option:
          '"Alex, with your skills, this job is a great match for you. It’s part of our big plan for the project and will need some creative thinking and new ideas."',
      },
    ],
    answers: ['20522c64-7e4f-4a48-b7c3-2d5bb7bc11af'],
    answer_description:
      'Direct, task-oriented, and focuses on results, aligning with an action-based communication style.',
    chapter_id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: 'dfd1b04a-92a5-4b91-a086-1ab2376eec50',
    question:
      'You have to share an update about your project with your team, and it includes some detailed data analysis. What’s the best way to share this information with them?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'bab93732-b9a1-4628-9f4d-21bd7866763f',
        option: 'In-person meeting',
      },
      {
        id: 'cb65407c-9ea4-467a-a4b5-17ca45937973',
        option: 'O Quick phone call',
      },
      {
        id: '88d47646-c77e-48c6-a9a0-98b375e05e9c',
        option: 'Detailed email',
      },

      {
        id: '6fa8c1fc-86d8-492e-aab0-be73ab64caa3',
        option: 'Group text message',
      },
    ],
    answers: ['88d47646-c77e-48c6-a9a0-98b375e05e9c'],
    answer_description:
      'A detailed email allows for the complex data to be laid out in an organized manner, which can be referred to later. An in-person meeting might also be suitable, but the email provides a permanent record of the data shared.',
    chapter_id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: 'a7206338-364f-435c-acc2-05a447b8d400',
    question: 'When is the best time to use a phone call to talk to someone at work? ',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'bab93732-b9a1-4628-9f4d-21bd7866763f',
        option: 'When you have a complicated project report that needs a thorough look-over.',
      },
      {
        id: 'cb65407c-9ea4-467a-a4b5-17ca45937973',
        option: 'When you’re giving a detailed update that should be written down.',
      },
      {
        id: '595b4983-2de7-45bc-aeff-cfe28d8071c1',
        option: 'When talking about private matters with many people.',
      },
      {
        id: 'e11c144d-b62e-4f12-89ad-8bf9129ea8d6',
        option: 'When you quickly need someone’s thoughts on something important.',
      },
    ],
    answers: ['e11c144d-b62e-4f12-89ad-8bf9129ea8d6'],
    answer_description:
      'Phone calls are best for urgent communications requiring immediate attention and direct interaction, unlike situations requiring documentation or involving sensitive topics with larger groups.',
    chapter_id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '17e4e3ba-d9f3-4257-8111-f14e51c5b768',
    question:
      'During a team meeting, you see that one of your co-workers looks troubled, but they haven’t said anything about it. What’s the best way to figure out what’s wrong and help with the situation?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'bf327cd0-0c47-48d3-9f2a-c7598a653afd',
        option: 'Ignore their nonverbal cues to avoid disagreements.',
      },
      {
        id: '71c467e4-04e2-49a4-9d3f-80f91127ccee',
        option: 'Talk for them, guessing you understand what’s upsetting them.',
      },
      {
        id: '543790e0-11fc-4b6b-acd5-985f79e609f2',
        option: 'Ot they handle their feelings more professionally.',
      },
      {
        id: '89028bab-2a9d-4560-8eb2-aa21be276e36',
        option: 'Ask direct, open-ended questions.',
      },
    ],
    answers: ['89028bab-2a9d-4560-8eb2-aa21be276e36'],
    answer_description:
      'Asking open-ended questions allows the colleague to express themselves, acknowledging their nonverbal cues without making assumptions or judgments, fostering open and empathetic communication.',
    chapter_id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '9dc18a4d-69b6-41d5-88a1-1c2df99951ee',
    question:
      'At your part-time job, your team is excited about starting a cool new promotion that’s totally different from what you’ve done before. But when you tried it out with a few customers, you found out that some parts of it didn’t really work for them. Now, you need to let your team know what’s up so you all can figure out how to make the promotion better. How would you share what you learned with your team?',
    type: QuestionType.RADIO,
    options: [
      {
        id: '2fd68cb2-d0cc-4968-9dd3-eacf3858da3c',
        option:
          'Share the exact comments from the customers in the team’s group chat and ask for quick thoughts and ideas.',
      },
      {
        id: '69d7a97b-3d86-4200-bf90-22dd28b96595',
        option:
          'Write a detailed email to your team explaining what the customers said, pointing out the bits that need a second look, and set up a time to talk about how to make the promotion better.',
      },
      {
        id: '874e7832-b7c3-41a2-867c-10f3e77b9188',
        option:
          'Have a brief meeting with all team members to discuss customer feedback. Utilize your understanding and notes to facilitate a conversation on enhancing the promotion.',
      },
      {
        id: 'f3e4c2fb-1ad2-4903-8386-77138aabd3d8',
        option:
          'Send a short message to your team saying that the promotion needs some changes, but not going into specifics just yet, planning to go over it in more detail at your next team catch-up.',
      },
    ],
    answers: ['69d7a97b-3d86-4200-bf90-22dd28b96595'],
    answer_description:
      'The email provides a clear, organized summary of feedback and sets the stage for a focused discussion on revisions, aligning with effective written communication practices.',
    chapter_id: '97619d13-8985-40d6-b16b-3ecd1e67567f',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
];
