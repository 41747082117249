import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import elite_logo from '../assets/images/elite-logo.png';
import corporation_card from '../assets/images/landing/corporation_card.svg';
import group_4253_2x from '../assets/images/landing/Group 4253@2x.png';
import group_4254_2x from '../assets/images/landing/Group 4254@2x.png';
import group_4256_2x from '../assets/images/landing/Group 4256@2x.png';
import school_card from '../assets/images/landing/school_card.svg';
import vl1 from '../assets/images/landing/vl1.svg';
import vl2 from '../assets/images/landing/vl2.svg';
import vl3 from '../assets/images/landing/vl3.svg';
import Container from '../components/container/Container';
import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
import { CustomUser } from '../redux/slice/auth/CustomUser';
import { dispatchUpdateNewsletterFormSignup } from '../redux/slice/form-newsletter/form-newsletter.operations';
import { useAppDispatch } from '../redux/store';
import logger from '../services/logger';

const Landing: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organization = queryParams.get('organization');
  const user: CustomUser | null = useAuthContext();
  const isAuthenticated: boolean = user ? true : false;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [newsletterSubscribed, setNewsletterSubscribed] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const signupButtonClick = async () => {
    logger.debug('Submitted newsletter signup.', email);
    setIsLoading(true);
    const sanitizedEmail: string = email.trim().toLowerCase();
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(sanitizedEmail)) {
      await dispatch(dispatchUpdateNewsletterFormSignup({ email: sanitizedEmail }));
      setNewsletterSubscribed(true);
    } else {
      console.log('invalid valid');
      setIsLoading(false);
      setError('Invalid email address');
    }
  };
  useEffect(() => {
    // TODO: create utility function for this because it's used in multiple places
    if (organization) {
      Cookies.set('organization', organization, { path: '/' });
    }
  }, [organization]);

  useEffect(() => {
    setError('');
  }, [email]);

  return isAuthenticated ? (
    <Navigate to="/pre-employment/start" />
  ) : (
    <>
      <div className="banner-wrap relative flex items-center py-10">
        <Container>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="banner-content">
              <h1 className="p-0 md:pr-24">
                <span>Empowering</span> young adults with job-ready
                <span> skills</span> for their future.
              </h1>
              {!newsletterSubscribed ? (
                <>
                  <h4 className="mt-2 mb-4 pr-24">
                    Enter your email to sign up for our free newsletter and stay up-to-date!
                  </h4>
                  <div className="signup-wrap relative">
                    <input
                      className="p-5 border w-full shadow-lg rounded-xl pr-34"
                      type="text"
                      maxLength={100}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email address"
                    />
                    <button
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={signupButtonClick}
                      disabled={isLoading}
                      className="btn-primary signup-btn py-3 px-7 text-white rounded-xl"
                    >
                      Sign Up
                    </button>
                  </div>
                </>
              ) : (
                <div>
                  <h1 className="pt-20 pr-24">Thank You!</h1>
                </div>
              )}
              {error && <p className="pt-1 text-red-500">{error}</p>}
            </div>
          </div>
        </Container>
      </div>
      <section className="vl-wrap px-0 md:pt-32 md:pb-16">
        <Container>
          <h1 className="uppercase text-center">
            vero <span className="font-semibold">Learning</span>
          </h1>
          <h2 className="uppercase text-center text-3xl mb-8 mt-3">
            is an ai based talent optimization platform
          </h2>
          <h4 className="text-center text-xl md:w-3/4 mx-auto  mb-8">
            We use AI to improve vocational career readiness skill- connecting young adults with
            hiring managers for first round interviews
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="vero-learning-card text-center p-5 md:p-12 hover:shadow-2xl rounded-3xl">
              <img className=" mx-auto" src={vl1} alt="" />
              <h5 className="text-xl font-semibold mt-5 mb-4">Assess and Sharpen Skills</h5>
              <p>Assignment tools to evaluate user aptitude, 'stackable skills', and experience</p>
            </div>
            <div className="vero-learning-card text-center p-5 md:p-12 hover:shadow-2xl rounded-3xl">
              <img className=" mx-auto" src={vl2} alt="" />
              <h5 className="text-xl font-semibold mt-5 mb-4">
                AI-powered Personalized Career Pathways
              </h5>
              <p>
                AI is used to track KPIs to create Smart Profiles and to match top applicants with
                guaranteed first-round interviews.
              </p>
            </div>
            <div className="vero-learning-card text-center p-5 md:p-12 hover:shadow-2xl rounded-3xl">
              <img className=" mx-auto" src={vl3} alt="" />
              <h5 className="text-xl font-semibold mt-5 mb-4">
                Connect Applicants and Hiring Managers
              </h5>
              <p>
                Match applicants with companies facing major skill gaps and labor shortages for
                customer facing entry-level positions.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section className="mt-12">
        <Container>
          <div className="theme-gradient rounded-xl px-12 py-6">
            <div className="flex items-center justify-between flex-wrap">
              <h1 className="uppercase text-white font-thin">
                vero learning was selected in
                <br /> <span className="font-bold">the elite 200 gsv!</span>
              </h1>
              <img className="w-56" src={elite_logo} alt="" />
            </div>
          </div>
        </Container>
      </section>
      <section className="mt-20 mb-24">
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="card flex flex-wrap lg:flex-nowrap items-center">
              <img src={corporation_card} alt="" />
              <div className="pl-0 lg:pl-8">
                <h2 className="mb-3">Corporations</h2>
                <p>
                  Empowering businesses to identify and engage talent with enhanced job-ready skills
                  through our AI-driven platform, streamlining the hiring process for a promising
                  workforce.
                </p>
              </div>
            </div>
            <div className="card flex items-center  flex-wrap lg:flex-nowrap">
              <img src={school_card} alt="" />
              <div className="pl-0 lg:pl-8">
                <h2 className="mb-3">Schools</h2>
                <p>
                  Collaborating with educational institutions to integrate AI-powered vocational
                  training, fostering career growth and employability among students.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="bridge-wrap pt-8 md:pt-24">
        <Container>
          <div className="m-auto lg:w-3/4">
            <div className="md:px-20">
              <h1>
                Bridge the gap between <span>classroom learning</span> and
                <span> job placement</span>
              </h1>
              <h4 className="mt-4 mb-8">
                Transform the future of technical education with Vero Learning's talent optimization
                platform, empowering young adults to build job-ready skills and bridge the skills
                gap to secure fulfilling careers.
              </h4>
            </div>
            <div>
              <div className="grid grid-cols-1 md:grid-cols-3 three-card">
                <img src={group_4253_2x} alt="" />
                <img src={group_4254_2x} alt="" />
                <img src={group_4256_2x} alt="" />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Landing;
