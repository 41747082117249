import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
//import Typography from '@mui/material/Typography';
import _ from 'lodash';
import * as React from 'react';

interface TabsBlockProps {
  tabsData: {
    title: string;
    textColor?: string;
    bgColor?: string;
    audioUrl?: string;
  }[];
  children?: React.ReactNode;
  onTabChange: (value: number) => void;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const CustomAudioTabsBlock: React.FC<TabsBlockProps> = (props: TabsBlockProps) => {
  const [value, setValue] = React.useState<number | null>(null);
  const { children, tabsData, onTabChange } = props;
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  React.useEffect(() => {
    const audioElement = document.getElementById('audioElement') as HTMLAudioElement;
    if (value !== null && !_.isNil(tabsData[value]?.audioUrl)) {
      audioElement.src = tabsData[value].audioUrl ?? '';
      void audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  }, [value, tabsData]);
  return (
    <div className="preferencesTabContainer w-full border rounded-2xl overflow-hidden">
      <Box>
        <Tabs
          sx={{ columnGap: '10px' }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabsData.map(({ title, bgColor }, index) => (
            <Tab
              className={
                bgColor
                  ? ` !text-white !font-bold flex-1 ${bgColor}`
                  : 'preferencesTab !text-white !font-bold flex-1'
              }
              key={index}
              label={title}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {!_.isNil(value) && <>{children}</>}
      <audio hidden id="audioElement" controls />
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number | null;
}

export const CustomTabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tab-body"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          {children}
          {/* <Typography></Typography> */}
        </Box>
      )}
    </div>
  );
};
