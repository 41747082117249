import { BarChart } from '@mui/x-charts/BarChart';
import * as React from 'react';

export const StackableSkillsBarChart: React.FC = () => {
  return (
    <BarChart
      xAxis={[{ scaleType: 'band', data: ['skill 1', 'skill 2', 'skill 3', 'skill 4', 'skill 5'] }]}
      series={[{ data: [64, 60, 85, 50, 60] }]}
      width={500}
      height={300}
    />
  );
};
