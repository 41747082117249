import quoteImg from '../../../../assets/images/courses/communication-basics/chapter14/quoteImg.jpg';
import thankYou from '../../../../assets/images/courses/communication-basics/chapter14/thankYou.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { MediaBlock } from '../../MediaBlock';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const CommunicationBasicsChapter14: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 break-words">
        <h2 className="font-semibold text-3xl">Summary</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock mediaImage={thankYou}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            Thank you for completing the module.
          </p>
        </MediaBlock>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">Key Takeaways</h3>
        <p className="mb-8">
          Strong communication skills are critical to your professional success. Almost every
          interaction you have at work requires effective communication—whether you want to share
          information, pitch an idea, collaborate with your team, or develop a rapport with
          customers and colleagues.
          <br />
          <br />
          To become an expert communicator, you must learn how to leverage and hone all three types
          of communication. This includes:
        </p>
        <ul className="list-decimal pl-7 mt-8 mb-10 text-xl">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Verbal communication.</span> Improving your verbal
            communication skills will help you conduct better conversations, meetings, phone calls,
            and presentations. Remember to speak clearly, use the right tone, and practice active
            listening when someone else is speaking.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Written communication.</span> Enhancing your written
            communication skills is critical to writing effective business emails, messages,
            reports, letters, and other content. Your writing should always have a clear purpose, be
            tailored to your audience, and use an appropriate medium. Also, always proofread and
            edit your work. Look for spelling and grammatical errors and a coherent structure, and
            check your writing style or tone.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Nonverbal communication.</span> Lastly, don’t forget about
            how you come across through nonverbal communication cues like your facial expressions,
            body language, appearance, and actions. Make sure that all of these elements support the
            message you’re trying to convey—not contradict it.
          </li>
        </ul>
        <MediaBlock mediaImage={quoteImg}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            Communication works for those who work at it."
            <br />
            <span className="text-base">JohnPowell</span>
          </p>
        </MediaBlock>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          Communication Is Comprehensive
        </h3>
        <p className="mb-8">
          Communication is comprehensive. How you—or your message—come across to others is equally
          comprised of what you say, how you say it, and what you write. Keep practicing the tips
          and techniques you learned throughout this course, and you’ll be well on your way to
          becoming a more effective verbal, written, and nonverbal communicator.
        </p>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">References</h3>
        <p className="mb-8">
          To make sure you’re not glancing over common errors, follow these five tips—just remember
          to edit first and proofread second.
        </p>
        <div className="fr-view">
          <p>
            Abrahams, R. &amp; Groysberg, B. (2021). How to Become a Better Listener. Harvard
            Business Review
          </p>
          <br />
          <p>
            Andreev, Ivan. (2023). Types of Communication. Valamis Article: Updated Mar 14, 2023
          </p>
          <br />
          <p>
            Coursera. (2023). What Is Active Listening and How Can You Improve This Key Skill?
            Article: Updated Jun 15, 2023.
          </p>
          <br />
          <p>
            Indeed. (2023). How To Improve Your Business Writing Skills in 15 Ways: Article: Updated
            Feb 3, 2023.{' '}
            <a
              href="https://www.indeed.com/career-advice/career-development/how-to-improve-business-writing#:~:text=How%20to%20improve%20your%20business%20writing%20skills%201,in%20your%20call%20to%20action%20...%20More%20items"
              rel="noopener noreferrer"
              target="_blank"
            >
              Link here<span className="visually-hidden-always">(opens in a new tab)</span>
            </a>{' '}
            &nbsp;
          </p>
          <br />
          <p>
            MasterClass. (2021). Verbal vs. Nonverbal Communication Explained. Article: Last
            updated: Dec 21, 2021.
            https://www.masterclass.com/articles/verbal-vs-nonverbal-communication
          </p>
          <br />
          <p>
            O’Hara, Carolyn. (2014). How to Improve Your Business Writing. Harvard Business Review
            Article: Nov 20, 2014
          </p>
          <br />
          <p>
            Sharp Emerson, Mary. (2021). 8 Ways You Can Improve Your Communication Skills. Harvard
            Division of Continuing Education Blog.
          </p>
          <br />
          <p>
            University of California, Berkeley. Strategies for Effective Listening.
            https://hr.berkeley.edu/sites/default/files/strategies_for_effective_listening.pdf
          </p>
          <br />
          <p>
            Vain, Claire. (2021). Different Methods of Communication. Article: Updated on Apr 20,
            2023. https://cpdonline.co.uk/knowledge-base/business/different-communication-methods/
          </p>
        </div>

        <p className="text-2xl text-center leading-10 font-light mb-10 mt-10">
          Click below to check your knowledge. Once you have completed the quiz you can close the
          browser window to exit module.
        </p>

        <button onClick={handleNextChapterClick} className="btn-primary w-full uppercase">
          QUIZ
        </button>
      </div>
    </>
  );
};
