import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { UserEngagementType } from '../../redux/slice/user-engagement/user-engagement.type';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.USER_ENGAGEMENT;

async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

export async function getUserEngagementRecord(): Promise<UserEngagementType> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<UserEngagementType> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return result.data() ?? {};
  } catch (e) {
    logger.error('Failed to retrieve user engagement.', e);
    throw e;
  }
}

export async function updateUserEngagementRecord(dto: UserEngagementType): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved user course question.');
  } catch (e) {
    logger.error('Failed to update user course question.', e);
    throw e;
  }
}
