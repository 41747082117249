import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import { PreEmploymentAssessment } from './pre-employment-assessment.types';

export type PreEmploymentAssessmentState = Partial<PreEmploymentAssessment>;

const initialState: PreEmploymentAssessmentState = {};

const preEmploymentAssessmentSlice = createSlice({
  name: 'preEmploymentAssessment',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<PreEmploymentAssessment>) => {
      logger.debug('Updating pre-employment assessment state.', {
        action,
        state,
      });
      Object.assign(state, { ...action.payload });
    },
  },
});

export const { update: updatePreEmploymentAssessmentAction } = preEmploymentAssessmentSlice.actions;
export default preEmploymentAssessmentSlice.reducer;
