import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import AboutUs from '../../pages/AboutUs';
import { AsaUserInfoPage } from '../../pages/AsaUserInfo';
import { CareerReadinessPage } from '../../pages/CareerReadiness';
import ContactUs from '../../pages/ContactUs';
import { CoursesPage } from '../../pages/Course';
import { CoursesDetailPage } from '../../pages/CourseDetails';
import { CoursesListPage } from '../../pages/CoursesList';
import { EmploymentReadinessPage } from '../../pages/EmploymentReadiness';
import { ExperiencePage } from '../../pages/Experience';
import { ForgotPassword } from '../../pages/ForgotPassword';
import { FreeResources } from '../../pages/FreeResources';
import { IntakePage } from '../../pages/Intake';
import Landing from '../../pages/Landing';
import { Login } from '../../pages/Login';
import { MyResourcesPage } from '../../pages/MyResources';
import { OrganizationAdminDashboardPage } from '../../pages/OrganizationDashboard';
import { PaymentFailedPage } from '../../pages/PaymentFailed';
import { PaymentSuccessPage } from '../../pages/PaymentSuccess';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import { Signup } from '../../pages/Signup';
import { SkillsPage } from '../../pages/Skills';
import { StartPage } from '../../pages/Start';
import { SWOTPage } from '../../pages/SWOT';
import SwotReportDownload from '../../pages/SwotReportDownload';
import TermsAndConditions from '../../pages/TermsAndConditions';
import { UpdatePasswordPage } from '../../pages/UpdatePassword';
import { UpgradePage } from '../../pages/Upgrade';
import { UserDashboardPage } from '../../pages/UserDashboard';
import { UserProfilePage } from '../../pages/UserProfile';
import { UserProfileUpdatePage } from '../../pages/UserProfileUpdate';
import CourseLayout from '../Layout/CourseLayout';
import { DashboardLayoutComponent } from '../Layout/DashboardLayout';
import Layout from '../Layout/Layout';
import { OrganizationAdminLayout } from '../Layout/OrganizationAdminLayout';

const AppRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        <Route index path="" element={<Landing />} />
        <Route index path="/pre-employment" element={<Landing />} />
        <Route index path="/signup" element={<Signup />} />
        <Route index path="/login" element={<Login />} />
        <Route index path="/reset-password" element={<ForgotPassword />} />
        <Route index path="/pre-employment/start" element={<StartPage />} />
        <Route path="/pre-employment/intake" Component={IntakePage} />
        <Route path="/pre-employment/intake/:componentName" Component={IntakePage} />
        <Route path="/pre-employment/employment-readiness" Component={EmploymentReadinessPage} />
        <Route
          path="/pre-employment/employment-readiness/:componentName"
          Component={EmploymentReadinessPage}
        />
        <Route path="/pre-employment/skills" Component={SkillsPage} />
        <Route path="/pre-employment/skills/:componentName" Component={SkillsPage} />
        <Route path="/pre-employment/career-readiness" Component={CareerReadinessPage} />
        <Route
          path="/pre-employment/career-readiness/:componentName"
          Component={CareerReadinessPage}
        />
        <Route path="/pre-employment/experience" Component={ExperiencePage} />
        <Route path="/pre-employment/experience/:componentName" Component={ExperiencePage} />
        <Route index path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route index path="/contact-us" element={<ContactUs />} />
        <Route index path="/about-us" element={<AboutUs />} />
        <Route path="/swot" Component={SWOTPage} />
        <Route path="/swot/:componentName" Component={SWOTPage} />
        <Route path="/my-resources/:resourceName?" Component={MyResourcesPage} />
        <Route path="/free-resources/:resourceName?" Component={FreeResources} />
        <Route index path="/user-info" element={<AsaUserInfoPage />} />
        <Route index path="/upgrade" element={<UpgradePage />} />
      </Route>
      <Route path="/" element={<CourseLayout />}>
        <Route path="/courses/:moduleId" Component={CoursesListPage} />
        <Route path="/course/start/:courseId" Component={CoursesPage} />
        <Route path="/course/details/:courseId" Component={CoursesDetailPage} />
      </Route>
      <Route path="/" element={<DashboardLayoutComponent />}>
        <Route index path="/dashboard" element={<UserDashboardPage />} />
        <Route index path="/user-profile" element={<UserProfilePage />} />
        <Route index path="/update-profile" element={<UserProfileUpdatePage />} />
        <Route index path="/update-password" element={<UpdatePasswordPage />} />
      </Route>
      <Route path="/download-swot-report/:userId" element={<SwotReportDownload />} />
      <Route path="/payment-success" element={<PaymentSuccessPage />} />
      <Route path="/payment-failed" element={<PaymentFailedPage />} />
      <Route path="/" element={<OrganizationAdminLayout />}>
        <Route index path="/organization-dashboard" element={<OrganizationAdminDashboardPage />} />
        <Route index path="/change-password" element={<UpdatePasswordPage />} />
      </Route>
    </>,
  ),
);

export default AppRouter;
