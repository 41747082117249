import { Navigate } from 'react-router-dom';

import { useAuthContext, useAuthInitializedContext } from '../../hooks/use-user-auth-context.hooks';
import { CustomUser } from '../../redux/slice/auth/CustomUser';
import { CircularLoader } from '../loader/CircularLoader';

export interface OrgAuthenticatedPageProps {
  render: () => JSX.Element;
}
export const CommonAuthenticatedPage: React.FC<OrgAuthenticatedPageProps> = ({
  render,
}: OrgAuthenticatedPageProps) => {
  const user: CustomUser | null = useAuthContext();
  const isAuthenticated: boolean = user ? true : false;
  const isAuthInitialized: boolean = useAuthInitializedContext();
  if (!isAuthInitialized) {
    return (
      <div>
        <CircularLoader />
      </div>
    );
  } else if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } else {
    return render();
  }
};
