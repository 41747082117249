import { useSelector } from 'react-redux';

import { UserAsaTaskHistoryState } from '../redux/slice/asa-task-history';
import { RootState } from '../redux/store';

export const useUserAsaTaskHistoryContext = (): UserAsaTaskHistoryState => {
  const state: UserAsaTaskHistoryState = useSelector(
    (state: RootState) => state.userAsaTaskHistory,
  );
  return state;
};
