import { Replay } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import {
  CourseChatQuestion,
  QuestionOption,
  QuestionType,
} from '../../courses/jumpstart-communicator/jumpstart-communicator.types';

interface ActionableBlockProps {
  backgroundImage: string;
  courseChatQuestions: CourseChatQuestion[];
  characterImage: string;
  buttonName: string;
  feedbackTitle: string;
  feedbackText: string;
  cssClass?: string;
}
export const ActionableBlock: React.FC<ActionableBlockProps> = ({
  backgroundImage,
  courseChatQuestions,
  characterImage,
  buttonName,
  feedbackTitle,
  feedbackText,
  cssClass,
}) => {
  const [answer, setAnswer] = useState<QuestionOption | null>(null);
  const [isCorrect, setIsCorrect] = useState(false);
  const [chatEnded, setChatEnded] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [feedBackContent, setFeedBackContent] = useState({ title: '', content: '' });
  const [currentQuestion, setCurrentQuestion] = useState<CourseChatQuestion>(
    courseChatQuestions[questionIndex],
  );

  const handleStartChat = () => {
    if (currentQuestion.type === QuestionType.INFOBLOCK) {
      setQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      setQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };
  const handleAnswerSelect = (option: QuestionOption) => {
    if (currentQuestion?.answers?.length) {
      if (currentQuestion.answers.find((answer) => answer === option.id)) {
        const selectedOption = currentQuestion.options.filter((opt) => opt.id === option.id);
        if (selectedOption) {
          setFeedBackContent({
            title: selectedOption[0]?.feedback_title || '',
            content: selectedOption[0].feedback_content || '',
          });
        }
        setIsCorrect(true);
      }
    } else {
      if (option.id === currentQuestion?.answer) {
        setIsCorrect(true);
      }
    }
    setAnswer(option);
  };
  const handleContinue = () => {
    if (courseChatQuestions[questionIndex + 1] && questionIndex <= courseChatQuestions.length) {
      // this check is if there are more questions in index
      setQuestionIndex((prevIndex) => prevIndex + 1);
      setAnswer(null);
      setIsCorrect(false);
    } else {
      setChatEnded(true);
    }
  };
  const handleReplayChat = () => {
    if (chatEnded) {
      setQuestionIndex(0);

      if (questionIndex > courseChatQuestions.length) {
        if (characterImage) {
          currentQuestion.question_img = characterImage;
        }
      }
    } else {
      if (questionIndex > 0 && questionIndex < courseChatQuestions.length) {
        setCurrentQuestion(courseChatQuestions[questionIndex]);
      } else if (questionIndex === 0) {
        setQuestionIndex(0);
      }
    }

    setAnswer(null);
    setChatEnded(false);
    setIsCorrect(false);
  };

  useEffect(() => {
    if (questionIndex >= 0) {
      setCurrentQuestion(courseChatQuestions[questionIndex]);
    }

    if (questionIndex >= courseChatQuestions.length) {
      setChatEnded(true);
    }
  }, [questionIndex]);
  return (
    <div className="relative mt-8 rounded-3xl overflow-hidden mb-14">
      <div
        className={`${cssClass ?? ''} chat-bg min-h-[700px] lg:min-h-[600px]`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(4px)',
        }}
      ></div>
      <div className="chat-wrapper">
        {!chatEnded ? (
          <div
            className={`${
              cssClass ?? ''
            } character-img w-72 mt-20 -ml-20 lg:mt-10 flex-none lg:block`}
          >
            <img
              className=""
              src={
                answer
                  ? answer.img
                  : currentQuestion
                  ? currentQuestion?.question_img
                  : characterImage
              }
              alt=""
            />
          </div>
        ) : (
          <div className="continue-wrap">
            <h2 className="text-3xl mb-5">
              {feedbackTitle ? feedbackTitle : feedBackContent?.title}
            </h2>
            <p className="text-base font-normal">
              {feedbackText ? feedbackText : feedBackContent?.content}
            </p>
            <div className="mt-10 pt-5 border-1 border-t border-gray-700 inline-block">
              <button onClick={handleReplayChat} className="btn-secondary min-w-[140px]">
                START OVER
                <br />
                <Replay />
              </button>
            </div>
          </div>
        )}
        {!chatEnded && (
          <div className={`${cssClass ?? ''} chat-container`}>
            {questionIndex < 0 ? (
              <></>
            ) : answer ? (
              <>
                <div className="user-chat">
                  <p>{answer.option}</p>
                </div>
                <div className={`${cssClass ?? ''} system-chat`}>
                  <p>{answer.description}</p>
                </div>
                {isCorrect ? (
                  <button
                    onClick={handleContinue}
                    className="btn-secondary absolute bottom-0 right-0 min-w-[140px] mr-4 lg:mr-0"
                  >
                    Continue
                  </button>
                ) : (
                  <button
                    onClick={handleReplayChat}
                    className="btn-secondary absolute bottom-0 right-0 min-w-[140px] mr-4 lg:mr-0"
                  >
                    <Replay />
                  </button>
                )}
              </>
            ) : (
              <>
                {currentQuestion?.type === QuestionType.CHAT ? (
                  <div className="system-chat mt-5 lg:mt-10">
                    <p>{currentQuestion?.question}</p>
                  </div>
                ) : (
                  <></>
                )}

                <div className="answer-options">
                  {currentQuestion?.type === QuestionType.INFOBLOCK ? (
                    <div
                      className={currentQuestion?.question_img ? 'continue-wrap' : 'continue-wrap2'}
                    >
                      {currentQuestion?.title && (
                        <h2 className="text-3xl mb-5">{currentQuestion?.title}</h2>
                      )}
                      <p className="text-sm lg:text-base font-normal">{currentQuestion?.content}</p>
                      <button onClick={handleStartChat} className="btn-primary text-base mt-4">
                        {buttonName}
                      </button>
                    </div>
                  ) : (
                    currentQuestion?.options.map((option, index) => (
                      <div
                        onClick={() => handleAnswerSelect(option)}
                        key={option.id}
                        className="ans-option"
                      >
                        <div className="w-10 h-10 flex items-center justify-center rounded-full bg-purple-600 text-white mr-4 flex-none">
                          {index + 1}
                        </div>
                        <p>{option.option}</p>
                      </div>
                    ))
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
