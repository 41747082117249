import { East } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
// Inspired by the former Facebook spinners.
function FacebookCircularProgress(props: CircularProgressProps & { progresscolor: string }) {
  return (
    <div className="flex justify-center">
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: '#e7eff3',
            background: 'white',
            borderRadius: '50%',
            outline: '10px solid white',
          }}
          size={250}
          thickness={4}
          {...props}
          value={100}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{ fontSize: '36px', color: '#617688', fontWeight: 'bold' }}
            variant="caption"
            component="div"
            color=""
          >
            {props.value}%
          </Typography>
        </Box>
        <CircularProgress
          variant="indeterminate"
          //disableShrink
          sx={{
            color: `${props.progresscolor}`,
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,

            top: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={250}
          thickness={4}
          {...props}
        />
      </Box>
    </div>
  );
}
interface CourseProgressBarProps {
  name: string;
  progress: number;
  color: string;
  courseId: string;
  organization: string | undefined;
  isPremium: boolean;
  isPreAssesment: boolean;
  preAssementStep: string;
}
export const CourseProgressBar: React.FC<CourseProgressBarProps> = ({
  name,
  progress,
  color,
  courseId,
  organization,
  isPremium,
  isPreAssesment,
  preAssementStep,
}) => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <FacebookCircularProgress variant="determinate" value={progress} progresscolor={color} />
      </Box>
      <p className="font-semibold text-center mt-3">
        {organization || isPremium ? (
          <Link
            to={isPreAssesment ? preAssementStep : `/course/details/${courseId}`}
            className="course-action cursor-pointer"
          >
            {name}
            <East />
          </Link>
        ) : (
          <>{name}</>
        )}
      </p>
    </>
  );
};
