import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import start1 from '../assets/images/start1.svg';
import start2 from '../assets/images/start2.svg';
import start3 from '../assets/images/start3.svg';
import start4 from '../assets/images/start4.svg';
import { AuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../hooks/use-pre-employment-assessment.hooks';
import { useSWOTContext } from '../hooks/use-swot-context.hooks';
import { useUserAsaTaskHistoryContext } from '../hooks/use-user-asa-task-history-context.hooks';
import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchGetAsaTaskHistoryValues } from '../redux/slice/asa-task-history';
import { CustomUser } from '../redux/slice/auth/CustomUser';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import { dispatchUpsertPreEmploymentAssessment } from '../redux/slice/pre-employment-assessment';
import { dispatchGetSWOTReportValues } from '../redux/slice/swot/swot.operations';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';

export const Start: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { name, organization } = useIntakeFormContext();
  const user: CustomUser | null = useAuthContext();
  const asaUserTaskHistory = useUserAsaTaskHistoryContext();
  const userProfileContext = useUserProfileContext();
  const [isAsaUser, setIsAsaUser] = useState(false);
  const [completedSignup, setCompletedSignup] = useState(false);
  const swot = useSWOTContext();
  const startIntake = () => {
    if (isAsaUser && !completedSignup) {
      navigate('/user-info');
    } else if (isAsaUser && completedSignup) {
      console.log('redirect to task url');
    } else if (preEmploymentAssessment.disablePersonalInfo) {
      navigate('/pre-employment/skills');
    } else if (organization && !preEmploymentAssessment?.didCompleteIntake) {
      navigate('/pre-employment/intake');
    } else {
      navigate('/user-info');
    }
  };

  useEffect(() => {
    void dispatch(dispatchUpsertPreEmploymentAssessment());
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchGetSWOTReportValues());
    void dispatch(dispatchGetUserProfileValues());
  }, []);

  useEffect(() => {
    if (user?.claims.isAsaUser) {
      setIsAsaUser(true);
      void dispatch(dispatchGetAsaTaskHistoryValues());
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(userProfileContext).length && isAsaUser && userProfileContext?.asaUserId) {
      setCompletedSignup(true);
    }
  }, [userProfileContext, asaUserTaskHistory, isAsaUser]);

  const preEmploymentAssessment = usePreEmploymentAssessmentContext();

  useEffect(() => {
    if (userProfileContext?.isPremium || organization) {
      if (Object.keys(preEmploymentAssessment).length && name) {
        if (
          preEmploymentAssessment.activeStepUrl?.includes('pre-employment') &&
          !preEmploymentAssessment.isSWOTGenerated
        ) {
          navigate(preEmploymentAssessment.activeStepUrl);
        } else if (!preEmploymentAssessment.didCompleteIntake) {
          navigate('/pre-employment/intake/step1');
        } else if (
          preEmploymentAssessment.didCompleteIntake &&
          !preEmploymentAssessment.didCompleteEmploymentReadiness &&
          preEmploymentAssessment.requiresEmploymentReadiness
        ) {
          navigate('/pre-employment/employment-readiness/step1');
        } else if (
          preEmploymentAssessment.didCompleteIntake &&
          !preEmploymentAssessment.didCompleteSkills
        ) {
          navigate('/pre-employment/skills/step1');
        } else if (
          preEmploymentAssessment.didCompleteEmploymentReadiness &&
          !preEmploymentAssessment.didCompleteCareerReadiness &&
          preEmploymentAssessment.requiresCareerReadinessAssessment
        ) {
          navigate('/pre-employment/career-readiness/step1');
        } else if (!preEmploymentAssessment.didCompleteExperience) {
          navigate('/pre-employment/experience/step1');
        } else if (!swot) {
          navigate('/swot');
        } else {
          navigate('/dashboard');
        }
      } else if (
        !preEmploymentAssessment?.isSWOTGenerated &&
        preEmploymentAssessment?.didCompleteSkills &&
        organization
      ) {
        navigate('/swot');
      }
    } else if (preEmploymentAssessment?.activeStepUrl?.includes('skills')) {
      preEmploymentAssessment?.activeStepUrl && navigate(preEmploymentAssessment?.activeStepUrl);
    } else if (
      !preEmploymentAssessment?.isSWOTGenerated &&
      preEmploymentAssessment?.didCompleteSkills
    ) {
      navigate('/swot');
    } else if (preEmploymentAssessment?.isSWOTGenerated) {
      navigate('/upgrade');
    }
  }, [navigate, preEmploymentAssessment, name, swot, userProfileContext]);
  return (
    <>
      <section className="start-page relative pt-20">
        <Container>
          <div className="flex items-center gap-20 py-8 md:py-0 flex-wrap md:flex-nowrap">
            <img className=" mx-auto" src={start1} alt="" />
            <div className="start-point">
              <h4>
                <span className="sp-heading block">
                  Hey there future change <span className="font-bold">makers and job seekers!</span>
                </span>
                Welcome to Vero Learning, a platform designed just for you to dive into the exciting
                world of self-discovery and career readiness. We've got your back as you gear up for
                the pre-employment screening – an awesome journey towards understanding your
                strengths, interests, and skills. This isn't just an assessment; it's a doorway to
                your better, brighter future.
              </h4>
            </div>
          </div>
          <div className="flex items-center gap-20 py-8 md:py-0 flex-wrap md:flex-nowrap">
            <div className="start-point order-2 md:order-1">
              <h4 className="">
                <span className="sp-heading">We understand </span>
                that you're at an exciting stage of your life, navigating the path between
                adolescence and young adulthood. As young adults, you're embarking on a journey to
                explore your natural talents, interests, and skill sets. Today, we're introducing a
                set of mini-assessments that will empower you to better understand your unique
                competencies, experience levels, and career aspirations.
              </h4>
            </div>
            <img className=" mx-auto order-1 md:order-2" src={start2} alt="" />
          </div>
          <div className="flex items-center gap-20 py-8 md:py-0 flex-wrap md:flex-nowrap">
            <img className=" mx-auto" src={start3} alt="" />
            <div className="start-point">
              <h4>
                <span className="sp-heading">First and foremost, </span>
                let's clear something up: taking these self-assessments will not negatively impact
                your chances of finding employment. In fact, it's quite the opposite! Vero LEARNING
                offers you multiple chances for self-improvement and growth. So we want to assure
                you that this assessment is all about empowerment, not judgment.Your truthful
                answers to each question will help us pinpoint areas where you can improve, and
                we'll provide you with the tools you need to succeed.
              </h4>
            </div>
          </div>
          <div className="flex items-center gap-20 py-8 md:py-0 flex-wrap md:flex-nowrap">
            <div className="start-point order-2 md:order-1">
              <h4>
                <span className="sp-heading">It's all about highlighting areas </span>
                where you shine and areas where you can grow. Think of it as a revolving door of
                opportunities for you to up your game and shine even brighter.
                <br />
                <br />
                So, grab a seat, get comfy, and let's dive into the good stuff!
                <br />
                <br />
                Confidentiality is our commitment. Your information is safe with us, in line with
                our strict policies. Let's uncover the potential in you and shape a future that's
                uniquely yours! 🚀
              </h4>
            </div>
            <img className=" mx-auto order-1 md:order-2" src={start4} alt="" />
          </div>
          <div className="text-center mb-16 mt-10">
            <button className="btn-primary" onClick={startIntake}>
              Start
            </button>
          </div>
        </Container>
      </section>
    </>
  );
};

export const StartPage: React.FC = () => {
  return <AuthenticatedPage render={() => <Start />} isStartPage={true} />;
};
