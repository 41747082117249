import { useEffect, useState } from 'react';

import comStyleImage from '../../../../assets/images/courses/communication-styles/chapter2/comStyle.jpeg';
import { course_questions as courseQuestions } from '../../../../courses/communication-styles/communication-styles.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CourseQuestionCard } from '../../CourseQuestionCard';
interface CommunicationStylesProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const CommunicationStylesChapter2: React.FC<CommunicationStylesProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };

  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      // setAnswers({
      //   [chapterQuestions[0].id]: {
      //     chapter_id: chapter.id,
      //     question_id: chapterQuestions[0].id,
      //     question: chapterQuestions[0].question,
      //     is_correct: userAnswerResponse[0].isCorrect || false,
      //     answer_options: userAnswerResponse[0].answers || [],
      //   },
      // });
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  const accordionData = [
    {
      id: 'panel1',
      title: 'Action-Based',
      content:
        'People with an action-based communication style center their communications on action steps and how to produce results. <br /><br />Action-based communicators are often directors, go-getters, and natural leaders. Their communication style is direct, task-oriented, and cuts to the chase. They don’t hesitate to assign roles or delegate tasks. They may appear assertive, decisive, and goal-oriented. Managers often employ an action-based communication style.<br /><br />Common phrases of this communication style include:<ul class="list-disc pl-5 mt-5"><li class="mb-4 font-bold">“I need you to ...” </li><li class="mb-4 font-bold">“Here’s our plan.”</li><li class="mb-4 font-bold">“The deadline is …”</li><li class="mb-4 font-bold">“What’s our strategy?”</li><li class="mb-4 font-bold">“Let’s talk about next steps.”</li></ul>',
    },
    {
      id: 'panel2',
      title: 'Fact-Based',
      content:
        'A fact-based communication style values details, facts, and logic. <br /><br />This communication style takes a pragmatic, systematic, or scientific approach to interpreting and sharing information. They often appear serious or cautious in their work, and they might ask a lot of questions or evaluate information critically in their quest for accuracy.<br /><br /> It’s not uncommon for engineers, data scientists, and researchers to exhibit this communication style. <br /><br />Common phrases of this communication style include:<ul class="list-disc pl-5 mt-5"><li class="mb-4 font-bold">“What does the data say?” </li><li class="mb-4 font-bold">“How did you reach that conclusion?”</li><li class="mb-4 font-bold">“We’ll conduct an A/B test to identify which works best.”</li><li class="mb-4 font-bold">“Let’s review the strengths and weaknesses of each.”</li></ul>',
    },
    {
      id: 'panel3',
      title: 'Idea-Based',
      content:
        'This communication style likes to focus on ideas, vision, and the “big picture.” They often prefer brainstorming, connecting, and developing ideas rather than talking about how to execute them. <br /><br />Idea-based communicators are often seen as innovators, entrepreneurs, or dreamers. <br /><br />Common phrases of this communication style include:<ul class="list-disc pl-5 mt-5"><li class="mb-4 font-bold">“I’ve developed an overview/outline of ...”</li><li class="mb-4 font-bold">“The big picture is ...”</li><li class="mb-4 font-bold">“Imagine …”</li></ul>',
    },
    {
      id: 'panel4',
      title: 'People-Based',
      content:
        'The last communication style is people-based or relational. This style focuses on relationships, supporting people, and understanding others. <br /><br />People-based communicators are social and personable. They take a collaborative approach to their work and like to discuss ideas or talk through issues together. <br /><br />People-based communicators seek harmony, connection, and strong team relationships. Community managers and customer specialists are careers that might require a people-based communication style. <br /><br />Common phrases of this communication style:<ul class="list-disc pl-5 mt-5"><li class="mb-4 font-bold">“How do you feel about …”</li><li class="mb-4 font-bold">“I really appreciate your ...”</li><li class="mb-4 font-bold">“I’d love to get your thoughts on ...”</li></ul>',
    },
  ];
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Understanding Different Styles</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">4 Styles of Communication</h3>
        <p className="mb-7">
          No communication style is inherently better or worse than another. They’re simply
          different ways of receiving and sending information. Your personality, background, skill
          set, and values shape your communication style.
          <br />
          <br />
          <br />
          Let’s start by examining the four communication styles in the video below.
        </p>
        <div className="video-wrap mt-8 mb-7">
          <video className="rounded-3xl" controls>
            <source
              src="https://articulateusercontent.com/review/uploads/cGInIJMRt1aQhJgN2GBY_pljuhmwy21Y/GCBh_duU/assets/rbc/r_CvPlTZIIMjxs4y_COMM-DS-02.mp4?v=1"
              type="video/mp4"
            />
          </video>
        </div>
        <p>
          In this video, you’ll learn how to adapt and work effectively with four different
          communication styles.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-3">Which Style Are You?</h3>
        <p>
          Expand the rows below to review the four styles of communication. As you review each
          style, identify which one is most dominant for you.
        </p>
        <br />
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-3">Check Your Knowledge</h3>
        <CourseQuestionCard
          submittedAnswer={userCourseQuestionResponseContext[
            chapterQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterQuestions[0]}
        />
        <div className="md:flex items-center mt-10">
          <img className="w-96 md:mr-9 mb-5 md:mb-0" src={comStyleImage} alt="" />
          <div>
            <p>
              <span className="font-bold">Not Everyone Has a Single Communication Style</span>
              <br />
              <br />
              It’s important to realize that most people don’t have a single communication style.
              Rather, many of us naturally lean on one to two dominant styles. We might combine or
              change styles in different situations. Communication styles are fluid, meaning you can
              adapt your communication based on your listener and situation.
            </p>
          </div>
        </div>
        <p className="my-14 text-2xl font-light text-center">
          Now that you’re familiar with the four communication styles, continue to the next lesson
          to review strategies for adapting to each style.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="w-full btn-primary"
        >
          How to Adapt to Different Styles
        </button>
      </div>
    </>
  );
};
