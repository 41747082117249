import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { ChapterLayout } from '../components/Course/ChapterLayout';
import { LeftPanel } from '../components/Course/LeftPanel';
import { courses } from '../courses/courses';
import { Course, CourseChapter } from '../courses/courses.types';
import {
  useUserCoursePrePostAssessmentQuestionsContext,
  useUserCourseQuestionResponseContext,
} from '../hooks/use-user-course-question-response.hooks';
import { useCourseProgressContext } from '../hooks/use-user-course.hooks';
import { dispatchGetUserCourseAssesmentQuestionResponse } from '../redux/slice/user-assesments/user-course-assesments.opertations';
import {
  dispatchGetChapterProgress,
  dispatchUpdateChapterProgress,
} from '../redux/slice/user-course';
import {
  dispatchGetUserCourseQuestionResponse,
  dispatchGetUserCourseSortingQuestionResponse,
  UserChapter,
} from '../redux/slice/user-course-question-response';
import { useAppDispatch } from '../redux/store';
const CourseStart: React.FC = () => {
  const courseProgressContext = useCourseProgressContext();
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const userCourseAssessmentQuestionResponseContext =
    useUserCoursePrePostAssessmentQuestionsContext();

  const dispatch = useAppDispatch();
  const { courseId } = useParams<{ courseId: string }>();
  const course: Course | undefined = courses.find((course) => course.id === courseId);
  const defaultChapter = course?.sections[0].chapters[0];
  const [selectedChapter, setSelectedChapter] = useState<CourseChapter | undefined>(
    defaultChapter || undefined,
  );
  const [selectChapterClicked, setSelectChapterClicked] = useState(false);
  const [quizPassed, setQuizPassed] = useState<boolean | undefined>(undefined);
  const [postAssementQuizPassed, setPostAssementQuizPassed] = useState<boolean | undefined>(
    undefined,
  );
  const [sidebarEnabled, setSidebarEnabled] = useState(false);
  const flatChapters = _.flatMap(course?.sections, 'chapters');
  const handleQuizResult = (isPassed: boolean | undefined) => {
    setQuizPassed(isPassed);
  };
  const handleSelectChapter = (chapter: CourseChapter) => {
    setSelectChapterClicked(true);
    setSidebarEnabled(false);
    if (courseId) {
      const courseProgress = courseProgressContext[courseId]?.progress;
      void dispatch(
        dispatchUpdateChapterProgress({
          [courseId]: {
            activeChapter: chapter.id,
            chapters: { [chapter.id]: courseProgressContext[courseId]?.chapters[chapter.id] || 0 },
            progress: _.clamp(courseProgress, 0, 100),
          },
        }),
      );
    }

    setSelectedChapter(chapter);
  };

  const handleNextChapter = async (nextIndex: number) => {
    setSelectChapterClicked(true);
    if (course) {
      const currentChapterIndexInFlatArray = _.findIndex(flatChapters, { index: nextIndex });

      if (currentChapterIndexInFlatArray !== -1 && courseId && selectedChapter) {
        const nextChapter: CourseChapter = flatChapters[currentChapterIndexInFlatArray];
        const courseProgress = courseProgressContext[courseId]?.progress;
        const currentChapterProgress =
          courseProgressContext[courseId]?.chapters[selectedChapter.id];

        if (!currentChapterProgress || currentChapterProgress < 100) {
          const totalProgress = (courseProgress || 0) + selectedChapter.completion_percentage;
          await dispatch(
            dispatchUpdateChapterProgress({
              [courseId]: {
                activeChapter: selectedChapter.id,
                chapters: { [selectedChapter.id]: 100 },
                progress: _.clamp(totalProgress, 0, 100),
              },
            }),
          );

          // await dispatch(
          //   dispatchUpdateUserCourseQuestionResponse({
          //     [courseId]: {
          //       chapters: {
          //         [selectedChapter.id]: {
          //           questions: chapterQuestionAnswers || [],
          //           quiz: quiz || null,
          //         },
          //       },
          //     },
          //   }),
          // );
        } else {
          // await dispatch(
          //   dispatchUpdateUserCourseQuestionResponse({
          //     [courseId]: {
          //       chapters: {
          //         [selectedChapter.id]: {
          //           questions: chapterQuestionAnswers || [],
          //           quiz: quiz || null,
          //         },
          //       },
          //     },
          //   }),
          // );
          await dispatch(
            dispatchUpdateChapterProgress({
              [courseId]: {
                activeChapter: nextChapter.id,
                chapters: {
                  [nextChapter.id]: courseProgressContext[courseId]?.chapters[nextChapter.id] || 0,
                },
                progress: _.clamp(courseProgress, 0, 100),
              },
            }),
          );
        }

        setSelectedChapter(nextChapter);
      }
    }
  };

  useEffect(() => {
    void dispatch(dispatchGetChapterProgress());
    void dispatch(dispatchGetUserCourseQuestionResponse());
    void dispatch(dispatchGetUserCourseSortingQuestionResponse());
    void dispatch(dispatchGetUserCourseAssesmentQuestionResponse());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Object.keys(courseProgressContext).length && course && courseId && !selectChapterClicked) {
      const courseChapter = course.sections
        .find((section) =>
          section.chapters.some(
            (chapter) => chapter.id === courseProgressContext[courseId]?.activeChapter,
          ),
        )
        ?.chapters.find((chapter) => chapter.id === courseProgressContext[courseId]?.activeChapter);
      if (courseChapter) setSelectedChapter(courseChapter);
    }
  }, [courseProgressContext]);

  useEffect(() => {
    if (
      courseId &&
      Object.keys(userCourseQuestionResponseContext).length &&
      userCourseQuestionResponseContext[courseId] &&
      Object.keys(userCourseQuestionResponseContext[courseId]).length
    ) {
      _.forEach(userCourseQuestionResponseContext[courseId].chapters, (chapter: UserChapter) => {
        // Check if the chapter has a quiz
        if (chapter?.quiz) {
          setQuizPassed(chapter?.quiz.score >= 80);
        }
      });
    }
  }, [userCourseQuestionResponseContext]);

  useEffect(() => {
    if (
      course?.module_id &&
      Object.keys(userCourseAssessmentQuestionResponseContext).length &&
      userCourseAssessmentQuestionResponseContext[course?.module_id] &&
      Object.keys(userCourseAssessmentQuestionResponseContext[course?.module_id]).length
    ) {
      // Check if the chapter has a quiz
      if (
        Object.keys(
          userCourseAssessmentQuestionResponseContext[course?.module_id]?.postAssessmentQuestions ||
            {},
        ).length
      ) {
        const score =
          userCourseAssessmentQuestionResponseContext[course?.module_id]?.postAssessmentQuestions
            ?.score || 0;
        setPostAssementQuizPassed(score >= 80);
      }
    }
  }, [userCourseAssessmentQuestionResponseContext]);

  const handleSidebarMenuClick = (enabled: boolean) => {
    setSidebarEnabled(enabled);
  };

  if (course) {
    return (
      <>
        <Container>
          <div className="lg:flex overflow-hidden">
            <LeftPanel
              postAssementQuizPassed={postAssementQuizPassed}
              quizPassed={quizPassed}
              sideBarEnabled={sidebarEnabled}
              onSelectChapter={handleSelectChapter}
              course={course}
              selected={selectedChapter}
            />

            <ChapterLayout
              onQuizResult={handleQuizResult}
              menuActive={sidebarEnabled}
              onSidebarMenu={handleSidebarMenuClick}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onNextChapter={handleNextChapter}
              chapter={selectedChapter}
              totalChapters={flatChapters.length}
              courseId={courseId || ''}
            />
          </div>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <h1>Invalid Course id</h1>
      </>
    );
  }
};

export const CoursesPage: React.FC = () => {
  return <AuthenticatedPage render={() => <CourseStart />} />;
};
