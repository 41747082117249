import {
  getUserDocuments,
  updateUserDocumentRecords,
} from '../../../services/database/user-documents';

import { UserDocumentState, UserUploadedDocuments } from './user-documents-type';

export const dispatchGetUserDocument = (): ((
  dispatch: unknown,
) => Promise<UserUploadedDocuments[]>) => {
  return async () => {
    const values: UserUploadedDocuments[] | [] = await getUserDocuments();
    return Object.values(values);
  };
};

export const dispatchUpsertUserDocument = (dto: UserDocumentState) => async () => {
  try {
    await updateUserDocumentRecords(dto);
  } catch (error) {
    console.log('error:asadas', error);
  }
};
