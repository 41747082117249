import introImg from '../../../../assets/images/courses/jumpstart-communicator/chapter7/intro.jpeg';
import { CourseChapter } from '../../../../courses/courses.types';
import { MediaBlock } from '../../MediaBlock';
interface JumpStartChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
export const JumpStartChapter7: React.FC<JumpStartChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-base">
        <h2 className="font-semibold text-3xl">How to Remove Five Common Communication Barriers</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock cssClass="mb-14" mediaImage={introImg}>
          <div className="flex-1">
            <p className="lg:w-3/4 text-2xl leading-10">Thank you for completing the module.</p>
          </div>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">Summary</h3>
        <p>
          Communication is the way we send and receive information—or messages—to one another. In
          most cases, the process goes smoothly. Occasionally, communication breaks down. Knowing
          the ways messages get muddy can help you avoid common misunderstandings and keep
          communication flowing.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">Key Takeaways</h3>
        <p>Check the box to say “Got it!”</p>
        <ul className="mt-7">
          <li className="pb-5">
            <label className="custom-checkbox">
              Great communicators use several tools: straightforward verbal messages, good listening
              skills, and confident, clear writing. They reinforce their messages with nonverbal
              cues.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="pb-5">
            <label className="custom-checkbox">
              Good communicators build positive team dynamics, increase trust in relationships, and
              manage themselves and others effectively. These skills are essential to advance your
              career.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="pb-5">
            <label className="custom-checkbox">
              Communication can fail when we use unclear language, choose an ineffective medium, or
              when the person listening is distracted.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="pb-5">
            <label className="custom-checkbox">
              Choosing the right medium and timing for messages makes all the difference between
              impact and misunderstanding. Think through whether in-person, video, phone, or written
              delivery works best for your message and the person receiving it.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
          <li className="pb-5">
            <label className="custom-checkbox">
              Keep an open mind as you share information. Even if you have different perspectives,
              approaches, and experiences, an open mind and good listening will help you reach
              consensus.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </li>
        </ul>
        <h3 className="text-2xl text-purple-600 font-bold mb-3 mt-7">References</h3>
        <p>
          Chapter 2. (n.d.). The Meaning and Process of Communication. Retrieved September 10, 2020
          from shodhganga.inflibnet.ac.in
          <br />
          <br />
          Communication Process. (n.d.). Retrieved September 09, 2020 from cls.utk.edu
          <br />
          <br />
          Hansen, Brianna. (2022). 4 Reasons Why Communication Fails (and What to Do About It).
          Wrike Blog post: June 16, 2022. https://www.wrike.com/blog/4-reasons-communication-fails/
          <br />
          <br />
          Lunenburg, F.C. (2010). Communication: The Process, Barriers, and Improving Effectiveness.
          Schooling, 1(1), 1-11. Retrieved September 09, 2020 from nationalforum.com
          <br />
          <br />
          Indeed Team. (2023). Communication Mediums: 5 Types (Plus Choosing the Right One) Indeed
          Article - Updated September 29, 2023.
          https://www.indeed.com/career-advice/career-development/what-is-medium-in-communication.
          <br />
          <br />
          Inthera Consulting (2022). 7 Reasons Why Communication Fails. Blog post January 28, 2022.
          https://www.inthera.ca/blog/7-reasons-why-communication-fails/
          <br />
          <br />
          Nordquist, R. (2019). Definition and Examples of Senders in Communication. Retrieved
          September 10, 2020 from thoughtco.com
          <br />
          <br />
          Rigby, Amy. (2022). What Causes Poor Communication In The Workplace—and How to Fix It.
          Krisp Blog post: Updated on Oct 02, 2022.
          https://krisp.ai/blog/poor-communication-in-workplace/
          <br />
          <br />
          Seek. (2023). 12 examples of communication barriers and how to overcome them. SEEK Article
          - updated on 12 October, 2023. https://krisp.ai/blog/poor-communication-in-workplace/
        </p>
        <p className="mt-11 text-2xl leading-10 text-center font-light mb-10">
          Click below to check your knowledge. Once you have completed the quiz you can close the
          browser window to exit module.
        </p>
        <button onClick={handleNextChapterClick} className="btn-primary w-full">
          QUIZ
        </button>
      </div>
    </>
  );
};
