import { setDoc, doc } from 'firebase/firestore';

import { ContactUseInfo } from '../../redux/slice/form-contact-us/form-contact-us.types';
import { hash } from '../../utils/crypto';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';

const database: DatabaseTable = DatabaseTable.CONTACT_US_RESPONSES;

/**
 * upsert a record into the contact us responses
 */
async function updateRecord<T extends Record<string, unknown>>(
  dto: T,
  recordId: string,
): Promise<void> {
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

export async function insertContactUs(dto: ContactUseInfo): Promise<void> {
  logger.debug('Upserting Contact us responses', dto);
  try {
    const { email } = dto;
    const trimmedEmail: string = email.trim();
    const hashedId: string = await hash(trimmedEmail);
    await updateRecord(dto, hashedId);
  } catch (e) {
    console.log(e);

    logger.debug('Failed to upser contact us responses.', e);
    throw e;
  }
}
