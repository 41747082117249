import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import { getSkillsValues, updateSkills } from '../../../services/database/form-skills';

import { SkillsState, updateSkillsAction } from './form-skills.slice';
import { UpdateSkillsDTO } from './form-skills.types';

/**
 * retrieve previously saved values from the skills assessment
 */
export const dispatchGetSkillsValues =
  () =>
  async (dispatch: Dispatch<PayloadAction<SkillsState>>): Promise<void> => {
    const values: SkillsState = await getSkillsValues();
    dispatch(updateSkillsAction(values));
  };

/**
 * update progress in the skills assessment
 */
export const dispatchUpdateSkills =
  (dto: UpdateSkillsDTO) =>
  async (dispatch: Dispatch<PayloadAction<UpdateSkillsDTO>>): Promise<void> => {
    await updateSkills(dto);
    dispatch(updateSkillsAction(dto));
  };
