import { EnvironmentVariable } from '../../../utils/constants';

type PaymentStatus = {
  isAsaUser: boolean;
  to: string;
  paymentStatus: boolean;
  amount?: number;
  userName?: string;
};
const productPrice =
  Number(process.env[EnvironmentVariable.REACT_APP_SUBSCRIPTION_PRICE_CENTS]) / 100;
const asaUserDiscount = Number(process.env[EnvironmentVariable.REACT_APP_ASA_DISCOUNT_PERCENT]);

export const calculateFinalPrice = (price: number, discount: number) => {
  return price - (price * discount) / 100;
};

export const dispatchPaymentStatusEmail = async (dto: PaymentStatus) => {
  const sendEmailUrl = process.env[EnvironmentVariable.REACT_APP_PAYMENT_STATUS_EMAIL_URL];
  if (!dto?.userName) {
    dto.userName = dto?.to;
  }
  if (dto) {
    if (dto?.isAsaUser) {
      dto.amount = calculateFinalPrice(productPrice, asaUserDiscount);
    } else {
      dto.amount = calculateFinalPrice(productPrice, 0);
    }

    await fetch(`${sendEmailUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dto),
    });
  }
};
