import {
  CourseQuestion,
  QuestionType,
} from '../jumpstart-communicator/jumpstart-communicator.types';

export const course_questions: CourseQuestion[] = [
  {
    id: 'd89869f3-6b39-4ed4-9320-8e74816b0e91',
    question:
      'What is the best way to explain a complicated task to someone who’s just started their job?',
    type: QuestionType.RADIO,
    options: [
      {
        id: '88d7bcb1-6d54-4467-8b65-0e4bb0cc1af8',
        option: 'Use specialized words to sound professional and explain the whole task.',
      },
      {
        id: '75bee621-c033-4141-adfa-af50d58ef148',
        option: 'Split the task into easy steps and avoid using complicated words.',
      },
      {
        id: 'f24afe5a-6d0b-4cee-b089-391357327c58',
        option:
          'Give a quick summary of the task, leaving out the parts that aren’t important to keep it short.',
      },
      {
        id: '6c8a00d3-d1b4-4589-9e42-be66c054d39c',
        option: 'Give the new person some reading material to learn on their own.',
      },
    ],
    answers: ['75bee621-c033-4141-adfa-af50d58ef148'], // changed
    wrong_answer_description:
      'It is crucial to communicate clearly and comprehensibly for a new co-worker to grasp a complex process effectively. Breaking down the process into smaller steps and using everyday language will facilitate understanding and retention.',

    answer_description:
      'The most effective way to ensure understanding, especially for someone new, is to break down complex information into clear, step-bystep instructions using simple language (C).',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: 'f6c82a74-41a4-497a-84fc-df57c887db4d',
    question:
      'Picture being in a team meeting where you’re talking about tools that help get more work done. You want to suggest a new app for taking notes that might make everyone more efficient. What’s the best way to persuade your team to try it out?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'da10d0a1-fbd9-403b-9a6f-f0668deec458',
        option:
          '"Lots of businesses are picking up this app because it has cool new features, and more people are starting to use it."',
      },
      {
        id: 'd240fb06-9646-457d-acc5-5f7356cb3f12',
        option: '"This app is easy to use, and many professionals think it’s great."',
      },
      {
        id: '7af73015-1338-4908-8dca-d146bd20b11f',
        option: '"I think this app could really change how we take notes for the better."',
      },
      {
        id: '95e6625f-39a7-4ffc-a166-8377502688f8',
        option:
          '"If we start using this app, we might get up to 20% more done by making our note-taking and organizing way better."',
      },
    ],
    answers: ['95e6625f-39a7-4ffc-a166-8377502688f8'],
    answer_description:
      'To persuade the team effectively, it is essential to present concrete benefits and measurable improvements. This will demonstrate the app’s value and its potential to enhance their productivity.',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '8d187cce-2e96-4784-8bf6-3277b72e24b4',
    question:
      'You get an email with helpful suggestions on how to improve your last project. Thinking about why they chose to send this feedback in an email, what does that tell you about their preferred way of communicating?',
    type: QuestionType.RADIO,
    options: [
      {
        id: '05ea63a2-9d24-427e-9d92-ea844031db4f',
        option: 'Sending an email suggests that the matter is urgent and requires quick attention.',
      },
      {
        id: 'b37d2b63-6ec5-4656-80c8-703a8773ca10',
        option:
          'The email is sent privately to allow you time to think deeply about the suggestions.',
      },
      {
        id: 'cd0fa457-9535-4d09-931d-62f1c8938eef',
        option: 'The feedback is casual, so you don’t need to worry too much about it..',
      },
      {
        id: '5dc5635d-ebe8-4260-84a5-aabddf7410cf',
        option:
          'The email serves as an official record of the feedback, which could come up during evaluation of your work.',
      },
    ],
    answers: ['b37d2b63-6ec5-4656-80c8-703a8773ca10'], // changed
    wrong_answer_description:
      'Email facilitates private communication and enables the recipient to process the feedback at their own convenience. This makes it a suitable medium for constructive feedback, as it provides the recipient with the opportunity to reflect on the feedback and formulate an appropriate response.',
    answer_description:
      'Email allows for private communication and allows the recipient to process the feedback at their own pace, making option A the correct interpretation.',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '4fd381ed-cd04-4145-97bc-af86861e3f88',
    question:
      'You’re working on a project with Jordan, who often starts discussions with things like "Let’s figure out the steps" and "What deadlines are we targeting?". Jordan is probably showing what kind of communication style?',
    type: QuestionType.RADIO,
    options: [
      {
        id: '4aa84470-a867-4cdb-9116-59437a056c3b',
        option: 'Idea-Based',
      },
      {
        id: 'b6e778b3-d4f4-42b9-8560-f0c066d8a3bf',
        option: 'Action-Based',
      },
      {
        id: '0a595255-cafd-44eb-a5d8-253bfaf1f4f6',
        option: 'People-Based',
      },
      {
        id: 'b21b0a5c-5796-451f-9bfb-6c5cdc7b3111',
        option: 'Fact-Based',
      },
    ],
    answers: ['b6e778b3-d4f4-42b9-8560-f0c066d8a3bf'],
    answer_description:
      'Action-based communicators prioritize tasks, outcomes, and actions. They tend to use straightforward language when discussing plans, steps, and deadlines. Jordan’s use of phrases that emphasize planning and progress aligns with the characteristics of an action-based communication style.',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '19decffa-7955-4dac-a943-cb08919f857c',
    question:
      'When you’re showing a new project plan to a coworker known for their Fact-Based communication style, what’s the best approach to take?',
    type: QuestionType.RADIO,
    options: [
      {
        id: '24c97874-44f5-4ae8-bde7-05f9fdc7c11f',
        option:
          'Highlight how the project could positively affect the team’s feelings and relationships.',
      },
      {
        id: '96a56379-1eb7-460a-bcbe-4898df6af446',
        option:
          'Start with a quick summary of what the project aims to achieve, then go into the finer points.',
      },
      {
        id: '15405161-ca18-4b05-8679-98e1fbd7e3c5',
        option: 'Lay out the project’s plan in detail, explaining why each step makes sense.',
      },
      {
        id: '55df0570-4c80-47db-9758-bcaa4d71307f',
        option: 'Focus on what needs to be done right away to get the project moving.',
      },
    ],
    answers: ['15405161-ca18-4b05-8679-98e1fbd7e3c5'],
    answer_description:
      'Fact-based communicators are drawn to structured, logical presentations. To resonate with their communication style, it is essential to present a well-organized plan that clearly outlines each step and provides a sound rationale for each decision.',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '4945aaee-e531-4c35-bcbf-d826790cec0c',
    question:
      'You need to give some tasks to Sophia, a teammate who prefers action-based communication style. Keeping her communication style in mind, how should you go about giving her this task to make sure everything’s clear?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'c89caf24-25d8-4459-b7df-32b722eb2a65',
        option: '"Sophia, I want you to be in charge of this task. I know you’ll do a great job."',
      },
      {
        id: '49d1f8aa-ba0f-4d51-aaed-917fe794cc16',
        option:
          '"Sophia, this task is really important and needs a lot of focus. Let’s make sure we understand all the details."',
      },
      {
        id: '50cec69c-cbf5-4820-b9e5-0cdd6c0bdb54',
        option:
          '"Sophia, you’re taking on this important task. It’s due next Tuesday. Please keep me updated on how it’s going, and let’s aim to hit all our goals."',
      },
      {
        id: '80e7cd01-1894-44db-9399-63e832a6adf0',
        option:
          '"Sophia, you’re always ready to jump in, and this task is perfect for you. It’s about solving problems and coming up with new ideas."',
      },
    ],
    answers: ['50cec69c-cbf5-4820-b9e5-0cdd6c0bdb54'],
    answer_description:
      'For action-oriented communicators like Sophia, clear instructions, deadlines, and progress updates are crucial. Option C aligns with this communication style by providing a direct assignment, setting a deadline, requesting regular updates, and emphasizing the desired outcomes.',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '0abf4be3-8f8c-4c6a-bf1b-68c7a3209072',
    question:
      'When you have to tell your team about a project update that involves complicated data analysis, what’s the smartest approach?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'c725f1f7-15fb-4450-bd9d-478a8941144c',
        option: 'A meeting in person so you can talk about it right then and there.',
      },
      {
        id: '8d1841d3-c8ff-4beb-8fac-6ffa5266fd37',
        option: 'A quick phone call to go over the main points.',
      },
      {
        id: '224e1367-f126-41a1-982b-d88a9a2db465',
        option: 'A group text to send out the info and handle quick questions.',
      },

      {
        id: '6542e62d-2bca-4e21-b0eb-7b9916f4cc5c',
        option: 'A detailed email that goes through the analysis step by step.',
      },
    ],
    answers: ['6542e62d-2bca-4e21-b0eb-7b9916f4cc5c'], // changed
    answer_description:
      'Complex data analysis necessitates a structured and thorough presentation to ensure clarity and comprehension. An email allows you to present the data in a well-organized manner, incorporating visual aids if needed. It also provides a permanent record for future reference and enables team members to review the information at their own pace.',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '309fd0a9-60fc-43ea-b335-8e36045fc3cf',
    question: 'In what situation is making a phone call the best way to communicate at work? ',
    type: QuestionType.RADIO,
    options: [
      {
        id: '90090519-e2f9-45ad-9b95-585ab478c853', // correct
        option: 'When you need quick responses on something urgent that can’t wait.',
      },
      {
        id: '9096b520-7b5b-4d23-9d1f-bda1314c9752',
        option: 'Sharing a detailed report on a project that needs careful review.',
      },
      {
        id: '15cf6d20-3cbc-4dac-8652-3cf8faf69f3d',
        option: 'Giving a detailed update that needs to be officially documented.',
      },
      {
        id: 'e271d0cc-c8f1-4145-b69c-8732dcd516a7',
        option: 'Having a private talk about personal matters with several people.',
      },
    ],
    answers: ['90090519-e2f9-45ad-9b95-585ab478c853'], // changed
    answer_description:
      'Phone calls are most appropriate when immediate feedback is required on a time-sensitive issue. They facilitate direct conversations and enable quick responses, making them the preferred choice for urgent matters that demand immediate attention.',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '539e4ded-14f3-40ed-8a7f-8017d1ca23f4',
    question:
      'During a group meeting, you see a teammate looking upset but they haven’t said anything about it. How can you help them feel supported and listened to without making them uncomfortable?',
    type: QuestionType.RADIO,
    options: [
      {
        id: 'a10362f1-e1ed-454e-9e3c-1c5cbb5e5540',
        option: 'Ask open-ended questions and let them explain in their own way.',
      },
      {
        id: 'cd36b5e2-d429-4516-8c70-0ab8fa96fb82',
        option: 'Ignore their body language to avoid any arguments.',
      },
      {
        id: '30200a2e-c31c-4ee0-affb-637ebde2cc28',
        option: 'Guess what’s bothering them and talk for them.',
      },
      {
        id: 'f2c8094b-32a1-4de2-8bfd-1c8ca4e02650',
        option: 'Advise them to get better at managing their feelings at work.',
      },
    ],
    answers: ['a10362f1-e1ed-454e-9e3c-1c5cbb5e5540'],
    answer_description:
      'In such situations, it is crucial to acknowledge nonverbal cues and encourage the colleague to share their thoughts. Asking open-ended questions allows them to express themselves freely without assumptions or judgments. This approach fosters open and empathetic communication, creating a safe space for them to share their concerns.',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
  {
    id: '01f62360-1fb7-444f-ab86-bc5c2cf02ca6',
    question:
      'Imagine your project group is about to present a project that is totally different from what you usually do. After practicing in front of some friends, you realize that some parts of your presentation might be hard for the audience to understand. You need to share this feedback with your group so you can work together to make your presentation clearer. How would you talk to your group about these suggestions?',
    type: QuestionType.RADIO,
    options: [
      {
        id: '323845f4-5c88-48b7-9fe2-88b3b3aba1e1',
        option:
          'Share the exact feedback from your friends on the group’s chat and ask for quick ideas and comments.',
      },
      {
        id: 'f2503ded-73ad-4bb4-b4eb-5a350ea37f83',
        option:
          'Compose an email to your team detailing your friend’s feedback on the presentation, highlighting areas needing revision, and schedule a meeting to discuss enhancements.',
      },
      {
        id: '68c7912a-faaa-44ef-a72e-e00f0ad8b5c0',
        option:
          'Quickly get everyone together for a meeting to talk about the feedback, using your own understanding and notes to lead the conversation towards improving your presentation.',
      },
      {
        id: 'ec784ec9-3752-4ced-acca-51fa1abb0935',
        option:
          'Send a short note to your group saying that your presentation needs some changes, but not giving details right away, planning to talk more about it at your next group meeting.',
      },
    ],
    answers: ['f2503ded-73ad-4bb4-b4eb-5a350ea37f83'],
    answer_description:
      'The email provides a clear, organized summary of feedback and sets the stage for a focused discussion on revisions, aligning with effective written communication practices.',
    chapter_id: '4b015d7c-2368-47ba-ac15-3dc35fc981d5',
    course_id: 'eb7b1338-7fa1-4673-9b2f-4fe30c2a9069',
  },
];
