import { EnvironmentVariable } from '../../../utils/constants';

type OTPMailObject = {
  To: string;
  otp: number;
  validUntil: number;
};

export const dispatchOtpEmail = async (dto: OTPMailObject) => {
  const otpEmailUrl = process.env[EnvironmentVariable.REACT_APP_SEND_OTP_EMAIL_URL];
  if (dto) {
    await fetch(`${otpEmailUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dto),
    });
  }
};
