import { useEffect, useState } from 'react';

import { course_questions as courseQuestions } from '../../../../courses/communication-styles/communication-styles.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CarouselComponent } from '../../CarouselComponent';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { InfoBlock } from '../../InfoBlock';

interface CommunicationStylesProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const CommunicationStylesChapter3: React.FC<CommunicationStylesProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const slidesData = [
    {
      heading: 'Do’s and Don’ts of Adapting to Different Communication Styles',
      paragraph:
        'Advance through the slides to learn practical tips for adapting to each communication style.',
      list: [
        '<span class="font-bold">Do:</span> Communicate action items and how they will produce results. Speak with confidence.',
        '<span class="font-bold">Don’t:</span> Take blunt feedback personally or waffle on decisions. ',
      ],
    },
    {
      heading: 'Action-Based',
      paragraph:
        'If you’re communicating with someone who prefers an action-based communication style, then make your communications succinct, task-based, and action-oriented. Skip unnecessary details and get to the bottom line. Approach communications from the perspective of the practical steps of who, what, when, where, and how to achieve set objectives. ',
      list: [
        '<span class="font-bold">Do:</span> Communicate action items and how they will produce results. Speak with confidence.',
        '<span class="font-bold">Don’t:</span> Take blunt feedback personally or waffle on decisions. ',
      ],
    },
    {
      heading: 'Fact-Based',
      paragraph:
        'For this communication style, shift your information to focus on the facts. Present information in an orderly, logical way that clearly outlines supporting research or data. Analyze ideas critically and from all angles. You might need to build in extra time for people with this communication style to reflect, gather their thoughts, or develop a plan. They may prefer to work on a problem independently. ',
      list: [
        '<span class="font-bold">Do:</span> Provide concrete examples, evidence, and explanations. Prepare for questions. Use charts, figures, and graphs. Give clear expectations. Be realistic, rational, and precise.',
        '<span class="font-bold">Don’t:</span> Be offended if this person tries to find holes in your logic. Don’t ask for an opinion or recommendation without giving them time to research. Don’t be idealistic or emotional.',
      ],
    },
    {
      heading: 'Idea-Based',
      paragraph:
        'Focus your communications on the vision, mission, overall picture, or big ideas. Communicate information through summaries, outlines, and high-level overviews, and share how tasks fit into the greater objectives. Focus on the big idea and let the idea-based communicator run with it from there. ',
      list: [
        '<span class="font-bold">Do:</span> Spend more time discussing what and why. Involve this person in brainstorming and developing ideas. Be creative.',
        '<span class="font-bold">Don’t:</span> Weigh down communications with the small details; a simple outline or summary is ideal. Don’t hesitate to dream big and think outside-of-the-box.',
      ],
    },
    {
      heading: 'People-Based',
      paragraph:
        'If you’re communicating with someone who prefers a people-based communication style, then mirror their warm, friendly, and personal approach to working with people. Plan plenty of meetings to collaborate and discuss ideas, feelings, and opinions with relevant members of the team. Frame projects by their impact on people.  ',
      list: [
        '<span class="font-bold">Do:</span> Set up meetings to share progress, work together, or discuss ideas. Check in often to see how this person is feeling and learn how to better support one another. Be casual and emotionally honest in conversations.',
        '<span class="font-bold">Don’t:</span> Be too blunt, curt, or aggressive. Don’t confine their ability to express themselves. Don’t be too formal or underestimate the power of team-building, relationships, and personal connection.',
      ],
    },
    {
      heading: 'Summary',
      paragraph:
        'Adapting your communication style doesn’t mean changing the content of your message. Instead, use these tips to deliver your message in a way that others will hear and process it.',
      list: [],
    },
  ];
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">How to Adapt to Different Styles?</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          What Does It Mean to Adapt Your Communication Style?
        </h3>
        <p className="mb-7">
          Once you understand the communication style of your listener, you can then adapt your
          communication style to suit. Adapting to other people’s communication styles doesn’t mean
          that you’re changing the content of your message. Rather, it involves delivering the
          content in a way that gives others the best chance to understand it.
        </p>
        <div className="relative bg-blue-50 rounded-3xl">
          <CarouselComponent data={slidesData} />
        </div>

        <div className="mt-7">
          <InfoBlock
            title="Ask what they prefer. "
            text="If you’re unsure what communication style someone prefers, ask them, “What’s your preferred communication style?” or, “Is there anything I could change or do differently to communicate more effectively?” They’ll appreciate the effort, and you won’t have to guess what works best for them. "
          />
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">Check Your Knowledge</h3>
        <CourseQuestionCard
          submittedAnswer={userCourseQuestionResponseContext[
            chapterQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterQuestions[0]}
        />
        <p className="text-2xl text-center mt-5 mb-7 font-light">
          Continue to review what you’ve learned.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full uppercase"
        >
          Summary
        </button>
      </div>
    </>
  );
};
