import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import './App.css';
import AppRouter from './components/AppRoute/AppRouter';
import ActiveTimeTracker from './components/Timer/ActivityTimeTracker';
import store from './redux/store';
import { initializeFirebase } from './services/database';

function App() {
  initializeFirebase();

  return (
    <Provider store={store}>
      <RouterProvider router={AppRouter} />
      <ActiveTimeTracker />
    </Provider>
  );
}

export default App;
