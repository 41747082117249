import { useDrop } from 'react-dnd';

import { MatchingQuestionBase } from '../../../courses/jumpstart-communicator/jumpstart-communicator.types';

interface QuestionCardProps {
  item: MatchingQuestionBase;
  onDrop: (item: MatchingQuestionBase) => void;
}
export const MatchingQuestionCard: React.FC<QuestionCardProps> = ({ item, onDrop }) => {
  const [, drop] = useDrop({
    accept: 'MATCHINGQUESTION',
    drop: () => {
      onDrop(item);
    },
  });
  return (
    <div ref={drop} onDragOver={(e) => e.preventDefault()} className="right-shape">
      <div className="right-content flex items-center">
        <p>{item.question.question}</p>
      </div>
    </div>
  );
};
