import React from 'react';

import { OrganizationProps } from '../../redux/slice/organization-dashboard/organization.types';

import { OrganizationStats } from './OrganizationStats';
import { PlatformUsageStats } from './PlatformUsageStats';
import { PreEmploymentStats } from './PreEmploymentStats';
import { UserCoursesStats } from './UserCoursesStats';

export const OrganizationUserOverview: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  return (
    <div className=" w-full p-4">
      <div className="mt-6 w-full overflow-hidden bg-gray-50 rounded-lg shadow-md p-4">
        <OrganizationStats orgnizationId={orgnizationId} />
      </div>

      <div className="mt-6 w-full overflow-hidden bg-gray-50 rounded-lg shadow-md p-4">
        <PreEmploymentStats orgnizationId={orgnizationId} />
      </div>
      <div className="mt-6 w-full overflow-hidden bg-gray-50 rounded-lg shadow-md p-4">
        <PlatformUsageStats orgnizationId={orgnizationId} />
      </div>
      <div className="mt-6 w-full overflow-hidden bg-gray-50 rounded-lg shadow-md p-4">
        <UserCoursesStats orgnizationId={orgnizationId} />
      </div>
    </div>
  );
};
