import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { CustomUser } from '../../redux/slice/auth/CustomUser';
import {
  PreEmploymentAssessment,
  PreEmploymentAssessmentStatus,
} from '../../redux/slice/pre-employment-assessment';
import logger from '../logger';

import { getCurrentUser } from './auth';
import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';

const database: DatabaseTable = DatabaseTable.PRE_EMPLOYMENT_ASSESSMENTS;

/**
 * returns the id used for indexing a pre-employment assessment
 * currently only supports one assessment per user because it's indexed by user id
 */
async function getIdForPreEmploymentAssessmentRecord(): Promise<string> {
  const currentUser: CustomUser | null = await getCurrentUser();
  if (!currentUser) {
    // TODO: use custom errors
    throw new Error('User not authenticated!');
  }

  return currentUser.user.uid;
}

/**
 * attempts to find an existing pre-employment assessment
 */
async function findPreEmploymentAssessment(
  recordId: string,
): Promise<PreEmploymentAssessment | undefined> {
  try {
    const result: DocumentSnapshot<Partial<PreEmploymentAssessment>> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return (result.data() as PreEmploymentAssessment) ?? undefined;
  } catch (e) {
    logger.error('Failed to retrieve pre-employment assessment values.', e);
    throw e;
  }
}

/**
 * upsert a record into the pre-employment assessment collection
 */
async function updatePreEmploymentAssessment(dto: PreEmploymentAssessment): Promise<void> {
  const recordId: string = await getIdForPreEmploymentAssessmentRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

/**
 * creates or updates a pre-employment assessment
 */
export async function upsertPreEmploymentAssessment(
  dto?: Partial<PreEmploymentAssessment>,
): Promise<PreEmploymentAssessment> {
  logger.debug('Upserting pre-employment assessment.');
  try {
    let assessment: PreEmploymentAssessment | undefined;
    const recordId: string = await getIdForPreEmploymentAssessmentRecord();
    assessment = await findPreEmploymentAssessment(recordId);
    if (dto && Object.keys(dto).length > 0) {
      await updatePreEmploymentAssessment({
        userId: dto.userId ?? recordId,
        status: dto.status ?? assessment?.status ?? PreEmploymentAssessmentStatus.NOT_STARTED,
        disablePersonalInfo: dto.disablePersonalInfo ?? assessment?.disablePersonalInfo ?? false,
        requiresEmploymentReadiness:
          dto.requiresEmploymentReadiness ?? assessment?.requiresEmploymentReadiness ?? true,
        requiresCareerReadinessAssessment:
          dto.requiresCareerReadinessAssessment ??
          assessment?.requiresCareerReadinessAssessment ??
          true,
        requiresExperienceAssessment:
          dto.requiresExperienceAssessment ?? assessment?.requiresExperienceAssessment ?? true,
        isSWOTGenerated: dto.isSWOTGenerated ?? assessment?.isSWOTGenerated ?? false,

        didCompleteIntake: dto.didCompleteIntake ?? assessment?.didCompleteIntake ?? false,
        didCompleteEmploymentReadiness:
          dto.didCompleteEmploymentReadiness ?? assessment?.didCompleteEmploymentReadiness ?? false,
        didCompleteSkills: dto.didCompleteSkills ?? assessment?.didCompleteSkills ?? false,
        didCompleteCareerReadiness:
          dto.didCompleteCareerReadiness ?? assessment?.didCompleteCareerReadiness ?? false,
        didCompleteExperience:
          dto.didCompleteExperience ?? assessment?.didCompleteExperience ?? false,
        activeStepUrl: dto.activeStepUrl ?? assessment?.activeStepUrl ?? '',
        progress:
          (dto?.progress && (dto?.progress > 100 ? 100 : dto?.progress)) ||
          assessment?.progress ||
          0,
        defaultStepProgress: assessment?.defaultStepProgress || 3.57,
        syncedToAirtable: assessment?.syncedToAirtable || false,
        preEmployementSubmitDate:
          dto?.preEmployementSubmitDate || assessment?.preEmployementSubmitDate || '',
        didAsaUserViewedSwot: dto.didAsaUserViewedSwot ?? assessment?.didAsaUserViewedSwot ?? false,
      });

      assessment = await findPreEmploymentAssessment(recordId);
    }
    if (!assessment) {
      await updatePreEmploymentAssessment({
        userId: recordId,
        status: PreEmploymentAssessmentStatus.NOT_STARTED,
        requiresEmploymentReadiness: true,
        requiresCareerReadinessAssessment: true,
        requiresExperienceAssessment: true,
        isSWOTGenerated: false,
        didCompleteIntake: false,
        didCompleteEmploymentReadiness: false,
        didCompleteSkills: false,
        didCompleteCareerReadiness: false,
        didCompleteExperience: false,
        progress: 0,
        defaultStepProgress: 3.57,
        syncedToAirtable: false,
        preEmployementSubmitDate: '',
        didAsaUserViewedSwot: false,
      });
      assessment = await findPreEmploymentAssessment(recordId);
    }

    if (!assessment) {
      throw new Error('Pre-employment assessment not found.');
    }

    return assessment;
  } catch (e) {
    logger.error('Failed to upsert pre-employment assessment.', e);
    throw e;
  }
}
