import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
import {
  AsaTaskName,
  completeASATask,
  dispatchGetAsaTaskHistoryValues,
  dispatchUpdateAsaTaskHistoryInfo,
  UserAsaTaskHistory,
} from '../redux/slice/asa-task-history';
import { setOTPAuthAction } from '../redux/slice/auth';
import {
  clearUserTempData,
  dispatchLogin,
  dispatchLogout,
  dispatchSignup,
  dispatchVerifyLogin,
} from '../redux/slice/auth/auth.operations';
import { SignupDTO, UserRole } from '../redux/slice/auth/auth.types';
import { CustomUser } from '../redux/slice/auth/CustomUser';
import { dispatchUpdateIntakeFormUserOrganization } from '../redux/slice/form-intake/form-intake.operations';
import { dispatchOtpEmail } from '../redux/slice/form-otp-validation/form-otp-validation.operations';
import { dispatchUpdateUserProfileInfo } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';
import { AddUserClaims, DeleteUser } from '../services/database';
import { updateUserEmail } from '../services/database/form-intake';
import { getIsOtpVerified, isOtpVerified, sendOtpToEmail } from '../services/database/mfa-auth';
import { upsertOrganization } from '../services/database/organizations';
import { getUserProfileValues } from '../services/database/user-profile';
import logger from '../services/logger';
import { EnvironmentVariable } from '../utils/constants';
import { hash } from '../utils/crypto';
import { buildASANextTaskUrl, isASATaskRequest } from '../utils/helper';

import { CircularLoader } from './loader/CircularLoader';
import OtpPopup from './Otp/OtpPopup';

const validatePassword = (password: unknown) => {
  if (typeof password !== 'string') {
    return false;
  }

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*_`'^.",<>:;)(|=+,~}{?&])?[A-Za-z\d@$!%*_`'^.",<>:;)(|=+,~}{?&]{8,}$/;
  return passwordRegex.test(password);
};
const SignupSchema = yup
  .object({
    email: yup
      .string()
      .max(50, 'Email must not exceed 50 characters')
      .email('Invalid email address')
      .required(),
    password: yup
      .string()
      .max(100, 'Password must not exceed 100 characters')
      .test(
        'password-policy',
        'Password does not meet the policy, must be minimum 8 character long and should be alphanumeric',
        validatePassword,
      )
      .required(),
  })
  .required();
export const Signup: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryParams: URLSearchParams = new URLSearchParams(location.search);
  const organization: string | undefined =
    queryParams.get('organization') ?? Cookies.get('organization');
  const disablePersonalInfo: string | undefined =
    queryParams.get('disablePersonalInfo') ?? Cookies.get('disablePersonalInfo');
  const isASARequest = isASATaskRequest(queryParams);
  if (isASARequest) {
    const user_id: string | null = queryParams.get('user_id');
    const unique_task_key: string | null = queryParams.get('unique_task_key');
    const step_number: string | null = queryParams.get('step_number');
    sessionStorage.setItem(
      'asa_params',
      JSON.stringify({ user_id, unique_task_key, step_number, task_url: location.href }),
    );
  }
  const asaParams: string | null = sessionStorage.getItem('asa_params');
  const otpVerified = getIsOtpVerified();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(SignupSchema),
  });
  const createAcount: SubmitHandler<IFormInput> = async (dto: SignupDTO): Promise<void> => {
    setOpen(false);
    logger.debug('Submitted form to register user.', dto);
    setIsLoading(true);
    try {
      let result: CustomUser = await dispatch(dispatchSignup(dto));
      let initiateOtp = !(
        result.user.email?.includes('jumpstarttutors') ||
        result.user.email?.includes('verolearning')
      );
      const updateClaimsUrl =
        process.env[EnvironmentVariable.REACT_APP_PARAGON_ADD_USER_CLAIMS_ENDPOINT];
      const deleteUserUrl = process.env[EnvironmentVariable.REACT_APP_PARAGON_DELETE_USER_ENDPOINT];

      if (asaParams && result.user) {
        const asaParamObj = JSON.parse(asaParams);
        try {
          const response = await AddUserClaims(
            {
              id: result.user.uid,
              isAsaUser: true,
              asaUserId: asaParamObj['user_id'] || null,
              role: UserRole.ASA_USER,
            },
            updateClaimsUrl,
          );

          if (response.ok) {
            let taskName = '';
            let totalSteps = 0;
            if (asaParamObj['task_url']?.includes(AsaTaskName.SKILL)) {
              taskName = AsaTaskName.SKILL;
              totalSteps = 10;
            } else if (asaParamObj['task_url']?.includes(AsaTaskName.SIGNUP)) {
              taskName = AsaTaskName.SIGNUP;
              totalSteps = 1;
            }
            const endPointUrl = process.env[EnvironmentVariable.REACT_APP_ASA_COMPLETE_TASK_URL];
            const isStaging =
              process.env[EnvironmentVariable.REACT_APP_BASE_URL]?.includes('staging') ||
              process.env[EnvironmentVariable.REACT_APP_BASE_URL]?.includes('localhost');
            const taskResponse = await completeASATask(
              {
                asaUserId: asaParamObj['user_id'] as number,
                taskId: asaParamObj['unique_task_key'],
                taskStep: 1,
              },
              endPointUrl || '',
              isStaging || false,
            );

            if (!taskResponse || !taskResponse?.ok) {
              initiateOtp = false;
              setIsLoading(false);
              await DeleteUser(result.user.uid, deleteUserUrl);
              await dispatch(dispatchLogout());
              toast.error('Something went wrong!');
            } else {
              await dispatch(
                dispatchUpdateAsaTaskHistoryInfo({
                  asaUserId: asaParamObj['user_id'] as number,
                  taskId: asaParamObj['unique_task_key'],
                  taskStep: asaParamObj['step_number'],
                  taskUrl: asaParamObj['task_url'],
                  taskName: taskName,
                  totalSteps: totalSteps,
                  startDate: new Date(),
                  completedDate: new Date(),
                  isCompleted: true,
                }),
              );

              let url = new URL(asaParamObj['task_url'] || location.href);
              const nextTaskUrl = buildASANextTaskUrl(url, AsaTaskName.SKILL, 2);
              url = new URL(nextTaskUrl || '');
              await dispatch(
                dispatchUpdateAsaTaskHistoryInfo({
                  asaUserId: asaParamObj['user_id'] as number,
                  taskId: asaParamObj['unique_task_key'],
                  taskStep: 2,
                  taskUrl: nextTaskUrl,
                  taskName: AsaTaskName.SKILL,
                  totalSteps: 11,
                }),
              );
              result = await dispatch(dispatchLogin(dto));
              sessionStorage.removeItem('asa_params');
              toast.success('User successfully created');
              // navigate('/pre-employment/start');
            }
          } else {
            initiateOtp = false;
            setIsLoading(false);
            await DeleteUser(result.user.uid, deleteUserUrl);
            await dispatch(dispatchLogout());
            toast.error('Something went wrong!');
          }
        } catch (e) {
          initiateOtp = false;
          setIsLoading(false);
          await dispatch(dispatchLogout());
          await DeleteUser(result.user.uid, deleteUserUrl);
          toast.error('Something went wrong!');
        }
      } else if (organization) {
        const organizationHash: string = await hash(organization);
        try {
          const response = await AddUserClaims(
            {
              id: result.user.uid,
              isAsaUser: false,
              role: UserRole.ORGANIZATION_USER,
              organizationId: organizationHash,
            },
            updateClaimsUrl,
          );

          if (response.ok) {
            await dispatch(
              dispatchUpdateIntakeFormUserOrganization({ organization: organizationHash }),
            );
            await upsertOrganization({ organization });
            Cookies.remove('organization', { path: '/' });
            result = await dispatch(dispatchLogin(dto));
            toast.success('User successfully created');
          } else {
            initiateOtp = false;
            setIsLoading(false);
            await DeleteUser(result.user.uid, deleteUserUrl);
            await dispatch(dispatchLogout());
            toast.error('Something went wrong!');
          }
        } catch (e) {
          initiateOtp = false;
          setIsLoading(false);
          await dispatch(dispatchLogout());
          await DeleteUser(result.user.uid, deleteUserUrl);
          toast.error('Something went wrong!');
        }
      }

      sessionStorage.removeItem('asa_params');
      await updateUserEmail({ email: result.user.email || '' });
      await dispatch(
        dispatchUpdateUserProfileInfo({
          email: result.user.email || '',
          isMFAEnabled: initiateOtp,
        }),
      );
      if (initiateOtp) {
        const otp = await sendOtpToEmail();
        const otpExpTime = process.env[EnvironmentVariable.REACT_APP_OTP_EXP_TIME];
        void dispatchOtpEmail({
          To: result?.user?.email || '',
          otp: otp,
          validUntil: Number(otpExpTime) || 15,
        });
        setOpen(true);
      } else {
        const userProfile = await getUserProfileValues();
        dispatch(setOTPAuthAction());
        await isOtpVerified(true);
        await dispatch(dispatchVerifyLogin(result));
        await dispatch(clearUserTempData());
        if (user?.claims.isAsaUser) {
          // const asaParamObj = asaParams && JSON.parse(asaParams);
          const taskHistory: UserAsaTaskHistory | undefined = await dispatch(
            dispatchGetAsaTaskHistoryValues(),
          );

          if (taskHistory) {
            const currentTask = taskHistory.tasks.find((x) => !x.isCompleted);
            if (userProfile?.email && !userProfile?.asaUserId) {
              navigate('/pre-employment/start');
            } else {
              window.location.href = currentTask?.taskUrl
                ? currentTask?.taskUrl
                : userProfile?.isPremium
                ? '/dashboard'
                : '/upgrade';
            }
          }
        } else {
          toast.success('User successfully created');
          navigate('/pre-employment/start');
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        toast.error(`${e?.message?.split('/')[1]?.split(')')[0]?.split('-')?.join(' ')}`);
      } else {
        toast.error('Something went wrong!');
      }
    } finally {
      setIsLoading(false);
    }
  };

  interface IFormInput {
    email: string;
    password: string;
  }

  const user: CustomUser | null = useAuthContext();
  const isAuthenticated: boolean = user ? true : false;

  useEffect(() => {
    // TODO: create utility function for this because it's used in multiple places
    if (organization) {
      Cookies.set('organization', organization, { path: '/' });
    }
  }, [organization]);

  useEffect(() => {
    if (disablePersonalInfo !== undefined && Cookies.get('disablePersonalInfo') === undefined) {
      Cookies.set('disablePersonalInfo', 'true');
    }
  }, [disablePersonalInfo]);

  return isAuthenticated && !asaParams && otpVerified ? (
    <Navigate to="/pre-employment/start" />
  ) : (
    <div className="flex items-center justify-center">
      {isLoading && <CircularLoader />}
      <OtpPopup isOtpSent={open} />
      <div
        className={`mx-auto w-full max-w-lg bg-white-100 rounded-xl  px-5 py-10 lg:p-10 border border-black/10`}
      >
        <h2 className="text-center text-2xl font-bold leading-tight mb-4">
          Sign up to create account
        </h2>
        <p className="mt-2 text-center text-base text-black/60 mb-8">
          Already have an account?&nbsp;
          <Link
            to="/login"
            className="text-purple-700 font-medium text-primary transition-all duration-200 hover:underline"
          >
            Sign In
          </Link>
        </p>
        <Box
          className="form-style"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(createAcount)}
          component="form"
          sx={{
            '& > :not(style)': { width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <FormControl margin="normal">
            <TextField
              id="email"
              type="email"
              {...register('email')}
              label="Email"
              variant="outlined"
            />
            <p className="text-red-500">{errors.email?.message}</p>
          </FormControl>
          <FormControl margin="normal">
            <TextField
              id="password"
              type="password"
              {...register('password')}
              label="Password"
              variant="outlined"
            />
            <p className="text-red-500">{errors.password?.message}</p>
          </FormControl>
          <FormControl margin="normal">
            <button type="submit" className="btn-primary w-full" disabled={isLoading}>
              Create Account
            </button>
          </FormControl>
        </Box>
      </div>
    </div>
  );
};
