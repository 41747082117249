import _ from 'lodash';
import { useEffect, useState } from 'react';

import introImg from '../../../../assets/images/courses/jumpstart-communicator/chapter1/Intro.jpeg';
import Pre_assesment_bg from '../../../../assets/images/Pre-assesment-bg.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  QuestionOption,
  QuizAnswer,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { course_questions } from '../../../../courses/jumpstart-communicator/jumstart-communicator.pre-assessment';
import { useUserCoursePrePostAssessmentQuestionsContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { useCourseProgressContext } from '../../../../hooks/use-user-course.hooks';
import { dispatchUpdateUserCourseAssesmentQuestionResponse } from '../../../../redux/slice/user-assesments/user-course-assesments.opertations';
import { dispatchUpdateChapterProgress } from '../../../../redux/slice/user-course';
import { UserChapterQuestion } from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { MediaBlock } from '../../MediaBlock';
import { GenericPreAssessmentsQuizQuestion } from '../../PreAssessmentsQuiz';
import { QuizResult } from '../../QuizResult';

interface JumpStartChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
  courseId?: string;
  onQuizResult?: (result: boolean | undefined) => void;
}
type AnswersState = {
  [questionId: string]: QuizAnswer;
};
export const JumpStartChapter1: React.FC<JumpStartChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
  courseId,
  onQuizResult,
}) => {
  const dispatch = useAppDispatch();
  const courseProgressContext = useCourseProgressContext();
  const userCourseQuestionResponseContext = useUserCoursePrePostAssessmentQuestionsContext();
  // const [courseProgress, setCourseProgress] = useState(
  //   courseProgressContext[courseId || '']?.progress || 0,
  // );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [, setAnswers] = useState<AnswersState>({});
  const [quizScore, setQuizScore] = useState<number | null>(null);
  const [lastQuizScore, setLastQuizScore] = useState<number | null | undefined>(null);

  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const quizQuestions = course_questions.filter((course) => course.chapter_id === chapter.id);

  const handleAnswerSubmit = async (
    questionId: string,
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
  ) => {
    setAnswers((prevAnswers) => {
      return {
        ...prevAnswers,
        [questionId]: {
          question: quizQuestions[currentQuestionIndex].question,
          is_correct: isCorrect,
          answer_options: quizAnswers,
        },
      };
    });
    setAnswers((prevAnswers) => {
      if (currentQuestionIndex + 1 === quizQuestions.length) {
        const totalQuestions = Object.keys(prevAnswers).length;
        const correctAnswers = Object.values(prevAnswers).filter(
          (answer) => answer.is_correct,
        ).length;

        // Calculate percentage
        const score = (correctAnswers / totalQuestions) * 100;

        setQuizScore(score);
        // if (score < 80 && onQuizResult) {
        //   onQuizResult(false);
        // } else {
        //   onQuizResult && onQuizResult(true);
        // }
        const postAssessmentQuestions =
          userCourseQuestionResponseContext[quizQuestions[0].course_id]?.postAssessmentQuestions ??
          null;
        const answersArray = Object.entries(prevAnswers);
        const quizQuestionAnswers: UserChapterQuestion[] = [];
        answersArray.map(([questionId, quizAnswer]) => {
          quizQuestionAnswers.push({
            question_id: questionId,
            answers: quizAnswer.answer_options,
            isCorrect: quizAnswer.is_correct,
            question: quizAnswer.question,
          });
        });
        void dispatch(
          dispatchUpdateUserCourseAssesmentQuestionResponse({
            [quizQuestions[0].course_id]: {
              preAssessmentsQuestions: {
                isPassed: score >= 80,
                questions: quizQuestionAnswers,
                score: score,
              },
              postAssessmentQuestions: postAssessmentQuestions ?? null,
            },
          }),
        );
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      return { ...prevAnswers };
    });
  };

  const handleStart = () => {
    setCurrentQuestionIndex(0);
  };

  const handleQuizRetake = async () => {
    onQuizResult && onQuizResult(undefined);
    setCurrentQuestionIndex(-1);
    setQuizScore(null);
    setAnswers({});
    void dispatch(
      dispatchUpdateChapterProgress({
        [courseId || '']: {
          activeChapter: chapter.id,
          chapters: { [chapter.id]: 90 },
          progress: _.clamp(
            (courseProgressContext[courseId || '']?.progress || 0) - chapter.completion_percentage,
            0,
            100,
          ),
        },
      }),
    );

    const postAssessmentQuestions =
      userCourseQuestionResponseContext[quizQuestions[0].course_id].postAssessmentQuestions ?? null;

    await dispatch(
      dispatchUpdateUserCourseAssesmentQuestionResponse({
        [quizQuestions[0].course_id]: {
          preAssessmentsQuestions: null,
          postAssessmentQuestions: postAssessmentQuestions ?? null,
        },
      }),
    );
  };
  useEffect(() => {
    //Calculate course progress based on the previous quiz answers
    if (
      courseId &&
      Object.keys(
        userCourseQuestionResponseContext[quizQuestions[0].course_id]?.preAssessmentsQuestions ||
          {},
      ).length
    ) {
      setLastQuizScore(
        userCourseQuestionResponseContext[quizQuestions[0].course_id]?.preAssessmentsQuestions
          ?.score,
      );
      setQuizScore(
        userCourseQuestionResponseContext[quizQuestions[0].course_id]?.preAssessmentsQuestions
          ?.score ?? null,
      );
    }
  }, [userCourseQuestionResponseContext]);

  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Introduction</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock mediaImage={introImg}>
          <p className="lg:w-3/4 text-2xl leading-10">
            A teenager texts a friend to meet her at her locker. A toddler points to his mouth in a
            busy restaurant, signaling he’s hungry. A nervous employee with shaky hands asks for a
            raise.
          </p>
        </MediaBlock>
        <p className="text-2xl text-center mt-7 mb-20 leading-10">
          All these familiar scenes are displays of the communication process in action. They’re
          examples of how we use various mediums such as speech, written words, and physical
          expressions to <span className="font-bold"> send</span> and
          <span className="font-bold"> receive</span> information or
          <span className="font-bold"> messages</span>.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mb-1">Learning Objectives</h3>
        <p className="text-xl font-light mb-8">In this module, participants will:</p>
        <ol className="list-decimal pl-5 mb-14 text-xl font-light">
          <li className="pl-9 mb-7">
            Gain knowledge and apply tools for great communication, including straightforward
            messages, listening skills, and confident and clear writing.
          </li>
          <li className="pl-9 mb-7">
            Develop skills to build positive team dynamics, increase trust, and manage effectively.
          </li>
          <li className="pl-9 mb-7">
            Learn how to avoid unclear language and choose effective communication mediums.
          </li>
          <li className="pl-9 mb-7">
            Evolve the ability to share information and keep an open mind even if you have different
            perspectives, approaches, and experiences.
          </li>
        </ol>
        <h3 className="text-2xl mt-2  text-center font-light mb-5">
          Let's start by completing the following assessment on your current communication skills.
        </h3>
        <div className="mt-5 mb-5">
          {quizScore !== null ? (
            <QuizResult
              lastScore={lastQuizScore}
              courseId={courseId}
              chapter={chapter}
              onNextChapter={handleNextChapterClick}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onRetakeClick={handleQuizRetake}
              score={quizScore}
              quizResultTitle="Pre-Assesment Result"
            />
          ) : currentQuestionIndex < 0 ? (
            <MediaBlock mediaImage={Pre_assesment_bg}>
              <div className="text-center mb-4 w-full">
                <p className="text-2xl leading-10 font-light lg:w-3/4 mx-auto">
                  Click the start button to begin the assessment
                </p>
                <button onClick={handleStart} className="btn-primary mt-4">
                  Start
                </button>
              </div>
            </MediaBlock>
          ) : (
            <GenericPreAssessmentsQuizQuestion
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onAnswerSubmit={handleAnswerSubmit}
              questionType={quizQuestions[currentQuestionIndex]?.type}
              question={quizQuestions[currentQuestionIndex]}
              currentQuestionIndex={currentQuestionIndex + 1}
              totalQuestions={quizQuestions.length}
              buttonText="Continue"
              typeOfAssesment="Pre assessment"
            />
          )}
        </div>
        <div className="mt-7 mb-5">
          <h3 className="text-3xl text-center text-purple-600 font-semibold mb-5">
            How did you do?
          </h3>
          <h4 className="text-2xl mb-3">Remember</h4>
          <p className="font-light text-lg mb-8">
            Assessments like these are merely snapshots of where we are at a given moment. Your
            score serves as a starting point, highlighting areas where we can focus our efforts
            during the course. It's perfectly normal to encounter challenges initially, especially
            in new or complex subjects. What's essential is the willingness to learn and grow.
          </p>
          <p className="font-light text-lg mb-8">
            Throughout the course, we'll delve deeper into the material, providing ample support and
            resources to aid your understanding. Remember, learning is a journey, and progress often
            comes through perseverance and determination.
          </p>
        </div>
        <button
          disabled={quizScore === null}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          CLICK TO GET STARTED
        </button>
      </div>
    </>
  );
};
