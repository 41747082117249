import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import {
  getAsaTaskHistoryValues,
  upsertAsaTaskHistory,
} from '../../../services/database/asa-task-history';

import { updateUserAsaTaskHistoryInfoAction } from './asa-task-history.slice';
import {
  CompleteAsaTaskDto,
  UserAsaTaskHistory,
  UserAsaTaskHistoryDTO,
} from './asa-task-history.types';

/**
 * retrieve previously saved values from the asa task history
 */
export const dispatchGetAsaTaskHistoryValues =
  () =>
  async (
    dispatch: Dispatch<PayloadAction<UserAsaTaskHistory>>,
  ): Promise<UserAsaTaskHistory | undefined> => {
    const values: UserAsaTaskHistory | undefined = await getAsaTaskHistoryValues();
    dispatch(updateUserAsaTaskHistoryInfoAction(values || { asaUserId: 0, tasks: [], userId: '' }));
    return values;
  };

/**
 * update user profile info
 */
export const dispatchUpdateAsaTaskHistoryInfo =
  (dto: UserAsaTaskHistoryDTO) =>
  async (dispatch: Dispatch<PayloadAction<UserAsaTaskHistory>>): Promise<void> => {
    await upsertAsaTaskHistory(dto);
    const values: UserAsaTaskHistory | undefined = await getAsaTaskHistoryValues();
    dispatch(updateUserAsaTaskHistoryInfoAction(values || { asaUserId: 0, tasks: [], userId: '' }));
  };

/**
 * send task complete status to ASA
 */
export const completeASATask = async (dto: CompleteAsaTaskDto, url: string, isStaging: boolean) => {
  if (url) {
    const response = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        taskId: dto.taskId,
        taskStep: dto.taskStep,
        asaUserId: dto.asaUserId,
        isProduction: !isStaging,
      }),
    });

    return response;
  }
  return null;
};
