import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { CoursePrePostAssessmentsState } from '../../redux/slice/user-assesments/users-course-assesments.slice';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.COURSE_PRE_POST_ASSESMENT_RESPONSE;

export async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

export async function getUserPreAssesmentCourseQuestionValues(): Promise<CoursePrePostAssessmentsState> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<CoursePrePostAssessmentsState> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return result.data() ?? {};
  } catch (e) {
    logger.error('Failed to retrieve user course question values.', e);
    throw e;
  }
}

export async function updateUserPreAssesmentQuestion(
  dto: CoursePrePostAssessmentsState,
): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved user course pre assesment question.');
  } catch (e) {
    logger.error('Failed to update user course pre assesment question.', e);
    throw e;
  }
}
