import { deleteField, doc, setDoc } from 'firebase/firestore';

import { CustomUser } from '../../redux/slice/auth/CustomUser';

import { getCurrentUser } from './auth';
import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';

/**
 * returns the id used for indexing a user's submissions
 *
 * @todo retrieve current pre-employment assessment and use that as the id
 *       the current implementation has the forms + the pre-employment assessment indexing by the user id so it works for now
 *       if in the future we want to support taking the test multiple times, this will have to change
 */
export async function getIdForFormRecord(): Promise<string> {
  const currentUser: CustomUser | null = await getCurrentUser();
  if (!currentUser) {
    // TODO: use custom errors
    throw new Error('User not authenticated!');
  }

  return currentUser.user.uid;
}

/**
 * updates `undefined` properties in a DTO with firestore's `deleteField`
 *
 * if `undefined` properties are attempted to be set, firestore throws an error
 * this method is used to intentionally unset values
 *
 * @tutorial https://firebase.google.com/docs/firestore/manage-data/delete-data#fields
 */
export function replaceUndefinedWithDeleteField<T extends Record<string, unknown>>(dto: T): T {
  const transformedDTO = Object.keys(dto).reduce(
    (updated: Partial<T>, key: string): Partial<T> => ({
      ...updated,
      [key]: dto[key] === undefined ? deleteField() : dto[key],
    }),
    {},
  );
  return transformedDTO as T;
}

/**
 * upsert a record into the  collection
 */
export async function updateRecord<T extends Record<string, unknown>>(
  dto: T,
  database: DatabaseTable,
): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}
