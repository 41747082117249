import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import contactImg from '../assets/images/Mention-bro.svg';
import Container from '../components/container/Container';
import {
  dispacthInsertContactUs,
  dispatchSyncContactToParagon,
} from '../redux/slice/form-contact-us/form-contact-us.operations';
import { ContactUseInfo } from '../redux/slice/form-contact-us/form-contact-us.types';
import { useAppDispatch } from '../redux/store';
import logger from '../services/logger';
const initialState = {
  name: '',
  email: '',
  message: '',
};

interface FormData extends ContactUseInfo {}
const ContactUseInfoSchema = yup
  .object({
    name: yup.string().trim().max(50, 'Name must not exceed 50 characters').required('Required'),
    email: yup
      .string()
      .max(100, 'Email must not exceed 100 characters')
      .email('Invalid email address')
      .required(),
    message: yup
      .string()
      .trim()
      .max(250, 'Message must not exceed 250 characters')
      .required('Required'),
  })
  .required();

const ContactUs: React.FC = () => {
  const dispatch = useAppDispatch();
  const { name, email, message } = initialState;
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(ContactUseInfoSchema),
    defaultValues: {
      name: name,
      email: email,
      message: message,
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const submitContactUs: SubmitHandler<FormData> = async (data: FormData) => {
    logger.debug('Submitted Contact us info.', data);

    setIsLoading(true);
    try {
      if (data) {
        await dispatch(dispacthInsertContactUs(data));
        await dispatchSyncContactToParagon(data);
        toast.success('Thank you! Your submission has been received!');
        reset({
          name: '',
          email: '',
          message: '',
        });
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    reset({
      name: '',
      email: '',
      message: '',
    });
  }, [name, email, message]);
  return (
    <>
      <div className="contact-wrap relative flex items-center py-24">
        <Container>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="banner-content">
              <h1 className="!font-semibold">Contact Us</h1>
              <h4 className="mt-5 mb-12">
                Have a inquiry or some feedback for us? Fill out the form below to contact our team{' '}
              </h4>
              <Box
                className="md:px-10"
                component="form"
                sx={{
                  '& > :not(style)': { mb: 4 },
                }}
                autoComplete="off"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit(submitContactUs)}
              >
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      className="w-full"
                      id="name"
                      type="text"
                      label="Name"
                      variant="outlined"
                      placeholder="Enter your name"
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      inputProps={{ maxLength: 50 }}
                    />
                  )}
                ></Controller>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      className="w-full"
                      id="email"
                      type="email"
                      label="Email"
                      variant="outlined"
                      placeholder="Enter your email"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      inputProps={{ maxLength: 100 }}
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                    />
                  )}
                ></Controller>

                <Controller
                  control={control}
                  name="message"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      className="w-full"
                      id="message"
                      type="text"
                      label="Message"
                      multiline
                      rows={4}
                      variant="outlined"
                      placeholder="Enter your message"
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                ></Controller>

                <button type="submit" className="btn-primary" disabled={isLoading}>
                  Contact Us
                </button>
              </Box>
            </div>
            <div className="">
              <img className="ml-auto mt-16" src={contactImg} alt="" />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default ContactUs;
