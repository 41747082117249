import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { AuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { AgeRange } from '../components/Intake/AgeRange';
import { CareerConstraints } from '../components/Intake/CareerConstraints';
import { CareerPlan } from '../components/Intake/CareerPlan';
import { IntakeHeader } from '../components/Intake/IntakeHeader';
import { LanguageProficiency } from '../components/Intake/LanguageProficiency';
import { PersonalInfo } from '../components/Intake/PersonalInfo';
import { WorkHistory } from '../components/Intake/WorkHistory';
import { WorkStatus } from '../components/Intake/WorkStatus';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../hooks/use-pre-employment-assessment.hooks';
//import { useSWOTContext } from '../hooks/use-swot-context.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import {
  PreEmploymentAssessmentModule,
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../redux/slice/pre-employment-assessment';
import { dispatchGetSWOTReportValues } from '../redux/slice/swot/swot.operations';
//import { SWOTReport } from '../redux/slice/swot/swot.types';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';

export const stepsForPreEmploymentModules: Record<PreEmploymentAssessmentModule, number> = {
  [PreEmploymentAssessmentModule.INTAKE]: 1,
  [PreEmploymentAssessmentModule.EMPLOYMENT_READINESS]: 8,
  [PreEmploymentAssessmentModule.SKILLS]: 16,
  [PreEmploymentAssessmentModule.CAREER_READINESS]: 27,
  [PreEmploymentAssessmentModule.EXPERIENCE]: 29,
};

export const Intake: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //const swot: SWOTReport | undefined = useSWOTContext();
  const { componentName } = useParams();
  const {
    status: preEmploymentAssessmentStatus,
    progress,
    activeStepUrl,
    isSWOTGenerated,
  } = usePreEmploymentAssessmentContext();
  const { organization, email: userEmail } = useIntakeFormContext();

  const userProfileContext = useUserProfileContext();

  useEffect(() => {
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchUpsertPreEmploymentAssessment());
    void dispatch(dispatchGetSWOTReportValues());
    void dispatch(dispatchGetUserProfileValues());
  }, []);
  const [step, setStep] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBackButton = () => {
    void dispatch(
      dispatchUpsertPreEmploymentAssessment({
        activeStepUrl: `/pre-employment/intake/step${step - 1}`,
      }),
    );
    navigate(`/pre-employment/intake/step${step - 1}`);
  };

  useEffect(() => {
    setStep(parseInt(componentName?.replace('step', '') || '1'));
    window.scrollTo(0, 0);

    if (!componentName) {
      navigate('/pre-employment/intake/step1');
    }
  }, [componentName]);

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.NOT_STARTED) {
      void dispatch(
        dispatchUpsertPreEmploymentAssessment({
          status: PreEmploymentAssessmentStatus.ACTIVE,
        }),
      );
    }
  }, [preEmploymentAssessmentStatus]);

  useEffect(() => {
    if (
      Object.keys(userProfileContext).length &&
      !userProfileContext?.isPremium &&
      userProfileContext?.email &&
      userEmail &&
      preEmploymentAssessmentStatus &&
      !organization
    ) {
      activeStepUrl
        ? navigate(activeStepUrl)
        : isSWOTGenerated
        ? navigate('/upgrade')
        : navigate('/pre-employment/start');
    } else if (
      Object.keys(userProfileContext).length &&
      (userProfileContext?.isPremium || organization) &&
      userProfileContext?.email &&
      userEmail &&
      preEmploymentAssessmentStatus
    ) {
      activeStepUrl && activeStepUrl.includes('intake') && navigate(activeStepUrl);
    }
  }, [organization, userProfileContext, userEmail, preEmploymentAssessmentStatus]);
  // useEffect(() => {
  //   if (swot) {
  //     navigate('/swot/step1');
  //   }
  // }, [swot, navigate]);

  const renderFormStep = () => {
    switch (componentName) {
      case 'step1':
        return <PersonalInfo step={step} />;
      case 'step2':
        return <AgeRange step={step} onBack={handleBackButton} />;
      case 'step3':
        return <WorkStatus step={step} onBack={handleBackButton} />;
      case 'step4':
        return <LanguageProficiency step={step} onBack={handleBackButton} />;
      case 'step5':
        return <WorkHistory step={step} onBack={handleBackButton} />;
      case 'step6':
        return <CareerPlan step={step} onBack={handleBackButton} />;
      case 'step7':
        return <CareerConstraints step={step} onBack={handleBackButton} />;
      default:
        return null;
    }
  };

  return (
    <div className={`steps overflow-hidden relative step-${step}`}>
      <Container>
        <>
          <IntakeHeader progress={progress || 0} step={step}>
            <>{renderFormStep()}</>
          </IntakeHeader>
        </>
      </Container>
    </div>
  );
};

export const IntakePage: React.FC = () => {
  return <AuthenticatedPage render={() => <Intake />} />;
};
