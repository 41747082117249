import React from 'react';
import { useDrag } from 'react-dnd';

import dragIcon from '../../../assets/icons/drag.svg';
import { QuestionBase } from '../../../courses/jumpstart-communicator/jumpstart-communicator.types';
interface QuestionCardProps {
  question: QuestionBase;
  onDrag: () => void;
}

const QuestionCard: React.FC<QuestionCardProps> = ({ question, onDrag }) => {
  const [, drag] = useDrag({
    type: 'QUESTION',
    item: { type: 'QUESTION', question },
    end: (_, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        onDrag();
      }
    },
  });

  return (
    <div
      ref={drag}
      className="cursor-grab max-w-xs lg:max-w-sm h-48 text-center p-5 lg:p-8 border rounded-lg bg-white text-base lg:text-lg flex items-center justify-center mx-auto mt-10 border-t-purple-600 border-t-8 relative"
    >
      <p>{question.question}</p>
      <div className="absolute bottom-4 left-0 right-0 mx-auto flex justify-center">
        <img src={dragIcon} alt="" />
      </div>
    </div>
  );
};

export default QuestionCard;
