import {
  AgreementLevel,
  ProfessionalResourceStatus,
  YesNoSomewhat,
} from '../../redux/slice/form-employment-readiness/form-employment-readiness.types';

/**
 * the types of questions in the employment readiness assessment
 */
export enum ReadinessQuestionType {
  YES_NO = 'yes_no',
  AGREEMENT_LEVEL = 'agreement_level',
  MARKETING_TOOLKIT = 'marketing_toolkit',
}

/**
 * a question in which the user selects one option
 */
export type YesNoQuestion = {
  /**
   * a unique id for the question
   */
  id: string;

  /**
   * the type of question
   */
  type: ReadinessQuestionType.YES_NO;

  /**
   * the question displayed to users
   */
  question: string;

  /**
   * the tooltip on question
   */
  tooltip?: AssessmentTooltip;
};

/**
 * an answer to yes/no question
 */
export type YesNoAnswer = {
  /**
   * the question that was asked
   */
  question: string;

  /**
   * answet option selected for the question
   */
  answer: YesNoSomewhat;
};

/**
 * a tooltip shown for the question
 */
export type AssessmentTooltip = {
  /**
   * title which highlight tooltip string
   */
  title: string;

  /**
   * content which will shown for the tooltip
   */
  content: string;

  /**
   * bullet list which will shown inside tooltip box
   */
  bulletList?: string[];
};

/**
 * a question in which the user selects one option
 */
export type MarketingToolkitQuestion = {
  /**
   * a unique id for the question
   */
  id: string;

  /**
   * the type of question
   */
  type: ReadinessQuestionType.MARKETING_TOOLKIT;

  /**
   * the resource displayed in bold
   */
  resource: string;

  /**
   * the marketing toolkit options
   */
  options: Record<string, ProfessionalResourceStatus>;
};

/**
 * an answer to an marketting toolkit question
 */
export type MarketingToolkitAnswer = {
  /**
   * the question that was asked
   */
  question: string;

  /**
   * answet option selected for the question
   */
  answer: ProfessionalResourceStatus;
};

/**
 * a question in which the user selects one option
 */
export type AgreementLevelQuestion = {
  /**
   * a unique id for the question
   */
  id: string;

  /**
   * the type of question
   */
  type: ReadinessQuestionType.AGREEMENT_LEVEL;

  /**
   * the question displayed to users
   */
  question: string;
};

/**
 * an answer to an agreement level question
 */
export type AgreementLevelAnswer = {
  /**
   * the question that was asked
   */
  question: string;

  /**
   * answet option selected for the question
   */
  answer: AgreementLevel;
};

/**
 * a question in the employment readiness assessment
 */
export type ReadinessQuestion = YesNoQuestion | MarketingToolkitQuestion | AgreementLevelQuestion;

/**
 * an answer to a question in the assessment
 */
export type EmploymentReadinessAnswer = YesNoAnswer | MarketingToolkitAnswer | AgreementLevelAnswer;
