import { httpsCallable } from 'firebase/functions';

import { getFunctionsInstance } from '../services/database';

interface UploadParams extends DownloadParams {
  file: string; // Base64 encoded file content
  contentType: string;
}
interface DownloadParams {
  fileName: string;
  fileType: string;
  userId: string;
}
interface UploadResponse {
  url: string;
}
export const uploadFile = async (params: UploadParams): Promise<UploadResponse> => {
  const functions = getFunctionsInstance();
  const uploadDocumentCallable = httpsCallable<UploadParams, UploadResponse>(
    functions,
    'uploadDocument',
  );
  try {
    const result = await uploadDocumentCallable(params);
    return result.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const getSignedUrl = async (params: DownloadParams): Promise<UploadResponse> => {
  const functions = getFunctionsInstance();
  const getSignedUrlCallable = httpsCallable<DownloadParams, UploadResponse>(
    functions,
    'getSignedUrlForS3',
  );
  try {
    const result = await getSignedUrlCallable(params);
    return result.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};
