/* eslint-disable @typescript-eslint/no-misused-promises */
import { Box, Button, Modal, TextField } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { sendPasswordResetLink } from '../../services/database';
import { UiMessages } from '../../utils/uiMessages';
import { CircularLoader } from '../loader/CircularLoader';

type ForgetPasswordPopupProps = {
  openPopup: boolean;
  onClose: () => void;
};

export const ForgetPasswordPopup: React.FC<ForgetPasswordPopupProps> = ({ openPopup, onClose }) => {
  const [emailForReset, setEmailForReset] = useState('');
  const [loading, setLoading] = useState(false);
  const [information, setInformation] = useState(UiMessages.forgotPassword);
  const [isResetEmailSent, setIsResetEmailSent] = useState(false);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    return emailRegex.test(email);
  };

  const handleForgotPasswordSubmit = async () => {
    if (!emailForReset) {
      toast.error('Please enter an email address');
      return;
    }

    if (!isValidEmail(emailForReset)) {
      toast.error('Please enter a valid email address');
      return;
    }

    setLoading(true);
    try {
      const response = await sendPasswordResetLink(emailForReset);
      if (response) {
        toast.success(`Password reset link sent to ${emailForReset}`);
        setEmailForReset('');
        setIsResetEmailSent(true);
        setInformation(UiMessages.forgotPasswordEmailSent);
      }
    } catch (error) {
      toast.error('Error sending password reset link, please check the email address');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setEmailForReset('');
    setIsResetEmailSent(false);
    setInformation(UiMessages.forgotPassword);
    onClose();
  };

  return (
    <div className="p-4">
      {loading && <CircularLoader />}
      <Modal
        open={openPopup}
        onClose={handleClose} // Ensure handleClose is called to reset states
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
        BackdropProps={{
          onClick: (e) => e.stopPropagation(),
        }}
      >
        {isResetEmailSent ? (
          <Box className="cstm-popup-fg md:w-1/4 md:p-6 p-3 ">
            <h2 className="text-[1.5rem] text-center">Reset Password</h2>
            <p className="text-gray-400 font-light mb-4 mt-4 p-2 text-lg sm:text-sm md:text-center">
              {information}
            </p>
            <div className="btn-container mt-3 flex justify-center gap-4">
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </div>
          </Box>
        ) : (
          <Box className="cstm-popup-fg md:w-1/4 md:p-6 p-3">
            <h2 className="text-[1.5rem] text-center mt-3">Reset Password</h2>

            <p className="text-gray-400 font-light mb-4 mt-4 p-2 text-lg sm:text-sm md:text-center">
              {information}
            </p>
            <TextField
              autoFocus
              margin="dense"
              label="Email Address"
              type="email"
              variant="outlined"
              fullWidth
              value={emailForReset}
              onChange={(e) => setEmailForReset(e.target.value)}
            />
            <div className="btn-container mt-3 flex justify-center gap-4">
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleForgotPasswordSubmit}
                variant="contained"
                sx={{
                  backgroundColor: '#962cc6',
                  '&:hover': {
                    backgroundColor: '#8738ab',
                  },
                }}
              >
                Reset Your Password
              </Button>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};
