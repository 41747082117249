import _ from 'lodash';
import { useEffect, useState } from 'react';

import Pre_assesment_bg from '../../../../assets/images/Post-assesment-bg.jpg';
import { course_questions } from '../../../../courses/communication-styles/communication-style-post-assesment.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  QuestionOption,
  QuizAnswer,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCoursePrePostAssessmentQuestionsContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { useCourseProgressContext } from '../../../../hooks/use-user-course.hooks';
import { dispatchUpdateUserCourseAssesmentQuestionResponse } from '../../../../redux/slice/user-assesments/user-course-assesments.opertations';
import { dispatchUpdateChapterProgress } from '../../../../redux/slice/user-course';
import { UserChapterQuestion } from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { MediaBlock } from '../../MediaBlock';
import { GenericPreAssessmentsQuizQuestion } from '../../PreAssessmentsQuiz';
import { QuizResult } from '../../QuizResult';

type AnswersState = {
  [questionId: string]: QuizAnswer;
};

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
  courseId?: string;
  onQuizResult?: (result: boolean | undefined) => void;
}

export const PostAssessmentQuiz: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  courseId,
  onQuizResult,
  totalPages,
}) => {
  const dispatch = useAppDispatch();
  const courseProgressContext = useCourseProgressContext();
  const userCourseQuestionResponseContext = useUserCoursePrePostAssessmentQuestionsContext();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [, setAnswers] = useState<AnswersState>({});
  const [quizScore, setQuizScore] = useState<number | null>(null);
  const [lastQuizScore, setLastQuizScore] = useState<number | null | undefined>(null);

  const quizQuestions = course_questions.filter((course) => course.chapter_id === chapter.id);

  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };

  const handleStart = () => {
    setCurrentQuestionIndex(0);
  };

  const handleAnswerSubmit = async (
    questionId: string,
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
  ) => {
    setAnswers((prevAnswers) => {
      return {
        ...prevAnswers,
        [questionId]: {
          question: quizQuestions[currentQuestionIndex].question,
          is_correct: isCorrect,
          answer_options: quizAnswers,
        },
      };
    });
    setAnswers((prevAnswers) => {
      if (currentQuestionIndex + 1 === quizQuestions.length) {
        const totalQuestions = Object.keys(prevAnswers).length;
        const correctAnswers = Object.values(prevAnswers).filter(
          (answer) => answer.is_correct,
        ).length;
        // Calculate percentage
        const score = (correctAnswers / totalQuestions) * 100;

        setQuizScore(score);
        if (score < 80 && onQuizResult) {
          onQuizResult(false);
        } else {
          onQuizResult && onQuizResult(true);
        }
        const preAssessmentResult =
          userCourseQuestionResponseContext[quizQuestions[0].course_id]?.preAssessmentsQuestions;
        const answersArray = Object.entries(prevAnswers);
        const quizQuestionAnswers: UserChapterQuestion[] = [];
        answersArray.map(([questionId, quizAnswer]) => {
          quizQuestionAnswers.push({
            question_id: questionId,
            answers: quizAnswer.answer_options,
            isCorrect: quizAnswer.is_correct,
            question: quizAnswer.question,
          });
        });
        void dispatch(
          dispatchUpdateUserCourseAssesmentQuestionResponse({
            [quizQuestions[0].course_id || '']: {
              postAssessmentQuestions: {
                isPassed: score >= 80,
                questions: quizQuestionAnswers,
                score: score,
              },
              preAssessmentsQuestions: preAssessmentResult ?? null,
            },
          }),
        );
      } else {
        const quizProgress = Math.floor(((currentQuestionIndex + 1) / quizQuestions.length) * 100);
        void dispatch(
          dispatchUpdateChapterProgress({
            [courseId || '']: {
              activeChapter: chapter.id,
              chapters: { [chapter.id]: quizProgress },
              progress: _.clamp(courseProgressContext[courseId || '']?.progress ?? 0, 0, 100),
            },
          }),
        );
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      return { ...prevAnswers };
    });
  };

  const handleQuizRetake = async () => {
    onQuizResult && onQuizResult(undefined);
    setCurrentQuestionIndex(-1);
    setQuizScore(null);
    setAnswers({});
    const preAssessmentResult =
      userCourseQuestionResponseContext[quizQuestions[0].course_id].preAssessmentsQuestions;

    await dispatch(
      dispatchUpdateChapterProgress({
        [courseId || '']: {
          activeChapter: chapter.id,
          chapters: { [chapter.id]: 0 },
          progress: _.clamp(
            (courseProgressContext[courseId || '']?.progress || 0) - chapter.completion_percentage,
            0,
            100,
          ),
        },
      }),
    );

    await dispatch(
      dispatchUpdateUserCourseAssesmentQuestionResponse({
        [quizQuestions[0].course_id || '']: {
          postAssessmentQuestions: null,
          preAssessmentsQuestions: preAssessmentResult ?? null,
        },
      }),
    );
  };

  useEffect(() => {
    //Calculate course progress based on the previous quiz answers
    if (
      quizQuestions[0].course_id &&
      Object.keys(
        userCourseQuestionResponseContext[quizQuestions[0].course_id]?.postAssessmentQuestions ||
          {},
      ).length
    ) {
      setLastQuizScore(
        userCourseQuestionResponseContext[quizQuestions[0].course_id]?.postAssessmentQuestions
          ?.score,
      );
      setQuizScore(
        userCourseQuestionResponseContext[quizQuestions[0].course_id]?.postAssessmentQuestions
          ?.score ?? null,
      );
    }
  }, [userCourseQuestionResponseContext]);

  return (
    <>
      <div>
        <div className="mt-7 mb-5 pr-2">
          <h3 className="text-3xl  text-purple-600 font-semibold mb-1">Post-Assessment</h3>
          <p className="text-sm italic mb-5">
            Part {chapter.index} of {totalPages}
          </p>

          <p className="font-light text-xl mb-8">
            The following assessment will test your learning, understanding, and application of the
            concepts covered throughout the course. It consists of 10 questions and you must score a
            minimum of 80% to pass.
          </p>
          <p className="font-light text-center text-xl mb-5">
            <strong>Note :</strong>
            You can retake the assessment if you do not achieve 80% or higher.
          </p>
        </div>
        <div className="mb-7">
          {quizScore !== null ? (
            <QuizResult
              lastScore={lastQuizScore}
              courseId={courseId}
              chapter={chapter}
              onNextChapter={handleNextChapterClick}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onRetakeClick={handleQuizRetake}
              score={quizScore}
              quizResultTitle="Post-Assesment Result"
            />
          ) : currentQuestionIndex < 0 ? (
            <MediaBlock mediaImage={Pre_assesment_bg}>
              <div className="text-center mb-4 w-full">
                <p className="text-2xl leading-10 font-light lg:w-3/4 mx-auto">
                  Click the start button to begin the post assessment
                </p>
                <button onClick={handleStart} className="btn-primary mt-4">
                  Start
                </button>
              </div>
            </MediaBlock>
          ) : (
            <GenericPreAssessmentsQuizQuestion
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onAnswerSubmit={handleAnswerSubmit}
              questionType={quizQuestions[currentQuestionIndex]?.type}
              question={quizQuestions[currentQuestionIndex]}
              currentQuestionIndex={currentQuestionIndex + 1}
              totalQuestions={quizQuestions.length}
              buttonText="Continue"
              typeOfAssesment="Post assessment"
            />
          )}
        </div>
      </div>
    </>
  );
};
